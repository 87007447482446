import { useMutation, useQuery, useQueryClient } from 'react-query';
import qs from 'qs';

import * as registerManageApi from 'lib/api/material/registerManage';
import { MaterialRegister, MaterialRegisterGet } from 'types/material/register';
import client from 'lib/api/client';
import { APIPageableResponse } from 'types/common';

const queryKeys = {
  materials: 'materials',
};

export const useMaterialRegisterManage = ({
  status,
  page,
  searchType,
  searchKeyword,
}: MaterialRegisterGet) => {
  const queryClient = useQueryClient();

  const {
    data: materialRegisters = null,
    isFetching: getMaterialRegistersLoading,
  } = useQuery(
    [queryKeys.materials, status, page, searchType, searchKeyword],
    () =>
      client.get<APIPageableResponse<MaterialRegister[]>>(
        `/materials?${qs.stringify({
          page,
          size: 10,
          status,
          searchType,
          searchKeyword,
        })}`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const {
    mutate: deleteMaterials,
    isLoading: deleteMaterialsLoading,
  } = useMutation(
    (materialIds: number[]) => registerManageApi.deleteMaterials(materialIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.materials);
      },
    },
  );

  const {
    mutate: discontinueMaterials,
    isLoading: discontinueMaterialsLoading,
  } = useMutation(
    (materialIds: number[]) =>
      registerManageApi.discontinueMaterials(materialIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.materials);
      },
    },
  );

  return {
    materialRegisters,
    getMaterialRegistersLoading,
    discontinueMaterials,
    discontinueMaterialsLoading,
    deleteMaterials,
    deleteMaterialsLoading,
  };
};
