import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import client from 'lib/api/client';
import * as countryApi from 'lib/api/country';
import { APIResponse } from 'types/common';
import { Country } from 'types/country';

export const useNotarizationCountries = () => {
  const {
    data: notarizationCountries = [],
    isFetching: getLoading,
  } = useQuery(
    'country/getNotarizationCountries',
    countryApi.getNotarizationCountries,
    { select: (res) => res.data.result },
  );
  return useMemo(() => ({ notarizationCountries, getLoading }), [
    notarizationCountries,
    getLoading,
  ]);
};

export const useCountries = () => {
  const [emptyCountries] = useState([]);

  const { data: countries = emptyCountries } = useQuery(
    'country/getCountries',
    countryApi.getCountries,
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );
  return countries;
};

export const useCountryCodeToNameMap = () => {
  const [emptyCountries] = useState([]);

  const {
    data: countries = emptyCountries,
  } = useQuery(
    'country/getPubCountries',
    () => client.get<APIResponse<Country[]>>('/v1/pub/countries'),
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );

  const countryCodeToNameMap: { [key: string]: string } = countries.reduce(
    (acc, country) => {
      acc[country.countryCode] = country.countryNameKo;
      return acc;
    },
    {} as { [key: string]: string },
  );

  countryCodeToNameMap['ETC'] = 'ETC';

  return countryCodeToNameMap;
};

export const usePubCountries = () => {
  const [emptyCountries] = useState([]);

  const {
    data: countries = emptyCountries,
  } = useQuery(
    'country/getPubCountries',
    () => client.get<APIResponse<Country[]>>('/v1/pub/countries'),
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );
  return countries;
};

export const useCountryNameKo = (countryId: number) => {
  const countries = useCountries();
  return useMemo(
    () =>
      countries.find((country) => country.countryId === countryId)
        ?.countryNameKo || '',
    [countries, countryId],
  );
};

export const useCountryCode = (countryId: number) => {
  const countries = useCountries();
  return useMemo(
    () =>
      countries.find((country) => country.countryId === countryId)
        ?.countryCode || '',
    [countries, countryId],
  );
};
