import { Route, Switch } from 'react-router-dom';

import { useIsLoggedIn } from 'service/auth';
import IntroPage from 'pages/main/IntroPage';
import BrandMainPage from 'pages/brand/main/MainPage';
import ManuMainPage from 'pages/manufacturer/main/MainPage';
import MaterialMainPage from 'pages/material/main/MainPage';
import { useCompanyType } from 'service/company';
import { CompanyType } from 'types/company';

const MainRouter = () => {
  const isLoggedIn = useIsLoggedIn();
  const companyType = useCompanyType();

  return (
    <Switch>
      {!isLoggedIn ? (
        <Route path="/" component={IntroPage} />
      ) : (
        <>
          {companyType === CompanyType.BRAND && (
            <Route path="/" component={BrandMainPage} />
          )}
          {companyType === CompanyType.MANUFACTURE && (
            <Route path="/" component={ManuMainPage} />
          )}
          {companyType === CompanyType.MATERIAL && (
            <Route path="/" component={MaterialMainPage} />
          )}
        </>
      )}
    </Switch>
  );
};

export default MainRouter;
