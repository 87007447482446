import { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Radio, message } from 'antd';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { QCQALotRecord } from 'types/brand/qcqa';
import { downloadFile } from 'lib/file';
import Icon from 'components/ui/Icon/Icon';

export interface QCQADocsHistoryModalProps {
  initialQCQADocumentRecordId: number;
  records: QCQALotRecord[] | null;
  onComplete: (qcqaDocumentRecordId: number) => void;
  onClose?: () => void;
}

interface QCQADocsHistoryModalUIProps extends QCQADocsHistoryModalProps {
  selectedId: number;
  onChangeSelectedId: (id: number) => void;
  onSubmit?: (values: any) => void;
}

const HistoryContainer = styled.div`
  margin-top: 24px;

  .ant-radio-group-outline {
    display: block;
    width: 100%;
  }

  .ant-radio-wrapper {
    margin: 0;
    line-height: 0;
  }
`;

const HistoryItemWrapper = styled.div<{ selected: boolean }>`
  padding: 15px 31px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border: ${({ selected }) =>
    selected ? `1px solid ${palette.PRIMARY50}` : `1px solid transparent`};
  border-radius: 8px;
  transition: 'all 0.3s ease';
  cursor: pointer;
`;

const AttachWrapper = styled(Flex)`
  border-radius: 4px;
  padding: 8px 16px;
  background: ${palette.SLATE_GRAY10};
`;

const QCQADocsHistoryModalUI = ({
  records,
  selectedId,
  onChangeSelectedId,
  onComplete,
  onClose,
}: QCQADocsHistoryModalUIProps) => {
  const selectNoneId = 0;

  return (
    <Modal
      visible={true}
      width={800}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      title="개정 이력 변경"
      bodyStyle={{
        height: 630,
        overflowY: 'auto',
      }}
      centered
    >
      <Button
        type="primary"
        onClick={() => {
          onComplete(selectedId);
          onClose?.();
        }}
      >
        선택 완료
      </Button>
      <HistoryContainer>
        <Radio.Group value={selectedId}>
          <HistoryItemWrapper
            onClick={() => onChangeSelectedId(selectNoneId)}
            selected={selectedId === selectNoneId}
          >
            <Flex gap={8} align="center">
              <Radio value={selectNoneId} />
              <Typography.Text color="SLATE_GRAY70" bold>
                선택 안함
              </Typography.Text>
            </Flex>
          </HistoryItemWrapper>
          <Flex gap={16} dir="column" align="stretch" gutter={{ top: 16 }}>
            {records?.map(
              ({ recordDate, name, qcqaDocumentRecordId, attaches }) => (
                <HistoryItemWrapper
                  selected={selectedId === qcqaDocumentRecordId}
                  key={qcqaDocumentRecordId}
                  onClick={() => onChangeSelectedId(qcqaDocumentRecordId)}
                >
                  <Flex align="center" gap={8}>
                    <Radio value={qcqaDocumentRecordId} />
                    <Typography.Text color="SLATE_GRAY60" inline bold>
                      {recordDate}
                    </Typography.Text>
                    <Typography.Text
                      color="SLATE_GRAY70"
                      gutter={{ left: 4 }}
                      inline
                      bold
                    >
                      {name}
                    </Typography.Text>
                  </Flex>
                  {attaches && (
                    <Flex
                      dir="column"
                      align="stretch"
                      gap={8}
                      gutter={{ top: 16 }}
                      onClick={(e) => e.stopPropagation()}
                      style={{ cursor: 'auto' }}
                    >
                      {attaches?.map(
                        ({ filename, registerDt, uploadFileUrl, attachId }) => (
                          <AttachWrapper gap={24} align="center" key={attachId}>
                            <Typography.Text
                              type="SMALL"
                              color="SLATE_GRAY70"
                              medium
                              style={{ flex: 1 }}
                            >
                              {filename}
                            </Typography.Text>
                            <Typography.Text type="SMALL" color="GRAY50" medium>
                              {registerDt.slice(0, 10)}
                            </Typography.Text>
                            <Icon
                              name="download"
                              size={18}
                              color="GRAY60"
                              style={{ flex: '0 0 18px', cursor: 'pointer' }}
                              onClick={() => {
                                downloadFile(uploadFileUrl, filename).then(
                                  () => {
                                    message.success(
                                      '정상적으로 다운로드되었습니다.',
                                    );
                                  },
                                );
                              }}
                            />
                          </AttachWrapper>
                        ),
                      )}
                    </Flex>
                  )}
                </HistoryItemWrapper>
              ),
            )}
          </Flex>
        </Radio.Group>
      </HistoryContainer>
    </Modal>
  );
};

const QCQADocsHistoryModal = (props: QCQADocsHistoryModalProps) => {
  const [qcqaDocumentRecordId, setQCQADocumentRecordId] = useState<number>(
    props.initialQCQADocumentRecordId,
  );

  return (
    <QCQADocsHistoryModalUI
      {...props}
      onComplete={props.onComplete}
      selectedId={qcqaDocumentRecordId}
      onChangeSelectedId={setQCQADocumentRecordId}
    />
  );
};

export default QCQADocsHistoryModal;
