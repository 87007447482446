import { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

import SectionA from 'components/common/Intro/SectionA';
import SectionB from 'components/common/Intro/SectionB';
import SectionC from 'components/common/Intro/SectionC';
import SectionD from 'components/common/Intro/SectionD';
import SectionE from 'components/common/Intro/SectionE';
import SectionF from 'components/common/Intro/SectionF';
import SectionG from 'components/common/Intro/SectionG';
import SectionH from 'components/common/Intro/SectionH';
import SectionI from 'components/common/Intro/SectionI';
import SectionJ from 'components/common/Intro/SectionJ';
import SectionK from 'components/common/Intro/SectionK';
import SectionL from 'components/common/Intro/SectionL';
import { deviceSize } from 'lib/styles';
import { useModal } from 'hook/useModal';

const Container = styled.div`
  width: 100%;
  max-width: none !important;
  margin: -80px auto !important;
  overflow-x: hidden;
  * {
    font-family: 'Spoqa Han Sans Neo', 'Roboto', sans-serif !important;
  }

  @media ${deviceSize.sm} {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
`;

const IntroPage = () => {
  const { openStibeePopUpModal } = useModal();

  useEffect(() => {
    const expireDate = window.localStorage.getItem('ST_POP');
    let today = new Date();
    if (!expireDate || Number(today.getTime()) > Number(expireDate)) {
      openStibeePopUpModal({});
    }
    if (expireDate === 'Infinity') {
      return;
    }
  }, []);

  useLayoutEffect(() => {
    const layoutElement: HTMLElement | null = document.querySelector(
      'section.ant-layout',
    );

    if (layoutElement) {
      layoutElement.style.overflow = 'visible';
    }
  }, []);

  return (
    <Container>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <SectionF />
      <SectionG />
      <SectionH />
      <SectionI />
      <SectionJ />
      <SectionK />
      <SectionL />
    </Container>
  );
};

export default IntroPage;
