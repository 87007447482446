import { Button, Col, Row } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import {
  useVCRPAccount,
  useProductVCRPAccount,
} from 'service/brand/product/vcrp';
import VCRPAccountAddModal from 'components/modal/product/VCRPAccountAddModal';
import { VCRPAccountParam } from 'types/brand/vcrp';
import { DocumentCode } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const ProductVCRPAccountContainer = styled.div`
  .ant-table-content {
    th:first-child,
    td:first-child {
      padding-left: 36px;
    }
  }
`;

const VCRPPasswordContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${palette.GRAY30};
  border-radius: 4px;
`;

const ProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { vcrpAccount, getVCRPAccountLoading } = useVCRPAccount();
  const {
    addProductVCRPAccount,
    getProductVCRPAccountLoading,
    readOnlyMode,
    updateMode,
  } = useProductVCRPAccount({
    productId,
    countryId,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns: ColumnsType<VCRPAccountParam> = useMemo(
    () => [
      {
        title: '사용자명 (ID)',
        width: 360,
        align: 'left',
        dataIndex: 'vcrpAccount',
      },
      {
        title: '비밀번호',
        align: 'left',
        width:
          vcrpAccount?.vcrpPassword && vcrpAccount.vcrpPassword.length > 15
            ? 270
            : 150,
        render: (_, { vcrpPassword }) => (
          <VCRPPasswordContainer>
            {isPasswordVisible ? vcrpPassword : '*'.repeat(vcrpPassword.length)}
          </VCRPPasswordContainer>
        ),
      },
      {
        align: 'left',
        render: () => (
          <>
            {isPasswordVisible ? (
              <IconWrapper
                onClick={() => setIsPasswordVisible(false)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="show" size={14} color="GRAY60" />
              </IconWrapper>
            ) : (
              <IconWrapper
                onClick={() => setIsPasswordVisible(true)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="hide" size={14} color="GRAY60" />
              </IconWrapper>
            )}
          </>
        ),
      },
    ],
    [vcrpAccount, isPasswordVisible],
  );

  return (
    <ProductVCRPAccountContainer>
      <VCRPAccountAddModal
        vcrpAccount={vcrpAccount}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.VCRPAR}
          />
        </Col>
      </Row>
      <Table
        rowKey="vcrpAccountId"
        columns={columns}
        dataSource={vcrpAccount !== null ? [vcrpAccount] : []}
        className="secondary"
        loading={getVCRPAccountLoading}
        pagination={false}
      />
      <Row justify="end" style={{ marginTop: 8 }}>
        <Col>
          <Button onClick={() => setIsModalVisible(true)}>
            {vcrpAccount === null ? '계정 정보 입력' : '계정 정보 변경'}
          </Button>
        </Col>
      </Row>
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          {updateMode && (
            <CorrectRequestWithoutChangeButton
              documentCode={DocumentCode.VCRPAR}
            />
          )}
          <Button
            type="primary"
            loading={getProductVCRPAccountLoading}
            onClick={() => addProductVCRPAccount()}
          >
            {!updateMode ? '등록' : '보완 완료'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductVCRPAccountContainer>
  );
};

export default ProductVCRPAccount;
