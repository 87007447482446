import { useParams } from 'react-router-dom';

import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';
import NMPAESD from 'components/material/nmpa/NMPAESD';

const notices = [
  '추가 제출을 원하시는 자료 파일을 업로드해 주세요.',
  '자료는 반드시 PDF 파일이어야 합니다.',
];

const NMPAESDPage = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);

  return (
    <NMPAPageTemplate
      title="원료 신고 정보"
      documentName="기타 제출 자료"
      notices={notices}
    >
      <NMPAESD materialId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPAESDPage;
