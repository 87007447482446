import { Checkbox, Form, FormInstance, message, Radio } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { CSSProperties, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import { requireRule } from 'lib/validate';
import {
  NMPABATAttribute,
  NMPABATCategory,
  NMPABATForm,
} from 'types/material/nmpa/nmpa';

const CheckboxContainer = styled(Flex)`
  padding: 24px 16px;
  background: #f5f8fb;
  border-radius: 4px;

  .ant-checkbox-wrapper {
    font-size: 16px;
  }
`;

const WhiteBox = styled(Flex)<{
  errorPosition?: { top: number; left: number };
}>`
  border-radius: 4px;
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 16px 24px;

  .ant-form-item {
    margin-bottom: 0;
    flex: 1;
  }

  .ant-form-item-explain {
    position: absolute;

    ${({ errorPosition }) =>
      errorPosition
        ? css`
            top: ${errorPosition.top}px;
            left: ${errorPosition.left}px;
          `
        : css`
            top: -40px;
            left: -20px;
          `};
  }

  .ant-form-item-explain.ant-form-item-explain-success {
    display: none;
  }

  .ant-radio-group.ant-radio-group-outline,
  .ant-radio-wrapper {
    gap: 80px;
    justify-content: space-between;
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    cursor: not-allowed;
  }

  .ant-checkbox + span {
    padding-right: 4px;
  }

  .ant-checkbox-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    align-items: flex-start;
    row-gap: 8px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    margin-left: 0;

    .ant-checkbox.ant-checkbox-checked {
      height: 16px;
    }

    span:nth-child(2) {
      line-height: 24px;
    }

    .ant-checkbox {
      margin-top: 5px;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: pointer !important;

      * {
        cursor: pointer !important;
      }

      .ant-checkbox-inner {
        background-color: #fff;
      }

      &:hover .ant-checkbox-inner {
        border-color: #3785f7 !important;
      }
    `}
`;

const DivForMessage = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 4px;
  cursor: pointer;
  z-index: 999;
`;

// 구조가 명확한 단일 화합물
const singleCompundCode = 'NMPA_MAIN_INGREDIENT_BASIC_FEATURE001';
// 혼합물(폴리머 미함유)
const mixtureCode = 'NMPA_MAIN_INGREDIENT_BASIC_FEATURE002';
// 물리적 파쇄/압착/분리 공정
const physicalProcessCode = 'NMPA_RAW_MATERIAL_PRODUCTION_METHOD003';
// 물리적 혼합
const physicalMixCode = 'NMPA_RAW_MATERIAL_PRODUCTION_METHOD004';
// 단일 성분 원료
const singleRawMaterialCode = 'NMPA_RAW_MATERIAL_MIXING_REASON007';

const AttributeCheckbox = ({
  nmpaBATCategory,
  readOnly,
  form,
}: {
  nmpaBATCategory: NMPABATCategory[];
  readOnly: boolean;
  form: FormInstance<NMPABATForm>;
}) => {
  const [value, setValue] = useState<number>();

  const { mixingReasonCategories, basicFeatureCategories } = useMemo(
    () => ({
      basicFeatureCategories: nmpaBATCategory.filter(
        (item) =>
          item.type === NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE,
      ),
      mixingReasonCategories: nmpaBATCategory.filter(
        (item) =>
          item.type === NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON,
      ),
    }),
    [nmpaBATCategory],
  );

  const singleCompoundId = basicFeatureCategories.find(
    (item) => item.code === singleCompundCode,
  )?.materialCategoryId;

  const mixtureId = basicFeatureCategories.find(
    (item) => item.code === mixtureCode,
  )?.materialCategoryId;

  const handleChangeRadio = (code: string) => {
    const isSingleCompound = code === singleCompundCode;

    if (isSingleCompound) {
      const singleAttribute = mixingReasonCategories.find(
        ({ code }) => code === 'NMPA_RAW_MATERIAL_MIXING_REASON007',
      )!;

      form.setFieldsValue({
        [NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON]: [
          singleAttribute.materialCategoryId,
        ],
      });

      message.info(
        <Typography.Text>
          원료 배합/ 혼합하는 경우 그 사유 -{' '}
          <Typography.Text color="PRIMARY50" inline>
            “단일 성분원료”
          </Typography.Text>{' '}
          자동 선택되었습니다.
        </Typography.Text>,
      );
    } else {
      form.setFieldsValue({
        [NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON]: [],
      });
    }
  };

  return (
    <CheckboxContainer dir="column" gap={24}>
      <Flex dir="column" gap={8} style={{ width: '100%' }}>
        <Flex align="center">
          <Typography.Text
            asterisk
            medium
            gutter={{
              bottom:
                form.getFieldError(
                  NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE,
                ).length > 0
                  ? 15
                  : 0,
            }}
          >
            주성분 기본 기능
          </Typography.Text>
          <Tip
            style={{
              display: 'inline-block',
              marginLeft: 4,
              zIndex: 1,
            }}
            trigger="click"
            closeIconStyle={{
              fontSize: 16,
            }}
            bodyStyle={{
              width: 304,
              whiteSpace: 'pre-line',
            }}
          >
            <Typography.Text inline type="SMALL">
              원료 품질을 위한 소량의 불순물, 방부제, 산화방지
              <br />제 등의 성분은 제외합니다.
            </Typography.Text>
          </Tip>
        </Flex>
        <WhiteBox
          align="center"
          justify="space-between"
          style={{ height: 56, padding: '16px 64px' }}
          errorPosition={{ top: -30, left: -62 }}
        >
          <Form.Item
            name={NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE}
            rules={[requireRule]}
          >
            <Radio.Group
              value={value}
              disabled={readOnly}
              onChange={(e) => setValue(e.target.value)}
            >
              {basicFeatureCategories.map(
                ({ code, nameKo, materialCategoryId }) => (
                  <div key={code}>
                    <Radio
                      value={materialCategoryId}
                      onChange={() => handleChangeRadio(code)}
                    >
                      {nameKo}
                    </Radio>
                  </div>
                ),
              )}
            </Radio.Group>
          </Form.Item>
        </WhiteBox>
      </Flex>
      <CheckboxSection
        title="원료의 출처 (중복 가능)"
        formName={NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE}
        isRequire={true}
        readOnly={readOnly}
        categories={nmpaBATCategory.filter(
          (item) => item.type === NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE,
        )}
        checkboxGroupStyle={{ columnGap: 24 }}
        checkboxStyle={{ flex: '1 1 calc(33% - 21px)' }}
        tipBodyStyle={{
          width: 304,
          whiteSpace: 'pre-line',
        }}
      />
      <CheckboxSection
        title="원료 생산 방법 (중복 가능)"
        formName={NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD}
        isRequire={true}
        readOnly={readOnly}
        categories={nmpaBATCategory.filter(
          (item) =>
            item.type === NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD,
        )}
        checkboxGroupStyle={{ columnGap: 24 }}
        checkboxStyle={{ flex: '1 1 calc(33% - 21px)' }}
      />
      <CheckboxSection
        title="기타 다른 원료 특징 (중복 가능)"
        formName={NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE}
        readOnly={readOnly}
        categories={nmpaBATCategory.filter(
          (item) =>
            item.type === NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE,
        )}
        checkboxStyle={{ flex: '1 1 20%', justifyContent: 'center' }}
        contentStyle={{ height: 56 }}
      />
      <CheckboxSection
        title="원료 배합/ 혼합하는 경우 그 사유 (중복 가능)"
        isRequire={true}
        formName={NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON}
        readOnly={readOnly}
        categories={mixingReasonCategories}
        checkboxGroupStyle={{ columnGap: 120 }}
        checkboxStyle={{ flex: '1 1 calc(50% - 60px)' }}
        singleCompoundId={singleCompoundId}
        mixtureId={mixtureId}
      />
    </CheckboxContainer>
  );
};

const CheckboxSection = ({
  title,
  formName,
  isRequire,
  readOnly,
  categories,
  contentStyle,
  checkboxGroupStyle,
  checkboxStyle,
  tipBodyStyle,
  singleCompoundId,
  mixtureId,
}: {
  title: string;
  formName: NMPABATAttribute;
  isRequire?: true;
  readOnly: boolean;
  categories: NMPABATCategory[];
  contentStyle?: CSSProperties;
  checkboxGroupStyle?: CSSProperties;
  checkboxStyle: CSSProperties;
  tipBodyStyle?: CSSProperties;
  singleCompoundId?: number;
  mixtureId?: number;
}) => {
  const isMixingReason =
    formName === NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON;
  const isProductionMethod =
    formName === NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD;

  const handleClickCheckbox = ({
    e,
    isDisabled,
    disableMessage,
  }: {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    isDisabled: boolean;
    disableMessage: string;
  }) => {
    if (isDisabled) {
      e.preventDefault();
      message.warning(disableMessage);
    }
  };

  const getDisableInfo = ({
    code,
    getFieldValue,
  }: {
    code: string;
    getFieldValue: (name: NamePath) => any;
  }) => {
    let disableMessage = '';
    /**
     * 원료 배합/혼합하는 경우 그 사유
     * 구조가 명확한 단일 화합물 -> 단일 성분 원료만 선택 가능
     * 혼합물(폴리머 미함유) -> 단일 성분 원료 제외 선택 가능
     */
    if (isMixingReason) {
      const basicFeatureValue = getFieldValue(
        NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE,
      );

      if (
        code !== singleRawMaterialCode &&
        basicFeatureValue === singleCompoundId
      ) {
        disableMessage =
          '“구조가 명확한 단일 화합물” 이므로 선택할 수 없습니다.';
      } else if (
        code === singleRawMaterialCode &&
        basicFeatureValue === mixtureId
      ) {
        disableMessage = '“혼합물(폴리머 미함유)” 이므로 선택할 수 없습니다.';
      }
    }

    /**
     * 원료 생산 방법
     * 물리적 파쇄/압착/분리 공정 선택 시 다른 항목 선택 불가
     * 물리적 혼합 선택 시 다른 항목 선택 불가
     * 다른 항목 선택시 물리적 파쇄/압착/분리 공정
     */
    if (isProductionMethod) {
      const productionMethodValue = getFieldValue(
        NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD,
      );

      const physicalProcessId = categories.find(
        (item) => item.code === physicalProcessCode,
      )?.materialCategoryId;

      const physicalMixId = categories.find(
        (item) => item.code === physicalMixCode,
      )?.materialCategoryId;

      if (productionMethodValue?.length > 0) {
        const isIncludePhysicalProcess = productionMethodValue.includes(
          physicalProcessId,
        );
        const isIncludePhysicalMix = productionMethodValue.includes(
          physicalMixId,
        );
        if (
          (isIncludePhysicalProcess && code !== physicalProcessCode) ||
          (!isIncludePhysicalProcess &&
            !isIncludePhysicalMix &&
            code === physicalProcessCode)
        ) {
          disableMessage =
            '“물리적 파쇄/압착/분리 공정”은 다른 공정과 중복 선택할 수 없습니다.';
        }

        if (
          (isIncludePhysicalMix && code !== physicalMixCode) ||
          (!isIncludePhysicalMix &&
            !isIncludePhysicalProcess &&
            code === physicalMixCode)
        ) {
          disableMessage =
            '“물리적 혼합”은 다른 공정과 중복 선택할 수 없습니다.';
        }
      }
    }

    return {
      isDisabled: disableMessage ? true : false,
      disableMessage,
    };
  };

  return (
    <Flex dir="column" gap={8} style={{ width: '100%' }}>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => {
          return true;
        }}
      >
        {({ getFieldError, getFieldValue }) => {
          return (
            <>
              <Typography.Text
                asterisk={isRequire}
                medium
                gutter={{
                  bottom: getFieldError(formName).length > 0 ? 15 : 0,
                }}
              >
                {title}
              </Typography.Text>
              <WhiteBox
                align="center"
                justify="space-between"
                style={contentStyle}
              >
                <Form.Item name={formName} rules={isRequire && [requireRule]}>
                  <Checkbox.Group
                    disabled={readOnly}
                    style={{ ...checkboxGroupStyle }}
                  >
                    {categories.map(
                      ({ code, nameKo, description, materialCategoryId }) => {
                        const { isDisabled, disableMessage } = getDisableInfo({
                          code,
                          getFieldValue,
                        });

                        return (
                          <Flex
                            key={code}
                            align="center"
                            style={{ ...checkboxStyle, position: 'relative' }}
                            onClick={(e) =>
                              handleClickCheckbox({
                                e,
                                isDisabled,
                                disableMessage,
                              })
                            }
                          >
                            {isDisabled && <DivForMessage />}
                            <StyledCheckbox
                              disabled={isDisabled}
                              isDisabled={!readOnly && isDisabled}
                              value={materialCategoryId}
                              style={{
                                lineHeight: '32px',
                              }}
                            >
                              {nameKo}{' '}
                            </StyledCheckbox>
                            {description && (
                              <Tip
                                style={{
                                  display: 'inline-block',
                                }}
                                trigger="click"
                                closeIconStyle={{
                                  fontSize: 16,
                                }}
                                bodyStyle={tipBodyStyle}
                              >
                                <Typography.Text inline type="SMALL">
                                  {description.replace('.', '.\n')}
                                </Typography.Text>
                              </Tip>
                            )}
                          </Flex>
                        );
                      },
                    )}
                  </Checkbox.Group>
                </Form.Item>
              </WhiteBox>
            </>
          );
        }}
      </Form.Item>
    </Flex>
  );
};

export default AttributeCheckbox;
