import { fabric } from 'fabric';

import { ProductArtworkScreeningCorrections } from 'types/brand/product/artworkScreening';

export const defaultFontFamily = 'Pretendard, Noto Sans KR, Roboto, sans-serif';

export function createCanvas(canvas: HTMLCanvasElement) {
  fabric.Group.prototype.hasControls = false;
  const fabricCanvas = new fabric.Canvas(canvas, {
    selection: false,
  });

  return fabricCanvas;
}

export function formatFabricData(
  screeningCorrections: ProductArtworkScreeningCorrections,
) {
  const fabricData = JSON.parse(
    screeningCorrections.image.drawingElements[0]?.elementData || '{}',
  );

  fabricData.backgroundImage = undefined;

  const hasCorrectionMessage = ({
    leftTopX,
    leftTopY,
    rightBottomX,
    rightBottomY,
  }: {
    leftTopX: number;
    leftTopY: number;
    rightBottomX: number;
    rightBottomY: number;
  }) => {
    return (
      screeningCorrections.image.splitImages.find(
        (splitImage) =>
          Math.abs(splitImage.leftTopPointX - leftTopX) < 1 &&
          Math.abs(splitImage.leftTopPointY - leftTopY) < 1 &&
          Math.abs(splitImage.rightBottomPointX - rightBottomX) < 1 &&
          Math.abs(splitImage.rightBottomPointY - rightBottomY) < 1 &&
          (((splitImage?.drawingElements || '').length > 0 &&
            JSON.parse(splitImage?.drawingElements[0]?.elementData || '')
              ?.objects?.length > 0) ||
            (splitImage?.imageCorrection?.correctRequestMessage || '').length >
              0),
      ) !== undefined
    );
  };

  if (fabricData.objects) {
    const objects = fabricData.objects;

    for (const object of objects) {
      object.selectable = false;
      object.hoverCursor = 'default';

      if (object.type === 'group') {
        if (
          typeof object.leftTopPointX !== 'number' ||
          typeof object.leftTopPointY !== 'number' ||
          typeof object.rightBottomPointX !== 'number' ||
          typeof object.rightBottomPointY !== 'number'
        )
          continue;

        if (
          hasCorrectionMessage({
            leftTopX: object.leftTopPointX,
            leftTopY: object.leftTopPointY,
            rightBottomX: object.rightBottomPointX,
            rightBottomY: object.rightBottomPointY,
          })
        ) {
          if (object.objects) {
            object.hoverCursor = 'pointer';

            for (const child of object.objects) {
              if (child.type === 'rect') {
                child.stroke = '#2CF4F0';
                child.fill = 'rgba(44, 244, 240, 0.3)';
                child.strokeDashArray = [22];
              } else {
                child.fontFamily = defaultFontFamily;
                child.fill = '#2CF4F0';
              }
            }
          }
        } else {
          if (object.objects) {
            for (const child of object.objects) {
              child.visible = false;
            }
          }
        }
      } else if (object.type === 'textbox') {
        object.fontFamily = defaultFontFamily;
      }
    }
  }

  return fabricData;
}
