import { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import FileUploadContainer from 'containers/file/FileUploadContainer';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import ArtworkCountryFileUpload, {
  ArtworkItem,
  FileItemProp,
} from 'components/file/ArtworkCountryFileUpload';
import { DocStatus, DocumentCode, ProductCountryArtwork } from 'types/product';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const ProductArtworkBlock = styled.div`
  max-width: 864px;
  margin: 32px auto 0 auto;
`;

const ProductArtwork = ({
  certificateMode,
  status,
  updateMode,
  readOnlyMode,
  artworks,
  savedArtworks,
  onUpload,
  fetchLoading,
  onDeleteArtwork,
  onSubmit,
  setArtworkRegisterData,
}: {
  setArtworkRegisterData: React.Dispatch<
    React.SetStateAction<Record<number, ArtworkItem>>
  >;
  certificateMode: boolean;
  status: DocStatus | undefined;
  updateMode: boolean;
  readOnlyMode: boolean;
  artworks: (File | FileItemProp)[];
  savedArtworks: ProductCountryArtwork[];
  onUpload: any;
  fetchLoading: boolean;
  onDeleteArtwork: any;
  onSubmit: () => void;
}) => {
  const buttonText = useMemo(() => {
    if (!updateMode) return '등록';
    else if (!certificateMode) return '수정';
    else if (status === DocStatus.CHK) return '확인 완료';

    // MOD
    return '보완 완료';
  }, [updateMode, certificateMode, status]);

  return (
    <>
      <ProductArtworkBlock>
        {certificateMode ? (
          <FileUploadContainer
            files={artworks}
            readOnly={readOnlyMode}
            onUpload={onUpload}
            onDelete={onDeleteArtwork}
          />
        ) : (
          <ArtworkCountryFileUpload
            onUpload={onUpload}
            onDelete={onDeleteArtwork}
            artworks={artworks}
            savedArtworks={savedArtworks}
            setArtworkRegisterData={setArtworkRegisterData}
          />
        )}
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            {buttonText === '보완 완료' && (
              <CorrectRequestWithoutChangeButton
                documentCode={DocumentCode.ART}
              />
            )}
            <Button type="primary" loading={fetchLoading} onClick={onSubmit}>
              {buttonText}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </ProductArtworkBlock>
    </>
  );
};

export default ProductArtwork;
