import { FocusEvent, MouseEvent, useRef } from 'react';
import { Form, Input, Select } from 'antd';
import styled from 'styled-components';
import { Rule } from 'antd/lib/form';

import * as S from '../../../components/product/physicalProperty/Styled';

interface SelectOptionInputProps {
  parentFormName: string;
  rules: Rule[];
  selectPlaceholder: string;
  selectInputPlaceholder?: string;
  selectDisabled: boolean;
  inputDisabled: boolean;
  unitDirectValue?: string | null;
  selectClassName?: string;
  children: React.ReactNode;
  onSelect: () => void;
  onInputChange: any;
  isOthers: boolean;
  inputFormName: string;
  style?: React.CSSProperties;
  selectInputStyle?: React.CSSProperties;
}

const SelectOptionWrapper = styled(S.AntdExplainWrapper)`
  margin-bottom: -10px;

  .ant-form-item-explain-error {
    position: absolute;
    bottom: -24%;
    margin-left: -120px;
  }
`;

const SelectOptionInput = ({
  parentFormName,
  rules,
  selectPlaceholder,
  selectInputPlaceholder = '단위 직접 입력',
  selectDisabled,
  selectClassName,
  inputDisabled,
  children,
  onSelect,
  onInputChange,
  unitDirectValue,
  isOthers,
  inputFormName,
  style = {
    width: 160,
    position: 'relative',
    left: '14%',
    margin: -10,
  },
  selectInputStyle,
}: SelectOptionInputProps) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<Input>(null);

  const input = rowRef.current?.getElementsByClassName(
    'ant-select-selector',
  ) as HTMLCollectionOf<HTMLElement>;

  const focusUnitSelect = () => {
    const row = rowRef.current;
    if (!row) return;

    const selectDiv = row.querySelector<HTMLDivElement>('.ant-select');
    if (!selectDiv) return;

    selectDiv.classList.add('ant-select-focused');
  };

  const blurUnitSelect = (
    e: FocusEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>,
  ) => {
    if (e.target === document.activeElement) return;

    const row = rowRef.current;
    if (!row) return;

    const selectDiv = row.querySelector<HTMLDivElement>('.ant-select');
    if (!selectDiv) return;

    selectDiv.classList.remove('ant-select-focused');
  };

  const drawBorderDirectInput = (value: string) => {
    if (!input) return;
    if (value === '') {
      input[0].style.border = '1px solid #ff4d4f';
    } else {
      input[0].style.border = '1px solid #d9d9d9';
    }
  };

  const drawBorderSelectOption = (value: any) => {
    if (!input) return;
    if (value === 'others' && unitDirectValue === '') {
      input[0].style.border = '1px solid #ff4d4f';
    }
    if (value !== 'others') {
      input[0].style.border = '1px solid #d9d9d9';
    }
  };

  return (
    <S.StyledRow ref={rowRef}>
      <Form.Item name={parentFormName} rules={rules}>
        <Select
          disabled={selectDisabled}
          style={style}
          placeholder={selectPlaceholder}
          onSelect={(value, _) => {
            onSelect();
            drawBorderSelectOption(value);

            if (value === 'others') {
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 0);
            }
          }}
        >
          {children}
        </Select>
      </Form.Item>
      {isOthers && (
        <SelectOptionWrapper className={selectClassName}>
          <Form.Item name={inputFormName} rules={rules}>
            <S.SelectInput
              ref={inputRef}
              id="select_input"
              style={selectInputStyle}
              placeholder={selectInputPlaceholder}
              disabled={inputDisabled}
              onChange={(e) => {
                onInputChange(e);
                drawBorderDirectInput(e?.target?.value);
              }}
              onMouseUp={focusUnitSelect}
              onFocus={focusUnitSelect}
              onBlur={(e) => {
                blurUnitSelect(e);
                drawBorderDirectInput(e?.target?.value);
              }}
              onMouseEnter={focusUnitSelect}
              onMouseLeave={blurUnitSelect}
              autoComplete="off"
            />
          </Form.Item>
        </SelectOptionWrapper>
      )}
    </S.StyledRow>
  );
};

export default SelectOptionInput;
