import { Col, Row, Select } from 'antd';
import styled from 'styled-components';
import { useMemo, useState } from 'react';

import Typography from 'components/system/general/Typography';
import { useProducts } from 'service/manufacturer/product';
import { ProductSearchType, ProductStatus } from 'types/manufacturer/product';
import { SearchInput } from 'components/system';
import Table from 'components/ui/Table/Table';

const searchTypeOptions = [
  { label: '전체', value: ProductSearchType.ALL },
  { label: '자체 품목명', value: ProductSearchType.MANUFACTURER_ITEM_NAME_KO },
  { label: '브랜드사', value: ProductSearchType.BRAND_COMPANY_NAME_KO },
  { label: '랩넘버', value: ProductSearchType.SAMPLE_LAB_NO },
];

const ProductReadyListBlock = styled.div`
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    padding-left: 20px;
  }

  .ant-table-wrapper .ant-table-container table > tbody > tr > td:last-child {
    padding-right: 16px;
  }
`;

export const ProductReadyList = () => {
  const columns: any = useMemo(
    () => [
      {
        title: '자체 품목명 (용량)',
        width: '50%',
        render: (product: any) => (
          <Typography.Text
            type="BODY_2"
            medium
            color="GRAY90"
            style={{ cursor: 'pointer', textAlign: 'left', paddingLeft: 16 }}
            onClick={() => showProductDetail(product)}
          >
            {`${product.manufacturerItemNameKo} ${
              product.netWeight
                ? `(${product.netWeight} ${product.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '브랜드사',
        width: '15%',
        dataIndex: 'brandCompanyNameKo',
        align: 'center',
      },
      {
        title: '샘플 랩넘버',
        width: '15%',
        align: 'center',
        render: ({ productSampleLabNo }: any) => productSampleLabNo ?? '-',
      },
      {
        title: '최근 수정 일시',
        width: '20%',
        align: 'center',
        render: ({ updateDt }: any) =>
          updateDt ? updateDt.replace('T', ' ') : '-',
      },
    ],
    [],
  );
  const {
    page,
    setPage,
    searchType,
    setSearchType,
    searchKeyword,
    setSearchKeyword,
    products,
    totalElements,
    getLoading,
    showProductDetail,
  } = useProducts(ProductStatus.PRD_RDY);
  const [searchTypeBuffer, setSearchTypeBuffer] = useState(searchType);
  const [searchKeywordBuffer, setSearchKeywordBuffer] = useState(searchKeyword);
  const handleSearch = () => {
    setPage(1);
    setSearchType(searchTypeBuffer);
    setSearchKeyword(searchKeywordBuffer);
  };
  return (
    <ProductReadyListBlock>
      <Row gutter={8} justify="end">
        <Col>
          <Select
            value={searchTypeBuffer}
            options={searchTypeOptions}
            style={{ width: 164 }}
            onChange={(searchType) => setSearchTypeBuffer(searchType)}
          />
        </Col>
        <Col>
          <SearchInput
            defaultValue={searchKeywordBuffer}
            onChange={(e) => setSearchKeywordBuffer(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={products}
        rowKey="productId"
        loading={getLoading}
        pagination={{
          total: totalElements,
          pageSize: 10,
          current: page,
          onChange: setPage,
        }}
        style={{ marginTop: 8 }}
      />
    </ProductReadyListBlock>
  );
};
