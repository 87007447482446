import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  CosmeticCategory,
  ProductCategoryAddParams,
  ProductCategory,
  ThirdCategoryType,
} from 'types/product';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { Typography } from 'components/system';
import path from 'lib/path';
import history from 'lib/history';
import { manuSetNextPathname, setNextPathname } from 'modules/product';
import { useRetailerMode } from 'hook/company';
import { CompanyType } from 'types/company';
import useGA, { EGAActionType } from 'hook/useGA';

export const useProductCategory = (productId: number) => {
  const { sendEventToGA } = useGA();

  const {
    data: cosmeticCategories = [],
    isFetching: isLoadingCosmeticCategories,
  } = useQuery(
    ['cosmetic-categories'],
    () =>
      client.get<APIResponse<CosmeticCategory[]>>('/v2/cosmetic-categories'),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const {
    data: productCategory = null,
    isFetching: isLoadingProductCategory,
    refetch,
  } = useQuery(
    ['product-category', productId],
    () =>
      client.get<APIResponse<ProductCategory>>(
        `/product-category/${productId}`,
      ),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const [selectedFirstCategoryId, setSelectedFirstCategoryId] = useState<
    number | null
  >(null);
  const [selectedSecondCategoryId, setSelectedSecondCategoryId] = useState<
    number | null
  >(null);
  const [
    selectedThirdCategory,
    setSelectedThirdCategory,
  ] = useState<ThirdCategoryType | null>(null);
  const [selectedDetailCategoryId, setSelectedDetailCategoryId] = useState<
    number | null
  >(null);
  const firstCategories = useMemo(
    () => cosmeticCategories.filter(({ parentId }) => parentId === null),
    [cosmeticCategories],
  );
  const secondCategories = useMemo(
    () =>
      cosmeticCategories.find(
        (item) => item.cosmeticCategoryId === selectedFirstCategoryId,
      )?.children || [],
    [cosmeticCategories, selectedFirstCategoryId],
  );

  const detailCategories = useMemo(() => {
    if (selectedThirdCategory === null) return [];

    const target = secondCategories.find(
      (item) => item.cosmeticCategoryId === selectedSecondCategoryId,
    );

    return selectedThirdCategory === ThirdCategoryType.NORMAL
      ? target?.detailCategoriesOfCommon || []
      : target?.detailCategoriesOfFunctional || [];
  }, [secondCategories, selectedSecondCategoryId, selectedThirdCategory]);

  const categoryExample = useMemo(() => {
    if (selectedSecondCategoryId === null) return null;
    return secondCategories.find(
      ({ cosmeticCategoryId }) =>
        cosmeticCategoryId === selectedSecondCategoryId,
    )?.example;
  }, [selectedSecondCategoryId]);

  const {
    addProductCategory,
    addProductCategoryLoading,
  } = useAddProductCategory();
  const dispatch = useDispatch();
  const { manuMode, nextPathname, skipMode } = useSelector(
    ({ company, product }: any) => ({
      manuMode: company.company.companyType === CompanyType.MANUFACTURE,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
    }),
  );

  const retailerMode = useRetailerMode();
  const submitCategory = () => {
    if (selectedFirstCategoryId === null)
      return message.warning('대분류를 선택해 주세요.');
    if (selectedSecondCategoryId === null)
      return message.warning('중분류를 선택해 주세요.');
    if (selectedThirdCategory === null)
      return message.warning('소분류를 선택해 주세요.');
    if (detailCategories.length > 0 && selectedDetailCategoryId === null)
      return message.warning('상세분류를 선택해 주세요.');

    Modal.confirm({
      icon: null,
      content: (
        <Typography.Text type="BODY_2" style={{ textAlign: 'center' }}>
          한번 등록한 카테고리는 수정할 수 없습니다.
          <br /> 또한, 선택하신 카테고리에 따라 [인증 신청]의 셀프 견적과
          <br /> [유통 플랫폼]의 국내 입점 서류 리스트가 달라질 수 있습니다.
          <br />
          <br />
          카테고리를 이대로 등록하시겠습니까?
        </Typography.Text>
      ),
      okText: '등록',
      onOk: () => {
        addProductCategory(
          {
            productId,
            cosmeticCategoryIdDepth1: selectedFirstCategoryId,
            cosmeticCategoryIdDepth2: selectedSecondCategoryId,
            isFunctional:
              selectedThirdCategory === ThirdCategoryType.FUNCTIONAL,
            ...(detailCategories.length > 0 &&
              selectedDetailCategoryId && {
                functionalCategoryId: selectedDetailCategoryId,
              }),
          },
          {
            // HINT : isRetailer가 아닐 경우 react-query이전 인증 등록 제품 flow를 타야 하므로
            // moveToNextPathnameSaga를 대체해야 함.
            onSuccess: () => {
              sendEventToGA({
                documentName: '카테고리',
                actionType: EGAActionType.REGISTER,
              });
              if (retailerMode) {
                message.success('제품이 등록되었습니다.');
                history.replace(path.retailer.retailerApply.product.list);
              } else {
                if (skipMode) {
                  history.replace(`/product/${productId}/country`);
                  if (!manuMode) {
                    dispatch(setNextPathname(nextPathname));
                  } else {
                    dispatch(manuSetNextPathname(nextPathname));
                  }
                } else {
                  history.goBack();
                  message.success('입력되었습니다.');
                }
              }
              refetch();
            },
          },
        );
      },
    });
  };

  return useMemo(
    () => ({
      productCategory,
      firstCategories,
      secondCategories,
      detailCategories,
      selectedFirstCategoryId,
      setSelectedFirstCategoryId,
      selectedSecondCategoryId,
      setSelectedSecondCategoryId,
      selectedThirdCategory,
      setSelectedThirdCategory,
      selectedDetailCategoryId,
      setSelectedDetailCategoryId,
      getCategoryLoading:
        isLoadingCosmeticCategories || isLoadingProductCategory,
      categoryExample,
      addProductCategoryLoading,
      onSubmit: submitCategory,
    }),
    [
      productCategory,
      firstCategories,
      secondCategories,
      selectedFirstCategoryId,
      setSelectedFirstCategoryId,
      selectedSecondCategoryId,
      setSelectedSecondCategoryId,
      selectedThirdCategory,
      setSelectedThirdCategory,
      selectedDetailCategoryId,
      setSelectedDetailCategoryId,
      isLoadingCosmeticCategories,
      categoryExample,
      addProductCategoryLoading,
      submitCategory,
    ],
  );
};

export const useAddProductCategory = () => {
  const {
    mutate: addProductCategory,
    isLoading: addProductCategoryLoading,
  } = useMutation((productCategoryAddParams: ProductCategoryAddParams) =>
    client.post('/product-category', productCategoryAddParams),
  );

  return useMemo(() => ({ addProductCategory, addProductCategoryLoading }), [
    addProductCategory,
    addProductCategoryLoading,
  ]);
};
