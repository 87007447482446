import { useMemo, useState } from 'react';
import { Button, Col, message, Modal, Row, Select, Space } from 'antd';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

import Typography from 'components/system/general/Typography';
import { useProducts } from 'service/manufacturer/product';
import palette from 'lib/styles/palette';
import { SearchInput } from 'components/system';
import { ProductSearchType } from 'types/manufacturer/product';
import { ProductStatus } from 'types/manufacturer/product';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const ProductListBlock = styled.div`
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    padding-left: 20px;
  }

  .ant-table-wrapper .ant-table-container table > tbody > tr > td:last-child {
    padding-right: 16px;
  }
`;
const StyledSpace = styled(Space)`
  cursor: pointer;

  &:hover {
    color: ${palette.PRIMARY50};
  }
`;

const searchTypeOptions = [
  { label: '전체', value: ProductSearchType.ALL },
  { label: '자체 품목명', value: ProductSearchType.MANUFACTURER_ITEM_NAME_KO },
  { label: '브랜드사', value: ProductSearchType.BRAND_COMPANY_NAME_KO },
  { label: '랩넘버', value: ProductSearchType.SAMPLE_LAB_NO },
];

export const ProductList = () => {
  const {
    page,
    setPage,
    searchType,
    setSearchType,
    searchKeyword,
    setSearchKeyword,
    products,
    totalElements,
    getLoading,
    showProductDetail,
    issueAuthCode,
    issueAuthCodeLoading,
  } = useProducts(ProductStatus.PRD_REG);
  const handleIssueAuthCode = (productId: number) => {
    Modal.confirm({
      icon: null,
      width: 400,
      content: (
        <Typography.Text type="BODY_2" align="center">
          코드 발행하시면 랩넘버의 변경이 불가능합니다.
          <br />
          브랜드사로 전달하기 위한 코드 발행을 하시겠습니까?
        </Typography.Text>
      ),
      okText: '네',
      onOk: () => {
        issueAuthCode(productId);
      },
    });
  };
  const columns: any = useMemo(
    () => [
      {
        title: '자체 품목명 (용량)',
        width: '50%',
        render: (product: any) => (
          <Typography.Text
            type="BODY_2"
            medium
            color="GRAY90"
            style={{ cursor: 'pointer', textAlign: 'left', paddingLeft: 16 }}
            onClick={() => showProductDetail(product)}
          >
            {`${product.manufacturerItemNameKo} ${
              product.netWeight
                ? `(${product.netWeight} ${product.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '브랜드사',
        width: '20%',
        dataIndex: 'brandCompanyNameKo',
        align: 'center',
      },
      {
        title: '샘플 랩넘버',
        width: '15%',
        align: 'center',
        render: ({ productSampleLabNo }: any) => productSampleLabNo ?? '-',
      },
      {
        title: '코드',
        width: '15%',
        align: 'center',
        render: ({ productId, authCode, manufacturerStatus }) => {
          if (authCode) {
            return (
              <CopyToClipboard
                text={authCode}
                onCopy={() => message.success('클립보드에 복사되었습니다.')}
              >
                <StyledSpace>
                  <Icon name="copy" color="PRIMARY50" size={18} />
                  <Typography.Text type="BODY_2">{authCode}</Typography.Text>
                </StyledSpace>
              </CopyToClipboard>
            );
          }
          if (manufacturerStatus === 'PRD-REG') {
            return (
              <Button
                size="small"
                onClick={() => handleIssueAuthCode(productId)}
                style={{ width: 80 }}
              >
                코드 발행
              </Button>
            );
          }
        },
      },
    ],
    [],
  );
  const [searchTypeBuffer, setSearchTypeBuffer] = useState(searchType);
  const [searchKeywordBuffer, setSearchKeywordBuffer] = useState(searchKeyword);
  const handleSearch = () => {
    setSearchType(searchTypeBuffer);
    setSearchKeyword(searchKeywordBuffer);
  };

  return (
    <ProductListBlock>
      <Row gutter={8} justify="end">
        <Col>
          <Select
            value={searchTypeBuffer}
            options={searchTypeOptions}
            style={{ width: 164 }}
            onChange={(searchType) => setSearchTypeBuffer(searchType)}
          />
        </Col>
        <Col>
          <SearchInput
            defaultValue={searchKeywordBuffer}
            onChange={(e) => setSearchKeywordBuffer(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={products}
        rowKey="productId"
        loading={getLoading || issueAuthCodeLoading}
        pagination={{
          total: totalElements,
          pageSize: 10,
          current: page,
          onChange: setPage,
        }}
        style={{ marginTop: 16 }}
      />
    </ProductListBlock>
  );
};
