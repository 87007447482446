import { Col, message, Modal, Radio, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback } from 'react';
import styled from 'styled-components';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import history from 'lib/history';
import path from 'lib/path';
import { useICIDRequestList } from 'service/material/icid/icid';
import {
  ICIDContractStatus,
  ICIDDepositStatus,
  ICIDRequest,
  ICIDRequestListPageState,
} from 'types/material/icid/icid';

const Container = styled.div`
  .ant-table-container table tr {
    td {
      height: 76px;
      padding: 4px;
    }

    th {
      height: 40px;
      line-height: 1;
    }

    th:first-child,
    td:first-child {
      padding-left: 16px;
    }
  }
`;

const ContractRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-left: 8px;
    border: 1px solid #d9d9d9;
    border-color: #d9d9d9 !important;
    border-radius: 4px;
    padding: 10px 16px;
    color: #4e5968 !important;
    font-weight: 500;
    height: auto;
    line-height: normal;

    &:first-child {
      margin-left: 0;
      border-right-color: #d9d9d9 !important;
    }
    &::before {
      content: none;
    }
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: none;
    padding: 11px 17px;
    background-color: #f1f4f6 !important;

    &:focus-within {
      box-shadow: none;
    }
  }
`;

const ICIDRequestList = ({
  initialPageState,
  onChangePageState,
}: {
  initialPageState: ICIDRequestListPageState;
  onChangePageState: (state: ICIDRequestListPageState) => void;
}) => {
  const { page, searchValue, isFinishedContract } = initialPageState;
  const {
    icidRequestList,
    total,
    isLoading,
    deleteICIDRequest,
  } = useICIDRequestList({
    isFinishedContract,
    tradeName: searchValue,
    page,
  });

  const handleDelete = ({
    materialIcidApplicationId,
    finishedContract,
  }: {
    materialIcidApplicationId: number;
    finishedContract: boolean;
  }) => {
    if (finishedContract) {
      message.error('계약이 완료된 건은 삭제할 수 없습니다.');
      return;
    }

    Modal.confirm({
      width: 400,
      bodyStyle: { textAlign: 'center' },
      content: (
        <div>
          등재 의뢰 리스트에서
          <br />
          원료를 삭제하시겠습니까?
          <br />
          입력하신 정보가 모두 사라지며
          <br />이 작업은 되돌릴 수 없습니다.
        </div>
      ),
      closable: true,
      icon: null,
      onOk: () => {
        deleteICIDRequest(materialIcidApplicationId);
      },
    });
  };

  const handleChangeContractStatus = useCallback(
    (isFinishedContract: ICIDContractStatus) => {
      onChangePageState({
        page: 1,
        searchValue,
        isFinishedContract,
      });
    },
    [page, searchValue],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        searchValue,
        isFinishedContract,
      });
    },
    [searchValue, isFinishedContract],
  );

  const handleSearch = useCallback(
    (searchValue: string) => {
      onChangePageState({
        page: 1,
        searchValue,
        isFinishedContract,
      });
    },
    [isFinishedContract],
  );

  const columns: ColumnsType<ICIDRequest> = [
    {
      title: '원료명(영문)',
      width: '20%',
      render: (_, { tradeName }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {tradeName}
        </Typography.Text>
      ),
    },
    {
      title: '카테고리',
      width: '28%',
      render: (_, { categoryName }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {categoryName}
        </Typography.Text>
      ),
    },
    {
      title: '등록 일시',
      align: 'center',
      width: '20%',
      render: (_, { registerDt }) =>
        `${registerDt.slice(0, 10)} ${registerDt.slice(11)}`,
    },
    {
      title: '계약',
      align: 'center',
      width: '10%',
      render: (_, { finishedContract, materialIcidApplicationId }) =>
        finishedContract ? (
          '계약 완료'
        ) : (
          <Tags.Mark
            type="arrow"
            color="red"
            onClick={() => {
              history.push(
                `${path.material.icid.root}/${materialIcidApplicationId}/contract`,
              );
            }}
          >
            계약하기
          </Tags.Mark>
        ),
    },
    {
      title: '진행 현황',
      align: 'center',
      width: '15%',
      render: (_, { finishedContract, depositStatus, receivedContract }) =>
        finishedContract ? (
          <Row
            style={{ flexDirection: 'column' }}
            align="middle"
            gutter={[4, 4]}
          >
            <Col>
              <Tags.Mark
                color={
                  depositStatus === ICIDDepositStatus.FINISH
                    ? 'green'
                    : 'lightGray'
                }
                style={{ width: 95 }}
              >
                {depositStatus === ICIDDepositStatus.FINISH
                  ? '입금 확인 완료'
                  : '입금 확인 중'}
              </Tags.Mark>
            </Col>
            <Col>
              <Tags.Mark
                color={
                  depositStatus === ICIDDepositStatus.FINISH
                    ? 'green'
                    : 'lightGray'
                }
                style={{ width: 95 }}
              >
                {receivedContract ? '계약서 수령 완료' : '계약서 수령 전'}
              </Tags.Mark>
            </Col>
          </Row>
        ) : (
          <Tags.Mark color="lightGray" style={{ width: 95 }}>
            계약 체결 전
          </Tags.Mark>
        ),
    },
    {
      align: 'center',
      width: '7%',
      render: (_, { materialIcidApplicationId, finishedContract }) => (
        <Icon
          name="delete"
          size={22}
          color={!finishedContract ? 'GRAY60' : 'GRAY30'}
          style={{ cursor: 'pointer', margin: '0 auto' }}
          onClick={() =>
            handleDelete({ materialIcidApplicationId, finishedContract })
          }
        />
      ),
    },
  ];

  return (
    <Container>
      <Flex justify="space-between">
        <ContractRadioGroup
          optionType="button"
          buttonStyle="solid"
          options={[
            { label: '전체', value: ICIDContractStatus.ALL },
            { label: '계약 체결 전', value: ICIDContractStatus.ONG },
            { label: '계약 체결 완료', value: ICIDContractStatus.FIN },
          ]}
          value={isFinishedContract}
          onChange={(e) => handleChangeContractStatus(e.target.value)}
        />
        <SearchInput
          onSearch={handleSearch}
          defaultValue={searchValue}
          placeholder="원료명(영문) 검색"
          style={{ width: 320 }}
        />
      </Flex>
      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        dataSource={icidRequestList}
        columns={columns}
        pagination={{ current: page, onChange: handleChangePage, total: total }}
        rowKey="materialIcidApplicationId"
      />
    </Container>
  );
};

export default ICIDRequestList;
