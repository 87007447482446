import { useHistory } from 'react-router-dom';

import NMPARequestList from 'components/material/nmpa/NMPARequestList';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import {
  NMPAContractStatus,
  NMPARequestListPageState,
} from 'types/material/nmpa/nmpa';

const NMPARequestListPage = () => {
  const history = useHistory<NMPARequestListPageState>();
  const pageState = history.location.state || {
    page: 1,
    contractStatus: NMPAContractStatus.ALL,
  };

  const handleChangePageState = (state: NMPARequestListPageState) => {
    history.replace(path.material.nmpa.requestList, state);
  };

  return (
    <PageTemplate
      title="취득 의뢰 리스트"
      description="계약하기 버튼을 눌러 견적서를 확인하고 신고번호 취득 계약 체결까지 진행하실 수 있습니다."
      back={false}
    >
      <NMPARequestList
        pageState={pageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default NMPARequestListPage;
