import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { s3AssetDomain } from 'lib/consts';
import { Button, H1, H4 } from './introStyles';
import history from 'lib/history';
import path from 'lib/path';
import { deviceSize } from 'lib/styles';
import useIntersectionObserver from 'hook/useIntersectionObserver';
import palette from 'lib/styles/palette';

const ImgContainer = forwardRef(
  (
    {
      isIntersecting,
      isAnimated,
      mainImg,
    }: {
      isIntersecting: boolean;
      isAnimated: boolean;
      mainImg: {
        id: number;
        fileUrl: string;
        filename: string;
      };
    },
    ref: ForwardedRef<HTMLDivElement> | null,
  ) => {
    const title =
      mainImg.id === 1 ? (
        <>
          더 빠르고, 더 저렴하고, 더 정확하게
          <br />
          인허가를 획득하세요.
        </>
      ) : (
        <>
          AI 기술을 활용한
          <br />
          Artwork 전성분 스크리닝
        </>
      );

    const description =
      mainImg.id === 1 ? (
        <>
          써티코스는 빅데이터기술, 블록체인기술, AI 기술 등을 탑재한 고도화된
          화장품 인증 플랫폼으로 효율성과 효과성을 높여드립니다.
        </>
      ) : (
        <>
          Artwork의 전성분 라벨링을
          <br />
          국가별 규칙에 맞춰 스크리닝해보세요.
        </>
      );
    return (
      <ImgDiv ref={ref} isAnimated={isAnimated} isIntersecting={isIntersecting}>
        <StyledImg imgId={mainImg.id} src={mainImg.fileUrl} />
        <BodyContainer>
          <Title color="WHITE">{title}</Title>
          <TextWrapper>
            <Desc imgId={mainImg.id}>{description}</Desc>
          </TextWrapper>
          <StyledButton
            style={{
              background: mainImg.id === 1 ? '#DA2128' : 'white',
              color: mainImg.id === 1 ? 'white' : palette.GRAY90,
            }}
            onClick={() => {
              history.push(path.login);
            }}
          >
            지금 시작하기
          </StyledButton>
        </BodyContainer>
      </ImgDiv>
    );
  },
);

const SectionA = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0,
  });
  useEffect(() => {
    if (isIntersecting) {
      setIsAnimated(true);
    }
  }, [isIntersecting]);

  const mainImages = [
    {
      id: 1,
      fileUrl: `${s3AssetDomain}/intro_main.png`,
      filename: 'a',
    },
    {
      id: 2,
      fileUrl: `${s3AssetDomain}/intro_main2.png`,
      filename: 'a',
    },
  ];
  return (
    <Container>
      <Splide
        options={{
          autoplay: mainImages.length >= 2 ? true : 'pause',
          interval: mainImages.length >= 2 ? 3000 : undefined,
          type: mainImages.length >= 2 ? 'loop' : undefined,
          perPage: 2,
          perMove: 1,
          focus: 'center',
          arrows: false,
          drag: true,
        }}
      >
        {mainImages.map((mainImg) => (
          <SplideSlide key={mainImg.id}>
            <ImgContainer
              ref={target}
              isIntersecting={isIntersecting}
              isAnimated={isAnimated}
              mainImg={mainImg}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Container>
  );
};

const Container = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .splide__slide {
    width: 100% !important;
  }

  .splide__pagination {
    padding: 0 0 40px 0;
    bottom: 0;
    @media ${deviceSize.sm} {
      padding: 0 0 32px 0;
    }
  }

  .splide__pagination__page.is-active {
    transform: scale(1) !important;
    background-color: white !important;
  }
`;

const Title = styled(H1)`
  word-break: keep-all;
  opacity: 0;
  transform: translateY(20%);
  transition: all 0.8s;

  @media ${deviceSize.sm} {
    width: 280px;
  }
`;

const Desc = styled(H4)<{ imgId: number }>`
  color: ${palette.GRAY50};
  font-size: 22px;
  word-break: keep-all;
  width: ${({ imgId }) => (imgId === 1 ? '58%' : '66%')};

  @media ${deviceSize.sm} {
    width: 100%;
    height: 72px;
    /* word-break: normal; */

    font-size: 16px;
  }
`;

const BodyContainer = styled.div`
  position: absolute;
  left: 17%;
  top: 24%;

  padding: 0px 24px;

  @media ${deviceSize.sm} {
    font-size: 28px;
    line-height: 38px;
    left: 0%;
    top: 22%;
    padding: 0 24px;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 80px;
  margin-top: 42px;
  opacity: 0;
  transform: translateY(28%);
  transition: all 1s;

  @media ${deviceSize.sm} {
    margin-bottom: 40px;
    margin-top: 30px;
  }
`;

const StyledButton = styled(Button)`
  opacity: 0;
  transform: translateY(30%);
  transition: all 1.05s;
`;

const ImgDiv = styled.div<{
  isIntersecting: boolean;
  isAnimated: boolean;
}>`
  width: 100%;
  position: relative;

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      ${Title}, ${TextWrapper}, ${StyledButton} {
        opacity: 1;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
    `}
  ${({ isAnimated }) =>
    isAnimated &&
    css`
      ${Title}, ${TextWrapper}, ${StyledButton} {
        opacity: 1;
        transform: translateY(0);
      }
    `} //
`;

const StyledImg = styled.div<{ src: string; imgId: number }>`
  width: 100%;
  height: 781px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  /* HINT: 다시 사용할 수 있어서 주석 처리 했습니다. */
  /* animation: shrink 12s ease forwards; */

  @media ${deviceSize.sm} {
    height: 562px;
    background-image: ${({ imgId }) =>
      imgId === 1
        ? `url(${s3AssetDomain}/intro_main_m.png)`
        : `url(${s3AssetDomain}/intro_main2_m.png)`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  /* @keyframes shrink {
    0% {
      background-size: 110% auto;
    }
    100% {
      background-size: 100% auto;
    }
  } */
`;

export default SectionA;
