import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row, message } from 'antd';
import styled from 'styled-components';
import { ColumnsType } from 'antd/es/table';

import FooterBox from 'components/FooterBox';
import BuyerAddModal from 'components/modal/buyer/BuyerAddModal';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { DocumentCode, MyRPItem } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import { useMyAllRP, useProductRPs } from 'service/brand/product/product';
import { CompanyType } from 'types/company';

const ProductRP = ({
  productId,
  countryId,
  subtitle,
}: {
  productId: number;
  countryId: number;
  subtitle: string;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [selectedRPId, setSelectedRPId] = useState<number>();
  const { myAllRP, myAllRPLoading } = useMyAllRP();
  const { addProductRP, addProductRPLoading, productRP } = useProductRPs({
    productId,
    countryId,
  });
  const [isCdriRP, setIsCdriRP] = useState<boolean>();
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );
  const { openProductRPAddModal } = useModal();

  useEffect(() => {
    if (productRP) {
      setSelectedRPId(productRP.responsiblePerson.responsiblePersonId);
    }
    if (
      productRP?.responsiblePerson &&
      !productRP?.responsiblePerson.companyId
    ) {
      setIsCdriRP(true);
    }
  }, [productRP]);

  const rpDatas = useMemo(() => {
    if (!productRP) return myAllRP;
    const cdriRP = [productRP?.responsiblePerson];

    if (isCdriRP && myAllRP) {
      return [...cdriRP, ...myAllRP];
    } else if (isCdriRP && myAllRP?.length === 0) {
      return [...cdriRP];
    } else {
      return myAllRP;
    }
  }, [productRP, myAllRP, isCdriRP]);

  const columns: ColumnsType<MyRPItem> = useMemo(
    () => [
      {
        title: 'RP회사명',
        align: 'center',
        width: '15.3%',
        render: ({ companyName }) => companyName,
      },
      {
        title: 'RP연락처',
        align: 'center',
        width: '23%',
        render: ({ companyTel, companyEmail, companyHomepage }) => (
          <Flex
            dir="column"
            justify="center"
            style={{ fontSize: 14, textAlign: 'start' }}
          >
            {companyTel && (
              <>
                전화번호: {companyTel}
                <br />
              </>
            )}
            {companyEmail && (
              <>
                이메일 주소: {companyEmail}
                <br />
              </>
            )}
            {companyHomepage && <>홈페이지 주소: {companyHomepage}</>}
          </Flex>
        ),
      },
      {
        title: 'RP주소',
        align: 'center',
        width: 264,
        render: ({ companyAddress }) => companyAddress,
      },
      {
        title: 'RP종류',
        align: 'center',
        width: '10%',
        render: ({ companyType }) =>
          companyType === CompanyType.BRAND
            ? 'Distributor'
            : companyType === CompanyType.MANUFACTURE
            ? 'Manufacturer'
            : 'Packer',
      },
      {
        title: '중소기업 여부',
        width: '10.7%',
        align: 'center',
        render: ({ companySize }) =>
          companySize === 'CORPORATE'
            ? '중소기업 아님'
            : !companySize
            ? '미지정'
            : '중소기업',
      },
      {
        title: '등록일/수정일',
        width: '11.5%',
        align: 'center',
        render: ({ updateDt }) => updateDt,
      },
    ],
    [myAllRP],
  );

  const handleSubmit = () => {
    if (!selectedRPId) {
      return message.warn('RP를 선택해 주세요.');
    }
    addProductRP({ responsiblePersonId: selectedRPId });
  };

  return (
    <Container>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.RP}
          />
        </Col>
      </Row>
      <BuyerAddModal countryId={countryId} />
      <Row justify="end" style={{ marginTop: 16, marginBottom: 16 }}>
        <Col>
          <Button
            onClick={() => openProductRPAddModal({})}
            disabled={readOnlyMode}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: readOnlyMode
                ? `1px solid ${palette.GRAY40}`
                : `1px solid ${palette.PRIMARY50}`,
              background: 'white',
            }}
          >
            <Icon
              name="plus"
              size={18}
              color={readOnlyMode ? 'GRAY50' : 'PRIMARY50'}
              style={{ marginRight: 4 }}
            />
            RP 추가
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={rpDatas}
        rowKey="responsiblePersonId"
        rowSelection={{
          type: 'radio',
          hideSelectAll: true,
          selectedRowKeys: selectedRPId ? [selectedRPId] : [],
          onChange: (_, item) => {
            setSelectedRPId(item[0].responsiblePersonId);
          },
          getCheckboxProps: () => ({
            disabled: readOnlyMode,
          }),
        }}
        loading={myAllRPLoading}
        pagination={false}
        onRow={({ responsiblePersonId }) => ({
          onClick: () => {
            if (readOnlyMode) {
              return;
            }
            setSelectedRPId(responsiblePersonId);
          },
          style: {
            cursor: readOnlyMode ? 'auto' : 'pointer',
          },
        })}
      />

      <FooterBox style={{ paddingTop: 56 }}>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          {productRP && (
            <CorrectRequestWithoutChangeButton documentCode={DocumentCode.RP} />
          )}
          <Button
            type="primary"
            loading={addProductRPLoading}
            onClick={handleSubmit}
          >
            {productRP ? '보완 완료' : '등록'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </Container>
  );
};

const Container = styled.div`
  .ant-table {
    font-size: 14px !important;
  }
  .ant-radio-inner::after {
    transition: none;
  }
  .ant-radio-checked .ant-radio-inner::after {
    display: block;
  }

  .ant-table-wrapper tr.ant-table-row-selected > td {
    background-color: transparent !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 16px !important;
  }
  .ant-table-selection-column {
    max-width: 40px !important;
  }
`;
export default ProductRP;
