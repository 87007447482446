import { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import path from 'lib/path';
import RetailerApplyRouter from './RetailerApplyRouter';
import FullLoading from 'components/FullLoading';

const RetailerRouter = () => {
  return (
    <Suspense fallback={<FullLoading />}>
      <Switch>
        <Route
          path={path.retailer.retailerApply.root}
          component={RetailerApplyRouter}
        />
      </Switch>
    </Suspense>
  );
};

export default RetailerRouter;
