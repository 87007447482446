import { Button, Upload, message } from 'antd';
import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import { useModal } from 'hook/useModal';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { FormulaScreeningItem } from 'service/brand/formulaScreening/formulaScreening';

const FormulaScreening = ({
  file,
  setFile,
  isArtworkScreening = false,
  __html,
  setHTML,
  formulaScreeningResult,
  uploadLoading,
}: {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  isArtworkScreening?: boolean;
  __html?: string;
  setHTML?: React.Dispatch<React.SetStateAction<string>>;
  formulaScreeningResult?: FormulaScreeningItem | null;
  uploadLoading: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>(
    'none',
  );
  const [fileLoading, setFileLoading] = useState<{
    type: 'upload' | 'put';
    loading: boolean;
  } | null>(null);
  const [hoverCount, setHoverCount] = useState(0);
  const [uploadFileDone, setUploadFileDone] = useState<boolean>();

  const { openAlertModal, openConfirmModal } = useModal();

  const success = formulaScreeningResult
    ? !formulaScreeningResult.isRowError
    : undefined;

  const handleUpload = (file: File) => {
    setFile(file);
  };

  const handleInit = () => {
    if (!file) {
      return message.warn('입력한 내용이 없습니다.');
    }
    openAlertModal({
      content: '초기화 하시겠습니까?',
      onOk: () => {
        setFile(null);
        setUploadFileDone(false);
      },
      okText: '초기화',
    });
  };

  const handleShowUploadExampleModal = () => {
    openConfirmModal({
      title: <Typography.Text type="TITLE_2">업로드 예시</Typography.Text>,
      width: 640,
      content: (
        <img
          src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Formula+Breakdown+Screening.png"
          alt="formula-screening"
          width={560}
          height={476}
        />
      ),
      footer: null,
    });
  };

  const commonFileUpload = async (filesList: FileList | File[]) => {
    const files: File[] = [];

    if (filesList.length > 1) {
      return message.warn('1개의 파일만 업로드 가능합니다.');
    }

    for (const file of filesList) {
      const filename = file.name.toLowerCase();

      if (!filename.endsWith('.xlsx')) {
        return message.warn('엑셀 파일만 업로드 가능합니다.');
      }
      files.push(file);
    }

    setFileLoading({
      type: 'upload',
      loading: true,
    });

    const data = await filesList[0]?.arrayBuffer();

    const wb = XLSX.read(data);
    const ws = wb.Sheets[wb.SheetNames[0]];

    setHTML?.(XLSX.utils.sheet_to_html(ws, { id: 'excel_table' }));

    setFile(files[0]);
    message.success(`정상적으로 업로드되었습니다.`);

    setFileLoading({
      type: 'upload',
      loading: false,
    });
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if (
      [...e.dataTransfer.files].filter((file) => file.type === '').length > 0
    ) {
      message.warn('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    commonFileUpload(e.dataTransfer.files);
  };

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;

    commonFileUpload(e.target.files);

    e.target.value = '';
  };

  const preventDefault: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const referToFile = () => fileRef.current?.click();

  return (
    <Container success={success}>
      <LoadingModal
        visible={uploadLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 스크리닝 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <Flex
        gap={8}
        style={{
          marginTop: 56,
          paddingBottom: 8,
          borderBottom: `2px solid ${palette.PRIMARY50}`,
          width: '100%',
        }}
        align="center"
        justify="space-between"
      >
        <Flex gap={8} align="center">
          <Flex gap={4} align="center">
            <Icon name={isArtworkScreening ? 'robot' : 'screening'} size={24} />
            <Typography.Text type="TITLE_1">
              {isArtworkScreening ? '전성분표 입력' : '성분 스크리닝 정보 입력'}
            </Typography.Text>
          </Flex>
          {!isArtworkScreening && (
            <SmallGrayButton onClick={handleInit}>
              <Icon
                name="refresh"
                size={16}
                color="SLATE_GRAY60"
                style={{ marginRight: 4 }}
              />
              초기화
            </SmallGrayButton>
          )}
        </Flex>
        <SmallGrayButton
          type="button"
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Ingredients_Screening_Form.xlsx',
            )
          }
        >
          <Icon
            name="download"
            size={16}
            color="SLATE_GRAY60"
            style={{ marginRight: 4 }}
          />
          엑셀 서식 다운로드
        </SmallGrayButton>
      </Flex>
      <DescriptionContainer
        gap={4}
        dir="column"
        style={{ height: isArtworkScreening ? 140 : 72 }}
      >
        {isArtworkScreening ? (
          <>
            <Flex align="center" gap={8}>
              <Typography.Text style={{ fontSize: 14 }}>
                · certicos에서 제공하는 엑셀 서식 파일을 다운로드하여 정보를
                알맞게 기입한 후 업로드해 주세요.
              </Typography.Text>
              {/* <Flex gap={2} align="center">
                <Typography.Text
                  style={{ fontSize: 12, color: palette.PRIMARY50 }}
                >
                  엑셀 사용 가이드
                </Typography.Text>
                <Icon name="newpage" size={12} color="PRIMARY50" />
              </Flex> */}
            </Flex>
            <Typography.Text style={{ fontSize: 14 }}>
              · CAS No. 가 없거나 모르시는 경우는 입력하지 않으셔도 됩니다.
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              · 필요한{' '}
              <Typography.Text
                type="BODY_2"
                inline
                color="MESSAGE_ERROR"
                style={{ fontWeight: 500 }}
              >
                단일 전성분표
              </Typography.Text>
              는 제조사에 요청해야 하며 미리 서류 준비 부탁드립니다.
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              · Artwork와 동일한 언어를 사용하여 전성분표에 작성해 주세요.
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              · 단상자 전성분표에 있는 성분의 함량이 1% 이상이면 함량이 높은
              순서대로 입력해 주세요.
            </Typography.Text>
          </>
        ) : (
          <>
            <Flex align="center" gap={8}>
              <Typography.Text type="BODY_2">
                · certicos에서 제공하는 엑셀 서식 파일을 다운로드하여 정보를
                알맞게 기입한 후 업로드해 주세요.
              </Typography.Text>
              <ExampleButton
                justify="center"
                align="center"
                onClick={handleShowUploadExampleModal}
              >
                업로드 예시 보기
              </ExampleButton>
            </Flex>
            <Typography.Text style={{ fontSize: 14 }}>
              · CAS No. 가 없거나 모르시는 경우는 입력하지 않으셔도 됩니다.
            </Typography.Text>
          </>
        )}
      </DescriptionContainer>
      {isArtworkScreening ? (
        <>
          {!file && !uploadFileDone && (
            <UploadContainer
              ref={containerRef}
              dragging={fileDragState === 'dragging'}
              onDragEnter={handleFileDragEnter}
              onDragLeave={handleFileDragLeave}
              onDragOver={preventDefault}
              onDrop={handleFileDragUpload}
              align="center"
              justify="center"
            >
              <input
                type="file"
                ref={fileRef}
                multiple={true}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                accept=".xlsx"
              />
              {fileLoading && fileLoading.loading ? (
                <UploadingContainer dir="column" align="center" gap={8}>
                  <LoadingIndicator size="md" />
                  <Typography.Text
                    style={{
                      fontSize: 16,
                      color: palette.SLATE_GRAY70,
                      fontWeight: 500,
                    }}
                  >
                    파일을 업로드 하는 중...
                  </Typography.Text>
                </UploadingContainer>
              ) : (
                <Flex
                  dir="column"
                  align="center"
                  justify="center"
                  onClick={referToFile}
                >
                  <Icon
                    name="uploadDocIcon"
                    size={48}
                    color="SLATE_GRAY50"
                    style={{ marginBottom: 16 }}
                  />
                  <Typography.Text
                    style={{
                      color: palette.SLATE_GRAY60,
                      fontSize: 16,
                      fontWeight: 500,
                      marginBottom: 8,
                    }}
                  >
                    버튼 클릭 혹은 파일 끌어서 넣기
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      color: palette.SLATE_GRAY60,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    업로드 가능한 파일 : xlsx
                  </Typography.Text>
                  <Button
                    loading={fileLoading?.loading}
                    icon={
                      <Icon
                        name="upload"
                        size={18}
                        color="GRAY50"
                        style={{ marginRight: 4 }}
                      />
                    }
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderColor: palette.GRAY40,
                      color: palette.GRAY70,
                      height: 32,
                      marginTop: 16,
                      padding: '6px 8px',
                    }}
                  >
                    파일 업로드
                  </Button>
                </Flex>
              )}
            </UploadContainer>
          )}
          {file && __html && (
            <Flex dir="column" gap={16} style={{ width: '100%' }}>
              <PreviewContainer>
                <div style={{ width: '100%' }}>
                  <div dangerouslySetInnerHTML={{ __html }} />
                </div>
              </PreviewContainer>
              <FileItem align="center" justify="space-between">
                <Flex align="center" gap={8}>
                  <Icon name="clip" size={18} color="SLATE_GRAY70" />
                  <Typography.Text
                    style={{ fontSize: 12, color: palette.SLATE_GRAY70 }}
                  >
                    {file.name}
                  </Typography.Text>
                </Flex>
                <Icon
                  name="close"
                  size={18}
                  color="SLATE_GRAY70"
                  onClick={() => setFile(null)}
                />
              </FileItem>
            </Flex>
          )}
        </>
      ) : (
        <Flex gap={8} align="center" style={{ marginTop: 24 }}>
          <Upload
            accept=".xlsx"
            beforeUpload={(file) => {
              handleUpload(file);
              if (file) message.success(`정상적으로 업로드되었습니다.`);
              return false;
            }}
            itemRender={() => null}
          >
            <Flex align="center" gap={8}>
              <Button
                loading={uploadLoading}
                icon={
                  <Icon
                    name="upload"
                    size={18}
                    color={'PRIMARY50'}
                    style={{ marginRight: 4 }}
                  />
                }
                style={{
                  width: 122,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onDrop={(e) => e.stopPropagation()}
              >
                파일 업로드
              </Button>
              {file && (
                <Typography.Text
                  type="BODY_2"
                  style={{ color: palette.GRAY90 }}
                >
                  {file?.name}
                </Typography.Text>
              )}
            </Flex>
          </Upload>
        </Flex>
      )}
    </Container>
  );
};

const Container = styled.div<{
  success?: boolean;
}>`
  width: 100%;
  position: relative;

  .ant-descriptions-view {
    border-radius: 0;
    border: none;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    border-right: none;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    vertical-align: top;
    padding: 16px;
  }

  .ant-descriptions-item-content {
    padding: 24px;
  }
`;

const SmallGrayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.SLATE_GRAY70};
  background-color: ${palette.SLATE_GRAY30};
  padding: 6px 8px;
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 72px;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const ExampleButton = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${palette.PRIMARY50};
  background: white;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.GRAY90};
  height: 24px;
  cursor: pointer;
  padding: 0 8px;
  transition: all 200ms ease-in-out;

  &:hover {
    color: ${palette.PRIMARY50};
  }
`;

const UploadContainer = styled(Flex)<{ dragging: boolean }>`
  width: 100%;
  background-color: white;
  height: 320px;
  border-radius: 8px;
  padding: 52px 32px;
  margin-top: 24px;
  cursor: pointer;
  transition: border 100ms linear;
  border: 1px solid ${palette.GRAY30};
  ${({ dragging }) =>
    dragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
  `}
`;

const UploadingContainer = styled(Flex)``;

const PreviewContainer = styled(Flex)`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${palette.GRAY30};
  margin-top: 40px;
  max-height: 320px;
  overflow: scroll;
  width: 100%;

  table {
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid ${palette.SLATE_GRAY40};
  }

  tr:first-of-type {
    background-color: #c0c0c0;
  }

  td {
    min-height: 20px;
    height: 20px;
    min-width: 60px;
    &:first-of-type {
      min-width: 80px;
      width: 80px;
    }
    &:nth-child(2) {
      width: 100%;
    }
    &:nth-child(3) {
      min-width: 120px;
      width: 120px;
    }
    &:nth-child(4) {
      min-width: 120px;
      width: 120px;
    }
  }
`;

const FileItem = styled(Flex)`
  width: 100%;
  border-radius: 4px;
  background-color: ${palette.SLATE_GRAY10};
  padding: 11px 16px;
  color: ${palette.SLATE_GRAY70};
`;

export default FormulaScreening;
