import { Button, Col, Row } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';
import Table from 'components/ui/Table/Table';
import { useFDAAccount } from 'service/brand/product/product';
import { FDAAccountItem } from 'types/product';
import { useModal } from 'hook/useModal';

const FDAAccountContainer = styled.div`
  .ant-table-content {
    th:first-child,
    td:first-child {
      padding-left: 36px;
    }
  }
`;

const FDAPasswordContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${palette.GRAY30};
  border-radius: 4px;
`;

const FDAAccount = () => {
  const { fdaAccount, fdaAccountLoading } = useFDAAccount({});
  const { openFDAAccountAddModal } = useModal();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const columns: ColumnsType<FDAAccountItem> = useMemo(
    () => [
      {
        title: '사용자명 (ID)',
        width: '360px',
        align: 'left',
        dataIndex: 'fdaAccount',
      },
      {
        title: '비밀번호',
        align: 'left',
        width:
          fdaAccount?.fdaPassword && fdaAccount.fdaPassword.length > 15
            ? 270
            : 150,
        render: (_, { fdaPassword }) => (
          <FDAPasswordContainer>
            {isPasswordVisible ? fdaPassword : '*'.repeat(fdaPassword.length)}
          </FDAPasswordContainer>
        ),
      },
      {
        align: 'left',
        render: () => (
          <>
            {isPasswordVisible ? (
              <IconWrapper
                onClick={() => setIsPasswordVisible(false)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="show" size={14} color="GRAY60" />
              </IconWrapper>
            ) : (
              <IconWrapper
                onClick={() => setIsPasswordVisible(true)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="hide" size={14} color="GRAY60" />
              </IconWrapper>
            )}
          </>
        ),
      },
    ],
    [fdaAccount, isPasswordVisible],
  );

  const openFDAAccountModal = () => {
    openFDAAccountAddModal({
      fdaAccount,
    });
  };

  return (
    <FDAAccountContainer>
      {fdaAccount !== null && (
        <Row justify="end" style={{ marginTop: 8, marginBottom: 16 }}>
          <Col>
            <Button onClick={openFDAAccountModal}>계정 정보 변경</Button>
          </Col>
        </Row>
      )}
      <Table
        rowKey="fdaAccountId"
        columns={columns}
        dataSource={fdaAccount ? [fdaAccount] : []}
        loading={fdaAccountLoading}
        pagination={false}
      />
    </FDAAccountContainer>
  );
};

export default FDAAccount;
