import { useQuery } from 'react-query';
import { useMemo } from 'react';

import client from 'lib/api/client';
import { APIPageableResponse } from 'types/common';
import { Estimate } from 'types/material/nmpa/estimate';

export const useMyNmpaEstimates = ({
  page,
  size = 10,
}: {
  page: number;
  size?: number;
}) => {
  const { data = null } = useQuery(
    ['material/nmpa/estimates', page, size],
    () =>
      client.get<APIPageableResponse<Estimate[]>>(
        '/v1/material/nmpa/estimates',
        {
          params: {
            page,
            size,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const { content: myNmpaEstimates = [], totalElements } = data || {};

  return useMemo(() => ({ myNmpaEstimates, totalElements }), [
    myNmpaEstimates,
    totalElements,
  ]);
};
