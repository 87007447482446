import styled from 'styled-components';
import { Button, Form, FormInstance, Input, Spin, Upload, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useForm } from 'antd/lib/form/Form';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shallowEqual, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import {
  useProductAnnex14,
  useProductDocStatus,
  useProductFormulaBreakdownChina,
} from 'service/product';
import { ReactComponent as TooltipArrow } from 'asset/svg/tooltip_arrow.svg';
import FooterBox from 'components/FooterBox';
import { MinusIcon } from 'components/system/general/icon';
import { requireRule } from 'lib/validate';
import history from 'lib/history';
import { DocumentCode, ProductDocStatus } from 'types/product';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from './UpdateLog';
import { messages } from 'lib/consts';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const Container = styled.div``;

const TradeContainer = styled(Flex)`
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};
  padding: 16px 24px;

  .title {
    &::before {
      content: '';
      width: 4px;
      height: 4px;
      margin-right: 4px;
      background-color: ${palette.PRIMARY50};
    }
  }

  .ant-form-item-control-input-content {
    text-align: start;
  }
`;

const TradeName = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  color: ${palette.PRIMARY50};
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  transition: all 100ms ease;

  &:hover {
    background-color: ${palette.PRIMARY30};
    border-radius: 30px;
  }

  &:active {
    background-color: ${palette.PRIMARY40};
  }
`;

const CopyTooltip = styled(Flex)`
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  color: ${palette.ETC_WHITE};
  padding: 5.5px 8.25px;
  display: none;
  position: absolute;
  width: 74.5px;
  top: -32px;
  transition: background-color 300ms ease;
  opacity: 0.9;
  left: 50%;
  transform: translateX(-50%);

  &:target {
    background-color: ${palette.PRIMARY40};
  }

  ${TradeName}:hover & {
    display: flex;
  }
`;

const TableContainer = styled.div`
  .ant-table {
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
    overflow: visible;

    th.ant-table-cell {
      background-color: ${palette.GRAY10} !important;
    }
    .ant-table-cell {
      border-color: ${palette.GRAY40};
    }
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

const TextWithAsterisk = styled(Typography.Text)`
  &::before {
    content: '*';
    color: ${palette.PRIMARY50};
  }
`;

const FileNameText = styled(Typography.Text)`
  max-width: 340px;
  font-size: 14px;
  font-weight: 400;
  color: ${palette.GRAY90};
  letter-spacing: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface AnnexFormItem {
  tradeName: string;
  uploadFile: File;
  productAnnex14Id?: number;
  annex14FileUrl?: string;
  annex14Filename?: string;
  tmpId?: string;
}

interface AnnexFormInstance {
  annexItems: AnnexFormItem[];
}

interface AnnexFormState {
  tmpId?: string;
  productAnnex14Id?: number;
  tradeName?: string;
  annex14FileUrl?: string;
  annex14Filename?: string;
}

interface FileMap {
  [key: number | string]: {
    file?: File;
    filename?: string;
    fileurl?: string;
    productAnnex14Id?: number;
    tmpId?: string;
    isDelete?: boolean;
    tradeName: string;
  };
}

interface AnnexDataParam {
  uploadFile?: File | undefined;
  productAnnex14Id?: number;
  tradeName?: string | undefined;
  isDelete?: boolean | undefined;
}

const AnnexItemList = ({
  form,
  fileMap,
  loading,
  updateMode,
  readOnlyMode,
  docStatus,
  setFileMap,
}: {
  form: FormInstance<any>;
  loading: boolean;
  fileMap: FileMap;
  updateMode: boolean | undefined;
  readOnlyMode: boolean | undefined;
  docStatus: ProductDocStatus | null;
  setFileMap: React.Dispatch<React.SetStateAction<FileMap>>;
}) => {
  const disabledMode =
    readOnlyMode &&
    (docStatus?.status === 'ONG' || docStatus?.status === 'FIN');

  return (
    <Form.List name="annexItems">
      {(_, { add, remove }) => {
        return (
          <Form.Item
            shouldUpdate={(prev, next) => prev.annexItems !== next.annexItems}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const columns: ColumnsType<AnnexFormState> = [
                {
                  title: (
                    <Typography.Text medium color="SLATE_GRAY70">
                      No.
                    </Typography.Text>
                  ),
                  width: 64,
                  align: 'center',
                  render: (_, { productAnnex14Id, tmpId }, index) => (
                    <Typography.Text
                      style={{
                        color: palette.SLATE_GRAY70,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {index + 1}
                      {getFieldValue(['annexItems']).length > 1 &&
                        !disabledMode && (
                          <MinusIcon
                            style={{ position: 'absolute', left: -40 }}
                            onClick={() => {
                              const newFileMap = { ...fileMap };
                              if (productAnnex14Id) {
                                newFileMap[productAnnex14Id] = {
                                  ...fileMap[productAnnex14Id],
                                  isDelete: true,
                                };
                              } else if (tmpId) {
                                delete newFileMap[tmpId];
                              }
                              setFileMap(newFileMap);
                              remove(index);
                            }}
                          />
                        )}
                    </Typography.Text>
                  ),
                },
                {
                  title: (
                    <TextWithAsterisk medium color="SLATE_GRAY70">
                      Trade Name
                    </TextWithAsterisk>
                  ),
                  width: 412,
                  render: (_, __, index) => (
                    <Form.Item
                      name={[index, 'tradeName']}
                      rules={[requireRule]}
                      initialValue={null}
                      normalize={(value) => (value !== '' ? value : null)}
                    >
                      <Input
                        maxLength={1000}
                        style={{ width: 412 }}
                        disabled={disabledMode}
                      />
                    </Form.Item>
                  ),
                },
                {
                  title: (
                    <TextWithAsterisk medium color="SLATE_GRAY70">
                      ANNEX 14 파일
                    </TextWithAsterisk>
                  ),
                  width: 412,
                  render: (_, { productAnnex14Id, tmpId }, index) => (
                    <Form.Item
                      required
                      name={[index, 'uploadFile']}
                      normalize={(value) => (value !== '' ? value : null)}
                      shouldUpdate={(prev, next) =>
                        prev.annexItems[index]?.uploadFile !==
                        next.annexItems[index]?.uploadFile
                      }
                      rules={[
                        {
                          validator: () => {
                            if (tmpId) {
                              if (!fileMap[tmpId]?.file) {
                                return Promise.reject('필수 항목');
                              } else {
                                return Promise.resolve();
                              }
                            } else if (productAnnex14Id) {
                              if (
                                !fileMap[productAnnex14Id]?.filename &&
                                !fileMap[productAnnex14Id]?.file
                              ) {
                                return Promise.reject('필수 항목');
                              } else {
                                return Promise.resolve();
                              }
                            }
                            return;
                          },
                        },
                      ]}
                      initialValue={null}
                    >
                      {productAnnex14Id &&
                        !fileMap[productAnnex14Id]?.filename &&
                        !fileMap[productAnnex14Id]?.file && (
                          <Upload
                            accept=".pdf,.xlsx"
                            beforeUpload={(file) => {
                              const tradeName = getFieldValue(['annexItems'])[
                                index
                              ].tradeName;
                              const annexItems = getFieldValue(['annexItems']);
                              const newAnnexItemsMap = {
                                ...getFieldValue(['annexItems']),
                              };

                              for (let i = 0; i < annexItems.length; i++) {
                                newAnnexItemsMap[index] = {
                                  ...newAnnexItemsMap[index],
                                  uploadFile: file.name,
                                };
                              }

                              setFieldsValue({
                                annexItems: Object.values(newAnnexItemsMap),
                              });
                              const newFileMap = { ...fileMap };
                              newFileMap[productAnnex14Id] = {
                                tradeName,
                                ...(productAnnex14Id && { productAnnex14Id }),
                                file,
                              };
                              setFileMap(newFileMap);

                              return false;
                            }}
                            itemRender={() => null}
                          >
                            <Button
                              icon={
                                <Icon
                                  name="upload"
                                  size={14}
                                  style={{ marginRight: 4 }}
                                />
                              }
                              style={{
                                width: 122,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onDrop={(e) => e.stopPropagation()}
                            >
                              파일 업로드
                            </Button>
                          </Upload>
                        )}
                      {tmpId &&
                        !fileMap[tmpId]?.filename &&
                        !fileMap[tmpId]?.file &&
                        updateMode && (
                          <Upload
                            accept=".pdf,.xlsx"
                            beforeUpload={(file, fileList) => {
                              const tradeName = getFieldValue(['annexItems'])[
                                index
                              ].tradeName;
                              const newFileMap = { ...fileMap };

                              newFileMap[tmpId] = {
                                tradeName,
                                file,
                                ...(productAnnex14Id && { productAnnex14Id }),
                              };

                              setFileMap(newFileMap);

                              return false;
                            }}
                            itemRender={() => null}
                          >
                            <Button
                              icon={
                                <Icon
                                  name="upload"
                                  size={14}
                                  style={{ marginRight: 4 }}
                                />
                              }
                              style={{
                                width: 122,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onDrop={(e) => e.stopPropagation()}
                            >
                              파일 업로드
                            </Button>
                          </Upload>
                        )}
                      {!updateMode &&
                        typeof tmpId === 'string' &&
                        !fileMap[tmpId]?.file && (
                          <Upload
                            accept=".pdf,.xlsx"
                            beforeUpload={(file, fileList) => {
                              const tradeName = getFieldValue(['annexItems'])[
                                index
                              ].tradeName;
                              const newFileMap = { ...fileMap };
                              const annexItems = getFieldValue(['annexItems']);
                              const newAnnexItemsMap = {
                                ...getFieldValue(['annexItems']),
                              };

                              for (let i = 0; i < annexItems.length; i++) {
                                newAnnexItemsMap[index] = {
                                  ...newAnnexItemsMap[index],
                                  uploadFile: file.name,
                                };
                              }

                              setFieldsValue({
                                annexItems: Object.values(newAnnexItemsMap),
                              });

                              newFileMap[tmpId] = {
                                tradeName,
                                file,
                              };

                              setFileMap(newFileMap);

                              return false;
                            }}
                            itemRender={() => null}
                          >
                            <Button
                              icon={
                                <Icon
                                  name="upload"
                                  size={14}
                                  style={{ marginRight: 4 }}
                                />
                              }
                              style={{
                                width: 122,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onDrop={(e) => e.stopPropagation()}
                            >
                              파일 업로드
                            </Button>
                          </Upload>
                        )}
                      {tmpId && fileMap[tmpId]?.file && (
                        <Flex align="center" gap={4}>
                          <Icon name="page" />
                          <Flex justify="flex-start">
                            <FileNameText>
                              {fileMap[tmpId]?.file?.name?.slice(
                                0,
                                fileMap[tmpId]?.file?.name?.lastIndexOf('.'),
                              )}
                            </FileNameText>
                            <span className="last-file-name">
                              {fileMap[tmpId]?.file?.name?.slice(
                                fileMap[tmpId]?.file?.name?.lastIndexOf('.'),
                              )}
                            </span>
                          </Flex>
                          {!disabledMode && (
                            <Icon
                              name="smallClose"
                              size={12}
                              color="SLATE_GRAY70"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const newFileMap = { ...fileMap };
                                const annexItems = getFieldValue([
                                  'annexItems',
                                ]);
                                const newAnnexItemsMap = {
                                  ...getFieldValue(['annexItems']),
                                };

                                for (let i = 0; i < annexItems.length; i++) {
                                  newAnnexItemsMap[index] = {
                                    ...newAnnexItemsMap[index],
                                    uploadFile: null,
                                  };
                                }

                                setFieldsValue({
                                  annexItems: Object.values(newAnnexItemsMap),
                                });

                                if (productAnnex14Id) {
                                  newFileMap[productAnnex14Id] = {
                                    ...fileMap[productAnnex14Id],
                                    productAnnex14Id: productAnnex14Id,
                                    filename: undefined,
                                    fileurl: undefined,
                                  };
                                } else if (tmpId) {
                                  newFileMap[tmpId] = {
                                    ...fileMap[tmpId],
                                    file: undefined,
                                  };
                                }

                                setFileMap(newFileMap);
                              }}
                            />
                          )}
                        </Flex>
                      )}
                      {productAnnex14Id &&
                        (fileMap[productAnnex14Id]?.filename ||
                          fileMap[productAnnex14Id].file) &&
                        updateMode && (
                          <Flex align="center" gap={4}>
                            <Icon name="page" />
                            <Typography.Text
                              style={{
                                color: palette.GRAY90,
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: 0,
                                textOverflow: 'ellipsis',
                                maxWidth: 364,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {fileMap[productAnnex14Id]?.filename ||
                                fileMap[productAnnex14Id].file?.name}
                            </Typography.Text>
                            {!disabledMode && (
                              <Icon
                                name="smallClose"
                                size={12}
                                color="SLATE_GRAY70"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const tradeName = getFieldValue([
                                    'annexItems',
                                  ])[index].tradeName;
                                  const annexItems = getFieldValue([
                                    'annexItems',
                                  ]);
                                  const newAnnexItemsMap = {
                                    ...getFieldValue(['annexItems']),
                                  };

                                  for (let i = 0; i < annexItems.length; i++) {
                                    newAnnexItemsMap[index] = {
                                      ...newAnnexItemsMap[index],
                                      uploadFile: null,
                                    };
                                  }

                                  setFieldsValue({
                                    annexItems: Object.values(newAnnexItemsMap),
                                  });

                                  const newFileMap = { ...fileMap };
                                  if (productAnnex14Id) {
                                    newFileMap[productAnnex14Id] = {
                                      tradeName,
                                      productAnnex14Id: productAnnex14Id,
                                      filename: undefined,
                                      fileurl: undefined,
                                    };
                                  } else if (tmpId) {
                                    newFileMap[tmpId] = {
                                      tradeName,
                                      tmpId: tmpId,
                                      file: undefined,
                                    };
                                  }

                                  setFileMap(newFileMap);
                                }}
                              />
                            )}
                          </Flex>
                        )}
                    </Form.Item>
                  ),
                },
              ];
              return (
                <TableContainer>
                  <Table
                    columns={columns}
                    dataSource={getFieldValue(['annexItems'])}
                    pagination={false}
                    loading={loading}
                    rowKey={({ productAnnex14Id, tmpId }) =>
                      productAnnex14Id || tmpId!
                    }
                  />
                  {!disabledMode && (
                    <Button
                      type="dashed"
                      icon={
                        <Icon
                          name="plus"
                          color="PRIMARY50"
                          size={14}
                          style={{ marginRight: 4 }}
                        />
                      }
                      block
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 16,
                        maxWidth: 520,
                        margin: '16px auto 0 auto',
                      }}
                      onClick={() => {
                        const annexData = form.getFieldsValue(['annexItems'])
                          .annexItems;
                        const newTmpId = nanoid();

                        if (annexData.length > 49) {
                          return;
                        }

                        const newFileMap = { ...fileMap };
                        newFileMap[newTmpId] = {
                          tradeName: '',
                        };
                        setFileMap(newFileMap);

                        add({
                          tmpId: newTmpId,
                          tradeName: '',
                        });
                      }}
                    >
                      ANNEX 14 추가
                    </Button>
                  )}
                </TableContainer>
              );
            }}
          </Form.Item>
        );
      }}
    </Form.List>
  );
};

const ProductAnnex14 = () => {
  const [form] = useForm<AnnexFormInstance>();
  const { productId } = useCurrentProduct();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [fileMap, setFileMap] = useState<FileMap>({});
  const countryId = useCountryId();
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.ANE,
  });
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const {
    formulaBreakdownChinaItems,
    getLoading,
  } = useProductFormulaBreakdownChina({
    productId,
    countryId,
  });
  const {
    productAnnex14Data,
    isProductAnnex14DataLoading,
    addProductAnnex14Data,
    isAddProductAnnex14DataLoading,
    updateProductAnnex14Data,
    isUpdateProductAnnex14DataLoading,
  } = useProductAnnex14({ productId, countryId });

  const updateMode = productAnnex14Data && productAnnex14Data.length > 0;

  useEffect(() => {
    if (!productAnnex14Data) return;

    if (productAnnex14Data.length > 0) {
      const newFileMap = { ...fileMap };

      form.setFieldsValue({
        annexItems: productAnnex14Data.map(
          ({
            productAnnex14Id,
            tradeName,
            annex14FileUrl,
            annex14Filename,
          }) => ({
            productAnnex14Id,
            tradeName,
            annex14FileUrl,
            annex14Filename,
            uploadFile: annex14FileUrl,
          }),
        ),
      });

      productAnnex14Data.forEach((item) => {
        newFileMap[item.productAnnex14Id] = {
          tradeName: item.tradeName,
          filename: item.annex14Filename,
          fileurl: item.annex14FileUrl,
          productAnnex14Id: item.productAnnex14Id,
        };
      });
      setFileMap(newFileMap);
    } else {
      form.setFieldsValue({
        annexItems: [
          {
            tmpId: nanoid(),
            tradeName: undefined,
            uploadFile: undefined,
          },
        ],
      });
    }
  }, [productAnnex14Data]);

  const handleSubmit = () => {
    const formValues: AnnexFormInstance = form.getFieldsValue(['annexItems']);

    if (updateMode) {
      const originDataMap: Record<number | string, AnnexDataParam> = {};
      const newDataMap: Record<number | string, AnnexDataParam> = {};

      productAnnex14Data.forEach(({ productAnnex14Id, tradeName }) => {
        originDataMap[productAnnex14Id] = {
          productAnnex14Id,
          tradeName,
        };
      });

      for (const [key, { file, productAnnex14Id, isDelete }] of Object.entries(
        fileMap,
      )) {
        if (productAnnex14Id) {
          const tradeName = formValues.annexItems.find(
            (item) => item.productAnnex14Id === productAnnex14Id,
          )?.tradeName;

          newDataMap[productAnnex14Id] = {
            productAnnex14Id,
            ...(isDelete && { isDelete }),
            ...(tradeName && { tradeName }),
            ...(file && { uploadFile: file }),
          };
        } else if (typeof key === 'string') {
          const tradeName = formValues.annexItems.find(
            (item) => item.tmpId === key,
          )?.tradeName;
          newDataMap[key] = {
            ...(tradeName && { tradeName }),
            ...(file && { uploadFile: file }),
          };
        }
      }

      if (isEqual(originDataMap, newDataMap)) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }

      const newParams: Record<number | string, AnnexDataParam> = {};

      for (const [newKey, newValue] of Object.entries(newDataMap)) {
        const isNewItem = !originDataMap[newKey];
        const isTradeNameChanged =
          originDataMap[newKey]?.tradeName !== newValue.tradeName &&
          newValue.tradeName !== undefined;

        const isDeleteChanged =
          originDataMap[newKey]?.isDelete !== newValue.isDelete;
        const isNewFile = newValue.uploadFile;
        if (isNewItem) {
          newParams[newKey] = {
            tradeName: newValue.tradeName,
            uploadFile: newValue.uploadFile,
          };
        } else {
          newParams[newKey] = {
            ...(isTradeNameChanged && {
              tradeName: newValue.tradeName,
            }),
            ...(isDeleteChanged && {
              isDelete: newValue.isDelete,
            }),
            ...(isNewFile && { uploadFile: newValue.uploadFile }),
            ...((isTradeNameChanged || isDeleteChanged || isNewFile) &&
              !isNaN(Number(newKey)) && {
                productAnnex14Id: newValue.productAnnex14Id,
              }),
          };
        }
      }

      updateProductAnnex14Data(
        {
          ...(countryId && { countryId }),
          params: Object.values(newParams).filter(
            (data) =>
              data.isDelete ||
              data.productAnnex14Id ||
              data.tradeName ||
              data.uploadFile,
          ),
        },
        {
          onSuccess: () => {
            message.success(
              `${
                docStatus?.status === 'MOD' ? '보완 완료' : '수정'
              }되었습니다.`,
            );
            history.goBack();
          },
        },
      );
      return;
    }
    const newDataMap: Record<
      string,
      {
        uploadFile: File;
        tradeName: string;
      }
    > = {};

    for (const [key, { file }] of Object.entries(fileMap)) {
      const tradeName = formValues.annexItems.find((item) => item.tmpId === key)
        ?.tradeName;
      if (!tradeName || !file) return;
      newDataMap[key] = {
        tradeName,
        uploadFile: file,
      };
    }

    addProductAnnex14Data(
      {
        ...(countryId && { countryId }),
        params: Object.values(newDataMap),
      },
      {
        onSuccess: () => {
          message.success('등록되었습니다.');
          history.goBack();
        },
      },
    );
  };

  return (
    <Container>
      <Spin spinning={getLoading || isProductAnnex14DataLoading}>
        <TradeContainer dir="column">
          <Flex align="center" gap={8} className="title">
            <Typography.Text
              style={{
                color: palette.SLATE_GRAY70,
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: 0,
              }}
            >
              ANNEX를 보유한 Trade Name 입니다.
            </Typography.Text>
          </Flex>
          <Typography.Text
            style={{
              color: palette.SLATE_GRAY60,
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: 0,
              marginLeft: 14,
            }}
          >
            클릭 복사하여 하단 Trade Name에 쉽게 입력하세요.
          </Typography.Text>
          <Flex gap={16} style={{ marginLeft: 14, marginTop: 16 }}>
            {formulaBreakdownChinaItems.map(
              ({ hasAnnex14, tradeName, productFormulaBreakdownChinaId }) => {
                const hasAnnexTradeName = hasAnnex14 && tradeName !== null;

                return (
                  hasAnnexTradeName && (
                    <CopyToClipboard
                      text={tradeName}
                      key={productFormulaBreakdownChinaId}
                      onCopy={() =>
                        message.success('Ctrl+V 하여 붙여넣기 할 수 있습니다.')
                      }
                    >
                      <TradeName>
                        {tradeName}
                        <Icon name="copy" size={18} color="PRIMARY50" />
                        <CopyTooltip justify="center" align="cener">
                          클릭하여 복사
                          <TooltipArrow
                            style={{ position: 'absolute', top: '26px' }}
                          />
                        </CopyTooltip>
                      </TradeName>
                    </CopyToClipboard>
                  )
                );
              },
            )}
          </Flex>
        </TradeContainer>
        <Flex
          justify="flex-end"
          align="center"
          gap={8}
          style={{ marginTop: 36, marginBottom: 10 }}
        >
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.ANE}
          />
          {updateMode && (
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.ANE}
              gutter={{ bottom: 0 }}
            />
          )}
        </Flex>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <AnnexItemList
            form={form}
            loading={false}
            fileMap={fileMap}
            docStatus={docStatus}
            updateMode={updateMode}
            readOnlyMode={readOnlyMode}
            setFileMap={setFileMap}
          />
          <FooterBox>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {updateMode && docStatus?.status === 'MOD' && (
                <CorrectRequestWithoutChangeButton
                  documentCode={DocumentCode.ANE}
                />
              )}
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  isAddProductAnnex14DataLoading ||
                  isUpdateProductAnnex14DataLoading
                }
              >
                {!updateMode
                  ? '등록'
                  : docStatus?.status !== 'MOD'
                  ? '수정'
                  : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </Container>
  );
};

export default ProductAnnex14;
