import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from 'antd';
import { isEqual } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { MinusIcon, Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import { messages } from 'lib/consts';
import { filterOptionForStringLabel, focusToInvalidatedField } from 'lib/form';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import { useCompany } from 'service/company';
import {
  RawMaterialBase,
  useKCIAIngredients,
  useRawMaterial,
  useRawMaterialNameEnDuplicateCheck,
} from 'service/material/rawMaterial';
import { useRawMaterialCategories } from 'service/material/rawMaterialSearch';

const RawMaterialBasicBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;

  .ant-select-arrow {
    color: ${palette.GRAY50};
  }

  .ant-checkbox + span {
    padding-right: 4px;
  }
`;

const FormItemWithAdditionalAction = styled(Form.Item)`
  & > .ant-form-item-label > label {
    width: 100%;
  }
`;

export interface RawMaterialForm extends RawMaterialBase {
  efficacies: string[];
  formulaPurposes: string[];
  marketingKeywords?: string;
  retentionCharacteristicsIds: number[];
  kciaIngredientIds: number[];
}

const checkIsDuplicate = (str1: string, str2: string) =>
  str1.replaceAll(' ', '').toLowerCase() ===
  str2.replaceAll(' ', '').toLowerCase();

const RawMaterialBasic = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId) || undefined;
  const updateMode = typeof materialId !== 'undefined';
  const [initFormData, setInitFormData] = useState();
  const [form] = Form.useForm<RawMaterialForm>();

  const {
    formulaPurposes,
    efficacies,
    retentionCharacteristics,
    getLoading: getRawMaterialCategoriesLoading,
  } = useRawMaterialCategories();

  const rawMaterialNameEnDuplicateCheck = useRawMaterialNameEnDuplicateCheck();

  const {
    kciaIngredientsSelectOptions,
    getKCIAIngredientsLoading,
  } = useKCIAIngredients();

  const {
    rawMaterial,
    getLoading: getRawMaterialLoading,
    addRawMaterial,
    addLoading,
    updateRawMaterial,
    updateLoading,
    readOnly,
  } = useRawMaterial(materialId);

  const company = useCompany();

  const handleAddRawMaterial = ({
    marketingKeywords,
    formulaPurposes,
    efficacies,
    retentionCharacteristicsIds,
    ...rawMaterialForm
  }: RawMaterialForm) => {
    const areThereOverLengthWords = (words: string[]) =>
      words.find((word) => word.length > 15);

    const newMarketingKeywords = [
      ...(marketingKeywords
        ? marketingKeywords.split(/,\s?/).map((keyword) => ({ keyword }))
        : []),
      ...formulaPurposes.map((item) => ({ keyword: item })),
      ...efficacies.map((item) => ({ keyword: item })),
      ...retentionCharacteristicsIds.map((item: number) => ({
        keyword: retentionCharacteristics.find(
          (retentionCharacterlistic) =>
            retentionCharacterlistic.materialCategoryId === item,
        )?.nameKo!,
      })),
    ];

    if (
      areThereOverLengthWords(
        newMarketingKeywords.map((keyword) => keyword.keyword),
      )
    ) {
      message.warn('마케팅 키워드를 15글자 이내로 입력해주세요.');
      return;
    } else if (areThereOverLengthWords(formulaPurposes)) {
      message.warn('배합 목적을 15글자 이내로 입력해주세요.');
      return;
    } else if (areThereOverLengthWords(efficacies)) {
      message.warn('효능을 15글자 이내로 입력해주세요.');
      return;
    }

    if (!updateMode) {
      addRawMaterial({
        ...rawMaterialForm,
        efficacies: efficacies?.map((efficacyName) => ({
          efficacyName,
        })),
        formulaPurposes: formulaPurposes?.map((formulaPurposeName) => ({
          formulaPurposeName,
        })),
        ...(newMarketingKeywords.length > 0 && {
          marketingKeywords: newMarketingKeywords,
        }),
        retentionCharacteristicsIds,
      });
    } else {
      if (
        initFormData !== undefined &&
        isEqual(
          {
            marketingKeywords,
            retentionCharacteristicsIds,
            ...rawMaterialForm,
          },
          initFormData as any,
        )
      ) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }

      updateRawMaterial({
        ...rawMaterialForm,
        efficacies: efficacies?.map((efficacyName) => ({
          efficacyName,
        })),
        formulaPurposes: formulaPurposes?.map((formulaPurposeName) => ({
          formulaPurposeName,
        })),
        ...(newMarketingKeywords.length > 0 && {
          marketingKeywords: newMarketingKeywords,
        }),
        retentionCharacteristicsIds: retentionCharacteristicsIds || [],
      });
    }
  };

  useEffect(() => {
    if (rawMaterial) {
      const {
        efficacies,
        formulaPurposes,
        marketingKeywords,
        kciaIngredients,
        retentionCharacteristics,
        ...rest
      } = rawMaterial;

      const initialFormulaPurposes = formulaPurposes.map(
        ({ formulaPurposeName }) => formulaPurposeName,
      );

      const initialEfficacies = efficacies.map(
        ({ efficacyName }) => efficacyName,
      );

      const initialMarketingKeywords = marketingKeywords
        .filter(
          ({ keyword }) =>
            !initialFormulaPurposes.includes(keyword) &&
            !initialEfficacies.includes(keyword) &&
            !retentionCharacteristics
              .map(({ nameEn }) => nameEn)
              .includes(keyword),
        )
        .map(({ keyword }) => keyword)
        .join(', ');

      form.setFieldsValue({
        ...rest,
        efficacies: initialEfficacies,
        formulaPurposes: initialFormulaPurposes,
        kciaIngredientIds: kciaIngredients.map(
          ({ kciaIngredientId }) => kciaIngredientId,
        ),
        marketingKeywords: initialMarketingKeywords,
        retentionCharacteristicsIds: retentionCharacteristics.map(
          ({ materialCategoryId }) => materialCategoryId,
        ),
      });
      setTimeout(() => setInitFormData(form.getFieldsValue() as any), 0);

      return;
    }

    form.resetFields();

    if (process.env.NODE_ENV === 'development' && !updateMode) {
      form.setFieldsValue({
        materialNameEn: `My RawMaterial-${moment().format('YY-MM-DD HH:mm')}`,
        materialNameKo: '테스트 이름',
        isSelfProduction: false,
        efficacies: ['가려움', '간지러움'],
        formulaPurposes: ['pH 조절제', '산도 조절제'],
        originManufacturerName: 'company',
      });
    }
  }, [rawMaterial]);

  return (
    <RawMaterialBasicBlock>
      <Spin spinning={getRawMaterialLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleAddRawMaterial}
          onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
          initialValues={{
            retentionCharacteristicsIds: [],
          }}
        >
          <Form.Item
            label="원료명 (영문)"
            name="materialNameEn"
            required
            rules={[
              ...generateFormRules({
                required: true,
                exceptKorean: true,
                maxLength: 100,
              }),
              {
                validator: async (_, value) => {
                  if (
                    value &&
                    (!updateMode || value !== rawMaterial?.materialNameEn)
                  ) {
                    const res = await rawMaterialNameEnDuplicateCheck(value);
                    if (res.data.result) throw Error('이미 사용중인 원료명');
                  }
                },
              },
            ]}
          >
            <Input placeholder="영문" disabled={readOnly} />
          </Form.Item>
          <Form.Item
            label="원료명 (국문)"
            name="materialNameKo"
            initialValue=""
            rules={generateFormRules({
              maxLength: 100,
            })}
          >
            <Input placeholder="국문" disabled={readOnly} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev: any, next: any) =>
              prev.isSelfProduction !== next.isSelfProduction
            }
          >
            {({ getFieldValue }) => (
              <FormItemWithAdditionalAction
                label={
                  <Flex
                    style={{ width: '100%' }}
                    justify="space-between"
                    align="center"
                  >
                    <div>원료 생산 회사 (원료 제조사)</div>
                    <Flex align="middle">
                      <Form.Item
                        noStyle
                        name="isSelfProduction"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={readOnly}
                          onChange={(e) =>
                            e.target.checked &&
                            form.setFieldsValue({
                              originManufacturerName: company.companyNameEn,
                            })
                          }
                        >
                          자체 제조
                        </Checkbox>
                      </Form.Item>
                    </Flex>
                  </Flex>
                }
                name="originManufacturerName"
                required
                rules={generateFormRules({
                  required: true,
                  exceptKorean: true,
                  maxLength: 100,
                })}
              >
                <Input
                  placeholder="원료의 생산 회사를 영문으로 입력"
                  disabled={readOnly || getFieldValue('isSelfProduction')}
                />
              </FormItemWithAdditionalAction>
            )}
          </Form.Item>
          <Flex gap={4} align="center" style={{ marginBottom: 8 }}>
            <Typography.Text type="TITLE_2" asterisk>
              구성 성분 (INCI Name)
            </Typography.Text>
            <Tip
              trigger="click"
              closeIconStyle={{
                fontSize: 16,
              }}
            >
              <Typography.Text type="SMALL">
                입력되지 않는 INCI Name이 있으신 경우,
                <br />
                전화나 하단의 ‘이용 문의’로 문의해 주세요.
              </Typography.Text>
            </Tip>
          </Flex>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.kciaIngredientIds !== next.kciaIngredientIds
            }
          >
            {({ getFieldValue }) => {
              const kciaIngredientIds: number[] =
                getFieldValue('kciaIngredientIds') || [];

              const filteredKCIAIngredientIds = kciaIngredientsSelectOptions.filter(
                ({ value }) =>
                  kciaIngredientIds.every(
                    (kciaIngredientId) => kciaIngredientId !== value,
                  ),
              );

              return (
                <Form.List name="kciaIngredientIds" initialValue={[undefined]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ name, key }, index) => {
                        const selectedKCIAIngredientsOption = kciaIngredientsSelectOptions.find(
                          ({ value }) => value === kciaIngredientIds[name],
                        );

                        return (
                          <div style={{ position: 'relative' }} key={key}>
                            {!readOnly && fields.length > 1 && (
                              <MinusIcon
                                style={{
                                  position: 'absolute',
                                  right: -32,
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                }}
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            )}
                            <Form.Item
                              name={[name]}
                              required
                              rules={generateFormRules({ required: true })}
                            >
                              <Select
                                loading={getKCIAIngredientsLoading}
                                showSearch
                                disabled={readOnly}
                                placeholder="INCI Name을 검색하여 입력"
                                options={
                                  selectedKCIAIngredientsOption
                                    ? [
                                        selectedKCIAIngredientsOption,
                                        ...filteredKCIAIngredientIds,
                                      ]
                                    : filteredKCIAIngredientIds
                                }
                                filterOption={filterOptionForStringLabel}
                              />
                            </Form.Item>
                          </div>
                        );
                      })}
                      {!readOnly && (
                        <Button
                          type="dashed"
                          icon={
                            <Icon
                              name="plus"
                              color="PRIMARY50"
                              size={14}
                              style={{ marginRight: 4 }}
                            />
                          }
                          block
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 16,
                            maxWidth: 520,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                          onClick={() => {
                            add();
                          }}
                        >
                          INCI Name 추가
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
              );
            }}
          </Form.Item>
          <Row gutter={8} style={{ marginTop: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                required
                label="효능"
                name="efficacies"
                rules={generateFormRules({ required: true })}
              >
                <Select
                  placeholder="선택 또는 직접 입력 후 Enter"
                  mode="tags"
                  options={efficacies.map((efficacy) => ({
                    label: efficacy.efficacyName,
                    value: efficacy.efficacyName,
                  }))}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                required
                label="배합 목적"
                name="formulaPurposes"
                rules={generateFormRules({ required: true })}
                normalize={(selectedValues, prevValues = []) => {
                  if (selectedValues.length === 0) {
                    return selectedValues;
                  }

                  const lastIndex = selectedValues.length - 1;
                  const last = selectedValues[lastIndex];

                  const isDuplicateFormularPurpose = formulaPurposes.find(
                    ({ formulaPurposeName }) =>
                      checkIsDuplicate(formulaPurposeName, last),
                  );

                  const isAlreadyIncluded = selectedValues
                    .slice(0, -1)
                    .find((item: string) => checkIsDuplicate(item, last));

                  selectedValues[lastIndex] = isDuplicateFormularPurpose
                    ? isDuplicateFormularPurpose.formulaPurposeName
                    : last;

                  return isAlreadyIncluded ? prevValues : selectedValues;
                }}
              >
                <Select
                  loading={getRawMaterialCategoriesLoading}
                  placeholder="선택 또는 직접 입력 후 Enter"
                  mode="tags"
                  options={formulaPurposes.map(({ formulaPurposeName }) => ({
                    label: formulaPurposeName,
                    value: formulaPurposeName,
                  }))}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="retentionCharacteristicsIds"
            label={
              <Flex gap={4} align="center">
                <Typography.Text type="TITLE_2">원료 보유 특성</Typography.Text>
                <Tip
                  trigger="click"
                  closeIconStyle={{
                    fontSize: 16,
                  }}
                >
                  <Typography.Text type="SMALL">
                    원료가 보유하고 있는 특성 정보는 마케팅 키워드
                    <br />
                    로서 유저에게 노출됩니다.
                  </Typography.Text>
                </Tip>
              </Flex>
            }
          >
            <Checkbox.Group disabled={readOnly}>
              <Flex rowGap={16} wrap="true">
                {retentionCharacteristics.map((item) => (
                  <div
                    style={{ flex: '1 1 33%' }}
                    key={item.materialCategoryId}
                  >
                    <Flex align="center">
                      <Checkbox value={item.materialCategoryId}>
                        <Typography.Text inline>{item.nameKo}</Typography.Text>
                      </Checkbox>
                      {item.description && (
                        <Tip
                          trigger="click"
                          closeIconStyle={{
                            fontSize: 16,
                          }}
                        >
                          <Typography.Text type="SMALL">
                            {item.description}
                          </Typography.Text>
                        </Tip>
                      )}
                    </Flex>
                  </div>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            label="마케팅 키워드"
            shouldUpdate={(prev, next) =>
              prev.efficacies !== next.efficacies ||
              prev.formulaPurposes !== next.formulaPurposes ||
              prev.marketingKeywords !== next.marketingKeywords ||
              prev.retentionCharacteristicsIds !==
                next.retentionCharacteristicsIds
            }
          >
            {() => {
              const marketingKeywords = new Set<string>(
                form
                  .getFieldValue('marketingKeywords')
                  ?.split(/\s*,\s*/)
                  .filter((keyword: string) => keyword !== '') || [],
              );

              const keywords = [
                ...(form.getFieldValue('efficacies') || []),
                ...(form.getFieldValue('formulaPurposes') || []),
                ...(form
                  .getFieldValue('retentionCharacteristicsIds')
                  ?.map(
                    (item: number) =>
                      retentionCharacteristics.find(
                        (retentionCharacterlistic) =>
                          retentionCharacterlistic.materialCategoryId === item,
                      )?.nameKo,
                  ) || []),
                ...Array.from(marketingKeywords),
              ];

              return (
                <>
                  {keywords.length > 0 && (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                      {keywords.map((keyword: string) => (
                        <Col key={nanoid()}>
                          <Tags.Mark color="blue">{keyword}</Tags.Mark>
                        </Col>
                      ))}
                    </Row>
                  )}
                  <Form.Item noStyle name="marketingKeywords">
                    <Input.TextArea
                      placeholder={
                        '- 쉼표로 구분하여 키워드를 입력해 주세요. (Ex. 키워드1, 키워드2, 키워드3)\n- 띄어쓰기 포함 15글자로 작성해 주세요. (초과 시 15글자로 고정)\n- 효능, 배합 목적을 제외한 키워드를 입력해주세요.\n- 중복으로 입력된 키워드는 하나의 키워드로 취급됩니다.'
                      }
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      disabled={readOnly}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <FooterBox>
            <ReadOnlyBackButton readOnly={readOnly}>
              <Button
                type="primary"
                htmlType="submit"
                loading={addLoading || updateLoading}
              >
                {!updateMode ? '저장' : '수정'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </RawMaterialBasicBlock>
  );
};

export default RawMaterialBasic;
