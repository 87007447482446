import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Form, Spin, Upload, message as toast } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled, { CSSProperties, css } from 'styled-components';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import { ContentEditableEvent } from 'react-contenteditable';

import palette from 'lib/styles/palette';
import { ReactComponent as SendIcon } from 'asset/svg/send.svg';
import { ReactComponent as SendDisabledIcon } from 'asset/svg/send-disabled.svg';
import { Flex } from 'components/ui';
import { Typography } from 'components/system';
import { downloadFile, fileToBase64 } from 'lib/file';
import { NMPAChatMessage } from 'types/material/nmpa/nmpa';
import { messages } from 'lib/consts';
import SimpleEditor, { TextStatus } from 'components/SimpleEditor';
import { getTextStatus } from 'lib/editor';
import { ProductChatMessage } from 'types/certificate';
import Icon from 'components/ui/Icon/Icon';

export interface CorrectChatModalContentProps {
  onClose?: VoidFunction;
  chatMessages: (NMPAChatMessage | ProductChatMessage)[];
  correctRequestChatLoading: boolean;
  messageList: (NMPAMsgList | ProductMsgList)[];
  sendCorrectRequestChat: ({
    message,
    docThreadMessageAttaches,
    onSuccess,
  }: {
    message?: string;
    docThreadMessageAttaches?: {
      attachFile: File | string | null;
    }[];
    onSuccess: VoidFunction;
  }) => void;
}

interface CorrectChatForm {
  message: string;
}

interface MsgList {
  date: string;
  userId?: number;
  userType?: string;
}

export interface NMPAMsgList extends MsgList {
  materialNmpaDocThreadId: number;
  materialNmpaDocThreadMessageId?: number;
  messages: NMPAMsgItem[];
}

export interface ProductMsgList extends MsgList {
  productDocThreadId: number;
  productDocThreadMessageId?: number;
  messages: ProductMsgItem[];
}

interface MsgItem {
  msgId: number;
  dir: 'R' | 'L';
  time: string;
  msg: string;
  images: MsgImage[];
  read?: boolean;
  deleted?: boolean;
}

export interface NMPAMsgItem extends MsgItem {
  materialNmpaDocThreadId: number;
}

export interface ProductMsgItem extends MsgItem {
  productDocThreadId: number;
}

export interface MsgImage {
  src: string;
  name: string;
  type: 'img' | 'file';
  size: number;
}

const customScrollbar = css`
  &::-webkit-scrollbar {
    width: 4px; /* 스크롤바의 너비 */
    height: 4px; /* 스크롤바의 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.SLATE_GRAY70}; /* 스크롤바의 색상 */
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;

const Container = styled(Flex)`
  height: 729px;
`;

const BodyContainer = styled(Flex)`
  width: 100%;
  flex: 1;
  padding: 96px 24px 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  ${customScrollbar}
`;

const Footer = styled(Flex)`
  width: 100%;
  background: white;
  border-radius: 0 0 8px 8px;
`;

const FooterContainer = styled(Flex)`
  padding: 14px 24px;
  width: 100%;
`;

const FooterLine = styled.div`
  height: 1px;
  background: ${palette.GRAY30};
  width: 512px;
`;

const EditButtonWrapper = styled(Flex)`
  position: absolute;
  width: 100%;
  padding: 20px 24px 19px;
  border-bottom: 1px solid ${palette.GRAY30};
  background: rgba(255, 255, 255, 0.88);
  z-index: 9999;
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 32px;
  width: 93px;
  padding: 6px 8px;
`;

const StyledButton = styled(Button)`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 24px;
  &:hover {
    background: transparent;
  }
`;

const InputContainer = styled(Flex)`
  border-radius: 8px;
  padding: 12px 16px;
  background: #f5f8fb; // palette.bgBlue
  font-size: 12px;
  font-weight: 400;
`;

const Line = styled.div`
  height: 1px;
  width: 160px;
  background: ${palette.GRAY30};
`;

const FilePreviewContainer = styled(Flex)`
  width: 416px;
  margin-top: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 6px;

  ${customScrollbar}
`;

const StyledImg = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;
`;

const FileViewerContainer = styled(Flex)`
  width: 168px;
  height: 48px;
  background: ${palette.ETC_WHITE};
  padding: 8px;
  border-radius: 8px;

  &:hover .background {
    display: block !important;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }

  &:hover .download-icon {
    display: flex !important;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }
`;

const FileNameText = styled(Typography.Text)`
  max-width: 100px;
  font-size: 12px;
  font-weight: 400;
  color: #434343; // palette.neutral9;
  letter-spacing: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FileNameExText = styled.span`
  font-size: 12px !important;
`;

const ChatBodyContainer = styled(Flex)`
  width: 100%;
`;

const CurrentChatCotainer = styled(ChatBodyContainer)``;

const DateContainer = styled(Flex)`
  width: 100%;
  margin-bottom: 24px;
`;

const ChatContainer = styled(Flex)`
  width: 100%;
`;

const ChatItemContainer = styled(Flex)<{ location: 'R' | 'L' }>`
  align-items: ${({ location }) =>
    location === 'R' ? 'flex-end' : 'flex-start'};
`;

const ChatItem = styled(Flex)<{ location: 'R' | 'L' }>`
  display: inline-block;
  white-space: pre-wrap;
  max-width: 376px;
  padding: 12px 16px;
  background: ${({ location }) =>
    location === 'R' ? palette.LIGHT_BLUE20 : palette.GRAY20};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${palette.SLATE_GRAY80};
`;

const ChatInImgContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:hover .background {
    display: block !important;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }

  &:hover .download-icon {
    display: flex !important;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }
`;

const ImgBackground = styled.div`
  width: 100%;
  height: 100%;
  background: #222222;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px;
  display: none;
`;

const StyledImgInChat = styled.img`
  height: 120px;
  width: auto;
  max-width: 344px;
  border-radius: 8px;
  object-fit: cover;
`;

const StyledUpload = styled(Upload)`
  height: 34px;
  border-radius: 4px;

  .ant-btn.ant-btn-icon-only {
    height: 34px;
    width: 34px;
  }

  &:hover {
    background: ${palette.GRAY20};
  }
`;

const TextEditButtonWrapper = styled(Flex)`
  position: relative;
  border: 1px solid ${palette.GRAY30};
  border-radius: 4px;

  & > div {
    border-left: 1px solid ${palette.GRAY30};
  }

  & > div:nth-of-type(1) {
    border-radius: 3px 0 0 3px;
    border: none;
  }

  & > div:nth-of-type(4) {
    border-radius: 0 3px 3px 0;
  }
`;

const TextEditButtonContainer = styled.div`
  padding: 7px 8px;
  text-align: center;
  cursor: pointer;

  line-height: 0px;

  &:hover {
    background: ${palette.GRAY20} !important;
  }
`;

const ColorButtonWrapper = styled(Flex)`
  position: absolute;
  top: -38px;
  right: -1px;
  padding: 8px 12px;
  background: ${palette.ETC_WHITE};
  border: 1px solid ${palette.GRAY30};
  border-radius: 4px;
`;

const ColorButton = styled(Flex)<{ color: string }>`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background: ${({ color }) => color};
  border: ${({ color }) =>
    color === 'transparent' && `1px solid ${palette.GRAY40}`};
`;

const StyledIcon = styled(Icon)`
  path:first-of-type,
  path:nth-of-type(2) {
    fill: ${palette.SLATE_GRAY70};
  }
`;

const commands = ['bold', 'underline', 'foreColor', 'backColor'];
const textColors = [
  palette.GRAY90,
  palette.MESSAGE_ERROR,
  palette.MESSAGE_INFO,
  palette.MESSAGE_SUCCESS,
];
const textBackgroundColors = ['transparent', '#FFED65'];

const formatFileSize = (size: number) => {
  if (size < 1024) {
    return size + 'bytes';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(1) + 'KB';
  } else if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + 'MB';
  } else {
    return (size / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
  }
};

const CorrectChatModalContent = ({
  onClose,
  chatMessages,
  correctRequestChatLoading,
  messageList,
  sendCorrectRequestChat,
}: CorrectChatModalContentProps) => {
  const [form] = Form.useForm<CorrectChatForm>();
  const [message, setMessage] = useState('');
  const [previewImages, setPreviewImages] = useState<(string | File)[]>([]);
  const [imagesData, setImagesData] = useState<File[]>([]);
  const [displayError, setDisplayError] = useState(false);
  const [textStatus, setTextStatus] = useState<TextStatus>({
    isBold: false,
    isUnderlined: false,
    isFontColorClicked: false,
    isBackgroundClicked: false,
    fontColor: palette.GRAY90,
    backgroundColor: 'transparent',
    hasColor: false,
    hasBackground: false,
  });
  const filePreviewContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!filePreviewContainerRef.current) return;
    filePreviewContainerRef.current.scrollLeft =
      filePreviewContainerRef.current?.scrollWidth;
  }, [previewImages]);

  const handleSendMessage = (formValues: CorrectChatForm) => {
    const chatBodyarea = document.getElementById('chat-body');
    if (!chatBodyarea) return;

    chatBodyarea.scrollTo({
      top: Number.MAX_SAFE_INTEGER,
      behavior: 'smooth',
    });

    const files: {
      attachFile: File | string | null;
    }[] = [];

    imagesData?.forEach((file) =>
      files.push({
        attachFile: file,
      }),
    );

    sendCorrectRequestChat({
      ...(formValues.message && {
        message: formValues.message,
      }),
      ...(files.length > 0 && {
        docThreadMessageAttaches: files,
      }),
      onSuccess: () => {
        form.setFieldsValue({ message: '' });
        setPreviewImages(() => []);
        setImagesData([]);
        setMessage('');
      },
    });
  };

  const deleteFileOnEditing = (i: number) => {
    const files = [...previewImages];
    const newImgDatas = [...imagesData];

    if (i > -1) {
      files.splice(i, 1);
      setPreviewImages(files);

      newImgDatas.splice(i, 1);
      setImagesData(newImgDatas);
    }
  };

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    const sameFilenameList = fileList
      .map(({ name: uploadFilename }) => uploadFilename)
      .filter((uploadFilename) =>
        imagesData.map(({ name }) => name).includes(uploadFilename),
      );

    if (sameFilenameList.includes(file.name)) {
      toast.warn(messages.DUPLICATE_FILE_NAME);
      return false;
    }
    if (imagesData.length + fileList.length - sameFilenameList.length > 10) {
      setDisplayError(true);
      return false;
    }

    if (file.type.startsWith('image')) {
      fileToBase64(file).then((fileImg) => {
        setPreviewImages((draft) => draft.concat(fileImg));
      });
    } else {
      setPreviewImages((draft) => draft.concat(file));
    }
    setImagesData((draft) => draft.concat(file));

    return false;
  };

  useEffect(() => {
    const chatBodyarea = document.getElementById('chat-body');
    if (!chatBodyarea) return;

    chatBodyarea.scrollTop = Number.MAX_SAFE_INTEGER;
  }, [chatMessages]);

  useEffect(() => {
    if (displayError) {
      toast.warn('한 번에 10개의 파일만 업로드할 수 있습니다.');
      setDisplayError(false);
    }
  }, [displayError]);

  useEffect(() => {
    document.execCommand('styleWithCSS', false);

    /**
     * HINT: 특정 영역 이외 클릭시 닫는 함수.
     * not-close 클래스가 있는 영역도 닫지 않음.
     */
    const handleClick = (e: MouseEvent) => {
      const clickedElement = e.target as HTMLElement;
      const targetClasses = ['not-close'];

      let element: HTMLElement | null = clickedElement;

      while (element !== null) {
        let isBreak = false;

        for (const targetClass of targetClasses) {
          if (element.classList && element.classList.contains(targetClass)) {
            isBreak = true;
            break;
          }
        }

        if (isBreak) {
          break;
        }

        element = element.parentNode as HTMLElement | null;
      }

      if (element === null) {
        setTextStatus((textStatus) => ({
          ...textStatus,
          isFontColorClicked: false,
          isBackgroundClicked: false,
        }));
      }
    };

    document.body.addEventListener('click', handleClick);

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Form form={form} onFinish={handleSendMessage}>
      <Container dir="column" align="stretch">
        <EditButtonWrapper justify="space-between" align="center">
          <Typography.Text medium color="SLATE_GRAY70">
            입력 내용에 수정이 필요하신가요?
          </Typography.Text>
          <EditButton
            type="primary"
            htmlType="button"
            icon={<Icon name="write" size={18} color="ETC_WHITE" />}
            onClick={() => {
              toast.info('입력 내용을 수정해 주세요.');
              onClose?.();
            }}
          >
            수정하기
          </EditButton>
        </EditButtonWrapper>
        <BodyContainer id="chat-body">
          <ChatAreas
            messageList={messageList}
            formatFileSize={formatFileSize}
          />
        </BodyContainer>
        <Footer dir="column" align="center">
          <FooterLine />
          <FooterContainer
            dir="column"
            align="stretch"
            justify="space-between"
            gap={8}
          >
            <Flex gap={4} align="center">
              <StyledUpload
                accept=".pdf,.xlsx,.xls,.png,.jpeg,.jpg"
                itemRender={() => null}
                multiple
                beforeUpload={handleBeforeUpload}
              >
                <StyledButton icon={<StyledIcon name="folderAddFill" />} />
              </StyledUpload>
              <TextEditButtonWrapper>
                {commands.map((cmd) => (
                  <TextEditButton
                    key={cmd}
                    cmd={cmd}
                    textStatus={textStatus}
                    onChangeTextStatus={setTextStatus}
                  />
                ))}
              </TextEditButtonWrapper>
            </Flex>
            <Flex gap={8} align="center">
              <InputContainer
                justify="space-between"
                align="center"
                style={{ flex: 1 }}
              >
                <Flex dir="column" style={{ width: '100%' }}>
                  <Form.Item noStyle name="message">
                    <SimpleEditor
                      contentMaxHeight={100}
                      placeholder="내용을 입력해 주세요."
                      message={message}
                      onChange={(e: ContentEditableEvent) => {
                        setMessage(e.target.value);
                      }}
                      onChangeTextStatus={setTextStatus}
                    />
                  </Form.Item>
                  {previewImages?.length > 0 && (
                    <>
                      <Line
                        style={{
                          width: '100%',
                          backgroundColor: palette.SLATE_GRAY40,
                          marginTop: 16,
                        }}
                      />
                      <FilePreviewContainer
                        gap={10}
                        ref={filePreviewContainerRef}
                      >
                        {previewImages?.map((file, i) => {
                          if (typeof file === 'string') {
                            return (
                              <Flex key={`${i}_type_img`}>
                                <StyledImg src={file} />
                                <Icon
                                  name="smallClose"
                                  size={14}
                                  color="ETC_WHITE"
                                  circleColor="SLATE_GRAY80"
                                  style={{
                                    marginTop: -6,
                                    marginLeft: -7,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => deleteFileOnEditing(i)}
                                />
                              </Flex>
                            );
                          } else {
                            return (
                              <Flex key={`${i}_type_file`}>
                                <FileViewerContainer
                                  justify="flex-start"
                                  gap={8}
                                >
                                  <Icon name="folder" />
                                  <Flex dir="column">
                                    <Flex justify="flex-start">
                                      <FileNameText>
                                        {file.name?.slice(
                                          0,
                                          file.name?.lastIndexOf('.'),
                                        )}
                                      </FileNameText>
                                      <FileNameExText className="last-file-name">
                                        {file.name?.slice(
                                          file.name?.lastIndexOf('.'),
                                        )}
                                      </FileNameExText>
                                    </Flex>
                                    <Typography.Text
                                      style={{
                                        fontSize: 10,
                                        fontWeight: 400,
                                        color: palette.SLATE_GRAY50,
                                        letterSpacing: 0,
                                      }}
                                    >
                                      {formatFileSize(file.size)}
                                    </Typography.Text>
                                  </Flex>
                                </FileViewerContainer>
                                <Icon
                                  name="smallClose"
                                  size={14}
                                  color="ETC_WHITE"
                                  circleColor="SLATE_GRAY80"
                                  style={{
                                    marginTop: -6,
                                    marginLeft: -7,
                                    cursor: 'pointer',
                                    zIndex: 10,
                                  }}
                                  onClick={() => deleteFileOnEditing(i)}
                                />
                              </Flex>
                            );
                          }
                        })}
                      </FilePreviewContainer>
                    </>
                  )}
                </Flex>
              </InputContainer>
              {(message || previewImages?.length > 0) &&
                !correctRequestChatLoading && (
                  <StyledButton icon={<SendIcon />} onClick={form.submit} />
                )}
              {!(message || previewImages?.length > 0) &&
                !correctRequestChatLoading && (
                  <StyledButton
                    icon={<SendDisabledIcon />}
                    style={{ userSelect: 'none', cursor: 'no-drop' }}
                  />
                )}
              {correctRequestChatLoading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 18, color: palette.SLATE_GRAY70 }}
                    />
                  }
                />
              )}
            </Flex>
          </FooterContainer>
        </Footer>
      </Container>
    </Form>
  );
};

const ChatAreas = ({
  messageList,
  formatFileSize,
}: {
  messageList: (NMPAMsgList | ProductMsgList)[];
  formatFileSize: (size: number) => void;
}) => {
  return (
    <CurrentChatCotainer dir="column" gap={24}>
      {messageList?.map((messageListItem, key) => {
        return (
          <Flex dir="column" style={{ width: '100%' }} key={`${key}_msg_list`}>
            <DateContainer justify="space-between" align="center">
              <Line />
              <Typography.Text
                style={{
                  fontSize: 12,
                  letterSpacing: 0,
                }}
                color="SLATE_GRAY70"
              >
                {messageListItem.date}
              </Typography.Text>
              <Line />
            </DateContainer>
            <Flex dir="column" style={{ width: '100%' }} gap={8}>
              {messageListItem.messages.map((i, key) => {
                if (i.msg && i.images?.length === 0 && !i.deleted) {
                  // 텍스트만 있을때
                  return (
                    <ChatContainer
                      dir="column"
                      align="stretch"
                      gap={8}
                      key={`${key}_cur_msg`}
                    >
                      <ChatItemContainer dir="column" location={i.dir} gap={4}>
                        <Flex align="flex-end" gap={8}>
                          <ChatItem
                            location={i.dir}
                            dangerouslySetInnerHTML={{ __html: i.msg }}
                          ></ChatItem>
                        </Flex>
                        <Flex align="center">
                          <Typography.Text
                            style={{
                              fontSize: 10,
                              fontWeight: 400,
                              color: palette.GRAY60,
                              letterSpacing: 0,
                            }}
                          >
                            {moment(i.time).format('a HH:mm')}
                          </Typography.Text>
                        </Flex>
                      </ChatItemContainer>
                    </ChatContainer>
                  );
                } else if (i.images?.length > 0 && !i.deleted) {
                  //텍스트 + images 있을때
                  return (
                    <ChatContainer
                      dir="column"
                      align="stretch"
                      gap={8}
                      key={`${key}_cur_msg_img`}
                    >
                      <ChatItemContainer dir="column" location={i.dir} gap={4}>
                        <Flex align="flex-end" gap={8}>
                          <ChatItem dir="column" location={i.dir}>
                            {i.msg && (
                              <>
                                <div
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: palette.SLATE_GRAY70,
                                    whiteSpace: 'pre-wrap',
                                  }}
                                  dangerouslySetInnerHTML={{ __html: i.msg }}
                                />
                                <Line
                                  style={{
                                    width: '100%',
                                    backgroundColor: palette.SLATE_GRAY40,
                                    margin: '16px 0',
                                  }}
                                />
                              </>
                            )}
                            <Flex gap={8} style={{ flexWrap: 'wrap' }}>
                              {/* type 파일 + 이미지일때 */}
                              {i.images.map((i) => i.type).includes('file') &&
                                i.images.map((i) => i.type).includes('img') &&
                                i.images.map((img, i) => {
                                  return (
                                    <Flex key={`${i}_type_file`}>
                                      <FileViewerContainer
                                        justify="flex-start"
                                        gap={8}
                                        style={{ position: 'relative' }}
                                      >
                                        {img.type === 'file' ? (
                                          <>
                                            <ImgBackground className="background" />
                                            <StyledDownloadIcon img={img} />
                                            <Icon name="folder" />
                                          </>
                                        ) : (
                                          <>
                                            <ImgBackground className="background" />
                                            <StyledDownloadIcon img={img} />
                                            <StyledImg
                                              style={{
                                                width: 24,
                                                height: 24,
                                              }}
                                              src={img.src}
                                            />
                                          </>
                                        )}
                                        <Flex dir="column">
                                          <Flex justify="flex-start">
                                            <FileNameText>
                                              {img.name?.slice(
                                                0,
                                                img.name?.lastIndexOf('.'),
                                              )}
                                            </FileNameText>
                                            <FileNameExText className="last-file-name">
                                              {img.name?.slice(
                                                img.name?.lastIndexOf('.'),
                                              )}
                                            </FileNameExText>
                                          </Flex>
                                          <Typography.Text
                                            style={{
                                              fontSize: 10,
                                              color: palette.SLATE_GRAY50,
                                              letterSpacing: 0,
                                            }}
                                          >
                                            {formatFileSize(img.size)}
                                          </Typography.Text>
                                        </Flex>
                                      </FileViewerContainer>
                                    </Flex>
                                  );
                                })}
                              {!i.images.map((i) => i.type).includes('file') &&
                                i.images.map((i) => i.type).includes('img') &&
                                i.images.map((img, i) => {
                                  return (
                                    <ChatInImgContainer key={`${i}_type_file`}>
                                      <ImgBackground
                                        className="background"
                                        style={{
                                          zIndex: 1,
                                        }}
                                      />
                                      <StyledDownloadIcon
                                        img={img}
                                        style={{ top: 8 }}
                                      />
                                      <Flex style={{ position: 'relative' }}>
                                        <StyledImgInChat src={img.src} />
                                      </Flex>
                                    </ChatInImgContainer>
                                  );
                                })}
                              {i.images.map((i) => i.type).includes('file') &&
                                !i.images.map((i) => i.type).includes('img') &&
                                i.images.map((img, i) => {
                                  return (
                                    <Flex key={`${i}_type_file`}>
                                      <FileViewerContainer
                                        justify="flex-start"
                                        gap={8}
                                        style={{ position: 'relative' }}
                                      >
                                        {img.type === 'file' ? (
                                          <>
                                            <ImgBackground className="background" />
                                            <StyledDownloadIcon img={img} />
                                            <Icon name="folder" />
                                          </>
                                        ) : (
                                          <>
                                            <ImgBackground className="background" />
                                            <StyledDownloadIcon img={img} />
                                            <StyledImg
                                              style={{
                                                width: 24,
                                                height: 24,
                                              }}
                                              src={img.src}
                                            />
                                          </>
                                        )}
                                        <Flex dir="column">
                                          <Flex justify="flex-start">
                                            <FileNameText>
                                              {img.name?.slice(
                                                0,
                                                img.name?.lastIndexOf('.'),
                                              )}
                                            </FileNameText>
                                            <FileNameExText className="last-file-name">
                                              {img.name?.slice(
                                                img.name?.lastIndexOf('.'),
                                              )}
                                            </FileNameExText>
                                          </Flex>
                                          <Typography.Text
                                            style={{
                                              fontSize: 10,
                                              color: palette.SLATE_GRAY50,
                                              letterSpacing: 0,
                                            }}
                                          >
                                            {formatFileSize(img.size)}
                                          </Typography.Text>
                                        </Flex>
                                      </FileViewerContainer>
                                    </Flex>
                                  );
                                })}
                            </Flex>
                          </ChatItem>
                        </Flex>
                        <Flex align="center">
                          <Typography.Text
                            style={{
                              fontSize: 10,
                              color: palette.GRAY60,
                              letterSpacing: 0,
                            }}
                          >
                            {moment(i.time).format('a HH:mm')}
                          </Typography.Text>
                        </Flex>
                      </ChatItemContainer>
                    </ChatContainer>
                  );
                } else if (i.deleted) {
                  return (
                    <ChatContainer
                      dir="column"
                      align="stretch"
                      gap={8}
                      key={`${key}_cur_msg`}
                    >
                      <ChatItemContainer dir="column" location={i.dir} gap={4}>
                        <ChatItem
                          location={i.dir}
                          align="center"
                          gap={8}
                          style={{ display: 'flex' }}
                        >
                          <Icon name="delete" color="SLATE_GRAY50" size={20} />
                          <Typography.Text
                            style={{
                              fontSize: 14,
                              color: palette.SLATE_GRAY50,
                            }}
                          >
                            삭제된 파일입니다.
                          </Typography.Text>
                        </ChatItem>
                        <Typography.Text
                          style={{
                            fontSize: 10,
                            color: palette.GRAY60,
                            letterSpacing: 0,
                          }}
                        >
                          {moment(i.time).format('a HH:mm')}
                        </Typography.Text>
                      </ChatItemContainer>
                    </ChatContainer>
                  );
                }
                return <div key={key}></div>;
              })}
            </Flex>
          </Flex>
        );
      })}
    </CurrentChatCotainer>
  );
};

const TextEditButton = ({
  cmd,
  arg,
  textStatus,
  onChangeTextStatus,
}: {
  cmd: string;
  arg?: string;
  textStatus: TextStatus;
  onChangeTextStatus: React.Dispatch<SetStateAction<TextStatus>>;
}) => {
  const isChangeColor = cmd === 'foreColor';
  const isChangeBackground = cmd === 'backColor';

  const getStatusIsActive = () => {
    if (cmd === 'bold') {
      return textStatus.isBold;
    } else if (cmd === 'underline') {
      return textStatus.isUnderlined;
    } else if (cmd === 'foreColor') {
      return textStatus.isFontColorClicked || textStatus.hasColor;
    } else if (cmd === 'backColor') {
      return textStatus.isBackgroundClicked || textStatus.hasBackground;
    }
  };

  const getIcon = () => {
    if (cmd === 'bold') {
      return <Icon name="textBold" size={18} />;
    } else if (cmd === 'underline') {
      return <Icon name="textUnderline" size={18} />;
    } else if (cmd === 'foreColor') {
      return <Icon name="textColor" size={18} />;
    } else if (cmd === 'backColor') {
      return <Icon name="textBackcolor" size={18} />;
    }
  };

  const handleClick = () => {
    if (!isChangeColor && !isChangeBackground) {
      document.execCommand(cmd, false, arg);
    }

    const {
      isBold,
      isUnderlined,
      hasColor,
      hasBackground,
      fontColor,
      backgroundColor,
    } = getTextStatus();

    if (isChangeColor || isChangeBackground) {
      onChangeTextStatus((textStatus) => ({
        ...textStatus,
        isBold,
        isUnderlined,
        hasColor,
        hasBackground,
        fontColor,
        backgroundColor,
        ...(isChangeColor && {
          isFontColorClicked: !textStatus.isFontColorClicked,
          isBackgroundClicked: false,
        }),
        ...(isChangeBackground && {
          isFontColorClicked: false,
          isBackgroundClicked: !textStatus.isBackgroundClicked,
        }),
      }));

      return;
    }

    onChangeTextStatus((textStatus) => ({
      ...textStatus,
      isBold,
      isUnderlined,
      hasColor,
      hasBackground,
    }));
  };

  const handleClickColorButton = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    color: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (cmd === 'backColor' && color === 'transparent') {
      document.execCommand(cmd, false, 'initial');
    } else {
      document.execCommand(cmd, false, color);
    }

    const {
      isBold,
      isUnderlined,
      hasColor,
      hasBackground,
      fontColor,
      backgroundColor,
    } = getTextStatus();

    onChangeTextStatus({
      ...textStatus,
      isBold,
      isUnderlined,
      hasColor,
      hasBackground,
      fontColor,
      backgroundColor,
      ...(isChangeColor && { fontColor: color }),
      ...(isChangeBackground && { backgroundColor: color }),
      isFontColorClicked: false,
      isBackgroundClicked: false,
    });
  };

  return (
    <TextEditButtonContainer
      className="not-close"
      key={cmd}
      onMouseDown={(e) => e.preventDefault()} // Avoids loosing focus from the editable area
      onClick={handleClick}
      style={{ background: getStatusIsActive() ? palette.GRAY20 : '#fff' }}
    >
      {getIcon()}
      {isChangeColor && textStatus.isFontColorClicked && (
        <ColorButtonWrapper
          className="not-close"
          gap={8}
          onClick={(e) => e.stopPropagation()}
        >
          {textColors.map((color) => (
            <ColorButton
              className="not-close"
              key={color}
              color={color}
              onClick={(e) => {
                handleClickColorButton(e, color);
              }}
            >
              {color === textStatus.fontColor && (
                <Icon name="check" size={12} color="ETC_WHITE" />
              )}
            </ColorButton>
          ))}
        </ColorButtonWrapper>
      )}
      {isChangeBackground && textStatus.isBackgroundClicked && (
        <ColorButtonWrapper
          className="not-close"
          gap={8}
          onClick={(e) => e.stopPropagation()}
        >
          {textBackgroundColors.map((color) => (
            <ColorButton
              className="not-close"
              key={color}
              color={color}
              onClick={(e) => {
                handleClickColorButton(e, color);
              }}
            >
              {color === textStatus.backgroundColor && (
                <Icon name="check" size={12} color="SLATE_GRAY70" />
              )}
            </ColorButton>
          ))}
        </ColorButtonWrapper>
      )}
    </TextEditButtonContainer>
  );
};

const StyledDownloadIcon = ({
  img,
  style,
}: {
  img: MsgImage;
  style?: CSSProperties;
}) => {
  return (
    <Icon
      className="download-icon"
      name="download"
      size={16}
      color="SLATE_GRAY70"
      onClick={() => {
        downloadFile(img.src, img.name);
      }}
      style={{
        display: 'none',
        position: 'absolute',
        top: 12,
        right: 8,
        background: palette.ETC_WHITE,
        padding: 3,
        borderRadius: 4,
        zIndex: 1,
        ...style,
      }}
    />
  );
};

export default CorrectChatModalContent;
