import { PageHeader } from 'antd';
import { nanoid } from 'nanoid';
import React from 'react';
import { GlassMagnifier } from 'react-image-magnifiers';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeCard from 'components/system/data/NoticeCard';
import Typography from 'components/system/general/Typography';
import Icon from 'components/ui/Icon/Icon';
import SubNoticeCard from 'components/ui/SubNoticeCard';

const PageTemplateBlock = styled.div`
  margin: 0 auto;

  .ant-page-header-back {
    margin-right: 16px;
  }
  .ant-page-header-heading-title {
    margin-right: 16px;
  }

  .ant-page-header-heading-left {
    width: 100%;
  }

  .ant-page-header-heading-sub-title {
    margin-right: 0;
  }
`;

const ExampleBlock = styled.div`
  margin: 32px 0;
`;

const ChildrenBlock = styled.div`
  padding-top: 36px;
`;

export interface PageTemplateProps {
  title?: string;
  titleStyle?: React.CSSProperties;
  subtitle?: React.ReactNode;
  description?: string;
  notices?: React.ReactNode[];
  subInfo?: string;
  exampleImg?: string;
  exampleImgUrl?: string;
  exampleImgWidth?: number;
  back?: boolean;
  magnify?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  childrenStyle?: React.CSSProperties;
  onBack?: () => void;
  subnotices?: {
    title?: React.ReactNode;
    content?: React.ReactNode;
    bottomText?: React.ReactNode;
  };
  headerStyle?: React.CSSProperties;
}

const PageTemplate = ({
  title,
  titleStyle,
  subtitle,
  description,
  notices,
  subInfo,
  exampleImg,
  exampleImgUrl,
  exampleImgWidth = 800,
  back = true,
  magnify = true,
  style,
  children,
  childrenStyle,
  onBack,
  subnotices,
  headerStyle,
}: PageTemplateProps) => {
  const history = useHistory();
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  return (
    <PageTemplateBlock style={style}>
      {title && (
        <PageHeader
          title={
            <Typography.Text light type="HEADLINE_1" style={titleStyle}>
              {title}
            </Typography.Text>
          }
          subTitle={subtitle}
          backIcon={<Icon name="back" />}
          style={{ padding: 0, ...headerStyle }}
          onBack={back ? handleBack : undefined}
        />
      )}
      {description && (
        <Typography.Text
          type="BODY_2"
          gutter={{ top: 4 }}
          color="GRAY70"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {description}
        </Typography.Text>
      )}
      {notices && (
        <NoticeCard title="안내사항">
          <ul>
            {notices.map((notice) => (
              <li key={nanoid()}>{notice}</li>
            ))}
          </ul>
        </NoticeCard>
      )}
      {subInfo}
      {subnotices && <SubNoticeCard gutter={{ top: 36 }} {...subnotices} />}
      {(exampleImg || exampleImgUrl) && (
        <ExampleBlock>
          <Typography.Text type="TITLE_2" style={{ marginBottom: 16 }}>
            업로드 예시
          </Typography.Text>
          {magnify ? (
            <GlassMagnifier
              imageSrc={
                exampleImgUrl ||
                `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/${exampleImg}`
              }
              imageAlt=""
              largeImageSrc={
                exampleImgUrl ||
                `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/${exampleImg}`
              }
              cursorStyle="zoom-in"
              magnifierSize="50%"
              style={{ maxWidth: exampleImgWidth, margin: '0 auto' }}
            />
          ) : (
            <img
              src={
                exampleImgUrl ||
                `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/${exampleImg}`
              }
              alt="example"
              style={{
                display: 'block',
                maxWidth: exampleImgWidth,
                margin: '0 auto',
              }}
            />
          )}
        </ExampleBlock>
      )}
      <ChildrenBlock style={childrenStyle}>{children}</ChildrenBlock>
    </PageTemplateBlock>
  );
};

export default PageTemplate;
