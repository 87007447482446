import { useEffect, useState } from 'react';
import { Button, Col, List, message, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled, { css } from 'styled-components';

import SearchableList from 'components/SearchableList';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import {
  useNotarizationBuyers,
  useProductBuyers,
} from 'service/brand/buyer/buyer';
import { useNotarizationCountries } from 'service/country';
import { useCompany } from 'service/company';
import { Buyer, ProductBuyer } from 'types/brand/buyer';
import { NotarizationCountry } from 'types/country';
import { usePublishNotarization } from 'service/brand/product/notarization';
import { NotarizationDocumentCode } from 'types/brand/notarization';
import DocumentPreviewModal from 'components/modal/product/DocumentPreviewModal';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const NotarizationBlock = styled.div``;

const ItemBlock = styled(List.Item)<{ selected: boolean }>`
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  background-color: ${(props) =>
    props.selected ? palette.LIGHT_BLUE20 : palette.ETC_WHITE};

  ${(props) =>
    !props.selected &&
    css`
      &:hover {
        background-color: ${palette.LIGHT_BLUE10};
      }
    `}
`;

const columns: ColumnsType<ProductBuyer> = [
  {
    title: '제품명 (용량)',
    render: (_, { product, buyer }) => (
      <Typography.Text type="BODY_2" color="GRAY90" medium>
        {`${
          product.productDetails.find(
            ({ countryId }) => countryId === buyer.countryId,
          )?.productNameEn
        } ${
          product.netWeight
            ? `(${product.netWeight} ${product.netWeightUnit})`
            : '( - )'
        }`}
      </Typography.Text>
    ),
  },
];

const Notarization = () => {
  const {
    notarizationCountries,
    getLoading: getNotarizationCountriesLoading,
  } = useNotarizationCountries();
  const [
    selectedCountry,
    setSelectedCountry,
  ] = useState<NotarizationCountry | null>(null);
  const { buyers, getLoading: getBuyersLoading } = useNotarizationBuyers(
    selectedCountry?.countryCode || null,
  );
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [selectedProductBuyerIds, setSelectedProductBuyerIds] = useState<
    number[]
  >([]);
  const {
    publishNotarization,
    publishedDocumentUrl,
    initPublishedDocumentUrl,
    loadingDocumentCode,
  } = usePublishNotarization();
  const { companyId } = useCompany();
  const {
    productBuyers,
    getLoading: getProductBuyersLoading,
  } = useProductBuyers({
    companyId,
    buyerId: selectedBuyer?.buyerId,
  });
  const handlePublishDocument = (documentCode: NotarizationDocumentCode) => {
    if (selectedProductBuyerIds.length === 0) {
      return message.warn('제품을 선택해 주세요.');
    }
    publishNotarization({
      documentCode,
      buyer: selectedBuyer!,
      productBuyers: productBuyers.filter(({ productBuyerId }) =>
        selectedProductBuyerIds.includes(productBuyerId),
      ),
    });
  };
  useEffect(() => {
    if (selectedCountry) {
      setSelectedBuyer(null);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedBuyer) {
      setSelectedProductBuyerIds([]);
    }
  }, [selectedBuyer]);
  return (
    <NotarizationBlock>
      {publishedDocumentUrl && (
        <DocumentPreviewModal
          documentUrls={publishedDocumentUrl}
          onClose={initPublishedDocumentUrl}
        />
      )}
      <Row gutter={[16, 32]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <SearchableList
            title="국가 선택"
            list={notarizationCountries}
            filterList={({ countryNameKo }, keyword) =>
              countryNameKo.includes(keyword)
            }
            loading={getNotarizationCountriesLoading}
            renderItem={(country) => (
              <ItemBlock
                onClick={() => setSelectedCountry(country)}
                selected={country === selectedCountry}
              >
                <img
                  src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${country.countryCode.toLowerCase()}.png`}
                  className="logo"
                  alt=""
                  style={{ width: 20, marginRight: '8px' }}
                />
                {country.countryNameKo}
              </ItemBlock>
            )}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <SearchableList
            title="바이어 선택"
            list={buyers}
            loading={getBuyersLoading}
            filterList={({ companyName }, keyword) =>
              companyName.includes(keyword)
            }
            renderItem={(buyer) => (
              <ItemBlock
                onClick={() => setSelectedBuyer(buyer)}
                selected={buyer === selectedBuyer}
              >
                {buyer.companyName}
              </ItemBlock>
            )}
          />
        </Col>
      </Row>
      <Typography.Text type="TITLE_1" gutter={{ top: 32, bottom: 16 }}>
        제품 선택
      </Typography.Text>
      <Table
        columns={columns}
        dataSource={productBuyers}
        rowKey={({ productBuyerId }) => productBuyerId}
        loading={getProductBuyersLoading}
        rowSelection={{
          selectedRowKeys: selectedProductBuyerIds,
          onChange: (keys) =>
            setSelectedProductBuyerIds(keys.map((key) => Number(key))),
        }}
        pagination={false}
      />
      {selectedCountry && selectedBuyer && (
        <Row gutter={16} justify="center" style={{ marginTop: 24 }}>
          {selectedCountry.documentSeqs.map(
            ({ documentCode, documentName }) => (
              <Col key={documentCode}>
                <Button
                  loading={loadingDocumentCode === documentCode}
                  icon={
                    <Icon
                      name="download"
                      color="PRIMARY50"
                      size={20}
                      style={{ marginRight: 4 }}
                    />
                  }
                  block
                  style={{
                    marginBottom: 8,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handlePublishDocument(documentCode)}
                >
                  {documentName}
                </Button>
              </Col>
            ),
          )}
        </Row>
      )}
    </NotarizationBlock>
  );
};

export default Notarization;
