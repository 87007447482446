import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { message } from 'antd';

import { createRequestSaga, createRequestActionTypes } from 'lib/saga';
import * as productApi from 'lib/api/product';
import * as manuProductApi from 'lib/api/manufacturer/product';
import path from 'lib/path';
import history from 'lib/history';
import { finishLoading, startLoading } from 'modules/loading';
import * as certificateActions from 'modules/certificate';
import { CompanyType } from 'types/company';

// 공통
const SET_SKIP_MODE = 'product/SET_SKIP_MODE';
const SKIP_PAGE = 'product/SKIP_PAGE';
const SET_NEXT_PATHNAME = 'product/SET_NEXT_PATHNAME';
const MANU_SET_NEXT_PATHNAME = 'product/MANU_SET_NEXT_PATHNAME';
const [GET_DOCUMENT_LOGS, GET_DOCUMENT_LOGS_SUCCESS] = createRequestActionTypes(
  'product/GET_DOCUMENT_LOGS',
);
const INITIALIZE_DOCUMENT_LOGS = 'product/INITIALIZE_DOCUMENT_LOGS';

// Product
const [MANU_GET_PRODUCTS, MANU_GET_PRODUCTS_SUCCESS] = createRequestActionTypes(
  'product/MANU_GET_PRODUCTS',
);
const [
  UPDATE_CHINESE_PRODUCT_NAME,
  UPDATE_CHINESE_PRODUCT_NAME_SUCCESS,
] = createRequestActionTypes('product/UPDATE_CHINESE_PRODUCT_NAME');
const [
  UPDATE_COMPANY_INFO,
  UPDATE_COMPANY_INFO_SUCCESS,
] = createRequestActionTypes('product/UPDATE_COMPANY_INFO');
const [REGISTER_PRODUCT] = createRequestActionTypes('product/REGISTER_PRODUCT');
const [MANU_REGISTER_PRODUCT] = createRequestActionTypes(
  'product/MANU_REGISTER_PRODUCT',
);
const [GET_DOCUMENTS, GET_DOCUMENTS_SUCCESS] = createRequestActionTypes(
  'product/GET_DOCUMENTS',
);
const SET_DOCUMENT_CODES = 'product/SET_DOCUMENT_CODES';
const MANU_SET_DOCUMENT_CODES = 'product/MANU_SET_DOCUMENT_CODES';
const [
  MANU_GET_DOCUMENTS,
  MANU_GET_DOCUMENTS_SUCCESS,
] = createRequestActionTypes('product/MANU_GET_DOCUMENTS');
const SET_CURRENT_DOC_STATUS = 'product/SET_CURRENT_DOC_STATUS';
const INITIALIZE_DOCUMENTS = 'product/INITIALIZE_DOCUMENTS';
const MANU_INITIALIZE_DOCUMENTS = 'product/MANU_INITIALIZE_DOCUMENTS';
const UPDATE_CURRENT_PATHNAME = 'product/UPDATE_CURRENT_PATHNAME';
const SET_CURRENT_PRODUCT = 'product/SET_CURRENT_PRODUCT';
const [MANU_ADD_PRODUCT, MANU_ADD_PRODUCT_SUCCESS] = createRequestActionTypes(
  'product/MANU_ADD_PRODUCT',
);
const [
  MANU_UPDATE_PRODUCT,
  MANU_UPDATE_PRODUCT_SUCCESS,
] = createRequestActionTypes('product/MANU_UPDATE_PRODUCT');
const [GET_COUNTRIES, GET_COUNTRIES_SUCCESS] = createRequestActionTypes(
  'product/GET_COUNTRIES',
);
const [
  SET_PRODUCT_EXPIRE_MONTHS,
  SET_PRODUCT_EXPIRE_MONTHS_SUCCESS,
] = createRequestActionTypes('product/SET_PRODUCT_EXPIRE_MONTHS');

// Artwork
const [
  GET_PRODUCT_ARTWORKS,
  GET_PRODUCT_ARTWORKS_SUCCESS,
] = createRequestActionTypes('product/GET_PRODUCT_ARTWORKS');
const INITIALIZE_ARTWORK = 'product/INITIALIZE_ARTWORK';

// Stability Test
const [ADD_PRODUCT_STABILITY_TEST] = createRequestActionTypes(
  'product/ADD_PRODUCT_STABILITY_TEST',
);
const [UPDATE_PRODUCT_STABILITY_TEST] = createRequestActionTypes(
  'product/UPDATE_PRODUCT_STABILITY_TEST',
);
const INITIALIZE_PRODUCT_STABILITY_TEST =
  'product/INITIALIZE_PRODUCT_STABILITY_TEST';

// 공통
export const setSkipMode = createAction(SET_SKIP_MODE);
export const skipPage = createAction(SKIP_PAGE);
export const setNextPathname = createAction(SET_NEXT_PATHNAME);
export const manuSetNextPathname = createAction(MANU_SET_NEXT_PATHNAME);
const moveToNextPathnameSaga = function* () {
  const { manuMode, nextPathname, skipMode, productId } = yield select(
    ({ company, product }) => ({
      manuMode: company.company.companyType === CompanyType.MANUFACTURE,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
      productId: product.currentProduct?.productId,
    }),
  );

  try {
    if (skipMode) {
      if (nextPathname === 'finish') {
        if (!manuMode) {
          history.replace(`/product/${productId}`);
        } else {
          history.replace(`/manufacturer/product/${productId}`);
        }
      } else {
        history.replace(
          `/product/${productId}/${nextPathname}`,
          nextPathname === 'phy' && manuMode
            ? { referer: path.manufacturer.product.basic }
            : null,
        );
        if (!manuMode) {
          yield put(setNextPathname(nextPathname));
        } else {
          yield put(manuSetNextPathname(nextPathname));
        }
      }
    } else {
      history.goBack();
      message.success('입력되었습니다.');
    }
  } catch (e) {
    console.error(e);
  }
};
const updateProductSuccessSaga = function* ({ type }) {
  const { currentDocStatus } = yield select(({ product }) => product);
  if (currentDocStatus && currentDocStatus.status === 'MOD') {
    yield put(
      certificateActions.fixDocument(currentDocStatus.productDocStatusId),
    );
    message.success('보완 완료되었습니다.');
  } else {
    message.success('수정되었습니다.');
  }

  if (
    type !== UPDATE_CHINESE_PRODUCT_NAME_SUCCESS &&
    type !== UPDATE_COMPANY_INFO_SUCCESS
  ) {
    history.goBack();
  } else {
    const certTargetId = yield select(
      ({ certificate }) => certificate.certificate.certTargetId,
    );
    yield put(certificateActions.getDocuments(certTargetId));
  }
};
export const getDocumentLogs = createAction(GET_DOCUMENT_LOGS);
const getDocumentLogsSaga = createRequestSaga(
  GET_DOCUMENT_LOGS,
  productApi.getDocumentLogs,
);
export const initializeDocumentLogs = createAction(INITIALIZE_DOCUMENT_LOGS);

// Product
export const manuGetProducts = createAction(MANU_GET_PRODUCTS);
const manuGetProductsSaga = createRequestSaga(
  MANU_GET_PRODUCTS,
  productApi.manuGetProducts,
);
export const updateChineseProductName = createAction(
  UPDATE_CHINESE_PRODUCT_NAME,
);
export const updateCompanyInfo = createAction(UPDATE_COMPANY_INFO);
const updateCompanyInfoSaga = createRequestSaga(
  UPDATE_COMPANY_INFO,
  productApi.updateProduct,
  {
    onSuccess: function* () {
      yield put(certificateActions.toggleCompanyInfoModalVisible());
    },
  },
);
export const registerProduct = createAction(REGISTER_PRODUCT);
const registerProductSaga = createRequestSaga(
  REGISTER_PRODUCT,
  productApi.updateProduct,
  {
    onSuccess: function* () {
      const history = yield getContext('history');
      history.push(path.product.registered);
    },
  },
);
export const manuRegisterProduct = createAction(MANU_REGISTER_PRODUCT);
const manuRegisterProductSaga = createRequestSaga(
  MANU_REGISTER_PRODUCT,
  productApi.manuRegisterProduct,
  {
    onSuccess: function* () {
      const history = yield getContext('history');
      history.push(path.manufacturer.product.list);
    },
  },
);
export const getDocuments = createAction(GET_DOCUMENTS);
const getDocumentsSaga = createRequestSaga(
  GET_DOCUMENTS,
  productApi.getDocuments,
);
export const manuGetDocuments = createAction(MANU_GET_DOCUMENTS);
const manuGetDocumentsSaga = createRequestSaga(
  MANU_GET_DOCUMENTS,
  productApi.manuGetDocuments,
);
export const setCurrentDocStatus = createAction(SET_CURRENT_DOC_STATUS);
export const initializeDocuments = createAction(INITIALIZE_DOCUMENTS);
export const manuInitializeDocuments = createAction(MANU_INITIALIZE_DOCUMENTS);
export const setProductExpireMonths = createAction(SET_PRODUCT_EXPIRE_MONTHS);
const setProductExpireMonthsSaga = function* ({ payload }) {
  const {
    productId,
    countryId,
    productExpireMonths,
    certTargetId,
    updateMode,
  } = payload;
  yield put(startLoading(SET_PRODUCT_EXPIRE_MONTHS));
  try {
    yield call(productApi.updateShelfLifeDeclaration, {
      productId,
      countryId,
      productExpireMonths,
    });
    yield put({
      type: SET_PRODUCT_EXPIRE_MONTHS_SUCCESS,
      payload,
    });
    yield put(certificateActions.toggleShelfLifeMonthModalVisible());
    if (updateMode) {
      const { productDocStatusId } = yield select(
        ({ product }) => product.currentDocStatus,
      );
      yield put(certificateActions.fixDocument(productDocStatusId));
      message.success('보완 완료되었습니다.');
    } else {
      yield put(certificateActions.getDocuments(certTargetId));
      message.success('입력되었습니다.');
    }
  } catch (error) {}
  yield put(finishLoading(SET_PRODUCT_EXPIRE_MONTHS));
};

export const updateCurrentPathname = createAction(UPDATE_CURRENT_PATHNAME);
export const setCurrentProduct = createAction(SET_CURRENT_PRODUCT);

export const manuAddProduct = createAction(MANU_ADD_PRODUCT);
const manuAddProductSaga = function* ({ payload }) {
  yield put(startLoading(MANU_ADD_PRODUCT));
  try {
    const {
      data: { result: addedProduct },
    } = yield call(manuProductApi.addProductBasic, payload);
    const { productId } = addedProduct;
    yield put({
      type: MANU_ADD_PRODUCT_SUCCESS,
      payload: addedProduct,
    });
    yield put(manuGetDocuments(productId));
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(MANU_ADD_PRODUCT));
};
export const manuUpdateProduct = createAction(MANU_UPDATE_PRODUCT);
const manuUpdateProductSaga = function* ({ payload }) {
  yield put(startLoading(MANU_UPDATE_PRODUCT));
  try {
    const { productId } = payload;

    // 제품 수정
    yield call(manuProductApi.updateProductBasic, payload);
    yield put({
      type: MANU_UPDATE_PRODUCT_SUCCESS,
    });
    yield put(manuGetDocuments(productId));
  } catch (error) {
    console.error(error);
  }
  yield put(finishLoading(MANU_UPDATE_PRODUCT));
};

// Country
export const getCountries = createAction(GET_COUNTRIES);
const getCountriesSaga = createRequestSaga(
  GET_COUNTRIES,
  productApi.getCountries,
);

// Artwork
export const getProductArtworks = createAction(GET_PRODUCT_ARTWORKS);
const getProductArtworksSaga = createRequestSaga(
  GET_PRODUCT_ARTWORKS,
  productApi.getProductArtworks,
);
export const initializeArtwork = createAction(INITIALIZE_ARTWORK);

// Stability Test
export const addProductStabilityTest = createAction(ADD_PRODUCT_STABILITY_TEST);
const addProductStabilityTestSaga = createRequestSaga(
  ADD_PRODUCT_STABILITY_TEST,
  productApi.addProductStabilityTest,
);
export const updateProductStabilityTest = createAction(
  UPDATE_PRODUCT_STABILITY_TEST,
);
const updateProductStabilityTestSaga = createRequestSaga(
  UPDATE_PRODUCT_STABILITY_TEST,
  productApi.updateProductStabilityTest,
);

export const productSaga = function* () {
  // 공통
  yield takeLatest(
    ({ type }) =>
      /^product\/(MANU_)?ADD_PRODUCT.*_SUCCESS$/.test(type) ||
      type === UPDATE_CURRENT_PATHNAME ||
      type === SKIP_PAGE,
    moveToNextPathnameSaga,
  );
  yield takeLatest(
    ({ type }) =>
      /^product\/(MANU_)?UPDATE_PRODUCT.*_SUCCESS$/.test(type) ||
      type === UPDATE_CHINESE_PRODUCT_NAME_SUCCESS ||
      type === UPDATE_COMPANY_INFO_SUCCESS ||
      type === 'buyer/UPDATE_PRODUCT_BUYERS_SUCCESS',
    updateProductSuccessSaga,
  );
  yield takeLatest(GET_DOCUMENT_LOGS, getDocumentLogsSaga);

  // Product
  yield takeLatest(MANU_GET_PRODUCTS, manuGetProductsSaga);

  yield takeLatest(UPDATE_COMPANY_INFO, updateCompanyInfoSaga);

  yield takeLatest(REGISTER_PRODUCT, registerProductSaga);
  yield takeLatest(MANU_REGISTER_PRODUCT, manuRegisterProductSaga);
  yield takeLatest(GET_DOCUMENTS, getDocumentsSaga);
  yield takeLatest(MANU_GET_DOCUMENTS, manuGetDocumentsSaga);
  yield takeLatest(MANU_ADD_PRODUCT, manuAddProductSaga);
  yield takeLatest(MANU_UPDATE_PRODUCT, manuUpdateProductSaga);
  yield takeLatest(SET_PRODUCT_EXPIRE_MONTHS, setProductExpireMonthsSaga);

  // Country
  yield takeLatest(GET_COUNTRIES, getCountriesSaga);

  // Artwork
  yield takeLatest(GET_PRODUCT_ARTWORKS, getProductArtworksSaga);

  // Stability Test
  yield takeLatest(ADD_PRODUCT_STABILITY_TEST, addProductStabilityTestSaga);
  yield takeLatest(
    UPDATE_PRODUCT_STABILITY_TEST,
    updateProductStabilityTestSaga,
  );
};

const initialState = {
  skipMode: false,
  nextPathname: null,
  manuProducts: [],
  currentProduct: null,
  countries: [],
  documents: [],
  documentCodes: ['basic', 'cat', 'country', 'phy', 'hmms'],
  manuDocuments: [],
  manuDocumentCodes: ['basic', 'phy', 'hmms'],
  documentLogs: [],
  product: {
    productNameEnDupCheck: {
      status: 'INIT',
      duplicatedProduct: null,
      data: null,
    },
  },
  artwork: [],
  isArtworkLegacy: null,
  stabilityTest: null,
};

export default handleActions(
  {
    [SET_SKIP_MODE]: (state, { payload: skipMode }) =>
      produce(state, (draft) => {
        draft.skipMode = skipMode;
      }),
    [SET_NEXT_PATHNAME]: (state, { payload: currentPathname }) =>
      produce(state, (draft) => {
        const currentIndex = state.documentCodes.indexOf(currentPathname);
        draft.nextPathname =
          currentIndex === state.documentCodes.length - 1
            ? 'finish'
            : state.documentCodes[currentIndex + 1];
      }),
    [MANU_SET_NEXT_PATHNAME]: (state, { payload: currentPathname }) =>
      produce(state, (draft) => {
        const currentIndex = state.manuDocumentCodes.indexOf(currentPathname);
        draft.nextPathname =
          currentIndex === state.manuDocumentCodes.length - 1
            ? 'finish'
            : state.manuDocumentCodes[currentIndex + 1];
      }),
    [GET_DOCUMENT_LOGS_SUCCESS]: (state, { payload: documentLogs }) =>
      produce(state, (draft) => {
        draft.documentLogs = documentLogs.logs;
      }),
    [INITIALIZE_DOCUMENT_LOGS]: (state) =>
      produce(state, (draft) => {
        draft.documentLogs = [];
      }),
    [MANU_GET_PRODUCTS_SUCCESS]: (state, { payload: manuProducts }) =>
      produce(state, (draft) => {
        draft.manuProducts = manuProducts;
      }),
    [INITIALIZE_DOCUMENTS]: (state) =>
      produce(state, (draft) => {
        draft.documents = [];
        draft.documentCodes = initialState.documentCodes;
      }),
    [MANU_INITIALIZE_DOCUMENTS]: (state) =>
      produce(state, (draft) => {
        draft.manuDocuments = [];
        draft.manuDocumentCodes = initialState.manuDocumentCodes;
      }),
    [GET_DOCUMENTS_SUCCESS]: (state, { payload: documents }) =>
      produce(state, (draft) => {
        // 정책 : Heavy Metals & Microbiological Standard의 경우 미입력 된 경우에만 노출
        const parsedDocuments = documents
          .filter(
            (document) =>
              document.documentCode !== 'HMMS01' || document.status === 'INP',
          )
          .map((document, index) => ({
            ...document,
            no: index + 1,
            documentCode: document.documentCode
              .replace(/\d+/, '')
              .toLowerCase(),
          }));
        draft.documents = parsedDocuments;
        draft.documentCodes = parsedDocuments
          .filter((item) => item.status !== 'OPT')
          .map(({ documentCode }) => documentCode);
      }),
    [MANU_GET_DOCUMENTS_SUCCESS]: (state, { payload: documents }) =>
      produce(state, (draft) => {
        // 정책 : Heavy Metals & Microbiological Standard의 경우 미입력 된 경우에만 노출
        const parsedDocuments = documents
          .filter(
            (document) =>
              document.documentCode !== 'HMMS01' || document.status === 'INP',
          )
          .map((document, index) => ({
            ...document,
            no: index + 1,
            documentCode: document.documentCode
              .replace(/\d+/, '')
              .toLowerCase(),
          }));
        draft.manuDocuments = parsedDocuments;
        // 정책 : cgmp는 skip모드에서 패스
        draft.manuDocumentCodes = parsedDocuments
          .filter(({ documentCode }) => documentCode !== 'cgmp')
          .map(({ documentCode }) => documentCode);
      }),
    [SET_CURRENT_DOC_STATUS]: (state, { payload: currentDocStatus }) =>
      produce(state, (draft) => {
        draft.currentDocStatus = currentDocStatus;
      }),
    [SET_DOCUMENT_CODES]: (state, { payload: documentCodes }) =>
      produce(state, (draft) => {
        draft.documentCodes = documentCodes;
      }),
    [MANU_SET_DOCUMENT_CODES]: (state, { payload: manuDocumentCodes }) =>
      produce(state, (draft) => {
        draft.manuDocumentCodes = manuDocumentCodes;
      }),
    [GET_COUNTRIES_SUCCESS]: (state, { payload: countries }) =>
      produce(state, (draft) => {
        draft.countries = countries;
      }),
    [SET_CURRENT_PRODUCT]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.currentProduct = payload;
      }),
    [MANU_ADD_PRODUCT_SUCCESS]: (state, { payload: currentProduct }) =>
      produce(state, (draft) => {
        draft.currentProduct = currentProduct;
      }),
    [GET_PRODUCT_ARTWORKS_SUCCESS]: (state, { payload: artworks }) =>
      produce(state, (draft) => {
        draft.artwork = artworks?.productArtworks.map((artwork) => ({
          name: artwork.filename,
          url: artwork.uploadFileUrl,
          ...artwork,
        }));
        draft.isArtworkLegacy = artworks?.isLegacyProductArtwork;
      }),
    [INITIALIZE_ARTWORK]: (state) =>
      produce(state, (draft) => {
        draft.artwork = [];
      }),
    [INITIALIZE_PRODUCT_STABILITY_TEST]: (state) =>
      produce(state, (draft) => {
        draft.stabilityTest = null;
      }),
  },
  initialState,
);
