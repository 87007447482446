import { RawMaterialGet } from 'service/material/rawMaterial';

export enum DocumentCode {
  MDI = 'MDI01',
  MCAS = 'MCAS01',
  MCI = 'MCI01',
  COA = 'COA01',
  MSDS = 'MSDS01',
  ALLE = 'ALLE01',
  IFRA = 'IFRA01',
  MMD = 'MMD01',
}

export enum DocumentStatus {
  INP = 'INP', // 필수 입력
  IPS = 'IPS', // 선택 입력
  UPD = 'UPD', // 업로드 필요
  FIN = 'FIN', // 입력 완료
}
export interface DocumentSeq {
  documentSeqId: number;
  documentName: string;
  documentCode: DocumentCode;
}
export interface Document {
  materialDocId: number;
  documentSeq: DocumentSeq;
  status: DocumentStatus;
  registerDt: string | null;
  isOptional: boolean;
  displayOrder: number;
  modifyDt: string | null;
  modifyUserId: string | null;
  modifierName: string | null;
  modifierCompanyName: string | null;
}

export enum SpecialBrandDocResponseType {
  POSSIBLE = 'POSSIBLE',
  IMPOSSIBLE = 'IMPOSSIBLE',
  NONE = 'NONE',
}

export interface RegisteredRawMaterial
  extends Pick<
    RawMaterialGet,
    'materialId' | 'materialNameKo' | 'materialNameEn'
  > {
  originManufacturerName: string;
  finishRegisterDt: string;
  finishRegistrant: { name: string };
}

export interface MadeCountry {
  countryListId: number;
  nameEn: string;
}
