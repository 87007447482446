import { useHistory } from 'react-router-dom';

import ICIDOngoing from 'components/material/icid/ICIDOngoing';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import { ICIDOngoingPageState } from 'types/material/icid/icid';

const ICIDOngoingPage = () => {
  const history = useHistory<ICIDOngoingPageState>();
  const pageState = history.location.state || {
    page: 1,
    tradeName: '',
  };

  const handleChangePageState = (state: ICIDOngoingPageState) => {
    history.replace(path.material.icid.ongoing, state);
  };

  return (
    <PageTemplate
      title="등재 중 원료 관리"
      back={false}
      description="등재 중인 원료를 관리해보세요."
    >
      <ICIDOngoing
        pageState={pageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default ICIDOngoingPage;
