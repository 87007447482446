export enum StimulationMethod {
  FLAVOR = 'FLAVOR',
  FRAGRANCE = 'FRAGRANCE',
  FLAVOR_AND_FRAGRANCE = 'FLAVOR_AND_FRAGRANCE',
}

export interface AllergensList {
  productAllergenListId: number;
  productId: number;
  countryId: number;
  isOdourless: boolean;
  stimulationMethod: StimulationMethod | null;
  uploadedFileUrl: string | null;
  fileName: string | null;
  fragrances: Fragrance[];
}

export interface AllergicIngredientBasic {
  allergenListIngredientId: number;
  ingredientContent: number;
}

export interface FragranceBasic {
  fragranceName: string;
  usageLevel: number;
  allergicIngredients: AllergicIngredient[];
  isNonAllergenOdour: boolean;
  isDelete?: boolean;
}

export interface Fragrance extends Omit<FragranceBasic, 'allergicIngredients'> {
  productAllergenListFragranceId: number;
  allergicIngredients: AllergicIngredient[];
}

export interface AllergicIngredient extends AllergicIngredientBasic {
  name?: number;
  ingredientName: string;
  casNo: string;
  isDelete?: boolean;
}

export interface AllergensListFormValues {
  isOdourless: boolean;
  stimulationMethod?: StimulationMethod;
  fragrances?: Partial<Fragrance>[];
}

export interface AllergensListAddParams extends AllergensListFormValues {
  productId: number;
}

export interface AllergensListUpdateParams
  extends Partial<AllergensListAddParams> {
  countryId?: number;
}

export interface AllergenListIngredient {
  allergenListIngredientId: number;
  ingredientName: string;
  casNo: string;
  isDelete?: boolean;
}

export interface AllergenListIngredientData extends AllergenListIngredient {
  name: string;
}
