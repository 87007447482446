import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CompanyType } from 'types/company';

export const useManuMode = () => {
  const manuMode = useSelector(
    ({ company }) => company.company?.companyType === CompanyType.MANUFACTURE,
  );
  return manuMode;
};

export const useRetailerMode = () => {
  const history = useHistory();
  const retailerMode = history.location.pathname.startsWith('/retailer/');

  return retailerMode;
};
