import styled, { css } from 'styled-components';

import { s3AssetDomain } from 'lib/consts';
import { deviceSize } from 'lib/styles';
import { Body2, H1, H2, H5, LogoText } from './introStyles';
import { Flex } from 'components/ui';
import useIntersectionObserver from 'hook/useIntersectionObserver';
import { useEffect, useState } from 'react';

type ImageDirection = 'left' | 'right';

const Container = styled.div`
  width: 100%;
  padding: 170px 24px;

  @media ${deviceSize.sm} {
    padding: 90px 24px;
  }
`;

const Body = styled.div`
  max-width: 1200px;
  margin: auto;
  text-align: center;
`;

const CardList = styled(Flex)`
  padding-top: 80px;
  row-gap: 80px;

  @media ${deviceSize.sm} {
    padding-top: 50px;
    row-gap: 50px;
  }
`;

const CardTextWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  white-space: pre-wrap;
  opacity: 0;
  transform: translateY(20%);
  transition: all 1s;

  @media ${deviceSize.sm} {
    flex-direction: flex-start;
  }

  > *:first-child {
    margin-bottom: 20px;

    @media ${deviceSize.sm} {
      margin-bottom: 8px;
    }
  }

  > *:nth-child(2) {
    margin-bottom: 30px;

    @media ${deviceSize.sm} {
      margin-bottom: 16px;
    }
  }

  > *:nth-child(3) {
    max-width: 500px;
  }
`;

const CardContainer = styled(Flex)<{
  imageDirection: ImageDirection;
  isIntersecting: boolean;
  isAnimated: boolean;
}>`
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  column-gap: 80px;

  @media ${deviceSize.lg || deviceSize.md} {
    align-items: center;
  }

  @media ${deviceSize.sm} {
    flex-direction: column-reverse;
    row-gap: 30px;
  }

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      ${CardTextWrapper} {
        opacity: 1;
        transform: translateY(0);
      }
    `}

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      ${CardTextWrapper} {
        opacity: 1;
        transform: translateY(0);
      }
    `}

  ${({ imageDirection }) =>
    imageDirection === 'left' &&
    css`
      flex-direction: row-reverse;

      @media ${deviceSize.sm} {
        flex-direction: flex-start;
      }
    `}
`;

const CardImageWrapper = styled.div<{
  imageDirection: ImageDirection;
}>`
  flex: 1;
  height: auto;
  overflow: hidden;
  border-radius: 12px;

  @media ${deviceSize.sm} {
    ${({ imageDirection }) =>
      imageDirection === 'right' && `margin-left: auto;`};
  }

  > img {
    width: 100%;
    max-width: 585px;
    transition: transform 0.5s;

    @media ${deviceSize.sm} {
      max-width: 246px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const CardLabel = styled(H5)`
  @media ${deviceSize.sm} {
    font-size: 16px;
    font-weight: 500;
  }
`;

const CardTitle = styled(H2)`
  @media ${deviceSize.sm} {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
`;

const CardSubTitle = styled(Body2)`
  @media ${deviceSize.sm} {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;

const SectionB = () => {
  return (
    <Container>
      <Body>
        <H1>
          왜 <LogoText>certicos</LogoText>인가요?
        </H1>
        <CardList dir="column" gap={3}>
          <Card
            label="업무 정의"
            title={`인허가 관련 업무를\n디지털화 하였습니다.`}
            subtitle="디지털화를 통해 인증 과정 중 발생하는 복잡한 과정을 단순화하고 비효율적인 시간낭비를 줄여서 인허가 관련 업무의 효율성을 대폭 개선시켜줍니다."
            imageUrl={`${s3AssetDomain}/intro_main_card_a.png`}
            imageDirection="right"
            alt="써티코스(certicos)는 화장품 인증을 자동화시켜 더 빠르고 더 저렴합니다"
          />
          <Card
            label="업무 범위"
            title={`국가별 인증은 물론,\n특수 인증까지 한 번에 해결해드립니다.`}
            subtitle="유럽(CPNP), 영국(SCPN), 중국(NMPA), 미국(VCRP/OTC Drug), 인도네시아(BPOM), 인도(CDSCO) 등 35개국 인증뿐만 아니라, VEGAN, FDA 의료기기, ISO22716, cGMP, EPA 등 특수인증도 가능합니다. 여러 대행업체에 맡기는 대신 certicos에서 한 번에 관리하세요."
            imageUrl={`${s3AssetDomain}/intro_main_card_b.png`}
            imageDirection="left"
            alt="써티코스(certicos)는 유럽(CPNP), 영국(SCPN), 중국(NMPA), 미국(VCRP/OTC Drug), 인도네시아(BPOM), 인도(CDSCO) 등 35개국 인증뿐만 아니라, VEGAN, FDA 의료기기, ISO22716, cGMP, EPA 등 특수인증도 진행합니다"
          />
          <Card
            label="인체 적용시험"
            title={`고객중심의 합리적인\n인체적용시험 솔루션을 제공합니다.`}
            subtitle="AI 스크리닝 후 국내외 임상기관의 빅데이터를 기반으로 가장 싸게, 가장 빠르게, 그리고 한 번의 임상으로 여러 국가의 증빙 이슈를 한꺼번에 해결할 수 있게 해드립니다."
            imageUrl={`${s3AssetDomain}/intro_main_card_c.png`}
            imageDirection="right"
            alt="써티코스(certicos)는 인체적용시험, 임상시험은 가격도 저렴할 뿐만 아니라 고객님께 적합한 컨설팅을 제공해 드립니다"
          />
          <Card
            label="고객사 업무 범위"
            title={`정보는 한 번만 입력하고\n나머지는 저희에게 맡기세요.`}
            subtitle="ceticos를 통한 단 한 번의 입력은 전 세계 화장품 인증을 가능하게 합니다. 검증된 프로그램과 전문가들이 제공하는 차별화된 서비스를 경험해보세요."
            imageUrl={`${s3AssetDomain}/intro_main_card_d.png`}
            imageDirection="left"
            alt="써티코스(certicos)는 전 세계 화장품 인증을 최신 IT기술과 내부 인증 전문가들을 통해 차별화된 서비스를 제공해 드립니다"
          />
        </CardList>
      </Body>
    </Container>
  );
};

const Card = ({
  label,
  title,
  subtitle,
  imageUrl,
  imageDirection,
  alt,
}: {
  label: string;
  title: string;
  subtitle: string;
  imageUrl: string;
  imageDirection: ImageDirection;
  alt: string;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.7,
  });

  useEffect(() => {
    if (isIntersecting) {
      setIsAnimated(true);
    }
  }, [isIntersecting]);
  return (
    <CardContainer
      imageDirection={imageDirection}
      ref={target}
      isIntersecting={isIntersecting}
      isAnimated={isAnimated}
    >
      <CardTextWrapper>
        <CardLabel color="C_MAIN">{label}</CardLabel>
        <CardTitle color="TYPO2">{title}</CardTitle>
        <CardSubTitle color="TYPO3">{subtitle}</CardSubTitle>
      </CardTextWrapper>
      <CardImageWrapper imageDirection={imageDirection}>
        <img src={imageUrl} alt={alt} />
      </CardImageWrapper>
    </CardContainer>
  );
};

export default SectionB;
