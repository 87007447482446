import { Switch, Route } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import path from 'lib/path';
import RawMaterialRouter from './RawMaterialRouter';
import MaterialRegisterManagePage from 'pages/material/MaterialRegisterManagePage';
import IngredientRouter from './IngredientRouter';
import ICIDRouter from './ICIDRouter';
import NMPARouter from './NMPARouter';

const MaterialRouter = () => {
  return (
    <Switch>
      <Route
        path={path.material.rawMaterial.root}
        component={RawMaterialRouter}
      />
      <Route
        path={path.material.ingredient.root}
        component={IngredientRouter}
      />
      <Route
        path={`${path.material.manage.root}/:status`}
        component={MaterialRegisterManagePage}
      />
      <Route path={path.material.icid.root} component={ICIDRouter} />
      <Route path={path.material.nmpa.root} component={NMPARouter} />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default MaterialRouter;
