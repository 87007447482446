import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { omit, pick } from 'lodash';

import { registerCompany, updateCompany } from 'modules/auth';
import BrandRegisterForm from 'components/auth/brand/BrandRegisterForm';
import { getCompany } from 'modules/company';
import useUpdateEffect from 'hook/useUpdateEffect';
import { useModal } from 'hook/useModal';
import { Typography } from 'components/system';
import { useBrandCompanies } from 'service/brand/company';
import { messages } from 'lib/consts';
import { isObjectChanged } from 'lib/form';

const BrandRegisterFormContainer = ({ tabKey, setTabKey }) => {
  const dispatch = useDispatch();
  const { openAlertModal, openChangePasswordModal } = useModal();

  const [form] = Form.useForm();
  const { user, company, submitLoading } = useSelector(
    ({ auth, company, loading }) => ({
      user: auth.user,
      company: company.company,
      submitLoading:
        loading['auth/REGISTER_COMPANY'] || loading['auth/UPDATE_COMPANY'],
    }),
  );

  const updateMode = user !== null && company !== null;
  const { data: brands = [] } = useBrandCompanies({
    isExcludeUserInserted: true,
    enabled: !updateMode,
  });
  const handleSubmit = (formData) => {
    const brand = brands.find(
      ({ companyNameKo }) => companyNameKo === formData.companyNameKo,
    );
    const companyForm = {
      companyNameKo:
        formData.companyNameKo === 'companyNameKoDirect'
          ? formData.companyNameKoDirect
          : formData.companyNameKo,
      companyNameEn: formData.companyNameEn,
      ...(brand?.brandCompanyId && {
        brandCompanyId: brand.brandCompanyId,
      }),
      bizNumber: formData.bizNumber,
      addressKo: formData.addressKo,
      addressEn: formData.addressEn,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }),
      ...(!updateMode && {
        bizLicenseFile: formData.bizLicenseFile,
        bizLicenseEnFile: formData.bizLicenseEnFile,
      }),
      companyType: 'BRAND',
    };
    const userForm = {
      isSuperUser: true,
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
      userType: 'BRAND',
    };

    if (!updateMode) {
      dispatch(registerCompany({ ...companyForm, ...userForm }));
    } else {
      const userData = omit({ ...userForm, userId: user.userId }, ['password']);

      const updateFormData = {
        company: {
          ...companyForm,
          companyId: company.companyId,
        },
        user: userData,
        companyId: company.companyId,
      };

      const originFormData = {
        company: pick(company, [
          'addressEn',
          'addressKo',
          'bizNumber',
          'ceoNameEn',
          'ceoNameKo',
          'companyId',
          'companyNameEn',
          'companyNameKo',
          'companyType',
          'fax',
          'homepageUrl',
          'tel',
        ]),
        user: pick({ ...user, isSuperUser: true, userId: user.userId }, [
          'username',
          'password',
          'name',
          'deptName',
          'positionName',
          'email',
          'mobile',
          'userType',
          'isSuperUser',
          'userId',
        ]),
        companyId: company.companyId,
      };

      if (
        tabKey === 'company'
          ? !isObjectChanged({
              object1: originFormData.company,
              object2: updateFormData.company,
            })
          : !isObjectChanged({
              object1: originFormData.user,
              object2: updateFormData.user,
            })
      ) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }

      openAlertModal({
        content: (
          <Typography.Text style={{ fontSize: '14px' }}>
            정말로 수정하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => {
          dispatch(
            updateCompany({
              company: {
                ...companyForm,
                companyId: company.companyId,
              },
              user: userData,
              companyId: company.companyId,
            }),
          );
        },
      });
    }
  };
  const handleClickChangePassword = () => {
    openChangePasswordModal({});
  };

  useEffect(() => {
    if (updateMode) {
      dispatch(getCompany(company.companyId));
      form.setFieldsValue({
        ...company,
        ...user,
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
      });
    }
  }, []);

  useUpdateEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
      });
    }
  }, [company]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !updateMode) {
      form.setFieldsValue({
        companyNameKo: '둘리나라',
        companyNameEn: 'DooleyWorld',
        addressKo: '서울시 종로구',
        addressEn: 'Jongro-gu, Seoul',
        ceoNameKo: '둘리',
        ceoNameEn: 'Dooley',
        tel: '0211112222',
        fax: '0211112222',
        homepageUrl: 'http://www.dooley.com',
        bizNumber: '111-11-11120',
        password: '1111',
        passwordConfirm: '1111',
        name: '장동혁',
        deptName: '고길동괴롭힘팀',
        positionName: '한량',
        email: 'flynn@fromom.net',
        mobile: '01012345678',
        isEmailVerified: false,
        isPhoneVerified: false,
        isSmsAgreed: true,
        isEmailAgreed: true,
      });
    }
  }, []);

  return (
    <BrandRegisterForm
      tabKey={tabKey}
      setTabKey={setTabKey}
      updateMode={updateMode}
      form={form}
      submitLoading={submitLoading}
      onClickChangePassword={handleClickChangePassword}
      onSubmit={handleSubmit}
      brands={brands}
      bizLicenseEnUrl={company?.bizLicenseEnUrl}
    />
  );
};

export default BrandRegisterFormContainer;
