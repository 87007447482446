import styled, { css } from 'styled-components';
import { Form, Input } from 'antd';

import { Typography } from 'components/system';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AntdExplainWrapper = styled.div`
  .ant-form-item-explain {
    width: 200px;
    position: absolute;
    bottom: -16px;
  }
`;

export const StyledLabelSpaceBetween = styled(StyledRow)`
  justify-content: space-between;
  align-items: center;
`;

export const StyledLabel = styled(Typography.Text)<{ isOnlyCN: boolean }>`
  font-size: 18px;
  margin-bottom: 8px;
  ${({ isOnlyCN }) =>
    !isOnlyCN &&
    css`
      &:before {
        color: #3785f7;
        content: '*';
        font-size: 14px;
        margin-right: 1px;
      }
    `}
`;

export const SelectInput = styled(Input)`
  position: absolute;
  border: none;
  width: 118px;
  top: -10%;
  right: 0%;
  height: 40px !important;
  margin-top: 2px;
  padding-left: 4px !important;
`;

export const CheckBoxLabel = styled(Typography.Text)`
  display: inline-block;
  font-size: 16px;
`;
