import { Typography } from 'antd';
import DocumentsUpload from 'components/material/icid/DocumentsUpload';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/material/icid/icid';
import ICIDPageTemplate from './ICIDPageTemplate';

const CompositionPage = () => {
  return (
    <ICIDPageTemplate
      subtitle="Composition"
      documentCode={DocumentCode.MCI}
      notices={[
        <Typography.Text>
          등재하실 원료를 개발한 원료사의 레터헤드로 된{' '}
          <b>영문 Composition 서류</b>를 업로드해 주세요.
        </Typography.Text>,
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload />
    </ICIDPageTemplate>
  );
};

export default CompositionPage;
