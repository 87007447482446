import { Button, Form, Input, message, Modal, Radio } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FindPasswordModal from 'components/modal/auth/FindPasswordModal';
import { PasswordInput, Typography } from 'components/system';
import palette from 'lib/styles/palette';
import {
  findPassword,
  initializeLogin,
  login,
  togglePasswordModal,
} from 'modules/auth';
import SlateButton from '../Button/SlateButton';
import Storage from 'lib/storage';
import { useEffect } from 'react';

const StyledRadioGroup = styled(Radio.Group)<{ isOnlyBrand: boolean }>`
  display: flex;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${palette.PRIMARY50};

  .ant-radio-button-wrapper {
    flex: 0 0 33.3333%;
    padding-top: 8px;
    height: 56px;
    background-color: #fff !important;
    box-shadow: none !important;
    position: relative;
    border-bottom: 1px solid ${palette.PRIMARY50};
    bottom: -1px;

    &::before {
      height: 54px;
    }

    &:first-child {
      border-radius: ${({ isOnlyBrand }) =>
        !isOnlyBrand ? '4px 0 0 0' : '4px 4px 0 0'};
    }
    &:last-child {
      border-radius: 0 4px 0 0;
    }

    &.ant-radio-button-wrapper-checked {
      border-bottom: 1px solid #fff;
    }

    & > span {
      font-size: 18px;
      line-height: 1.33;
      color: ${palette.GRAY50};
    }
  }
  .ant-radio-button-wrapper-checked > span {
    color: ${palette.PRIMARY50} !important;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px;

  &.ant-form-item-with-help {
    margin-bottom: 0px;
  }
`;

const LoginModal = ({
  visible,
  isOnlyBrand = false,
  onClose,
}: {
  visible: boolean;
  isOnlyBrand?: boolean;
  onClose: () => void;
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    isShowFindPasswordModal,
    findPasswordLoading,
    loginLoading,
    loginError,
    loginFailCount,
    isLoggedIn,
  } = useSelector(
    ({ auth, loading }: any) => ({
      isShowFindPasswordModal: auth.login.isShowFindPasswordModal,
      findPasswordLoading: loading['auth/FIND_PASSWORD'],
      loginLoading: loading['auth/LOGIN'],
      loginError: auth.login.error,
      loginFailCount: auth.login.failCount,
      isLoggedIn: auth.user !== null,
    }),
    shallowEqual,
  );
  const showFindPasswordModal = () => {
    dispatch(initializeLogin());
    dispatch(togglePasswordModal());
  };
  const handleLogin = ({
    username,
    password,
    userType,
  }: {
    username: string;
    password: string;
    userType: string;
  }) => {
    dispatch(
      login({
        username,
        password,
        userType,
        fromModal: true,
      }),
    );
  };
  const sendAuthenticationEmail = ({
    username,
    email,
    userType,
  }: {
    username: string;
    email: string;
    userType: string;
  }) => {
    dispatch(
      findPassword({
        username,
        email,
        userType,
      }),
    );
  };

  useEffect(() => {
    if (loginError) {
      if (loginFailCount >= 5) {
        message.warning(
          <Typography.Text type="BODY_2" style={{ textAlign: 'center' }}>
            비밀번호를 모르실 경우, 비밀번호 찾기를 눌러주세요,
          </Typography.Text>,
        );
      }
    }
  }, [loginError, loginFailCount]);

  useEffect(() => {
    isLoggedIn && onClose();
  }, [isLoggedIn]);

  return (
    <Modal footer={null} width={480} visible={visible} onCancel={onClose}>
      <FindPasswordModal
        visible={isShowFindPasswordModal}
        findPasswordLoading={findPasswordLoading}
        onOk={sendAuthenticationEmail}
        onCancel={() => dispatch(togglePasswordModal())}
      />
      <Typography.Text light type="HEADLINE_1" gutter={{ top: 4, bottom: 56 }}>
        로그인
      </Typography.Text>
      <Form form={form} colon={false} onFinish={handleLogin}>
        <Form.Item
          initialValue={
            isOnlyBrand ? 'BRAND' : Storage.getItem('userType') || 'BRAND'
          }
          name="userType"
          style={{ marginBottom: 24 }}
        >
          <StyledRadioGroup buttonStyle="solid" isOnlyBrand={isOnlyBrand}>
            <Radio.Button value="BRAND">브랜드사</Radio.Button>
            {!isOnlyBrand && (
              <>
                <Radio.Button value="MANUFACTURE">제조사</Radio.Button>
                <Radio.Button value="MATERIAL">원료사</Radio.Button>
              </>
            )}
          </StyledRadioGroup>
        </Form.Item>
        <StyledFormItem
          name="username"
          rules={[{ required: true, message: '아이디를 입력해 주세요' }]}
        >
          <Input name="id" placeholder="아이디 또는 사업자 번호" />
        </StyledFormItem>
        <StyledFormItem
          name="password"
          rules={[{ required: true, message: '비밀번호를 입력해 주세요' }]}
        >
          <PasswordInput placeholder="비밀번호" />
        </StyledFormItem>
        <Typography.Text type="BODY_2" gutter={{ bottom: 16 }}>
          <Typography.Text
            type="BODY_2"
            inline
            style={{ cursor: 'pointer' }}
            onClick={showFindPasswordModal}
          >
            비밀번호 찾기
          </Typography.Text>
        </Typography.Text>
        <Button
          type="primary"
          loading={loginLoading}
          htmlType="submit"
          block
          style={{ height: 56, fontSize: 18 }}
        >
          로그인
        </Button>
      </Form>
      <SlateButton
        loading={loginLoading}
        style={{ height: 56, fontSize: 18 }}
        onClick={onClose}
      >
        로그인없이 진행
      </SlateButton>
    </Modal>
  );
};

export default LoginModal;
