import { Form, Button, Spin } from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { useCertificateMode } from 'hook/certificate';
import { BackgroundRadio } from 'components/ui';
import { generateFormRules } from 'lib/validate';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { DocumentCode } from 'types/product';

const ProductAllergensListBlock = styled.div`
  .ant-form-item-control-input {
    min-height: auto;
  }
  .ant-radio-wrapper {
    line-height: 24px;
  }
`;

const ProductAllergensList = ({
  updateMode,
  readOnlyMode,
  form,
  file,
  fetchLoading,
  submitLoading,
  onUpload,
  onDelete,
  onSubmit,
}: any) => {
  const certificateMode = useCertificateMode();
  return (
    <ProductAllergensListBlock>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Spin spinning={fetchLoading === true}>
          <div style={{ maxWidth: 520, margin: '0 auto' }}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.isOdourless !== next.isOdourless
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="향료 유무"
                  name="isOdourless"
                  required
                  rules={generateFormRules({
                    required: getFieldValue('isOdourless') === undefined,
                  })}
                >
                  <BackgroundRadio
                    gap={80}
                    bodyStyle={{
                      width: 520,
                      padding: '16px 0',
                    }}
                    disabled={readOnlyMode}
                    options={[
                      {
                        title: '무향 (향료가 없음)',
                        value: true,
                      },
                      {
                        title: '유향 (향료가 있음)',
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) =>
                prev.isOdourless !== curr.isOdourless
              }
              noStyle
            >
              {() =>
                form.getFieldValue('isOdourless') !== undefined &&
                !form.getFieldValue('isOdourless') ? (
                  <Form.Item
                    required
                    label="구분"
                    name="isNonAllergenOdour"
                    initialValue={true}
                  >
                    <BackgroundRadio
                      gap={36}
                      disabled={readOnlyMode}
                      bodyStyle={{
                        width: 520,
                        padding: '16px 28px',
                      }}
                      options={[
                        {
                          title: '알러지 프리 (Non-Allergen) 향료',
                          value: true,
                        },
                        {
                          title: '알러지 유발 성분 포함 향료',
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </div>
        </Spin>
        <Form.Item
          shouldUpdate={(prev, curr) => prev.isOdourless !== curr.isOdourless}
          noStyle
        >
          {() =>
            !form.getFieldValue('isOdourless') ? (
              <FileUploadContainer
                readOnly={readOnlyMode}
                files={file}
                accept=".pdf"
                style={{ marginTop: 24 }}
                onUpload={onUpload}
                onDelete={onDelete}
              />
            ) : null
          }
        </Form.Item>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            {updateMode && certificateMode && (
              <CorrectRequestWithoutChangeButton
                documentCode={DocumentCode.ALLE}
              />
            )}
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </ProductAllergensListBlock>
  );
};

export default ProductAllergensList;
