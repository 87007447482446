import { Button, Form, Input, Modal, message } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';

import { Tip, Typography } from 'components/system';
import { BackgroundRadio, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { generateFormRules } from 'lib/validate';
import { messages } from 'lib/consts';
import { CompanySize, ManufacturerInfo } from 'types/product';

export interface ManufacturerInfoModalProps {
  onClose?: () => void;
  updateMode: boolean;
  readOnlyMode: boolean;
  manufacturerInfo?: ManufacturerInfo;
  manufacturerIsSmallCompany?: CompanySize;
  manufacturerFeiNumber?: string;
  setManufacturerIsSmallCompany: React.Dispatch<
    React.SetStateAction<CompanySize | undefined>
  >;
  setManufacturerFeiNumber: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export interface ManufacturerInfoModalForm {
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerIsSmallCompany: CompanySize;
  manufacturerFeiNumber: string;
}

const ManufacturerInfoModal = ({
  onClose,
  updateMode,
  readOnlyMode,
  manufacturerInfo,
  manufacturerIsSmallCompany,
  manufacturerFeiNumber,
  setManufacturerIsSmallCompany,
  setManufacturerFeiNumber,
}: ManufacturerInfoModalProps) => {
  const [form] = Form.useForm<ManufacturerInfoModalForm>();

  const handleSubmit = (formValues: ManufacturerInfoModalForm) => {
    if (
      updateMode &&
      manufacturerIsSmallCompany === formValues.manufacturerIsSmallCompany &&
      manufacturerFeiNumber === formValues.manufacturerFeiNumber
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    setManufacturerIsSmallCompany(formValues.manufacturerIsSmallCompany);
    setManufacturerFeiNumber(formValues.manufacturerFeiNumber);

    message.success(`${updateMode ? '수정' : '등록'}되었습니다.`);
    onClose?.();
  };

  useEffect(() => {
    if (manufacturerInfo) {
      const {
        manufacturerAddressEn,
        manufacturerNameEn,
        manufacturerNameKo,
        manufacturerTel,
      } = manufacturerInfo;
      form.setFieldsValue({
        manufacturerAddressEn,
        manufacturerNameEn,
        manufacturerNameKo,
        manufacturerTel,
      });
    }

    if (manufacturerFeiNumber) {
      form.setFieldsValue({
        manufacturerFeiNumber,
      });
    }
    if (manufacturerIsSmallCompany) {
      form.setFieldsValue({
        manufacturerIsSmallCompany,
      });
    }
  }, [manufacturerInfo, manufacturerIsSmallCompany, manufacturerFeiNumber]);

  return (
    <StyledModal
      visible={true}
      title={
        <Flex justify="space-between" align="center">
          <Typography.Text type="TITLE_2" medium>
            제조사 중소기업 여부
          </Typography.Text>
          <Icon name="close" size={24} onClick={onClose} />
        </Flex>
      }
      closable={false}
      bodyStyle={{
        padding: '24px 40px 32px',
        whiteSpace: 'pre-wrap',
      }}
      width={560}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="manufacturerNameKo"
          label="화장품제조업자 (국문)"
          required
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="manufacturerNameEn"
          label="화장품제조업자 (영문)"
          required
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="manufacturerAddressEn"
          label="화장품제조업자 주소 (영문)"
          required
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="manufacturerTel"
          label="화장품제조업자 전화번호"
          required
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="manufacturerIsSmallCompany"
          label={
            <Flex align="center">
              <Typography.Text type="TITLE_2">중소기업 여부</Typography.Text>
              <Tip style={{ marginLeft: 4 }} trigger="click">
                <Typography.Text type="SMALL">
                  중소기업: 미국내 지난 3년간 매출이 평균 연 100만불
                  <br />
                  미만인 업체
                </Typography.Text>
              </Tip>
            </Flex>
          }
          required
          rules={generateFormRules({
            required: true,
          })}
        >
          <BackgroundRadio
            bodyStyle={{
              padding: '16px 56px 16px 80px',
            }}
            gap={24}
            itemWidth={160}
            disabled={readOnlyMode}
            options={[
              { title: '중소기업', value: CompanySize.MEDIUM },
              { title: '중소기업 아님', value: CompanySize.CORPORATE },
            ]}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.manufacturerIsSmallCompany !== next.manufacturerIsSmallCompany
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('manufacturerIsSmallCompany') ===
              CompanySize.CORPORATE && (
              <Form.Item
                name="manufacturerFeiNumber"
                label="FEI No."
                required
                rules={generateFormRules({
                  required: true,
                  onlyNumber: true,
                  maxLength: 12,
                })}
              >
                <Input placeholder="FEI No 입력" disabled={readOnlyMode} />
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
      {!readOnlyMode && (
        <Flex justify="center" align="center" gap={8} gutter={{ top: 24 }}>
          <Button
            key="submit"
            type="primary"
            onClick={form.submit}
            style={{ width: 73 }}
          >
            {updateMode ? '수정' : '등록'}
          </Button>
        </Flex>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  border-radius: 4px;

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    height: min-content !important;
    padding: 16px 24px !important;
    border: auto;
  }
`;

export default ManufacturerInfoModal;
