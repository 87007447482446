import { Button, Col, Form, Radio, Row, Spin } from 'antd';
import styled, { css } from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import FooterBox from 'components/FooterBox';
import { Tip, Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { AllergenIngredient } from 'types/material/allergen';
import { useAllergen } from 'service/material/allergen';
import { useRawMaterial } from 'service/material/rawMaterial';
import PreventEnterInput from 'components/system/form/PreventEnterInput';
import { generateFormRules } from 'lib/validate';
import Table from 'components/ui/Table/Table';

const AllergensListBlock = styled.div`
  position: relative;
  .ant-form-item-control-input {
    min-height: auto;
  }
  .ant-radio-wrapper {
    line-height: 24px;
  }

  .ant-form-item-explain-error,
  .ant-form-item-explain {
    width: fit-content;
    margin-left: 36px;
  }
`;

const TableWrapper = styled.div`
  margin: 8px 0 -136px;
  overflow: auto;
  border-top: 2px solid ${palette.PRIMARY50};

  .ant-table-thead > tr > th {
    background-color: ${palette.GRAY10};
  }

  .ant-input-number-input {
    height: 32px;
  }
`;

const RadioGroupWrapper = styled.div<{ padding?: boolean }>`
  width: fit-content;
  margin: 0 auto;

  ${(props) =>
    props.padding &&
    css`
      padding-top: 16px;
    `};
`;

const StyledStar = styled.span`
  color: ${palette.PRIMARY50};
  margin-right: 4px;
`;

const SpinContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledFooterBox = styled(FooterBox)`
  padding-top: 192px;
`;

const TableContainer = styled.div`
  padding-top: 16px;
`;

const AllergensList = () => {
  const params = useParams<{
    materialId: string;
  }>();

  const materialId = Number(params.materialId) || undefined;

  const [form] = Form.useForm();
  const [isVisibleName, setIsVisibleName] = useState('');
  const { rawMaterial } = useRawMaterial(materialId);
  const {
    allergensList,
    getListLoading,
    allergensDefaultList,
    getDefaultListLoading,
    addLoading,
    updateLoading,
    materialAllergenListId,
    onSubmit,
  } = useAllergen(materialId, rawMaterial?.status);

  const updateMode = typeof materialAllergenListId !== 'undefined';
  const isIngredientsEmpty = useMemo(
    () => _.isEmpty(allergensList?.materialAllergenIngredients),
    [allergensList],
  );

  const columns: ColumnsType<AllergenIngredient> = [
    {
      title: 'No.',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'INCI Name',
      dataIndex: 'inciName',
      align: 'center',
      render: (name, record) => (
        <Row gutter={8} justify="center" align="middle">
          {record.inciName.includes('Lilial') && (
            <Col>
              <Tip
                trigger="click"
                bodyStyle={{
                  margin: '24px 0 0 -8px',
                  textAlign: 'left',
                  width: 'max-content',
                }}
                isVisible={isVisibleName === record.inciName}
              >
                <Typography.Text type="SMALL">
                  ‘Lilial’는 유럽 규정을 준수하여 제공하므로 성분을 함유하지
                  않는 경우 <br />
                  함량 입력을 비워 주세요.
                </Typography.Text>
              </Tip>
            </Col>
          )}
          {record.inciName.includes('Lyral') && (
            <Col>
              <Tip
                trigger="click"
                bodyStyle={{
                  marginTop: 24,
                  textAlign: 'left',
                  width: 'max-content',
                }}
                isVisible={isVisibleName === record.inciName}
              >
                <Typography.Text type="SMALL">
                  ‘Lyral’는 유럽 규정을 준수하여 제공하므로 성분을 함유하지 않는
                  경우 <br />
                  함량 입력을 비워 주세요.
                </Typography.Text>
              </Tip>
            </Col>
          )}
          <Col>
            <Typography.Text type="BODY_2">{name}</Typography.Text>
          </Col>
        </Row>
      ),
    },
    {
      title: 'CAS No.',
      dataIndex: 'casNo',
      align: 'center',
    },
    {
      title: 'Total in Raw Material(%)',
      align: 'center',
      render: (_, record, index) => {
        return (
          <Form.Item
            initialValue={record.ingredientTotalPercent || ''}
            key={index}
            name={
              !isIngredientsEmpty
                ? record.materialAllergenIngredientId
                : record.materialCategoryId
            }
            style={{ marginBottom: 0 }}
            rules={generateFormRules({
              onlyNumber: true,
            })}
          >
            <PreventEnterInput
              disabled={rawMaterial?.isDiscontinued}
              style={{ maxWidth: 180, height: 32, lineHeight: '28px' }}
              onClick={() => {
                setIsVisibleName(record.inciName);
              }}
            />
          </Form.Item>
        );
      },
    },
  ];

  const tableData = useMemo(() => {
    return (!isIngredientsEmpty
      ? allergensList?.materialAllergenIngredients
      : allergensDefaultList!
    )?.sort((a, b) => a.displayOrder - b.displayOrder);
  }, [updateMode, allergensList, allergensDefaultList]);

  const fetchLoading =
    getDefaultListLoading || getListLoading || updateLoading || addLoading;

  if (fetchLoading) {
    return (
      <AllergensListBlock>
        <SpinContainer>
          <Spin spinning />
        </SpinContainer>
      </AllergensListBlock>
    );
  }

  return (
    <AllergensListBlock>
      <Spin spinning={fetchLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(value) => onSubmit(value)}
        >
          <RadioGroupWrapper>
            <Form.Item
              label="향료 사용 가능 여부"
              name="canUseForFragrance"
              initialValue={
                typeof allergensList?.canUseForFragrance !== 'undefined'
                  ? allergensList?.canUseForFragrance
                  : null
              }
              rules={generateFormRules({
                required: true,
              })}
            >
              <Radio.Group
                disabled={rawMaterial?.isDiscontinued}
                style={{
                  flexFlow: 'wrap',
                  background: palette.GRAY10,
                  padding: '16px 84px',
                  borderRadius: '4px',
                }}
                onChange={() => setIsVisibleName('')}
              >
                <Radio value={false} style={{ marginRight: 162 }}>
                  향료로 사용 불가
                </Radio>
                <Radio value={true}>향료로 사용 가능</Radio>
              </Radio.Group>
            </Form.Item>
          </RadioGroupWrapper>
          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.canUseForFragrance !== curr.canUseForFragrance
            }
            noStyle
          >
            {() =>
              form.getFieldValue('canUseForFragrance') === true && (
                <>
                  <RadioGroupWrapper padding>
                    <Form.Item
                      label="알러지 유발 성분 유무"
                      name="isAllergenFree"
                      initialValue={
                        typeof allergensList?.isAllergenFree !== 'undefined'
                          ? allergensList?.isAllergenFree
                          : null
                      }
                      rules={generateFormRules({
                        required: true,
                      })}
                    >
                      <Radio.Group
                        disabled={rawMaterial?.isDiscontinued}
                        style={{
                          flexFlow: 'wrap',
                          background: palette.GRAY10,
                          padding: '16px 84px',
                          borderRadius: '4px',
                        }}
                        onChange={() => setIsVisibleName('')}
                      >
                        <Radio value={true} style={{ marginRight: 162 }}>
                          알러지 프리
                        </Radio>
                        <Radio value={false}>알러지 유발 성분 있음</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </RadioGroupWrapper>
                  <Form.Item
                    shouldUpdate={(prev, curr) =>
                      prev.isAllergenFree !== curr.isAllergenFree
                    }
                    noStyle
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('isAllergenFree') === false && (
                        <TableContainer>
                          <Typography.Text type="BODY_2">
                            <StyledStar>*</StyledStar>
                            함유하지 않는 경우 입력란을 비워주세요.
                          </Typography.Text>
                          <Typography.Text type="BODY_2">
                            <StyledStar>*</StyledStar>해당 리스트는 유럽 규정을
                            준수하여 작성되었으며, 국내 기준과 상이할 수
                            있습니다.
                          </Typography.Text>
                          <Typography.Text type="BODY_2">
                            <StyledStar>*</StyledStar>
                            소수점 이하 최대 열 번째 자리까지만 작성 가능합니다.
                          </Typography.Text>
                          <TableWrapper>
                            <Table
                              columns={columns}
                              dataSource={tableData}
                              rowKey={({ materialCategoryId }) =>
                                materialCategoryId
                              }
                              pagination={false}
                            />
                          </TableWrapper>
                        </TableContainer>
                      )
                    }
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          {!rawMaterial?.isDiscontinued && (
            <StyledFooterBox>
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  getDefaultListLoading ||
                  getListLoading ||
                  updateLoading ||
                  addLoading
                }
              >
                {!updateMode ? '저장' : '수정'}
              </Button>
            </StyledFooterBox>
          )}
        </Form>
      </Spin>
    </AllergensListBlock>
  );
};

export default AllergensList;
