import styled from 'styled-components';

import { Typography } from 'components/system';
import { Button, Col, Form, Select, Row } from 'antd';
import { requireRule } from 'lib/validate';

const LoAModalContentBlock = styled.div`
  margin-bottom: -24px;
`;

const LoAModalContent = ({
  onSubmit,
}: {
  onSubmit: (loaExpireYears: string) => void;
}) => {
  return (
    <LoAModalContentBlock>
      <Form onFinish={({ loaExpireYears }) => onSubmit(loaExpireYears)}>
        <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
          LoA 유효기간
        </Typography.Text>
        <Typography.Text type="BODY_2" align="center" gutter={{ bottom: 16 }}>
          This letter of authorization is effective for{' '}
        </Typography.Text>
        <Row justify="center" gutter={8}>
          <Col>
            <Form.Item
              name="loaExpireYears"
              rules={[requireRule]}
              style={{ display: 'inline' }}
            >
              <Select
                style={{ width: '100px', margin: '0 auto' }}
                placeholder="선택"
              >
                <Select.Option value="01">01</Select.Option>
                <Select.Option value="02">02</Select.Option>
                <Select.Option value="03">03</Select.Option>
                <Select.Option value="04">04</Select.Option>
                <Select.Option value="05">05</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ paddingTop: 12 }}>
            <Typography.Text type="BODY_2" inline>
              year from the date of issuance.
            </Typography.Text>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 32 }}>
          <Col>
            <Button type="primary" htmlType="submit" style={{ width: 120 }}>
              입력 완료
            </Button>
          </Col>
        </Row>
      </Form>
    </LoAModalContentBlock>
  );
};

export default LoAModalContent;
