import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import auth, { authSaga } from 'modules/auth';
import loading from 'modules/loading';
import modal from 'modules/modal';
import navigator from 'modules/navigator';
import company, { companySaga } from 'modules/company';
import product, { productSaga } from 'modules/product';
import estimate, { estimateSaga } from 'modules/estimate';
import certificate, { certificateSaga } from 'modules/certificate';
import buyer, { buyerSaga } from 'modules/buyer';
import service, { serviceSaga } from 'modules/service';
import ingredient from 'modules/material/ingredient';

const rootReducer = combineReducers({
  loading,
  auth,
  navigator,
  company,
  product,
  estimate,
  certificate,
  buyer,
  service,
  ingredient,
  modal,
});

export function* rootSaga() {
  yield all([
    authSaga(),
    companySaga(),
    productSaga(),
    estimateSaga(),
    certificateSaga(),
    buyerSaga(),
    serviceSaga(),
  ]);
}

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
