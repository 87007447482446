import { ReactElement, useRef, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

import Icon from 'components/ui/Icon/Icon';
import { icons } from 'components/ui/Icon/icons';
import palette from 'lib/styles/palette';

const TooltipIcon = ({
  name,
  targetRect,
  setTargetRect,
  onClick,
  style,
  size = 16,
  icon,
}: {
  name: keyof typeof icons;
  targetRect?: DOMRect;
  setTargetRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  onClick: VoidFunction;
  style?: CSSProperties;
  size?: number;
  icon?: ReactElement;
}) => {
  const [isHover, setIsHover] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const getTooltipText = () => {
    switch (name) {
      case 'textsms':
        return '의견 남기기';
      case 'copy':
        return '텍스트 복사';
      case 'refresh':
        return '초기화';
      default:
        return '';
    }
  };

  return (
    <TooltipContainer
      ref={ref}
      onMouseEnter={() => {
        setIsHover(true);
        setTargetRect(ref.current?.getBoundingClientRect());
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setTargetRect(undefined);
      }}
      onClick={() => {
        onClick();
      }}
      style={{ cursor: 'pointer', position: 'relative', zIndex: 1, ...style }}
    >
      {icon || (
        <Icon
          size={size}
          color={isHover ? 'SLATE_GRAY70' : 'SLATE_GRAY50'}
          name={name}
        />
      )}
      {isHover && (
        <Tooltip
          style={{
            position: 'fixed',
            top: `${(targetRect?.top || 0) - 4}px`,
            left: `${(targetRect?.left || 0) + size / 2}px`,
            transform: 'translate(-50%, -100%)',
          }}
        >
          {getTooltipText()}
        </Tooltip>
      )}
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div``;
const Tooltip = styled.div`
  padding: 4px 10px;
  border-radius: 4px;
  background: ${palette.SLATE_GRAY70};
  color: #fff;
  font-size: 12px;
  width: max-content;
  z-index: 2;
`;

export default TooltipIcon;
