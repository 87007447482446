import { Button, Card, Col, Row } from 'antd';
import cx from 'classnames';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import styled from 'styled-components';

import Table from 'components/Table';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import client from 'lib/api/client';
import { getReactEnvVar } from 'lib/common';
import { countryCodeMap } from 'lib/consts';
import { toNoExponents } from 'lib/form';
import palette from 'lib/styles/palette';
import { CountryProhibitInfo } from 'types/formula';
import {
  ProductSampleRecipeDetailErrorCode,
  ProductSampleRecipeDetailItem,
} from 'types/manufacturer/productSample';

const RecipeDetailTableBlock = styled.div`
  .ant-table-cell {
    font-size: 12px;

    th {
      text-align: center;
    }
  }
  td.ant-table-cell {
    word-break: break-all;
  }
`;

const FormulaDownloadCard = styled(Card)`
  margin-top: 24px;
  border: 1px solid #d7e7fe;
  background-color: ${palette.PRIMARY20};

  .ant-card-body {
    padding: 16px;
  }
`;

const AdditionalItemInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 154px;
  transform: translateY(-50%);
  padding: 8px 16px;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  background-color: #fff;
  width: max-content;
  text-align: left;
  color: ${palette.GRAY90};
  z-index: 1;
  white-space: pre-wrap;
  max-width: 304px;
`;

const RecipeDetailTable = ({
  recipeDetailItems,
  formulaExcelUrl,
  formulaCountryVisible = true,
}: {
  recipeDetailItems: ProductSampleRecipeDetailItem[];
  formulaExcelUrl: string | null;
  formulaCountryVisible?: boolean;
}) => {
  const [currentRow, setCurrentRow] = useState<number>();
  const [limitedFormulaCountryInfos, setLimitedFormulaCountryInfos] = useState<
    CountryProhibitInfo[] | null
  >(null);
  const [
    limitedFormulaDefaultCountryName,
    setLimitedFormulaDefaultCountryName,
  ] = useState<string | null>(null);
  const handleClickLimitedFormula = (
    limitedFormulaCountryInfos: CountryProhibitInfo[],
    limitedFormulaDefaultCountryName?: string,
  ) => {
    setLimitedFormulaCountryInfos(limitedFormulaCountryInfos);
    if (limitedFormulaDefaultCountryName) {
      setLimitedFormulaDefaultCountryName(limitedFormulaDefaultCountryName);
    }
  };

  const handleDownloadFormulaSingle = async () => {
    const params = recipeDetailItems.map(
      ({ inciName, actualWt, ingredientFunction, casNo }) => ({
        inciName,
        formulaWt: actualWt,
        formulaFunction: ingredientFunction,
        formulaCasNo: casNo,
      }),
    );
    const { data: blobFile } = await client.post(
      `${getReactEnvVar('SERVER_URL')}/files/formula`,
      params,
      {
        responseType: 'blob',
      },
    );

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blobFile);
    a.download = decodeURIComponent('SingleFormula.xlsx');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <RecipeDetailTableBlock>
      <CountryProhibitInfosModal
        defaultCountryName={limitedFormulaDefaultCountryName || undefined}
        countryInfos={limitedFormulaCountryInfos || []}
        onClose={() => setLimitedFormulaCountryInfos(null)}
      />
      <Table bordered>
        <Table.Thead>
          <Table.Tr>
            <Table.Th width="50" style={{ textAlign: 'center' }}>
              Phase
            </Table.Th>
            <Table.Th width="112">국문 성분명</Table.Th>
            <Table.Th width="136">INCI Name</Table.Th>
            <Table.Th width="88">
              RM or Ingredient
              <br />% in fla
            </Table.Th>
            <Table.Th width="80">
              Ingredients <br />% in RM
            </Table.Th>
            <Table.Th width="72">Actual WT%</Table.Th>
            <Table.Th width="96">
              Ingredient
              <br />
              function
            </Table.Th>
            <Table.Th width="72">CAS No.</Table.Th>
            {formulaCountryVisible && (
              <>
                <Table.Th width="72">비고</Table.Th>
                <Table.Th colSpan={2} width="136">
                  국가별 성분 규제
                </Table.Th>
              </>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {recipeDetailItems.map(
            ({
              rowNo,
              phase,
              phaseRowSpan,
              materialName,
              materialCompanyName,
              inciNameKo,
              inciName,
              flaIngredientPercent,
              flaIngredientPercentRowSpan,
              rmIngredientPercent,
              actualWt,
              ingredientFunction,
              casNo,
              remark,
              errorCodes,
              limitedFormulaCountryInfos,
            }) => {
              return (
                <Table.Tr
                  key={nanoid()}
                  style={
                    currentRow && currentRow === rowNo
                      ? { backgroundColor: palette.GRAY10 }
                      : {}
                  }
                  onMouseEnter={() => {
                    setCurrentRow(rowNo);
                  }}
                  onMouseLeave={() => setCurrentRow(undefined)}
                >
                  {phase && (
                    <Table.Td
                      rowSpan={phaseRowSpan}
                      style={{ textAlign: 'center' }}
                    >
                      {phase}
                      {currentRow === rowNo && (
                        <AdditionalItemInfo>
                          <Typography.Text type="SMALL">
                            원료명 : {materialName || '(미입력)'}
                          </Typography.Text>
                          <Typography.Text type="SMALL">
                            원료사 : {materialCompanyName || '(미입력)'}
                          </Typography.Text>
                        </AdditionalItemInfo>
                      )}
                    </Table.Td>
                  )}
                  <Table.Td
                    className={cx({
                      error:
                        errorCodes &&
                        errorCodes.includes(
                          ProductSampleRecipeDetailErrorCode.INCI_NAME_KO,
                        ),
                    })}
                  >
                    {inciNameKo || '-'}
                  </Table.Td>
                  <Table.Td
                    className={cx({
                      error:
                        errorCodes &&
                        errorCodes.includes(
                          ProductSampleRecipeDetailErrorCode.INCI_NAME,
                        ),
                    })}
                  >
                    {inciName}
                  </Table.Td>
                  {flaIngredientPercent && (
                    <Table.Td
                      className="rm-ingredient-in-fla-percent"
                      rowSpan={flaIngredientPercentRowSpan}
                      style={{ textAlign: 'right' }}
                    >
                      {toNoExponents(flaIngredientPercent)}
                    </Table.Td>
                  )}
                  <Table.Td style={{ textAlign: 'right' }}>
                    {toNoExponents(rmIngredientPercent)}
                  </Table.Td>
                  <Table.Td
                    className={cx('actual-percent', {
                      error:
                        errorCodes &&
                        errorCodes.includes(
                          ProductSampleRecipeDetailErrorCode.ACTUAL_WT,
                        ),
                    })}
                    style={{ textAlign: 'right' }}
                  >
                    {toNoExponents(actualWt)}
                  </Table.Td>
                  <Table.Td>{ingredientFunction}</Table.Td>
                  <Table.Td>{casNo}</Table.Td>
                  {formulaCountryVisible && (
                    <>
                      <Table.Td className="remark">{remark}</Table.Td>
                      <Table.Td
                        style={{ textAlign: 'center' }}
                        className="formulaTypeList"
                        key="limit"
                        width="48"
                      >
                        {Array.from(
                          new Set(
                            limitedFormulaCountryInfos?.map(({ type }) => type),
                          ),
                        )
                          .sort()
                          .map((type) => (
                            <Tags.Chip
                              key={type}
                              type="line"
                              color={
                                type === 'limit'
                                  ? 'MESSAGE_CHECK'
                                  : 'MESSAGE_ERROR'
                              }
                              style={{
                                display: 'inline-flex',
                              }}
                              onClick={() =>
                                handleClickLimitedFormula(
                                  limitedFormulaCountryInfos,
                                )
                              }
                            >
                              {type === 'limit' ? '제한' : '금지'}
                            </Tags.Chip>
                          ))}
                      </Table.Td>
                      <Table.Td style={{ textAlign: 'center' }} width="84">
                        <Row gutter={[2, 2]} justify="center">
                          {limitedFormulaCountryInfos?.map(
                            ({ countryNameKo }, index) => (
                              <Col key={countryNameKo}>
                                <img
                                  key={index}
                                  src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                                  alt=""
                                  style={{
                                    width: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleClickLimitedFormula(
                                      limitedFormulaCountryInfos,
                                      countryNameKo,
                                    )
                                  }
                                />
                              </Col>
                            ),
                          )}
                        </Row>
                      </Table.Td>
                    </>
                  )}
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
      {formulaExcelUrl && (
        <FormulaDownloadCard>
          <Row align="middle" justify="center">
            <Col>
              <Typography.Text inline gutter={{ right: 16 }}>
                업로드된 처방 상세를 기준으로 생성된 단일 성분표를 다운로드해
                보세요.
              </Typography.Text>
            </Col>
            <Col>
              <Button
                size="small"
                icon={
                  <Icon
                    name="download"
                    size={18}
                    color="PRIMARY50"
                    style={{ marginRight: 4 }}
                  />
                }
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleDownloadFormulaSingle}
              >
                <Typography.Text type="SMALL" color="PRIMARY50" medium>
                  Formula Single
                </Typography.Text>
              </Button>
            </Col>
          </Row>
        </FormulaDownloadCard>
      )}
    </RecipeDetailTableBlock>
  );
};

export default RecipeDetailTable;
