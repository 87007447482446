import {
  Button,
  Descriptions,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  message,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { MinusIcon, Typography } from 'components/system';
import { CorrectButton, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { urlToFile } from 'lib/file';
import { focusToInvalidatedField } from 'lib/form';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { getExeceptKoreanRule, getTextRule, requireRule } from 'lib/validate';
import {
  useNMPABasicInfoStatus,
  useNMPABasicOld,
  useNMPAChinaIngredients,
  useNMPACorrectWithNoUpdate,
  useNMPAEvaluationData,
  useNMPAOverwriteHistory,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  NMPADocumentCode,
} from 'types/material/nmpa/nmpa';

const Container = styled.div``;

const EvaluationDataListContainer = styled.div`
  .ant-descriptions-header {
    margin-bottom: 8px;
  }

  .ant-descriptions-item-content {
    padding: 16px;
    vertical-align: baseline;
  }

  .ant-descriptions-view {
    border-width: 0px;
    border-top: 2px solid ${palette.PRIMARY50};
    border-bottom: 1px solid ${palette.GRAY40};
  }

  .ant-descriptions-title {
    overflow: initial;
  }

  .ant-descriptions-item-label {
    background-color: ${palette.GRAY10};
    width: 160px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding: 0 16px;
  }

  .ant-descriptions-row {
    border-color: ${palette.GRAY40};
  }

  .ant-descriptions-item-content {
    border-left-width: 0px;
    border-right-width: 0px;
    padding: 8px 16px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const ETC_OPTION_VALUE = '기타';

const evaluationInstitutionOptions = [
  {
    label: 'SCCS',
    value: 'SCCS',
  },
  {
    label: 'CIR',
    value: 'CIR',
  },
  {
    label: 'IFRA',
    value: 'IFRA',
  },
  {
    label: 'WHO',
    value: 'WHO',
  },
  {
    label: 'FAO',
    value: 'FAO',
  },
  {
    label: '기타 (직접 입력)',
    value: ETC_OPTION_VALUE,
  },
];

const isEtcOption = (value: string) =>
  evaluationInstitutionOptions
    .slice(0, evaluationInstitutionOptions.length - 1)
    .filter((option) => option.label === value).length === 0;

const NMPAEDT = ({ materialId }: { materialId: number }) => {
  // HINT: 최초 불러온 데이터를 저장하고 있고, 수정할 내역을 검사할 때 사용됩니다.
  const [initFormData, setInitFormData] = useState();
  const { openNMPACorrectChatModal, openAlertModal } = useModal();
  const { nmpaBasicInfo } = useNMPABasicOld(materialId);
  const materialNmpaBasicInfoId = nmpaBasicInfo?.materialNmpaBasicInfoId;

  const {
    nmpaCorrectRequestWithNoUpdate,
    nmpaCorrectRequestWithNoUpdateLoading,
  } = useNMPACorrectWithNoUpdate({
    materialNmpaBasicInfoId,
    documentCode: NMPADocumentCode.EDT,
  });
  const { nmpaChinaIngredients } = useNMPAChinaIngredients();
  const {
    evaluationData,
    isReadNMPAEvaluationLoading,
    isUpdateNMPAEvaluationDataLoading,
    updateNMPAEvaluationData,
  } = useNMPAEvaluationData(materialId);

  const {
    basicInfoStatus,
    isLoading: isBasicInfoStatusLoading,
  } = useNMPABasicInfoStatus({
    materialId,
    documentCode: DocumentCode.EDT,
    enabled: materialId !== undefined,
  });
  const {
    nmpaOverwriteHistory,
    nmpaOverwriteHistoryLoading,
  } = useNMPAOverwriteHistory({
    nmpaBasicInfoId: materialId,
    documentCode: NMPADocumentCode.EDT,
  });
  const [form] = Form.useForm();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const history = useHistory();
  const readOnly =
    (nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.ONGOING &&
      !basicInfoStatus?.isNmpaDocModStatus) ||
    nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.FINISH;
  const updateMode = evaluationData.length > 0;
  const loading = isReadNMPAEvaluationLoading || isBasicInfoStatusLoading;
  const options = useMemo(
    () =>
      nmpaBasicInfo?.materialNmpaBasicInfoIncis.map((inci) => ({
        label: inci.chinaIngredient.nameEn,
        value: inci.materialNmpaBasicInfoInciId,
      })),
    [nmpaChinaIngredients],
  );
  const ctaName = useMemo(() => {
    if (basicInfoStatus?.isNmpaDocModStatus) return '보완 완료';
    if (!updateMode) return '등록';
    return '수정';
  }, [updateMode, basicInfoStatus]);

  const handleEvaluationInstitutionSelect = (name: number) => (
    value: string,
  ) => {
    const newEvaluationDatas = { ...form.getFieldValue('evaluationDatas') };
    newEvaluationDatas[name]['evaluationAgency'] =
      value === ETC_OPTION_VALUE ? '' : value;

    form.setFieldsValue(newEvaluationDatas);
    form.validateFields([['evaluationDatas', name, 'evaluationAgency']]);
  };

  const handleFileUpload = (name: number) => (file: RcFile) => {
    const newEvaluationDatas = { ...form.getFieldValue('evaluationDatas') };

    // HINT: 형식을 바꾸기가 어려워 antd file upload 형식으로 맞춤.
    newEvaluationDatas[name]['addDataFile'] = {
      file,
    };

    form.setFieldsValue(newEvaluationDatas);

    return false;
  };

  const handleNoUpdateSubmit = () => {
    if (materialNmpaBasicInfoId) {
      openAlertModal({
        content: `
'보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

보완할 내용 없이 처리 하시겠습니까?
`,
        onOk: () => {
          nmpaCorrectRequestWithNoUpdate(
            {},
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');
                history.goBack();
              },
            },
          );
        },
      });
    }
  };

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();

    const { evaluationDatas } = formValues;

    const isNoData =
      evaluationDatas[0].addDataFileUrl === undefined &&
      evaluationDatas[0].addDataFilename === undefined &&
      evaluationDatas[0].evaluationAgency === undefined &&
      evaluationDatas[0].figure === undefined &&
      evaluationDatas[0].limitElement === undefined &&
      evaluationDatas[0].materialNmpaBasicInfoInci === undefined &&
      evaluationDatas[0].unit === undefined;

    if (
      initFormData !== undefined &&
      isEqual(formValues, initFormData as any)
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    if (initFormData) {
      nmpaOverwriteHistory(
        getChangedValues({ obj1: initFormData, obj2: formValues }),
      );
    }

    const newEvaluationDatas: any[] = [];

    // HINT: antd file 형식을 전송 형식에 맞게 수정. { file, fileList } => file
    const formatParams = () => {
      for (const key of Object.keys(evaluationDatas)) {
        const newData = {
          ...evaluationDatas[key],
          ...(!evaluationDatas[key]?.limitElement && {
            limitElement: '',
          }),
          ...(evaluationDatas[key]?.addDataFile?.file && {
            addDataFile: evaluationDatas[key].addDataFile.file.originFileObj,
          }),
        };

        if (!newData?.addDataFile) {
          delete newData['addDataFile'];
        }
        newEvaluationDatas.push(newData);
      }
    };

    formatParams();

    updateNMPAEvaluationData(
      {
        materialId,
        evaluationDatas: isNoData ? [] : newEvaluationDatas,
      },
      {
        onSuccess: () => {
          message.success(`${ctaName}되었습니다.`);
          history.replace(`${path.material.nmpa.material.root}/${materialId}`);
        },
      },
    );
  };

  useEffect(() => {
    if (!evaluationData?.length) return;

    const apiData2FormData = async () => {
      const evaluationDatas: any[] = [];

      let i = 0;
      for (const data of evaluationData) {
        let file = null;
        if (data.addDataFileUrl) {
          file = await urlToFile(data.addDataFileUrl, data.addDataFilename);
          // ant file 형식 지키기 위함
          (file as any).originFileObj = file;
        }

        evaluationDatas.push({
          key: i,
          materialNmpaBasicInfoInciId:
            data.materialNmpaBasicInfoInci.materialNmpaBasicInfoInciId,
          evaluationAgencyCategory: isEtcOption(data.evaluationAgency)
            ? ETC_OPTION_VALUE
            : data.evaluationAgency,
          evaluationAgency: data.evaluationAgency,
          figure: data.figure,
          unit: data.unit,
          limitElement: data.limitElement || '',
          ...(file && {
            addDataFile: {
              file,
            },
          }),
        });

        i++;
      }

      form.setFieldsValue({ evaluationDatas });
      setTimeout(() => setInitFormData(form.getFieldsValue()), 0);
    };

    apiData2FormData();
  }, [evaluationData]);

  const handleReset = () => {
    Modal.confirm({
      content: (
        <Typography.Text type="BODY_2" align="center">
          입력된 정보를 모두 삭제하고 <br />
          초기화하시겠습니까?
        </Typography.Text>
      ),
      icon: null,
      onOk: () => {
        form.resetFields();
      },
      closable: true,
    });
  };

  return (
    <Container>
      {loading && <FullLoading />}
      {basicInfoStatus?.isNmpaDocModStatus && (
        <Flex justify="end" style={{ marginBottom: 16 }}>
          <CorrectButton
            onClick={() => {
              openNMPACorrectChatModal({
                title: nmpaBasicInfo?.tradeName,
                nmpaBasicInfoId: materialNmpaBasicInfoId,
                documentCode: NMPADocumentCode.EDT,
                isFirstOpenChatModal,
              });
              setIsFirstOpenChatModal(true);
            }}
            disableAnim={false}
          />
        </Flex>
      )}
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
      >
        <EvaluationDataListContainer>
          <Form.List name="evaluationDatas" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    style={{ marginTop: index > 0 ? 32 : 0 }}
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && index > 0 && (
                          <MinusIcon
                            style={{ position: 'absolute', left: -40, top: 7 }}
                            onClick={() => remove(index)}
                          />
                        )}
                        <Typography.Text type="TITLE_1">
                          평가 자료 {index + 1}
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text medium asterisk>
                          INCI Name
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'materialNmpaBasicInfoInciId']}
                        rules={[
                          !updateMode ? requireRule : { required: false },
                        ]}
                      >
                        <Select
                          showSearch
                          disabled={readOnly}
                          placeholder="입력 또는 선택"
                          style={{ width: '100%' }}
                          options={options}
                        />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text medium asterisk>
                          평가 기관
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex columnGap={8}>
                        <Form.Item
                          name={[name, 'evaluationAgencyCategory']}
                          style={{ flex: 1 }}
                          rules={[
                            !updateMode ? requireRule : { required: false },
                          ]}
                        >
                          <Select
                            disabled={readOnly}
                            options={evaluationInstitutionOptions}
                            onSelect={handleEvaluationInstitutionSelect(name)}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[name, 'evaluationAgency']}
                          style={{ flex: 1 }}
                          rules={[
                            !updateMode ? requireRule : { required: false },
                            getTextRule({
                              end: 50,
                              message: '최대 50자',
                            }),
                          ]}
                        >
                          <Input
                            disabled={
                              readOnly ||
                              form.getFieldValue([
                                'evaluationDatas',
                                name,
                                'evaluationAgencyCategory',
                              ]) !== ETC_OPTION_VALUE
                            }
                          />
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text medium asterisk>
                          수치
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          !updateMode ? requireRule : { required: false },
                          getExeceptKoreanRule({
                            end: 50,
                            message: '국문 입력 불가, 최대 50자',
                          }),
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          !updateMode ? requireRule : { required: false },
                          getTextRule({
                            end: 20,
                            message: '최대 20자',
                          }),
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text medium>제한 요소</Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'limitElement']}
                        rules={[
                          getTextRule({
                            start: 0,
                            end: 100,
                            message: '최대 100자',
                          }),
                        ]}
                      >
                        <Input
                          disabled={readOnly}
                          placeholder="평가 자료에 따른 제한 요소"
                        />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text medium>증빙 자료</Typography.Text>
                      }
                      span={3}
                    >
                      <Flex align="center" columnGap={24}>
                        <Form.Item
                          valuePropName="addDataFile"
                          name={[name, 'addDataFile']}
                        >
                          <Upload
                            itemRender={() => null}
                            beforeUpload={handleFileUpload(name)}
                          >
                            <Button size="small" disabled={readOnly}>
                              파일 선택
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Typography.Text type="BODY_2">
                          {
                            form.getFieldValue([
                              'evaluationDatas',
                              name,
                              'addDataFile',
                            ])?.file?.name
                          }
                        </Typography.Text>
                      </Flex>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                {!readOnly && (
                  <Button
                    type="dashed"
                    icon={
                      <Icon
                        name="plus"
                        size={14}
                        color="PRIMARY50"
                        style={{ marginRight: 4 }}
                      />
                    }
                    block
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 16,
                      maxWidth: 520,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    onClick={() => {
                      if (fields.length >= 10) {
                        message.warning('최대 10개까지 입력 가능합니다.');
                        return;
                      }
                      add({});
                    }}
                  >
                    국제 공인 기관 평가 자료 추가
                  </Button>
                )}
              </>
            )}
          </Form.List>
        </EvaluationDataListContainer>
        <FooterBox>
          {!readOnly && updateMode && (
            <Button onClick={handleReset}>입력 초기화</Button>
          )}
          <ReadOnlyBackButton readOnly={readOnly}>
            <Flex>
              {basicInfoStatus?.isNmpaDocModStatus && (
                <Button
                  loading={nmpaCorrectRequestWithNoUpdateLoading}
                  onClick={handleNoUpdateSubmit}
                >
                  보완할 내용 없음
                </Button>
              )}
              <Button
                type="primary"
                loading={
                  isUpdateNMPAEvaluationDataLoading ||
                  nmpaOverwriteHistoryLoading
                }
                onClick={form.submit}
              >
                {ctaName}
              </Button>
            </Flex>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

export default NMPAEDT;
