import { useEffect, useMemo } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useAllergensList } from 'service/brand/product/allergensList';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import ProductAllergensListContainer from 'containers/product/ProductAllergensListContainer';
import FullLoading from 'components/FullLoading';
import ProductAllergensListForm from 'components/brand/allergensList/ProductAllergensListForm';

const ProductAllergensListPage = () => {
  const currentProduct = useCurrentProduct();
  const { productId } = currentProduct;
  const countryId = useCountryId();
  const { allergensList, isLoading } = useAllergensList(productId, countryId);

  const notices = useMemo(
    () =>
      allergensList?.uploadedFileUrl
        ? [
            '모든 향료에 대한 Allergens 서류를 영문 PDF 파일로 업로드해 주세요.',
            '원료사에서 제공받은 파일을 그대로 업로드해 주세요.',
            '파일이 여러 개일 경우, 하나의 PDF로 병합하여 업로드해 주세요.',
            <MistakeNoticeItem />,
          ]
        : [
            '원료사에서 받은 모든 향료에 대한 Allergens 서류를 확인하여 기입해 주세요.',
            <MistakeNoticeItem />,
          ],
    [allergensList],
  );

  useEffect(() => {
    if (!isLoading) {
      const rootPageTemplete = document.querySelector(
        '[class*="PageTemplateStyle"]',
      );
      (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
    }
  }, [isLoading]);

  if (isLoading) {
    return <FullLoading />;
  }

  return (
    <ProductPageTemplate
      subtitle="Allergens List"
      notices={notices}
      exampleImg="AllergensList.jpg"
    >
      {allergensList?.uploadedFileUrl ? (
        <ProductAllergensListContainer />
      ) : (
        <ProductAllergensListForm
          productId={productId}
          countryId={countryId}
          allergensList={allergensList}
        />
      )}
    </ProductPageTemplate>
  );
};

export default ProductAllergensListPage;
