import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = <T extends Record<string, any>>({
  path,
}: {
  path: string;
}) => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const handleChangeSearchParams = (params: Partial<T>) => {
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value?.toString());
    }
    history.push(`${path}?${searchParams.toString()}`);
  };

  const replaceSearchParams = (params: Partial<T>) => {
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value?.toString());
    }
    history.replace(`${path}?${searchParams.toString()}`);
  };

  return {
    searchParams,
    onChangeSearchParams: handleChangeSearchParams,
    replaceSearchParams,
  };
};
