import { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import * as H from 'history';
import { Button, Modal } from 'antd';

import { Typography } from './system';
import FooterBox from './FooterBox';
import path from 'lib/path';
import styled from 'styled-components';

interface Props {
  width?: number;
  when?: boolean;
  shouldBlockNavigation?: (nextLocation: H.Location) => boolean;
  description?: string;
  continueText?: string;
  cancelText?: string;
}

const Container = styled.div``;

const StyledButton = styled(Button)`
  min-width: 44px !important;
`;

const RouteLeaveGuard = ({
  width = 400,
  when = true,
  shouldBlockNavigation = () => true,
  description = '정말로 페이지를 벗어나시겠습니까?',
  continueText = '나가기',
  cancelText = '계속입력',
}: Props) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<H.Location | undefined>();
  const [lastAction, setLastAction] = useState<H.Action | undefined>();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const showModal = (location?: H.Location) => {
    setModalVisible(true);
    setLastLocation(location);
  };

  const closeModal = (cb?: () => void) => {
    setModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (
    nextLocation: H.Location,
    action: H.Action,
  ) => {
    if (
      nextLocation.pathname !== path.login &&
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      showModal(nextLocation);
      setLastAction(action);
      return false;
    }

    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        setConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (lastAction === 'PUSH') {
        history.push(lastLocation.pathname);
      } else if (lastAction === 'REPLACE') {
        history.replace(lastLocation.pathname);
      } else if (lastAction === 'POP') {
        history.goBack();
      }
    }
  }, [confirmedNavigation]);

  return (
    <Container>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        centered
        visible={modalVisible}
        cancelText
        width={width}
        footer={null}
        onCancel={() => closeModal()}
        bodyStyle={{
          padding: '56px 24px 32px 24px',
        }}
      >
        <Typography.Text
          style={{ textAlign: 'center', whiteSpace: 'pre-line', fontSize: 14 }}
        >
          {description}
        </Typography.Text>
        <FooterBox modal={true} style={{ paddingTop: 24 }}>
          <StyledButton onClick={() => closeModal()}>{cancelText}</StyledButton>
          <StyledButton
            style={{
              width: 84,
              marginLeft: 8,
            }}
            type="primary"
            onClick={handleConfirmNavigationClick}
          >
            {continueText}
          </StyledButton>
        </FooterBox>
      </Modal>
    </Container>
  );
};

export default RouteLeaveGuard;
