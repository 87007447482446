import { memo } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Upload,
  Modal,
} from 'antd';
import produce from 'immer';

import {
  CertificationType,
  CertificationItemForm,
} from 'types/material/certification';
import palette from 'lib/styles/palette';
import {
  checkIsFutureDate,
  noPastDateRule,
  normalizeDate,
  requireRule,
} from 'lib/validate';
import { MinusIcon } from 'components/system/general/icon';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import DatePicker from 'components/ui/DatePicker/DatePicker';

type HandleChangeItemFn = <T extends keyof CertificationItemForm>(
  index: number,
  {
    key,
    value,
  }: {
    key: T;
    value: CertificationItemForm[T];
  },
) => void;

interface CertificationItemProps extends CertificationItemForm {
  index: number;
  onChangeItem: HandleChangeItemFn;
  onDeleteItem: (index: number) => void;
}

const renderCertificationItem = ({
  index,
  certificationType,
  autoPublished,
  uploadType,
  hasRestrictMaterial,
  enable,
  certificationTitle,
  uploadFile,
  expiryDate,
  isCustom,
  onChangeItem,
  onDeleteItem,
}: CertificationItemProps) => {
  return (
    <Descriptions.Item
      key={`${certificationType}_${index}`}
      label={
        !isCustom ? (
          <>
            <Checkbox
              checked={enable}
              onChange={(e) => {
                if (
                  e.target.checked &&
                  typeof hasRestrictMaterial !== 'undefined'
                ) {
                  Modal.info({
                    title: (
                      <Typography.Text type="TITLE_1">
                        {certificationTitle} 규정 확인
                      </Typography.Text>
                    ),
                    closable: true,
                    icon: null,
                    width: 600,
                    content: (
                      <Typography.Text style={{ width: 468 }}>
                        {certificationTitle.includes('CMR') ? (
                          <>
                            Regulation (EC) No 1272/2008 Annex VI의 Part 3에
                            따라 카테고리 1A 또는 1B의 CMR 물질이 포함되어 있지
                            않음을 확인하였습니까?
                            <br />
                            <br />
                            유럽 규정에 따라 예외사항에 해당하는 CMR 물질의
                            경우, 함유를 선택하여 해당 서류를 업로드해 주세요.
                          </>
                        ) : (
                          <>
                            이 원료는 유럽 규정에 따라 나노물질을 포함하지
                            않았음을 확인하였습니까?
                            <br />
                            <br />
                            유럽 규정에 따라 예외사항에 해당하는 나노 물질의
                            경우, 함유를 선택하여 해당 서류를 업로드해 주세요.
                          </>
                        )}
                      </Typography.Text>
                    ),
                    okText: '규정 확인 완료',
                    okButtonProps: {
                      style: {
                        width: 160,
                      },
                    },
                  });
                }
                onChangeItem(index, { key: 'enable', value: e.target.checked });
                onChangeItem(index, { key: 'uploadType', value: 'AUTO' });
              }}
            >
              {certificationTitle}
            </Checkbox>
            {enable && typeof hasRestrictMaterial !== 'undefined' && (
              <Radio.Group
                value={hasRestrictMaterial}
                style={{ marginTop: 8, paddingLeft: 16 }}
                onChange={(e) => {
                  onChangeItem(index, {
                    key: 'hasRestrictMaterial',
                    value: e.target.value,
                  });
                }}
              >
                <Radio value={false} style={{ lineHeight: 1.5 }}>
                  {certificationTitle.slice(0, certificationTitle.indexOf(' '))}{' '}
                  Free
                </Radio>
                <Radio value={true} style={{ lineHeight: 1.5 }}>
                  함유
                </Radio>
              </Radio.Group>
            )}
          </>
        ) : (
          <Form.Item
            rules={[requireRule]}
            name={[certificationType, index, 'certificationTitle']}
            initialValue={certificationTitle}
          >
            <Input
              placeholder="직접 입력"
              style={{ width: 174, height: 32 }}
              onChange={(e) => {
                onChangeItem(index, {
                  key: 'certificationTitle',
                  value: e.target.value,
                });
              }}
            />
          </Form.Item>
        )
      }
      contentStyle={{ height: 50 }}
    >
      <>
        {isCustom && (
          <MinusIcon
            style={{ position: 'absolute', top: 12, left: -32 }}
            onClick={() => onDeleteItem(index)}
          />
        )}
        {enable && (
          <Row justify="end" align="middle" gutter={16} wrap={false}>
            <Col flex="auto" style={{ padding: 0 }}>
              <Row style={{ width: '100%' }} justify="space-between">
                {autoPublished === true && !hasRestrictMaterial && (
                  <>
                    <Col
                      style={{
                        padding: 0,
                        marginLeft: 16,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Radio.Group
                        disabled={!enable}
                        onChange={(e) => {
                          onChangeItem(index, {
                            key: 'uploadType',
                            value: e.target.value,
                          });
                        }}
                        value={uploadType}
                      >
                        <Radio
                          value="AUTO"
                          style={{ marginRight: 32 }}
                          onChange={(e) => {
                            // AUTO가 선택되었을 떄 uploadFile 제거
                            if (e.target.value) {
                              onChangeItem(index, {
                                key: 'uploadFile',
                                value: undefined,
                              });
                            }
                          }}
                        >
                          서류 자동 발행
                        </Radio>
                        <Radio value="UPLOAD">서류 업로드</Radio>
                      </Radio.Group>
                      {uploadType === 'UPLOAD' && uploadFile && (
                        <Typography.Text
                          type="BODY_2"
                          style={{
                            maxWidth: 250,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {uploadFile.name}
                        </Typography.Text>
                      )}
                    </Col>
                    {uploadType === 'UPLOAD' && (
                      <Col
                        style={{
                          padding: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Upload
                          accept=".pdf"
                          itemRender={() => null}
                          beforeUpload={(uploadFile) => {
                            onChangeItem(index, {
                              key: 'uploadFile',
                              value: uploadFile,
                            });
                            return false;
                          }}
                        >
                          <Button style={{ height: 32 }}>파일 선택</Button>
                        </Upload>
                        <Form.Item
                          name={[certificationType, index, 'uploadFile']}
                          rules={[
                            {
                              validator: async () => {
                                if (!uploadFile) {
                                  throw new Error('필수 항목');
                                }
                              },
                            },
                          ]}
                        >
                          <Input
                            type="file"
                            style={{
                              width: 0,
                              height: 0,
                              padding: 0,
                              border: 'none',
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
                {(typeof autoPublished === 'undefined' ||
                  hasRestrictMaterial) &&
                  uploadFile && (
                    <Col>
                      <Typography.Text type="BODY_2">
                        {uploadFile.name}
                      </Typography.Text>
                    </Col>
                  )}
              </Row>
            </Col>
            {(!autoPublished || hasRestrictMaterial === true) && uploadFile && (
              <Col>
                <Form.Item
                  name={[certificationType, index, 'expiryDate']}
                  initialValue={expiryDate}
                  normalize={normalizeDate}
                  rules={[noPastDateRule]}
                >
                  <DatePicker
                    placeholder="유효기간 (YYYY-MM-DD)"
                    checkIsValidDate={checkIsFutureDate}
                    onChange={(value) =>
                      onChangeItem(index, {
                        key: 'expiryDate',
                        value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )}
            {(!autoPublished || hasRestrictMaterial === true) && (
              <>
                <Col>
                  <Upload
                    accept=".pdf"
                    itemRender={() => null}
                    beforeUpload={(uploadFile) => {
                      onChangeItem(index, {
                        key: 'uploadFile',
                        value: uploadFile,
                      });
                      return false;
                    }}
                  >
                    <Button style={{ height: 32 }}>파일 선택</Button>
                  </Upload>
                  <Form.Item
                    name={[certificationType, index, 'uploadFile']}
                    rules={[
                      {
                        validator: async () => {
                          if (!uploadFile) {
                            throw new Error('필수 항목');
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      style={{
                        width: 0,
                        height: 0,
                        padding: 0,
                        border: 'none',
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        )}
      </>
    </Descriptions.Item>
  );
};

interface CertificationGroupProps {
  form: FormInstance<any>;
  title: string;
  certificationType: CertificationType;
  addable?: boolean;
  certificationItems: CertificationItemForm[];
  onChangeCertificationItems: React.Dispatch<
    React.SetStateAction<CertificationItemForm[]>
  >;
}

const CertificationGroupBlock = styled.div`
  & + & {
    margin-top: 24px;
  }

  .ant-descriptions-header {
    margin-bottom: 12px;

    .ant-descriptions-title {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .ant-descriptions-view {
    border: none;
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
    overflow: visible;

    .ant-descriptions-row {
      position: relative;
      border-bottom: 1px solid #d8d8d8;

      .ant-descriptions-item-label {
        background-color: #f9f9f9;
        border-right: none;
      }
      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-control-input,
        .ant-form-item-explain .ant-form-item-explain-error {
          min-height: 0;
        }
      }
    }
  }
`;

export const CertificationGroup = memo(
  ({
    form,
    certificationType,
    title,
    addable,
    certificationItems,
    onChangeCertificationItems,
  }: CertificationGroupProps) => {
    const handleChangeCertificateItem: HandleChangeItemFn = (
      index,
      { key, value },
    ) => {
      if (key === 'uploadFile') {
        form.validateFields([[title.toUpperCase(), index, 'uploadFile']]);
      }
      onChangeCertificationItems((draft) =>
        produce(draft, (draft) => {
          draft[index][key] = value;
        }),
      );
    };
    const handleAddCertificateItem = () => {
      onChangeCertificationItems((draft) =>
        produce(draft, (draft) => {
          draft.push({
            certificationType,
            enable: true,
            certificationTitle: '',
            isCustom: true,
          });
        }),
      );
    };
    const handleDeleteCertificateItem = (index: number) => {
      onChangeCertificationItems((draft) =>
        produce(draft, (draft) => {
          draft.splice(index, 1);
        }),
      );
    };
    return (
      <CertificationGroupBlock>
        <Descriptions
          bordered
          title={title}
          column={1}
          labelStyle={{ width: 240 }}
          size="small"
        >
          {certificationItems.map((item, index) =>
            renderCertificationItem({
              ...item,
              index,
              onChangeItem: handleChangeCertificateItem,
              onDeleteItem: handleDeleteCertificateItem,
            }),
          )}
        </Descriptions>
        {addable && (
          <Button
            type="dashed"
            icon={
              <Icon
                name="plus"
                size={14}
                color="PRIMARY50"
                style={{ marginRight: 4 }}
              />
            }
            block
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 8,
              maxWidth: 520,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            onClick={handleAddCertificateItem}
          >
            {title} 인증 항목 추가
          </Button>
        )}
      </CertificationGroupBlock>
    );
  },
);
