import { Redirect, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import path from 'lib/path';
import * as authActions from 'modules/auth';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lowerCasedSearch = search.toLocaleLowerCase();

  useEffect(() => {
    if (
      lowerCasedSearch === '?reason=tokenerror' ||
      lowerCasedSearch === '?reason=expire'
    ) {
      dispatch(authActions.logout(true));
      return;
    }
    dispatch(authActions.logout());
  }, []);

  return <Redirect to={`${path.login}${search}`} />;
};

export default LogoutPage;
