import { useState } from 'react';
import styled from 'styled-components';
import { Button, Col, message, Row } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { DocStatus, DocumentCode } from 'types/product';
import {
  useManufacturerDeclarations,
  useMDDocumentForm,
  useProductDocStatus,
} from 'service/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import palette from 'lib/styles/palette';
import history from 'lib/history';
import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
} from 'components/ui';
import { Typography } from 'components/system';
import ProductSingleFile, { ProductSingleFileProps } from './ProductSingleFile';
import UpdateLog from './UpdateLog';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { updateCurrentPathname } from 'modules/product';
import Icon from 'components/ui/Icon/Icon';

const SignImgContainer = styled(Flex)`
  position: relative;
  width: 810px;
  height: 280px;
  background: ${palette.SLATE_GRAY10};
  border-radius: 12px;
  padding: 32px 76px 32px 56px;
  box-sizing: border-box;
`;

const ProductManufacturerDeclaration = () => {
  const dispatch = useDispatch();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { sendEventToGA } = useGA();
  const {
    createManufacturerDeclaration,
    isCreateManufacturerDeclarationLoading,
    isUpdateManufacturerDeclarationLoading,
    updateManufacturerDeclaration,
  } = useManufacturerDeclarations();
  const currentProduct = useCurrentProduct();
  const productId = currentProduct.productId;
  const countryId = useCountryId();
  const { downloadMDDocumentForm, isLoading } = useMDDocumentForm();
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.SMP,
  });
  const { skipMode } = useSelector(
    ({ product }: any) => ({
      currentProduct: product.currentProduct,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
    }),
    shallowEqual,
  );
  const updateMode =
    docStatus !== null &&
    docStatus.status !== DocStatus.INP &&
    docStatus.status !== DocStatus.OPT;

  const handleSuccess = () => {
    sendEventToGA({
      documentName: "Manufacturer's Declaration",
      actionType: !updateMode
        ? EGAActionType.REGISTER
        : docStatus.status === 'MOD'
        ? EGAActionType.MODIFY
        : EGAActionType.UPDATE,
    });
    if (skipMode) {
      dispatch(updateCurrentPathname());
      return;
    }
    history.goBack();
    message.success('입력되었습니다.');
  };

  const handleSubmit: ProductSingleFileProps['onSubmit'] = ({
    productId,
    countryId,
    updateMode,
    file,
  }) => {
    if (!updateMode) {
      createManufacturerDeclaration(
        {
          productId,
          countryId,
          file,
        },
        {
          onSuccess: handleSuccess,
        },
      );
    } else {
      updateManufacturerDeclaration(
        {
          productId,
          countryId,
          file,
        },
        {
          onSuccess: handleSuccess,
        },
      );
    }
  };

  return (
    <div style={{ marginTop: 20, paddingLeft: 52 }}>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.MD}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.MD}
            />
          </Col>
        )}
      </Row>
      <OrderList>
        <OrderListItem horizontal columnGap={16}>
          <OrderListItemLabel>서류를 다운로드해 주세요.</OrderListItemLabel>
          <OrderListItemContent>
            <Button
              loading={isLoading}
              style={{ borderRadius: 4, display: 'flex', alignItems: 'center' }}
              icon={
                <Icon
                  name="download"
                  size={14}
                  color="PRIMARY50"
                  style={{ marginRight: 4 }}
                />
              }
              onClick={() => {
                downloadMDDocumentForm({
                  productId: currentProduct.productId,
                  countryId,
                });
              }}
            >
              서류 양식 다운로드
            </Button>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem rowGap={16}>
          <OrderListItemLabel>
            제조사 책임자 정보를 영문으로 기입 후 서명을 해주세요.
          </OrderListItemLabel>
          <OrderListItemContent>
            <Flex>
              <SignImgContainer>
                <img
                  src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/manu_sign.png"
                  alt="sign"
                />
                <Flex
                  dir="column"
                  style={{ position: 'absolute', top: 112, right: 80 }}
                >
                  <Typography.Text>
                    제조사 책임자 정보 (영문 기입)
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: 8 }} color="SLATE_GRAY70">
                    예시1 - Q.C. Manager GILDONG HONG
                  </Typography.Text>
                  <Typography.Text
                    color="SLATE_GRAY70"
                    gutter={{ bottom: 16 }}
                    style={{ fontSize: 8 }}
                  >
                    예시2 - R&amp;D Director GILDONG HONG
                  </Typography.Text>
                  <Typography.Text>서명</Typography.Text>
                </Flex>
              </SignImgContainer>
            </Flex>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem rowGap={24}>
          <OrderListItemLabel>
            서명이 완료된 파일을 스캔 후 아래에 업로드해 주세요.
          </OrderListItemLabel>
          <OrderListItemContent>
            <ProductSingleFile
              loading={
                isCreateManufacturerDeclarationLoading ||
                isUpdateManufacturerDeclarationLoading
              }
              documentCode={DocumentCode.MD}
              onSubmit={handleSubmit}
              correctRequestWithNoUpdate={true}
            />
          </OrderListItemContent>
        </OrderListItem>
      </OrderList>
    </div>
  );
};

export default ProductManufacturerDeclaration;
