import { APIResponse } from 'types/common';
import {
  Classification,
  MsdsAdd,
  MsdsDefaultInfo,
  MsdsGet,
  MsdsUpdate,
  Pictogram,
  StatementMap,
} from 'types/material/msds';
import client from 'lib/api/client';

export const getClassifications = () =>
  client.get<APIResponse<Classification[]>>('/material/msds/GHSs');

export const getStatementMap = (materialCategoryIds: number[]) =>
  client.get<APIResponse<StatementMap>>(
    `/material/msds/GHSs/statements?materialCategoryIds=${materialCategoryIds.join(
      ',+',
    )}`,
  );

export const getMsdsDefaultInfo = (materialId: number) =>
  client.get<APIResponse<MsdsDefaultInfo>>(
    `/materials/${materialId}/msds/default-info`,
  );

export const getMsds = (materialId: number) =>
  client.get<APIResponse<MsdsGet>>(`/material/msds?materialId=${materialId}`);

export const addMsds = (msds: MsdsAdd) =>
  client.post('/material/msds', {
    ...msds,
  });

export const updateMsds = ({ materialMsdsBasicInfoId, ...rest }: MsdsUpdate) =>
  client.patch(`/material/msds/${materialMsdsBasicInfoId}`, rest);

export const getAllPictograms = () =>
  client.get<APIResponse<Pictogram[]>>('/material/msds/pictograms');
