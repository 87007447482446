import { useLayoutEffect } from 'react';
import { Modal } from 'antd';

import history from 'lib/history';
import { messages } from 'lib/consts';
import { useCountryId } from 'service/brand/product/product';

const GoBackIfThereIsNoCountryId = () => {
  const countryId = useCountryId();

  useLayoutEffect(() => {
    if (countryId === undefined) {
      Modal.warn({
        content: messages.INVALID_APPROACH,
        onOk: () => {
          history.goBack();
        },
      });
    }
  }, [countryId]);

  return null;
};

export default GoBackIfThereIsNoCountryId;
