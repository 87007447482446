import styled from 'styled-components';
import { Modal, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { UseGuideItem } from 'types/service';
import { useGuides } from 'service/service';
import * as serviceActions from 'modules/service';
import Table from 'components/ui/Table/Table';

const UseGuideContainer = ({
  initialUseGuideId,
}: {
  initialUseGuideId?: string;
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { useGuideData, isLoading } = useGuides(page);

  const setPost = (post: any) => {
    dispatch(serviceActions.setPost(post));
  };

  useEffect(() => {
    if (initialUseGuideId) {
      const initialPost = useGuideData?.content.find(
        (item: any) => item.useGuideId === Number(initialUseGuideId),
      );

      if (initialPost) {
        setPost(initialPost);
      }
    }
  }, [initialUseGuideId, useGuideData]);

  const columns: ColumnsType<UseGuideItem> = [
    {
      title: '번호',
      dataIndex: 'useGuideId',
      align: 'center',
      width: 160,
    },
    {
      title: '제목',
      render: (_, { title, content }) => (
        <Typography.Link
          onClick={() => {
            Modal.info({
              width: 1000,
              icon: null,
              closable: true,
              title,
              content: (
                <div
                  style={{ height: 500, overflow: 'scroll' }}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              ),
            });
          }}
        >
          {title}
        </Typography.Link>
      ),
    },
    {
      title: '작성자',
      align: 'center',
      width: 160,
      render: () => '관리자',
    },
  ];

  return (
    <Container>
      <Typography.Title
        level={3}
        style={{ textAlign: 'center', fontWeight: 300, marginBottom: 32 }}
      >
        이용 안내
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={useGuideData?.content}
        loading={isLoading}
        rowKey="useGuideId"
        pagination={{
          current: page,
          pageSize: 10,
          onChange: setPage,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-top: 64px;
`;

export default UseGuideContainer;
