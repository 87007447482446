import { useMutation, useQuery } from 'react-query';
import qs from 'qs';
import { useCallback, useMemo } from 'react';

import client from 'lib/api/client';
import { APIPageableResponse, APIResponse } from 'types/common';
import {
  DocumentCode,
  ProductDocumentStatusCode,
  DocumentRelatedId,
  ProductDocument,
  ProductListItem,
  Retailer,
} from 'types/brand/retailer/retailerApply';
import { message } from 'antd';
import { downloadFiles, getFilenameExtension } from 'lib/file';

export const allRetailerIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

export const useRetailerApplyProducts = ({
  page,
  size = 10,
  searchValue,
}: {
  page: number;
  size?: number;
  searchValue: string;
}) => {
  const {
    data: retailerProducts = { content: [], totalElements: 0 },
    isLoading,
    refetch,
  } = useQuery(
    ['retailer-apply/products', page, size, searchValue],
    () =>
      client.get<APIPageableResponse<ProductListItem[]>>(
        `/v1/retailer-apply/products?${qs.stringify({
          page,
          size,
          ...(searchValue.trim() && { all: searchValue }),
        })}`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(
    () => ({
      retailerProducts: retailerProducts.content,
      totalElements: retailerProducts.totalElements,
      isLoading,
      refetch,
    }),
    [retailerProducts, isLoading, refetch],
  );
};

export const useSavedSelectedRetailerIds = (productId: number) => {
  const { data: userSelectRetailers = [], isLoading } = useQuery(
    ['retailer-apply/products', productId],
    () =>
      client.get<APIResponse<{ productId: number; retailerId: number }[]>>(
        `/v1/retailer-apply/products/${productId}/retailers`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(
    () => ({
      savedRetailerIds: userSelectRetailers.map(({ retailerId }) => retailerId),
      isLoading,
    }),
    [userSelectRetailers, isLoading],
  );
};

export const useSelectRetailerIds = (productId: number) => {
  const { mutate: selectRetailerIds } = useMutation((retailerIds: number[]) =>
    client.post(
      `/v1/retailer-apply/products/${productId}/retailers`,
      retailerIds,
    ),
  );

  return useMemo(() => ({ selectRetailerIds }), [selectRetailerIds]);
};

export const useUnselectRetailerIds = (productId: number) => {
  const { mutate: unselectRetailerIds } = useMutation((retailerIds: number[]) =>
    client.delete(`/v1/retailer-apply/products/${productId}/retailers`, {
      data: retailerIds,
    }),
  );

  return useMemo(() => ({ unselectRetailerIds }), [unselectRetailerIds]);
};

export const useRetailerApplyProductDetail = (
  productId: number,
  selectedRetailerIds: number[],
) => {
  const { data: productDocuments = [], isLoading } = useQuery(
    ['retailer-apply/documents'],
    () =>
      client.get<APIResponse<ProductDocument[]>>(
        `/v1/retailer-apply/products/${productId}/documents`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const [sharedDocuments, privateDocuments] = useMemo(() => {
    return productDocuments.reduce(
      (prev, document) => {
        document.forAllPlatform
          ? prev[0].push(document)
          : prev[1].push(document);
        return prev;
      },
      [[], []] as [ProductDocument[], ProductDocument[]],
    );
  }, [productDocuments]);
  const allRetailerIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  const { data: documentRelatedIds = [] } = useQuery(
    ['retailers/retailer-apply-doc'],
    () =>
      client.get<APIResponse<DocumentRelatedId[]>>(
        `/v1/retailer-apply/retailers/retailer-apply-doc`,
      ),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const retailerDocumentMap = allRetailerIds.reduce((map, id) => {
    const retailerRelatedIds = documentRelatedIds.filter(
      ({ retailerId }) => retailerId === id,
    );
    const retailerApplyDocSeqIds = retailerRelatedIds.map(
      ({ retailerApplyDocSeqId }) => retailerApplyDocSeqId,
    );
    if (retailerRelatedIds.length > 0) {
      map.set(
        retailerRelatedIds[0].retailerId,
        privateDocuments.filter(({ retailerApplyDocSeqId }) =>
          retailerApplyDocSeqIds.some((id) => retailerApplyDocSeqId === id),
        ),
      );
    }
    return map;
  }, new Map<number, ProductDocument[]>());

  const OBDIds = [4, 5, 6, 7, 8, 9, 10];
  const isNeedOBD = useMemo(
    () => selectedRetailerIds.some((id) => OBDIds.includes(id)),
    [selectedRetailerIds],
  );
  const checkIsDownloadable = useCallback(
    (retailerId: number) => {
      const isAllUploadedPrivateDocuments = retailerDocumentMap
        .get(retailerId)
        ?.every(
          ({ retailerApplyDocStatus }) =>
            retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN ||
            retailerApplyDocStatus.status === ProductDocumentStatusCode.OPT,
        );

      if (OBDIds.includes(retailerId)) {
        return (
          (isAllUploadedPrivateDocuments &&
            sharedDocuments.every(
              ({ retailerApplyDocStatus }) =>
                retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN,
            )) ||
          false
        );
      }

      return (
        isAllUploadedPrivateDocuments &&
        sharedDocuments
          .filter(({ documentCode }) => documentCode !== DocumentCode.OBD)
          .every(
            ({ retailerApplyDocStatus }) =>
              retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN,
          )
      );
    },
    [retailerDocumentMap, sharedDocuments],
  );

  const parseFile = useCallback(
    (files: ProductDocument[]) =>
      files
        .map(({ documentName, retailerApplyDocStatus }) => {
          if (retailerApplyDocStatus.retailerApplyFile.length > 1) {
            return [
              {
                name: `${documentName}_(앞면).${getFilenameExtension(
                  retailerApplyDocStatus.retailerApplyFile[0].url,
                )}`,
                url: retailerApplyDocStatus.retailerApplyFile[0].url,
              },
              {
                name: `${documentName}_(뒷면).${getFilenameExtension(
                  retailerApplyDocStatus.retailerApplyFile[1].url,
                )}`,
                url: retailerApplyDocStatus.retailerApplyFile[1].url,
              },
            ];
          } else {
            return {
              name: `${documentName}.${getFilenameExtension(
                retailerApplyDocStatus.retailerApplyFile[0].url,
              )}`,
              url: retailerApplyDocStatus.retailerApplyFile[0].url,
            };
          }
        })
        .flat()
        .map((document, index) => ({
          ...document,
          name: `${index + 1}. ${document.name}`,
        })),
    [getFilenameExtension],
  );

  const getDownloadableRetailerFiles = useCallback(
    (retailerId: number) => {
      const retailerPrivateDocuments = retailerDocumentMap
        ?.get(retailerId)
        ?.filter(
          ({ retailerApplyDocStatus }) =>
            retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN,
        );

      if (!retailerPrivateDocuments) {
        return;
      }

      const retailerSharedDocuments = [
        ...(OBDIds.includes(retailerId)
          ? sharedDocuments
          : sharedDocuments.filter(
              ({ documentCode }) => documentCode !== DocumentCode.OBD,
            )),
      ];

      if (
        !retailerSharedDocuments.every(
          ({ retailerApplyDocStatus }) =>
            retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN,
        )
      ) {
        return;
      }

      const files = [...retailerSharedDocuments, ...retailerPrivateDocuments];

      return parseFile(files);
    },
    [retailerDocumentMap, sharedDocuments, OBDIds, parseFile],
  );

  const downloadableRetailerFilesMap = useMemo(() => {
    return allRetailerIds
      .filter((id) => id !== 0)
      .reduce(
        (map, id) => {
          const files = getDownloadableRetailerFiles(id);
          if (checkIsDownloadable(id) && files) {
            map.set(id, files);
          }
          return map;
        },
        new Map<
          number,
          {
            name: string;
            url: string;
          }[]
        >(),
      );
  }, [checkIsDownloadable, allRetailerIds, getDownloadableRetailerFiles]);

  const downloadRetailerFileZip = useCallback(
    async (retailerId: number, fileName: string) => {
      const retailerFiles = downloadableRetailerFilesMap.get(retailerId);
      if (retailerFiles) {
        await downloadFiles(retailerFiles, fileName);
      } else {
        message.warn('다운로드 가능한 서류가 없습니다.');
      }
    },
    [downloadableRetailerFilesMap, downloadFiles],
  );

  const { data: retailers = [] } = useQuery(
    ['retailers'],
    () => client.get<APIResponse<Retailer[]>>('/v1/retailer-apply/retailers'),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const retailerMap = retailers.reduce(
    (map, { retailerId, name }) => {
      return map.set(retailerId, name);
    },
    new Map<number, string>([[0, '전체 리스트']]),
  );

  const retailerApplyDocSeqIdSet = useMemo(() => {
    return selectedRetailerIds.reduce((set, id) => {
      const retailerRelatedIds = retailerDocumentMap
        ?.get(id)
        ?.map(({ retailerApplyDocSeqId }) => retailerApplyDocSeqId);
      if (retailerRelatedIds) {
        retailerRelatedIds.forEach((retailerApplyDocSeqId) => {
          set.add(retailerApplyDocSeqId);
        });
      }
      return set;
    }, new Set<number>());
  }, [selectedRetailerIds, retailerDocumentMap]);

  const userPrivateDocuments = useMemo(() => {
    return privateDocuments
      .filter(({ retailerApplyDocSeqId }) =>
        retailerApplyDocSeqIdSet.has(retailerApplyDocSeqId),
      )
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }, [privateDocuments, retailerApplyDocSeqIdSet]);

  const downloadAllFINActiveDocuments = useCallback(
    async (fileName: string) => {
      const files = [
        ...sharedDocuments.filter(
          ({ retailerApplyDocStatus }) =>
            retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN,
        ),
        ...userPrivateDocuments.filter(
          ({ retailerApplyDocStatus }) =>
            retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN,
        ),
      ];

      const retailerFiles = parseFile(files);
      await downloadFiles(retailerFiles, fileName);
    },
    [sharedDocuments, userPrivateDocuments],
  );

  return useMemo(
    () => ({
      retailers,
      retailerMap,
      sharedDocuments,
      userPrivateDocuments,
      retailerDocumentMap,
      isLoading,
      isNeedOBD,
      downloadableRetailerFilesMap,
      downloadRetailerFileZip,
      downloadAllFINActiveDocuments,
    }),
    [
      retailers,
      retailerMap,
      sharedDocuments,
      userPrivateDocuments,
      retailerDocumentMap,
      isLoading,
      isNeedOBD,
      downloadableRetailerFilesMap,
      downloadRetailerFileZip,
      downloadAllFINActiveDocuments,
    ],
  );
};
