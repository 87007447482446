import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import Flex from './Flex/Flex';
import { Typography } from 'components/system';

const MyPageTabs = ({
  activeTab,
  tabs,
  onChange,
}: {
  activeTab: string;
  tabs: { label: string; value: string }[];
  onChange: (tab: string) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const activeTabIdx = tabs.findIndex(({ value }) => activeTab === value);

    ref.current?.scrollTo({
      left: 138 * activeTabIdx - 138,
      behavior: 'smooth',
    });
  }, [activeTab]);

  return (
    <Container>
      <Typography.Text
        type="TITLE_1"
        color="SLATE_GRAY80"
        gutter={{ left: 16, bottom: 24 }}
        style={{ fontWeight: 500 }}
      >
        마이페이지
      </Typography.Text>
      {tabs.map(({ label, value }) => {
        const isActive = activeTab === value;
        return (
          <StepDiv
            key={value}
            align="center"
            gap={8}
            active={activeTab === value}
            onClick={() => onChange(value)}
          >
            <StepTitle active={isActive}>{label}</StepTitle>
          </StepDiv>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 216px;
  border-right: 1px solid ${palette.GRAY30};
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  padding: 136px 16px;
`;

const StepDiv = styled(Flex)<{ active: boolean }>`
  padding: 18px 16px;
  border-radius: 4px;
  ${({ active }) => css`
    background-color: ${active ? `${palette.SLATE_GRAY10}` : `transparent`};
  `};
  cursor: pointer;
`;

const StepTitle = styled(Typography.Text)<{ active: boolean }>`
  font-size: 14px;
  font-weight: ${({ active }) => (active ? `500` : `400`)};
  text-align: center;
  color: ${({ active }) =>
    active ? `${palette.SLATE_GRAY70}` : `${palette.SLATE_GRAY50}`};
`;

export default MyPageTabs;
