import { Button, Form, Spin, message } from 'antd';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { useUpdateMode } from 'hook/material';
import { useModal } from 'hook/useModal';
import { scrollToFirstErrorOption } from 'lib/consts';
import { useCertificationItems } from 'service/material/certification';
import {
  CertificationItemForm,
  CertificationItemGet,
  CertificationType,
} from 'types/material/certification';
import { CertificationGroup } from './CertificationGroup';

const RawMaterialCertificationBlock = styled.div``;

interface DeFaultCertificationItem {
  title: string;
  autoPublished?: boolean;
  hasRestrictMaterial?: boolean;
}

const defaultCertificationItems: {
  [key in CertificationType]: DeFaultCertificationItem[];
} = {
  GENERAL: [
    { title: 'Non-Animal Test', autoPublished: true },
    { title: 'BSE / TSE Free', autoPublished: true },
    {
      title: 'CMR Declaration',
      autoPublished: true,
      hasRestrictMaterial: false,
    },
    {
      title: 'Nano material Declaration',
      autoPublished: true,
      hasRestrictMaterial: false,
    },
    { title: 'CITES Statement' },
    { title: 'RSPO' },
    { title: 'REACH Registration' },
    { title: 'Halal Attestation' },
  ],
  VEGAN: [
    { title: 'Vegan Statement (자사)' },
    { title: 'The Vegan Society' },
    { title: 'EVE Vegan' },
    { title: 'Vegan Action' },
    { title: '한국 비건 인증원' },
  ],
  ORGANIC: [
    { title: 'Ecocert' },
    { title: 'USDA' },
    { title: 'ISO16128' },
    { title: 'COSMETIQUE' },
    { title: 'BDIH' },
    { title: 'Canada Organic' },
    { title: 'Biologique' },
    { title: 'COSMOS' },
  ],
  NATURAL: [
    { title: 'Natural Statement (자사)' },
    { title: 'COSMOS Natural' },
    { title: 'Natural Seal Personal Care' },
    { title: 'IBD certification' },
    { title: 'BioGro' },
    { title: 'CCPB' },
    { title: 'SGS INSTITUT FRESENIUS' },
  ],
  ETC: [],
};

const getDefaultCertificationItems = (certificationType: CertificationType) =>
  defaultCertificationItems[certificationType].map(
    ({ title, autoPublished, hasRestrictMaterial }) => ({
      certificationType,
      certificationTitle: title,
      isCustom: false,
      enable: false,
      ...(autoPublished && { autoPublished }),
      ...(typeof hasRestrictMaterial !== 'undefined' && {
        hasRestrictMaterial,
      }),
    }),
  );

const initializeCertificationItems = (
  isCustom: boolean,
  item: CertificationItemGet,
  setter: React.Dispatch<React.SetStateAction<CertificationItemForm[]>>,
) => {
  const {
    materialCertificationId,
    certificationType,
    certificationTitle,
    expiryDate,
    filename,
    attachUrl,
    hasRestrictMaterial,
  } = item;
  if (!isCustom) {
    setter((draft) =>
      produce(draft, (draft) => {
        const targetItem = draft.find(
          (item) => item.certificationTitle === certificationTitle,
        )!;
        targetItem.materialCertificationId = materialCertificationId;
        targetItem.isCustom = false;
        targetItem.enable = true;
        targetItem.uploadType = targetItem.autoPublished
          ? attachUrl
            ? 'UPLOAD'
            : 'AUTO'
          : undefined;
        if (hasRestrictMaterial !== null) {
          targetItem.hasRestrictMaterial = hasRestrictMaterial;
        }
        if (expiryDate) {
          targetItem.expiryDate = expiryDate;
        }
        if (filename && attachUrl) {
          targetItem.uploadFile = { name: filename, attachUrl };
        }
      }),
    );
  } else {
    setter((draft) =>
      draft.concat([
        {
          materialCertificationId,
          isCustom: true,
          enable: true,
          certificationType,
          certificationTitle,
          ...(expiryDate && { expiryDate }),
          ...(filename &&
            attachUrl && {
              uploadFile: { name: filename, attachUrl },
            }),
        },
      ]),
    );
  }
};

const RawMaterialCertification = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const [form] = Form.useForm();
  const { openConfirmModal } = useModal();
  const [generalCertificationItems, setGeneralCertificationItems] = useState<
    CertificationItemForm[]
  >(getDefaultCertificationItems('GENERAL'));

  const [veganCertificationItems, setVeganCertificationItems] = useState<
    CertificationItemForm[]
  >(getDefaultCertificationItems('VEGAN'));

  const [organicCertificationItems, setOrganicCertificationItems] = useState<
    CertificationItemForm[]
  >(getDefaultCertificationItems('ORGANIC'));

  const [naturalCertificationItems, setNaturalCertificationItems] = useState<
    CertificationItemForm[]
  >(getDefaultCertificationItems('NATURAL'));

  const [etcCertificationItems, setEtcCertificationItems] = useState<
    CertificationItemForm[]
  >(getDefaultCertificationItems('ETC'));
  const updateMode = useUpdateMode();
  const {
    certificationItems,
    getLoading,
    addCertificationItems,
    addLoading,
    updateCertificationItems,
    updateLoading,
  } = useCertificationItems(materialId, updateMode);

  const handleFinishFailed = ({ errorFields }: any) => {
    if (
      errorFields.some(
        ({ name }: any) => Array.isArray(name) && name.includes('uploadFile'),
      )
    ) {
      openConfirmModal({
        title: '체크하신 항목의 서류를 업로드해 주세요.',
        content: `서류가 업로드 되지 않았습니다.`,
        noCancelButton: true,
      });
    }
  };

  const handleSubmit = () => {
    const enableItems = [
      ...generalCertificationItems,
      ...veganCertificationItems,
      ...organicCertificationItems,
      ...naturalCertificationItems,
      ...etcCertificationItems,
    ]
      .filter(({ enable }) => enable)
      .map((value) => ({
        ...value,
        isAutoGenerateDocument:
          value.autoPublished && value.uploadFile !== undefined
            ? false
            : value.autoPublished,
      }));

    if (!updateMode && enableItems.length === 0) {
      return message.warn('1개 이상의 항목을 입력해 주세요.');
    }

    if (!updateMode) {
      addCertificationItems({ materialId, certificationItems: enableItems });
    } else {
      updateCertificationItems({ materialId, certificationItems: enableItems });
    }
  };

  useEffect(() => {
    if (certificationItems.length) {
      certificationItems.forEach((item) => {
        const { certificationType, certificationTitle } = item;
        const isCustom = defaultCertificationItems[certificationType].every(
          ({ title }) => title !== certificationTitle,
        );
        switch (certificationType) {
          case 'GENERAL': {
            initializeCertificationItems(
              isCustom,
              item,
              setGeneralCertificationItems,
            );
            break;
          }
          case 'VEGAN': {
            initializeCertificationItems(
              isCustom,
              item,
              setVeganCertificationItems,
            );
            break;
          }
          case 'ORGANIC': {
            initializeCertificationItems(
              isCustom,
              item,
              setOrganicCertificationItems,
            );
            break;
          }
          case 'NATURAL': {
            initializeCertificationItems(
              isCustom,
              item,
              setNaturalCertificationItems,
            );
            break;
          }
          case 'ETC': {
            initializeCertificationItems(
              isCustom,
              item,
              setEtcCertificationItems,
            );
            break;
          }
        }
      });
    }
  }, [certificationItems]);

  return (
    <RawMaterialCertificationBlock>
      <Spin spinning={getLoading}>
        <Form
          form={form}
          onFinishFailed={handleFinishFailed}
          onFinish={handleSubmit}
          scrollToFirstError={scrollToFirstErrorOption}
        >
          <CertificationGroup
            form={form}
            title="General"
            certificationType="GENERAL"
            certificationItems={generalCertificationItems}
            onChangeCertificationItems={setGeneralCertificationItems}
          />
          <CertificationGroup
            form={form}
            title="Vegan"
            certificationType="VEGAN"
            addable
            certificationItems={veganCertificationItems}
            onChangeCertificationItems={setVeganCertificationItems}
          />
          <CertificationGroup
            form={form}
            title="Organic"
            certificationType="ORGANIC"
            addable
            certificationItems={organicCertificationItems}
            onChangeCertificationItems={setOrganicCertificationItems}
          />
          <CertificationGroup
            form={form}
            title="Natural"
            certificationType="NATURAL"
            addable
            certificationItems={naturalCertificationItems}
            onChangeCertificationItems={setNaturalCertificationItems}
          />
          <CertificationGroup
            form={form}
            title="기타"
            certificationType="ETC"
            addable
            certificationItems={etcCertificationItems}
            onChangeCertificationItems={setEtcCertificationItems}
          />
          <FooterBox>
            <Button
              htmlType="submit"
              type="primary"
              loading={addLoading || updateLoading}
            >
              {!updateMode ? '저장' : '수정'}
            </Button>
          </FooterBox>
        </Form>
      </Spin>
    </RawMaterialCertificationBlock>
  );
};

export default RawMaterialCertification;
