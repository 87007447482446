import qs from 'qs';

import client from 'lib/api/client';
import { SelfEstimateTarget } from 'types/brand/estimate';
import { APIResponse } from 'types/common';

export const addEstimateTargets = (estimateTargets: {
  productId: number;
  countryCodes: string[];
}) =>
  client.post<APIResponse<SelfEstimateTarget[]>>(
    `/estimate-targets`,
    estimateTargets,
  );
export const deleteEstimateTargets = (estimateTargetIds: number[]) =>
  client.delete(
    `/estimate-targets?${qs.stringify(
      { estimateTargetIds },
      { indices: false },
    )}`,
  );
export const publishEstimate = (estimateTargetIds: number[]) =>
  client.post(
    `/estimate?${qs.stringify(estimateTargetIds, { indices: false })}`,
  );
export const contract = (estimateId: number, isAgreeContract: boolean) =>
  client.post(`/contract/${estimateId}`, { isAgreeContract: isAgreeContract });

export const setAddress = (params: { estimateId: number; address: string }) =>
  client.patch(
    `/estimate/address/${params.estimateId}?postAddress=${encodeURIComponent(
      params.address,
    )}`,
  );
export const getEstimate = (companyId: number) =>
  client.get(`/estimate/${companyId}`);
export const getEstimates = (params: { companyId: number; pageNo: number }) =>
  client.get(`/estimates/${params.companyId}/${params.pageNo}/10`);
export const getEstimateHistories = (estimateId: number) =>
  client.get(`/estimate/history/${estimateId}`);
