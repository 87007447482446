import { useMemo, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import { toggleJapaneseProductNameModalVisible } from 'modules/certificate';
import UpdateLog from 'components/product/UpdateLog';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { useCountryProductName } from 'service/brand/certificate/certificateDetail';
import { DocumentCode } from 'types/product';
import { useUpdateJapaneseProductName } from 'service/brand/product/product';
import { normalizeBlank } from 'lib/form';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { useProductDocStatus } from 'service/product';

const JapaneseProductNameModal = ({
  certTargetId,
}: {
  certTargetId: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const dispatch = useDispatch();
  const visible = useSelector(
    ({ certificate }: any) => certificate.japaneseProductNameModalVisible,
  );
  const onClose = () => {
    dispatch(toggleJapaneseProductNameModalVisible());
  };
  const {
    form,
    status,
    productId,
    countryId,
    readOnlyMode,
    onSubmit,
  } = useCountryProductName();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PN,
  });
  const ctaName = useMemo(() => {
    if (productDocStatus?.status === 'ONG') return '수정';
    if (productDocStatus?.status === 'MOD') return '보완 완료';
    return '등록';
  }, [productDocStatus]);
  const { updateProductName, isLoading } = useUpdateJapaneseProductName({
    productDocStatus,
    certTargetId,
  });
  return (
    <Modal visible={visible} footer={null} destroyOnClose onCancel={onClose}>
      <Typography.Text type="TITLE_1">일본 제품명 관리</Typography.Text>
      <Typography.Text type="BODY_2" style={{ paddingLeft: 4 }}>
        일본 브랜드명과 제품명이 없으신 경우, certicos에서 추천해 드리고
        있습니다.
        <br />
        전화나 하단의 ‘이용 문의’로 문의해 주세요.
      </Typography.Text>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.PN}
            productId={productId}
            countryId={countryId}
          />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.BASIC}
            validCodes={['BASIC01-BNJP', 'BASIC01-PNE', 'BASIC01-PNJP']}
          />
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={(values) =>
          onSubmit(values, (params) => updateProductName(params))
        }
      >
        <Form.Item
          label="일본 브랜드명"
          name="countryBrandName"
          rules={[
            requireRule,
            exceptKoreanRule,
            {
              pattern: /^.{1,20}$/i,
              message: '최대 20자',
            },
          ]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="제품명 (영문)"
          name="productNameEn"
          normalize={normalizeBlank}
          rules={[requireRule, exceptKoreanRule]}
        >
          {/* HINT : 입력 정보가 필수 입력(INP)인 경우는 제품명 (영문) disabled. */}
          <Input
            disabled={readOnlyMode || status === 'INP'}
            onBlur={(e) => {
              const value = e.target.value;

              if (value.endsWith(' ')) {
                form.setFieldsValue({
                  productNameEn: value.trimEnd(),
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="제품명 (일본어)"
          name="countryProductName"
          normalize={normalizeBlank}
          rules={[
            requireRule,
            exceptKoreanRule,
            {
              pattern: /^.{1,20}$/i,
              message: '최대 20자',
            },
          ]}
        >
          <Input
            disabled={readOnlyMode}
            onBlur={(e) => {
              const value = e.target.value;

              if (value.endsWith(' ')) {
                form.setFieldsValue({
                  countryProductName: value.trimEnd(),
                });
              }
            }}
          />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox>
            {ctaName === '보완 완료' && (
              <CorrectRequestWithoutChangeButton
                documentCode={DocumentCode.PN}
                productId={productId}
                countryId={countryId}
                onSubmit={() => {
                  onClose();
                }}
              />
            )}
            <Button type="primary" loading={isLoading} htmlType="submit">
              {ctaName}
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default JapaneseProductNameModal;
