import { Modal } from 'antd';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { SpecialRegionLawRegulation } from 'service/brand/formulaScreening/formulaScreening';
import Tags from 'components/ui/Tags';

const ModalHeader = styled(Flex)`
  padding: 16px 24px;
  border-bottom: 1px solid ${palette.GRAY20};
  height: 58px;
`;

const ModalBody = styled.div`
  padding: 24px 40px 32px;
  height: 502px;
  overflow-y: scroll;
`;

const Tab = styled.div<{ isActive: boolean; label: string }>`
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          font-weight: 500;
          cursor: default;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background-color: ${palette.ETC_WHITE};
          color: ${palette.SLATE_GRAY60};
          cursor: pointer;
        `};

  &::after {
    content: '${({ label }) => label}';
    font-weight: bold;
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const StyledTable = styled.div`
  margin-top: 24px;
  border-top: 2px solid ${palette.PRIMARY50};
`;

const HeaderItem = styled.div`
  padding: 12px 16px;
  background-color: ${palette.GRAY10};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  font-weight: 500;

  &:last-child {
    border-right: none;
  }
`;

const TableBody = styled.div``;

const BodyItem = styled(Flex)`
  align-items: center;
  padding: 12px 16px;
  background-color: ${palette.ETC_WHITE};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  white-space: pre-wrap;

  &:last-child {
    border-right: none;
  }
`;

const SpecialRegionProhibitInfosModal = ({
  defaultSpecialRegionId,
  specialRegionRegulations,
  formulaScreeningSpecialRegions,
  onClose,
}: {
  defaultSpecialRegionId?: number;
  specialRegionRegulations: SpecialRegionLawRegulation[];
  formulaScreeningSpecialRegions: {
    specialRegionId: number;
    specialRegionName: string;
  }[];
  onClose: () => void;
}) => {
  const [selectedSpecialRegionId, setSelectedSpecialRegionId] = useState(
    defaultSpecialRegionId ||
      specialRegionRegulations.map(
        (item) => item.specialRegion.specialRegionId,
      )[0],
  );
  const selectedSpecialRegionInfo = specialRegionRegulations.find(
    (item) =>
      item.specialRegion &&
      item.specialRegion.specialRegionId === selectedSpecialRegionId,
  )!;

  const getSpecialRegionNameById = (specialRegionId: number) => {
    return (
      formulaScreeningSpecialRegions.find(
        (item) => item.specialRegionId === Number(specialRegionId),
      )?.specialRegionName || '-'
    );
  };

  return (
    <Modal
      visible={true}
      width={896}
      closable={false}
      centered
      onCancel={onClose}
      footer={null}
      bodyStyle={{ padding: 0, height: 560 }}
    >
      <ModalHeader justify="space-between" align="center">
        <Typography.Text type="TITLE_2" semiBold>
          성분 규제사항
        </Typography.Text>
        <Icon name="close" size={24} onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex gap={8} wrap="true">
          {specialRegionRegulations
            .filter(({ specialRegion }) => specialRegion)
            ?.reduce(
              (acc, cur) => {
                if (
                  !acc.find(
                    (item) =>
                      item.specialRegion.specialRegionId ===
                      cur.specialRegion.specialRegionId,
                  )
                ) {
                  acc.push({
                    specialRegion: cur.specialRegion,
                  });
                }
                return acc;
              },
              [] as {
                specialRegion: {
                  logoUrl: string;
                  specialRegionId: number;
                  specialRegionName: string;
                  url: string | null;
                };
              }[],
            )
            .map(({ specialRegion }) => (
              <Tab
                label={getSpecialRegionNameById(
                  Number(specialRegion.specialRegionId),
                )}
                key={specialRegion.specialRegionId}
                isActive={
                  Number(specialRegion.specialRegionId) ===
                  selectedSpecialRegionId
                }
                onClick={() =>
                  Number(specialRegion.specialRegionId) !==
                    selectedSpecialRegionId &&
                  setSelectedSpecialRegionId(
                    Number(specialRegion.specialRegionId),
                  )
                }
              >
                {getSpecialRegionNameById(
                  Number(specialRegion.specialRegionId),
                )}
              </Tab>
            ))}
        </Flex>
        {selectedSpecialRegionInfo && (
          <StyledTable>
            <Flex>
              <HeaderItem style={{ flex: '0 0 160px' }}>규제명</HeaderItem>
              <HeaderItem style={{ flex: '0 0 280px' }}>고시원료명</HeaderItem>
              <HeaderItem style={{ flex: '0 0 376px' }}>제한사항</HeaderItem>
            </Flex>
            <TableBody>
              {selectedSpecialRegionInfo.ingredientLawRegulations &&
                selectedSpecialRegionInfo.ingredientLawRegulations.map(
                  (ingredientLawRegulation) => (
                    <Flex
                      align="stretch"
                      key={ingredientLawRegulation.ingredientLawRegulationId}
                    >
                      <BodyItem style={{ flex: '0 0 160px' }}>
                        {ingredientLawRegulation.law.name}
                      </BodyItem>
                      <BodyItem style={{ flex: '0 0 280px', fontWeight: 500 }}>
                        {
                          ingredientLawRegulation.regulation
                            ?.notifiedIngredientName
                        }
                      </BodyItem>
                      <BodyItem style={{ flex: '0 0 376px' }}>
                        <div>
                          <Tags.Mark
                            color={
                              ingredientLawRegulation.regulation?.type ===
                              'LIMIT'
                                ? 'green'
                                : 'red'
                            }
                            style={{ margin: '0 0 8px 0' }}
                          >
                            {ingredientLawRegulation.regulation?.type ===
                            'LIMIT'
                              ? '제한'
                              : '금지'}
                          </Tags.Mark>
                          {ingredientLawRegulation.regulation?.regulationsOfLanguage.find(
                            (item) => item.languageCode === 'KO',
                          )?.limitedMatter || ''}
                        </div>
                      </BodyItem>
                    </Flex>
                  ),
                )}
              {selectedSpecialRegionInfo.groupingLawRegulations &&
                selectedSpecialRegionInfo.groupingLawRegulations.map(
                  (groupingLawRegulation) => {
                    const isGroupLimit =
                      groupingLawRegulation.regulation?.type === 'LIMIT';

                    return (
                      <Flex align="stretch">
                        <BodyItem style={{ flex: '0 0 160px' }}>
                          {groupingLawRegulation.law.name}
                        </BodyItem>
                        <BodyItem
                          style={{
                            flex: '0 0 280px',
                            fontWeight: 500,
                          }}
                        >
                          {
                            groupingLawRegulation.regulation
                              ?.notifiedIngredientName
                          }
                        </BodyItem>
                        <BodyItem style={{ flex: '0 0 376px' }}>
                          <div>
                            <Tags.Mark
                              color={isGroupLimit ? 'green' : 'red'}
                              style={{ margin: '0 0 8px 0' }}
                            >
                              {isGroupLimit ? '제한' : '금지'}
                            </Tags.Mark>
                            {groupingLawRegulation.regulation?.regulationsOfLanguage.find(
                              (item) => item.languageCode === 'KO',
                            )?.limitedMatter || ''}
                          </div>
                        </BodyItem>
                      </Flex>
                    );
                  },
                )}
            </TableBody>
          </StyledTable>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SpecialRegionProhibitInfosModal;
