import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

import FileViewer from 'components/file/FileViewer';
import { useDocuments } from 'service/brand/retailer/retailerApply';
import { DocumentCode } from 'types/brand/retailer/retailerApply';
import { downloadFile, printFile } from 'lib/file';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  Button {
    height: 32px;
  }
`;

const BL = () => {
  const {
    documents: [businessLicense],
  } = useDocuments({ documentCode: DocumentCode.BL });
  if (!businessLicense) {
    return null;
  }
  const { filename, url } = businessLicense;

  return (
    <Container>
      <Row gutter={8} justify="end" style={{ marginBottom: 8 }}>
        <Col>
          <Button
            icon={
              <Icon
                name="download"
                size={14}
                color="PRIMARY50"
                style={{ marginRight: 4 }}
              />
            }
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => downloadFile(url, filename)}
          >
            다운로드
          </Button>
        </Col>
        <Col>
          <Button
            icon={
              <Icon
                name="print"
                size={14}
                color="PRIMARY50"
                style={{ marginRight: 4 }}
              />
            }
            onClick={() => printFile(url)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            인쇄하기
          </Button>
        </Col>
      </Row>
      <FileViewer title="" file={url} />
    </Container>
  );
};

export default BL;
