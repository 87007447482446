import { Button } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { s3AssetDomain } from 'lib/consts';
import palette from 'lib/styles/palette';
import history from 'lib/history';
import path from 'lib/path';

const Container = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  padding: 76px 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  width: 176px;
  height: 200px;
  border: 1px solid ${palette.GRAY40};
  border-radius: 8px;

  &:hover,
  &:active,
  &:focus {
    color: ${palette.GRAY90};
    box-shadow: 0px 2px 22px rgba(162, 162, 162, 0.23);
  }
`;

const QCQARegistration = () => {
  return (
    <Container>
      <img
        src={`${s3AssetDomain}/qc_registration.png`}
        alt="qc_registration"
        width={520}
        height={260}
      />
      <Typography.Text light type="HEADLINE_1" gutter={{ top: 40 }}>
        품질 서류 관리가 필요하신가요?
      </Typography.Text>
      <Typography.Text type="BODY_2" color="GRAY70" gutter={{ top: 16 }}>
        화장품책임판매업자의 품질 서류 관리 및 보관 의무에 따라 보관하셔야 하는
        서류들을 편리하게 관리해 보세요.
      </Typography.Text>
      <Flex gap={16} justify="center" style={{ marginTop: 56 }}>
        <StyledButton
          onClick={() => {
            history.push(path.qcqa.registration.product);
          }}
        >
          품질 제품 등록
        </StyledButton>
        <StyledButton
          onClick={() => {
            history.push(path.qcqa.registration.lot);
          }}
        >
          제조번호 등록
        </StyledButton>
      </Flex>
    </Container>
  );
};

export default QCQARegistration;
