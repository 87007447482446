import { MouseEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as TooltipArrow } from 'asset/svg/tooltip_arrow2.svg';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import Flex from './Flex/Flex';
import Icon from './Icon/Icon';

const Container = styled(Flex)<{
  visible: boolean;
}>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 12px;
  background: ${palette.SLATE_GRAY70};
  border-radius: 8px;
  white-space: nowrap;
  z-index: 1;
  cursor: default;
`;

Container.defaultProps = {
  justify: 'center',
};

// 해당 컴포넌트는 부모의 position을 relative로 바꿉니다.
const Tooltip2 = ({
  title = '',
  visible = false,
  onClose,
}: {
  title?: string;
  visible?: boolean;
  onClose?: VoidFunction;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClose?.();
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const parentEl = containerRef.current.parentElement;
    if (!parentEl) return;

    const originPosition = parentEl.style.position;

    if (visible) {
      parentEl.style.position = 'relative';
    }

    return () => {
      parentEl.style.position = originPosition;
    };
  }, [visible]);

  return (
    <Container ref={containerRef} visible={visible} gap={10}>
      <Typography.Text type="SMALL" color="ETC_WHITE">
        {title}
      </Typography.Text>
      <Icon
        name="close"
        color="ETC_WHITE"
        size={12}
        onClick={handleCloseClick}
      />
      <TooltipArrow
        style={{
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </Container>
  );
};

export default Tooltip2;
