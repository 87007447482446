import { Radio } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import TermsEditorViewer from 'components/terms/TermsEditorViewer';
import { useAllTerms } from 'service/terms';
import { TermsType } from 'types/terms';

const TermsRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-left: 8px;
    border: 1px solid #d9d9d9;
    border-color: #d9d9d9 !important;
    border-radius: 4px;
    padding: 10px 16px;
    color: #4e5968 !important;
    font-weight: 500;
    height: auto;
    line-height: normal;

    &:first-child {
      margin-left: 0;
      border-right-color: #d9d9d9 !important;
    }
    &::before {
      content: none;
    }
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: none;
    padding: 11px 17px;
    background-color: #f1f4f6 !important;

    &:focus-within {
      box-shadow: none;
    }
  }
`;

const PrivacyProcessTermsPage = () => {
  const [termsType, setTermsType] = useState<TermsType>(
    TermsType.PRIVACY_PROCESS_BRAND,
  );
  const { data: allTerms } = useAllTerms();

  const terms = useMemo(
    () => allTerms?.find((item) => item.termType === termsType),
    [allTerms, termsType],
  );

  return (
    <>
      <TermsRadioGroup
        style={{ marginBottom: 16 }}
        optionType="button"
        buttonStyle="solid"
        options={[
          { label: '브랜드사', value: TermsType.PRIVACY_PROCESS_BRAND },
          { label: '제조사', value: TermsType.PRIVACY_PROCESS_MANUFACTURER },
          { label: '원료사', value: TermsType.PRIVACY_PROCESS_MATERIAL },
        ]}
        value={termsType}
        onChange={(e) => setTermsType(e.target.value)}
      />
      <TermsEditorViewer
        content={terms ? terms.content : ''}
      ></TermsEditorViewer>
    </>
  );
};

export default PrivacyProcessTermsPage;
