import { Col, Row, List } from 'antd';
import styled, { css } from 'styled-components';
import { useMemo } from 'react';
import { nanoid } from 'nanoid';

import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import { downloadFile } from 'lib/file';
import Icon from 'components/ui/Icon/Icon';

const FileListBlock = styled.div``;

const StyledList = styled(List)<{ width?: number }>`
  border: none;
  .ant-list-header {
    padding: 0;
    border: none;
  }

  .ant-spin-container {
    border: 1px solid ${palette.GRAY40};
    border-radius: 4px;
    height: 106px;
    overflow: auto;
    padding: 0;

    .ant-list-empty-text {
      padding: 17px 0;

      .ant-empty.ant-empty-normal {
        margin: 0;
      }
    }
  }

  ${(props) =>
    props.width &&
    css`
      .ant-list-items {
        width: ${props.width}px;
      }
    `}
`;

const FileItem = styled(List.Item)<{ selected?: boolean }>`
  padding: 10px 14px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      background-color: #f0faff;

      p {
        color: #222;
      }
    `}
  @media (hover: hover) {
    &:hover {
      p {
        color: #222;
      }
    }
  }
`;

const FileList = ({
  selectedFile,
  files,
  header,
  onFileClick,
  onDelete,
  style,
  upload,
  width,
}: {
  selectedFile?: any;
  files?: any;
  header?: React.ReactNode;
  onFileClick: (file: any) => void;
  onDelete?: (file: any) => void;
  style?: React.CSSProperties;
  upload?: React.ReactNode;
  width?: number;
}) => {
  const realFiles = useMemo(() => {
    if (!files || typeof files === 'number') {
      return [];
    }
    return files instanceof Array ? files : [files];
  }, [files]);

  return (
    <FileListBlock style={style}>
      <StyledList
        header={
          header || (
            <Typography.Text type="BODY_2" gutter={{ bottom: 8 }}>
              업로드 된 파일 목록
            </Typography.Text>
          )
        }
        width={width}
        dataSource={realFiles}
        renderItem={(file: any) => {
          return (
            <FileItem
              key={nanoid()}
              onClick={() => onFileClick(file)}
              selected={file === selectedFile}
            >
              <Row
                justify="space-between"
                align="middle"
                gutter={8}
                wrap={false}
                style={{ width: 'calc(100% + 8px)' }}
              >
                <Col flex="1 1 auto" style={{ wordBreak: 'break-all' }}>
                  <Typography.Text type="BODY_2" color="GRAY70">
                    {file.name || file.filename}
                  </Typography.Text>
                </Col>
                {onDelete ? (
                  <Col flex="0 0 14px">
                    <Icon
                      name="delete"
                      size={16}
                      color="GRAY60"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(file);
                      }}
                    />
                  </Col>
                ) : (
                  <Col flex="0 0 14px">
                    <Icon
                      name="download"
                      size={16}
                      color="GRAY60"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (file instanceof File) {
                          downloadFile(file);
                        } else {
                          downloadFile(file.url, file.name);
                        }
                      }}
                    />
                  </Col>
                )}
              </Row>
            </FileItem>
          );
        }}
      />
      {upload && (
        <Typography.Text type="BODY_2" gutter={{ top: 16, bottom: 8 }}>
          파일 업로드
        </Typography.Text>
      )}
      {upload}
    </FileListBlock>
  );
};

export default FileList;
