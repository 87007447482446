import { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { downloadFile } from 'lib/file';
import * as certificateActions from 'modules/certificate';
import {
  useDownloadCompletionDocumentFiles,
  useProductArtworkList,
} from 'service/product';
import { useCurrentProduct } from 'service/brand/product/product';
import usePreviewModal from 'hook/usePreviewModal';
import CertificateDocuments from 'components/certificate/CertificateDocuments';
import {
  generateCertificateDocumentMapWithDocumentUrl,
  getPifDownloadFiles,
  downloadFilesAsZip,
  getFilenameFromUrl,
} from 'lib/file';
import { DocumentIndexCode } from 'types/product';

const CertificateDocumentsContainer = () => {
  const {
    params: { certTargetId },
  }: {
    params: {
      certTargetId: string;
    };
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { certificate, documents, getLoading } = useSelector(
    ({ certificate, loading }: any) => ({
      certificate: certificate.certificate,
      documents: certificate.completedDocuments,
      getLoading: loading['certificate/GET_COMPLETED_DOCUMENTS'],
    }),
    shallowEqual,
  );
  const [downloadFilesLoading, setDownloadFilesLoading] = useState(false);
  const [downloadPIFLoading, setDownloadPIFLoading] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const currentProduct = useCurrentProduct() || {};
  const { PreviewModal, setUrl } = usePreviewModal();
  const previewDocument = ({
    documentUrl,
    documentUrls,
    documentFilename,
  }: {
    documentUrl?: string;
    documentUrls?: string[];
    documentFilename?: string;
  }) => {
    setDocumentName(documentFilename || '');
    setUrl(documentUrls || documentUrl || '');
  };
  const {
    downloadCompletionDocumentFiles,
    isLoading,
  } = useDownloadCompletionDocumentFiles();
  const { getProductArtworkList } = useProductArtworkList();

  const getCertificateDocumentUrl = ({
    productId,
    countryId,
    documentCode,
  }: {
    productId: number;
    countryId: number;
    documentCode: DocumentIndexCode;
  }) => {
    let documentUrl = '';
    let documentName = '';
    downloadCompletionDocumentFiles(
      {
        productId,
        countryId,
        documentCode,
      },
      {
        onSuccess: async (res) => {
          const url = res?.data?.result?.documentUrl || '';
          documentUrl = url;
          documentName = await getFilenameFromUrl(url);
        },
      },
    );
    return { fileUrl: documentUrl, fileName: documentName };
  };

  const downloadDocuments = async () => {
    const {
      certRequestNo,
      estimateTarget: { productDetail, countryName },
    } = certificate;
    setDownloadFilesLoading(true);
    const { productId, countryId } = currentProduct.productDetail;

    const uploadFiles: { name: string; url: string }[] = [];
    const cdriFormFileQueue: {
      documentName: string;
      documentCode: string;
      uploadFileUrl?: string;
      uploadFileUrls?: string[];
    }[] = [];
    const downloadQueue = [...documents];

    for (const document of downloadQueue) {
      const {
        generatableDocumentReport,
        originalUploadFileName,
        uploadFileUrl,
        documentName,
        documentCode,
      } = document;

      if (!countryId) return;

      const { fileName, fileUrl } = getCertificateDocumentUrl({
        productId,
        countryId,
        documentCode,
      });

      if (generatableDocumentReport.canGenerate) {
        cdriFormFileQueue.push({
          documentName,
          documentCode,
          uploadFileUrl,
        });
      }

      if (uploadFileUrl || fileUrl) {
        uploadFiles.push({
          name: originalUploadFileName || fileName,
          url: fileUrl || uploadFileUrl,
        });
      }
    }

    getProductArtworkList(
      {
        productId,
        countryId,
      },
      {
        onSuccess: (res) => {
          const uploadFileUrls = res?.data?.result?.productArtworks;

          if (uploadFileUrls && uploadFileUrls.length > 0)
            uploadFileUrls.forEach(({ filename, uploadFileUrl }) => {
              uploadFiles.push({
                name: filename,
                url: uploadFileUrl,
              });
            });
        },
      },
    );

    const downloadDone = async () => {
      await downloadFilesAsZip(
        cdriFormFiles,
        `[인증서류]${certRequestNo}_${productDetail.productNameEn}_${countryName}.zip`,
      );
      await downloadFilesAsZip(
        uploadFiles,
        `[업로드파일]${certRequestNo}_${productDetail.productNameEn}_${countryName}.zip`,
      );
      setDownloadFilesLoading(false);
    };

    const getCdriFormFiles = () => {
      const cdriFormFiles: { name: string; url: string }[] = [];

      return new Promise<{ name: string; url: string }[]>((resolve) => {
        const startDownload = () => {
          const { documentCode, uploadFileUrl } = cdriFormFileQueue.pop() as {
            documentCode: string;
            uploadFileUrl?: string;
          };

          downloadCompletionDocumentFiles(
            {
              productId,
              countryId,
              documentCode,
            },
            {
              onSuccess: async (res) => {
                if (
                  !res &&
                  documentCode === DocumentIndexCode.PI &&
                  uploadFileUrl
                ) {
                  cdriFormFiles.push({
                    name: await getFilenameFromUrl(uploadFileUrl),
                    url: uploadFileUrl,
                  });
                  return;
                }
                const documentUrl = res?.data?.result?.documentUrl || '';

                cdriFormFiles.push({
                  name: await getFilenameFromUrl(documentUrl),
                  url: documentUrl,
                });

                if (cdriFormFileQueue.length > 0) {
                  startDownload();
                } else {
                  resolve(cdriFormFiles);
                }
              },
              onError: async (error) => {
                if (uploadFileUrl) {
                  cdriFormFiles.push({
                    name: await getFilenameFromUrl(uploadFileUrl),
                    url: uploadFileUrl,
                  });
                }

                if (cdriFormFileQueue.length > 0) {
                  startDownload();
                } else {
                  resolve(cdriFormFiles);
                }
              },
            },
          );
        };
        startDownload();
      });
    };

    const cdriFormFiles = await getCdriFormFiles();

    downloadDone();
  };
  const downloadPIFDocuments = async () => {
    const {
      productNameEn,
      productId,
      countryId,
    } = currentProduct.productDetail;
    const {
      estimateTarget: { netWeight, netWeightUnit },
    } = certificate;
    const handleLoad = async () => {
      if (!countryId) return;
      setDownloadPIFLoading(true);
      const certificateDocumentMap = await generateCertificateDocumentMapWithDocumentUrl(
        {
          productId,
          countryId,
          certificateDocuments: documents,
        },
      );

      const pifDownloadFiles = await getPifDownloadFiles(
        certificateDocumentMap,
      );
      return {
        files: [pifDownloadFiles],
        filenames: [`${productNameEn} (${netWeight} ${netWeightUnit}).zip`],
      };
    };
    const { files, filenames }: any = await handleLoad();

    await Promise.all(
      files.map((file: any, index: number) =>
        downloadFilesAsZip(file, filenames[index]),
      ),
    );
    setDownloadPIFLoading(false);
  };

  const handleDocumentPreview = ({
    type,
    documentCode,
    documentName,
  }: {
    type: 'download' | 'preview';
    documentCode: DocumentIndexCode;
    documentName: string;
  }) => {
    if (isLoading) return;
    const { productId, countryId } = currentProduct.productDetail;

    if (!countryId) return;

    if (documentCode === DocumentIndexCode.ART) {
      getProductArtworkList(
        {
          productId,
          countryId,
        },
        {
          onSuccess: (res) => {
            const uploadFileUrls = res.data.result.productArtworks?.map(
              (data) => data.uploadFileUrl,
            );

            previewDocument({
              documentUrls: uploadFileUrls,
            });
          },
        },
      );
    } else {
      downloadCompletionDocumentFiles(
        {
          documentCode,
          productId,
          countryId: countryId ?? undefined,
        },
        {
          onSuccess: (res) => {
            if (type === 'download') {
              downloadFile(res.data.result, documentName);
            } else {
              previewDocument(res.data.result);
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    dispatch(certificateActions.getCertificate(certTargetId));
  }, [certTargetId]);

  useEffect(() => {
    if (certificate) {
      dispatch(
        certificateActions.getCompletedDocuments({
          certTargetId,
          productId: certificate.estimateTarget.productId,
          countryId: certificate.estimateTarget.countryId,
        }),
      );
    }
  }, [certificate]);

  if (!certificate) return null;

  return (
    <>
      <PreviewModal documentName={documentName} />
      <CertificateDocuments
        certificate={certificate}
        documents={documents}
        getLoading={getLoading}
        downloadFilesLoading={downloadFilesLoading}
        downloadPIFLoading={downloadPIFLoading}
        onPreview={previewDocument}
        onDocumentPreview={handleDocumentPreview}
        onDownload={downloadDocuments}
        onPIFDownload={downloadPIFDocuments}
      />
    </>
  );
};

export default CertificateDocumentsContainer;
