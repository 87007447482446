import { Tabs, message } from 'antd';
import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import { messages, qcqaDocumentCodes } from 'lib/consts';
import { downloadFile } from 'lib/file';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import {
  useQCQAFormulaBreakdown,
  useQCQALotDocs,
  useUpdateQCQALotDocsHistory,
} from 'service/brand/qcqa/qcqa';
import { useUploadFiles } from 'service/common';
import {
  FileInfo,
  QCQALotAttach,
  QCQALotDocsListItem,
  QCQALotDocumentUseType,
} from 'types/brand/qcqa';

const NOT_CLOSE_TOOLTIP = 'not-close-tooltip';

const tabs = [
  {
    key: QCQALotDocumentUseType.LOT_NO,
    label: '제조번호 서류',
  },
  {
    key: QCQALotDocumentUseType.PRODUCT,
    label: '제품 서류',
  },
  {
    key: QCQALotDocumentUseType.COMPANY,
    label: '회사 서류',
  },
];

const Container = styled.div`
  .ant-tabs-nav {
    margin: 0;
    &::before {
      border: none;
    }

    .ant-tabs-ink-bar {
      background-color: ${palette.SLATE_GRAY70};
    }

    .ant-tabs-tab {
      margin: 0 24px 0 0;
      padding: 0 0 22px;
    }
  }
`;

const FileUploadCardList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
`;

const QCQALotRegisterDocs = ({
  qcqaProductId,
  qcqaProductLotnoId,
  qcqaLotDocsList,
  isGetQCQALotDocsLoading,
  onChangeQCQALotDocsList,
  updateMode,
  onRefetch,
  onChangeTempDataMap,
  companyManufacturerId,
}: {
  qcqaProductId: number;
  qcqaProductLotnoId?: number;
  qcqaLotDocsList: QCQALotDocsListItem[];
  isGetQCQALotDocsLoading: boolean;
  onChangeQCQALotDocsList: React.Dispatch<
    React.SetStateAction<QCQALotDocsListItem[]>
  >;
  onChangeTempDataMap: React.Dispatch<
    React.SetStateAction<
      Record<
        number,
        {
          attaches?: QCQALotAttach[];
          selected?: {
            qcqaProductLotnoRecordId: number;
            qcqaRelationDocumentRecordId: number;
          };
        }
      >
    >
  >;
  updateMode: boolean;
  onRefetch: VoidFunction;
  companyManufacturerId?: number;
}) => {
  const refs = useRef<HTMLDivElement[]>([]);
  const [activeKey, setActiveKey] = useState<QCQALotDocumentUseType>(
    QCQALotDocumentUseType.LOT_NO,
  );
  const [visibleTooltipId, setVisibleTooltipId] = useState<number>();
  const [visibleLoadModal, setVisibleLoadModal] = useState(false);
  const [uploadingDocumentsMap, setUploadingDocumentsMap] = useState<
    Record<number, boolean>
  >({});
  const [
    qcqaRelationDocumentRecordId,
    setQCQADocumentProductRecordId,
  ] = useState(0);

  const { openAlertModal } = useModal();
  const { uploadFilesAsync } = useUploadFiles();
  const { qcqaFormulaBreakdownItems } = useQCQAFormulaBreakdown({
    qcqaProductDetailId: qcqaProductId,
    qcqaRelationDocumentRecordId,
  });
  const { updateQCQALotDocsHistory } = useUpdateQCQALotDocsHistory({
    qcqaProductId,
    qcqaProductLotnoId,
  });
  const {
    addLotDocumentAttach,
    addLotDocumentAttachLoading,
    deleteLotDocumentAttach,
    deleteLotDocumentAttachLoading,
    deleteQCQALotDocsHistory,
  } = useQCQALotDocs({ qcqaProductId, qcqaProductLotnoId });

  const hasScreening =
    qcqaRelationDocumentRecordId !== 0 && !!qcqaFormulaBreakdownItems;

  const handleScroll = useCallback(() => {
    const productDocPosition =
      refs.current[1].getBoundingClientRect().top - 190;
    const lotDocPosition = refs.current[2].getBoundingClientRect().top - 190;

    if (productDocPosition <= 0 && lotDocPosition <= 0) {
      setActiveKey(QCQALotDocumentUseType.COMPANY);
    } else if (productDocPosition <= 0 && lotDocPosition >= 0) {
      setActiveKey(QCQALotDocumentUseType.PRODUCT);
    } else {
      setActiveKey(QCQALotDocumentUseType.LOT_NO);
    }
  }, []);

  const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout>();

  const handleChangeTab = (e: string) => {
    window.removeEventListener('scroll', handleScroll);
    const index = tabs.findIndex((tab) => tab.key === e);
    const element = refs.current[index];

    setActiveKey(e as QCQALotDocumentUseType);
    if (element) {
      window.scrollTo({
        // 스크롤 높이 + 요소 위치 - (내비게이션바 높이 80px + 탭 높이 115px - shadow 5px)
        top: element.getBoundingClientRect().top + window.scrollY - 190,
        behavior: 'smooth',
      });
    }

    clearTimeout(scrollTimeout);
    setScrollTimeout(undefined);

    const newScrollTimeout = setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
    }, 1000);

    setScrollTimeout(newScrollTimeout);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleFileUpload = (qcqaUserDocumentId: number) => async (
    fileList: FileList | File[],
  ) => {
    // 로딩중인지
    if (uploadingDocumentsMap[qcqaUserDocumentId]) {
      return;
    }

    setUploadingDocumentsMap((prevMap) => {
      prevMap[qcqaUserDocumentId] = true;

      return { ...prevMap };
    });

    const files: File[] = [];
    for (const file of fileList) {
      files.push(file);
    }
    const res = await uploadFilesAsync({
      files,
      uploadDestinationType: 'QCQA',
    });

    const changeQCQALotDocsList = () => {
      onChangeQCQALotDocsList((qcqaLotDocsList) => {
        const targetQCQALotDocument = qcqaLotDocsList.find(
          (item) => item.qcqaUserDocumentId === qcqaUserDocumentId,
        );

        targetQCQALotDocument!.attaches = targetQCQALotDocument?.attaches
          ? [...targetQCQALotDocument.attaches, ...res.data.result]
          : res.data.result;

        return [...qcqaLotDocsList];
      });
    };

    if (res.data.result.length === 0) {
      message.warn(messages.SHOULD_CHECK_FILE);
      // 수정
    } else if (updateMode) {
      await addLotDocumentAttach(
        {
          qcqaUserDocumentId,
          attachIds: res.data.result.map((item) => item.attachId),
        },
        {
          onSuccess: () => {
            message.success('정상적으로 업로드되었습니다.');
            changeQCQALotDocsList();
          },
        },
      );
    } else {
      onChangeTempDataMap((tempDataMap) => {
        const targetTempData = tempDataMap[qcqaUserDocumentId];
        tempDataMap[qcqaUserDocumentId] = {
          ...tempDataMap[qcqaUserDocumentId],
          attaches: targetTempData?.attaches
            ? [...targetTempData.attaches, ...res.data.result]
            : res.data.result,
        };
        return { ...tempDataMap };
      });
      changeQCQALotDocsList();
    }

    setUploadingDocumentsMap((prevMap) => {
      prevMap[qcqaUserDocumentId] = false;

      return { ...prevMap };
    });
  };

  const handleFileDelete = (qcqaUserDocumentId: number) => (
    fileInfo: QCQALotAttach,
  ) => {
    const changeQCQALotDocsList = () => {
      onChangeQCQALotDocsList((qcqaLotDocsList) => {
        const targetQCQALotDocument = qcqaLotDocsList.find(
          (item) => item.qcqaUserDocumentId === qcqaUserDocumentId,
        );

        if (targetQCQALotDocument && targetQCQALotDocument.attaches) {
          const targetIndex = targetQCQALotDocument!.attaches?.findIndex(
            ({ attachId }) => attachId === fileInfo.attachId,
          );

          targetQCQALotDocument.attaches.splice(targetIndex, 1);
        }
        return [...qcqaLotDocsList];
      });
    };

    // 등록
    if (!updateMode) {
      openAlertModal({
        content: `‘${fileInfo.filename}’ 파일을 삭제하시겠습니까?`,
        okText: '확인',
        closeText: '취소',
        onOk: () => {
          changeQCQALotDocsList();
          onChangeTempDataMap((tempDataMap) => {
            const targetTempDataMap = tempDataMap[qcqaUserDocumentId];

            if (targetTempDataMap.attaches) {
              const targetIndex = targetTempDataMap.attaches.findIndex(
                ({ attachId }) => attachId === fileInfo.attachId,
              );

              targetTempDataMap.attaches.splice(targetIndex, 1);
            }

            return { ...tempDataMap };
          });
          message.success('삭제되었습니다.');
        },
      });
      return;
    }

    // 수정
    openAlertModal({
      content: `‘${fileInfo.filename}’ 파일을 삭제하시겠습니까?`,
      okText: '확인',
      closeText: '취소',
      onOk: () => {
        if (!fileInfo.qcqaProductLotnoAttachId) {
          message.warn('새로고침 후 다시 이용해주세요.');
          console.error('fileInfo does not have qcqaProductLotnoAttachId');
          return;
        }

        deleteLotDocumentAttach(
          {
            qcqaProductLotnoAttachId: fileInfo.qcqaProductLotnoAttachId,
          },
          {
            onSuccess: () => {
              message.success('삭제되었습니다.');
              changeQCQALotDocsList();
            },
          },
        );
      },
    });
  };

  const handleCompleteHistoryModal = ({
    qcqaUserDocumentId,
    useType,
  }: {
    qcqaUserDocumentId: number;
    useType: QCQALotDocumentUseType;
  }) => (qcqaDocumentRecordId: number) => {
    const targetQCQALotDocument = qcqaLotDocsList.find(
      (item) => item.qcqaUserDocumentId === qcqaUserDocumentId,
    );

    const changeQCQALotDocsList = () => {
      targetQCQALotDocument!.selected = {
        qcqaProductLotnoRecordId: qcqaDocumentRecordId,
        qcqaRelationDocumentRecordId: qcqaDocumentRecordId,
      };

      onChangeQCQALotDocsList([...qcqaLotDocsList]);
    };

    // 등록
    if (!updateMode) {
      changeQCQALotDocsList();
      onChangeTempDataMap((tempDataMap) => {
        tempDataMap[qcqaUserDocumentId] = {
          ...tempDataMap[qcqaUserDocumentId],
          selected: {
            qcqaProductLotnoRecordId: qcqaDocumentRecordId,
            qcqaRelationDocumentRecordId: qcqaDocumentRecordId,
          },
        };
        return tempDataMap;
      });
      // 수정
    } else if (qcqaDocumentRecordId !== 0) {
      updateQCQALotDocsHistory(
        {
          qcqaUserDocumentId,
          relationType: useType,
          qcqaRelationDocumentRecordId: qcqaDocumentRecordId,
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            onRefetch();
          },
        },
      );
      // 삭제
    } else {
      const qcqaProductLotnoRecordId =
        targetQCQALotDocument?.selected?.qcqaProductLotnoRecordId;

      if (qcqaProductLotnoRecordId) {
        deleteQCQALotDocsHistory(
          { qcqaProductLotnoRecordId },
          {
            onSuccess: () => {
              message.success('수정되었습니다.');
              changeQCQALotDocsList();
            },
          },
        );
      }
    }
  };

  useLayoutEffect(() => {
    const layoutElement: HTMLElement | null = document.querySelector(
      'section.ant-layout',
    );

    if (layoutElement) {
      layoutElement.style.overflow = 'visible';
    }
  }, []);

  useEffect(() => {
    const qcqaRelationDocumentRecordId =
      qcqaLotDocsList.find(
        ({ code }) => code === qcqaDocumentCodes.FORMULA_BREAKDOWN_CODE,
      )?.selected?.qcqaRelationDocumentRecordId || 0;

    setQCQADocumentProductRecordId(qcqaRelationDocumentRecordId);
  }, [qcqaLotDocsList]);

  useEffect(() => {
    if (visibleLoadModal) {
      openAlertModal({
        content: `추가된 내용을 불러오시겠습니까?\n등록중인 정보는 저장됩니다.`,
        okText: '불러오기',
        onCustomClose: () => {
          setVisibleLoadModal(false);
        },
        onOk: () => {
          onRefetch();
          setVisibleLoadModal(false);
        },
      });
    }
  }, [visibleLoadModal]);

  return (
    <Container>
      <DocumentTabs
        tabs={tabs}
        activeKey={activeKey}
        onActiveKeyChange={handleChangeTab}
      />
      {isGetQCQALotDocsLoading ? (
        <Flex
          justify="center"
          align="center"
          style={{ width: '100%', marginTop: 80 }}
        >
          <LoadingIndicator size="sm" />
        </Flex>
      ) : (
        tabs.map(({ label, key }, index) => (
          <div
            key={key}
            ref={(ref) => {
              if (ref) {
                refs.current[index] = ref;
              }
            }}
          >
            <Typography.Text type="TITLE_1" gutter={{ top: 40 }}>
              {label}
            </Typography.Text>
            <FileUploadCardList>
              {qcqaLotDocsList
                .filter(({ useType }) => useType === key)
                .map((doc) => (
                  <FileUploadCard
                    key={doc.qcqaUserDocumentId}
                    qcqaProductId={qcqaProductId}
                    preventFileHandling={
                      addLotDocumentAttachLoading ||
                      deleteLotDocumentAttachLoading
                    }
                    document={doc}
                    onUpload={handleFileUpload(doc.qcqaUserDocumentId)}
                    onDelete={handleFileDelete(doc.qcqaUserDocumentId)}
                    loading={uploadingDocumentsMap[doc.qcqaUserDocumentId]}
                    visibleTooltipId={visibleTooltipId}
                    onChangeVisibleTooltipId={setVisibleTooltipId}
                    onChangeVisibleLoadModal={setVisibleLoadModal}
                    hasScreening={hasScreening}
                    onCompleteHistoryModal={handleCompleteHistoryModal({
                      qcqaUserDocumentId: doc.qcqaUserDocumentId,
                      useType: doc.useType,
                    })}
                    companyManufacturerId={companyManufacturerId}
                  />
                ))}
            </FileUploadCardList>
          </div>
        ))
      )}
    </Container>
  );
};

const TabsWrapper = styled.div`
  position: sticky;
  top: 80px;
  padding-top: 32px;
  background-color: white;
  z-index: 999;

  .ant-tabs-nav {
    z-index: 999;
  }
`;

const TabsBottomDivider = styled.div`
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  width: 99vw;
  height: 75px;
  background: white;
  border-bottom: 1px solid #f0f0f0;
`;

const DocumentTabs = ({
  activeKey,
  onActiveKeyChange,
  tabs,
}: {
  activeKey: string;
  onActiveKeyChange: (key: string) => void;
  tabs: {
    key: string;
    label: string;
  }[];
}) => {
  return (
    <TabsWrapper>
      <div style={{ position: 'relative' }}>
        <Tabs activeKey={activeKey} onChange={onActiveKeyChange}>
          {tabs.map((tab) => (
            <Tabs.TabPane
              key={tab.key}
              tab={
                <TabLabel disabled={activeKey !== tab.key} label={tab.label} />
              }
            />
          ))}
        </Tabs>
        <TabsBottomDivider />
      </div>
    </TabsWrapper>
  );
};

const TabLabel = ({
  disabled,
  label,
}: {
  disabled: boolean;
  label: string;
}) => {
  return (
    <Flex columnGap={4}>
      <Typography.Text
        type="BODY_2"
        medium
        color={disabled ? 'SLATE_GRAY50' : 'SLATE_GRAY70'}
      >
        {label}
      </Typography.Text>
    </Flex>
  );
};

const FileUploadCardContainer = styled.div<{
  dragging: boolean;
}>`
  position: relative;
  padding: 14px 30px;
  background: ${palette.ETC_WHITE};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border: 2px dashed transparent;
  border-radius: 8px;
  transition: all 300ms ease;

  ${({ dragging }) =>
    dragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
    background-color: ${palette.PRIMARY10};
  `}
`;

const FileUploadCardHead = styled(Flex)``;

const ScreeningButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-left: 7px;
  border-radius: 4px;
  border: 1px solid ${palette.SLATE_GRAY40};
  cursor: pointer;
`;

const FileUploadGuide = styled.div`
  background-color: ${palette.PRIMARY20};
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 10px;
`;

const FileUploadCardBody = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const FileUploadTooltip = styled(Flex)`
  position: absolute;
  /* HINT: border 2px만큼 빼주기 위해 */
  top: -2px;
  right: 0;
  transform: translateX(calc(100% + 16px));
  padding: 10px 16px;
  background: ${palette.SLATE_GRAY70};
  border-radius: 8px;
`;

const DocsHistory = styled(Flex)`
  border: 1px solid ${palette.SLATE_GRAY40};
  border-radius: 4px;
  height: 24px;
  padding: 2px 4px;
`;

const FileUploadCard = ({
  qcqaProductId,
  preventFileHandling,
  document,
  onUpload,
  onDelete,
  loading,
  visibleTooltipId,
  onChangeVisibleTooltipId,
  onChangeVisibleLoadModal,
  hasScreening,
  onCompleteHistoryModal,
  companyManufacturerId,
}: {
  qcqaProductId: number;
  preventFileHandling: boolean;
  document: QCQALotDocsListItem;
  onUpload: (filesList: FileList | File[], isScreening?: boolean) => void;
  onDelete: (fileInfo: QCQALotAttach) => void;
  loading: boolean;
  visibleTooltipId?: number;
  onChangeVisibleTooltipId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  onChangeVisibleLoadModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasScreening: boolean;
  onCompleteHistoryModal: (qcqaDocumentRecordId: number) => void;
  companyManufacturerId?: number;
}) => {
  const {
    records,
    useType,
    attaches,
    qcqaUserDocumentId,
    selected,
    code,
  } = document;
  const isSelectedNone =
    (records &&
      records.length > 0 &&
      (!selected ||
        !records.find(
          ({ qcqaDocumentRecordId }) =>
            qcqaDocumentRecordId === selected.qcqaRelationDocumentRecordId,
        ))) ||
    selected?.qcqaRelationDocumentRecordId === 0;

  const selectedRecord = isSelectedNone
    ? 'None'
    : selected
    ? records?.find(
        ({ qcqaDocumentRecordId }) =>
          qcqaDocumentRecordId === selected.qcqaRelationDocumentRecordId,
      )
    : undefined;
  const fileRef = useRef<HTMLInputElement>(null);
  const [hoverCount, setHoverCount] = useState(0);
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>(
    'none',
  );

  const {
    openQCQAFormulaBreakdownModal,
    openQCQADocsHistoryModal,
  } = useModal();

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if (
      [...e.dataTransfer.files].filter((file) => file.type === '').length > 0
    ) {
      message.warn('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    onUpload(e.dataTransfer.files);
  };

  const handleScreeningClick = () => {
    if (preventFileHandling) return;

    openQCQAFormulaBreakdownModal({
      qcqaProductDetailId: qcqaProductId,
      onRegister: () => {},
      qcqaRelationDocumentRecordId: selected?.qcqaRelationDocumentRecordId || 0,
      readOnly: true,
    });
  };

  const referToFile = () => fileRef.current?.click();

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;
    onUpload(e.target.files);
    e.target.value = '';
  };

  const handleFileDelete = (fileInfo: QCQALotAttach) => () =>
    onDelete(fileInfo);

  const getTooltipText = () => {
    switch (useType) {
      case QCQALotDocumentUseType.COMPANY:
        return (
          <Typography.Text type="BODY_2" color="ETC_WHITE">
            회사 서류는 마이페이지에서
            <br />
            파일을 추가하실 수 있습니다.
          </Typography.Text>
        );
      case QCQALotDocumentUseType.PRODUCT:
        return (
          <Typography.Text type="BODY_2" color="ETC_WHITE">
            제품 서류는 품질 제품 관리에서
            <br />
            파일을 추가하실 수 있습니다.
          </Typography.Text>
        );
      case QCQALotDocumentUseType.LOT_NO:
        return (
          <Typography.Text type="BODY_2" color="ETC_WHITE">
            제조번호 서류는 제조사 관리에서
            <br />
            파일을 추가하실 수 있습니다.
          </Typography.Text>
        );
    }
  };

  return (
    <FileUploadCardContainer
      dragging={fileDragState === 'dragging'}
      onDragEnter={
        preventFileHandling || useType !== QCQALotDocumentUseType.LOT_NO
          ? undefined
          : handleFileDragEnter
      }
      onDragLeave={handleFileDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={
        preventFileHandling || useType !== QCQALotDocumentUseType.LOT_NO
          ? undefined
          : handleFileDragUpload
      }
    >
      {visibleTooltipId === qcqaUserDocumentId && (
        <FileUploadTooltip gap={12} className={NOT_CLOSE_TOOLTIP}>
          {getTooltipText()}
          <Typography.Text
            type="BODY_2"
            medium
            color="MESSAGE_WARNING"
            onClick={() => {
              onChangeVisibleLoadModal(true);
              onChangeVisibleTooltipId(undefined);
              const targetPath =
                useType === QCQALotDocumentUseType.COMPANY
                  ? `${path.my}/docs`
                  : useType === QCQALotDocumentUseType.LOT_NO
                  ? `${path.my}/manufacturer`
                  : `${path.qcqa.management.root}/${qcqaProductId}`;

              const url = new URL(targetPath, window.location.origin);

              switch (useType) {
                case QCQALotDocumentUseType.LOT_NO:
                  if (companyManufacturerId) {
                    url.searchParams.set(
                      'manufacturerId',
                      String(companyManufacturerId),
                    );
                  }
                  break;
                case QCQALotDocumentUseType.COMPANY:
                  url.searchParams.set(
                    'qcqaUserDocumentId',
                    String(qcqaUserDocumentId),
                  );
                  break;
                case QCQALotDocumentUseType.PRODUCT:
                  url.searchParams.set('tab', 'document');
                  url.searchParams.set(
                    'qcqaUserDocumentId',
                    String(qcqaUserDocumentId),
                  );
                  break;
              }
              window.open(url.toString(), '_blank');
            }}
          >
            추가하기
          </Typography.Text>
        </FileUploadTooltip>
      )}
      <FileUploadCardHead align="center" justify="space-between">
        <Flex align="center">
          <Typography.Text color="SLATE_GRAY70" bold>
            {document.name}
          </Typography.Text>
          {document.description && (
            <Tip trigger="click" iconStyle={{ marginLeft: 8 }}>
              {document.description}
            </Tip>
          )}
          {document.isForCertification && (
            <Tags.Chip color="YELLOW_20" style={{ marginLeft: 16 }}>
              인증 활용
            </Tags.Chip>
          )}
        </Flex>
        <Flex align="center" columnGap={16}>
          <Flex gap={8} align="center">
            {
              // 전성분표
              document.code === qcqaDocumentCodes.FORMULA_BREAKDOWN_CODE &&
                hasScreening && (
                  <ScreeningButton role="button" onClick={handleScreeningClick}>
                    <Icon name="screening" size={18} />
                    <Typography.Text type="SMALL" bold>
                      스크리닝
                    </Typography.Text>
                  </ScreeningButton>
                )
            }
            {useType !== QCQALotDocumentUseType.LOT_NO && (
              <DocsHistory
                align="center"
                style={{
                  cursor: selectedRecord ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (selectedRecord) {
                    openQCQADocsHistoryModal({
                      initialQCQADocumentRecordId:
                        selected?.qcqaRelationDocumentRecordId || 0,
                      records,
                      onComplete: onCompleteHistoryModal,
                    });
                  }
                }}
              >
                <Icon
                  name="refresh"
                  size={16}
                  color={selectedRecord ? 'PRIMARY50' : 'GRAY60'}
                  style={{ marginRight: 4 }}
                />
                {selectedRecord ? (
                  <>
                    {selectedRecord !== 'None' && (
                      <Typography.Text
                        type="SMALL"
                        color="SLATE_GRAY60"
                        inline
                        bold
                      >
                        {selectedRecord.recordDate}
                      </Typography.Text>
                    )}
                    <Typography.Text
                      type="SMALL"
                      color="SLATE_GRAY70"
                      gutter={{ left: selectedRecord === 'None' ? 0 : 4 }}
                      inline
                      bold
                    >
                      {selectedRecord === 'None'
                        ? '선택 안함'
                        : selectedRecord.name}
                    </Typography.Text>
                  </>
                ) : (
                  <Typography.Text
                    type="SMALL"
                    color="SLATE_GRAY50"
                    inline
                    bold
                  >
                    이력 없음
                  </Typography.Text>
                )}
              </DocsHistory>
            )}
          </Flex>
          <Flex
            role="button"
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (
                useType !== QCQALotDocumentUseType.LOT_NO ||
                code === qcqaDocumentCodes.BUSINESS_REGISTERS_CODE ||
                code === qcqaDocumentCodes.COSMETIC_REGISTERS_CODE
              ) {
                onChangeVisibleTooltipId(
                  visibleTooltipId === qcqaUserDocumentId
                    ? undefined
                    : qcqaUserDocumentId,
                );
              } else if (!preventFileHandling) {
                referToFile();
              }
            }}
            className={NOT_CLOSE_TOOLTIP}
          >
            <Icon name="plus" size={16} color="SLATE_GRAY70" />
            <Typography.Text type="BODY_2" color="SLATE_GRAY70">
              파일 추가
            </Typography.Text>
            <input
              type="file"
              ref={fileRef}
              multiple={true}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
          </Flex>
        </Flex>
      </FileUploadCardHead>
      {
        // 전성분표
        document.code === qcqaDocumentCodes.FORMULA_BREAKDOWN_CODE && (
          <FileUploadGuide>
            <Typography.Text type="SMALL" color="SLATE_GRAY70" medium>
              전성분표를 스크리닝하여 등록하면 해외 인증 서비스 진행시 간편하게
              사용할 수 있습니다.
            </Typography.Text>
          </FileUploadGuide>
        )
      }
      {loading && (
        <Flex justify="center" align="center" dir="column" rowGap={4}>
          <LoadingIndicator size="sm" />
          <Typography.Text type="SMALL" color="SLATE_GRAY70" bold>
            파일을 업로드 하는 중...
          </Typography.Text>
        </Flex>
      )}
      {selectedRecord &&
        selectedRecord !== 'None' &&
        selectedRecord.attaches &&
        selectedRecord.attaches.length > 0 && (
          <FileUploadCardBody>
            {selectedRecord.attaches.map((fileInfo, fileIdx) => (
              <FileUploadItem
                key={fileIdx}
                fileInfo={fileInfo}
                useType={useType}
                code={code}
              />
            ))}
          </FileUploadCardBody>
        )}
      {attaches && attaches.length > 0 && (
        <FileUploadCardBody>
          {attaches?.map((fileInfo, fileIdx) => (
            <FileUploadItem
              key={fileIdx}
              fileInfo={fileInfo}
              useType={useType}
              onDelete={
                preventFileHandling ? undefined : handleFileDelete(fileInfo)
              }
              code={code}
            />
          ))}
        </FileUploadCardBody>
      )}
    </FileUploadCardContainer>
  );
};

const FileUploadItemWrapper = styled(Flex)`
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
  padding: 8px 16px;
`;

const FileUploadItem = ({
  fileInfo,
  useType,
  onDelete,
  code,
}: {
  fileInfo: FileInfo;
  useType: QCQALotDocumentUseType;
  onDelete?: () => void;
  code: string;
}) => {
  const { filename, registerDt, uploadFileUrl } = fileInfo;

  return (
    <FileUploadItemWrapper justify="space-between" align="center">
      <Typography.Text type="SMALL" color="SLATE_GRAY70" medium>
        {fileInfo.filename}
      </Typography.Text>
      <Flex columnGap={24} align="center">
        <Typography.Text type="SMALL" color="GRAY50" medium>
          {registerDt.slice(0, 10)}
        </Typography.Text>
        <Flex
          align="center"
          justify="center"
          style={{ width: 18, cursor: 'pointer' }}
          onClick={() => {
            downloadFile(uploadFileUrl, filename).then(() => {
              message.success('정상적으로 다운로드되었습니다.');
            });
          }}
        >
          <Icon name="download" size={16} color="GRAY60" />
        </Flex>
        {useType === QCQALotDocumentUseType.LOT_NO &&
          code !== qcqaDocumentCodes.BUSINESS_REGISTERS_CODE &&
          code !== qcqaDocumentCodes.COSMETIC_REGISTERS_CODE && (
            <Flex
              align="center"
              style={{ height: 18, cursor: 'pointer' }}
              onClick={onDelete}
            >
              <Icon name="delete" size={18} color="GRAY60" />
            </Flex>
          )}
      </Flex>
    </FileUploadItemWrapper>
  );
};

export default QCQALotRegisterDocs;
