import { useQuery } from 'react-query';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { Terms } from 'types/terms';

export const useAllTerms = () =>
  useQuery(
    ['terms/getAllTerms'],
    () => client.get<APIResponse<Terms[]>>(`/pub/service-termses`),
    { select: (res) => res.data.result },
  );
