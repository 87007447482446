import { memo } from 'react';
import { Form, Input } from 'antd';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import styled from 'styled-components';
import AlphabetLabel from './AlphabetLabel';

const MSDSExposureControlBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSExposureControl = () => {
  return (
    <MSDSExposureControlBlock>
      <Form.Item
        label={
          <AlphabetLabel alphabet="A" style={{ width: 538 }}>
            Control parameters
          </AlphabetLabel>
        }
        name={['exposureControl', 'controlParameters']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="화학물질의 노출기준, 생물학적 노출기준 등"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">
            Appropriate engineering controls
          </AlphabetLabel>
        }
        name={['exposureControl', 'appropriateEngineeringControls']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="적절한 공학적 관리"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="C">
            Personal protective equipment
          </AlphabetLabel>
        }
        name={['exposureControl', 'personalProtectiveEquipment']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="개인 보호구"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Respiratory protection"
        name={['exposureControl', 'respiratoryProtection']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="호흡기 보호"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Hand protection"
        name={['exposureControl', 'handProtection']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="손 보호"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Eye protection"
        name={['exposureControl', 'eyeProtection']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="눈 보호"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Skin and body protection"
        name={['exposureControl', 'skinAndBodyProtection']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="신체 보호"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Hygiene measures"
        name={['exposureControl', 'hygieneMeasures']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="No special measures required."
      >
        <Input.TextArea
          placeholder="위생 대책"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSExposureControlBlock>
  );
};

export default memo(MSDSExposureControl);
