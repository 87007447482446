import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import client from 'lib/api/client';
import {
  apiUrls,
  useBasicMutation,
  useBasicQuery,
  usePageQuery,
} from 'service/core';
import { CompanyDocsList } from 'types/brand/company';
import {
  QCQACompanyDocsHistory,
  QCQACreateLot,
  QCQACreateProduct,
  QCQALotBasic,
  QCQALotDocsHistoryUpdate,
  QCQALotDocsListItem,
  QCQALotItem,
  QCQALotListItem,
  QCQAProduct,
  QCQAProductListItem,
  QCQAProductOutputItem,
  QCQAUpdateProduct,
} from 'types/brand/qcqa';
import { APIResponse } from 'types/common';
import {
  ProductFormulaBreakdownDraft,
  ProductFormulaBreakdownItem,
} from 'types/product';

// 품질 제품 관리 조회
export const useQCQAProductList = ({
  page,
  productName,
  brandName,
}: {
  page?: number;
  size?: number;
  productName?: string;
  brandName?: string;
}) => {
  const {
    data: qcqaProductList,
    totalElements,
    isLoading: isQCQAProductListLoading,
  } = usePageQuery<QCQAProductListItem>({
    apiUrl: apiUrls.qcqaProductList,
    qs: {
      ...(page && { page }),
      size: 10,
      ...(productName && { productName }),
      ...(brandName && { brandName }),
    },
  });

  const {
    data: qcqaAllProductList,
    isLoading: isQCQAAllProductListLoading,
  } = useQuery(
    [apiUrls.qcqaProductList],
    () =>
      client.get<APIResponse<QCQAProductListItem[]>>(apiUrls.qcqaProductList),
    {
      select: (res) => res.data.result,
    },
  );

  // 품질 제품 삭제
  const {
    mutate: deleteQCQAProduct,
    isLoading: isDeleteQCQAProductLoading,
  } = useBasicMutation<number[]>({
    apiUrl: apiUrls.deleteQcqaProduct,
    method: 'delete',
    refetchUrl: apiUrls.qcqaProductList,
  });

  return {
    qcqaProductList,
    totalElements,
    isQCQAProductListLoading,
    qcqaAllProductList,
    isQCQAAllProductListLoading,
    deleteQCQAProduct,
    isDeleteQCQAProductLoading,
  };
};

export const useGetProductsWithDocsHistory = () => {
  const { mutate: getProductsWithDocsHistory } = useBasicMutation<
    {
      qcqaUserDocumentId: number;
      qcqaRelationDocumentRecordId: number;
    },
    {
      qcqaProductId: number;
      productNameKo: string;
      productNameEn: string;
      registerDt: string;
      updateDt: string | null;
      lotNos: {
        qcqaProductLotnoId: number;
        lotNo: string;
      }[];
    }[]
  >({
    apiUrl: apiUrls.qcqaProductsWithDocsHistory,
    method: 'get',
    paramNamesForUrl: ['qcqaUserDocumentId', 'qcqaRelationDocumentRecordId'],
  });

  return {
    getProductsWithDocsHistory,
  };
};

// 제조번호 관리 조회
export const useQCQALotList = ({
  page,
  qcqaProductId,
  productYear,
}: {
  page: number;
  qcqaProductId: number;
  productYear: number;
}) => {
  const [emptyArray] = useState([]);

  const {
    data: qcqaLotListItems,
    totalElements,
    isLoading: isQCQALotListLoading,
  } = usePageQuery<QCQALotListItem>({
    apiUrl: apiUrls.qcqaLotList,
    qs: {
      size: 10,
      productYear,
      page,
    },
    urlParams: { qcqaProductId },
  });

  // 연도별 제조번호 개수 조회
  const {
    data: qcqaYearlyLotCount = emptyArray,
    isLoading: isQCQAYearlyLotCountLoading,
  } = useBasicQuery<{ year: string; count: number }[]>({
    apiUrl: apiUrls.qcqaYearlyLotCount,
    urlParams: {
      qcqaProductId,
    },
  });

  // 제조번호 삭제
  const {
    mutate: deleteQCQALot,
    isLoading: isDeleteQCQALotLoading,
  } = useBasicMutation<number[]>({
    apiUrl: apiUrls.qcqaLotList,
    method: 'delete',
    refetchUrl: apiUrls.qcqaLotList,
    urlParams: {
      qcqaProductId,
    },
  });

  // 특정 제조 번호 서류 리스트 조회
  const { mutateAsync: getQCQALotDocsListAsync } = useBasicMutation<
    { qcqaProductLotnoId: number },
    QCQALotDocsListItem[]
  >({
    apiUrl: apiUrls.qcqaLotDocumentList,
    method: 'get',
    urlParams: {
      qcqaProductId,
    },
  });

  return {
    qcqaLotListItems,
    totalElements,
    isQCQALotListLoading,
    deleteQCQALot,
    isDeleteQCQALotLoading,
    qcqaYearlyLotCount,
    isQCQAYearlyLotCountLoading,
    getQCQALotDocsListAsync,
  };
};

// 품질 제품 등록
export const useQCQACreateProduct = () => {
  const {
    mutate: createQCQAProduct,
    isLoading: isCreateQCQAProductLoading,
  } = useBasicMutation<
    QCQACreateProduct,
    {
      brandName: string;
      productNameEn: string;
      productNameKo: string;
      qcqaProductId: number;
    }
  >({
    apiUrl: apiUrls.createQcqaProduct,
    method: 'post',
    useFormData: true,
  });

  return {
    createQCQAProduct,
    isCreateQCQAProductLoading,
  };
};

export const useQCQALot = ({
  qcqaProductId,
  qcqaProductLotnoId,
}: {
  qcqaProductId?: number;
  qcqaProductLotnoId?: number;
}) => {
  const [emptyArray] = useState([]);

  // 품질 제품 전체 리스트 조회
  const {
    data: qcqaProductList = emptyArray,
    isLoading: isQCQAProductListLoading,
  } = useBasicQuery<QCQAProductListItem[]>({
    apiUrl: apiUrls.qcqaProductList,
  });

  // 제조번호 서류 리스트 조회
  const {
    data: qcqaLotDocsList = emptyArray,
    isLoading: isGetQCQALotDocsLoading,
    refetch: refetchQCQALotDocsList,
  } = useBasicQuery<QCQALotDocsListItem[]>({
    apiUrl: apiUrls.qcqaLotDocumentList,
    ...(qcqaProductId && { urlParams: { qcqaProductId } }),
    ...(qcqaProductLotnoId && {
      qs: {
        qcqaProductLotnoId,
      },
    }),
    option: {
      enabled: qcqaProductId !== undefined,
    },
  });

  // 제조번호 등록
  const {
    mutate: createQCQALot,
    isLoading: isCreateQCQALotLoading,
  } = useBasicMutation<QCQACreateLot>({
    apiUrl: apiUrls.createQCQALot,
    method: 'post',
    paramNamesForUrl: ['qcqaProductId'],
  });

  // 제조번호 기본정보 수정
  const {
    mutate: updateQCQALotBasic,
    isLoading: isUpdateQCQALotBasicLoading,
  } = useBasicMutation<Partial<QCQACreateLot>>({
    apiUrl: apiUrls.qcqaLotBasic,
    ...(qcqaProductId &&
      qcqaProductLotnoId && {
        urlParams: { qcqaProductId, qcqaProductLotnoId },
      }),
    method: 'patch',
  });

  return {
    qcqaProductList,
    isQCQAProductListLoading,
    qcqaLotDocsList,
    isGetQCQALotDocsLoading,
    refetchQCQALotDocsList,
    createQCQALot,
    isCreateQCQALotLoading,
    updateQCQALotBasic,
    isUpdateQCQALotBasicLoading,
  };
};

export const useQCQALotBasic = ({
  qcqaProductId,
  qcqaProductLotnoId,
}: {
  qcqaProductId?: number;
  qcqaProductLotnoId?: number;
}) => {
  // 제조번호 기본정보 조회
  const { data: qcqaLotBasic } = useBasicQuery<QCQALotBasic>({
    apiUrl: apiUrls.qcqaLotBasic,
    ...(qcqaProductId &&
      qcqaProductLotnoId && {
        urlParams: { qcqaProductId, qcqaProductLotnoId },
      }),
    option: {
      enabled: qcqaProductId !== undefined && qcqaProductLotnoId !== undefined,
    },
  });

  return {
    qcqaLotBasic,
  };
};

export const useUpdateQCQALotDocsHistory = ({
  qcqaProductId,
  qcqaProductLotnoId,
}: {
  qcqaProductId?: number;
  qcqaProductLotnoId?: number;
}) => {
  // 제조 번호 개정이력 수정
  const {
    mutate: updateQCQALotDocsHistory,
    isLoading: updateQCQALotDocsHistoryLoading,
  } = useBasicMutation<QCQALotDocsHistoryUpdate>({
    apiUrl: apiUrls.updateQCQALotDocsHistory,
    method: 'put',
    ...(qcqaProductId &&
      qcqaProductLotnoId && {
        urlParams: {
          qcqaProductId,
          qcqaProductLotnoId,
        },
      }),
  });

  return { updateQCQALotDocsHistory, updateQCQALotDocsHistoryLoading };
};

export const useQCQALotDocs = ({
  qcqaProductId,
  qcqaProductLotnoId,
}: {
  qcqaProductId: number;
  qcqaProductLotnoId?: number;
}) => {
  // 회사 서류 개정이력 조회
  const {
    mutate: getQCQACompanyDocsHistory,
    isLoading: getQCQACompanyDocsHistoryLoading,
  } = useBasicMutation<
    { qcqaUserDocumentId: number },
    QCQACompanyDocsHistory[]
  >({
    apiUrl: apiUrls.qcqaCompanyDocsHistory,
    method: 'get',
    paramNamesForUrl: ['qcqaUserDocumentId'],
  });

  // 품질 제품별 개정이력 조회
  const {
    mutate: getQCQALotDocsHistory,
    isLoading: getQCQALotDocsHistoryLoading,
  } = useBasicMutation<
    { qcqaProductId: number; qcqaUserDocumentId: number },
    QCQACompanyDocsHistory[]
  >({
    apiUrl: apiUrls.qcqaLotDocsHistory,
    method: 'get',
    paramNamesForUrl: ['qcqaProductId', 'qcqaUserDocumentId'],
  });

  // 제조번호 서류 파일 추가
  const {
    mutate: addLotDocumentAttach,
    isLoading: addLotDocumentAttachLoading,
  } = useBasicMutation<{
    qcqaUserDocumentId: number;
    attachIds: number[];
  }>({
    apiUrl: apiUrls.qcqaLotAddDocumentAttach,
    method: 'post',
    urlParams: {
      qcqaProductId,
      ...(qcqaProductLotnoId && { qcqaProductLotnoId }),
    },
  });

  // 제조번호 서류 파일 삭제
  const {
    mutate: deleteLotDocumentAttach,
    isLoading: deleteLotDocumentAttachLoading,
  } = useBasicMutation<{
    qcqaProductLotnoAttachId: number;
  }>({
    apiUrl: apiUrls.qcqaLotDeleteDocumentAttach,
    method: 'delete',
    urlParams: {
      qcqaProductId,
      ...(qcqaProductLotnoId && { qcqaProductLotnoId }),
    },
    paramNamesForUrl: ['qcqaProductLotnoAttachId'],
  });

  // 제조 번호 개정이력 삭제
  const {
    mutate: deleteQCQALotDocsHistory,
    isLoading: deleteQCQALotDocsHistoryLoading,
  } = useBasicMutation<{ qcqaProductLotnoRecordId: number }>({
    apiUrl: apiUrls.deleteQCQALotDocsHistory,
    method: 'delete',
    ...(qcqaProductId &&
      qcqaProductLotnoId && {
        urlParams: {
          qcqaProductId,
          qcqaProductLotnoId,
        },
      }),
    paramNamesForUrl: ['qcqaProductLotnoRecordId'],
  });

  return {
    getQCQACompanyDocsHistory,
    getQCQACompanyDocsHistoryLoading,
    getQCQALotDocsHistory,
    getQCQALotDocsHistoryLoading,
    addLotDocumentAttach,
    addLotDocumentAttachLoading,
    deleteLotDocumentAttach,
    deleteLotDocumentAttachLoading,
    deleteQCQALotDocsHistory,
    deleteQCQALotDocsHistoryLoading,
  };
};

// 품질 제품 기본 정보
export const useQCQAProduct = ({
  qcqaProductId,
}: {
  qcqaProductId: number;
}) => {
  const {
    data: qcProduct,
    isLoading: isQcProductLoading,
  } = useBasicQuery<QCQAProduct>({
    apiUrl: apiUrls.getQCQAProduct,
    urlParams: {
      qcqaProductId,
    },
    option: {
      enabled: qcqaProductId > 0,
    },
  });

  const {
    mutate: updateQCProduct,
    isLoading: isUpdateQCProductLoading,
  } = useBasicMutation<QCQAUpdateProduct>({
    apiUrl: apiUrls.updateQcqaProduct,
    urlParams: {
      qcqaProductId,
    },
    method: 'post',
    useFormData: true,
  });

  return {
    qcProduct,
    isQcProductLoading,
    updateQCProduct,
    isUpdateQCProductLoading,
  };
};

// 품질 제품 서류 정보
export const useQCQAProductDocs = ({
  qcqaProductId,
}: {
  qcqaProductId: number;
}) => {
  const {
    data: qcqaProductDocs,
    isLoading: isQcqaProductDocsLoading,
  } = useBasicQuery<CompanyDocsList[]>({
    apiUrl: apiUrls.getQcqaProductDocs,
    urlParams: {
      qcqaProductId,
    },
    option: {
      enabled: !!qcqaProductId,
      cacheTime: 0,
    },
  });

  return {
    qcqaProductDocs,
    isQcqaProductDocsLoading,
  };
};

//QCQA 제품 서류 전체 다운로드
export const useDownloadQCQAProductDocs = () => {
  const {
    mutateAsync: qcqaProductDocs,
    isLoading: isQcqaProductDocsLoading,
  } = useBasicMutation<{ qcqaProductId: number }, CompanyDocsList[]>({
    apiUrl: apiUrls.getQcqaProductDocs,
    method: 'get',
    paramNamesForUrl: ['qcqaProductId'],
  });

  return {
    qcqaProductDocs,
    isQcqaProductDocsLoading,
  };
};

//최신 제조번호 조회
export const useQCQALatesLotDocs = () => {
  const {
    mutateAsync: qcqaLatestLotDocs,
    isLoading: isQcqaLatestLotDocsLoading,
  } = useBasicMutation<{ qcqaProductId: number }, QCQALotItem>({
    apiUrl: apiUrls.getQcqaLatestLotDocs,
    method: 'get',
    paramNamesForUrl: ['qcqaProductId'],
  });

  return {
    qcqaLatestLotDocs,
    isQcqaLatestLotDocsLoading,
  };
};

//QCQA 제조번호 서류 조회
export const useDownloadQCQALotDocs = () => {
  const {
    mutateAsync: qcqaLotDocs,
    isLoading: isQcqaLotDocsLoading,
  } = useBasicMutation<
    { qcqaProductId: number; qcqaProductLotnoId: number },
    CompanyDocsList[]
  >({
    apiUrl: apiUrls.getQcqaLotDocs,
    method: 'get',
    paramNamesForUrl: ['qcqaProductId', 'qcqaProductLotnoId'],
  });

  const {
    mutateAsync: qcqaLotDocsList,
    isLoading: isGetQCQALotDocsLoading,
  } = useBasicMutation<
    { qcqaProductId: number; qcqaProductLotnoId: number },
    QCQALotDocsListItem[]
  >({
    apiUrl: apiUrls.qcqaLotDocumentList,
    method: 'get',
    paramNamesForUrl: ['qcqaProductId'],
  });

  return {
    qcqaLotDocs,
    isQcqaLotDocsLoading,
    qcqaLotDocsList,
    isGetQCQALotDocsLoading,
  };
};

// QCQA Formula Breakdown
const calculateFormulaBreakdownRowSpan = (
  formulaBreakdownItems: ProductFormulaBreakdownItem[],
) => {
  const targetKeys: ['no', 'formulaWt'] = ['no', 'formulaWt'];
  let noSyncStack: number[] = [];
  let rowSpanAcc = 1;

  targetKeys.forEach((targetKey) => {
    for (let i = formulaBreakdownItems.length - 1; i >= 0; i--) {
      if (formulaBreakdownItems[i][targetKey] === null) {
        rowSpanAcc++;
        noSyncStack.push(i);
      } else {
        switch (targetKey) {
          case 'no': {
            formulaBreakdownItems[i].noRowSpan = rowSpanAcc;
            noSyncStack.forEach((nullIndex) => {
              formulaBreakdownItems[nullIndex].noSync =
                formulaBreakdownItems[i].no;
            });
            noSyncStack = [];
            break;
          }
          case 'formulaWt': {
            formulaBreakdownItems[i].formulaWtRowSpan = rowSpanAcc;
            break;
          }
        }
        rowSpanAcc = 1;
      }
    }
  });
};

export const useQCQAFormulaBreakdown = ({
  qcqaProductDetailId,
  qcqaRelationDocumentRecordId,
}: {
  qcqaProductDetailId: number;
  qcqaRelationDocumentRecordId: number;
}) => {
  const [
    qcqaFormulaBreakdownDraft,
    setQCQAFormulaBreakdownDraft,
  ] = useState<ProductFormulaBreakdownDraft | null>(null);

  const {
    data: qcqaFormulaBreakdownItems,
    isLoading: getLoading,
  } = useBasicQuery<{
    fileUrl: string;
    list: ProductFormulaBreakdownItem[];
    qcqaFormulaBreakdownId: number;
  }>({
    apiUrl: apiUrls.qcqaFormulaBreakdowns,
    urlParams: {
      qcqaProductDetailId,
      qcqaRelationDocumentRecordId,
    },
    option: {
      select: (res) => {
        if (res.data.result) {
          calculateFormulaBreakdownRowSpan(res.data.result.list);
        }

        return res.data.result;
      },
      enabled: qcqaProductDetailId > 0 && qcqaRelationDocumentRecordId > 0,
    },
  });

  const {
    mutate: validateQCQAFormulaBreakdown,
    isLoading: validateLoading,
  } = useBasicMutation<{ file: File }, ProductFormulaBreakdownDraft>({
    apiUrl: apiUrls.qcqaFormulaBreakdownsValid,
    method: 'post',
    option: {
      onSuccess: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result.list);
        setQCQAFormulaBreakdownDraft(res.data.result);
      },
      onError: () => {
        setQCQAFormulaBreakdownDraft(null);
      },
    },
  });

  return {
    qcqaFormulaBreakdownDraft,
    validateQCQAFormulaBreakdown,
    validateLoading,
    qcqaFormulaBreakdownItems,
    getLoading,
  };
};

export const useProductOutputs = ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  // qcqa_제품별_생산량_검색_qcqa112
  const {
    data: productOutputs,
    isLoading: isProductOutputsLoading,
  } = useBasicQuery<QCQAProductOutputItem[]>({
    apiUrl: apiUrls.qcqaProductOutputs,
    method: 'get',
    qs: {
      from,
      to,
    },
    option: {
      enabled: !!from && !!to,
    },
  });

  const {
    mutate: downloadProductOutputExcel,
    isLoading: downloadProductOutputExcelLoading,
  } = useMutation(
    ({ from, to }: { from: string; to: string }) =>
      client.get(`${apiUrls.qcqaProductOutputExcel}?from=${from}&to=${to}`, {
        responseType: 'blob',
      }),
    {
      onSuccess: (res) => {
        let a = document.createElement('a');

        a.href = window.URL.createObjectURL(res.data);
        a.download = decodeURIComponent(
          `[${from.replaceAll('-', '')}-${to.replaceAll(
            '-',
            '',
          )}]생산실적현황.xlsx`,
        );
        a.click();
      },
    },
  );

  return {
    productOutputs,
    isProductOutputsLoading,
    downloadProductOutputExcel,
    downloadProductOutputExcelLoading,
  };
};
