import { Button, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';

import FooterBox from 'components/FooterBox';
import { FileUpload, Typography } from 'components/system';
import { createFormData, downloadFile } from 'lib/file';
import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
} from 'components/ui';
import { requireRule } from 'lib/validate';
import { NMPACompanyInfo } from 'types/material/nmpa/nmpa';
import {
  useNMPAAuthorization,
  useNMPACompanyInfoFileUrl,
} from 'service/material/nmpa/nmpa';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { ReactComponent as DottedLine } from 'asset/svg/dotted_line.svg';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  margin: 20px auto 0;
  max-width: 816px;
`;

const FormItemContainer = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const UploadExampleContainer = styled.div`
  position: relative;
  padding: 24px;
  border-radius: 12px;
  background: ${palette.SLATE_GRAY10};
  margin-top: 8px;

  &:nth-of-type(1) {
    margin-top: 24px;
  }
`;

interface NMPAAuthorizationForm {
  companyName: string;
  ceoName: string;
  zipcode: string;
  managerName: string;
  letterOfAuthorizationFile?: File | { name: string; url: string };
  companyInformationFile?: File | { name: string; url: string };
}

const uploadExampleInfo = [
  {
    title: '기업정보표 예시',
    explain: '명판/직인 날인',
    img: 'company_info_example',
  },
];

const NMPAAuthorization = ({
  nmpaCompanyInfo,
}: {
  nmpaCompanyInfo?: NMPACompanyInfo | null;
}) => {
  const [form] = Form.useForm<NMPAAuthorizationForm>();
  const { addNMPAAuthorization, isLoading } = useNMPAAuthorization();
  const updateMode = typeof nmpaCompanyInfo?.companyName === 'string';

  const {
    generateCompanyInformationFileUrl,
    generateLetterOfAuthorizationFileUrl,
    generateCompanyInformationFileUrlLoading,
  } = useNMPACompanyInfoFileUrl();

  const handleSubmit = ({
    companyName,
    ceoName,
    zipcode,
    letterOfAuthorizationFile,
    companyInformationFile,
    managerName,
  }: NMPAAuthorizationForm) => {
    const formData = createFormData({
      companyName,
      ceoName,
      zipcode,
      managerName,
      ...(companyInformationFile instanceof File && {
        companyInformationFile,
      }),
      ...(letterOfAuthorizationFile instanceof File && {
        letterOfAuthorizationFile,
      }),
    });

    addNMPAAuthorization(formData, {
      onSuccess: () => {
        if (updateMode) {
          message.success('수정되었습니다.');

          return;
        }

        Modal.confirm({
          bodyStyle: {
            textAlign: 'center',
          },
          width: 400,
          icon: null,
          content: (
            <Typography.Text type="BODY_2">
              회사 정보 입력이 완료되었습니다.
              <br />
              <br />
              취득 신청을 위해
              <br />
              원료 신고 정보 입력으로 이동하시겠습니까?
            </Typography.Text>
          ),
          okText: '원료 정보 입력',
          onOk: () => {
            history.push(path.material.nmpa.material.list);
          },
        });
      },
    });
  };

  const handleFileGenerate = async ({
    isAuthorization,
  }: {
    isAuthorization: boolean;
  }) => {
    const {
      ceoName,
      companyName,
      zipcode,
      managerName,
    } = form.getFieldsValue();

    if (!ceoName || !companyName || !zipcode || !managerName) {
      message.warn('회사 정보를 먼저 입력해 주세요.');

      return;
    }

    if (isAuthorization) {
      generateLetterOfAuthorizationFileUrl({
        zipcode,
        managerName,
      });
    } else {
      generateCompanyInformationFileUrl({
        zipcode,
        managerName,
      });
    }
  };

  useEffect(() => {
    if (nmpaCompanyInfo) {
      const {
        companyName,
        ceoName,
        zipcode,
        managerName,
        companyInformationFileUrl,
        companyInformationFilename,
      } = nmpaCompanyInfo;

      form.setFieldsValue({
        ...(companyName && {
          companyName,
        }),
        ...(ceoName && {
          ceoName,
        }),
        ...(zipcode && { zipcode }),
        ...(managerName && { managerName }),
        ...(companyInformationFileUrl &&
          companyInformationFilename && {
            companyInformationFile: {
              url: companyInformationFileUrl,
              name: companyInformationFilename,
            },
          }),
      });
    }
  }, [nmpaCompanyInfo]);

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <OrderList>
          <OrderListItem>
            <OrderListItemLabel>회사 정보를 입력해 주세요.</OrderListItemLabel>
            <OrderListItemContent gutter={{ top: 20 }}>
              <FormItemContainer>
                <Form.Item
                  label="회사명 (중문 또는 영문)"
                  name="companyName"
                  required
                  rules={[
                    requireRule,
                    {
                      pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,50}$/,
                      message: '한글 입력 불가, 최대 50자',
                    },
                  ]}
                >
                  <Input placeholder="영문 입력 시 사업자등록증과 동일하게 입력" />
                </Form.Item>
                <Form.Item
                  label="대표자명 (한자)"
                  name="ceoName"
                  required
                  rules={[
                    requireRule,
                    {
                      pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,50}$/,
                      message: '한글 입력 불가, 최대 50자',
                    },
                  ]}
                >
                  <Input placeholder="순우리말인 경우 성씨만 한자로 입력" />
                </Form.Item>
                <Form.Item
                  label="담당자명 (한자)"
                  name="managerName"
                  required
                  rules={[
                    requireRule,
                    {
                      pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,50}$/,
                      message: '한글 입력 불가, 최대 50자',
                    },
                  ]}
                >
                  <Input placeholder="순우리말인 경우 성씨만 한자로 입력" />
                </Form.Item>
                <Form.Item
                  label="우편번호"
                  name="zipcode"
                  required
                  rules={[
                    requireRule,
                    {
                      pattern: /^\d{1,10}$/,
                      message: '숫자만 입력, 최대 10자',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </FormItemContainer>
            </OrderListItemContent>
          </OrderListItem>
          <OrderListItem>
            <OrderListItemLabel>
              서류를 다운로드하고 아래 예시를 참고하여 명판/직인 날인, 서명을
              해주세요.
            </OrderListItemLabel>
            <OrderListItemContent gutter={{ top: 20 }}>
              <Button
                loading={generateCompanyInformationFileUrlLoading}
                icon={
                  <Icon
                    name="download"
                    color="PRIMARY50"
                    size={18}
                    style={{ marginRight: 4 }}
                  />
                }
                onClick={() => handleFileGenerate({ isAuthorization: false })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                기업 정보표 다운로드
              </Button>
              {uploadExampleInfo.map(({ title, explain, img }, index) => (
                <UploadExampleContainer key={title}>
                  <Flex>
                    <Typography.Text
                      medium
                      color="SLATE_GRAY70"
                      type="BODY_2"
                      style={{ flex: '0 0 138px' }}
                    >
                      {title}
                    </Typography.Text>
                    <img
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/${img}.png`}
                      alt="company_example"
                      width={416}
                      style={{ padding: '8px 0 0' }}
                    />
                    <Flex
                      align="center"
                      gap={16}
                      style={{
                        alignSelf: 'center',
                        transform: 'translateX(-50px)',
                      }}
                    >
                      <DottedLine />
                      <Typography.Text color="SLATE_GRAY70" medium>
                        {explain}
                      </Typography.Text>
                    </Flex>
                    {index === 0 && (
                      <div style={{ position: 'absolute', top: 32, right: 36 }}>
                        <Typography.Text color="SLATE_GRAY70" type="SMALL">
                          *참고용 국문 번역 파일
                        </Typography.Text>
                        <Button
                          icon={
                            <Icon
                              name="download"
                              color="PRIMARY50"
                              size={18}
                              style={{ marginRight: 4 }}
                            />
                          }
                          onClick={() =>
                            downloadFile(
                              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%8E%E1%85%A5%E1%86%B7%E1%84%87%E1%85%AE1-2.+%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%85%E1%85%AD%E1%84%91%E1%85%AE%E1%86%B7%E1%84%8C%E1%85%B5%E1%86%AF%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%A7%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8%E1%84%80%E1%85%B5%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%91%E1%85%AD_%E1%84%80%E1%85%AE%E1%86%A8%E1%84%86%E1%85%AE%E1%86%AB%E1%84%87%E1%85%A5%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%A8%E1%84%87%E1%85%A9%E1%86%AB.pdf',
                              'Example_Kor_Company_info.pdf',
                            )
                          }
                          style={{
                            marginTop: 8,
                            height: 32,
                            width: 136,
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          번역본 다운로드
                        </Button>
                      </div>
                    )}
                  </Flex>
                </UploadExampleContainer>
              ))}
            </OrderListItemContent>
          </OrderListItem>
          <OrderListItem>
            <OrderListItemLabel>
              서명이 완료된 파일을 스캔 후 아래에 업로드해 주세요.
            </OrderListItemLabel>
            <OrderListItemContent gutter={{ top: 20 }}>
              <Flex gap={16}>
                <Form.Item
                  name="companyInformationFile"
                  normalize={(value, prevValue) => {
                    if (value.size > 1024 * 1024 * 25) {
                      message.warn('최대 25MB 까지 업로드 가능합니다.');
                      return prevValue;
                    }
                    return value;
                  }}
                >
                  <FileUpload
                    icon={
                      <Icon
                        name="upload"
                        color="PRIMARY50"
                        size={18}
                        style={{ marginRight: 4 }}
                      />
                    }
                    isView={false}
                    readOnly={false}
                    text="기업 정보표 업로드"
                  />
                </Form.Item>
              </Flex>
            </OrderListItemContent>
          </OrderListItem>
        </OrderList>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {!updateMode ? '등록' : '수정'}
          </Button>
        </FooterBox>
      </Form>
    </Container>
  );
};

export default NMPAAuthorization;
