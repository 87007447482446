import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  message,
} from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { isEqual } from 'lodash';

import FooterBox from 'components/FooterBox';
import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import { useCountries } from 'service/country';
import { useMyAllRP, useMyRP } from 'service/brand/product/product';
import { BusinessType, RPCompanyType } from 'types/product';
import { useModal } from 'hook/useModal';

export interface ProductRPAddModalProps {
  onClose?: () => void;
  responsiblePersonId?: number;
}

interface RPFormState {
  countryId: number;
  companyName: string;
  companyAddress: string;
  contact: ContactType[];
  companyTel: string | null;
  companyEmail: string | null;
  companyHomepage: string | null;
  companyType: RPCompanyType;
  smallBusiness: boolean | 'none';
}

enum ContactType {
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
  URL = 'URL',
}

const ProductRPAddModal = ({
  onClose,
  responsiblePersonId,
}: ProductRPAddModalProps) => {
  const countries = useCountries();
  const [form] = Form.useForm<RPFormState>();
  const {
    addMyRP,
    addMyRPLoading,
    updateMyRP,
    updateMyRPLoading,
    myRPDetail,
    myRPDetailLoading,
    usingProductRPs,
  } = useMyRP({
    responsiblePersonId,
  });
  const { myAllRP } = useMyAllRP();
  const { openAlertModal } = useModal();

  const updateMode = !!responsiblePersonId;

  useEffect(() => {
    if (myRPDetail) {
      form.setFieldsValue({
        countryId: myRPDetail.countryId,
        companyAddress: myRPDetail.companyAddress,
        companyEmail: myRPDetail.companyEmail,
        companyHomepage: myRPDetail.companyHomepage,
        companyName: myRPDetail.companyName,
        smallBusiness:
          myRPDetail.companySize === BusinessType.MEDIUM
            ? true
            : !myRPDetail.companySize
            ? 'none'
            : false,
        companyTel: myRPDetail.companyTel,
        companyType: myRPDetail.companyType,
        contact: [
          myRPDetail.companyTel && ContactType.MOBILE,
          myRPDetail.companyEmail && ContactType.EMAIL,
          myRPDetail.companyHomepage && ContactType.URL,
        ].filter((item) => !!item),
      });
    } else {
      form.setFieldsValue({
        contact: [ContactType.MOBILE],
      });
    }
  }, [myRPDetail]);

  const handleSubmit = (formValues: RPFormState) => {
    const {
      contact,
      countryId,
      companyName,
      companyAddress,
      companyEmail,
      companyHomepage,
      companyTel,
      companyType,
      smallBusiness,
    } = formValues;

    const data = {
      countryId,
      companyAddress,
      companyEmail,
      companyHomepage,
      companyName,
      companySize:
        typeof smallBusiness === 'boolean'
          ? BusinessType.MEDIUM
          : (smallBusiness as unknown as string) === 'none'
          ? null
          : BusinessType.CORPORATE,
      companyTel,
      companyType,
    };

    if (contact.length === 0) {
      return message.warn('최소 1개 이상의 RP연락처를 입력해 주세요.');
    }

    if (updateMode && myRPDetail) {
      const beforeData = {
        countryId: myRPDetail.countryId,
        companyAddress: myRPDetail.companyAddress,
        ...(myRPDetail.companyEmail && {
          companyEmail: myRPDetail.companyEmail,
        }),
        ...(myRPDetail.companyHomepage && {
          companyHomepage: myRPDetail.companyHomepage,
        }),
        ...(myRPDetail.companyTel && {
          companyTel: myRPDetail.companyTel,
        }),
        companyName: myRPDetail.companyName,
        smallBusiness:
          myRPDetail.companySize === BusinessType.MEDIUM
            ? true
            : !myRPDetail.companySize
            ? null
            : false,
        companyType: myRPDetail.companyType,
        contact: [
          myRPDetail.companyTel && ContactType.MOBILE,
          myRPDetail.companyEmail && ContactType.EMAIL,
          myRPDetail.companyHomepage && ContactType.URL,
        ].filter((item) => !!item),
      };

      if (isEqual(formValues, beforeData)) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
      if (
        myAllRP?.find(
          ({ responsiblePersonId, companyAddress, companyName }) =>
            companyAddress === formValues.companyAddress &&
            companyName === formValues.companyName &&
            responsiblePersonId !== myRPDetail?.responsiblePersonId,
        )
      ) {
        return message.warn('동일한 정보의 RP가 이미 등록되어 있습니다.');
      }
      usingProductRPs([responsiblePersonId], {
        onSuccess: (res) => {
          const result = res.data.result;
          if (result.length > 0) {
            openAlertModal({
              width: 424,
              content: (
                <>
                  인증 제품에 등록된 RP 정보를 모두 변경하시겠습니까?
                  <br />
                  (변경을 원하지 않으실 경우 새로운 RP로 추가하여 사용해
                  주세요.)
                </>
              ),
              okText: '모두 변경',
              closeText: '취소',
              onOk: () => {
                updateMyRP(data, {
                  onSuccess: () => {
                    message.success('수정되었습니다.');
                    onClose?.();
                  },
                });
              },
            });
          } else {
            updateMyRP(data, {
              onSuccess: () => {
                message.success('수정되었습니다.');
                onClose?.();
              },
            });
          }
        },
      });
    } else {
      if (
        myAllRP?.find(
          ({ responsiblePersonId, companyAddress, companyName }) =>
            companyAddress === formValues.companyAddress &&
            companyName === formValues.companyName,
        )
      ) {
        return message.warn('동일한 정보의 RP가 이미 등록되어 있습니다.');
      }
      addMyRP(data, {
        onSuccess: () => {
          message.success('추가되었습니다.');
          onClose?.();
        },
      });
    }
  };

  return (
    <StyledModal
      title={
        <Typography.Text type="TITLE_2">
          RP {responsiblePersonId ? '수정' : '추가'}
        </Typography.Text>
      }
      visible={true}
      width={560}
      footer={null}
      onCancel={onClose}
    >
      <Spin spinning={responsiblePersonId ? myRPDetailLoading : false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          scrollToFirstError={scrollToFirstErrorOption}
        >
          <div style={{ maxHeight: '580px', overflowY: 'scroll' }}>
            <Form.Item
              name="countryId"
              label="국가"
              rules={generateFormRules({ required: true })}
            >
              <Select
                placeholder="국가 선택"
                options={countries.map(({ countryId, countryNameKo }) => ({
                  label: countryNameKo,
                  value: countryId,
                }))}
              />
            </Form.Item>
            <Form.Item
              required
              name="companyName"
              label="RP회사명 (영문)"
              rules={generateFormRules({
                required: true,
                exceptKorean: true,
              })}
            >
              <Input placeholder="회사명 입력" />
            </Form.Item>
            <Form.Item
              name="companyAddress"
              label="RP주소 (영문)"
              rules={generateFormRules({
                required: true,
                exceptKorean: true,
              })}
            >
              <Input placeholder="주소 입력" />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) => prev.contact !== curr.contact}
              noStyle
            >
              {({ getFieldValue, setFieldsValue }) => {
                const selectedContacts: ContactType[] =
                  getFieldValue('contact');

                return (
                  <>
                    <Form.Item
                      name="contact"
                      label={
                        <Flex gap={4} align="center">
                          RP연락처
                          <Tip trigger="click">
                            <Typography.Text type="SMALL">
                              RP전화번호 입력 방법
                              <br />
                              {`<국가 Code> + <지역 Code> + <전화번호>`}
                              <br />
                              예시 ① 미국 전화번호{' '}
                              <span style={{ color: palette.PRIMARY50 }}>
                                19999999999
                              </span>
                            </Typography.Text>
                          </Tip>
                        </Flex>
                      }
                      required
                      rules={generateFormRules({
                        required:
                          !selectedContacts ||
                          selectedContacts?.length === 0 ||
                          !selectedContacts.includes(ContactType.MOBILE),
                      })}
                    >
                      <Checkbox.Group
                        onChange={(value) => {
                          setFieldsValue({ contact: value });
                        }}
                        value={getFieldValue('contact')}
                        style={{
                          background: palette.GRAY10,
                          width: '100%',
                          padding: '10px 24px',
                          borderRadius: 4,
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: 16,
                        }}
                      >
                        <Checkbox
                          value={ContactType.MOBILE}
                          disabled
                          checked={true}
                        >
                          전화번호
                        </Checkbox>
                        <Checkbox value={ContactType.EMAIL}>
                          이메일 주소
                        </Checkbox>
                        <Checkbox value={ContactType.URL}>
                          홈페이지 주소
                        </Checkbox>
                      </Checkbox.Group>
                      <Form.Item
                        name="companyTel"
                        required
                        rules={generateFormRules({
                          required: true,
                          onlyNumber: true,
                        })}
                        style={{ marginBottom: 8, marginTop: 12 }}
                      >
                        <Input placeholder="국가번호 포함하여 전화번호 입력" />
                      </Form.Item>
                      {selectedContacts?.includes(ContactType.EMAIL) && (
                        <Form.Item
                          name="companyEmail"
                          required={selectedContacts?.includes(
                            ContactType.EMAIL,
                          )}
                          rules={generateFormRules({
                            required: selectedContacts?.includes(
                              ContactType.EMAIL,
                            ),
                            email: true,
                          })}
                          style={{ marginBottom: 8, marginTop: 12 }}
                        >
                          <Input placeholder="이메일 주소 입력" />
                        </Form.Item>
                      )}
                      {selectedContacts?.includes(ContactType.URL) && (
                        <Form.Item
                          name="companyHomepage"
                          required={selectedContacts?.includes(ContactType.URL)}
                          rules={generateFormRules({
                            required: selectedContacts?.includes(
                              ContactType.URL,
                            ),
                            url: true,
                          })}
                          style={{ marginBottom: 8, marginTop: 12 }}
                        >
                          <Input placeholder="홈페이지 주소 입력" />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
            <Form.Item
              name="companyType"
              label={
                <Flex gap={4} align="center">
                  RP종류
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      책임판매사, 제조사, 포장사인지의 여부를
                      <br />
                      체크해 주세요.
                    </Typography.Text>
                  </Tip>
                </Flex>
              }
              required
              rules={generateFormRules({
                required: true,
              })}
            >
              <Radio.Group
                style={{
                  background: palette.GRAY10,
                  width: '100%',
                  padding: '10px 24px',
                  borderRadius: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 16,
                  gap: 24,
                }}
              >
                <Radio style={{ width: 128 }} value="BRAND">
                  Distributor
                </Radio>
                <Radio style={{ width: 128 }} value="MANUFACTURE">
                  Manufacturer
                </Radio>
                <Radio style={{ width: 128 }} value="PACKER">
                  Packer
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="smallBusiness"
              label={
                <Flex gap={4} align="center">
                  중소기업 여부
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      중소기업: 미국내 지난 3년간 매출이 평균 연 100만
                      <br />불 미만인 업체
                    </Typography.Text>
                  </Tip>
                </Flex>
              }
              rules={generateFormRules({
                required: true,
              })}
              required
            >
              <Radio.Group
                style={{
                  background: palette.GRAY10,
                  width: '100%',
                  padding: '10px 24px',
                  borderRadius: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: 16,
                }}
              >
                <Radio style={{ width: 128 }} value={true}>
                  중소기업
                </Radio>
                <Radio style={{ width: 128 }} value={false}>
                  중소기업 아님
                </Radio>
                <Radio style={{ width: 128 }} value={'none'}>
                  미지정
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <FooterBox style={{ paddingTop: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={addMyRPLoading || updateMyRPLoading}
            >
              {updateMode ? '수정' : '등록'}
            </Button>
          </FooterBox>
        </Form>
      </Spin>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-form {
  }
  .ant-radio-wrapper {
    margin-right: 0px;
  }
`;

export default ProductRPAddModal;
