import { Checkbox, Form } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { generateFormRules } from 'lib/validate';
import { useRef } from 'react';
import InputNumber from 'components/ui/InputNumber';

const PHForm = ({
  isOnlyCN,
  readOnlyMode,
  onChangePhRadioGroup,
}: {
  isOnlyCN: boolean;
  readOnlyMode: boolean;
  onChangePhRadioGroup: (notApplicablePh: boolean) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <S.StyledLabelSpaceBetween>
        <S.StyledLabel isOnlyCN={isOnlyCN}>pH</S.StyledLabel>
        <Form.Item
          name="notApplicablePh"
          initialValue={false}
          valuePropName="checked"
          noStyle
        >
          <Checkbox
            disabled={readOnlyMode}
            onChange={(e) => onChangePhRadioGroup(e.target.checked)}
          >
            <S.CheckBoxLabel>해당 없음</S.CheckBoxLabel>
          </Checkbox>
        </Form.Item>
      </S.StyledLabelSpaceBetween>
      <S.StyledRow style={{ gap: 16 }}>
        <S.StyledColumn>
          <Typography.Text gutter={{ right: 4, bottom: 8 }}>
            정상 범위
          </Typography.Text>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.notApplicablePh !== next.notApplicablePh ||
              prev.phLimitMax !== next.phLimitMax ||
              prev.phLimitMin !== next.phLimitMin
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const notApplicablePh = getFieldValue('notApplicablePh');
              const phLimitMax = getFieldValue('phLimitMax');

              return (
                <S.StyledRow>
                  {!notApplicablePh ? (
                    <>
                      <S.AntdExplainWrapper>
                        <Form.Item
                          name="phLimitMin"
                          dependencies={['phLimitMax']}
                          initialValue={5.0}
                          rules={[
                            ...generateFormRules({
                              required: !(isOnlyCN || notApplicablePh),
                            }),
                            {
                              validator: (_, phLimitMinValue) => {
                                if (phLimitMinValue > phLimitMax) {
                                  return Promise.reject(
                                    '좌측 값은 우측 값보다 클 수 없음',
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={14}
                            precision={2}
                            step={0.1}
                            disabled={readOnlyMode}
                            style={{ width: 160 }}
                            inputwidth={82}
                          />
                        </Form.Item>
                      </S.AntdExplainWrapper>
                      <Typography.Text
                        style={{
                          display: 'inline-block',
                          width: '16px',
                          textAlign: 'center',
                        }}
                      >
                        ~
                      </Typography.Text>
                      <Form.Item
                        name="phLimitMax"
                        initialValue={10.0}
                        rules={generateFormRules({
                          required: !(isOnlyCN || notApplicablePh),
                        })}
                      >
                        <InputNumber
                          min={0}
                          max={14}
                          precision={2}
                          step={0.1}
                          disabled={readOnlyMode}
                          style={{ width: 160 }}
                          inputwidth={82}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item>
                        <InputNumber disabled style={{ width: 160 }} />
                      </Form.Item>
                      <Typography.Text
                        style={{
                          display: 'inline-block',
                          width: '16px',
                          textAlign: 'center',
                        }}
                      >
                        ~
                      </Typography.Text>
                      <Form.Item>
                        <InputNumber disabled style={{ width: 160 }} />
                      </Form.Item>
                    </>
                  )}
                </S.StyledRow>
              );
            }}
          </Form.Item>
        </S.StyledColumn>

        <S.StyledColumn>
          <Typography.Text gutter={{ bottom: 8 }}>수치</Typography.Text>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.notApplicablePh !== next.notApplicablePh ||
              prev.phLimitMax !== next.phLimitMax ||
              prev.phLimitMin !== next.phLimitMin
            }
            noStyle
          >
            {({ getFieldValue, setFieldsValue }) => {
              const notApplicablePh = getFieldValue('notApplicablePh');
              const phLimitMax = getFieldValue('phLimitMax');
              const phLimitMin = getFieldValue('phLimitMin');

              return (
                <>
                  {!notApplicablePh ? (
                    <Form.Item
                      name="ph"
                      required={!(isOnlyCN || notApplicablePh)}
                      dependencies={['phLimitMin', 'phLimitMax']}
                      style={{ width: 120 }}
                      rules={[
                        ...generateFormRules({
                          required: !(isOnlyCN || notApplicablePh),
                        }),
                        !(isOnlyCN || notApplicablePh)
                          ? {
                              validator: (_, ph) => {
                                if (ph < phLimitMin || ph > phLimitMax) {
                                  return Promise.reject(
                                    '정상 범위를 벗어난 수치',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }
                          : {
                              validator(_, ph) {
                                if (
                                  ph !== '' &&
                                  ph !== null &&
                                  (ph < phLimitMin || ph > phLimitMax) &&
                                  phLimitMax !== null &&
                                  phLimitMin !== null
                                ) {
                                  return Promise.reject(
                                    '정상 범위를 벗어난 수치',
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                      ]}
                      // 빈 값을 입력하면 value가 0으로 떨어져 ref를 통해 빈 값을 구분함
                      normalize={(value) =>
                        ref.current?.value === '' && value === 0 ? null : value
                      }
                    >
                      <InputNumber
                        ref={ref}
                        precision={2}
                        step={0.1}
                        // 빈 값은 onChange로 전달 받지 않아 onInput 이용
                        onInput={(value) => {
                          if (value === '') {
                            setFieldsValue({
                              ph: '',
                            });
                          }
                        }}
                        disabled={readOnlyMode}
                        style={{ width: 160 }}
                        inputwidth={82}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <InputNumber disabled style={{ width: 160 }} />
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </S.StyledColumn>
      </S.StyledRow>
    </>
  );
};

export default PHForm;
