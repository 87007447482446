import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import history from 'lib/history';
import {
  useDocument,
  useUploadDocument,
} from 'service/material/icid/application';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import { ICIDDocumentCode } from 'types/material/icid/icid';
import { CorrectButton, Flex } from 'components/ui';
import UpdateLog from '../UpdateLog';
import { useModal } from 'hook/useModal';
import { DocumentCode } from 'types/material/common';
import { DocumentStatus } from 'types/common';
import { useICIDApplication } from 'service/material/icid/icid';

const UploadDocumentCodeMap = {
  msds: ICIDDocumentCode.MATERIAL_SAFETY_DATA_SHEET,
  tds: ICIDDocumentCode.TDS,
  composition: ICIDDocumentCode.COMPOSITION_INFORMATION,
  coa: ICIDDocumentCode.CERTIFICATE_OF_ANALYSIS,
  'manufacturing-flow-chart': ICIDDocumentCode.MANUFACTURING_FLOW_CHART,
};

const DocumentCodeMap = {
  msds: DocumentCode.MSDS,
  tds: DocumentCode.TDS,
  composition: DocumentCode.MCI,
  coa: DocumentCode.COA,
  'manufacturing-flow-chart': DocumentCode.MFC,
};

const DocumentsUpload = () => {
  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const icidApplicationId = Number(splitPath[splitPath.length - 2]);
  const pathDocumentCode = splitPath[splitPath.length - 1];
  const uploadDocumentCode =
    UploadDocumentCodeMap[
      pathDocumentCode as keyof typeof UploadDocumentCodeMap
    ];
  const documentCode =
    DocumentCodeMap[pathDocumentCode as keyof typeof DocumentCodeMap];
  const { document } = useDocument({
    icidApplicationId,
    documentCode: uploadDocumentCode,
  });
  const { icidApplication } = useICIDApplication(icidApplicationId);
  const readOnlyMode = useMemo(
    () =>
      (document &&
        document.status !== DocumentStatus.MOD &&
        icidApplication?.status === 'ONGOING') ||
      icidApplication?.status === 'CANCEL' ||
      icidApplication?.status === 'APPLYING',
    [document],
  );
  const [file, setFile] = useState<File | { name: string; url: string }>();

  const handleUpload = (newFile: File) => {
    if (newFile.size > 1024 * 1024 * 25) {
      message.warn('최대 용량인 25MB를 초과했습니다.');
      return false;
    }
    setFile(newFile);
  };

  const handleDeleteFile = () => {
    setFile(undefined);
  };
  const updateMode = useMemo(() => !!document, [document]);
  const { uploadDocument } = useUploadDocument();
  const handleSubmit = () => {
    if (!file) {
      return message.warn('파일을 업로드해 주세요.');
    } else if (!(file instanceof File)) {
      return message.warn('수정할 내역이 없습니다.');
    }

    uploadDocument(
      { icidApplicationId, documentCode: uploadDocumentCode, file },
      {
        onSuccess: () => {
          message.success(!updateMode ? '등록되었습니다.' : '수정되었습니다.');
          history.goBack();
        },
      },
    );
  };

  useEffect(() => {
    if (document) {
      setFile({
        name: document.icidApplicationDocTypes[0].filename,
        url: document.icidApplicationDocTypes[0].fileUrl,
      });
    }
  }, [document]);

  const { openCorrectRequestMessageModal } = useModal();

  return (
    <>
      {document && (
        <Flex justify="end" gap={8}>
          {document.status === 'MOD' && (
            <CorrectButton
              onClick={() =>
                openCorrectRequestMessageModal({
                  correctMessage: document?.correctRequestMessage || '',
                })
              }
            />
          )}
          <UpdateLog id={icidApplicationId} documentCode={documentCode} />
        </Flex>
      )}
      <FileUploadTemplate
        updateMode={updateMode}
        readOnly={readOnlyMode}
        accept={'application/pdf, .doc, .xlsx, .docx, .jpg, .jpeg, .gif, .zip'}
        max={1}
        files={file}
        onDelete={handleDeleteFile}
        onSubmit={handleSubmit}
        onUpload={handleUpload}
      />
    </>
  );
};

export default DocumentsUpload;
