import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import QCQARegistrationPage from 'pages/brand/qcqa/QCQARegistrationPage';
import QCQASelfInspectPage from 'pages/brand/qcqa/QCQASelfInspectPage';
import QCQAProductPage from 'pages/brand/qcqa/QCQAProductPage';
import QCQALotPage from 'pages/brand/qcqa/QCQALotPage';
import QCQAProductListPage from 'pages/brand/qcqa/QCQAProductListPage';
import QCQALotListPage from 'pages/brand/qcqa/QCQALotListPage';
import QCQAProductUpdatePage from 'pages/brand/qcqa/QCQAProductUpdatePage';
import QCQAOutputPage from 'pages/brand/qcqa/QCQAOutputPage';

const QCQARouter = () => {
  return (
    <Switch>
      {/* 품질 제품 등록 */}
      <Route
        exact
        path={path.qcqa.registration.root}
        component={QCQARegistrationPage}
      />
      <Route
        exact
        path={path.qcqa.registration.product}
        component={QCQAProductPage}
      />
      <Route
        exact
        path={[
          path.qcqa.registration.lot,
          `${path.qcqa.management.root}/:qcqaProductId/lot/:qcqaProductLotnoId`,
        ]}
        component={QCQALotPage}
      />
      {/* 품질 제품 관리 */}
      <Route
        path={path.qcqa.management.root}
        exact
        component={QCQAProductListPage}
      />
      <Route
        path={`${path.qcqa.management.root}/:qcqaProductId`} //
        exact
        component={QCQAProductUpdatePage}
      />
      <Route
        exact
        path={`${path.qcqa.management.root}/:qcqaProductId/lot`}
        component={QCQALotListPage}
      />
      {/* 생산 실적 현황 */}
      <Route
        exact
        path={`${path.qcqa.outputs.root}`}
        component={QCQAOutputPage}
      />
      {/* 자율점검 관리 */}
      <Route
        path={path.qcqa.selfInspect.root}
        exact
        component={QCQASelfInspectPage}
      />
    </Switch>
  );
};

export default QCQARouter;
