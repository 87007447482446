import styled, { css } from 'styled-components';

interface StyleProps {
  width: string;
  height: string;
  borderRadius: string;
}

const Container = styled.div<StyleProps>`
  position: relative;
  cursor: pointer;

  ${({ width, height, borderRadius }) =>
    css`
      width: ${width};
      height: ${height};
      border-radius: ${borderRadius};
    `}
`;

const ImageWrapper = styled.div<StyleProps>`
  position: relative;

  ${({ width, height, borderRadius }) =>
    css`
      width: ${width};
      height: ${height};
      border-radius: ${borderRadius};
    `}

  :hover {
    .overlay,
    .opacity-item {
      visibility: visible;
    }
  }
`;

const StyledImage = styled.img<{ objectFit: any }>`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit};
`;

interface OpacityImageBoxProps extends StyleProps {
  src: string;
  alt: string;
  objectFit?: 'fill' | 'contain' | 'cover';
  onClick?: () => void;
}

const ImageBox = ({
  width,
  height,
  borderRadius,
  src,
  alt,
  onClick,
  objectFit = 'fill',
}: OpacityImageBoxProps) => {
  return (
    <Container
      width={width}
      height={height}
      borderRadius={borderRadius}
      onClick={onClick}
    >
      <ImageWrapper width={width} height={height} borderRadius={borderRadius}>
        <StyledImage src={src} alt={alt} objectFit={objectFit} />
      </ImageWrapper>
    </Container>
  );
};

export default ImageBox;
