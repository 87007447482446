import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import InfoIcon from 'components/system/general/icon/InfoIcon';
import * as authApi from 'lib/api/auth';
import { messages } from 'lib/consts';
import { useForceUpdate } from 'lib/hook';
import palette from 'lib/styles/palette';
import {
  emailRule,
  mobilePhoneRule,
  phoneRule,
  requireRule,
} from 'lib/validate';
import { useAuthority } from 'service/auth';
import { useCompanyType } from 'service/company';
import { UserType } from 'types/auth';
import { CompanyType } from 'types/company';

const ManagerFormBlock = styled.div<{ updateMode: boolean }>`
  max-width: 520px;
  margin: 0 auto;

  ${({ updateMode }) =>
    updateMode &&
    css`
      padding: 16px 0;
      border: none !important;

      .ant-card-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
      }

      & + & {
        margin-top: 20px;
      }
    `}

  .ant-card-body {
    padding: 20px;
  }
  .ant-radio-inner::after {
    display: block;
  }
`;

const BreakdownAuthorityNotice = styled.div`
  background-color: ${palette.SLATE_GRAY10};
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 8px;
`;

const IsPermitFormContainer = styled.div`
  .ant-form-item-control-input {
    min-height: unset;
  }

  .ant-radio-wrapper {
    line-height: 24px;
  }
`;

/**
 *
 * @param directMode
 * @returns
 */

const ManagerForm = ({
  updateMode = false,
  directMode = false,
  form,
  manufacturers,
  submitLoading,
  deleteLoading,
  onSubmit,
  onCancel,
  onDelete,
  onResetPassword,
}: any) => {
  const forceUpdate = useForceUpdate();
  const companyType = useCompanyType();
  const authority = useAuthority();
  const [isUsernameCheckSuccess, setIsUsernameCheckSuccess] = useState(false);

  return (
    <ManagerFormBlock updateMode={updateMode}>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onValuesChange={(changedValues) => {
          if (typeof changedValues.userType !== 'undefined') {
            forceUpdate();
          }
        }}
        onFinish={onSubmit}
        initialValues={{ isPermitFormulaBreakdown: true }}
      >
        {companyType === 'BRAND' && !directMode && (
          <Form.Item label="담당자 소속" name="userType" initialValue="BRAND">
            <Radio.Group
              disabled={updateMode}
              onChange={() => {
                form.validateFields(['username']);
              }}
            >
              <Radio value="BRAND">브랜드사</Radio>
              <Radio value="MANUFACTURE">제조사</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {directMode && (
          <Form.Item label="회사" name="companyNameKo">
            <Input disabled />
          </Form.Item>
        )}
        {(!updateMode || directMode) && (
          <Form.Item
            label="아이디"
            name="username"
            required
            rules={[
              {
                validator: async (_, username) => {
                  if (directMode) return;
                  if (!username) {
                    setIsUsernameCheckSuccess(false);
                    throw new Error(messages.REQUIRED_FIELD);
                  }
                  if (username.includes('__')) {
                    setIsUsernameCheckSuccess(false);
                    throw new Error('__는 아이디에 포함할 수 없습니다.');
                  }
                  if (/^\d{10}$/.test(username)) {
                    setIsUsernameCheckSuccess(false);
                    throw new Error(
                      '숫자 10자리는 아이디로 사용할 수 없습니다.',
                    );
                  }
                  try {
                    await authApi.usernameDupCheck({
                      username,
                      userType: form.getFieldValue('userType') || companyType,
                    });
                    setIsUsernameCheckSuccess(true);
                  } catch (e) {
                    setIsUsernameCheckSuccess(false);
                    throw new Error('이미 사용중인 아이디입니다.');
                  }
                },
              },
            ]}
            validateStatus={isUsernameCheckSuccess ? 'success' : undefined}
            help={
              isUsernameCheckSuccess ? '사용 가능한 아이디입니다.' : undefined
            }
          >
            <Input disabled={directMode} />
          </Form.Item>
        )}
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col>
                <Typography.Text type="TITLE_2">비밀번호</Typography.Text>
              </Col>
              {!directMode && (
                <Col>
                  <Typography.Text type="SMALL" style={{ display: 'flex' }}>
                    <InfoIcon style={{ marginRight: 4 }} />
                    {!updateMode
                      ? '초기 비밀번호는 1234로 부여되며 추후 하위 담당자가 직접 변경할 수 있습니다.'
                      : '클릭하시면  비밀번호가 1234로 초기화 됩니다.'}
                  </Typography.Text>
                </Col>
              )}
            </Row>
          }
          name="password"
          initialValue="1234"
          required
        >
          {updateMode ? (
            <>
              <Button onClick={onResetPassword} style={{ width: 250 }}>
                {!directMode ? '비밀번호 초기화' : '비밀번호 변경'}
              </Button>
            </>
          ) : (
            <Input disabled />
          )}
        </Form.Item>
        {companyType === 'BRAND' &&
          form.getFieldValue('userType') === UserType.MANUFACTURE && (
            <Form.Item
              label="제조사명 (국문)"
              name="manufacturerId"
              required
              rules={[requireRule]}
            >
              <Select
                placeholder="제조사 검색"
                showSearch
                filterOption={(keyword, option) =>
                  (option?.label as string).includes(keyword)
                }
                disabled={directMode}
                options={manufacturers.map(
                  ({
                    manufacturerId,
                    companyNameKo,
                  }: {
                    manufacturerId: number;
                    companyNameKo: string;
                  }) => ({
                    label: companyNameKo,
                    value: manufacturerId,
                  }),
                )}
              />
            </Form.Item>
          )}
        {authority === 'SUPER_USER' &&
          companyType === CompanyType.BRAND &&
          form.getFieldValue('userType') !== UserType.MANUFACTURE && (
            <IsPermitFormContainer>
              <Form.Item
                required
                label="전성분표 열람 제한"
                name="isPermitFormulaBreakdown"
                rules={[requireRule]}
                style={{
                  marginBottom: 8,
                }}
              >
                <Radio.Group>
                  <Radio value={true}>열람 가능</Radio>
                  <Radio value={false}>열람 불가능</Radio>
                </Radio.Group>
              </Form.Item>
              <BreakdownAuthorityNotice>
                <Typography.Text type="SMALL" color="SLATE_GRAY70">
                  열람 불가로 설정하실 경우, 해당 담당자가 제품 정보의 Product
                  Formula Breakdown, Product Formula Breakdown (China) 정보를
                  열람할 수 없습니다.
                </Typography.Text>
              </BreakdownAuthorityNotice>
            </IsPermitFormContainer>
          )}
        <Form.Item label="이름" name="name" rules={[requireRule]}>
          <Input />
        </Form.Item>
        <Row gutter={8}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item label="부서명" name="deptName" rules={[requireRule]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item label="직급" name="positionName" rules={[requireRule]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            {companyType === 'MATERIAL' && (
              <Form.Item
                label="회사 직통 번호"
                name="directTelNumber"
                rules={[phoneRule]}
              >
                <Input placeholder={messages.PHONE_FORMAT} />
              </Form.Item>
            )}
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: companyType === 'MATERIAL' ? 12 : 24 }}
          >
            <Form.Item
              label="휴대폰번호"
              name="mobile"
              rules={[requireRule, mobilePhoneRule]}
            >
              <Input placeholder={messages.MOBILE_PHONE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="이메일" name="email" rules={[requireRule, emailRule]}>
          <Input />
        </Form.Item>
        <FooterBox>
          {!directMode && (
            <>
              {!updateMode ? (
                <Button onClick={onCancel}>취소</Button>
              ) : (
                <Button
                  type="default"
                  loading={deleteLoading}
                  onClick={onDelete}
                >
                  담당자 삭제
                </Button>
              )}
            </>
          )}
          <Button type="primary" loading={submitLoading} htmlType="submit">
            {!updateMode ? '등록' : !directMode ? '수정' : '회원정보 수정'}
          </Button>
        </FooterBox>
      </Form>
    </ManagerFormBlock>
  );
};

export default ManagerForm;
