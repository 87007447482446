import { useForm } from 'antd/lib/form/Form';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ArtworkStep2 from 'components/brand/artworkScreening/ArtworkStep2';
import ArtworkStep3 from 'components/brand/artworkScreening/ArtworkStep3';
import ArtworkStep4 from 'components/brand/artworkScreening/ArtworkStep4';
import Step1 from 'components/brand/formulaScreening/Step1';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { ArtworkItem } from 'types/brand/product/artworkScreening';
import palette from 'lib/styles/palette';
import { useFormulaArtworkScreening } from 'service/brand/artworkScreening/artworkScreening';
import { ScreeningFormData } from '../formulaScreening/FormulaScreeningPage';
import { ScreeningStatus } from 'types/brand/artworkScreening/artworkScreening';
import FullLoading from 'components/FullLoading';

const ScreeningNavigator = ({
  steps,
  activeStep,
}: {
  steps: { id: number; title: string }[];
  activeStep: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <NavigatorContainer>
      {steps.map(({ id, title }) => {
        const isActive = activeStep === id;
        const isCheckedStep = activeStep > id;
        return (
          <StepDiv key={id} align="center" gap={8} active={isActive}>
            {!isActive && isCheckedStep && (
              <Icon name="checkCircleHover" size={16} />
            )}
            {!isCheckedStep && <NumIcon active={isActive}>{id}</NumIcon>}
            <StepTitle active={isActive}>{title}</StepTitle>
          </StepDiv>
        );
      })}
    </NavigatorContainer>
  );
};

const ArtworkScreeningPage = () => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [step1Method, setStep1Method] = useState(2);
  const [file, setFile] = useState<File | null>(null);
  const [artworkFiile, setArtworkFiile] = useState<File | null>(null);
  const [
    existedArtworkFile,
    setExistedArtworkFile,
  ] = useState<ArtworkItem | null>(null);
  const [__html, setHTML] = useState('');
  const [, setStep2DisabledMode] = useState(true);
  const [screeningForm] = useForm<ScreeningFormData>();
  const [screeningId, setScreeningId] = useState<number>();

  const {
    uploadFormulaArtworkScreeningJson,
    uploadFormulaArtworkScreeningFile,
    formulaScreeningResult,
    formulaArtworkScreeningLoading,
    formulaScreeningArtworkFile,
    formulaScreeningStatus,
    getFormulaScreeningStatusLoading,
  } = useFormulaArtworkScreening({ formulaScreeningId: screeningId });

  const params = useParams<{
    screeningId: string;
  }>();

  useEffect(() => {
    if (params.screeningId) {
      setScreeningId(Number(params.screeningId));
      setStep1Method(1);
      formulaScreeningArtworkFile(
        { formulaScreeningId: Number(params.screeningId) },
        {
          onSuccess: (res) => {
            const artwork = res.data.result;
            setExistedArtworkFile(artwork);
          },
        },
      );
    }
  }, [params]);

  useEffect(() => {
    if (formulaScreeningResult?.formulaScreeningId) {
      setStep1Method(step1Method === 1 ? 1 : 2);
      setScreeningId(formulaScreeningResult.formulaScreeningId);

      screeningForm.setFieldsValue({
        ingredients: formulaScreeningResult.list.map(
          ({ ingredientName, wt, casNo }) => ({
            name: ingredientName,
            wt,
            casNo,
          }),
        ),
      });
    }
  }, [formulaScreeningResult]);

  useEffect(() => {
    if (
      params.screeningId &&
      (formulaScreeningStatus?.status === ScreeningStatus.ANALYZE ||
        formulaScreeningStatus?.status === ScreeningStatus.COMPLETE)
    ) {
      setActiveStep(4);
    }
  }, [formulaScreeningStatus]);

  useEffect(() => {
    const layout = document.getElementById('app-content-block');
    if (layout) {
      layout.style.paddingBottom = '0';
    }

    return () => {
      if (layout) {
        layout.style.paddingBottom = '80px';
      }
    };
  }, []);

  const steps = [
    {
      id: 1,
      title: '전성분표 입력',
    },
    {
      id: 2,
      title: '전성분표 분석',
    },
    {
      id: 3,
      title: 'Artwork 업로드',
    },
    {
      id: 4,
      title: '스크리닝 분석 결과',
    },
  ];

  const handleFormulaScreeningForArtwork = () => {
    const ingredients: ScreeningFormData['ingredients'] = screeningForm.getFieldValue(
      'ingredients',
    );

    const filteredIngredients = ingredients?.filter(
      ({ name }) => name && name !== null && name !== '',
    );

    const canSubmit =
      step1Method === 1
        ? !isEqual(
            filteredIngredients,
            formulaScreeningResult?.list.map(
              ({ ingredientName, casNo, wt }) => ({
                name: ingredientName,
                casNo,
                wt,
              }),
            ),
          )
        : true;

    if (!canSubmit) return setActiveStep(2);

    if (step1Method === 1) {
      uploadFormulaArtworkScreeningJson(
        {
          ingredients: filteredIngredients.map((item) => ({
            ...item,
            name: item.name.trimEnd(),
            wt: item.wt,
          })),
        },
        {
          onSuccess: () => {
            setActiveStep(2);
          },
        },
      );
    } else {
      if (!file) return;

      uploadFormulaArtworkScreeningFile(
        {
          uploadFile: file,
        },
        {
          onSuccess: (res) => {
            setActiveStep(2);
          },
        },
      );
    }
  };

  if (getFormulaScreeningStatusLoading) return <FullLoading />;

  return (
    <Container>
      <ScreeningNavigator
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      {activeStep === 1 ? (
        <Step1
          isArtworkScreening
          form={screeningForm}
          file={file}
          method={step1Method}
          setMethod={setStep1Method}
          setStep={setActiveStep}
          __html={__html}
          setHTML={setHTML}
          setFile={setFile}
          setStep2DisabledMode={setStep2DisabledMode}
          handleFormulaScreeningForArtwork={handleFormulaScreeningForArtwork}
          formulaArtworkScreeningLoading={formulaArtworkScreeningLoading}
        />
      ) : activeStep === 2 ? (
        <ArtworkStep2
          form={screeningForm}
          file={file}
          method={step1Method}
          setStep={setActiveStep}
          __html={__html}
          setHTML={setHTML}
          setFile={setFile}
          formulaScreeningResult={formulaScreeningResult}
        />
      ) : activeStep === 3 ? (
        <ArtworkStep3
          artworkFile={artworkFiile}
          setArtworkFile={setArtworkFiile}
          form={screeningForm}
          file={file}
          method={step1Method}
          setStep={setActiveStep}
          setHTML={setHTML}
          screeningId={screeningId}
          existedArtworkFile={existedArtworkFile}
          setExistedArtworkFile={setExistedArtworkFile}
        />
      ) : (
        activeStep === 4 &&
        screeningId && (
          <ArtworkStep4
            formulaScreeningId={screeningId}
            setStep={setActiveStep}
          />
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: -58px !important;
  max-width: 100vw !important;
`;

const NavigatorContainer = styled.div`
  width: 216px;
  border-right: 1px solid ${palette.GRAY30};
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  padding: 136px 16px;
`;

const StepDiv = styled(Flex)<{ active: boolean }>`
  padding: 18px 16px;
  border-radius: 4px;
  ${({ active }) => css`
    background-color: ${active ? `${palette.SLATE_GRAY10}` : `transparent`};
  `};
`;

const NumIcon = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) =>
    active ? `${palette.PRIMARY50}` : `${palette.SLATE_GRAY50}`};
  border-radius: 100px;
  font-size: 12px;
  color: white;
`;

const StepTitle = styled(Typography.Text)<{ active: boolean }>`
  font-size: 14px;
  font-weight: ${({ active }) => (active ? `500` : `400`)};
  text-align: center;
  color: ${({ active }) =>
    active ? `${palette.SLATE_GRAY70}` : `${palette.SLATE_GRAY50}`};
`;

export default ArtworkScreeningPage;
