import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as productActions from 'modules/product';
import { useDocumentFieldMap } from 'service/common';

export const useUpdateLog = ({
  productId,
  countryId,
  documentCode,
  validCodes,
  isVisibleLogs,
}: {
  productId: number;
  countryId?: number;
  documentCode: string;
  validCodes?: string[];
  isVisibleLogs: boolean;
}) => {
  const dispatch = useDispatch();
  const { documentFieldMap } = useDocumentFieldMap();
  const { allDocumentLogs, loading } = useSelector(
    ({ product, loading }: any) => ({
      allDocumentLogs: product.documentLogs,
      loading: loading['product/GET_DOCUMENT_LOGS'],
    }),
    shallowEqual,
  );

  const fields = useMemo(() => {
    if (validCodes) {
      return documentFieldMap[documentCode].filter(({ code }: any) =>
        validCodes.includes(code),
      );
    }
    return documentFieldMap[documentCode];
  }, [documentCode, validCodes]);

  const [selectedField, setSelectedField] = useState<{
    documentSeqId: number;
    documentFieldId: number;
  } | null>(null);

  useEffect(() => {
    if (isVisibleLogs && fields.length > 0) {
      dispatch(
        productActions.getDocumentLogs({
          productId,
          countryId,
          documentSeqId: fields[0].documentSeqId,
          documentFieldIds: fields.map((item: any) => item.documentFieldId),
        }),
      );
      return () => {
        dispatch(productActions.initializeDocumentLogs());
      };
    }
  }, [isVisibleLogs]);

  return useMemo(
    () => ({
      fields,
      selectedField,
      setSelectedField,
      allDocumentLogs,
      loading,
    }),
    [fields, selectedField, allDocumentLogs, loading],
  );
};

export const useHasCountry = (codes: string) => {
  const productCountries = useSelector(
    ({ product }: any) => product.currentProduct?.productCountries,
  );
  if (!productCountries) return false;
  const countryCodes = productCountries.map(
    ({ country: { countryCode } }: any) => countryCode,
  );
  if (Array.isArray(codes)) {
    return codes.every((code) => countryCodes.includes(code));
  } else {
    return countryCodes.includes(codes);
  }
};
