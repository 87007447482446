import { useQuery } from 'react-query';

import * as materialCompanyApi from 'lib/api/material/company';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { CompanyStatistics } from 'types/material/company';

export const useMaterialCompanies = (materialCompaniesParams: {
  isExcludeUserInserted: boolean;
  enabled: boolean;
}) => {
  return useQuery(
    ['pub/material-companies', materialCompaniesParams],
    () => materialCompanyApi.getMaterialCompanies(materialCompaniesParams),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
      enabled: materialCompaniesParams.enabled,
    },
  );
};

export const useCompanyStatistics = () => {
  const {
    data: companyStatistics,
    isLoading,
  } = useQuery(
    'material/company-statistics',
    () =>
      client.get<APIResponse<CompanyStatistics>>(
        `/v1/material/company-statistics`,
      ),
    { select: (res) => res.data.result },
  );

  return {
    companyStatistics,
    isLoading,
  };
};
