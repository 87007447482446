import client from 'lib/api/client';
import { createFormData } from 'lib/file';
import { DocumentCode, Document } from 'types/brand/retailer/retailerApply';
import { APIResponse } from 'types/common';

export const getDocuments = ({
  productId,
  documentCode,
}: {
  productId?: number;
  documentCode: DocumentCode;
}) =>
  client.get<APIResponse<Document[]>>(
    `/retailer-apply/documents/${documentCode}${
      productId ? `?productId=${productId}` : ''
    }`,
  );

export const uploadCommonDocuments = (
  documentCode: DocumentCode,
  files: (File | null)[],
  option?: 'F' | 'B',
) =>
  client.post(
    `/retailer-apply/common-documents/${documentCode}`,
    createFormData({ files, ...(option && { option }) }),
  );

export const uploadProductDocuments = (
  productId: number,
  documentCode: DocumentCode,
  {
    files,
    deleteFileIds,
  }: {
    files: File[];
    deleteFileIds: number[];
  },
) =>
  client.post(
    `/retailer-apply/products/${productId}/documents/${documentCode}`,
    createFormData({
      files,
      ...(deleteFileIds.length > 0 && { deleteFileIds }),
    }),
  );
