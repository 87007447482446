import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Upload,
  message,
} from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import isEqual from 'lodash/isEqual';
import { nanoid } from 'nanoid';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import { Tip, Typography } from 'components/system';
import { MinusIcon } from 'components/system/general/icon';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { urlToFile } from 'lib/file';
import { ifBExistAssignToAForObject } from 'lib/form';
import history from 'lib/history';
import palette from 'lib/styles/palette';
import {
  exceptKoreanRule,
  generateFormRules,
  getExeceptKoreanRule,
  requireRule,
} from 'lib/validate';
import {
  RawMaterialDetailUpdateParams,
  useDetailCategoryInfo,
  useMadeCountries,
  useMaterialDetail,
} from 'service/material/rawMaterial';

export enum EOriginType {
  VEGAN = 'VEGAN', // 식물성
  ORGANIC = 'ORGANIC', // 동물성
  BOTH = 'BOTH', // 혼합
  NOTHING = 'NOTHING', // 해당 없음
}

export enum SpecialBrandDocResponseType {
  POSSIBLE = 'POSSIBLE',
  IMPOSSIBLE = 'IMPOSSIBLE',
  NONE = 'NONE',
}

export interface SpecialBrand {
  brandCode: string;
  docResponseType: SpecialBrandDocResponseType;
}

export interface RawMaterialDetailForm {
  originType: string;
  effectiveIngredients?: {
    ingredientName: string;
    ingredientMinContent: string;
    ingredientUnit: string;
  }[];
  combinationWarningText?: string;
  materialPatents?: {
    patentNumber: string;
  }[];
  madeCountry?: string;
  hsCode?: string;
  manageCode?: string;
  hasSpecialBrand?: boolean;
  specialBrands?: {
    brandCode: string;
    docResponseType: string;
  };
  materialRemarks?: number[];
  etcRemark?: string;
  haveFilesChanged?: boolean;
}

const EnhancedRadioGroup = styled(Radio.Group)`
  padding: 16px 24px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  height: 56px;
  color: ${palette.SLATE_GRAY70};

  .ant-radio-wrapper,
  .ant-col {
    height: 24px;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }
`;

const CheckboxBlock = styled.div``;

const RawMaterialDetailBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;

  .ant-select-arrow {
    color: ${palette.GRAY50};
  }
`;

const HorizontalFormItem = styled(Form.Item)`
  flex-direction: row !important;

  .ant-form-item-label {
    padding-top: 8px;
    margin-right: 16px;
  }
`;

const originTypeOptions = [
  { label: '식물성 추출물 포함', value: EOriginType.VEGAN },
  { label: '동물성 추출물 포함', value: EOriginType.ORGANIC },
  { label: '식물성·동물성 혼합', value: EOriginType.BOTH },
  { label: '해당 없음', value: EOriginType.NOTHING },
];

const specialBrandDocResponseTypeOptions = [
  {
    label: '가능',
    value: SpecialBrandDocResponseType.POSSIBLE,
  },
  {
    label: '불가능',
    value: SpecialBrandDocResponseType.IMPOSSIBLE,
  },
  {
    label: '모름',
    value: SpecialBrandDocResponseType.NONE,
  },
];

const specialBrandsInitialValue = [
  { brandCode: undefined, docResponseType: undefined },
];

const IngredientListItem = ({
  fieldName,
  disabled,
  onDelete,
}: {
  fieldName: number;
  disabled?: boolean;
  onDelete: React.MouseEventHandler;
}) => (
  <Row gutter={8} wrap={false} align="middle">
    <Col span={12}>
      <Form.Item
        name={[fieldName, 'ingredientName']}
        required
        rules={[requireRule]}
      >
        <Input placeholder="유효 성분명 입력" maxLength={30} />
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item
        name={[fieldName, 'ingredientMinContent']}
        required
        rules={[
          requireRule,
          {
            pattern: /^(([1-9][0-9]*)|(0(?=\.)))(\.\d{1,10})?$/,
            message: '양수 입력(소수점 가능)',
          },
        ]}
      >
        <Input placeholder="성분 (최소)함량" maxLength={10} />
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item
        name={[fieldName, 'ingredientUnit']}
        required
        rules={[
          requireRule,
          {
            pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣0-9]*$/i,
            message: '영문, 기호 입력가능',
          },
        ]}
      >
        <Input placeholder="단위" maxLength={10} />
      </Form.Item>
    </Col>
    <Col>
      {!disabled && (
        <MinusIcon
          style={{
            cursor: 'pointer',
            position: 'relative',
            top: -6,
          }}
          onClick={onDelete}
        />
      )}
    </Col>
  </Row>
);

const PatentListItem = ({
  fieldName,
  onDelete,
  disabled,
  form,
}: {
  fieldName: number;
  onDelete?: VoidFunction;
  disabled?: boolean;
  form?: FormInstance<RawMaterialDetailForm>;
}) => (
  <Row align="middle" wrap={false} gutter={8}>
    <Col flex="0 0 100%">
      <Form.Item
        name={[fieldName, 'patentNumber']}
        rules={[
          requireRule,
          exceptKoreanRule,
          {
            validator: async (_, value) => {
              if (!form) return;

              if (
                value &&
                form
                  .getFieldValue('materialPatents')
                  .filter(
                    (patent: { patentNumber: string }) =>
                      patent.patentNumber === value,
                  ).length >= 2
              ) {
                throw new Error('중복된 특허 등록 번호');
              }
            },
          },
        ]}
      >
        <Input
          placeholder="원료의 특허 등록 번호를 입력해주세요"
          disabled={disabled}
          maxLength={17}
        />
      </Form.Item>
    </Col>
    <Col>
      {!disabled && (
        <MinusIcon
          style={{
            cursor: 'pointer',
            position: 'relative',
            top: -6,
          }}
          onClick={onDelete}
        />
      )}
    </Col>
  </Row>
);

const SpecialBrandListItem = ({
  fieldName,
  brandOptions = [],
  selectedBrandOption,
  disabled,
  onRemove,
}: {
  fieldName: number;
  brandOptions?: { label: string; value: string }[];
  selectedBrandOption?: { label: string; value: string };
  disabled?: boolean;
  onRemove: VoidFunction;
}) => (
  <Row gutter={8} style={{ position: 'relative' }}>
    <Col span={16}>
      <Form.Item name={[fieldName, 'brandCode']} rules={[requireRule]}>
        <Select
          style={{ width: '100%' }}
          placeholder="특수 브랜드 선택"
          options={
            selectedBrandOption
              ? [selectedBrandOption, ...brandOptions]
              : brandOptions
          }
        />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item name={[fieldName, 'docResponseType']} rules={[requireRule]}>
        <Select
          placeholder="서류 대응 여부"
          style={{ width: '100%' }}
          options={specialBrandDocResponseTypeOptions}
        />
      </Form.Item>
    </Col>
    {fieldName !== 0 && !disabled && (
      <MinusIcon
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 10,
          right: -26,
        }}
        onClick={onRemove}
      />
    )}
  </Row>
);

const RawMaterialDetailUI = ({
  form,
  disabled,
  countryOptions = [],
  specialBrandOptions = [],
  remarks = [],
  cta,
  defaultThesis,
  onSubmit,
  submitting,
}: {
  form?: FormInstance<RawMaterialDetailForm>;
  disabled?: boolean;
  countryOptions?: { label: string; value: string }[];
  specialBrandOptions?: { label: string; value: string }[];
  remarks?: { label: string; value: number }[];
  cta: '저장' | '수정';
  defaultThesis?: File[];
  onSubmit?: (values: RawMaterialDetailForm, thesis: File[]) => void;
  submitting?: boolean;
}) => {
  const [thesis, setThesis] = useState<File[]>([]);

  const handleIngredientDelete = (
    remove: (index: number) => void,
    fieldName: number,
  ) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    remove(fieldName);
  };

  const handleIngredientAdd = (
    fieldsLength: number,
    add: (defaultValue?: {}) => void,
  ) => () => {
    if (fieldsLength + 1 > 10) {
      return message.warning('최대 10개까지 입력가능');
    }
    add({});
  };

  const handlePatentDelete = (
    remove: (index: number) => void,
    index: number,
  ) => () => {
    remove(index);
  };

  const handlePatentAdd = (add: (defaultValue?: string) => void) => () => {
    add('');
  };

  const handleThesisUpload = (file: File) => {
    setThesis((prevThesis) => prevThesis.concat(file));
    form?.setFieldsValue({ haveFilesChanged: true }); // 폼 데이터 변경 체크용
    return false;
  };

  const handleThesisDelete = (targetIndex: number) => () => {
    if (disabled) return;
    setThesis((prevThesis) =>
      prevThesis.filter((_, index) => index !== targetIndex),
    );
    form?.setFieldsValue({
      haveFilesChanged: true,
    }); // 폼 데이터 변경 체크용
  };

  const handleBrandRemove = (
    targetIndex: number,
    remove: (index: number) => void,
  ) => () => {
    if (disabled) return;

    remove(targetIndex);
  };

  const handleBrandAdd = (add: (args: {}) => void) => () => {
    add({});
  };

  const handleSubmit = (values: RawMaterialDetailForm) => {
    onSubmit?.(values, thesis);
  };

  useEffect(() => {
    if (!defaultThesis) return;
    setThesis(defaultThesis);
  }, [defaultThesis]);

  return (
    <RawMaterialDetailBlock>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError={scrollToFirstErrorOption}
      >
        <Form.Item name="haveFilesChanged" noStyle></Form.Item>
        <Form.Item
          label="추출물 유래 선택"
          name="originType"
          rules={[requireRule]}
        >
          <Select
            placeholder="추출물 유래 선택"
            options={originTypeOptions}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          style={{ zIndex: 2 }}
          label={
            <>
              유효 성분
              <Tip
                style={{
                  marginLeft: 4,
                  zIndex: 1,
                }}
              >
                <Typography.Text type="BODY_2">
                  ex. 플라보노이드를 최소 200ppm 이상 함유하고 있는 원료
                  <br />
                  유효 성분명 : 플라보노이드(Flavonoid) / 성분 (최소)함량 : 200
                  / 단위 : ppm
                </Typography.Text>
              </Tip>
            </>
          }
        >
          <Form.List name="effectiveIngredients">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <IngredientListItem
                    key={field.name}
                    fieldName={field.name}
                    disabled={disabled}
                    onDelete={handleIngredientDelete(remove, field.name)}
                  />
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={handleIngredientAdd(fields.length, add)}
                    block
                    icon={
                      <Icon
                        name="plus"
                        color={disabled ? 'SLATE_GRAY60' : 'PRIMARY50'}
                        size={14}
                        style={{ marginRight: 4 }}
                      />
                    }
                    disabled={disabled}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    유효 성분 추가
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="배합시 주의사항"
          name="combinationWarningText"
          style={{ marginTop: -16 }}
          rules={generateFormRules({
            minLength: 0,
            maxLength: 100,
          })}
        >
          <Input.TextArea
            placeholder={
              '아래 예시를 참고하여 입력해 주세요.\n- 30 ~ 40℃로 온도를 유지하며 후첨해 주세요.\n- 믹서의 속도를 200rpm 이하에서 혼합해 주세요.\n- 용해 단계에서 반드시 수상과 함께 용해해 주세요.'
            }
            autoSize={{ minRows: 4, maxRows: 4 }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label="특허 등록 번호">
          <Form.List name="materialPatents" initialValue={[]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <PatentListItem
                    key={field.key}
                    fieldName={field.name}
                    disabled={disabled}
                    form={form}
                    onDelete={handlePatentDelete(remove, field.name)}
                  />
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={handlePatentAdd(add)}
                    block
                    icon={
                      <Icon
                        name="plus"
                        color={disabled ? 'SLATE_GRAY60' : 'PRIMARY50'}
                        size={14}
                        style={{ marginRight: 4 }}
                      />
                    }
                    disabled={disabled}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    특허 등록 번호 추가
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="관련 논문 파일">
          <Row align="middle" gutter={8}>
            <Col>
              <Upload
                multiple
                beforeUpload={handleThesisUpload}
                itemRender={() => null}
              >
                <Button style={{ width: 120 }} disabled={disabled}>
                  파일 선택
                </Button>
              </Upload>
            </Col>
            {thesis.map((thesisFile, index) => (
              <Col key={nanoid()}>
                <Tags.Mark
                  color="blue"
                  closable={!disabled}
                  onClick={handleThesisDelete(index)}
                >
                  {thesisFile.name}
                </Tags.Mark>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <HorizontalFormItem label="Made in" name="madeCountry">
          <Select
            showSearch
            disabled={disabled}
            placeholder="Country (국가 입력)"
            options={countryOptions}
          />
        </HorizontalFormItem>
        <Row gutter={8} align="top">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="원료 HS Code"
              name="hsCode"
              rules={[
                getExeceptKoreanRule({
                  start: 6,
                  end: 10,
                  message: '한글 입력 불가, 6~10자리',
                }),
              ]}
            >
              <Input disabled={disabled} maxLength={12} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item label="원료사 내부 관리 코드" name="manageCode">
              <Input disabled={disabled} maxLength={15} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="납품 가능한 특수 브랜드" name="hasSpecialBrand">
          <EnhancedRadioGroup disabled={disabled}>
            <Row
              justify="space-around"
              align="middle"
              style={{ width: '100%' }}
            >
              <Col>
                <Radio value={false}>없음</Radio>
              </Col>
              <Col>
                <Radio value={true}>있음</Radio>
              </Col>
            </Row>
          </EnhancedRadioGroup>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.hasSpecialBrand !== next.hasSpecialBrand ||
            prev.specialBrands !== next.specialBrands
          }
        >
          {({ getFieldValue }) => {
            if (!getFieldValue('hasSpecialBrand')) return null;

            const specialBrands: SpecialBrand[] = getFieldValue(
              'specialBrands',
            ) || [{}];
            const filteredSpecialBrandOptions = specialBrandOptions.filter(
              ({ value }) =>
                specialBrands.every(({ brandCode }) => brandCode !== value),
            );

            return (
              <Form.Item
                label="해당 원료의 특수 브랜드 서류 대응 여부"
                required
              >
                <Form.List
                  name="specialBrands"
                  initialValue={specialBrandsInitialValue}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => {
                        const selectedBrandOption = specialBrandOptions.find(
                          ({ value: brandCode }) =>
                            brandCode === specialBrands[field.name].brandCode,
                        );
                        return (
                          <SpecialBrandListItem
                            key={field.key}
                            fieldName={field.name}
                            onRemove={handleBrandRemove(field.name, remove)}
                            brandOptions={filteredSpecialBrandOptions}
                            selectedBrandOption={selectedBrandOption}
                            disabled={disabled}
                          />
                        );
                      })}
                      {
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={handleBrandAdd(add)}
                            block
                            icon={
                              <Icon
                                name="plus"
                                color={
                                  specialBrandOptions.length === 0 || disabled
                                    ? 'SLATE_GRAY60'
                                    : 'PRIMARY50'
                                }
                                size={14}
                                style={{ marginRight: 4 }}
                              />
                            }
                            disabled={
                              specialBrandOptions.length === 0 || disabled
                            }
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            특수 브랜드 추가
                          </Button>
                        </Form.Item>
                      }
                    </>
                  )}
                </Form.List>
              </Form.Item>
            );
          }}
        </Form.Item>
        <CheckboxBlock>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              next.materialRemarks !== prev.mmaterialRemarks
            }
          >
            {({ getFieldValue }) => (
              <Form.Item label="원료 특이사항" name="materialRemarks">
                <Checkbox.Group>
                  <Row style={{ rowGap: 20 }}>
                    {remarks.map((remark) =>
                      remark.label !== '기타' ? (
                        <Col span={8} key={remark.value}>
                          <Checkbox value={remark.value}>
                            <Typography.Text inline>
                              {remark.label}
                            </Typography.Text>
                          </Checkbox>
                        </Col>
                      ) : (
                        <Col
                          key={remark.value}
                          span={16}
                          style={{
                            display: 'flex',
                            alignItems: 'start',
                            height: 24,
                          }}
                        >
                          <Checkbox value={remark.value}>
                            <Typography.Text inline>기타</Typography.Text>
                          </Checkbox>
                          {getFieldValue('materialRemarks')?.includes(
                            remarks.find(({ label }) => label === '기타')
                              ?.value,
                          ) && (
                            <Form.Item style={{ margin: 0 }} name="etcRemark">
                              <Input
                                style={{
                                  width: 280,
                                  height: 32,
                                  transform: 'translateY(-8px)',
                                }}
                                placeholder="쉼표로 구분하여 입력"
                              />
                            </Form.Item>
                          )}
                        </Col>
                      ),
                    )}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            )}
          </Form.Item>
        </CheckboxBlock>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {cta}
          </Button>
        </FooterBox>
      </Form>
    </RawMaterialDetailBlock>
  );
};

export const RawMaterialDetail = ({ materialId }: { materialId: number }) => {
  const {
    detailCategoryInfo,
    isLoading: isDetailCategoryInfoLoading,
  } = useDetailCategoryInfo();
  const {
    createMaterialDetail,
    isCreateMaterialDetailLoading,
    updateMaterialDetail,
    isUpdateMaterialDetailLoading,
    materialDetail,
    isMaterialDetailLoading,
  } = useMaterialDetail(materialId);
  const {
    madeCountries,
    getLoading: getMadeCountriesLoading,
  } = useMadeCountries();
  const [form] = useForm();
  const [initFormData, setInitFormData] = useState<
    Partial<RawMaterialDetailForm> | undefined
  >();
  const [initTheses, setInitThese] = useState<File[]>([]);
  const updateMode = materialDetail?.materialDetailId !== undefined;

  const { specialBrandOptions, remarks } = useMemo(() => {
    return {
      specialBrandOptions: detailCategoryInfo.specialBrandTypes.map((type) => ({
        label: type.name,
        value: type.code,
      })),
      remarks: detailCategoryInfo.remarkCategories.map((remark) => ({
        label: remark.nameKo,
        value: remark.materialCategoryId,
      })),
    };
  }, [detailCategoryInfo]);

  const countryOptions = useMemo(
    () =>
      madeCountries.map((country) => ({
        label: country.nameEn,
        value: country.nameEn,
      })),
    [madeCountries],
  );

  const handleSubmit: ComponentProps<typeof RawMaterialDetailUI>['onSubmit'] = (
    values,
    files,
  ) => {
    const params: RawMaterialDetailUpdateParams = {
      ...values,
      materialId,
      isExperienceSpecialBrandDelivery: values.hasSpecialBrand,
      materialTheses: files.map((file) => ({
        uploadFile: file,
      })),
      materialRemarks: [],
    };

    const etcCategory = detailCategoryInfo.remarkCategories.find(
      (remarkCategory) => remarkCategory.nameKo === '기타',
    );

    const materialRemarks: {
      materialCategoryId: number;
      etcRemark: string;
    }[] = [];

    if (values.materialRemarks) {
      for (const materialCategoryId of values.materialRemarks) {
        materialRemarks.push({
          materialCategoryId: materialCategoryId,
          etcRemark:
            materialCategoryId === etcCategory?.materialCategoryId
              ? values.etcRemark || ''
              : '',
        });
      }
    }

    params.materialRemarks = materialRemarks;

    const handleSuccess = () => {
      message.success('저장되었습니다.');
      history.goBack();
    };

    if (updateMode) {
      if (values.materialPatents?.length === 0)
        params.deleteMaterialPatents = true;
      if (materialRemarks.length === 0) params.deleteMaterialRemarks = true;
      if (files.length === 0) params.deleteMaterialTheses = true;
      if (values.effectiveIngredients?.length === 0)
        params.deleteEffectiveIngredients = true;

      if (isEqual(values, initFormData) && !values.haveFilesChanged) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }
      // 변경된 값이 있는지 체킹 필요
      updateMaterialDetail(params as RawMaterialDetailUpdateParams, {
        onSuccess: handleSuccess,
      });
    } else {
      createMaterialDetail(params, {
        onSuccess: handleSuccess,
      });
    }
  };

  useEffect(() => {
    if (!materialDetail) return;

    const newFieldsValue: Partial<RawMaterialDetailForm> = {
      haveFilesChanged: undefined,
    };

    // 단순 복제
    ifBExistAssignToAForObject(newFieldsValue, materialDetail, [
      ['originType', 'originType'],
      ['hsCode', 'hsCode'],
      ['manageCode', 'manageCode'],
      ['hasSpecialBrand', 'isExperienceSpecialBrandDelivery'],
      ['madeCountry', 'madeCountry'],
      ['combinationWarningText', 'combinationWarningText'],
      ['materialPatents', 'materialPatents'],
      ['specialBrands', 'specialBrands'],
      ['effectiveIngredients', 'effectiveIngredients'],
    ]);

    // 원료 특이사항
    newFieldsValue.materialRemarks = materialDetail.materialRemarks.map(
      (remark) => remark.materialCategoryId,
    );

    const etcCategory = detailCategoryInfo.remarkCategories.find(
      (remarkCategory) => remarkCategory.nameKo === '기타',
    );
    const etcRemark = materialDetail.materialRemarks.find(
      (remark) => remark.materialCategoryId === etcCategory?.materialCategoryId,
    );

    if (etcRemark) {
      newFieldsValue.etcRemark = etcRemark.etcRemark;
    }

    form.setFieldsValue(newFieldsValue);
    setTimeout(() => {
      setInitFormData(form.getFieldsValue());
    }, 0);
  }, [materialDetail]);

  useEffect(() => {
    if (!materialDetail?.materialTheses) return;

    (async () => {
      const files: File[] = [];
      for (const thesis of materialDetail.materialTheses) {
        files.push(
          await urlToFile(thesis.attachUrl, thesis.fileName, {
            useOldS3Path: true,
          }),
        );
      }
      setInitThese(files);
    })();
  }, [materialDetail]);

  const loading =
    isDetailCategoryInfoLoading ||
    getMadeCountriesLoading ||
    isMaterialDetailLoading;
  const submitting =
    isCreateMaterialDetailLoading || isUpdateMaterialDetailLoading;
  if (loading) return <FullLoading />;

  return (
    <RawMaterialDetailUI
      form={form}
      specialBrandOptions={specialBrandOptions}
      countryOptions={countryOptions}
      remarks={remarks}
      defaultThesis={initTheses}
      onSubmit={handleSubmit}
      submitting={submitting}
      cta="저장"
    />
  );
};

export default RawMaterialDetailUI;
