import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import * as companyApi from 'lib/api/company';
import { CompanyType } from 'types/company';

export const useCompany = () =>
  useSelector(({ company }: any) => company.company);

export const useCompanyType = () =>
  useSelector(({ company }: any) => {
    if (!company.company || company.company.companyType === CompanyType.BRAND) {
      return CompanyType.BRAND;
    } else if (company.company.companyType === CompanyType.MANUFACTURE) {
      return CompanyType.MANUFACTURE;
    }
    return CompanyType.MATERIAL;
  });

export const useManufacturerBizLicenseEnUrl = (productId?: number) => {
  const {
    data: manufacturerBizLicenseEnUrl = null,
    isFetching: getLoading,
  } = useQuery(
    ['getManufacturerBizLicenseEnUrl', productId],
    () => companyApi.getManufacturerBizLicenseEnUrl(productId!),
    {
      select: (res) => res.data.result,
      enabled: productId !== undefined,
    },
  );
  return useMemo(
    () => ({
      manufacturerBizLicenseEnUrl,
      getLoading,
    }),
    [manufacturerBizLicenseEnUrl, getLoading],
  );
};
