import { checkMobile } from 'lib/common';
import { createGlobalStyle, css } from 'styled-components';
import palette from './palette';

const isMobile = checkMobile();

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    min-height: 100%;
    overscroll-behavior: none;
    touch-action: pan-x pan-y;
    color: ${palette.GRAY90};
    -webkit-text-size-adjust : none;
    -ms-text-size-adjust : none;
    -moz-text-size-adjust : none;
    -o-text-size-adjust : none;
  }

  #root {
    min-height: 100%;
  }

  a {
    text-decoration: none;
  }

  pre {
    margin-bottom: 0 !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  input::-webkit-calendar-picker-indicator {
    opacity: 100;
  }


  ${
    isMobile &&
    css`
      .ant-modal {
        top: 20px;
      }
    `
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }

  .ant-select-arrow {
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.41073 6.91083C4.73617 6.58539 5.26381 6.58539 5.58925 6.91083L9.99999 11.3216L14.4107 6.91083C14.7362 6.58539 15.2638 6.58539 15.5892 6.91083C15.9147 7.23626 15.9147 7.7639 15.5892 8.08934L10.5892 13.0893C10.2638 13.4148 9.73617 13.4148 9.41073 13.0893L4.41073 8.08934C4.0853 7.7639 4.0853 7.23626 4.41073 6.91083Z' fill='%23A9A6A6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    svg {
      display: none;
    }
    right: 16px !important;

    
  }

  .ant-select-arrow.ant-select-arrow-loading {
    background: none;
    svg {
        display: block;
    }
  }

`;

export default GlobalStyle;
