import { Button, Form, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ContractAccount from 'components/contract/ContractAccount';
import ContractDocuments from 'components/contract/ContractDocuments';
import { Typography } from 'components/system';
import CheckBoxCard from 'components/system/data/CheckBoxCard';
import NoticeCard from 'components/system/data/NoticeCard';
import { useModal } from 'hook/useModal';
import * as estimateAction from 'modules/estimate';
import { CompanyType } from 'types/company';

const EstimateContractBlock = styled.div``;

const EstimateContract = () => {
  const dispatch = useDispatch();
  const { estimate, company, submitLoading } = useSelector(
    ({ estimate, company, loading }: any) => ({
      estimate: estimate.estimate,
      company: company.company,
      submitLoading: loading['estimate/CONTRACT'],
    }),
    shallowEqual,
  );
  const [address, setAddress] = useState(company.addressKo);
  const [isChecked, setIsChecked] = useState(false);
  const { openAlertModal } = useModal();
  const handleContract = () => {
    openAlertModal({
      content: (
        <Typography.Text type="BODY_2" align="center">
          '계약 체결' 하시면 인증신청번호가 부여되고,
          <br />
          입금 및 계약서 수령단계로 넘어갑니다.
          <br />
          진행하시겠습니까?
        </Typography.Text>
      ),
      okText: '계약 체결',
      onOk: () => {
        dispatch(
          estimateAction.contract({
            estimateId: estimate.estimateId,
            address,
            isAgreeContract: isChecked,
          }),
        );
      },
    });
  };
  const onChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };
  return (
    <EstimateContractBlock>
      <ContractDocuments />
      <ContractAccount />
      <NoticeCard
        title="계약서 받으실 주소"
        titleStyle={{ marginBottom: 16 }}
        style={{ marginTop: 24 }}
        bodyStyle={{ paddingLeft: 24 }}
      >
        <ul>
          <li>
            <Typography.Text type="BODY_2">
              송부해 주실 계약서 2부 중 1부를 certicos에서 확인하고 날인한 뒤,
              귀사로 다시 송부해 드릴 예정이오니 계약서 받으실 주소를 입력해
              주세요.
            </Typography.Text>
          </li>
          <li>
            <Typography.Text type="BODY_2">
              가입 시 입력한 사업장 소재지 주소가 기본으로 입력되어 있으니, 다른
              곳으로 받으실 경우 하단의 주소를 수정한 후 ‘계약 체결’ 버튼을
              눌러주세요.
            </Typography.Text>
          </li>
          <Form.Item
            colon={false}
            label={<Typography.Text type="TITLE_2">주소</Typography.Text>}
            style={{
              marginTop: 16,
              marginBottom: 8,
              position: 'relative',
              left: -12,
            }}
          >
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
        </ul>
      </NoticeCard>
      <CheckBoxCard companyType={CompanyType.BRAND} onChange={onChange} />
      <FooterBox>
        <Button
          key="contract"
          type="primary"
          size="large"
          loading={submitLoading}
          onClick={handleContract}
          disabled={!isChecked}
        >
          계약 체결
        </Button>
      </FooterBox>
    </EstimateContractBlock>
  );
};

export default EstimateContract;
