import { Form, Checkbox, Select } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { generateFormRules } from 'lib/validate';
import { viscosityUnitOptions } from 'lib/selectOption';
import SelectOptionInput from 'components/ui/Select/SelectOptionInput';
import InputNumber from 'components/ui/InputNumber';

const ViscosityForm = ({
  isOnlyCN,
  readOnlyMode,
  onChangeViscosityRadioGroup,
}: {
  isOnlyCN: boolean;
  readOnlyMode: boolean;
  onChangeViscosityRadioGroup: (notApplicableViscosity: boolean) => void;
}) => {
  return (
    <>
      <S.StyledLabelSpaceBetween>
        <S.StyledLabel isOnlyCN={isOnlyCN}>Viscosity</S.StyledLabel>
        <Form.Item
          name="notApplicableViscosity"
          initialValue={false}
          valuePropName="checked"
          noStyle
        >
          <Checkbox
            disabled={readOnlyMode}
            onChange={(e) => onChangeViscosityRadioGroup(e.target.checked)}
          >
            <S.CheckBoxLabel>해당 없음</S.CheckBoxLabel>
          </Checkbox>
        </Form.Item>
      </S.StyledLabelSpaceBetween>
      <S.StyledRow>
        <S.StyledColumn style={{ marginRight: '30px' }}>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.notApplicableViscosity !== next.notApplicableViscosity ||
              prev.viscosityLimitMin !== next.viscosityLimitMin ||
              prev.viscosityLimitMax !== next.viscosityLimitMax
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const notApplicableViscosity = getFieldValue(
                'notApplicableViscosity',
              );
              const viscosityLimitMin = getFieldValue('viscosityLimitMin');
              const viscosityLimitMax = getFieldValue('viscosityLimitMax');

              /* HINT : Form의 validation이 reject된 상태에서 disabled가 먹히지 않으므로 
                      Form.Item 렌더링을 disabled된 컴포넌트와 아닌 컴포넌트로 분기 처리 */

              return (
                <S.StyledRow>
                  <S.StyledColumn>
                    <Typography.Text gutter={{ right: 4, bottom: 8 }}>
                      정상 범위
                    </Typography.Text>
                    <S.StyledRow>
                      {!notApplicableViscosity ? (
                        <>
                          <S.AntdExplainWrapper>
                            <Form.Item
                              dependencies={['viscosityLimitMax']}
                              name="viscosityLimitMin"
                              rules={[
                                ...generateFormRules({
                                  required: !(
                                    isOnlyCN || notApplicableViscosity
                                  ),
                                }),
                                {
                                  validator: (_, viscosityLimitMinValue) => {
                                    if (
                                      viscosityLimitMinValue > viscosityLimitMax
                                    ) {
                                      return Promise.reject(
                                        '좌측 값은 우측 값보다 클 수 없음',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                maxLength={8}
                                disabled={readOnlyMode}
                                step={100}
                                min={0}
                                formatter={(value) => {
                                  if (value) {
                                    return Number(value).toLocaleString();
                                  }
                                  return value as any;
                                }}
                                style={{ width: 160 }}
                                inputwidth={82}
                              />
                            </Form.Item>
                          </S.AntdExplainWrapper>
                          <Typography.Text
                            style={{
                              display: 'inline-block',
                              width: '16px',
                              textAlign: 'center',
                            }}
                          >
                            ~
                          </Typography.Text>
                          <Form.Item
                            name="viscosityLimitMax"
                            rules={generateFormRules({
                              required: !(isOnlyCN || notApplicableViscosity),
                            })}
                          >
                            <InputNumber
                              maxLength={8}
                              step={100}
                              min={0}
                              disabled={readOnlyMode}
                              formatter={(value) => {
                                if (value) {
                                  return Number(value).toLocaleString();
                                }
                                return value as any;
                              }}
                              style={{ width: 160 }}
                              inputwidth={82}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item>
                            <InputNumber disabled style={{ width: 160 }} />
                          </Form.Item>
                          <Typography.Text
                            style={{
                              display: 'inline-block',
                              width: '16px',
                              textAlign: 'center',
                            }}
                          >
                            ~
                          </Typography.Text>
                          <Form.Item>
                            <InputNumber disabled style={{ width: 160 }} />
                          </Form.Item>
                        </>
                      )}
                    </S.StyledRow>
                  </S.StyledColumn>

                  <S.StyledColumn style={{ marginLeft: '16px' }}>
                    <Typography.Text gutter={{ bottom: 8 }}>
                      수치
                    </Typography.Text>
                    <Form.Item
                      shouldUpdate={(prev, next) =>
                        prev.viscosityUnit !== next.viscosityUnit
                      }
                      noStyle
                    >
                      {({ getFieldValue, setFieldsValue }) => {
                        return (
                          <S.StyledRow style={{ gap: 6 }}>
                            {!notApplicableViscosity ? (
                              <Form.Item
                                name="viscosity"
                                required={!(isOnlyCN || notApplicableViscosity)}
                                dependencies={[
                                  'viscosityLimitMin',
                                  'viscosityLimitMax',
                                ]}
                                rules={[
                                  ...generateFormRules({
                                    required: !(
                                      isOnlyCN || notApplicableViscosity
                                    ),
                                  }),

                                  !(isOnlyCN || notApplicableViscosity)
                                    ? {
                                        validator: (_, viscosity) => {
                                          if (
                                            viscosity < viscosityLimitMin ||
                                            viscosity > viscosityLimitMax
                                          ) {
                                            return Promise.reject(
                                              '정상 범위를 벗어난 수치',
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      }
                                    : {
                                        validator: (_, viscosity) => {
                                          if (
                                            viscosity !== '' &&
                                            viscosity !== null &&
                                            (viscosity < viscosityLimitMin ||
                                              viscosity > viscosityLimitMax) &&
                                            viscosityLimitMax !== null &&
                                            viscosityLimitMin !== null
                                          ) {
                                            return Promise.reject(
                                              '정상 범위를 벗어난 수치',
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                ]}
                              >
                                <InputNumber
                                  disabled={readOnlyMode}
                                  maxLength={8}
                                  step={100}
                                  formatter={(value) => {
                                    if (value) {
                                      return Number(value).toLocaleString();
                                    }
                                    return value as any;
                                  }}
                                  style={{ width: 160 }}
                                  inputwidth={82}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item noStyle>
                                <InputNumber disabled style={{ width: 160 }} />
                              </Form.Item>
                            )}

                            {!notApplicableViscosity ? (
                              <SelectOptionInput
                                parentFormName="viscosityUnit"
                                rules={generateFormRules({
                                  required: !(
                                    isOnlyCN || notApplicableViscosity
                                  ),
                                })}
                                selectPlaceholder="단위선택"
                                onSelect={() =>
                                  setFieldsValue({
                                    viscosityUnitDirect: null,
                                  })
                                }
                                selectDisabled={
                                  readOnlyMode ||
                                  notApplicableViscosity === 'viscosityNo'
                                }
                                isOthers={
                                  getFieldValue('viscosityUnit') === 'others' &&
                                  !notApplicableViscosity
                                }
                                inputFormName={
                                  getFieldValue('viscosityUnit') === 'others'
                                    ? 'viscosityUnitDirect'
                                    : 'viscosityUnit'
                                }
                                inputDisabled={readOnlyMode}
                                onInputChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) =>
                                  setFieldsValue({
                                    viscosityUnitDirect: e.target.value,
                                  })
                                }
                                unitDirectValue={getFieldValue(
                                  'viscosityUnitDirect',
                                )}
                              >
                                {viscosityUnitOptions.map((option) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </Select.Option>
                                ))}
                                <Select.Option key="others" value="others">
                                  직접입력
                                </Select.Option>
                              </SelectOptionInput>
                            ) : (
                              <Form.Item>
                                <Select
                                  disabled
                                  style={{
                                    width: 160,
                                    position: 'relative',
                                    left: '14%',
                                    margin: -10,
                                  }}
                                />
                              </Form.Item>
                            )}
                          </S.StyledRow>
                        );
                      }}
                    </Form.Item>
                  </S.StyledColumn>
                </S.StyledRow>
              );
            }}
          </Form.Item>
        </S.StyledColumn>
      </S.StyledRow>
    </>
  );
};

export default ViscosityForm;
