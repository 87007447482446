import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';

const ThirtyCOSAddressBlock = styled.div`
  margin-top: 24px;
`;

const ThirtyCOSAddressBody = styled(Flex)`
  padding: 20px 0;
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};
  text-align: center;
`;

const ThirtyCOSAddress = () => {
  return (
    <ThirtyCOSAddressBlock>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
        계약서 보내실 주소 (CDRI 서울지사)
      </Typography.Text>
      <ThirtyCOSAddressBody justify="center">
        <Typography.Text
          type="TITLE_1"
          medium
          color="SLATE_GRAY70"
          style={{ fontSize: 16 }}
        >
          서울 강남구 논현로 417 화원빌딩 가든층 A (06246)
        </Typography.Text>
      </ThirtyCOSAddressBody>
    </ThirtyCOSAddressBlock>
  );
};

export default ThirtyCOSAddress;
