import { notification, Typography } from 'antd';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export const useDetectNewVersion = () => {
  const location = useLocation();
  const oldModified = useRef(null);

  useEffect(() => {
    const fetchIndex = async () => {
      const {
        headers: { 'last-modified': lastModified },
      } = await axios.head('/');

      // HINT : cloudfront 환경이 아닐 경우
      if (!lastModified) return;

      // HINT : 처음으로 lastModified를 가져온 경우라면 저장만 하고 return;
      if (oldModified.current === null) {
        oldModified.current = lastModified;
        return;
      }

      // HINT : 기존 modified와 새로운 modified가 다르다면 메세지 노출
      if (oldModified.current !== lastModified) {
        oldModified.current = lastModified;
        notification.open({
          message: '최신 버전이 릴리즈 되었습니다.',
          icon: null,
          description: (
            <Typography.Text>
              원활한 서비스를 위해{' '}
              <Typography.Link
                underline
                onClick={() => {
                  window.location.href = location.pathname;
                }}
              >
                여기
              </Typography.Link>
              를 눌러 최신 버전으로 갱신해 주세요. 단, 저장되지 않은 정보는
              초기화되므로 입력 중인 내용은 등록 또는 파일 업로드 후에
              클릭해주시기 바랍니다.
            </Typography.Text>
          ),
          duration: 0,
        });
      }
    };
    if (process.env.NODE_ENV !== 'development') {
      fetchIndex();
    }
  }, [location]);
};
