import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Form, message, Row, Select } from 'antd';

import { DocStatus, DocumentCode, PeriodOnMarketsForm } from 'types/product';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import { requireRule } from 'lib/validate';
import { messages } from 'lib/consts';
import history from 'lib/history';
import { focusToInvalidatedField } from 'lib/form';
import palette from 'lib/styles/palette';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import {
  usePeriodOnMarkets,
  usePOMDocumentForm,
  useProductDocStatus,
} from 'service/product';
import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
  SelectMonth,
  SelectYear,
} from 'components/ui';
import { PreventEnterInput, Typography } from 'components/system';
import UpdateLog from './UpdateLog';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import Icon from 'components/ui/Icon/Icon';

const SignImgContainer = styled(Flex)`
  position: relative;
  width: 810px;
  height: 280px;
  background: ${palette.SLATE_GRAY10};
  border-radius: 12px;
  padding: 32px 76px 32px 56px;
  box-sizing: border-box;
`;

const PeriodInputContainer = styled(Flex)``;

const periodOptions = [
  {
    label: 'month(s)',
    value: 'MONTH',
  },
  {
    label: 'year(s)',
    value: 'YEAR',
  },
];

const isFile = (file?: File | { name: string; url: string }): file is File =>
  !('url' in (file || {}));

const ProductPeriodOnMarkets = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [file, setFile] = useState<File | { name: string; url: string }>();
  const [form] = Form.useForm<PeriodOnMarketsForm>();
  const { sendEventToGA } = useGA();
  const countryId = useCountryId();
  const currentProduct = useCurrentProduct();
  const { productId } = currentProduct;
  const {
    periodOnMarkets,
    isPeriodOnMarketsLoading,
    createPeriodOnMarkets,
    isCreatePeriodOnMarketsLoading,
    updatePeriodOnMarkets,
    isUpdatePeriodOnMarketsLoading,
  } = usePeriodOnMarkets({
    productId,
    countryId: countryId,
  });
  const { downloadPOMDocumentForm, isLoading } = usePOMDocumentForm();
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.POM,
  });
  const updateMode = useMemo(
    () =>
      docStatus !== null &&
      docStatus.status !== DocStatus.INP &&
      docStatus.status !== DocStatus.OPT,
    [docStatus],
  );

  const handleSuccess = () => {
    sendEventToGA({
      documentName: 'Period on Markets',
      actionType: !updateMode
        ? EGAActionType.REGISTER
        : docStatus?.status === 'MOD'
        ? EGAActionType.MODIFY
        : EGAActionType.UPDATE,
    });
    history.goBack();
    message.success('입력되었습니다.');
  };

  const handleSubmit = (values: PeriodOnMarketsForm) => {
    if (!countryId) {
      return message.warning(messages.INVALID_APPROACH);
    }

    const {
      year: productLauncherYear,
      month: productLauncherMonth,
      salePeriod: productSalePeriod,
      salePeriodUnit: productSalePeriodUnit,
    } = values;

    if (updateMode) {
      if (
        periodOnMarkets?.productLauncherYear === productLauncherYear &&
        periodOnMarkets?.productLauncherMonth === productLauncherMonth &&
        periodOnMarkets?.productSalePeriod === productSalePeriod &&
        periodOnMarkets?.productSalePeriodUnit === productSalePeriodUnit &&
        !isFile(file)
      ) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }

      updatePeriodOnMarkets(
        {
          productId: currentProduct.productId,
          countryId,
          productLauncherYear,
          productLauncherMonth,
          productSalePeriod,
          productSalePeriodUnit,
          file: isFile(file) ? file : undefined,
        },
        {
          onSuccess: handleSuccess,
        },
      );
    } else {
      if (!isFile(file) || !file)
        return message.warning(messages.REQUIRED_FILE);

      createPeriodOnMarkets(
        {
          productId: currentProduct.productId,
          countryId,
          productLauncherYear,
          productLauncherMonth,
          productSalePeriod,
          productSalePeriodUnit,
          file,
        },
        {
          onSuccess: handleSuccess,
        },
      );
    }
  };

  useEffect(() => {
    if (!periodOnMarkets) return;

    const {
      productLauncherMonth: month,
      productLauncherYear: year,
      productSalePeriod: salePeriod,
      productSalePeriodUnit: salePeriodUnit,
      uploadFileName: name,
      uploadFileUrl: url,
    } = periodOnMarkets;

    form.setFieldsValue({
      year,
      month,
      salePeriod,
      salePeriodUnit,
    });

    setFile({
      name,
      url,
    });
  }, [periodOnMarkets]);

  const loading =
    isPeriodOnMarketsLoading ||
    isCreatePeriodOnMarketsLoading ||
    isUpdatePeriodOnMarketsLoading;

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ marginTop: 20, paddingLeft: 52 }}
      onFinish={handleSubmit}
      onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
    >
      <OrderList>
        <OrderListItem>
          <OrderListItemLabel>정보를 입력해 주세요.</OrderListItemLabel>
          <OrderListItemContent gutter={{ top: 24 }}>
            <PeriodInputContainer dir="column" align="center">
              <div style={{ width: 520 }}>
                <Form.Item
                  required
                  style={{ marginBottom: 0 }}
                  label={
                    <Row>
                      <Col>제품 출시일</Col>
                    </Row>
                  }
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item name="year" rules={[requireRule]}>
                        <SelectYear width="100%" disabled={readOnlyMode} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="month" rules={[requireRule]}>
                        <SelectMonth width="100%" disabled={readOnlyMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
              <div style={{ width: 520 }}>
                <Form.Item
                  required
                  style={{ marginBottom: 0 }}
                  label={
                    <Row>
                      <Col>제품이 시장에 판매된 총 기간</Col>
                    </Row>
                  }
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        name="salePeriod"
                        rules={[
                          requireRule,
                          {
                            pattern: /^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/,
                            message: '0~999까지의 숫자만 입력',
                          },
                        ]}
                      >
                        <PreventEnterInput
                          style={{ width: '100%' }}
                          placeholder="숫자 입력"
                          disabled={readOnlyMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="salePeriodUnit" rules={[requireRule]}>
                        <Select
                          style={{ width: '100%' }}
                          options={periodOptions}
                          placeholder="기간 (months, years)"
                          disabled={readOnlyMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            </PeriodInputContainer>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem horizontal columnGap={16}>
          <OrderListItemLabel>서류를 다운로드해 주세요.</OrderListItemLabel>
          <OrderListItemContent>
            <Button
              loading={isLoading}
              style={{ borderRadius: 4, display: 'flex', alignItems: 'center' }}
              icon={
                <Icon
                  name="download"
                  size={14}
                  color="PRIMARY50"
                  style={{ marginRight: 4 }}
                />
              }
              onClick={() => {
                const values = form.getFieldsValue();
                const {
                  year: productLauncherYear,
                  month: productLauncherMonth,
                  salePeriod: productSalePeriod,
                  salePeriodUnit: productSalePeriodUnit,
                } = values;

                if (
                  !productLauncherYear ||
                  !productLauncherMonth ||
                  productSalePeriod === undefined ||
                  !productSalePeriodUnit
                ) {
                  message.warning(messages.INPUT_INFO);
                  return;
                }

                downloadPOMDocumentForm({
                  productId: currentProduct.productId,
                  countryId,
                  productLauncherYear,
                  productLauncherMonth,
                  productSalePeriod,
                  productSalePeriodUnit,
                });
              }}
            >
              서류 양식 다운로드
            </Button>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem rowGap={16}>
          <OrderListItemLabel>
            브랜드사 책임자 정보를 영문으로 기입 후 서명을 해주세요.
          </OrderListItemLabel>
          <OrderListItemContent>
            <Flex>
              <SignImgContainer>
                <img
                  src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/manu_sign.png"
                  alt="sign"
                />
                <Flex
                  dir="column"
                  style={{ position: 'absolute', top: 112, right: 34 }}
                >
                  <Typography.Text>
                    브랜드사 책임자 정보 (영문 기입)
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: 8 }} color="SLATE_GRAY70">
                    예시1 - Q.C. Manager GILDONG HONG
                  </Typography.Text>
                  <Typography.Text
                    color="SLATE_GRAY70"
                    gutter={{ bottom: 16 }}
                    style={{ fontSize: 8 }}
                  >
                    예시2 - Overseas Sales Team Leader GILDONG HONG
                  </Typography.Text>
                  <Typography.Text>서명</Typography.Text>
                </Flex>
              </SignImgContainer>
            </Flex>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem rowGap={24}>
          <OrderListItemLabel>
            서명이 완료된 파일을 스캔 후 아래에 업로드해 주세요.
          </OrderListItemLabel>
          <OrderListItemContent>
            <Row justify="end" gutter={8}>
              <Col>
                <ProductCorrectButton
                  isFirstOpenChatModal={isFirstOpenChatModal}
                  onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
                  documentCode={DocumentCode.POM}
                />
              </Col>
              {updateMode && (
                <Col>
                  <UpdateLog
                    productId={productId}
                    countryId={countryId}
                    documentCode={DocumentCode.POM}
                  />
                </Col>
              )}
            </Row>
            <FileUploadTemplate
              accept=".pdf"
              fetchLoading={loading}
              readOnly={readOnlyMode}
              status={docStatus?.status}
              updateMode={updateMode}
              onSubmit={() => {}}
              onDelete={() => {
                setFile(undefined);
              }}
              onUpload={setFile}
              files={file}
              correctRequestWithNoUpdate={true}
              documentCode={DocumentCode.POM}
            />
          </OrderListItemContent>
        </OrderListItem>
      </OrderList>
    </Form>
  );
};

export default ProductPeriodOnMarkets;
