import { Form, message } from 'antd';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as buyerApi from 'lib/api/buyer';
import { messages } from 'lib/consts';
import { isObjectChanged } from 'lib/form';
import history from 'lib/history';
import * as buyerActions from 'modules/buyer';
import { useCountries } from 'service/country';
import useGA, { EGAActionType } from './useGA';

export const useBuyer = () => {
  const dispatch = useDispatch();
  const countries = useCountries();
  const {
    companyId,
    buyer,
    showAddModal,
    addLoading,
    updateLoading,
    deleteLoading,
  } = useSelector(
    ({ buyer, auth, loading }: any) => ({
      companyId: auth.user.companyId,
      buyer: buyer.buyer,
      showAddModal: buyer.showAddModal,
      addLoading: loading['buyer/ADD_BUYER'],
      updateLoading: loading['buyer/UPDATE_BUYER'],
      deleteLoading: loading['buyer/DELETE_BUYERS'],
    }),
    shallowEqual,
  );
  const [form] = Form.useForm();
  const toggleShowAddModal = () => {
    dispatch(buyerActions.toggleShowAddBuyerModal());
  };
  const addBuyer = (buyer: any) => {
    dispatch(
      buyerActions.addBuyer({
        brandCompanyId: companyId,
        ...buyer,
      }),
    );
  };
  const setBuyer = (buyerForm: any) => {
    dispatch(buyerActions.setBuyer(buyerForm));
  };
  const updateBuyer = (buyerForm: any) => {
    const originFormData = pick(buyer, [
      'ceoName',
      'companyAddress',
      'companyEmail',
      'companyName',
      'companyTel',
      'countryId',
      'representativeName',
    ]);

    if (!isObjectChanged({ object1: originFormData, object2: buyerForm })) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }

    dispatch(
      buyerActions.updateBuyer({ buyerId: buyer.buyerId, ...buyerForm }),
    );
  };
  const deleteBuyers = (buyerIds: number[]) => {
    dispatch(buyerActions.deleteBuyers(buyerIds));
  };
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        companyName: 'Farm Skin',
        companyTel: '0211112222',
        companyAddress: 'test address',
        representativeName: 'test representative',
        ceoName: 'ceo',
        companyEmail: 'ceo@dooley.com',
        countryId: 1,
      });
    }
  }, []);

  useEffect(() => {
    if (buyer !== null) {
      form.setFieldsValue(buyer);
    }
  }, [buyer]);

  return {
    buyer,
    form,
    countries,
    showAddModal,
    addLoading,
    updateLoading,
    deleteLoading,
    toggleShowAddModal,
    addBuyer,
    setBuyer,
    updateBuyer,
    deleteBuyers,
  };
};

export const useBuyers = (countryId: number) => {
  const dispatch = useDispatch();
  const { companyId, buyers, loading } = useSelector(
    ({ auth, buyer, loading }: any) => ({
      companyId: auth.user.companyId,
      buyers: buyer.buyers,
      loading: loading['buyer/GET_BUYERS'],
    }),
    shallowEqual,
  );
  const filteredBuyers = useMemo(
    () =>
      typeof countryId !== 'undefined'
        ? buyers.filter((buyer: any) => buyer.countryId === countryId)
        : buyers,
    [buyers, countryId],
  );

  const getBuyers = useCallback(() => {
    dispatch(buyerActions.getBuyers(companyId));
  }, []);

  useEffect(() => {
    getBuyers();
  }, []);

  return useMemo(
    () => ({
      loading,
      buyers: filteredBuyers,
    }),
    [loading, filteredBuyers],
  );
};

export const useProductBuyers = ({
  productId,
  countryId,
  subtitle,
}: {
  productId: number;
  countryId: number;
  subtitle: string;
}) => {
  const { sendEventToGA } = useGA();
  const dispatch = useDispatch();
  const { productBuyers, loading, addLoading } = useSelector(
    ({ buyer, loading }: any) => ({
      productBuyers: buyer.productBuyers,
      loading: loading['buyer/GET_PRODUCT_BUYERS'],
      addLoading:
        loading['buyer/ADD_PRODUCT_BUYERS'] ||
        loading['buyer/UPDATE_PRODUCT_BUYERS'],
    }),
  );
  const [selectedBuyerIds, setSelectedBuyerIds] = useState<number[]>([]);
  const getProductBuyers = useCallback(() => {
    dispatch(buyerActions.getProductBuyers({ productId, countryId }));
  }, [dispatch, productId, countryId]);

  const {
    mutate: addProductBuyers,
  } = useMutation(
    (productBuyers: {
      productId: number;
      buyerIds: number[];
      deleteProductBuyerIds: number[];
    }) => buyerApi.addProductBuyers(productBuyers),
  );

  const handleAddProductBuyers = useCallback(() => {
    if (!selectedBuyerIds.length) {
      return message.warn('최소 한 명 이상의 바이어를 선택해 주세요.');
    }
    if (
      selectedBuyerIds.length === productBuyers.length &&
      productBuyers.every(({ buyerId }: { buyerId: number }) =>
        selectedBuyerIds.includes(buyerId),
      )
    ) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }

    addProductBuyers(
      {
        productId,
        buyerIds: selectedBuyerIds,
        deleteProductBuyerIds: productBuyers.map(
          ({ productBuyerId }: any) => productBuyerId,
        ),
      },
      {
        onSuccess: () => {
          sendEventToGA({
            documentName: subtitle,
            actionType: !productBuyers.length
              ? EGAActionType.REGISTER
              : EGAActionType.MODIFY,
          });
          if (!productBuyers.length) {
            message.success('제품에 바이어/에이전시 정보가 등록되었습니다.');
          } else {
            message.success('보완 완료되었습니다.');
          }
          history.goBack();
        },
      },
    );
  }, [dispatch, productId, selectedBuyerIds, productBuyers]);
  useEffect(() => {
    getProductBuyers();
    return () => {
      dispatch(buyerActions.initializeProductBuyers());
    };
  }, [getProductBuyers]);
  useEffect(() => {
    if (productBuyers.length) {
      setSelectedBuyerIds(productBuyers.map(({ buyerId }: any) => buyerId));
    }
  }, [productBuyers]);

  return useMemo(
    () => ({
      selectedBuyerIds,
      setSelectedBuyerIds,
      productBuyers,
      loading,
      addLoading,
      addProductBuyers: handleAddProductBuyers,
    }),
    [
      selectedBuyerIds,
      productBuyers,
      loading,
      addLoading,
      handleAddProductBuyers,
    ],
  );
};
