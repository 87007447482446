import { Button } from 'antd';
import styled from 'styled-components';

import { downloadFile } from 'lib/file';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { DocumentCode } from 'types/product';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';

const StyledFlex = styled(Flex)`
  margin: 0 auto 32px;
  width: max-content;

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    path {
      fill: ${palette.PRIMARY50};
    }
  }
`;

const FileUploadTemplate = ({
  updateMode = false,
  readOnly = false,
  files,
  fetchLoading = false,
  accept,
  viewerVisible = true,
  footerVisible = true,
  formUrl = null,
  status,
  max,
  documentCode,
  correctRequestWithNoUpdate = false,
  onUpload,
  onDelete,
  onSubmit,
  children,
}: {
  updateMode?: boolean;
  readOnly?: boolean;
  files: any;
  fetchLoading?: boolean;
  accept?: string;
  viewerVisible?: boolean;
  footerVisible?: boolean;
  formUrl?: string | null;
  status?: string;
  max?: number;
  onUpload: (file: any) => any;
  onDelete: (file: any) => any;
  onSubmit: () => void;
  children?: React.ReactNode;
  documentCode?: DocumentCode;
  correctRequestWithNoUpdate?: boolean;
}) => {
  return (
    <>
      {children}
      {formUrl && (
        <StyledFlex justify="center">
          <Button
            type="dashed"
            icon={<Icon name="download" size={14} style={{ marginRight: 4 }} />}
            onClick={() => downloadFile(formUrl)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            서식 다운로드
          </Button>
        </StyledFlex>
      )}
      <FileUploadContainer
        readOnly={readOnly}
        files={files}
        viewerVisible={viewerVisible}
        accept={accept}
        max={max}
        onUpload={onUpload}
        onDelete={onDelete}
      />
      {footerVisible && (
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnly}>
            {correctRequestWithNoUpdate &&
              documentCode &&
              updateMode &&
              status === 'MOD' && (
                <CorrectRequestWithoutChangeButton
                  documentCode={documentCode}
                />
              )}
            <Button
              type="primary"
              htmlType="submit"
              loading={fetchLoading}
              onClick={onSubmit}
            >
              {!updateMode ? '등록' : status !== 'MOD' ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      )}
    </>
  );
};
export default FileUploadTemplate;
