import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as brandCompanyApi from 'lib/api/brand/company';
import client from 'lib/api/client';
import {
  CompanyDocsUserType,
  CompanyDocsList,
  CompanyDocsListParams,
} from 'types/brand/company';
import { apiUrls, useBasicMutation, useBasicQuery } from 'service/core';

export const useBrandCompanies = (brandCompaniesParams: {
  companyNameKo?: string;
  isExcludeUserInserted?: boolean;
  enabled: boolean;
}) => {
  return useQuery(
    ['pub/brand-companies', brandCompaniesParams],
    () => brandCompanyApi.getBrandCompanies(brandCompaniesParams),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
      enabled: brandCompaniesParams.enabled,
    },
  );
};

export interface CompanyDocsDataParmas {
  files: {
    [key: string]: {
      attachId: number;
      filename: string;
      uploadFileUrl: string;
      registerDt: string;
      qcqaDocumentRecordAttachId: number;
    };
  };
  histories: {
    [key: string]: {
      qcqaDocumentRecordId?: number | string;
      name: string;
      recordDate: string;
      attachIds: string[];
      qcqaDocumentRecordAttachIds: string[];
    };
  };
  columns: {
    [key: string]: {
      id: string;
      description?: string;
      relationType: string;
      name: string;
      visible: boolean;
      editable: boolean;
      open?: boolean;
      attachIds: string[];
      historyIds: string[];
      isForCertification?: boolean;
      code?: string;
    };
  };
  columnOrder: string[];
}

export const useCompanyDocsList = ({
  useType,
  isDisplay = false,
}: {
  useType?: CompanyDocsUserType;
  isDisplay?: boolean;
}) => {
  const queryClient = useQueryClient();
  const {
    data: companyDocsList = [],
    isLoading: isCompanyDocsListLoading,
  } = useBasicQuery<CompanyDocsList[]>({
    apiUrl: apiUrls.myCompanyDocsList,
    qs: { ...(useType && { useType }), ...(isDisplay && { isDisplay }) },
    option: {
      cacheTime: 0,
    },
  });

  const {
    mutate: addCompanyDocsList,
    isLoading: iaAddCompanyDocsListLoading,
  } = useMutation(
    (params: CompanyDocsListParams[]) =>
      client.put(`/v2/qcqa/common-documents/${useType}`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.myCompanyDocsList);
      },
    },
  );

  return {
    companyDocsList,
    isCompanyDocsListLoading,
    addCompanyDocsList,
    iaAddCompanyDocsListLoading,
  };
};

// QCQA 서류매핑 제품 조회 (qpsc009)
export const useGetProductsWithDocs = () => {
  const { mutate: getProductsWithDocs } = useBasicMutation<
    {
      qcqaUserDocumentId: number;
    },
    {
      qcqaProductId: number;
      productNameKo: string;
      productNameEn: string;
      registerDt: string;
      updateDt: string | null;
      lotNos: {
        qcqaProductLotnoId: number;
        lotNo: string;
      }[];
    }[]
  >({
    apiUrl: apiUrls.qcqaProductsWithDocs,
    method: 'get',
    paramNamesForUrl: ['qcqaUserDocumentId'],
  });

  return {
    getProductsWithDocs,
  };
};
