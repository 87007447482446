import { Button, Form, Input, message, Radio, Spin } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useCertificateMode, useReadOnlyMode } from 'hook/certificate';
import { useManuMode } from 'hook/company';
import { PackingAttestationAttache } from 'types/brand/product/packingAttestation';
import { DocumentCode } from 'types/product';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { useForceUpdate } from 'lib/hook';
import palette from 'lib/styles/palette';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { useProductPackingAttestation } from 'service/brand/product/packingAttestation';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import Typography from 'components/system/general/Typography';
import { BackgroundRadio, Flex } from 'components/ui';
import ImageBox from 'components/ui/ImageBox';
import UploadDragger from 'components/UploadDragger';
import UpdateLog from 'components/product/UpdateLog';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const BrandContainer = styled.div`
  width: 520px;
  margin: 0 auto;
  counter-reset: no;

  ${Title} {
    &::before {
      display: inline-block;
      counter-increment: no;
      content: counter(no);
      background-color: ${palette.PRIMARY50};
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
    }
  }
`;

const BoxContainer = styled(Flex)`
  padding-top: 12px;
  padding-bottom: 4px;
  max-width: 520px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.SLATE_GRAY70};
    border-radius: 55px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const PlusBox = styled(Flex)`
  width: 100px;
  height: 72px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
  cursor: pointer;
`;

const useOptions = [
  { title: '사용함', value: true },
  { title: '사용 안함', value: false },
];

const DeleteWrapper = styled(Flex)`
  position: absolute;
  top: -4px;
  right: -8px;
  border-radius: 52px;
  z-index: 99;
  cursor: pointer;
`;

const RadioFormItemWrapper = styled.div`
  width: 520px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;

  > .ant-radio-group {
    margin-top: 8px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 28px;
    background-color: ${palette.SLATE_GRAY10};
    padding: 20px 44px;
    border-radius: 4px;
  }

  .ant-radio {
    transform: translateY(-1px);
  }
`;

// HINT: File 타입별 name 체크 함수
const getNameOfFile = (file?: File | PackingAttestationAttache) => {
  return file instanceof File ? file.name : file?.filename;
};

const ProductPackingAttestation = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  const readOnlyMode = useReadOnlyMode();
  const manuMode = useManuMode();
  const {
    updateMode,
    form,
    getLoading,
    fetchLoading,
    updateLoading,
    packingAttestationType,
    firstPackingFiles,
    setFirstPackingFiles,
    currentFirstFile,
    setCurrentFirstFile,
    currentSecondFile,
    setCurrentSecondFile,
    secondPackingFiles,
    setSecondPackingFiles,
    submit,
    changePackingAttestationType,
    patchPackingAttestationType,
    isFile,
    setDeletedFiles,
  } = useProductPackingAttestation(productId, countryId);

  const handleClickPlus = useCallback((className: string) => {
    const dragger: HTMLElement | null = document.querySelector(className);

    if (dragger) {
      dragger.click();
    }
  }, []);

  const getImageUrl = useCallback(
    (file?: PackingAttestationAttache | File) =>
      file
        ? isFile(file)
          ? URL.createObjectURL(file)
          : file.attachUrl
        : undefined,
    [isFile],
  );

  return (
    <Container>
      <Flex justify="end" gap={8}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.PACK}
        />
        {updateMode && (
          <UpdateLog productId={productId!} documentCode={DocumentCode.PACK} />
        )}
      </Flex>

      <Spin spinning={getLoading === true}>
        {manuMode && (
          <RadioFormItemWrapper>
            <Typography.Text type="TITLE_2" asterisk>
              부자재 구매 유형
            </Typography.Text>
            <Radio.Group
              value={packingAttestationType}
              onChange={(e) => changePackingAttestationType(e.target.value)}
            >
              <Radio value="TRK">턴키 (Turn-Key)</Radio>
              <Radio value="ATK">턴키 구매대행</Radio>
              <Radio value="BND">브랜드사 사급</Radio>
            </Radio.Group>
          </RadioFormItemWrapper>
        )}
        {(!manuMode ||
          (packingAttestationType && packingAttestationType !== 'BND')) && (
          <>
            <BrandContainer>
              <Form
                form={form}
                onFinish={submit}
                colon={false}
                layout="vertical"
                scrollToFirstError={scrollToFirstErrorOption}
                onValuesChange={(fieldMap) => {
                  if (
                    [
                      'isUseRecyclingContainer',
                      'isUsePackingContainer2',
                    ].includes(Object.keys(fieldMap)[0])
                  ) {
                    forceUpdate();
                  }
                }}
              >
                <Title>
                  <Typography.Text type="TITLE_1" inline>
                    1차 포장 용기 정보를 입력해 주세요.
                  </Typography.Text>
                </Title>
                <Form.Item
                  label="1차 포장 용기 재질"
                  name="packingContainer1"
                  normalize={(value) => value.toUpperCase()}
                  rules={[requireRule, exceptKoreanRule]}
                >
                  <Input disabled={readOnlyMode} />
                </Form.Item>
                <Form.Item
                  required
                  name="packingContainer1Image"
                  label="1차 포장 용기 사진 첨부"
                  rules={[
                    {
                      validator: async () => {
                        if (firstPackingFiles.length === 0) {
                          throw Error(messages.REQUIRED_FIELD);
                        }
                      },
                    },
                  ]}
                >
                  <>
                    <UploadDragger
                      className="picture"
                      width={520}
                      height={260}
                      initialImageUrl={getImageUrl(currentFirstFile)}
                      disabled={readOnlyMode}
                      onDragger={(file) => {
                        if (firstPackingFiles.length === 5) {
                          return message.warning(
                            '최대 5개까지 업로드 가능합니다.',
                          );
                        }
                        if (
                          firstPackingFiles.some(
                            (item) => getNameOfFile(item) === file.name,
                          )
                        ) {
                          return message.warning(
                            '같은 이름의 파일은 업로드 할 수 없습니다.',
                          );
                        }

                        setCurrentFirstFile(file);
                        setFirstPackingFiles((draft) => [file, ...draft]);
                      }}
                      imageDescription="400 x 200 / 용량 300KB 이하 권장"
                    />
                    {firstPackingFiles.length > 0 && (
                      <BoxContainer>
                        {!readOnlyMode && (
                          <PlusBox
                            justify="center"
                            align="center"
                            onClick={() => handleClickPlus('.picture')}
                          >
                            <Icon name="plus" size={16} color="SLATE_GRAY70" />
                          </PlusBox>
                        )}
                        {firstPackingFiles.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              width: 100,
                              position: 'relative',
                              background: palette.SLATE_GRAY10,
                              marginLeft: index === 0 ? 8 : 16,
                            }}
                          >
                            {!readOnlyMode && (
                              <DeleteWrapper
                                align="center"
                                justify="center"
                                onClick={() => {
                                  if (
                                    getNameOfFile(currentFirstFile) ===
                                    getNameOfFile(file)
                                  ) {
                                    setCurrentFirstFile(undefined);
                                  }

                                  setFirstPackingFiles((draft) =>
                                    draft.filter(
                                      (item) =>
                                        getNameOfFile(item) !==
                                        getNameOfFile(file),
                                    ),
                                  );

                                  if (!isFile(file)) {
                                    setDeletedFiles((draft) =>
                                      draft.concat(file),
                                    );
                                  }
                                }}
                              >
                                <Icon
                                  name="smallClose"
                                  size={20}
                                  color="ETC_WHITE"
                                  circleColor="SLATE_GRAY60"
                                />
                              </DeleteWrapper>
                            )}

                            <ImageBox
                              key={getNameOfFile(file)}
                              width="100px"
                              height="72px"
                              borderRadius="4px"
                              src={getImageUrl(file) || ''}
                              alt={getNameOfFile(file) || ''}
                              objectFit="contain"
                              onClick={() => setCurrentFirstFile(file)}
                            ></ImageBox>
                          </div>
                        ))}
                      </BoxContainer>
                    )}
                  </>
                </Form.Item>
                <Title>
                  <Typography.Text type="TITLE_1" inline>
                    2차 포장 용기 정보를 입력해 주세요.
                  </Typography.Text>
                </Title>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.isUsePackingContainer2 !== next.isUsePackingContainer2
                  }
                >
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        label="2차 포장 용기 사용 여부"
                        name="isUsePackingContainer2"
                        rules={[requireRule, exceptKoreanRule]}
                      >
                        <BackgroundRadio
                          gap={140}
                          options={useOptions}
                          disabled={readOnlyMode}
                        />
                      </Form.Item>
                      {getFieldValue('isUsePackingContainer2') && (
                        <>
                          <Form.Item
                            label="2차 포장 용기 재질"
                            name="packingContainer2"
                            normalize={(value) => value.toUpperCase()}
                            rules={[requireRule, exceptKoreanRule]}
                          >
                            <Input disabled={readOnlyMode} />
                          </Form.Item>
                          <Form.Item
                            required
                            name="packingContainer2Image"
                            label="2차 포장 용기 사진 첨부"
                            rules={[
                              {
                                validator: async () => {
                                  if (secondPackingFiles.length === 0) {
                                    throw Error(messages.REQUIRED_FIELD);
                                  }
                                },
                              },
                            ]}
                          >
                            <>
                              <UploadDragger
                                className="picture2"
                                width={520}
                                height={260}
                                initialImageUrl={getImageUrl(currentSecondFile)}
                                disabled={readOnlyMode}
                                onDragger={(file) => {
                                  if (secondPackingFiles.length === 5) {
                                    return message.warning(
                                      '최대 5개까지 업로드 가능합니다.',
                                    );
                                  }
                                  if (
                                    secondPackingFiles.some(
                                      (item) =>
                                        getNameOfFile(item) === file.name,
                                    )
                                  ) {
                                    return message.warning(
                                      '같은 이름의 파일은 업로드 할 수 없습니다.',
                                    );
                                  }

                                  setCurrentSecondFile(file);
                                  setSecondPackingFiles((draft) => [
                                    file,
                                    ...draft,
                                  ]);
                                }}
                                imageDescription="400 x 200 / 용량 300KB 이하 권장"
                              />
                              {secondPackingFiles.length > 0 && (
                                <BoxContainer>
                                  {!readOnlyMode && (
                                    <PlusBox
                                      justify="center"
                                      align="center"
                                      onClick={() =>
                                        handleClickPlus('.picture2')
                                      }
                                    >
                                      <Icon
                                        name="plus"
                                        size={16}
                                        color="SLATE_GRAY70"
                                      />
                                    </PlusBox>
                                  )}
                                  {secondPackingFiles.map((file, index) => (
                                    <div
                                      style={{
                                        width: 100,
                                        position: 'relative',
                                        background: palette.SLATE_GRAY10,
                                        marginLeft: index === 0 ? 8 : 16,
                                      }}
                                      key={index}
                                    >
                                      {!readOnlyMode && (
                                        <DeleteWrapper
                                          align="center"
                                          justify="center"
                                          onClick={() => {
                                            if (
                                              getNameOfFile(
                                                currentSecondFile,
                                              ) === getNameOfFile(file)
                                            ) {
                                              setCurrentSecondFile(undefined);
                                            }

                                            setSecondPackingFiles((draft) =>
                                              draft.filter(
                                                (item) =>
                                                  getNameOfFile(item) !==
                                                  getNameOfFile(file),
                                              ),
                                            );

                                            if (!isFile(file)) {
                                              setDeletedFiles((draft) =>
                                                draft.concat(file),
                                              );
                                            }
                                          }}
                                        >
                                          <Icon
                                            name="smallClose"
                                            size={20}
                                            color="ETC_WHITE"
                                            circleColor="SLATE_GRAY60"
                                          />
                                        </DeleteWrapper>
                                      )}
                                      <ImageBox
                                        key={getNameOfFile(file)}
                                        width="100px"
                                        height="72px"
                                        borderRadius="4px"
                                        objectFit="contain"
                                        src={
                                          isFile(file)
                                            ? URL.createObjectURL(file)
                                            : file.attachUrl
                                        }
                                        onClick={() =>
                                          setCurrentSecondFile(file)
                                        }
                                        alt={getNameOfFile(file) || ''}
                                      />
                                    </div>
                                  ))}
                                </BoxContainer>
                              )}
                            </>
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                </Form.Item>
                <Title>
                  <Typography.Text type="TITLE_1" inline>
                    재활용 용기 정보를 입력해 주세요.
                  </Typography.Text>
                </Title>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.isUseRecyclingContainer !==
                    next.isUseRecyclingContainer
                  }
                >
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        rules={[requireRule]}
                        label={
                          <>
                            재활용 용기 사용 여부{' '}
                            <Typography.Text
                              type="BODY_2"
                              color="GRAY70"
                              gutter={{ left: 8 }}
                              style={{ fontSize: 12 }}
                            >
                              <span style={{ color: palette.PRIMARY50 }}>
                                *PCR 용기
                              </span>
                              (Post Consumer Recycled)를 사용했을 경우
                            </Typography.Text>
                          </>
                        }
                        name="isUseRecyclingContainer"
                      >
                        <BackgroundRadio
                          gap={140}
                          options={useOptions}
                          disabled={readOnlyMode}
                        />
                      </Form.Item>
                      {getFieldValue('isUseRecyclingContainer') && (
                        <Form.Item
                          rules={[requireRule]}
                          label="재활용 용기 색깔 사용 여부"
                          name="isUseColorContainer"
                        >
                          <BackgroundRadio
                            gap={140}
                            options={useOptions}
                            disabled={readOnlyMode}
                          />
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.Item>
              </Form>
            </BrandContainer>
            <FooterBox>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                {updateMode && certificateMode && (
                  <CorrectRequestWithoutChangeButton
                    documentCode={DocumentCode.PACK}
                  />
                )}
                <Button
                  type="primary"
                  loading={fetchLoading || updateLoading}
                  onClick={form.submit}
                >
                  {!updateMode
                    ? '등록'
                    : !certificateMode
                    ? '수정'
                    : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </>
        )}
        {manuMode && packingAttestationType === 'BND' && (
          <FooterBox>
            <Button
              loading={fetchLoading}
              type="primary"
              onClick={patchPackingAttestationType}
            >
              {!updateMode ? '확인' : '수정'}
            </Button>
          </FooterBox>
        )}
      </Spin>
    </Container>
  );
};

export default ProductPackingAttestation;
