import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import Error404Page from 'pages/error/Error404Page';
import NMPARequestPage from 'pages/material/nmpa/NMPARequestPage';
import NMPARequestListPage from 'pages/material/nmpa/NMPARequestListPage';
import NMPAOngoingPage from 'pages/material/nmpa/NMPAOngoingPage';
import NMPARegisteredPage from 'pages/material/nmpa/NMPARegisteredPage';
import NMPAChecklistPage from 'pages/material/nmpa/NMPAChecklistPage';
import NMPAContractPage from 'pages/material/nmpa/NMPAContractPage';
import NMPAEstimatePage from 'pages/material/nmpa/NMPAEstimatePage';
import NMPADocumentListPage from 'pages/material/nmpa/NMPADocumentListPage';
import NMPAMaterialListPage from 'pages/material/nmpa/NMPAMaterialListPage';
import { DocumentCode } from 'types/material/nmpa/nmpa';
import NMPABasicPage from 'pages/material/nmpa/NMPABasicPage';
import NMPABATPage from 'pages/material/nmpa/NMPABATPage';
import NMPADetailPage from 'pages/material/nmpa/NMPADetailPage';
import NMPADIFPage from 'pages/material/nmpa/NMPADIFPage';
import NMPAEDTPage from 'pages/material/nmpa/NMPAEDTPage';
import NMPAESDPage from 'pages/material/nmpa/NMPAESDPage';

const NMPARouter = () => {
  return (
    <Switch>
      <Route
        path={path.material.nmpa.estimate.request}
        component={NMPARequestPage}
      />
      <Route
        path={path.material.nmpa.estimate.checkList}
        component={NMPAChecklistPage}
      />
      <Route
        path={`${path.material.nmpa.estimate.root}/:materialNmpaEstimateId`}
        component={NMPAEstimatePage}
        exact
      />
      <Route
        path={`${path.material.nmpa.estimate.root}/:materialNmpaEstimateId/contract`}
        component={NMPAContractPage}
      />
      <Route
        path={path.material.nmpa.requestList}
        component={NMPARequestListPage}
      />
      <Route path={path.material.nmpa.ongoing} component={NMPAOngoingPage} />
      <Route
        path={path.material.nmpa.registered}
        component={NMPARegisteredPage}
      />
      <Route
        path={path.material.nmpa.material.list}
        component={NMPAMaterialListPage}
        exact
      />
      <Route
        path={`${path.material.nmpa.material.root}/${DocumentCode.BASIC}`}
        component={NMPABasicPage}
        exact
      />
      {/* materialId와 nmpaBasicInfoId는 같습니다.  */}
      <Route
        path={`${path.material.nmpa.material.root}/:materialId/${DocumentCode.BAT}`}
        component={NMPABATPage}
        exact
      />
      <Route
        path={`${path.material.nmpa.material.root}/:materialId/${DocumentCode.DETAIL}`}
        component={NMPADetailPage}
        exact
      />
      <Route
        path={`${path.material.nmpa.material.root}/:materialId/${DocumentCode.DIF}`}
        component={NMPADIFPage}
        exact
      />
      <Route
        path={`${path.material.nmpa.material.root}/:materialId/${DocumentCode.EDT}`}
        component={NMPAEDTPage}
        exact
      />
      <Route
        path={`${path.material.nmpa.material.root}/:materialId/${DocumentCode.ESD}`}
        component={NMPAESDPage}
        exact
      />
      <Route
        path={`${path.material.nmpa.material.root}/:materialId`}
        component={NMPADocumentListPage}
        exact
      />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default NMPARouter;
