import { useLocation } from 'react-router-dom';

import NMPABasicOld from 'components/material/nmpa/NMPABasicOld';
import { Typography } from 'components/system';
import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const notices = [
  <Typography.Text type="BODY_2" color="MESSAGE_ERROR">
    중국 원료 신고번호 취득을 위해 필요한 항목이므로 정확한 정보를 기입해
    주세요.
  </Typography.Text>,
  '작성해주신 내용에 따라 근거 자료 요청 및 보완 사항이 발생할 수 있습니다.',
  '입력된 내용은 취득 진행에 적합한 내용으로 수정될 수 있습니다.',
];

const NMPABasicPage = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const materialId = query.get('materialId')
    ? Number(query.get('materialId'))
    : undefined;

  return (
    <NMPAPageTemplate
      documentName="기본 정보"
      title="원료 신고 정보"
      notices={notices}
    >
      <NMPABasicOld materialId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPABasicPage;
