import { useEffect, useMemo } from 'react';
import { Typography } from 'antd';

import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductArtworkContainer from 'containers/product/ProductArtworkContainer';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import MistakeNoticeItem from 'components/MistakeNoticeItem';

const notices = [
  'Artwork : 제품에 최종 인쇄할 디자인 도면 (PDF)',
  '예시) 캡, 튜브, 개봉방지스티커, 라벨, 띠지, 삽지 등 제품에 인쇄될 모든 디자인 데이터',
  <>
    반드시{' '}
    <Typography.Text type="danger" style={{ padding: 0 }} strong>
      영문, PDF 파일
    </Typography.Text>
    로 업로드 바랍니다. 국가별 최대 10개의 파일까지 업로드가 가능합니다.
  </>,
  <MistakeNoticeItem />,
];

const ProductArtworkPage = () => {
  const countryId = useCountryId();
  const currentProduct = useCurrentProduct();

  useEffect(() => {
    const rootPageTemplete = document.querySelector(
      '[class*="PageTemplateStyle"]',
    );
    const exampleBlock = document.querySelector('[class*="ExampleBlock"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
    (exampleBlock as HTMLElement).style.maxWidth = '864px';
    (exampleBlock as HTMLElement).style.margin = '32px auto';
  }, []);

  const currentCountry = useMemo(
    () =>
      currentProduct?.productCountries?.find(
        (productCountry) => productCountry.countryId === countryId,
      ),
    [currentProduct, countryId],
  );

  const isEurope = useMemo(
    () => currentCountry?.country?.countryCode === 'EU',
    [currentCountry],
  );
  const isEngland = useMemo(
    () => currentCountry?.country?.countryCode === 'GB',
    [currentCountry],
  );

  const newNotices = useMemo(() => {
    if (!isEurope && !isEngland) return notices;
    const newNotices = [...notices];

    newNotices.splice(
      3,
      0,
      `${
        isEurope ? '유럽' : '영국'
      }의 경우 아트웍에 반드시 들어가야 하는 필수 기재 사항이 있어, 아래 버튼을 클릭하여 해당 부분을 반드시 먼저 확인해 주세요.`,
      '아트웍에 들어있지 않은 필수 기재 사항의 경우 추가 라벨로 부착이 가능하지만, 최종 파일은 해당 라벨을 부착한 형태의 아트웍으로 업로드 해야 합니다.',
    );

    return newNotices;
  }, [isEurope, isEngland]);

  return (
    <ProductPageTemplate
      subtitle="Artwork"
      notices={newNotices}
      exampleImg="ArtWork.jpg"
    >
      <ProductArtworkContainer
        countryCode={currentCountry?.country?.countryCode}
      />
    </ProductPageTemplate>
  );
};

export default ProductArtworkPage;
