import { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Col, Form, Input, List, Row } from 'antd';

import palette from 'lib/styles/palette';
import AlphabetLabel from './AlphabetLabel';
import { Typography } from 'components/system';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { FirstAidStatementParam } from 'types/material/msds';
import { messages } from 'lib/consts';
import Icon from 'components/ui/Icon/Icon';

const StatementSelectBlock = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 46px;
  border: 1px solid #d3d3d3;
  z-index: 1000;
  background-color: #fff;
`;

const StatementItemBlock = styled.div<{ disabled?: boolean }>`
  padding: 2px 12px;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.2;
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
          &:hover {
            background-color: ${palette.LIGHT_BLUE20};
          }
        `}
`;

interface StatementOption extends FirstAidStatementParam {
  disabled?: boolean;
}

const StatementSelect = ({
  options,
  onSelect,
}: {
  options: StatementOption[];
  onSelect: (statement: FirstAidStatementParam) => void;
}) => {
  const handleSelectOption = ({
    statementEn,
    statementKo,
    disabled,
  }: StatementOption) => {
    if (!disabled) {
      if (statementEn === '직접 입력') {
        onSelect({ statementEn: '' });
      } else {
        onSelect({ statementEn, statementKo });
      }
    }
  };
  return (
    <StatementSelectBlock>
      <List
        dataSource={[
          {
            statementEn: '직접 입력',
            statementKo: '직접 입력',
            disabled: false,
          },
          ...options,
        ]}
        rowKey="id"
        renderItem={({ statementEn, statementKo, disabled }) => (
          <List.Item
            style={{
              display: 'block',
              textAlign: 'left',
              padding: 4,
              cursor: 'default',
            }}
          >
            <StatementItemBlock
              disabled={disabled}
              onClick={() => {
                handleSelectOption({ statementEn, statementKo, disabled });
              }}
            >
              <Typography.Text>{statementEn}</Typography.Text>
              <Typography.Text type="BODY_2" color="GRAY70">
                {statementKo}
              </Typography.Text>
            </StatementItemBlock>
          </List.Item>
        )}
      />
    </StatementSelectBlock>
  );
};

const MSDSFirstAidBlock = styled.div`
  margin-bottom: 32px;
`;

const ListBlock = styled.div`
  position: relative;
  margin-top: 14px;
  border-top: 2px solid ${palette.PRIMARY50};
`;
const ListItemBlock = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 6px 24px;

  .ant-btn.ant-btn-sm {
    width: 86px;
    border: 1px solid ${palette.GRAY40};
    color: ${palette.GRAY70};
  }
`;

const StatementAddButton = styled(Button)<{ active: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${palette.SLATE_GRAY70};

  &:hover,
  &:focus {
    color: ${palette.SLATE_GRAY70};
  }
  transition-property: none;

  ${({ active }) =>
    active === 'false' &&
    css`
      border: none;
      background-color: #eff1f8;

      &:hover,
      &:focus {
        background-color: #eff1f8;
      }
    `}
`;

const FirstAidStatement = ({
  statementKey,
  defaultOptions,
}: {
  statementKey: string;
  defaultOptions?: FirstAidStatementParam[];
}) => {
  const [statementSelectVisible, setStatementSelectVisible] = useState(false);
  const handleClickStatementAddButton = () => {
    setStatementSelectVisible((draft) => !draft);
  };
  return (
    <Form.Item
      shouldUpdate={(prev, next) =>
        prev.firstAid[`${statementKey}Statements`] !==
        next.firstAid[`${statementKey}Statements`]
      }
    >
      {({ getFieldValue }) => {
        const statements: FirstAidStatementParam[] =
          getFieldValue(['firstAid', `${statementKey}Statements`]) || [];
        const statementOptions: FirstAidStatementParam[] =
          defaultOptions ||
          getFieldValue(['firstAid', `${statementKey}StatementOptions`]) ||
          [];
        return (
          <>
            {/* 스크롤을 위한 Form Item */}
            <Form.Item
              name={['firstAid', `${statementKey}Scroll`]}
              style={{ width: 0, height: 0 }}
              rules={[
                {
                  validator: async () => {
                    const statements: FirstAidStatementParam[] = getFieldValue([
                      'firstAid',
                      `${statementKey}Statements`,
                    ]);
                    if (statements.length === 0) {
                      throw new Error(' ');
                    }
                  },
                },
              ]}
            >
              <Input style={{ width: 0, height: 0, padding: 0, border: 0 }} />
            </Form.Item>
            {/* HINT: 유효성 검사 실패시 자동 스크롤을 위한 div */}
            <Form.List
              name={['firstAid', `${statementKey}Statements`]}
              rules={[
                {
                  validator: async (_, statements) => {
                    if (statements.length === 0) {
                      throw new Error(messages.REQUIRED_FIELD);
                    }
                  },
                },
              ]}
              initialValue={[]}
            >
              {(fields, { add, remove }, { errors }) => (
                <ListBlock>
                  {fields.map((field) => {
                    const { statementEn, statementKo } =
                      statements[field.name] || {};
                    return (
                      <ListItemBlock key={field.key}>
                        <Row
                          align="middle"
                          justify="space-between"
                          gutter={16}
                          wrap={false}
                        >
                          <Col flex="auto">
                            {statementKo ? (
                              <>
                                <Typography.Text>{statementEn}</Typography.Text>
                                <Typography.Text type="BODY_2" color="GRAY70">
                                  {statementKo}
                                </Typography.Text>
                              </>
                            ) : (
                              <Form.Item
                                name={[field.name, 'statementEn']}
                                style={{ marginBottom: 0 }}
                                rules={[requireRule, exceptKoreanRule]}
                              >
                                <Input size="small" placeholder="직접 입력" />
                              </Form.Item>
                            )}
                          </Col>
                          <Col>
                            <Button
                              onClick={() => remove(field.name)}
                              size="small"
                            >
                              문구 삭제
                            </Button>
                          </Col>
                        </Row>
                      </ListItemBlock>
                    );
                  })}
                  <ListItemBlock>
                    <StatementAddButton
                      active={statementSelectVisible.toString()}
                      block
                      onClick={handleClickStatementAddButton}
                    >
                      문구 추가하기{' '}
                      <Icon
                        name={statementSelectVisible ? 'up' : 'down'}
                        color="SLATE_GRAY70"
                        size={20}
                        style={{ marginLeft: 8 }}
                      />
                      {statementSelectVisible && (
                        <StatementSelect
                          options={statementOptions.map(
                            (option: FirstAidStatementParam) => ({
                              ...option,
                              disabled: statements.some(
                                ({ statementEn }: any) =>
                                  statementEn === option.statementEn,
                              ),
                            }),
                          )}
                          onSelect={({
                            statementEn,
                            statementKo,
                          }: FirstAidStatementParam) =>
                            add({ statementEn, statementKo })
                          }
                        />
                      )}
                    </StatementAddButton>
                  </ListItemBlock>
                  <Form.ErrorList errors={errors} />
                </ListBlock>
              )}
            </Form.List>
          </>
        );
      }}
    </Form.Item>
  );
};

const MSDSFirstAid = () => {
  return (
    <MSDSFirstAidBlock>
      <AlphabetLabel alphabet="A" indent={false}>
        In case of eye contact
      </AlphabetLabel>
      <FirstAidStatement statementKey="eye" />
      <AlphabetLabel alphabet="B" indent={false} style={{ marginTop: 24 }}>
        In case of skin contact
      </AlphabetLabel>
      <FirstAidStatement statementKey="skin" />
      <AlphabetLabel alphabet="C" indent={false} style={{ marginTop: 24 }}>
        If inhaled
      </AlphabetLabel>
      <FirstAidStatement statementKey="inhalation" />
      <AlphabetLabel alphabet="D" indent={false} style={{ marginTop: 24 }}>
        If swallowed
      </AlphabetLabel>
      <FirstAidStatement statementKey="ingestion" />
      <AlphabetLabel alphabet="E" indent={false} style={{ marginTop: 24 }}>
        Most important acute symptoms/effects
      </AlphabetLabel>
      <FirstAidStatement
        statementKey="acuteSymptom"
        defaultOptions={[
          { statementEn: 'None Known', statementKo: '알려지지 않음' },
        ]}
      />
      <AlphabetLabel alphabet="E-1" indent={false} style={{ marginTop: 24 }}>
        Most important delayed symptoms/effects
      </AlphabetLabel>
      <FirstAidStatement
        statementKey="delayedSymptom"
        defaultOptions={[
          { statementEn: 'None Known', statementKo: '알려지지 않음' },
        ]}
      />
      <AlphabetLabel alphabet="F" indent={false} style={{ marginTop: 24 }}>
        Notes to physician
      </AlphabetLabel>
      <FirstAidStatement statementKey="physician" />
      <AlphabetLabel alphabet="G" indent={false} style={{ marginTop: 24 }}>
        General advice
      </AlphabetLabel>
      <FirstAidStatement
        statementKey="advice"
        defaultOptions={[
          {
            statementEn:
              'Consult a physician. Show this material safety data sheet to the doctor in attendance.',
            statementKo:
              '의사와 상의하십시오. 이 물질안전보건자료를 의사에게 보여주십시오.',
          },
        ]}
      />
    </MSDSFirstAidBlock>
  );
};

export default memo(MSDSFirstAid);
