import { ComponentProps } from 'react';
import { Collapse as AntdCollapse, Modal } from 'antd';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { MinusIcon } from '../general/icon';
import PrimaryIconWithBackground from 'components/ui/PrimaryIconWithBackground';
import { Flex } from 'components/ui';
import { Typography } from '..';
import Icon from 'components/ui/Icon/Icon';

export interface CollapseProps extends ComponentProps<typeof AntdCollapse> {
  activeKey?: string | string[] | undefined;
  onActiveKeyChange?: ComponentProps<typeof EnhancedCollapse>['onChange'];
}
export interface CollapsePanelProps
  extends Omit<ComponentProps<typeof AntdCollapse.Panel>, 'header'> {
  headerText?: string;
  canDelete?: boolean;
  headerIcon?: React.ReactNode;
  onDelete?: VoidFunction;
}

const EnhancedCollapse = styled(AntdCollapse)`
  &.ant-collapse {
    border: none;
    background-color: #fff;
    counter-reset: no;

    .ant-collapse-item {
      border-bottom: none;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .ant-collapse-header {
        background-color: ${palette.SLATE_GRAY10};
        padding: 18px 36px;
        border-radius: 8px;

        .ant-collapse-arrow {
          top: 0;
          right: 0;
          padding: 0;
        }
      }

      .ant-collapse-content {
        border-top: none;

        .ant-collapse-content-box {
          margin: 16px 0px;
          border: 1px solid #d3d3d3;
          border-radius: 8px;
          padding: 24px 32px;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        background-color: ${palette.LIGHT_BLUE20};
      }
    }
  }
`;

const Collapse = ({
  activeKey,
  onActiveKeyChange,
  ...props
}: CollapseProps) => {
  return (
    <EnhancedCollapse
      expandIconPosition="right"
      expandIcon={({ isActive }: { isActive?: boolean }) => (
        <Icon
          name={isActive ? 'up' : 'down'}
          size={20}
          style={{ position: 'absolute', top: 20, right: 36 }}
        />
      )}
      activeKey={activeKey}
      onChange={onActiveKeyChange}
      {...props}
    />
  );
};

Collapse.Panel = ({
  headerText,
  headerIcon,
  onDelete,
  canDelete,
  ...props
}: CollapsePanelProps) => {
  return (
    <AntdCollapse.Panel
      {...props}
      header={
        <div style={{ position: 'relative' }}>
          {canDelete && (
            <MinusIcon
              style={{ position: 'absolute', left: -70 }}
              onClick={(e) => {
                e.stopPropagation();

                Modal.confirm({
                  closable: true,
                  title: (
                    <Typography.Text style={{ fontSize: 14, fontWeight: 400 }}>
                      정말로 삭제하시겠습니까?
                    </Typography.Text>
                  ),
                  icon: null,
                  onOk: onDelete,
                  width: 400,
                  bodyStyle: {
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 14,
                    paddingTop: 24,
                    paddingBottom: 8,
                    fontWeight: 400,
                  },
                });
              }}
            />
          )}
          <Flex align="center" gap={8}>
            <PrimaryIconWithBackground>{headerIcon}</PrimaryIconWithBackground>
            <Typography.Text color="SLATE_GRAY70" medium>
              {headerText}
            </Typography.Text>
          </Flex>
        </div>
      }
    />
  );
};

export default Collapse;
