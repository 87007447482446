import { Dispatch, SetStateAction, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Input, message, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Typography } from 'components/system';
import NoticeCard from 'components/system/data/NoticeCard';
import FooterBox from 'components/FooterBox';
import ContractAccount from 'components/contract/ContractAccount';
import ContractDocuments from 'components/contract/ContractDocuments';
import { RootState } from 'modules';
import { ICIDEstimateParam } from 'types/material/icid/icid';
import {
  useNMPAContract,
  useNMPAMaterialCounts,
  useNMPAEstimateRawMaterialCount,
} from 'service/material/nmpa/nmpa';
import CheckBoxCard from 'components/system/data/CheckBoxCard';
import { CompanyType } from 'types/company';

const Container = styled.div``;

const NMPAContract = ({
  materialNmpaEstimateId,
  onChangeContractStep,
}: {
  materialNmpaEstimateId: number;
  onChangeContractStep: Dispatch<SetStateAction<'contract' | 'payment'>>;
}) => {
  const { company } = useSelector(
    ({ estimate, company }: RootState) => ({
      company: company.company as { addressKo: string } | null,
      estimate: estimate.estimate as ICIDEstimateParam | null,
    }),
    shallowEqual,
  );
  const [address, setAddress] = useState(company?.addressKo);
  const [isChecked, setIsChecked] = useState(false);
  const { addNMPAContract, isLoading } = useNMPAContract();

  const { nmpaMaterialCounts } = useNMPAMaterialCounts();
  const availableCount = nmpaMaterialCounts
    ? nmpaMaterialCounts.totalCount -
      (nmpaMaterialCounts.ongoingCount + nmpaMaterialCounts.finishCount)
    : 0;

  const { rawMaterialCount } = useNMPAEstimateRawMaterialCount({
    materialNmpaEstimateId,
  });

  const handleContract = () => {
    if (!address?.replaceAll(' ', '')) {
      message.warn('주소를 입력해 주세요.');
      return;
    }

    Modal.confirm({
      bodyStyle: {
        textAlign: 'center',
      },
      width: 400,
      icon: null,
      content: (
        <Typography.Text type="BODY_2">
          현재 취득 가능한 원료수 : {availableCount}개
          <br />
          의뢰하신 원료수 : {rawMaterialCount}개
          <br />
          계약 체결 후 입금 시: {availableCount + rawMaterialCount}개
          <br />
          해당 견적서의 신고번호 취득 계약을 체결하시겠습니까?
        </Typography.Text>
      ),
      okText: '계약 체결',
      onOk: () => {
        addNMPAContract(
          {
            materialNmpaEstimateId,
            postAddress: address,
            isAgreeContract: isChecked,
          },
          {
            onSuccess: () => {
              message.success('계약이 체결되었습니다.');
              onChangeContractStep('payment');
            },
          },
        );
      },
    });
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Container>
      <ContractDocuments />
      <ContractAccount />
      <NoticeCard
        title="계약서 받으실 주소"
        titleStyle={{ marginBottom: 16 }}
        style={{ marginTop: 24 }}
        bodyStyle={{ paddingLeft: 24 }}
      >
        <ul>
          <li>
            <Typography.Text type="BODY_2">
              송부해 주실 계약서 2부 중 1부를 certicos에서 확인하고 날인한 뒤,
              귀사로 다시 송부해 드릴 예정이오니 계약서 받으실 주소를 입력해
              주세요.
            </Typography.Text>
          </li>
          <li>
            <Typography.Text type="BODY_2">
              가입 시 입력한 사업장 소재지 주소가 기본으로 입력되어 있으니, 다른
              곳으로 받으실 경우 하단의 주소를 수정한 후 ‘계약 체결’ 버튼을
              눌러주세요.
            </Typography.Text>
          </li>
          <Form.Item
            colon={false}
            label={<Typography.Text type="TITLE_2">주소</Typography.Text>}
            style={{
              marginTop: 16,
              marginBottom: 8,
              position: 'relative',
              left: -12,
            }}
          >
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
        </ul>
      </NoticeCard>
      <CheckBoxCard companyType={CompanyType.MATERIAL} onChange={onChange} />
      <FooterBox>
        <Button
          key="contract"
          type="primary"
          size="large"
          loading={isLoading}
          onClick={handleContract}
          disabled={!isChecked}
        >
          계약 체결
        </Button>
      </FooterBox>
    </Container>
  );
};

export default NMPAContract;
