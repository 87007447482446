import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import ProductManufacturerInfo from 'components/product/ProductManufacturerInfo';
import { useEffect } from 'react';

const notices = [
  '등록된 제조사의 중소기업 여부를 확인하고, 중소기업이 아닐 경우 FEI 넘버를 등록해 주세요.',
  '중소기업이란 미국내 지난 3년간 매출이 평균 연 100만불 미만인 업체를 뜻합니다.',
  <MistakeNoticeItem />,
];

const ProductManufacturerInfoPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId()!;

  useEffect(() => {
    const rootPageTemplete = document.querySelector(
      '[class*="PageTemplateStyle"]',
    );
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate subtitle="제조사 정보" notices={notices}>
      <ProductManufacturerInfo productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductManufacturerInfoPage;
