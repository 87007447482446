import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import history from 'lib/history';
import path from 'lib/path';
import TermsRouter from 'routers/TermsRouter';

const TermsPage = () => {
  const {
    tab = 'service',
  }: {
    tab:
      | 'service'
      | 'privacy-process'
      | 'privacy-service'
      | 'privacy-third-party'
      | 'ci-bi-service';
  } = useParams();

  const handleChangeTab = (key: string) => {
    history.push(`${path.terms.root}/${key.split('_')[0].toLowerCase()}`);
  };

  return (
    <PageTemplate back={false} title={'서비스 정책'}>
      <Tabs activeKey={tab} onChange={handleChangeTab}>
        <Tabs.TabPane tab="이용약관" key={'service'} />
        <Tabs.TabPane tab="개인정보 처리방침" key={'privacy-process'} />
        <Tabs.TabPane tab="개인정보 수집·이용" key={'privacy-service'} />
        <Tabs.TabPane tab="개인정보 제3자 제공" key={'privacy-third-party'} />
        <Tabs.TabPane tab="로고 사용 및 게시 동의" key={'ci-bi-service'} />
      </Tabs>
      <TermsRouter />
    </PageTemplate>
  );
};

export default TermsPage;
