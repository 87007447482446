import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMediaQuery } from 'react-responsive';

import { s3AssetDomain } from 'lib/consts';
import { deviceSize } from 'lib/styles';
import COLOR from './colors';
import { Body2, H1, H3, H4 } from './introStyles';
import { Flex } from 'components/ui';

interface CardProp {
  title: string;
  content: string;
  iconName: string;
  companyName: string;
  name: string;
}

const cardData: CardProp[] = [
  {
    title: `certicos는 비전문가들도 수월하게 접할 수 있어 효용성이 뛰어나요!`,
    content: `국가 및 지역별로 안배된 전문가들의 뛰어난 업무 노하우와 정확한 업무 처리, 의뢰인에게 진행 정보를 공유하고 서비스하는 것 역시 우수하여 완료시점까지 편안히 기다릴 수 있었습니다. 또한, 의뢰 제품의 정보가 DB로 구축되도록 설계되었기에 기타 국가와 지역으로 등록업무를 추가할 경우 그 처리기간이 더욱 단축됨을 경험한 바 있습니다.`,
    iconName: '/intro_ico_ingr_x3.png',
    companyName: 'INGR',
    name: '이진석 상무',
  },
  {
    title: `certicos is\nfast and simple`,
    content: `certicos provided a fast and simple method to receive CPNP certification. certicos handled all necessary communication, letting us focus on sales and marketing. `,
    iconName: '/intro_ico_toun_x3.png',
    companyName: '톤28',
    name: '주세현 대리',
  },
  {
    title: `certicos는 열번 일할거 처음 한번만 잘 해놓으면 너무 편합니다.`,
    content: `인증할 국가도 기관도 끝없이 많아지는 요즘, 제출할 서류 체크하느라 망각한 본연의 업무를 찾아줍니다. 써보신 분들은 아시겠지만 열번 일할거 처음 한번만 잘 해놓으면 너무 편합니다. 화장품인증 업계 필수 플랫폼 선두 주자 certicos로 시간 손실을 막을 수 있습니다.`,
    iconName: '/intro_ico_nowcos_x3.png',
    companyName: '나우코스',
    name: '김종화 대리',
  },
  {
    title: `제조사 입장에서 업무가 많이 수월해졌습니다.`,
    content: `브랜드사와 커뮤니케이션까지 해주니 제조사 입장에서 업무가 많이 수월해졌습니다. 제조사가 확인해야 하는 법규등도 빠르게 확인할 수 있어서 너무 좋습니다.`,
    iconName: '/intro_ico_kolmar_x3.png',
    companyName: '콜마스크',
    name: '천봉영 본부장',
  },
  {
    title: `앞으로 K-beauty 발전에 크게 기여하리라 확신합니다.`,
    content: `각 국가별 인허가를 받기 위해 많은 시간과 중복되는 서류제출을 반복해야했는데 인증 자동화 플랫폼 개발이라니 너무나 참신합니다. 시간단축과 업무 효율성이 증대되게 되어 기쁩니다.`,
    iconName: '/intro_ico_oracle_x3.png',
    companyName: '오라클 코스메틱',
    name: '박승권 상무',
  },
  {
    title: `certicos는 인증의 완전체 프로그램 같아요.`,
    content: `중국, 유럽이 아닌 다른 국가의 인증을 진행할 때 많은 어려움들이 있었는데 certicos 통해 한꺼번에 해결할 수 있어서 좋았습니다. 대응도 빠르고 정보도 많고 담당자 분들도 경험이 많으신 분들이라 신뢰가 갔습니다. 지금까지 많은 인증을 진행해봤지만 이렇게 편하고 빠른 것은 처음입니다. `,
    iconName: '/intro_ico_jejuind_x3.png',
    companyName: '제주인디',
    name: '정다와 이사',
  },
];

const Container = styled.div`
  padding: 150px 128px;
  position: relative;
  background-color: ${COLOR.BG2};

  .splide__track {
    padding-bottom: 100px;

    @media ${deviceSize.sm} {
      padding-bottom: 36px;
    }
  }

  .splide__slide {
    overflow: visible;

    @media ${deviceSize.sm} {
      transform: scale(1) !important;
    }

    @media ${deviceSize.md} {
      transform: scale(1) !important;
    }
  }

  .splide__arrow,
  .splide__pagination__page {
    opacity: 1;
  }

  .splide__arrow--prev {
    left: -80px;

    > svg {
      transform: scale(1.5) rotate(-180deg);
    }
  }

  .splide__arrow--next {
    right: -80px;

    > svg {
      transform: scale(1.5);
    }
  }

  .splide__arrow--prev,
  .splide__arrow--next {
    background-color: ${COLOR.WHITE};
    border-radius: ${COLOR.C_MAIN};
    width: 58px;
    height: 58px;
    border: 1px solid ${COLOR.C_MAIN};

    > svg {
      fill: ${COLOR.C_MAIN};
    }

    &:hover {
      transition: all 0.5s;
      background-color: ${COLOR.C_MAIN};
      border: 1px solid ${COLOR.C_MAIN};

      > svg {
        transition: all 0.5s;
        fill: ${COLOR.WHITE};
      }
    }
  }

  @media ${deviceSize.sm} {
    padding: 70px 0px 110px;
  }
  @media ${deviceSize.md} {
    padding: 70px 0px 110px;
  }
`;

const Header = styled.div`
  padding: 0px 24px;
  margin-bottom: 80px;
  text-align: center;

  @media ${deviceSize.sm} {
    margin-bottom: 50px;
  }
  @media ${deviceSize.md} {
    margin-bottom: 50px;
  }

  > *:first-child {
    margin-bottom: 30px;

    @media ${deviceSize.sm} {
      margin-bottom: 20px;
    }

    @media ${deviceSize.md} {
      margin-bottom: 20px;
    }
  }
`;

const Title = styled(H4)`
  margin-bottom: 30px;
  height: 64px;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${deviceSize.sm} {
    word-break: keep-all;
    height: 72px;
    -webkit-line-clamp: 3;
    margin-bottom: 20px;
    font-size: 18px;
  }
`;

const Content = styled(Body2)`
  height: 227px;
  margin-bottom: 24px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${deviceSize.sm} {
    height: 220px;
    font-size: 15px;
  }
`;

const Body = styled.div`
  margin: auto;
  max-width: 1200px;

  .splide__pagination {
    display: none;
  }

  .splide__arrows {
    transform: translateY(240px);
  }

  @media ${deviceSize.sm} {
    max-width: 85%;

    .splide__pagination {
      display: block;
      text-align: center;
      transform: translateY(52px);

      > li {
        &:not(:first-child) {
          margin-left: 16px;
        }

        button {
          width: 10px;
          height: 10px;
          background-color: ${COLOR.TYPO6};

          &.is-active {
            transform: none;
            background-color: ${COLOR.C_MAIN};
          }
        }
      }
    }

    .splide__arrows {
      display: none;
    }
  }

  @media ${deviceSize.md} {
    max-width: 85%;

    .splide__pagination {
      display: block;
      text-align: center;
      transform: translateY(52px);

      > li {
        &:not(:first-child) {
          margin-left: 16px;
        }

        button {
          width: 10px;
          height: 10px;
          background-color: ${COLOR.TYPO6};

          &.is-active {
            transform: none;
            background-color: ${COLOR.C_MAIN};
          }
        }
      }
    }

    .splide__arrows {
      display: none;
    }
  }
`;

const ProfileWrapper = styled(Flex)`
  column-gap: 20px;
`;

const ProfileWhoWrapper = styled.div`
  > *:first-child {
    margin-bottom: 4px;
  }
`;

const IconWrapper = styled(Flex)`
  width: 66px;
  height: 66px;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const CompanyName = styled(Body2)`
  font-weight: bold;

  @media ${deviceSize.sm} {
    font-size: 15px;
  }
`;

const Name = styled(Body2)`
  font-size: 16px;
  background-color: ${COLOR.WHITE};

  @media ${deviceSize.sm} {
    font-size: 15px;
  }
`;

const CardContainer = styled.div`
  width: 100%;

  > div {
    padding: 40px;
    box-sizing: border-box;
    background-color: ${COLOR.WHITE};
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    white-space: pre-wrap;
    margin: auto;
    width: 100%;
    height: 484px;

    @media ${deviceSize.sm} {
      width: 280px;
      height: auto;
      padding: 24px;
    }
  }

  &#selected-splide {
    > div {
    }
  }
`;

const SectionG = () => {
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });
  const isMD = useMediaQuery({
    query: deviceSize.md,
  });
  const isMobileOrMD = isMobile || isMD;

  useEffect(() => {
    if (isMobileOrMD) return;

    const splideContainerElmt = document.querySelector<HTMLElement>(
      '.splide__track',
    );

    let lastSelectedTarget: HTMLElement | null = null;

    const handleAnimation = () => {
      if (!splideContainerElmt) return;

      const splideElmts = document.querySelectorAll<HTMLElement>(
        '.splide__slide',
      );
      const {
        x: parentX,
        width: parentWidth,
      } = splideContainerElmt.getBoundingClientRect();

      const splidesInScreen = [];

      for (const splideElmt of splideElmts) {
        const { x, width } = splideElmt.getBoundingClientRect();
        const criteriaX = (x + x + width) / 2;

        if (
          criteriaX >= parentX + parentWidth / 4 &&
          criteriaX <= parentX + parentWidth - parentWidth / 4
        ) {
          splidesInScreen.push(splideElmt);
        }
      }

      if (splidesInScreen.length >= 1) {
        const cardContainer = splidesInScreen[0].querySelector<HTMLElement>(
          ':scope > div',
        );
        if (lastSelectedTarget === cardContainer) return;

        if (lastSelectedTarget) {
          lastSelectedTarget.id = '';
        }

        if (cardContainer) {
          lastSelectedTarget = cardContainer;
          cardContainer.id = 'selected-splide';
        }
      }
    };

    const tmAnimation = setInterval(() => {
      handleAnimation();
    }, 50);

    return () => {
      clearInterval(tmAnimation);
      const selectedSplides = document.querySelectorAll('#selected-splide');
      for (const selectedSplide of selectedSplides) {
        selectedSplide.id = '';
      }
      if (lastSelectedTarget) lastSelectedTarget.id = '';
    };
  }, [isMobileOrMD]);

  const cardComponents = useMemo(
    () =>
      cardData.map((card) => (
        <SplideSlide key={card.companyName}>
          <Card {...card} />
        </SplideSlide>
      )),
    [],
  );

  return (
    <Container>
      <Header>
        <H1>생생한 후기</H1>
        <H3 color="TYPO4">
          1인 사업자부터 스타트업 그리고 {isMobileOrMD && <br />} 대기업까지
          믿고 사용합니다.
        </H3>
      </Header>
      <Body>
        <Splide
          options={{
            perPage: isMobileOrMD ? 1 : 3,
            gap: '2rem',
            type: 'loop',
            perMove: 1,
            autoplay: true,
            interval: 5000,
            speed: 1200,
          }}
        >
          {cardComponents}
        </Splide>
      </Body>
    </Container>
  );
};

const Card = ({ title, content, iconName, companyName, name }: CardProp) => {
  return (
    <CardContainer>
      <div>
        <Title bold>{title}</Title>
        <Content>{content}</Content>
        <ProfileWrapper align="center">
          <IconWrapper justify="center" align="center">
            <img src={`${s3AssetDomain}${iconName}`} alt="company logo" />
          </IconWrapper>
          <ProfileWhoWrapper>
            <CompanyName>{companyName}</CompanyName>
            <Name>{name}</Name>
          </ProfileWhoWrapper>
        </ProfileWrapper>
      </div>
    </CardContainer>
  );
};

export default SectionG;
