import { Button, Col, message, Row, Space, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import usePreviewModal from 'hook/usePreviewModal';
import history from 'lib/history';
import path from 'lib/path';
import { useNMPARequestList } from 'service/material/nmpa/nmpa';
import {
  NMPAContractStatus,
  NMPADepositStatus,
  NMPARequestListItem,
  NMPARequestListPageState,
} from 'types/material/nmpa/nmpa';
import NMPAMaterialStats from './NMPAMaterialStats';

const Container = styled.div`
  .ant-table-container table tr {
    td {
      height: 76px;
      padding: 4px;
    }

    th {
      height: 40px;
      line-height: 1;
    }
  }

  .ant-tabs-tab {
    justify-content: center;
    width: 148px;
    font-size: 16px;
    color: #a9a6a6;
  }

  .ant-tabs-ink-bar {
    height: 4px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    font-weight: 500;
  }
`;

const contractStatusOptions = [
  { title: '전체', key: NMPAContractStatus.ALL },
  { title: '계약 체결 전', key: NMPAContractStatus.ISS_FIN },
  { title: '계약 체결 완료', key: NMPAContractStatus.CON_FIN },
];

const NMPARequestList = ({
  pageState,
  onChangePageState,
}: {
  pageState: NMPARequestListPageState;
  onChangePageState: (state: NMPARequestListPageState) => void;
}) => {
  const { page, contractStatus } = pageState;
  const [selectedRequestIds, setSelectedRequestIds] = useState<number[]>([]);
  const [documentName, setDocumentName] = useState('');
  const { openAlertModal } = useModal();

  const { PreviewModal, setUrl } = usePreviewModal({
    title: '견적서 확인',
  });

  const {
    nmpaRequestListItems,
    total,
    isLoading,
    isDeleteLoading,
    deleteNMPARequest,
  } = useNMPARequestList({
    page,
    contractStatus,
  });

  const handleDelete = () => {
    if (selectedRequestIds.length) {
      openAlertModal({
        content: (
          <div>
            취득 의뢰 리스트에서
            <br />
            견적서를 삭제하시겠습니까?
          </div>
        ),
        onOk: () => deleteNMPARequest(selectedRequestIds),
        okLoading: isDeleteLoading,
      });
    }
  };

  const handleChangeContractStatus = useCallback(
    (contractStatus: NMPAContractStatus) => {
      onChangePageState({
        page: 1,
        contractStatus,
      });
    },
    [page],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        contractStatus,
      });
    },
    [contractStatus],
  );

  const previewDocument = (fileUrl: string, documentName: string) => {
    if (!fileUrl) {
      message.warning('파일이 존재하지 않습니다.');

      return;
    }

    setDocumentName(documentName);
    setUrl(fileUrl);
  };

  const columns: ColumnsType<NMPARequestListItem> = [
    {
      title: '견적서 번호',
      align: 'center',
      width: 176,
      render: (_, { estimateCode }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {estimateCode}
        </Typography.Text>
      ),
    },
    {
      title: '원료 개수',
      align: 'center',
      width: 120,
      dataIndex: 'rawMaterialCount',
    },
    {
      title: '견적서 등록일',
      align: 'center',
      width: 160,
      render: (_, { registerDt }) =>
        `${registerDt.slice(0, 10)} ${registerDt.slice(11)}`,
    },
    {
      title: '계약',
      align: 'center',
      width: 80,
      render: (_, { status, materialNmpaEstimateId, isExpire }) => {
        if (isExpire) {
          return <Tags.Mark color="gray">유효기간 만료</Tags.Mark>;
        } else if (status === NMPAContractStatus.CON_FIN) {
          return (
            <Typography.Text
              type="BODY_2"
              onClick={() => {
                history.push(
                  `${path.material.nmpa.estimate.root}/${materialNmpaEstimateId}/contract`,
                  { status },
                );
              }}
            >
              계약 완료
            </Typography.Text>
          );
        } else {
          return (
            <Tags.Mark
              type="arrow"
              color="red"
              onClick={() => {
                history.push(
                  `${path.material.nmpa.estimate.root}/${materialNmpaEstimateId}/contract`,
                  { status },
                );
              }}
            >
              계약하기
            </Tags.Mark>
          );
        }
      },
    },
    {
      title: '진행 현황',
      align: 'center',
      width: 120,
      render: (_, { status, depositStatus, receivedContract }) =>
        status === NMPAContractStatus.CON_FIN ? (
          <Row
            style={{ flexDirection: 'column' }}
            align="middle"
            gutter={[4, 4]}
          >
            <Col>
              <Tags.Mark
                color={
                  depositStatus === NMPADepositStatus.FINISH
                    ? 'green'
                    : 'lightGray'
                }
                style={{ width: 95 }}
              >
                {depositStatus === NMPADepositStatus.FINISH
                  ? '입금 확인 완료'
                  : '입금 확인 중'}
              </Tags.Mark>
            </Col>
            <Col>
              <Tags.Mark
                color={receivedContract ? 'green' : 'lightGray'}
                style={{ width: 95 }}
              >
                {receivedContract ? '계약서 수령 완료' : '계약서 수령 전'}
              </Tags.Mark>
            </Col>
          </Row>
        ) : (
          <Tags.Mark style={{ width: 95 }} color="lightGray">
            계약 체결 전
          </Tags.Mark>
        ),
    },
    {
      title: '견적서 확인',
      width: 120,
      align: 'center',
      render: (_, { estimateFileUrl, estimateCode }) => (
        <Icon
          name="page"
          size={22}
          style={{ margin: '0 auto' }}
          onClick={() => previewDocument(estimateFileUrl, estimateCode)}
        />
      ),
    },
  ];

  const deleteAvailableCount = useMemo(
    () =>
      nmpaRequestListItems.filter(
        (item) => item.status !== NMPAContractStatus.CON_FIN,
      ).length,
    [nmpaRequestListItems],
  );

  return (
    <Container>
      <PreviewModal documentName={documentName} />
      <NMPAMaterialStats />
      <Tabs
        defaultActiveKey={contractStatus}
        onChange={(key) =>
          handleChangeContractStatus(key as NMPAContractStatus)
        }
        tabBarStyle={{ margin: '24px 0 0' }}
        tabBarGutter={0}
      >
        {contractStatusOptions.map(({ title, key }) => (
          <Tabs.TabPane tab={title} key={key}>
            <Table
              style={{ marginTop: 24 }}
              loading={isLoading}
              dataSource={nmpaRequestListItems}
              columns={columns}
              pagination={{
                current: page,
                onChange: handleChangePage,
                total: total,
              }}
              rowSelection={{
                selectedRowKeys: selectedRequestIds,
                onChange: (keys) => {
                  setSelectedRequestIds(keys.map((key) => Number(key)));
                },
                getCheckboxProps: (record) => ({
                  disabled: record.status === NMPAContractStatus.CON_FIN,
                }),
              }}
              rowKey="materialNmpaEstimateId"
              footer={() => (
                <Space
                  align="center"
                  style={{
                    marginTop: '16px',
                    width: '100%',
                  }}
                >
                  <Button
                    type="default"
                    icon={
                      <Icon
                        name="delete"
                        color={
                          deleteAvailableCount === 0 ? 'GRAY40' : 'PRIMARY50'
                        }
                        size={16}
                        style={{ marginRight: 4 }}
                      />
                    }
                    disabled={deleteAvailableCount === 0}
                    onClick={handleDelete}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    선택 삭제
                  </Button>
                </Space>
              )}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Container>
  );
};

export default NMPARequestList;
