import { Link, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Form, Tabs } from 'antd';

import path from 'lib/path';
import { useForceUpdate } from 'lib/hook';
import { scrollToFirstErrorOption } from 'lib/consts';
import FooterBox from 'components/FooterBox';
import SuperUserForm from 'components/auth/SuperUserForm';
import ManuForm from './ManuForm';
import { NoticeCard } from 'components/system';

const ManuRegisterForm = ({
  tab,
  onChangeTab,
  updateMode,
  form,
  manufacturers,
  firstCGMP,
  secondCGMP,
  thirdCGMP,
  originalSecondFactory,
  originalThirdFactory,
  submitLoading,
  onSubmit,
  onClickChangePassword,
  onClickAttachCGMP,
  bizLicenseEnUrl,
}: any) => {
  const mypageRoute = useRouteMatch(path.my);
  const isMyPage = path.my === mypageRoute?.path;

  const handleClickNext = () => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        onChangeTab('user');
      })
      .catch((error: any) => {
        form.scrollToField(
          error.errorFields[0].name[0],
          scrollToFirstErrorOption,
        );
      });
  };
  const handleClickBack = () => {
    onChangeTab('company');
  };
  const forceUpdate = useForceUpdate();
  return (
    <ManuRegisterFormBlock updateMode={updateMode}>
      {isMyPage ? (
        <StyledTabs defaultActiveKey={'company'}>
          <Tabs.TabPane key="company" tab="회사 정보">
            <Form
              form={form}
              colon={false}
              layout="vertical"
              scrollToFirstError={scrollToFirstErrorOption}
              onFinish={onSubmit}
              onValuesChange={(changedValues) => {
                if (
                  'firstAddressKo' in changedValues ||
                  'firstAddressEn' in changedValues ||
                  'secondAddressKo' in changedValues ||
                  'secondAddressEn' in changedValues ||
                  'thirdAddressKo' in changedValues ||
                  'thirdAddressEn' in changedValues
                ) {
                  forceUpdate();
                }
              }}
              style={{ maxWidth: 688, margin: '0 auto' }}
            >
              <ManuForm
                visible={updateMode || tab === 'company'}
                form={form}
                updateMode={updateMode}
                manufacturers={manufacturers}
                firstCGMP={firstCGMP}
                secondCGMP={secondCGMP}
                thirdCGMP={thirdCGMP}
                originalSecondFactory={originalSecondFactory}
                originalThirdFactory={originalThirdFactory}
                onClickAttachCGMP={onClickAttachCGMP}
                bizLicenseEnUrl={bizLicenseEnUrl}
              />
              <FooterBox>
                {!updateMode && tab === 'company' && (
                  <Button>
                    <Link to={path.login}>취소</Link>
                  </Button>
                )}
                {!updateMode && tab === 'user' && (
                  <Button onClick={handleClickBack}>뒤로</Button>
                )}
                {!updateMode && tab === 'company' && (
                  <Button type="primary" onClick={handleClickNext}>
                    다음
                  </Button>
                )}
                {(updateMode || tab === 'user') && (
                  <Button
                    type="primary"
                    loading={submitLoading}
                    htmlType="submit"
                  >
                    {!updateMode ? '회원가입' : '수정'}
                  </Button>
                )}
              </FooterBox>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane key="user" tab="슈퍼유저 정보">
            <NoticeCard title="안내사항" style={{ marginTop: 0 }}>
              <ul>
                <li>
                  슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능하며, 모든
                  영역에서 최고 권한을 가진 계정입니다.
                </li>
                <li>슈퍼유저는 하위 담당자를 추가하거나 삭제할 수 있습니다.</li>
              </ul>
            </NoticeCard>
            <Form
              form={form}
              colon={false}
              layout="vertical"
              scrollToFirstError={scrollToFirstErrorOption}
              onFinish={onSubmit}
              onValuesChange={(changedValues) => {
                if (
                  'firstAddressKo' in changedValues ||
                  'firstAddressEn' in changedValues ||
                  'secondAddressKo' in changedValues ||
                  'secondAddressEn' in changedValues ||
                  'thirdAddressKo' in changedValues ||
                  'thirdAddressEn' in changedValues
                ) {
                  forceUpdate();
                }
              }}
              style={{ maxWidth: 688, margin: '0 auto' }}
            >
              {(updateMode || tab === 'user') && (
                <SuperUserForm
                  form={form}
                  updateMode={updateMode}
                  onClickChangePassword={onClickChangePassword}
                  onClickBack={handleClickBack}
                />
              )}
              <FooterBox>
                {!updateMode && tab === 'company' && (
                  <Button>
                    <Link to={path.login}>취소</Link>
                  </Button>
                )}
                {!updateMode && tab === 'user' && (
                  <Button onClick={handleClickBack}>뒤로</Button>
                )}
                {!updateMode && tab === 'company' && (
                  <Button type="primary" onClick={handleClickNext}>
                    다음
                  </Button>
                )}
                {(updateMode || tab === 'user') && (
                  <Button
                    type="primary"
                    loading={submitLoading}
                    htmlType="submit"
                  >
                    {!updateMode ? '회원가입' : '수정'}
                  </Button>
                )}
              </FooterBox>
            </Form>
          </Tabs.TabPane>
        </StyledTabs>
      ) : (
        <Form
          form={form}
          colon={false}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          onFinish={onSubmit}
          onValuesChange={(changedValues) => {
            if (
              'firstAddressKo' in changedValues ||
              'firstAddressEn' in changedValues ||
              'secondAddressKo' in changedValues ||
              'secondAddressEn' in changedValues ||
              'thirdAddressKo' in changedValues ||
              'thirdAddressEn' in changedValues
            ) {
              forceUpdate();
            }
          }}
          style={{ maxWidth: 688, margin: '0 auto' }}
        >
          <ManuForm
            visible={updateMode || tab === 'company'}
            form={form}
            updateMode={updateMode}
            manufacturers={manufacturers}
            firstCGMP={firstCGMP}
            secondCGMP={secondCGMP}
            thirdCGMP={thirdCGMP}
            originalSecondFactory={originalSecondFactory}
            originalThirdFactory={originalThirdFactory}
            onClickAttachCGMP={onClickAttachCGMP}
            bizLicenseEnUrl={bizLicenseEnUrl}
          />
          {(updateMode || tab === 'user') && (
            <SuperUserForm
              form={form}
              updateMode={updateMode}
              onClickChangePassword={onClickChangePassword}
              onClickBack={handleClickBack}
            />
          )}
          <FooterBox>
            {!updateMode && tab === 'company' && (
              <Button>
                <Link to={path.login}>취소</Link>
              </Button>
            )}
            {!updateMode && tab === 'user' && (
              <Button onClick={handleClickBack}>뒤로</Button>
            )}
            {!updateMode && tab === 'company' && (
              <Button type="primary" onClick={handleClickNext}>
                다음
              </Button>
            )}
            {(updateMode || tab === 'user') && (
              <Button type="primary" loading={submitLoading} htmlType="submit">
                {!updateMode ? '회원가입' : '회원정보 수정'}
              </Button>
            )}
          </FooterBox>
        </Form>
      )}
    </ManuRegisterFormBlock>
  );
};

const ManuRegisterFormBlock = styled.div<{ updateMode: boolean }>`
  max-width: 1040px;
  margin: 0 auto;
  ${(props) =>
    !props.updateMode &&
    css`
      padding: 40px 0;
    `}
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;
    cursor: pointer;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

export default ManuRegisterForm;
