import { useForm } from 'antd/lib/form/Form';
import { useMemo } from 'react';
import { message } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { VCRPAccountParam, VCRPAccountRequest } from 'types/brand/vcrp';
import history from 'lib/history';
import * as productApi from 'lib/api/product';
import { useProductDocStatus } from 'service/product';
import { DocumentCode } from 'types/product';
import useGA, { EGAActionType } from 'hook/useGA';

const queryKeys = {
  saleInfoKey: 'product/getSaleInfo',
  vcrpAccountKey: 'product/getVCRPAccount',
  vcrpAccountRequestKey: 'product/getVCRPAccountRequest',
  fdaCertificatedColorKey: 'product/getFDACertificatedColor',
};

// 미국내 제품 판매 정보
export const useSaleInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.UPSI,
  });

  const { data: saleInfo = null, isLoading: getSaleInfoLoading } = useQuery(
    [queryKeys.saleInfoKey, productId, countryId],
    () => productApi.getSaleInfo({ productId, countryId }),
    { select: (res) => res.data.result },
  );

  const { mutate: addSaleInfo } = useMutation(
    (formValues: FormData) =>
      productApi.addSaleInfo({ productId, countryId, formValues }),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '미국내 제품 판매 정보',
          actionType: EGAActionType.REGISTER,
        });
        queryClient.refetchQueries([
          queryKeys.saleInfoKey,
          productId,
          countryId,
        ]);
        message.success('등록되었습니다.');
        history.goBack();
      },
    },
  );

  const { mutate: updateSaleInfo } = useMutation(
    (formValues: FormData) =>
      productApi.updateSaleInfo({ productId, countryId, formValues }),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '미국내 제품 판매 정보',
          actionType: EGAActionType.MODIFY,
        });
        queryClient.refetchQueries([
          queryKeys.saleInfoKey,
          productId,
          countryId,
        ]);
        message.success('보완 완료되었습니다.');
        history.goBack();
      },
    },
  );

  return useMemo(
    () => ({
      form,
      saleInfo,
      isLoading: getSaleInfoLoading,
      addSaleInfo,
      updateSaleInfo,
      updateMode: saleInfo !== null && productDocStatus?.status !== 'INP',
      readOnlyMode: saleInfo !== null && productDocStatus?.status !== 'MOD',
    }),
    [
      form,
      saleInfo,
      getSaleInfoLoading,
      addSaleInfo,
      updateSaleInfo,
      productDocStatus,
    ],
  );
};

// 미국 색소인증 서류
export const useFDACertificatedColor = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const queryClient = useQueryClient();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.FCCAD,
  });

  const {
    data: fdaCertificatedColor = [],
    isLoading: getFDACertificatedColor,
  } = useQuery(
    [queryKeys.fdaCertificatedColorKey, productId, countryId],
    () => productApi.getFDACertificatedColor({ productId, countryId }),
    { select: (res) => res.data.result },
  );

  const { mutate: addFDACertificatedColor } = useMutation(
    (formValues: FormData) =>
      productApi.addFDACertificatedColor({ productId, countryId, formValues }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          queryKeys.fdaCertificatedColorKey,
          productId,
          countryId,
        ]);
      },
    },
  );

  return useMemo(
    () => ({
      fdaCertificatedColor,
      addFDACertificatedColor,
      isLoading: getFDACertificatedColor,
      updateMode:
        fdaCertificatedColor.length !== 0 && productDocStatus?.status !== 'INP',
      readOnlyMode:
        fdaCertificatedColor.length !== 0 && productDocStatus?.status !== 'MOD',
    }),
    [
      fdaCertificatedColor,
      addFDACertificatedColor,
      getFDACertificatedColor,
      productDocStatus,
    ],
  );
};

// VCRP 계정
export const useVCRPAccount = () => {
  const {
    data: vcrpAccount = null,
    isLoading: getVCRPAccountLoading,
  } = useQuery([queryKeys.vcrpAccountKey], productApi.getVCRPAccount, {
    select: (res) => res.data.result,
  });

  return useMemo(() => ({ vcrpAccount, getVCRPAccountLoading }), [
    vcrpAccount,
    getVCRPAccountLoading,
  ]);
};

export const useAddVCRPAccount = () => {
  const queryClient = useQueryClient();

  const { mutate: addVCRPAccount } = useMutation(
    ({ vcrpAccount, vcrpPassword }: Omit<VCRPAccountParam, 'vcrpAccountId'>) =>
      productApi.addVCRPAccount({ vcrpAccount, vcrpPassword }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(queryKeys.vcrpAccountKey);
        message.success('입력되었습니다.');
      },
    },
  );

  return useMemo(() => ({ addVCRPAccount }), [addVCRPAccount]);
};

export const useUpdateVCRPAccount = () => {
  const queryClient = useQueryClient();

  const { mutate: updateVCRPAccount } = useMutation(
    ({ vcrpAccountId, vcrpAccount, vcrpPassword }: VCRPAccountParam) =>
      productApi.updateVCRPAccount({
        vcrpAccountId,
        vcrpAccount,
        vcrpPassword,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(queryKeys.vcrpAccountKey);
        message.success('변경되었습니다.');
      },
    },
  );

  return useMemo(() => ({ updateVCRPAccount }), [updateVCRPAccount]);
};

// 제품에 등록된 VCRP 계정
export const useProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.VCRPAR,
  });

  const {
    data: productVCRPAccount = null,
    isLoading: getProductVCRPAccountLoading,
  } = useQuery(
    ['product/getProductVCRPAccount', productId, countryId],
    () => productApi.getProductVCRPAccount({ productId, countryId }),
    { select: (res) => res.data.result },
  );

  const { mutate: addProductVCRPAccount } = useMutation(
    () => productApi.addProductVCRPAccount({ productId, countryId }),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '보유 VCRP 계정 정보',
          actionType:
            productVCRPAccount !== null && productDocStatus?.status !== 'INP'
              ? EGAActionType.MODIFY
              : EGAActionType.REGISTER,
        });
        if (productVCRPAccount !== null && productDocStatus?.status !== 'INP') {
          message.success('보완 완료되었습니다.');
        } else {
          message.success('등록되었습니다.');
        }
        history.goBack();
      },
    },
  );

  return useMemo(
    () => ({
      productVCRPAccount,
      addProductVCRPAccount,
      getProductVCRPAccountLoading,
      updateMode:
        productVCRPAccount !== null && productDocStatus?.status !== 'INP',
      readOnlyMode:
        productVCRPAccount !== null && productDocStatus?.status !== 'MOD',
    }),
    [
      productVCRPAccount,
      addProductVCRPAccount,
      getProductVCRPAccountLoading,
      productDocStatus,
    ],
  );
};

// VCRP 계정 생성 요청
export const useVCRPAccountRequest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.VCRPAC,
  });

  const {
    data: vcrpAccountRequest = null,
    isLoading: getVCRPAccountRequestLoading,
  } = useQuery(
    [queryKeys.vcrpAccountRequestKey, productId, countryId],
    () => productApi.getVCRPAccountRequest({ productId, countryId }),
    { select: (res) => res.data.result },
  );

  const { mutate: addVCRPAccountRequest } = useMutation(
    (formValues: VCRPAccountRequest) =>
      productApi.addVCRPAccountRequest({ productId, countryId, formValues }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          queryKeys.vcrpAccountRequestKey,
          productId,
          countryId,
        ]);
      },
    },
  );

  const { mutate: updateVCRPAccountRequest } = useMutation(
    (formValues: VCRPAccountRequest) =>
      productApi.updateVCRPAccountRequest({ productId, countryId, formValues }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          queryKeys.vcrpAccountRequestKey,
          productId,
          countryId,
        ]);
      },
    },
  );

  return useMemo(
    () => ({
      form,
      vcrpAccountRequest,
      addVCRPAccountRequest,
      updateVCRPAccountRequest,
      updateMode:
        vcrpAccountRequest !== null && productDocStatus?.status !== 'INP',
      readOnlyMode:
        vcrpAccountRequest !== null && productDocStatus?.status !== 'MOD',
      isLoading: getVCRPAccountRequestLoading,
    }),
    [
      form,
      vcrpAccountRequest,
      addVCRPAccountRequest,
      updateVCRPAccountRequest,
      getVCRPAccountRequestLoading,
      productDocStatus,
    ],
  );
};
