import { Form, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProductBasicForm from 'components/product/ProductBasicForm';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { setCurrentProduct } from 'modules/product';
import { useGetDuplicatedProduct } from 'service/brand/product/product';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { useProductBasic } from 'service/product';

const ProductBasic = () => {
  const [isNetWeightUnitDirect, setIsNetWeightUnitDirect] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { openAlertModal } = useModal();

  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { user, company } = useSelector(
    ({ auth, company }: any) => ({
      company: company.company,
      user: auth.user,
    }),
    shallowEqual,
  );
  const { companyId } = user;

  const {
    getDuplicatedProduct,
    getDuplicatedProductLoading,
  } = useGetDuplicatedProduct();
  const handleSubmit = () => {
    let {
      productNameEn,
      netWeightUnit,
      netWeight,
      netWeightUnitDirect,
    } = form.getFieldsValue();
    if (isNetWeightUnitDirect) netWeightUnit = netWeightUnitDirect;
    if (!netWeightUnit) {
      message.warning(
        isNetWeightUnitDirect
          ? '단위를 입력해 주세요.'
          : '단위를 선택해 주세요.',
      );
      return;
    }

    getDuplicatedProduct(
      {
        productNameEn,
        netWeight,
        netWeightUnit,
      },
      {
        onSuccess: (response) => {
          const { isDuplicate } = response.data.result;
          if (!isDuplicate) {
            openAlertModal({
              content: (
                <>
                  <span style={{ color: palette.PRIMARY50 }}>*</span>
                  화장품제조업자(영문)와{' '}
                  <span style={{ color: palette.PRIMARY50 }}>*</span>
                  화장품제조업자 주소(영문)를 cGMP 또는 ISO22716에 등록된
                  영문명과 영문 주소로
                  <br /> 동일하게 입력하셨습니까?
                </>
              ),
              okText: '네, 동일하게 입력했습니다.',
              closeText: '아니오',
              onOk: () => submitProduct(),
            });
          } else {
            openAlertModal({
              content: `이미 사용중인 제품명입니다.\n다른 제품명을 사용하시거나 고객센터로 문의해주세요.`,
              noCancelButton: true,
            });
          }
        },
      },
    );
  };

  const { addProductBasic, addProductBasicLoading } = useProductBasic();
  const submitProduct = () => {
    let {
      netWeightUnitDirect,
      netWeightUnit,
      ...restFormValues
    } = form.getFieldsValue();
    if (isNetWeightUnitDirect) netWeightUnit = netWeightUnitDirect;

    addProductBasic(
      {
        ...restFormValues,
        netWeightUnit,
        manufacturerNameKo: manufacturers.find(
          ({ manufacturerId }) =>
            manufacturerId === form.getFieldValue('manufacturerId'),
        )?.companyNameKo,
        companyId,
      },
      {
        onSuccess: (response) => {
          const product = response.data.result;
          dispatch(setCurrentProduct(product));
          history.replace(
            `${path.retailer.retailerApply.product.cat}/${product.productId}`,
          );
        },
      },
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      brandCompanyNameKo: company.companyNameKo,
      brandCompanyNameEn: company.companyNameEn,
      brandCompanyAddressKo: company.addressKo,
      brandCompanyAddressEn: company.addressEn,
    });
  }, [company]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        brandNameEn: 'Dowith',
        productNameKo: '두윗 세럼',
        productNameEn: `Dowith Serum-${moment().format('YY-MM-DD HH:mm')}`,
        manufacturerNameEn: 'LK Systems',
        manufacturerAddressEn:
          '12-11 Doekgogae-gil, Jeonui-myeon, Sejong Metropolitan Autonomous City',
        manufacturerTel: '0212345678',
        manufacturerZipCode: '123321',
        netWeight: 10,
        netWeightUnit: 'mL',
      });
    }
  }, []);

  return (
    <ProductBasicForm
      form={form}
      fetchLoading={getDuplicatedProductLoading || addProductBasicLoading}
      manufacturers={manufacturers}
      onFormFinished={handleSubmit}
      isNetWeightUnitDirect={isNetWeightUnitDirect}
      onChangeIsNetWeightUnitDirect={setIsNetWeightUnitDirect}
    />
  );
};

export default ProductBasic;
