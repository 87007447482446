import { Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import ManagerForm from 'components/auth/ManagerForm';
import { Typography } from 'components/system';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRegisterUser, useUserType } from 'service/auth';
import { useCompanyType } from 'service/company';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { CompanyType } from 'types/company';

const ManagerRegisterModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (isSuccess?: boolean) => void;
}) => {
  const [form] = useForm();
  const { registerUser, registerLoading } = useRegisterUser();
  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { companyId, manufacturerId } = useSelector(
    ({ auth }: any) => ({
      companyId: auth.user.companyId,
      manufacturerId: auth.user.manufacturerId,
    }),
    shallowEqual,
  );
  const companyType = useCompanyType();
  const userType = useUserType();

  const handleSubmit = (userForm: any) => {
    registerUser(
      {
        companyId,
        isSuperUser: false,
        ...userForm,
        ...(companyType === CompanyType.MANUFACTURE && { manufacturerId }),
        userType: userForm.userType || userType,
        password: '1234',
      },
      { onSuccess: () => onClose(true) },
    );
  };

  useEffect(() => {
    if (visible && process.env.NODE_ENV !== 'development') {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        username: 'test_manager',
        name: '둘리',
        deptName: '고길동괴롭힘팀',
        positionName: '한량',
        manufacturerId: 1,
        email: 'test@gmail.com',
        mobile: '01011112222',
      });
    }
  }, []);

  return (
    <Modal
      width={600}
      visible={visible}
      footer={null}
      onCancel={() => onClose()}
    >
      <Typography.Text type="TITLE_1" gutter={{ bottom: 40 }}>
        담당자 추가
      </Typography.Text>
      <ManagerForm
        updateMode={false}
        directMode={false}
        form={form}
        manufacturers={manufacturers}
        submitLoading={registerLoading}
        deleteLoading={false}
        onSubmit={handleSubmit}
        onCancel={onClose}
        onDelete={() => undefined}
        onResetPassword={() => undefined}
      />
    </Modal>
  );
};

export default ManagerRegisterModal;
