import qs from 'qs';

import client from 'lib/api/client';
import { APIPageableResponse } from 'types/common';
import {
  Product,
  ProductBasicAdd,
  ProductBasicUpdate,
  ProductSearchParams,
  ProductInputRequest,
  ProductInputRequestUser,
  ManufacturerProduct,
} from 'types/manufacturer/product';
import { APIResponse } from 'types/common';

export const getProduct = (productId: number) =>
  client.get<APIResponse<ManufacturerProduct>>(
    `/manufacturer/products/${productId}`,
  );

export const getProductForCheckDuplicate = (params: {
  labNo: string;
  netWeight: number;
  netWeightUnit: string;
}) =>
  client.get<APIResponse<Product>>(
    `/manufacturer/product?${qs.stringify(params)}`,
  );

export const addProductBasic = (productBasic: ProductBasicAdd) =>
  client.post('/manufacturer/product', productBasic);

export const updateProductBasic = (productBasic: ProductBasicUpdate) => {
  const { productId, ...rest } = productBasic;
  return client.patch(`/manufacturer/product/${productId}`, rest);
};

export const getProducts = ({
  page,
  searchType,
  searchKeyword,
  status,
}: ProductSearchParams) =>
  client.post<APIPageableResponse<any>>(
    `/manufacturer/product/all/${page}/10`,
    {
      [searchType]: searchKeyword,
      status,
    },
  );

export const issueAuthCode = (productId: number) =>
  client.patch(`/manufacturer/product/issue/authcode/${productId}`);

export const getProductInputRequestableUsers = ({
  productId,
  documentCode,
}: {
  productId: number;
  documentCode: string;
}) =>
  client.get<APIResponse<ProductInputRequestUser[]>>(
    `/manufacturer/products/${productId}/input-request/users?${qs.stringify({
      documentCode,
    })}`,
  );

export const postProductInputRequest = ({
  productId,
  documentCode,
  toUserId,
}: {
  productId: number;
  documentCode: string;
  toUserId: number;
}) =>
  client.post<APIResponse<null>>(
    `/manufacturer/products/${productId}/input-requests`,
    {
      documentCode,
      toUserId,
    },
  );
export const updateProductInputRequest = ({
  inputRequestId,
  status,
}: {
  inputRequestId: number;
  status: 'CANCEL' | 'REFUSE';
}) =>
  client.patch<APIResponse<null>>(
    `/manufacturer/input-requests/${inputRequestId}`,
    { status },
  );
export const getProductInputRequest = (inputRequestId: number) =>
  client.get<APIResponse<ProductInputRequest>>(
    `/manufacturer/input-requests/${inputRequestId}`,
  );
