import { useLayoutEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Col, Form, message, Row } from 'antd';

import { messages } from 'lib/consts';
import { createFormData } from 'lib/file';
import UpdateLog from 'components/product/UpdateLog';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import ProductAllergensList from 'components/product/ProductAllergensList';
import {
  useAllergensList,
  useUpdateAllergensListOld,
} from 'service/brand/product/allergensList';
import { useCertificateMode } from 'hook/certificate';
import history from 'lib/history';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';

const ProductAllergensListContainer = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const certificateMode = useCertificateMode();
  const countryId = useCountryId();
  const [form] = Form.useForm();
  const { readOnlyMode, submitLoading } = useSelector(
    ({ product, certificate, loading }) => ({
      currentProduct: product.currentProduct,
      readOnlyMode: certificate.readOnlyMode,
      submitLoading:
        loading['product/ADD_PRODUCT_ALLERGENS_LIST'] ||
        loading['product/UPDATE_PRODUCT_ALLERGENS_LIST'],
    }),
    shallowEqual,
  );

  const { allergensList, isLoading: getLoading } = useAllergensList(
    productId,
    countryId,
  );

  const { updateAllergensListOld } = useUpdateAllergensListOld();

  const updateMode = allergensList !== null;

  const [file, setFile] = useState(null);
  const handleDelete = () => {
    setFile(null);
  };
  const handleSubmit = ({ isOdourless, isNonAllergenOdour }) => {
    if (!isOdourless && !file) {
      return message.warning(messages.REQUIRED_FILE);
    }
    if (!updateMode) {
      message.warn(
        '알러젠 리스트 페이지가 업데이트되어 사용이 불가합니다. 관리자에게 문의부탁드립니다.',
      );
    } else {
      const updateFormValues = {
        ...(allergensList.isOdourless !== isOdourless && { isOdourless }),
        ...(!isOdourless &&
          allergensList.isNonAllergenOdour !== isNonAllergenOdour && {
            isNonAllergenOdour,
          }),
        ...(!isOdourless && !file.url && { documentFile: file }),
      };
      if (Object.keys(updateFormValues).length === 0) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
      updateAllergensListOld(
        createFormData({
          productId,
          ...(countryId && { countryId }),
          ...updateFormValues,
        }),
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Allergens List',
              actionType: certificateMode
                ? EGAActionType.MODIFY
                : EGAActionType.UPDATE,
            });
            message.success(
              `${certificateMode ? '보완 완료' : '수정'}되었습니다.`,
            );
            history.goBack();
          },
        },
      );
    }
  };
  useLayoutEffect(() => {
    if (allergensList) {
      form.setFieldsValue({
        isOdourless: allergensList.isOdourless,
        ...(!allergensList.isOdourless && {
          isNonAllergenOdour: allergensList.isNonAllergenOdour,
        }),
      });
      if (!allergensList.isOdourless) {
        setFile({
          name: allergensList.fileName,
          url: allergensList.uploadedFileUrl,
        });
      }
    }
  }, [allergensList]);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode="alle"
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode="alle"
              validCodes={['ALLE01-UF']}
            />
          </Col>
        )}
      </Row>
      <ProductAllergensList
        updateMode={updateMode}
        readOnlyMode={readOnlyMode}
        form={form}
        file={file}
        fetchLoading={getLoading}
        submitLoading={submitLoading}
        onUpload={setFile}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ProductAllergensListContainer;
