import qs from 'qs';

import client from './client';
import { createFormData } from 'lib/file';
import {
  ShelfLifeDeclaration,
  ProductUpdateParams,
  Product,
  ProductStatus,
  ProductSearchType,
  ProductDocStatus,
  ProductItem,
  AllDocumentCodeType,
  ProductArtworks,
} from 'types/product';
import { APIResponse, APIPageableResponse } from 'types/common';
import {
  FDACertificatedColor,
  SaleInfo,
  VCRPAccountParam,
  VCRPAccountRequest,
} from 'types/brand/vcrp';
import { PackingAttestationAddParam } from 'types/brand/product/packingAttestation';

// Product
export const getProduct = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get<APIPageableResponse<Product[]>>(
    `/v2/products/${productId}${
      typeof countryId !== 'undefined' ? `/countries/${countryId}` : ''
    }`,
  );
export const getProducts = ({
  page,
  size = 10,
  searchType,
  searchKeyword,
  status = null,
}: {
  page: number;
  size?: number;
  searchType: ProductSearchType;
  searchKeyword: string;
  status: ProductStatus | null;
}) =>
  client.get<APIPageableResponse<ProductItem[]>>('/v2/products', {
    params: { status, page, searchType, searchKeyword, size },
  });
export const manuGetProducts = ({
  page = 1,
  companyId,
  manufacturerItemNameKo,
  manufacturerItemNo,
  brandCompanyNameKo,
  status,
}: {
  page?: number;
  companyId: number;
  manufacturerItemNameKo: string;
  manufacturerItemNo: string;
  brandCompanyNameKo: string;
  status: string;
}) =>
  client.post(`/manufacturer/product/all/${page}/10`, {
    companyId,
    manufacturerItemNameKo,
    manufacturerItemNo,
    brandCompanyNameKo,
    status,
  });
export const getProductIsCertificated = (productId: number) =>
  client.get<APIResponse<boolean>>(
    `/products/is-certificated?productIds=${productId}`,
  );
export const deleteProducts = (productIds: number[]) =>
  client.delete(`/products?productIds=${productIds}`);

export const updateProduct = (productUpdateParams: ProductUpdateParams) =>
  client.patch('/product', productUpdateParams);

// Country
export const getCountries = () => client.get('/countries');
export const addProductCountry = (body: {
  productId: number;
  countryIds: number[];
}) => client.post('/product-country', body);
export const manuDeleteProductCountry = ({
  productId,
  productCountryIds,
}: {
  productId: number;
  productCountryIds: number[];
}) =>
  client.delete(
    `/manufacturer/product-country/${productId}?${qs.stringify(
      { productCountryIds },
      {
        indices: false,
      },
    )}`,
  );

// Artwork
export const getProductArtworks = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) =>
  client.get<APIResponse<ProductArtworks>>(
    `/products/${productId}/product-artworks${
      countryId ? `?countryId=${countryId}` : ''
    }`,
  );

// Stability Test
export const addProductStabilityTest = (productStabilityTest: {
  productId: number;
  temperatureRange1: string;
  temperatureRange2: string;
  temperatureRange3: string;
  cycleTemperatureRange: string;
  countryId: number;
}) => client.post('/product-stability-test', productStabilityTest);
export const updateProductStabilityTest = (productStabilityTest: {
  productStabilityTestId: number;
  temperatureRange1: string;
  temperatureRange2: string;
  temperatureRange3: string;
  cycleTemperatureRange: string;
  countryId: number;
}) => client.patch('/product-stability-test', productStabilityTest);

// Packing Attestation
export const addProductPackingAttestation = ({
  productId,
  data,
}: {
  productId: number;
  data: PackingAttestationAddParam;
}) =>
  client.post(
    `products/${productId}/product-packing-attestations`,
    createFormData(data),
  );

export const updateProductPackingAttestation = ({
  productId,
  countryId,
  data,
}: {
  productId: number;
  countryId?: number;
  data: PackingAttestationAddParam;
}) =>
  client.post(
    `products/${productId}/product-packing-attestations/update${
      countryId !== undefined ? `?countryId=${countryId}` : ''
    }`,
    createFormData(data),
  );

// Third Party Test Report
export const getProductThirdPartyTestReports = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => client.get(`/product-third-party-test/${productId}/${countryId}`);
export const addProductThirdPartyTestReport = (productThirdPartyTestReport: {
  productId: number;
  countryId: number;
  documentFiles: File[];
}) => client.post('/product-third-party-test', productThirdPartyTestReport);
export const deleteProductThirdPartyTestReport = (
  productThirdPartyTestId: number,
) =>
  client.delete(
    `/product-third-party-test?productThirdPartyTestId=${productThirdPartyTestId}`,
  );

// Document
export const getDocuments = (productId: number) =>
  client.get(`/products/pre-list/${productId}`);
export const getDocumentLogs = (documentLogs: {
  productId: number;
  documentSeqId: number;
  documentFieldIds: number[];
  countryId?: number;
}) => client.post('/v2/doc/logs', documentLogs);
export const manuGetDocuments = (productId: number) =>
  client.get(`/manufacturer/product/list/${productId}`);
export const getProductDocStatus = ({
  productId,
  countryId,
  documentCode,
}: {
  productId: number;
  countryId?: number;
  documentCode: AllDocumentCodeType;
}) =>
  client.get<APIResponse<ProductDocStatus>>(
    `/v2/products/${productId}${
      typeof countryId !== 'undefined' ? `/countries/${countryId}` : ''
    }/product-doc-statuses/${documentCode.toUpperCase()}01`,
  );
export const addDocStatus = ({
  isSkip = false,
  documentCode,
  productId,
  countryId,
  documentFile,
}: {
  isSkip?: boolean;
  documentCode: string;
  productId: number;
  countryId?: number;
  documentFile?: File;
}) =>
  client.post(
    `/products/${productId}/doc-statuses/${documentCode.toUpperCase()}01`,
    createFormData({
      isSkip,
      ...(documentFile && { documentFile }),
      ...(countryId && { countryId }),
    }),
  );

export const savePackingAttestationType = ({
  productId,
  packingAttestationType,
}: {
  productId: number;
  packingAttestationType: string;
}) =>
  client.patch(
    `/manufacturer/pack-attestation/${productId}?packingAttestationType=${packingAttestationType}`,
  );
export const manuRegisterProduct = (productId: number) =>
  client.patch(`/manufacturer/product/fin/${productId}`);
export const validateAuthCode = ({
  manufacturerId,
  authCode,
}: {
  manufacturerId: number;
  authCode: string;
}) =>
  client.post(`/auth/brand/validate/${manufacturerId}?authCode=${authCode}`);
export const importProducts = ({
  manufacturerId,
  authCodes,
}: {
  manufacturerId: number;
  authCodes: string[];
}) =>
  client.patch(
    `/auth/brand/${manufacturerId}?${qs.stringify(
      { authCodes },
      { indices: false },
    )}`,
  );

export const getShelfLifeDeclaration = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<ShelfLifeDeclaration>>(
    `/shelf-life?${qs.stringify({ productId, countryId })}`,
  );

export const updateShelfLifeDeclaration = (shelfLifeDeclarationUpdate: {
  productId: number;
  countryId: number;
  productExpireMonths: number;
}) => client.put('/shelf-life', shelfLifeDeclarationUpdate);

// Sale Info
export const getSaleInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<SaleInfo | null>>(
    `/products/${productId}/countries/${countryId}/sale-info`,
  );

export const addSaleInfo = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: FormData;
}) =>
  client.post<APIResponse<null>>(
    `/products/${productId}/countries/${countryId}/sale-info`,
    formValues,
  );

export const updateSaleInfo = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: FormData;
}) =>
  client.post<APIResponse<null>>(
    `/products/${productId}/countries/${countryId}/sale-info/update`,
    formValues,
  );

// FDA Certificated Color
export const getFDACertificatedColor = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<FDACertificatedColor[]>>(
    `/products/${productId}/countries/${countryId}/fda-certificated-color`,
  );

export const addFDACertificatedColor = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: FormData;
}) =>
  client.post<APIResponse<null>>(
    `/products/${productId}/countries/${countryId}/fda-certificated-color`,
    formValues,
  );

// VCRP Account
export const getVCRPAccount = () =>
  client.get<APIResponse<VCRPAccountParam>>(`/vcrp-account`);

export const addVCRPAccount = ({
  vcrpAccount,
  vcrpPassword,
}: Omit<VCRPAccountParam, 'vcrpAccountId'>) =>
  client.post<APIResponse<null>>(`/vcrp-account`, {
    vcrpAccount,
    vcrpPassword,
  });

export const updateVCRPAccount = ({
  vcrpAccountId,
  vcrpAccount,
  vcrpPassword,
}: VCRPAccountParam) =>
  client.patch<APIResponse<null>>(`/vcrp-accounts/${vcrpAccountId}`, {
    vcrpAccount,
    vcrpPassword,
  });

export const getProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<Pick<VCRPAccountParam, 'vcrpAccountId'>>>(
    `/products/${productId}/countries/${countryId}/vcrp-account`,
  );

export const addProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.put<APIResponse<null>>(
    `/products/${productId}/countries/${countryId}/vcrp-account`,
  );

// VCRP Account Request
export const getVCRPAccountRequest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<VCRPAccountRequest>>(
    `/products/${productId}/countries/${countryId}/vcrp-account-request`,
  );

export const addVCRPAccountRequest = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: VCRPAccountRequest;
}) =>
  client.post<APIResponse<null>>(
    `/products/${productId}/countries/${countryId}/vcrp-account-request`,
    formValues,
  );

export const updateVCRPAccountRequest = ({
  productId,
  countryId,
  formValues,
}: {
  productId: number;
  countryId: number;
  formValues: VCRPAccountRequest;
}) =>
  client.patch<APIResponse<null>>(
    `/products/${productId}/countries/${countryId}/vcrp-account-request`,
    formValues,
  );
