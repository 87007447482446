import { Col, Row } from 'antd';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import Table from 'components/Table';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Tooltip2 from 'components/ui/Tooltip2';
import { countryImages } from 'lib/consts';
import palette from 'lib/styles/palette';
import {
  CountryLaw,
  DistributorLawRegulation,
  FormulaScreeningItem,
  FormulaScreeningItemDistributor,
  RegulationType,
  SpecialRegionLawRegulation,
} from 'service/brand/formulaScreening/formulaScreening';
import DistributorProhibitInfosModal from './DistributorProhibitInfosModal';
import FormulaScreeningCountryProhibitInfosModal from './FormulaScreeningCountryProhibitInfosModal';
import SeparateStoreRegulationsModal from './SeparateStoreRegulationsModal';
import SpecialRegionProhibitInfosModal from './SpecialRegionProhibitInfosModal';
import { CountryLawRegulation } from 'types/material/ingredient';

const FormulaScreeningTable = ({
  selectedCountryLaws,
  selectedDistributorIds,
  selectedSpecialRegionIds,
  formulaScreeningResult,
  formulaScreeningDistributors,
  formulaScreeningSpecialRegions,
  method,
}: {
  selectedCountryLaws: CountryLaw[];
  selectedSpecialRegionIds: string[];
  selectedDistributorIds: string[];
  formulaScreeningResult: FormulaScreeningItem;
  formulaScreeningDistributors: FormulaScreeningItemDistributor[];
  formulaScreeningSpecialRegions: {
    specialRegionId: number;
    specialRegionName: string;
  }[];
  method: number;
}) => {
  const {
    list: formulaScreeningListItems,
    detectionProhibitIngredientsOfDistributor,
  } = formulaScreeningResult;

  const [showTooltip, setShowTooltip] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string | null>(
    null,
  );
  const [
    selectedFormulaCountryInfos,
    setSelectedFormulaCountryInfos,
  ] = useState<CountryLawRegulation[]>([]);
  const [selectedDistributorId, setSelectedDistributorId] = useState<number>();
  const [
    separateStoreRegulationModalVisible,
    setSeparateStoreRegulationModalVisible,
  ] = useState(false);

  const [
    selectedDistributorRegulations,
    setSelectedDistributorRegulations,
  ] = useState<DistributorLawRegulation[]>();

  const [
    selectedSpecialRegionId,
    setSelectedSpecialRegionId,
  ] = useState<number>();

  const [
    selectedSpecialRegionRegulations,
    setSelectedSpecialRegionRegulations,
  ] = useState<SpecialRegionLawRegulation[]>();

  const handleClickCountry = (
    formulaCountryInfos: CountryLawRegulation[],
    countryCode?: string,
  ) => {
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }

    setSelectedFormulaCountryInfos(formulaCountryInfos);
  };

  const handleClickSpecialRegion = ({
    specialRegionId,
    specialRegionLawRegulations,
  }: {
    specialRegionId?: number;
    specialRegionLawRegulations: SpecialRegionLawRegulation[];
  }) => {
    if (specialRegionId !== undefined) {
      setSelectedSpecialRegionId(specialRegionId);
    }
    setSelectedSpecialRegionRegulations(specialRegionLawRegulations);
  };

  const handleClickDistributor = ({
    distributorId,
    distributorLawRegulations,
  }: {
    distributorId?: number;
    distributorLawRegulations: DistributorLawRegulation[];
  }) => {
    if (distributorId !== undefined) {
      setSelectedDistributorId(distributorId);
    }
    setSelectedDistributorRegulations(distributorLawRegulations);
  };

  const handleCloseCountryInfosModal = () => {
    setSelectedCountryCode(null);
    setSelectedFormulaCountryInfos([]);
  };

  const handleCloseDistributorInfosModal = () => {
    setSelectedDistributorId(undefined);
    setSelectedDistributorRegulations(undefined);
  };

  const handleCloseSpecialRegionInfosModal = () => {
    setSelectedSpecialRegionId(undefined);
    setSelectedSpecialRegionRegulations(undefined);
  };

  return (
    <TableWrapper>
      {/* 스토어 성분 별도 규제사항 모달 */}
      {separateStoreRegulationModalVisible &&
        detectionProhibitIngredientsOfDistributor.length > 0 && (
          <SeparateStoreRegulationsModal
            detectionProhibitIngredientsOfDistributor={
              detectionProhibitIngredientsOfDistributor
            }
            onClose={() => setSeparateStoreRegulationModalVisible(false)}
          />
        )}
      {/* 국가 성분 규제 모달 */}
      {selectedFormulaCountryInfos.length > 0 && (
        <FormulaScreeningCountryProhibitInfosModal
          defaultCountryCode={selectedCountryCode || undefined}
          countryInfos={selectedFormulaCountryInfos}
          onClose={handleCloseCountryInfosModal}
        />
      )}
      {/* 특수지역 성분 규제 모달 */}
      {selectedSpecialRegionRegulations && (
        <SpecialRegionProhibitInfosModal
          formulaScreeningSpecialRegions={formulaScreeningSpecialRegions}
          defaultSpecialRegionId={selectedSpecialRegionId}
          specialRegionRegulations={selectedSpecialRegionRegulations}
          onClose={handleCloseSpecialRegionInfosModal}
        />
      )}
      {/* 스토어 성분 규제 모달 */}
      {selectedDistributorRegulations && (
        <DistributorProhibitInfosModal
          formulaScreeningDistributors={formulaScreeningDistributors}
          defaultDistributorId={selectedDistributorId}
          distributorRegulations={selectedDistributorRegulations}
          onClose={handleCloseDistributorInfosModal}
        />
      )}
      <Table bordered>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{ textAlign: 'center' }}>No.</Table.Th>
            <Table.Th style={{ textAlign: 'center' }}>성분명</Table.Th>
            <Table.Th style={{ textAlign: 'center' }}>WT %</Table.Th>
            <Table.Th style={{ textAlign: 'center' }}>CAS No.</Table.Th>
            {selectedCountryLaws.length > 0 && (
              <Table.Th style={{ textAlign: 'center' }} colSpan={2}>
                국가 성분 규제
              </Table.Th>
            )}
            {selectedSpecialRegionIds.length > 0 && (
              <Table.Th style={{ textAlign: 'center' }} colSpan={2}>
                특수지역 성분 규제
              </Table.Th>
            )}
            {selectedDistributorIds.length > 0 && (
              <Table.Th style={{ textAlign: 'center' }} colSpan={2}>
                <Flex
                  gap={4}
                  align="center"
                  justify="center"
                  style={{ position: 'relative' }}
                >
                  <Typography.Text type="SMALL">
                    스토어 성분 규제
                  </Typography.Text>
                  {detectionProhibitIngredientsOfDistributor.length > 0 && (
                    <div style={{ position: 'relative' }}>
                      <Icon
                        name="screening"
                        onClick={() => {
                          setSeparateStoreRegulationModalVisible(true);
                        }}
                      />
                      <Tooltip2
                        visible={showTooltip}
                        title="별도 규제사항을 확인해 주세요."
                        onClose={() => {
                          setShowTooltip(false);
                        }}
                      />
                    </div>
                  )}
                </Flex>
              </Table.Th>
            )}
            <Table.Th style={{ textAlign: 'center' }}>EWG 등급</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {formulaScreeningListItems.map(
            (
              {
                countryLawRegulations,
                distributorLawRegulations,
                specialRegionLawRegulations,
                ewg,
                ingredient,
              },
              index,
            ) => {
              const {
                no,
                noRowSpan,
                casNo,
                wt,
                wtRowSpan,
                name,
                errorCode,
                bigo,
              } = ingredient;

              const ingredientRegulations = (
                countryLawRegulations || []
              ).filter(
                ({ ingredientLawRegulations }) =>
                  ingredientLawRegulations &&
                  ingredientLawRegulations.length > 0,
              );
              const groupRegulations = (countryLawRegulations || []).filter(
                ({ groupingLawRegulations }) =>
                  groupingLawRegulations && groupingLawRegulations.length > 0,
              );

              const foodAndDrugRegulations = (
                countryLawRegulations || []
              ).filter(
                ({ foodAndDrugAdministration }) => foodAndDrugAdministration,
              );

              const limitDistributorId =
                distributorLawRegulations?.find(
                  (item) =>
                    item.ingredientLawRegulations &&
                    item.ingredientLawRegulations.some(
                      ({ regulation }) =>
                        regulation?.type === RegulationType.LIMIT,
                    ),
                )?.distributorDto?.distributorId ||
                distributorLawRegulations?.find(
                  (item) =>
                    item.groupingLawRegulations &&
                    item.groupingLawRegulations.some(
                      (group) =>
                        group.regulation?.type === RegulationType.LIMIT,
                    ),
                )?.distributorDto?.distributorId;
              const prohibitDistributorId =
                distributorLawRegulations?.find(
                  (item) =>
                    item.ingredientLawRegulations &&
                    item.ingredientLawRegulations.some(
                      ({ regulation }) =>
                        regulation?.type === RegulationType.PROHIBIT,
                    ),
                )?.distributorDto?.distributorId ||
                distributorLawRegulations?.find(
                  (item) =>
                    item.groupingLawRegulations &&
                    item.groupingLawRegulations.some(
                      (group) =>
                        group.regulation?.type === RegulationType.PROHIBIT,
                    ),
                )?.distributorDto?.distributorId;

              return (
                <Table.Tr key={nanoid()}>
                  <Table.Td
                    width={36}
                    style={{ textAlign: 'center' }}
                    rowSpan={noRowSpan}
                  >
                    {no || index + 1}
                  </Table.Td>
                  <Table.Td width={189} style={{ overflowWrap: 'anywhere' }}>
                    <Typography.Text
                      type="SMALL"
                      color={errorCode || !!bigo ? 'MESSAGE_ERROR' : 'GRAY90'}
                    >
                      {name || ''}
                    </Typography.Text>
                  </Table.Td>
                  <Table.Td
                    width={46}
                    className="wt"
                    rowSpan={wtRowSpan}
                    style={{ textAlign: 'left' }}
                  >
                    {wt}
                  </Table.Td>
                  <Table.Td width={68} style={{ textAlign: 'center' }}>
                    {casNo}
                  </Table.Td>
                  {/* 국가 성분 규제 */}
                  {selectedCountryLaws.length > 0 && (
                    <Table.Td width="56" style={{ textAlign: 'center' }}>
                      <Flex
                        dir="column"
                        gap={4}
                        style={{ display: 'inline-flex' }}
                      >
                        {countryLawRegulations?.some(
                          (item) =>
                            (item.ingredientLawRegulations &&
                              item.ingredientLawRegulations.some(
                                (law) =>
                                  law.regulation?.type === RegulationType.LIMIT,
                              )) ||
                            (item.groupingLawRegulations &&
                              item.groupingLawRegulations.some(
                                (group) =>
                                  group.regulation?.type ===
                                  RegulationType.LIMIT,
                              )) ||
                            (item.foodAndDrugAdministration &&
                              item.foodAndDrugAdministration.type ===
                                RegulationType.LIMIT),
                        ) && (
                          <TagChip
                            type="check"
                            style={{ display: 'inline-flex' }}
                            onClick={() => {
                              handleClickCountry(
                                [
                                  ...ingredientRegulations,
                                  ...groupRegulations,
                                  ...foodAndDrugRegulations,
                                ],
                                ingredientRegulations[0]?.country.code ??
                                  groupRegulations[0]?.country.code ??
                                  foodAndDrugRegulations[0].country.code,
                              );
                            }}
                          >
                            제한
                          </TagChip>
                        )}
                        {countryLawRegulations?.some(
                          (item) =>
                            (item.ingredientLawRegulations &&
                              item.ingredientLawRegulations.some(
                                (lawRegulation) =>
                                  lawRegulation.regulation?.type ===
                                  RegulationType.PROHIBIT,
                              )) ||
                            (item.groupingLawRegulations &&
                              item.groupingLawRegulations.some(
                                (group) =>
                                  group.regulation?.type ===
                                  RegulationType.PROHIBIT,
                              )) ||
                            (item.foodAndDrugAdministration &&
                              item.foodAndDrugAdministration.type ===
                                RegulationType.PROHIBIT),
                        ) && (
                          <TagChip
                            type="error"
                            style={{ display: 'inline-flex' }}
                            onClick={() => {
                              handleClickCountry(
                                [
                                  ...ingredientRegulations,
                                  ...groupRegulations,
                                  ...foodAndDrugRegulations,
                                ],
                                ingredientRegulations[0]?.country.code ??
                                  groupRegulations[0]?.country.code ??
                                  foodAndDrugRegulations[0].country.code,
                              );
                            }}
                          >
                            금지
                          </TagChip>
                        )}
                      </Flex>
                    </Table.Td>
                  )}
                  {selectedCountryLaws.length > 0 && (
                    <Table.Td width="130" style={{ textAlign: 'center' }}>
                      <Row gutter={[2, 2]} justify="center">
                        {countryLawRegulations
                          ?.reduce((acc, cur) => {
                            if (
                              !acc.find(
                                (item) => item.code === cur.country.code,
                              )
                            ) {
                              acc.push({
                                nameKo: cur.country.nameKo,
                                code: cur.country.code,
                              });
                            }
                            return acc;
                          }, [] as { nameKo: string; code: string }[])
                          ?.map(({ code, nameKo }) => (
                            <Col key={code}>
                              <ImageWrapper name={nameKo}>
                                <img
                                  src={countryImages[nameKo]}
                                  alt=""
                                  style={{
                                    width: 20,
                                    height: 20,
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                  }}
                                  onClick={() => {
                                    handleClickCountry(
                                      countryLawRegulations.filter(
                                        ({ ingredientLawRegulations }) =>
                                          ingredientLawRegulations,
                                      ),
                                      code,
                                    );
                                  }}
                                />
                              </ImageWrapper>
                            </Col>
                          ))}
                      </Row>
                    </Table.Td>
                  )}
                  {/* 특수지역 성분 규제 */}
                  {selectedSpecialRegionIds.length > 0 && (
                    <Table.Td width="56" style={{ textAlign: 'center' }}>
                      <Flex
                        dir="column"
                        gap={4}
                        style={{ display: 'inline-flex' }}
                      >
                        {specialRegionLawRegulations &&
                          Object.values(specialRegionLawRegulations).some(
                            (item) =>
                              item.ingredientLawRegulations &&
                              item.ingredientLawRegulations.some(
                                ({ regulation }) =>
                                  regulation?.type === RegulationType.LIMIT,
                              ),
                          ) && (
                            <TagChip
                              type="check"
                              onClick={() => {
                                handleClickSpecialRegion({
                                  specialRegionLawRegulations,
                                  specialRegionId: Object.values(
                                    specialRegionLawRegulations,
                                  ).find((item) =>
                                    item.ingredientLawRegulations.some(
                                      ({ regulation }) =>
                                        regulation?.type ===
                                        RegulationType.LIMIT,
                                    ),
                                  )?.specialRegion.specialRegionId,
                                });
                              }}
                            >
                              제한
                            </TagChip>
                          )}
                        {specialRegionLawRegulations &&
                          Object.values(specialRegionLawRegulations).some(
                            (item) =>
                              item.groupingLawRegulations &&
                              item.groupingLawRegulations.some(
                                (group) =>
                                  group.regulation?.type ===
                                  RegulationType.LIMIT,
                              ),
                          ) && (
                            <TagChip
                              type="check"
                              onClick={() => {
                                handleClickSpecialRegion({
                                  specialRegionLawRegulations,
                                  specialRegionId: Object.values(
                                    specialRegionLawRegulations,
                                  ).find(
                                    (item) =>
                                      item.ingredientLawRegulations &&
                                      item.ingredientLawRegulations.some(
                                        ({ regulation }) =>
                                          regulation?.type ===
                                          RegulationType.LIMIT,
                                      ),
                                  )?.specialRegion.specialRegionId,
                                });
                              }}
                            >
                              제한
                            </TagChip>
                          )}
                        {specialRegionLawRegulations &&
                          Object.values(specialRegionLawRegulations).some(
                            (item) =>
                              item.ingredientLawRegulations &&
                              item.ingredientLawRegulations.some(
                                ({ regulation }) =>
                                  regulation?.type === RegulationType.PROHIBIT,
                              ),
                          ) && (
                            <TagChip
                              type="error"
                              onClick={() => {
                                handleClickSpecialRegion({
                                  specialRegionLawRegulations,
                                  specialRegionId: Object.values(
                                    specialRegionLawRegulations,
                                  ).find((item) =>
                                    item.ingredientLawRegulations.some(
                                      ({ regulation }) =>
                                        regulation?.type ===
                                        RegulationType.PROHIBIT,
                                    ),
                                  )?.specialRegion.specialRegionId,
                                });
                              }}
                            >
                              금지
                            </TagChip>
                          )}
                        {specialRegionLawRegulations &&
                          Object.values(specialRegionLawRegulations).some(
                            (item) =>
                              item.groupingLawRegulations &&
                              item.groupingLawRegulations.some(
                                (group) =>
                                  group &&
                                  group.regulation?.type ===
                                    RegulationType.PROHIBIT,
                              ),
                          ) && (
                            <TagChip
                              type="error"
                              onClick={() => {
                                handleClickSpecialRegion({
                                  specialRegionLawRegulations,
                                  specialRegionId: Object.values(
                                    specialRegionLawRegulations,
                                  ).find(
                                    (item) =>
                                      item.ingredientLawRegulations &&
                                      item.ingredientLawRegulations.some(
                                        ({ regulation }) =>
                                          regulation?.type ===
                                          RegulationType.PROHIBIT,
                                      ),
                                  )?.specialRegion.specialRegionId,
                                });
                              }}
                            >
                              금지
                            </TagChip>
                          )}
                      </Flex>
                    </Table.Td>
                  )}
                  {selectedSpecialRegionIds.length > 0 && (
                    <Table.Td width="135" style={{ textAlign: 'center' }}>
                      <Row gutter={[2, 2]} justify="center">
                        {specialRegionLawRegulations &&
                          Object.values(specialRegionLawRegulations).every(
                            (item) => item.specialRegion,
                          ) &&
                          specialRegionLawRegulations
                            ?.reduce(
                              (acc, cur) => {
                                if (
                                  !acc.find(
                                    (item) =>
                                      item.specialRegion.specialRegionId ===
                                      cur.specialRegion.specialRegionId,
                                  )
                                ) {
                                  acc.push({
                                    specialRegion: cur.specialRegion,
                                  });
                                }
                                return acc;
                              },
                              [] as {
                                specialRegion: {
                                  logoUrl: string;
                                  specialRegionId: number;
                                  specialRegionName: string;
                                  url: string | null;
                                };
                              }[],
                            )
                            .map(({ specialRegion }) => (
                              <Col key={specialRegion.specialRegionId}>
                                <ImageWrapper
                                  name={specialRegion.specialRegionName}
                                >
                                  <img
                                    src={specialRegion.logoUrl}
                                    alt=""
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: 'pointer',
                                      borderRadius: '50%',
                                    }}
                                    onClick={() => {
                                      handleClickSpecialRegion({
                                        specialRegionLawRegulations,
                                        specialRegionId: Number(
                                          specialRegion.specialRegionId,
                                        ),
                                      });
                                    }}
                                  />
                                </ImageWrapper>
                              </Col>
                            ))}
                      </Row>
                    </Table.Td>
                  )}
                  {/* 스토어 성분 규제 */}
                  {selectedDistributorIds.length > 0 && (
                    <Table.Td width="56" style={{ textAlign: 'center' }}>
                      <Flex
                        dir="column"
                        gap={4}
                        style={{ display: 'inline-flex' }}
                      >
                        {distributorLawRegulations &&
                          Object.values(distributorLawRegulations).some(
                            (item) =>
                              (item.ingredientLawRegulations &&
                                item.ingredientLawRegulations.some(
                                  ({ regulation }) =>
                                    regulation?.type === RegulationType.LIMIT,
                                )) ||
                              (item.groupingLawRegulations &&
                                item.groupingLawRegulations.some(
                                  (group) =>
                                    group.regulation?.type ===
                                    RegulationType.LIMIT,
                                )),
                          ) && (
                            <TagChip
                              type="check"
                              onClick={() => {
                                handleClickDistributor({
                                  distributorLawRegulations,
                                  distributorId: limitDistributorId,
                                });
                              }}
                            >
                              제한
                            </TagChip>
                          )}
                        {distributorLawRegulations &&
                          Object.values(distributorLawRegulations).some(
                            (item) =>
                              (item.ingredientLawRegulations &&
                                item.ingredientLawRegulations.some(
                                  ({ regulation }) =>
                                    regulation?.type ===
                                    RegulationType.PROHIBIT,
                                )) ||
                              (item.groupingLawRegulations &&
                                item.groupingLawRegulations.some(
                                  (group) =>
                                    group.regulation?.type ===
                                    RegulationType.PROHIBIT,
                                )),
                          ) && (
                            <TagChip
                              type="error"
                              onClick={() => {
                                handleClickDistributor({
                                  distributorLawRegulations,
                                  distributorId: prohibitDistributorId,
                                });
                              }}
                            >
                              금지
                            </TagChip>
                          )}
                      </Flex>
                    </Table.Td>
                  )}
                  {selectedDistributorIds.length > 0 && (
                    <Table.Td width="130" style={{ textAlign: 'center' }}>
                      <Row gutter={[2, 2]} justify="center">
                        {distributorLawRegulations &&
                          Object.values(distributorLawRegulations).every(
                            (item) => item.distributorDto,
                          ) &&
                          distributorLawRegulations
                            ?.reduce((acc, cur) => {
                              if (
                                !acc.find(
                                  (item) =>
                                    item.distributorDto.distributorId ===
                                    cur.distributorDto.distributorId,
                                )
                              ) {
                                acc.push(cur);
                              }
                              return acc;
                            }, [] as DistributorLawRegulation[])
                            .map(({ distributorDto }) => (
                              <Col key={distributorDto?.distributorId}>
                                <ImageWrapper
                                  name={distributorDto?.distributorName || '-'}
                                >
                                  <img
                                    src={distributorDto?.logoUrl}
                                    alt=""
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: 'pointer',
                                      borderRadius: '50%',
                                    }}
                                    onClick={() => {
                                      handleClickDistributor({
                                        distributorLawRegulations,
                                        distributorId: Number(
                                          distributorDto?.distributorId,
                                        ),
                                      });
                                    }}
                                  />
                                </ImageWrapper>
                              </Col>
                            ))}
                      </Row>
                    </Table.Td>
                  )}

                  {/* EWG 등급 */}
                  <Table.Td width={80} style={{ textAlign: 'center' }}>
                    {ewg && (
                      <EWG scoreMax={ewg.scoreMax}>
                        {ewg.scoreMin === ewg.scoreMax
                          ? ewg.scoreMax
                          : `${ewg.scoreMin}-${ewg.scoreMax}`}
                      </EWG>
                    )}
                  </Table.Td>
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  margin-top: 8px;

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    border-top-left-radius: 4px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    th:last-child {
    border-top-right-radius: 4px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > tbody
    > tr:last-child
    > td:first-child {
    border-bottom: none !important;
  }

  .ant-table-wrapper .ant-table-container table > tbody > tr > td:last-child {
    border-right: none !important;
  }

  .ant-table-wrapper .ant-table-container table > tbody > tr:last-child > td {
    border-bottom: none !important;
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border: none;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 10px 12px;
  }

  .ant-table {
    overflow: visible;
  }

  .ant-table-cell {
    font-size: 12px !important;
    color: ${palette.GRAY90} !important;
  }
`;

const TagChip = styled(Flex)<{ type: 'error' | 'check' }>`
  color: ${({ type }) =>
    type === 'error' ? palette.MESSAGE_ERROR : palette.MESSAGE_SUCCESS};
  background-color: ${({ type }) =>
    type === 'error' ? palette.SECONDARY_20 : palette.GREEN_20};
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 37px;
  cursor: pointer;
  display: inline-flex;
`;

const EWG = styled.div<{ scoreMax: number }>`
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 6px 12px 12px 12px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  margin: 0 auto;

  ${({ scoreMax }) =>
    scoreMax <= 2
      ? css`
          background: #00b32d;
        `
      : scoreMax <= 6
      ? css`
          background: #fdb900;
        `
      : css`
          background: #fb2f2f;
        `};
`;

const ImageWrapper: React.FC<{
  name: string;
  children: React.ReactNode;
}> = ({ name, children }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      {children}
      {isTooltipOpen && (
        <Tooltip>
          <Typography.Text type="SMALL" color="ETC_WHITE">
            {name}
          </Typography.Text>
        </Tooltip>
      )}
    </div>
  );
};

const Tooltip = styled.div`
  position: absolute;
  padding: 4px 10px;
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 4px;
  top: calc(100% + 4.5px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  z-index: 1;
`;

export default FormulaScreeningTable;
