import { Button, Modal, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { materialDocumentCodeWithPathMap } from 'lib/consts';
import history from 'lib/history';
import path from 'lib/path';
import {
  useRawMaterial,
  useRawMaterialDocuments,
} from 'service/material/rawMaterial';
import {
  Document,
  DocumentCode,
  DocumentStatus,
} from 'types/material/rawMaterial';

const RawMaterialDocumentsBlock = styled.div`
  .ant-table-thead {
    th {
      padding: 10px 8px !important;
      line-height: 20px !important;
      border-bottom: none !important;
    }
    th:first-child {
      padding-left: 16px !important;
    }
    th:last-child {
      padding-right: 16px !important;
    }
  }

  .ant-table-tbody {
    td {
      padding: 16px 8px !important;
      height: 56px;
    }
    td:first-child {
      padding-left: 16px !important;
    }
    td:last-child {
      padding-right: 16px !important;
    }
  }
`;

const SearchExposureNotice = styled(Flex)`
  background-color: #f1f4f6;
  width: 331px;
  height: 24px;
  margin: 0 auto;
  border-radius: 4px;
`;

const documentStatusMap = {
  [DocumentStatus.INP]: '필수 입력',
  [DocumentStatus.IPS]: '선택 입력',
  [DocumentStatus.UPD]: '업로드 필요',
  [DocumentStatus.FIN]: '입력 완료',
};

const RawMaterialDocuments = ({
  onRegisterSuccess,
}: {
  onRegisterSuccess: () => void;
}) => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const {
    rawMaterial,
    getLoading: getRawMaterialLoading,
    registerRawMaterial,
    registerLoading,
  } = useRawMaterial(materialId);
  const { documents, getLoading } = useRawMaterialDocuments(materialId);
  const registerable =
    rawMaterial &&
    rawMaterial.status === 'RM_RDY' &&
    documents.length > 0 &&
    documents.every(
      ({ isOptional, status }) => isOptional || status !== DocumentStatus.INP,
    );

  const handleMoveToDocumentPage = (
    documentCode: DocumentCode,
    status: DocumentStatus,
  ) => {
    if (documentCode === DocumentCode.MSDS) {
      const ciDocument = documents.find(
        ({ documentSeq: { documentCode } }) =>
          documentCode === DocumentCode.MCI,
      );
      if (!ciDocument) throw new Error('Invalid ciDocument');
      if (ciDocument.status !== DocumentStatus.FIN) {
        return message.warn(
          "'구성 성분의 명칭 및 함유량 정보'에 기입될 Composition 정보가 없습니다.\nComposition Information 입력 후에 Material Safety Data Sheet 입력이 가능합니다.",
        );
      }
    }
    history.push(
      `${path.material.rawMaterial.root}/${
        materialDocumentCodeWithPathMap[documentCode]
      }/${materialId}${
        documentCode !== DocumentCode.MDI &&
        status !== DocumentStatus.INP &&
        status !== DocumentStatus.IPS
          ? '?updateMode=true'
          : ''
      }`,
    );
  };

  const columns: ColumnsType<Document> = [
    {
      title: '구분',
      width: 64,
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: '입력 정보',
      width: 328,
      render: (_, { documentSeq: { documentCode, documentName }, status }) => (
        <Typography.Text
          type="BODY_2"
          medium
          color="GRAY90"
          style={{ cursor: 'pointer' }}
          onClick={() => handleMoveToDocumentPage(documentCode, status)}
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리 현황',
      width: 128,
      align: 'center',
      render: (_, { documentSeq: { documentCode }, status, isOptional }) =>
        status !== DocumentStatus.FIN ? (
          <Tags.Mark
            type="arrow"
            color={status === DocumentStatus.UPD ? 'red' : 'blue'}
            onClick={() => handleMoveToDocumentPage(documentCode, status)}
          >
            {/* HINT: 기존 - status로 필수입력과 선택입력을 구분, 현재 - isOptional로 필수입력과 선택입력을 구분 */}
            {status === DocumentStatus.INP || status === DocumentStatus.IPS
              ? isOptional
                ? '선택 입력'
                : '필수 입력'
              : documentStatusMap[status]}
          </Tags.Mark>
        ) : (
          <Typography.Text type="BODY_2">
            {documentStatusMap[status]}
          </Typography.Text>
        ),
    },
    {
      title: '최근 입력자 명',
      width: 216,
      align: 'center',
      render: (
        _,
        {
          status,
          modifierCompanyName,
          modifierName,
          documentSeq: { documentCode },
        },
      ) => {
        if (
          status !== DocumentStatus.FIN &&
          (documentCode === DocumentCode.MCI ||
            documentCode === DocumentCode.COA ||
            documentCode === DocumentCode.MSDS ||
            documentCode === DocumentCode.ALLE)
        ) {
          return {
            props: { colSpan: 2 },
            children: (
              <SearchExposureNotice gap={4} align="center" justify="center">
                <Icon name="find" size={16} color="SLATE_GRAY70" />
                <Typography.Text
                  style={{ letterSpacing: 0 }}
                  inline
                  color="SLATE_GRAY70"
                  type="SMALL"
                >
                  데이터 입력 시 원료 검색 리스트에{' '}
                  <Typography.Text
                    style={{ letterSpacing: 0 }}
                    inline
                    color="PRIMARY50"
                    bold
                    type="SMALL"
                  >
                    상단으로 노출
                  </Typography.Text>
                  됩니다.
                </Typography.Text>
              </SearchExposureNotice>
            ),
          };
        }

        return (
          <Typography.Text type="BODY_2">
            {modifierName && modifierCompanyName
              ? `${modifierName} (${modifierCompanyName})`
              : '-'}
          </Typography.Text>
        );
      },
    },
    {
      title: '입력 일시',
      width: 184,
      align: 'center',
      dataIndex: 'registerDt',
      render: (_, { registerDt, modifyDt }) => (
        <Typography.Text type="BODY_2">
          {registerDt || modifyDt || '-'}
        </Typography.Text>
      ),
    },
  ];

  const handleRegisterRawMaterial = () => {
    const isAllDocumentsNotEmpty = documents.every(
      ({ status }) =>
        status !== DocumentStatus.INP && status !== DocumentStatus.IPS,
    );

    Modal.confirm({
      bodyStyle: {
        textAlign: isAllDocumentsNotEmpty ? 'start' : 'center',
      },
      width: isAllDocumentsNotEmpty ? 480 : 400,
      title: isAllDocumentsNotEmpty && '원료를 등록하시겠습니까?',
      icon: null,
      closable: true,
      content: isAllDocumentsNotEmpty ? (
        <Typography.Text type="BODY_2">
          등록하시면 해당 원료를 제조사에서 검색하여 조회할 수 있게 됩니다.
          <br />
          처리현황에 ‘업로드 필요’ 항목이 있다면 14일 이내로 업로드해 주세요.
          <br />
          (14일 경과 시 업로드 안된 인증은 목록에서 제외됩니다)
        </Typography.Text>
      ) : (
        <Typography.Text type="BODY_2">
          입력이 가능한 자료가 있습니다.
          <br />
          입력 완료 처리된 항목이 많아질수록
          <br />
          [원료 검색] {'>'} [일반 원료]의 상단에 노출됩니다.
          <br />
          <br />
          이대로 등록하시겠습니까?
        </Typography.Text>
      ),
      okText: '등록',
      cancelText: '취소',
      onOk: () => {
        registerRawMaterial(materialId, {
          onSuccess: onRegisterSuccess,
        });
      },
    });
  };

  return (
    <RawMaterialDocumentsBlock>
      <Table
        columns={columns}
        dataSource={documents}
        loading={getLoading || getRawMaterialLoading}
        rowKey={({ documentSeq }) => documentSeq.documentSeqId}
        pagination={false}
      />
      {registerable && (
        <FooterBox>
          <Button
            type="primary"
            onClick={handleRegisterRawMaterial}
            loading={registerLoading}
          >
            원료 등록
          </Button>
        </FooterBox>
      )}
    </RawMaterialDocumentsBlock>
  );
};

export default RawMaterialDocuments;
