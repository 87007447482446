import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { normalizeDate } from 'lib/validate';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

type StandardDtType = 'start' | 'end';

interface RangePickerProps {
  disabled?: boolean;
  startDt: string;
  endDt: string;
  yearRestriction?: number;
  onChange: (value: [string, string]) => void;
}

const RangePicker = ({
  disabled = false,
  onChange,
  startDt,
  endDt,
  yearRestriction,
}: RangePickerProps) => {
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const today = moment();
  const initialMonth = startDt
    ? moment(startDt, 'YYYY-MM-DD').month() + 1
    : today.month() + 1;
  const initialYear = startDt
    ? moment(startDt, 'YYYY-MM-DD').year()
    : today.year();

  const [startDtBuffer, setStartDtBuffer] = useState(startDt);
  const [endDtBuffer, setEndDtBuffer] = useState(endDt);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);
  const [currentYear, setCurrentYear] = useState(initialYear);
  const [standardDt, setStandardDt] = useState<StandardDtType>('start');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [clearable, setClearable] = useState(false);

  useEffect(() => {
    /**
     * HINT: 특정 영역 이외 클릭시 닫는 함수.
     * not-close 클래스가 있는 영역도 닫지 않음.
     */
    const handleClick = (e: MouseEvent) => {
      const clickedElement = e.target as HTMLElement;
      const targetClasses = ['not-close'];

      let element: HTMLElement | null = clickedElement;

      while (element !== null) {
        let isBreak = false;

        for (const targetClass of targetClasses) {
          if (element.classList && element.classList.contains(targetClass)) {
            isBreak = true;
            break;
          }
        }

        if (isBreak) {
          break;
        }

        element = element.parentNode as HTMLElement | null;
      }

      if (element === null) {
        // 클릭시 닫는 함수가 아래에 들어감.
        setStandardDt('start');
        setDropdownVisible(false);
      }
    };

    document.body.addEventListener('click', handleClick);

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (dropdownVisible) return;

    let startDt = '';
    let endDt = '';

    if (
      startDtBuffer &&
      /\d{4}-\d{2}-\d{2}/.test(startDtBuffer) &&
      moment(startDtBuffer, 'YYYY-MM-DD').isValid()
    ) {
      startDt = startDtBuffer;
    }

    if (
      endDtBuffer &&
      /\d{4}-\d{2}-\d{2}/.test(endDtBuffer) &&
      moment(endDtBuffer, 'YYYY-MM-DD').isValid()
    ) {
      endDt = endDtBuffer;
    }

    if (startDt && endDt) {
      onChange([startDt, endDt]);
    } else {
      onChange(['', '']);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (
      standardDt === 'start' &&
      startDtBuffer &&
      /\d{4}-\d{2}-\d{2}/.test(startDtBuffer) &&
      moment(startDtBuffer, 'YYYY-MM-DD').isValid()
    ) {
      setCurrentMonth(
        moment(startDtBuffer.slice(0, 10), 'YYYY-MM-DD').month() + 1,
      );
      setCurrentYear(moment(startDtBuffer.slice(0, 10), 'YYYY-MM-DD').year());
    }

    if (
      standardDt === 'end' &&
      endDtBuffer &&
      /\d{4}-\d{2}-\d{2}/.test(endDtBuffer) &&
      moment(endDtBuffer, 'YYYY-MM-DD').isValid()
    ) {
      setCurrentMonth(
        moment(endDtBuffer.slice(0, 10), 'YYYY-MM-DD')
          .subtract(1, 'months')
          .month() + 1,
      );
      setCurrentYear(
        moment(endDtBuffer.slice(0, 10), 'YYYY-MM-DD')
          .subtract(1, 'months')
          .year(),
      );
    }
  }, [startDtBuffer, endDtBuffer, standardDt]);

  useEffect(() => {
    setStartDtBuffer(startDt);
    setEndDtBuffer(endDt);
  }, [startDt, endDt]);

  return (
    <OuterContainer>
      <Container
        gap={16}
        className="not-close custom-range-picker"
        align="center"
        justify="space-between"
        disabled={disabled}
        onMouseEnter={() => {
          if (startDtBuffer || endDtBuffer) {
            setClearable(true);
          }
        }}
        onMouseLeave={() => {
          setClearable(false);
        }}
        onClick={(e) => {
          const target = e.target as HTMLElement;

          if (target.id === 'endDt') {
            endInputRef.current?.focus();
            setStandardDt('end');
          } else {
            startInputRef.current?.focus();
            setStandardDt('start');
          }
          setDropdownVisible(true);
        }}
      >
        <Flex gap={8} align="center" style={{ position: 'relative' }}>
          <input
            value={startDtBuffer}
            placeholder="시작일"
            disabled={disabled}
            ref={startInputRef}
            onChange={(e) => {
              setStartDtBuffer(normalizeDate(e.target.value));
            }}
            onBlur={() => {
              const startDtMoment = moment(startDtBuffer, 'YYYY-MM-DD');
              const endDtMoment = moment(endDtBuffer, 'YYYY-MM-DD');
              const yearDifference = startDtMoment.diff(
                endDtMoment,
                'years',
                true,
              );
              const isWithinYearRestriction =
                startDtBuffer && endDtBuffer && yearRestriction
                  ? Math.abs(yearDifference) < yearRestriction
                  : true;

              if (
                !startDtMoment.isValid() ||
                startDtMoment.isAfter(endDtMoment) ||
                !isWithinYearRestriction
              ) {
                setStartDtBuffer('');
                setEndDtBuffer('');
              }
            }}
          />
          <Icon
            name="back"
            size={16}
            color="GRAY50"
            style={{ transform: 'rotate(180deg)' }}
          />
          <input
            id="endDt"
            value={endDtBuffer}
            placeholder="종료일"
            disabled={disabled}
            ref={endInputRef}
            onChange={(e) => {
              setEndDtBuffer(normalizeDate(e.target.value));
            }}
            onBlur={() => {
              const startDtMoment = moment(startDtBuffer, 'YYYY-MM-DD');
              const endDtMoment = moment(endDtBuffer, 'YYYY-MM-DD');
              const yearDifference = startDtMoment.diff(
                endDtMoment,
                'years',
                true,
              );
              const isWithinYearRestriction =
                startDtBuffer && endDtBuffer && yearRestriction
                  ? Math.abs(yearDifference) < yearRestriction
                  : true;

              if (
                !endDtMoment.isValid() ||
                endDtMoment.isBefore(startDtMoment) ||
                !isWithinYearRestriction
              ) {
                setStartDtBuffer('');
                setEndDtBuffer('');
              }
            }}
          />
        </Flex>
        {clearable ? (
          <Icon
            name="smallClose"
            size={12}
            onClick={() => {
              setStartDtBuffer('');
              setEndDtBuffer('');
              onChange(['', '']);
              setClearable(false);
            }}
          />
        ) : (
          <Icon name="calendar" size={20} color="GRAY50" />
        )}
      </Container>
      {dropdownVisible && (
        <Dropdown
          startDt={startDtBuffer}
          endDt={endDtBuffer}
          standardDt={standardDt}
          setStartDtBuffer={setStartDtBuffer}
          setEndDtBuffer={setEndDtBuffer}
          onChangeStandardDt={setStandardDt}
          onChangeDropdownVisible={setDropdownVisible}
          today={today}
          currentMonth={currentMonth}
          currentYear={currentYear}
          yearRestriction={yearRestriction}
          onChangeCurrentMonth={setCurrentMonth}
          onChangeCurrentYear={setCurrentYear}
        />
      )}
    </OuterContainer>
  );
};

const Dropdown = ({
  standardDt,
  startDt,
  endDt,
  onChangeStandardDt,
  setStartDtBuffer,
  setEndDtBuffer,
  onChangeDropdownVisible,
  today,
  currentMonth,
  currentYear,
  yearRestriction,
  onChangeCurrentMonth,
  onChangeCurrentYear,
}: {
  standardDt: StandardDtType;
  startDt: string;
  endDt: string;
  setStartDtBuffer: (startDt: string) => void;
  setEndDtBuffer: (endDt: string) => void;
  onChangeStandardDt: (standardDt: StandardDtType) => void;
  onChangeDropdownVisible: (visible: boolean) => void;
  today: moment.Moment;
  currentMonth: number;
  currentYear: number;
  yearRestriction?: number;
  onChangeCurrentMonth: (month: number) => void;
  onChangeCurrentYear: (year: number) => void;
}) => {
  const [hoveredCell, setHoveredCell] = useState<moment.Moment>();

  const handleClickPrev = () => {
    if (currentMonth === 1) {
      onChangeCurrentMonth(12);
      onChangeCurrentYear(currentYear - 1);
    } else {
      onChangeCurrentMonth(currentMonth - 1);
    }
  };

  const handleClickNext = () => {
    if (currentMonth === 12) {
      onChangeCurrentMonth(1);
      onChangeCurrentYear(currentYear + 1);
    } else {
      onChangeCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <DropdownContainer className="not-close">
      <Calendar
        year={currentYear}
        month={currentMonth}
        today={today}
        momentInstance={moment(`${currentYear}-${currentMonth}`, 'YYYY-MM')}
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
        standardDt={standardDt}
        startDt={startDt}
        endDt={endDt}
        setStartDtBuffer={setStartDtBuffer}
        setEndDtBuffer={setEndDtBuffer}
        hoveredCell={hoveredCell}
        yearRestriction={yearRestriction}
        onChangeStandardDt={onChangeStandardDt}
        onChangeDropdownVisible={onChangeDropdownVisible}
        onChangeHoveredCell={setHoveredCell}
      />
      <Calendar
        year={currentMonth === 12 ? currentYear + 1 : currentYear}
        month={currentMonth === 12 ? 1 : currentMonth + 1}
        today={today}
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
        momentInstance={moment(
          `${currentMonth === 12 ? currentYear + 1 : currentYear}-${
            currentMonth === 12 ? 1 : currentMonth + 1
          }`,
          'YYYY-MM',
        )}
        leftCalendar={false}
        standardDt={standardDt}
        startDt={startDt}
        endDt={endDt}
        setStartDtBuffer={setStartDtBuffer}
        setEndDtBuffer={setEndDtBuffer}
        hoveredCell={hoveredCell}
        yearRestriction={yearRestriction}
        onChangeStandardDt={onChangeStandardDt}
        onChangeDropdownVisible={onChangeDropdownVisible}
        onChangeHoveredCell={setHoveredCell}
      />
    </DropdownContainer>
  );
};

const Calendar = ({
  year,
  month,
  today,
  onClickPrev,
  onClickNext,
  leftCalendar = true,
  momentInstance,
  standardDt,
  startDt,
  endDt,
  setStartDtBuffer,
  setEndDtBuffer,
  hoveredCell,
  yearRestriction,
  onChangeStandardDt,
  onChangeDropdownVisible,
  onChangeHoveredCell,
}: {
  year: number;
  month: number;
  today: moment.Moment;
  onClickPrev: VoidFunction;
  onClickNext: VoidFunction;
  leftCalendar?: boolean;
  momentInstance: moment.Moment;
  standardDt: StandardDtType;
  startDt: string;
  endDt: string;
  setStartDtBuffer: (startDt: string) => void;
  setEndDtBuffer: (endDt: string) => void;
  hoveredCell?: moment.Moment;
  yearRestriction?: number;
  onChangeStandardDt: (standardDt: StandardDtType) => void;
  onChangeDropdownVisible: (visible: boolean) => void;
  onChangeHoveredCell: (moment: undefined | moment.Moment) => void;
}) => {
  const firstDayOfMonth = moment(momentInstance).startOf('month');
  const lastDayOfMonth = moment(momentInstance).endOf('month');
  const startDate = moment(firstDayOfMonth).startOf('week');
  const endDate = moment(lastDayOfMonth).endOf('week');
  const daysInMonth = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate) || daysInMonth.length < 42) {
    daysInMonth.push(moment(currentDate));
    currentDate.add(1, 'day');
  }

  return (
    <CalendarContainer>
      <CalendarHeader
        align="center"
        justify={leftCalendar ? 'flex-start' : 'flex-end'}
      >
        {leftCalendar ? (
          <>
            <Icon name="left" size={18} onClick={onClickPrev} />
            <Typography.Text semiBold gutter={{ left: 53 }}>
              {year}년 {month}월
            </Typography.Text>
          </>
        ) : (
          <>
            <Typography.Text semiBold gutter={{ right: 53 }}>
              {year}년 {month}월
            </Typography.Text>
            <Icon name="right" size={18} onClick={onClickNext} />
          </>
        )}
      </CalendarHeader>
      <CalendarBody>
        {['일', '월', '화', '수', '목', '금', '토'].map((item) => (
          <CalendarWeeks key={item}>{item}</CalendarWeeks>
        ))}
        {daysInMonth.map((cellMoment, index) => {
          const formatDate = cellMoment.format('YYYY-MM-DD');
          const date = cellMoment.date();
          const cellMonth = cellMoment.month() + 1;
          const disabled =
            (!!startDt &&
              standardDt === 'end' &&
              cellMoment.isBefore(moment(startDt, 'YYYY-MM-DD'))) ||
            (!!endDt &&
              standardDt === 'start' &&
              cellMoment.isAfter(moment(endDt, 'YYYY-MM-DD'))) ||
            (!!startDt &&
              standardDt === 'end' &&
              !!yearRestriction &&
              !(
                Math.abs(
                  cellMoment.diff(moment(startDt, 'YYYY-MM-DD'), 'years', true),
                ) < yearRestriction
              )) ||
            (!!endDt &&
              standardDt === 'start' &&
              !!yearRestriction &&
              !(
                Math.abs(
                  cellMoment.diff(moment(endDt, 'YYYY-MM-DD'), 'years', true),
                ) < yearRestriction
              ));
          const isThisMonth = month === cellMonth;
          const isStartDt = formatDate === startDt;
          const isEndDt = formatDate === endDt;
          const isHoveredCell = cellMoment.isSame(hoveredCell);
          const isPrevRangeHover =
            !disabled &&
            standardDt === 'start' &&
            !!hoveredCell &&
            cellMoment.isSameOrAfter(hoveredCell) &&
            !hoveredCell.isSame(moment(startDt, 'YYYY-MM-DD')) &&
            !hoveredCell.isSame(moment(endDt, 'YYYY-MM-DD')) &&
            (startDt
              ? cellMoment.isSameOrBefore(moment(startDt, 'YYYY-MM-DD'))
              : cellMoment.isSameOrBefore(moment(endDt, 'YYYY-MM-DD')));
          const isNextRangeHover =
            !disabled &&
            standardDt === 'end' &&
            !!hoveredCell &&
            cellMoment.isSameOrBefore(hoveredCell) &&
            !hoveredCell.isSame(moment(startDt, 'YYYY-MM-DD')) &&
            !hoveredCell.isSame(moment(endDt, 'YYYY-MM-DD')) &&
            (endDt
              ? cellMoment.isSameOrAfter(moment(endDt, 'YYYY-MM-DD'))
              : cellMoment.isSameOrAfter(moment(startDt, 'YYYY-MM-DD')));
          const bothSelected = !!startDt && !!endDt;

          const isBetween =
            bothSelected &&
            cellMoment.isBefore(moment(endDt, 'YYYY-MM-DD')) &&
            cellMoment.isAfter(moment(startDt, 'YYYY-MM-DD'));

          return (
            <CalendarCell
              key={index}
              isToday={today.format('YYYY-MM-DD') === formatDate}
              isThisMonth={isThisMonth}
              bothSelected={bothSelected}
              isStartDt={isStartDt}
              isEndDt={isEndDt}
              isBetween={isBetween}
              isHoveredCell={isHoveredCell}
              isPrevRangeHover={isPrevRangeHover}
              isNextRangeHover={isNextRangeHover}
              disabled={disabled}
              onMouseEnter={() => {
                onChangeHoveredCell(cellMoment);
              }}
              onMouseLeave={() => {
                onChangeHoveredCell(undefined);
              }}
              onClick={() => {
                if (disabled) return;

                if (standardDt === 'start') {
                  setStartDtBuffer(formatDate);
                  onChangeStandardDt('end');
                } else if (standardDt === 'end') {
                  setEndDtBuffer(formatDate);

                  if (!startDt) {
                    onChangeStandardDt('start');
                  } else {
                    setTimeout(() => {
                      onChangeDropdownVisible(false);
                    }, 200);
                  }
                }

                // 이전 달 cell을 클릭한 경우
                if (!isThisMonth && momentInstance.isAfter(cellMoment)) {
                  onClickPrev();
                  // 다음 달 cell을 클릭한 경우
                } else if (
                  !isThisMonth &&
                  momentInstance.isBefore(cellMoment)
                ) {
                  onClickNext();
                }
              }}
            >
              {date}
            </CalendarCell>
          );
        })}
      </CalendarBody>
    </CalendarContainer>
  );
};

export default RangePicker;

const OuterContainer = styled.div`
  position: relative;
`;

const Container = styled(Flex)<{ disabled: boolean }>`
  position: relative;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid ${palette.GRAY40};
  background: ${palette.ETC_WHITE};
  width: 256px;
  height: 44px;

  input,
  input:focus,
  input:active {
    padding: 0;
    width: 80px;
    font-size: 14px;
    border: none;
    outline: none;
  }
  input::placeholder {
    color: ${palette.GRAY50};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${palette.GRAY20};

          input {
            background-color: ${palette.GRAY20};
            color: ${palette.GRAY50};
          }
        `
      : css`
          &:hover,
          &:focus,
          &:focus-within,
          &:active {
            border-color: ${palette.PRIMARY50};
          }
        `}
`;

const DropdownContainer = styled(Flex)`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background: white;
  z-index: 10;
  box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
  border-radius: 4px;
  user-select: none;
`;

const CalendarContainer = styled.div`
  padding: 24px;
  width: 272px;
`;

const CalendarHeader = styled(Flex)``;

const CalendarBody = styled(Flex)`
  margin-top: 24px;
  row-gap: 8px;
  flex-wrap: wrap;
`;

const CalendarWeeks = styled(Flex)`
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

const CalendarCell = styled(Flex)<{
  isToday: boolean;
  isThisMonth: boolean;
  bothSelected: boolean;
  isStartDt: boolean;
  isEndDt: boolean;
  isBetween: boolean;
  isHoveredCell: boolean;
  isPrevRangeHover: boolean;
  isNextRangeHover: boolean;
  disabled: boolean;
}>`
  position: relative;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${palette.GRAY20};
  }

  ${({ isToday, isBetween, isThisMonth }) =>
    isToday === true &&
    !isBetween &&
    isThisMonth &&
    css`
      border: 1px solid ${palette.PRIMARY50};
    `}

  ${({ isThisMonth }) =>
    !isThisMonth &&
    css`
      color: ${palette.GRAY50};
    `}

  ${({ isStartDt, isEndDt, isThisMonth, bothSelected }) =>
    (isStartDt || isEndDt) &&
    isThisMonth &&
    css`
      background-color: ${palette.PRIMARY50};
      color: ${palette.ETC_WHITE};

      &:hover {
        background-color: ${palette.PRIMARY50};
      }

      border-radius: ${bothSelected &&
      (isStartDt ? '8px 0 0 8px' : '0 8px 8px 0')};
    `}

    ${({ isBetween, isThisMonth }) =>
    isBetween &&
    isThisMonth &&
    css`
      background-color: ${palette.PRIMARY20};
      color: ${palette.PRIMARY50};
      border-radius: 0;

      &:hover {
        background-color: ${palette.PRIMARY20};
      }
    `}

    ${({ disabled, isToday, isThisMonth }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${palette.GRAY20};
      color: ${palette.GRAY50};
      border-radius: 0;
      border: none;

      &:hover {
        background-color: ${palette.GRAY20};
      }

      ${isToday &&
      isThisMonth &&
      css`
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border: 1px solid ${palette.PRIMARY50};
          border-radius: 50%;
        }
      `}
    `}

  ${({ isNextRangeHover, isStartDt, isEndDt, isHoveredCell, isThisMonth }) =>
    isNextRangeHover &&
    isThisMonth &&
    css`
      &:hover {
        background: transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: ${isStartDt || isEndDt ? '50%' : '2px'};
        transform: translateY(-50%);
        z-index: 0;
        border-top: 1px dashed ${palette.PRIMARY50};
        border-bottom: 1px dashed ${palette.PRIMARY50};
        border-radius: ${isHoveredCell && '0 8px 8px 0'};
        border-right: ${isHoveredCell && `1px dashed ${palette.PRIMARY50}`};
        height: 32px;
      }
    `}

    ${({ isPrevRangeHover, isStartDt, isEndDt, isHoveredCell, isThisMonth }) =>
    isPrevRangeHover &&
    isThisMonth &&
    css`
      &:hover {
        background: transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: ${isStartDt || isEndDt ? '50%' : '2px'};
        transform: translateY(-50%);
        z-index: 0;
        border-top: 1px dashed ${palette.PRIMARY50};
        border-bottom: 1px dashed ${palette.PRIMARY50};
        border-radius: ${isHoveredCell && '8px 0 0 8px'};
        border-left: ${isHoveredCell && `1px dashed ${palette.PRIMARY50}`};
        height: 32px;
      }
    `}
`;
