import { memo } from 'react';
import styled from 'styled-components';
import { Col, Form, FormInstance, Input, Row } from 'antd';

import { exceptKoreanRule, phoneRule, requireRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';
import { MsdsForm } from 'types/material/msds';
import { normalizeBlank } from 'lib/form';

const MSDSBasicInfoBlock = styled.div`
  width: 538px;
  padding-left: 18px;
  margin: 0 auto;
`;

const MSDSBasicInfo = ({ form }: { form: FormInstance<MsdsForm> }) => {
  return (
    <MSDSBasicInfoBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">Product name</AlphabetLabel>}
        name={['msdsBasicInfo', 'productName']}
        rules={[requireRule, exceptKoreanRule]}
        normalize={normalizeBlank}
        required={false}
      >
        <Input
          placeholder="제품명"
          maxLength={100}
          onBlur={(e) => {
            const value = e.target.value;

            if (value.endsWith(' ')) {
              form.setFieldsValue({
                msdsBasicInfo: { productName: value.trimEnd() },
              });
            }
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">
            Recommended use of the chemical and restrictions on use
          </AlphabetLabel>
        }
        name={['msdsBasicInfo', 'recommendedUse']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Raw material for Cosmetics"
      >
        <Input placeholder="제품의 권고 용도와 사용상의 제한" maxLength={200} />
      </Form.Item>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label={<AlphabetLabel alphabet="C">Company</AlphabetLabel>}
            name={['msdsBasicInfo', 'companyName']}
            rules={[requireRule, exceptKoreanRule]}
            required={false}
          >
            <Input placeholder="회사명" maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="Manufacturer"
            name={['msdsBasicInfo', 'originManufacturerName']}
            rules={[requireRule, exceptKoreanRule]}
          >
            <Input placeholder="실 생산자명" maxLength={200} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="Telephone"
            name={['msdsBasicInfo', 'ceoTel']}
            rules={[requireRule, phoneRule]}
          >
            <Input placeholder="회사 전화번호" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="Fax"
            name={['msdsBasicInfo', 'fax']}
            rules={[requireRule, phoneRule]}
          >
            <Input placeholder="팩스" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="Emergency Phone #"
            name={['msdsBasicInfo', 'emergencyTel']}
            rules={[requireRule, phoneRule]}
          >
            <Input placeholder="긴급 전화번호" />
          </Form.Item>
        </Col>
      </Row>
    </MSDSBasicInfoBlock>
  );
};

export default memo(MSDSBasicInfo);
