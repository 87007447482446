import { Button, Col, Modal, Row, Spin, Upload } from 'antd';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { GlassMagnifier } from 'react-image-magnifiers';
import styled, { css } from 'styled-components';

import { ProductFormulaBreakdownTable } from 'components/product/ProductFormulaBreakdown';
import { NoticeCard, Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import Tags from 'components/ui/Tags';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { useQCQAFormulaBreakdown } from 'service/brand/qcqa/qcqa';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';
import {
  ProductFormulaBreakdownDraft,
  ProductFormulaBreakdownItem,
} from 'types/product';

export interface QCQAFormulaBreakdownModalProps {
  qcqaRelationDocumentRecordId: number;
  qcqaProductDetailId: number;
  onRegister: (filesList: FileList | File[], isScreening: boolean) => void;
  screeningFile?: File;
  onClose?: () => void;
  readOnly?: boolean;
}

interface QCQAFormulaBreakdownModalUIProps
  extends QCQAFormulaBreakdownModalProps {
  onSubmit?: (values: any) => void;
  prohibitDataUpdatedDate?: string;
  isUploaded: boolean;
  onChangeIsUploaded: (isUploaded: boolean) => void;
  qcqaFormulaBreakdownDraft: ProductFormulaBreakdownDraft | null;
  qcqaFormulaBreakdownItems?: {
    fileUrl: string;
    list: ProductFormulaBreakdownItem[];
    qcqaFormulaBreakdownId: number;
  };
  getLoading: boolean;
  onUpload: (file: File) => void;
  validateLoading: boolean;
  uploadedFile?: File;
  isRegistered: boolean;
  onChangeIsRegistered: (isRegistered: boolean) => void;
}

const UploadResultContainer = styled.div<{
  success?: boolean;
  isWtSumError: boolean;
  isSubstanceSumError: boolean;
}>`
  table th {
    text-align: center;
  }

  .ant-table-cell {
    font-size: 12px;

    &.bigo {
      display: none;
    }
  }
  .ant-table-wrapper {
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  ${({ success }) =>
    typeof success !== 'undefined' &&
    css`
      .ant-table-wrapper {
        border-color: ${success
          ? palette.MESSAGE_SUCCESS
          : palette.MESSAGE_ERROR};
      }

      ${success === false &&
      css`
        .ant-table-cell.bigo {
          display: table-cell;
        }
      `}

      td.ant-table-cell.bigo {
        color: ${palette.MESSAGE_ERROR};
      }
    `}

  ${({ isWtSumError }) =>
    isWtSumError &&
    css`
      td.ant-table-cell.wt {
        color: ${palette.MESSAGE_ERROR};
      }
    `}

  ${({ isSubstanceSumError }) =>
    isSubstanceSumError &&
    css`
      td.ant-table-cell.sub-percent {
        color: ${palette.MESSAGE_ERROR};
      }
    `}
`;

const StyledButton = styled(Button)<{ isDownload: boolean }>`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${palette.PRIMARY50};
      transition: ${({ isDownload }) =>
        isDownload ? 'all 300ms ease' : 'all 150ms ease'};
    }
  }

  svg,
  path {
    transition: ${({ isDownload }) =>
      isDownload ? 'all 300ms ease' : 'all 150ms ease'};
  }
`;

const QCQAFormulaBreakdownModalUI = ({
  prohibitDataUpdatedDate,
  isUploaded,
  onChangeIsUploaded,
  qcqaFormulaBreakdownDraft,
  qcqaFormulaBreakdownItems,
  getLoading,
  onUpload,
  validateLoading,
  uploadedFile,
  onRegister,
  isRegistered,
  onChangeIsRegistered,
  onClose,
  ...props
}: QCQAFormulaBreakdownModalUIProps) => {
  const notices = isUploaded
    ? [
        '업로드 결과 화면의 국가별 성분 규제 항목은 원료 정보에 대한 참고용이며 인증 진행 가능 여부와 무관합니다.',
        '업로드 하시는 성분이 화장품 성분이 아닌 경우, "[INCI Name] : 표준명으로 변경필요." 문구가 표시될 수 있습니다.',
      ]
    : [
        '하단의 엑셀 서식 다운로드 버튼을 클릭하여 다운로드 받은 양식에 아래 샘플을 참고하여 작성 후, 업로드해 주세요.',
      ];

  const success = qcqaFormulaBreakdownDraft
    ? !qcqaFormulaBreakdownDraft.isWtSumError &&
      !qcqaFormulaBreakdownDraft.isSubstanceSumError &&
      qcqaFormulaBreakdownDraft.uploadedExcelUrl === null
    : undefined;

  return (
    <Modal
      visible={true}
      width={1000}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      title="스크리닝"
      bodyStyle={{
        maxHeight: 816,
        overflowY: 'scroll',
      }}
      centered
    >
      <LoadingModal
        visible={getLoading || validateLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 스크리닝 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <Flex gap={16} align="center">
        {isUploaded && !props.readOnly && (
          <Icon name="back" onClick={() => onChangeIsUploaded(false)} />
        )}
        <Typography.Text light type="HEADLINE_1">
          {isUploaded ? '업로드 결과' : '전성분표 업로드'}
        </Typography.Text>
      </Flex>
      <NoticeCard
        title=""
        titleStyle={{ margin: 0 }}
        bodyStyle={{ padding: '12px 24px' }}
        style={{ marginTop: 24 }}
      >
        <ul>
          {notices.map((notice) => (
            <li key={nanoid()} style={{ lineHeight: '20px' }}>
              {notice}
            </li>
          ))}
        </ul>
      </NoticeCard>
      {!isUploaded ? (
        <>
          <Typography.Text type="TITLE_2" gutter={{ top: 24 }}>
            업로드 예시
          </Typography.Text>
          <GlassMagnifier
            imageSrc={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Formula+Breakdown.png`}
            imageAlt=""
            largeImageSrc={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Formula+Breakdown.png`}
            cursorStyle="zoom-in"
            magnifierSize="50%"
            style={{ maxWidth: 912, margin: '16px auto 0' }}
          />
          <Flex
            gap={8}
            justify="center"
            align="center"
            style={{ margin: '38px 0 28px' }}
          >
            <StyledButton
              type="dashed"
              isDownload={true}
              icon={
                <Icon name="download" size={14} style={{ marginRight: 4 }} />
              }
              href="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Product%2BFormula_Breakdown.xlsx"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              엑셀 서식 다운로드
            </StyledButton>
            <Upload
              accept=".xlsx"
              beforeUpload={(file) => {
                onUpload(file);
                return false;
              }}
              itemRender={() => null}
            >
              <StyledButton
                loading={validateLoading}
                isDownload={false}
                icon={
                  <Icon name="upload" size={14} style={{ marginRight: 4 }} />
                }
                style={{
                  width: 122,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onDrop={(e) => e.stopPropagation()}
              >
                파일 업로드
              </StyledButton>
            </Upload>
          </Flex>
        </>
      ) : (
        <UploadResultContainer
          success={success}
          isWtSumError={
            qcqaFormulaBreakdownDraft
              ? qcqaFormulaBreakdownDraft.isWtSumError
              : false
          }
          isSubstanceSumError={
            qcqaFormulaBreakdownDraft
              ? qcqaFormulaBreakdownDraft.isWtSumError
              : false
          }
        >
          <Row style={{ marginTop: 24, marginBottom: 8 }} align="bottom">
            <Col flex="auto">
              {qcqaFormulaBreakdownDraft && (
                <>
                  {success && (
                    <Typography.Text
                      type="BODY_2"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <StatusIcon status="success" />
                      정상적으로 업로드 되었습니다.
                    </Typography.Text>
                  )}
                  {qcqaFormulaBreakdownDraft.uploadedExcelUrl && (
                    <Typography.Text
                      type="BODY_2"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <StatusIcon status="error" />
                      업로드를 시도한 파일의 특정 셀에 오류가 있습니다.{' '}
                      <Typography.Text
                        type="BODY_2"
                        inline
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          downloadFile(
                            qcqaFormulaBreakdownDraft.uploadedExcelUrl,
                          )
                        }
                      >
                        여기
                      </Typography.Text>
                      를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
                    </Typography.Text>
                  )}
                  {qcqaFormulaBreakdownDraft.isWtSumError && (
                    <Typography.Text
                      type="BODY_2"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <StatusIcon status="error" />
                      모든 성분의 함량(WT %)을 더했을 때 100이 아닙니다.
                      수정하여 업로드해 주세요.
                    </Typography.Text>
                  )}
                  {qcqaFormulaBreakdownDraft.isSubstanceSumError && (
                    <Typography.Text
                      type="BODY_2"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <StatusIcon status="error" />
                      모든 성분의 함량(% calculated for each substance)을 더했을
                      때 100이 아닙니다. 수정하여 업로드해 주세요.
                    </Typography.Text>
                  )}
                </>
              )}
            </Col>
            <Col>
              <Tags.Mark color="lightGray" style={{ display: 'inline-block' }}>
                DB 업데이트
              </Tags.Mark>
              <Typography.Text
                type="SMALL"
                medium
                color="SLATE_GRAY70"
                inline
                gutter={{ left: 8 }}
              >
                [국가별 성분 규제]
              </Typography.Text>
              <Typography.Text
                type="SMALL"
                medium
                color="SLATE_GRAY70"
                inline
                gutter={{ left: 4 }}
              >
                {prohibitDataUpdatedDate}
              </Typography.Text>
            </Col>
          </Row>
          <Spin spinning={getLoading}>
            <ProductFormulaBreakdownTable
              formulaBreakdownItems={
                qcqaFormulaBreakdownDraft
                  ? qcqaFormulaBreakdownDraft.list
                  : qcqaFormulaBreakdownItems?.list || []
              }
            />
          </Spin>
          <Flex justify="center" style={{ margin: '24px 0 14px' }}>
            {success && !isRegistered ? (
              <Button
                type="primary"
                icon={
                  <Icon
                    name="check"
                    size={14}
                    color="ETC_WHITE"
                    style={{ marginRight: 4 }}
                  />
                }
                onClick={() => {
                  if (uploadedFile) onRegister([uploadedFile], true);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                이대로 등록
              </Button>
            ) : (
              !props.readOnly && (
                <Flex gap={16} align="center">
                  <StyledButton
                    isDownload={false}
                    icon={
                      <Icon
                        name="upload"
                        size={14}
                        style={{ marginRight: 4 }}
                      />
                    }
                    onClick={() => {
                      onChangeIsUploaded(false);
                      onChangeIsRegistered(false);
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    다시 업로드
                  </StyledButton>
                  <Typography.Text color="PRIMARY50" type="BODY_2">
                    {props.screeningFile?.name}
                  </Typography.Text>
                </Flex>
              )
            )}
          </Flex>
        </UploadResultContainer>
      )}
    </Modal>
  );
};

const QCQAFormulaBreakdownModal = (props: QCQAFormulaBreakdownModalProps) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [isRegistered, setIsRegistered] = useState(!!props.screeningFile);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const {
    qcqaFormulaBreakdownItems,
    getLoading,
    validateQCQAFormulaBreakdown,
    validateLoading,
    qcqaFormulaBreakdownDraft,
  } = useQCQAFormulaBreakdown({
    qcqaProductDetailId: props.qcqaProductDetailId,
    qcqaRelationDocumentRecordId: props.qcqaRelationDocumentRecordId,
  });

  const handleUpload = (file: File) => {
    validateQCQAFormulaBreakdown(
      { file },
      {
        onSuccess: () => {
          setIsUploaded(true);
          setUploadedFile(file);
        },
      },
    );
  };

  useEffect(() => {
    if (props.screeningFile) {
      validateQCQAFormulaBreakdown(
        { file: props.screeningFile },
        {
          onSuccess: () => {
            setIsUploaded(true);
            setUploadedFile(props.screeningFile);
          },
        },
      );
    }
  }, []);

  useEffect(() => {
    if (qcqaFormulaBreakdownItems) {
      setIsUploaded(true);
    }
  }, [qcqaFormulaBreakdownItems]);

  return (
    <QCQAFormulaBreakdownModalUI
      {...props}
      prohibitDataUpdatedDate={prohibitDataUpdatedDate}
      isUploaded={isUploaded}
      onChangeIsUploaded={setIsUploaded}
      qcqaFormulaBreakdownDraft={qcqaFormulaBreakdownDraft}
      qcqaFormulaBreakdownItems={qcqaFormulaBreakdownItems}
      getLoading={getLoading}
      validateLoading={validateLoading}
      onUpload={handleUpload}
      uploadedFile={uploadedFile}
      onRegister={props.onRegister}
      isRegistered={isRegistered}
      onChangeIsRegistered={setIsRegistered}
    />
  );
};

export default QCQAFormulaBreakdownModal;
