import { Row, Col, Select, Input } from 'antd';
import styled from 'styled-components';
import { useCallback, useRef, useState } from 'react';

import Typography from 'components/system/general/Typography';
import { SearchInput } from 'components/system';
import {
  ProductSearchType,
  ProductStatus,
  ProductListPageState,
} from 'types/product';
import { useProducts } from 'service/product';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const searchTypeOptions = [
  { label: '전체', value: ProductSearchType.ALL },
  { label: '제품명', value: ProductSearchType.PRODUCT_NAME },
  { label: '국가', value: ProductSearchType.COUNTRY_NAME },
  { label: '제조사', value: ProductSearchType.MANUFACTURER_NAME },
];

const ProductListBlock = styled.div`
  .ant-table-tbody > tr > td:first-child:not(.ant-table-selection-column) {
    padding-left: 36px;
  }

  .ant-table-thead > tr > th:first-child:not(.ant-table-selection-column) {
    padding-left: 36px;
  }
`;

const ProductList = ({
  pageState,
  onChangePageState,
}: {
  pageState: ProductListPageState;
  onChangePageState: (state: ProductListPageState) => void;
}) => {
  const inputRef = useRef<Input | null>(null);
  const { page, searchType, searchKeyword } = pageState;
  const [searchTypeBuffer, setSearchTypeBuffer] = useState<ProductSearchType>(
    searchType,
  );
  const [searchKeywordBuffer, setSearchKeywordBuffer] = useState(searchKeyword);

  const {
    products,
    totalElements,
    getLoading,
    deleteProduct,
    showProduct,
  } = useProducts({
    status: ProductStatus.PRD_REG,
    page,
    searchType,
    searchKeyword,
  });

  const handleChangeSearchType = useCallback(
    (searchType: ProductSearchType) => {
      setSearchKeywordBuffer('');
      setSearchTypeBuffer(searchType);

      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [],
  );

  const handleSearch = useCallback(
    (searchKeyword: string) => {
      onChangePageState({
        page: 1,
        searchKeyword,
        searchType: searchTypeBuffer,
      });
    },
    [searchTypeBuffer],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        searchKeyword,
        searchType,
      });
    },
    [searchKeyword, searchType],
  );

  return (
    <ProductListBlock>
      <Row gutter={8} justify="end">
        <Col>
          <Select
            value={searchTypeBuffer}
            options={searchTypeOptions}
            style={{ width: 164 }}
            onChange={handleChangeSearchType}
          />
        </Col>
        <Col>
          <SearchInput
            inputRef={inputRef}
            value={searchKeywordBuffer}
            defaultValue={searchKeyword}
            onChange={(e) => setSearchKeywordBuffer(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
        </Col>
      </Row>
      <Table
        columns={[
          {
            title: '제품명 (용량)',
            render: (product) => (
              <Typography.Text
                type="BODY_2"
                color="GRAY90"
                style={{ fontWeight: 500, cursor: 'pointer', maxWidth: 400 }}
                onClick={() => showProduct(product)}
              >
                {`${product.productDetail.productNameEn} ${
                  product.netWeight
                    ? `(${product.netWeight} ${product.netWeightUnit})`
                    : '( - )'
                }`}
              </Typography.Text>
            ),
          },
          {
            title: '국가',
            dataIndex: 'productCountries',
            render: (productCountries) =>
              productCountries ? (
                <Typography.Text
                  type="BODY_2"
                  color="GRAY70"
                  style={{ maxWidth: 168 }}
                >
                  {`${productCountries[0].country.countryNameKo}${
                    productCountries.length > 1
                      ? ` 외 ${productCountries.length - 1}개 국가`
                      : ''
                  }
                `}
                </Typography.Text>
              ) : (
                ''
              ),
          },
          {
            title: '제조사',
            render: ({ productDetail }) => (
              <Typography.Text
                type="BODY_2"
                color="GRAY70"
                style={{ maxWidth: 192 }}
              >
                {productDetail.manufacturerNameKo}
              </Typography.Text>
            ),
          },
          {
            render: (product) => (
              <Icon
                name="delete"
                color="GRAY60"
                size={20}
                style={{ marginRight: 16 }}
                onClick={() => deleteProduct(product.productId)}
              />
            ),
          },
        ]}
        dataSource={products}
        rowKey="productId"
        loading={getLoading}
        pagination={{
          current: page,
          total: totalElements,
          onChange: handleChangePage,
        }}
        style={{ marginTop: 8 }}
      />
    </ProductListBlock>
  );
};

export default ProductList;
