import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Input,
  message,
  Select,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { isEqual } from 'lodash';
import {
  ComponentProps,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { MinusIcon, Typography } from 'components/system';
import { CorrectButton, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { filterOptionForStringLabel, focusToInvalidatedField } from 'lib/form';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import {
  getExeceptKoreanRule,
  getTextRule,
  normalizeExeceptKoreanRule,
  requireRule,
} from 'lib/validate';
import {
  useNMPABasicInfoStatus,
  useNMPABasicOld,
  useNMPACorrectWithNoUpdate,
  useNMPADangerInfos,
  useNMPAOverwriteHistory,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  MaterialNmpaControlIndicatorse,
  NMPADocumentCode,
  NMPAEtcDataDangerType,
} from 'types/material/nmpa/nmpa';

const Container = styled.div`
  .ant-descriptions-view {
    overflow: initial;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-descriptions-header {
    margin-bottom: 8px;
  }

  .ant-descriptions-view {
    border-width: 0px;
    border-top: 2px solid ${palette.PRIMARY50};
    border-bottom: 1px solid ${palette.GRAY40};
    border-radius: 0;
  }

  .ant-descriptions-item-label {
    position: relative;
    background-color: ${palette.GRAY10};
    width: 160px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding: 0 16px;
  }

  .ant-descriptions-row {
    border-color: ${palette.GRAY40};
  }

  .ant-descriptions-item-content {
    border-left-width: 0px;
    border-right-width: 0px;
    vertical-align: sub;
    padding: 8px 16px;
  }
`;

const Content = styled.div`
  > * {
    margin-top: 32px;
  }

  > *:first-child {
    margin-top: 24px;
  }
`;

const CollapseBoxContainer = styled(Flex)`
  padding: 16px 36px;
  background: ${palette.SLATE_GRAY30};
  border-radius: 8px;
`;

const CollapseIcon = styled.div`
  width: 6px;
  height: 6px;
  background: ${palette.PRIMARY50};
`;

const MaterialTypeSelectBox = styled.div`
  padding: 24px 16px;
  background-color: #f5f8fb;
  border-radius: 4px;
`;

const MaterialTypeSelectGroup = styled(Flex)`
  margin-top: 8px;
  padding: 16px 0;
  background: #fff;
  border-radius: 4px;
`;

const DangerousElementListContainer = styled.div``;

enum Category {
  HEAVY_METAL = 'heavyMetal',
  MICROBE = 'microbe',
  PESTICIDE = 'pesticide',
  ETC = 'etc',
}

type NMPADIFForm = {
  etc_checked: boolean;
  heavyMetal_checked: boolean;
  pesticide_checked: boolean;
  microbe_checked: boolean;
} & {
  [Category.HEAVY_METAL]?: (MaterialNmpaControlIndicatorse & {
    qualityOrHazardousSubstancesCategory: string;
  })[];
  [Category.MICROBE]?: (MaterialNmpaControlIndicatorse & {
    qualityOrHazardousSubstancesCategory: string;
  })[];
  [Category.PESTICIDE]?: (MaterialNmpaControlIndicatorse & {
    qualityOrHazardousSubstancesCategory: string;
  })[];
  [Category.ETC]?: (MaterialNmpaControlIndicatorse & {
    qualityOrHazardousSubstancesCategory: string;
  })[];
};

const ETC_OPTION_VALUE = '기타';

const nameOptions: {
  [key: string]: { label: string; value: string; casno?: string }[];
} = {
  [Category.HEAVY_METAL]: [
    {
      label: '납',
      value: '납',
      casno: '7439-92-1',
    },
    {
      label: '비소',
      value: '비소',
      casno: '7440-38-2',
    },
    {
      label: '수은',
      value: '수은',
      casno: '7439-97-6',
    },
    {
      label: '카드뮴',
      value: '카드뮴',
      casno: '7440-43-9',
    },
    {
      label: '크롬',
      value: '크롬',
      casno: '7440-47-3',
    },
    {
      label: '니켈',
      value: '니켈',
      casno: '7440-02-0',
    },
    {
      label: '셀레늄',
      value: '셀레늄',
      casno: '7782-49-2',
    },
    {
      label: '베릴륨',
      value: '베릴륨',
      casno: '7440-41-7',
    },
    {
      label: '안티몬',
      value: '안티몬',
      casno: '7440-36-0',
    },
    {
      label: '스트론튬',
      value: '스트론튬',
      casno: '7440-24-6',
    },
    {
      label: '지르코늄',
      value: '지르코늄',
      casno: '7440-67-7',
    },
    {
      label: '코발트',
      value: '코발트',
      casno: '7440-48-4',
    },
    {
      label: '기타(직접 입력)',
      value: ETC_OPTION_VALUE,
    },
  ],
  [Category.MICROBE]: [
    {
      label: '총 호기성 생균수',
      value: '총 호기성 생균수',
    },
    {
      label: '곰팡이 및 효모균수',
      value: '곰팡이 및 효모균수',
    },
    {
      label: '내열성대장균',
      value: '내열성대장균',
    },
    {
      label: '황색포도상구균',
      value: '황색포도상구균',
    },
    {
      label: '녹농균',
      value: '녹농균',
    },
    {
      label: '기타(직접 입력)',
      value: ETC_OPTION_VALUE,
    },
  ],
  [Category.ETC]: [
    {
      label: '메탄올',
      value: '메탄올',
    },
    {
      label: '다이옥세인',
      value: '다이옥세인',
    },
    {
      label: '석면',
      value: '석면',
    },
    {
      label: '포름알데하이드',
      value: '포름알데하이드',
    },
    {
      label: '다이에틸렌글라이콜',
      value: '다이에틸렌글라이콜',
    },
    {
      label: '페놀',
      value: '페놀',
    },
    {
      label: '아크릴아미드',
      value: '아크릴아미드',
    },
    {
      label: '2차 알킬아민',
      value: '2차 알킬아민',
    },
    {
      label: '니트로사민',
      value: '니트로사민',
    },
    {
      label: '1,3-부타디엔',
      value: '1,3-부타디엔',
    },
    {
      label: '하이드로퀴논',
      value: '하이드로퀴논',
    },
    {
      label: '벤젠',
      value: '벤젠',
    },
    {
      label: '기타(직접 입력)',
      value: ETC_OPTION_VALUE,
    },
  ],
  [Category.PESTICIDE]: [],
};

const isEtcOption = (category: Category, value: string) =>
  nameOptions[category]
    ?.slice(0, nameOptions[category].length - 1)
    .filter((option) => option.label === value).length === 0;

const NMPADIF = ({ materialId }: { materialId: number }) => {
  // HINT: 최초 불러온 데이터를 저장하고 있고, 수정할 내역을 검사할 때 사용됩니다.
  const [initFormData, setInitFormData] = useState<NMPADIFForm>();
  const [form] = Form.useForm<NMPADIFForm>();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const { openNMPACorrectChatModal, openAlertModal } = useModal();
  const {
    dangerInfos,
    isReadNMPADangerInfosLoading,
    updateNMPADangerInfos,
    isUpdateNMPADangerInfosLoading,
  } = useNMPADangerInfos(materialId);
  const { nmpaBasicInfo } = useNMPABasicOld(materialId);

  const materialNmpaBasicInfoId = nmpaBasicInfo?.materialNmpaBasicInfoId;

  const {
    nmpaCorrectRequestWithNoUpdate,
    nmpaCorrectRequestWithNoUpdateLoading,
  } = useNMPACorrectWithNoUpdate({
    materialNmpaBasicInfoId,
    documentCode: NMPADocumentCode.DIF,
  });

  const {
    basicInfoStatus,
    isLoading: isBasicInfoStatusLoading,
  } = useNMPABasicInfoStatus({
    materialId,
    documentCode: DocumentCode.DIF,
    enabled: materialId !== undefined,
  });
  const {
    nmpaOverwriteHistory,
    nmpaOverwriteHistoryLoading,
  } = useNMPAOverwriteHistory({
    nmpaBasicInfoId: materialId,
    documentCode: NMPADocumentCode.DIF,
  });
  const history = useHistory();
  const updateMode = (dangerInfos || false) !== false;
  const readOnly =
    (nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.ONGOING &&
      !basicInfoStatus?.isNmpaDocModStatus) ||
    nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.FINISH;
  const loading = isBasicInfoStatusLoading || isReadNMPADangerInfosLoading;
  const ctaName = useMemo(() => {
    if (basicInfoStatus?.isNmpaDocModStatus) return '보완 완료';
    if (
      !updateMode ||
      dangerInfos?.materialNmpaControlIndicatorses.length === 0
    )
      return '등록';
    return '수정';
  }, [updateMode, basicInfoStatus]);

  const handleCheckboxChange = (
    category: Category,
  ): ComponentProps<typeof Checkbox>['onChange'] => (e) => {
    const {
      target: { checked },
    } = e;
    const handleOk = () => {
      form.setFieldsValue({
        [category]: checked
          ? [
              {
                // HINT: 잔류 농약 위험 품질관리/위험물질 항목의 경우 기본 값으로 Category를 직접 입력으로 설정
                ...(category === Category.PESTICIDE && {
                  qualityOrHazardousSubstancesCategory: ETC_OPTION_VALUE,
                }),
              },
            ]
          : undefined,
        [`${category}_checked`]: checked,
      });
    };

    if (checked) {
      handleOk();
      return;
    }

    // checked 변경 방지
    form.setFieldsValue({
      [getCheckboxNameFromListName(category)]: !checked,
    });
    openAlertModal({
      content: (
        <Typography.Text type="BODY_2" align="center">
          체크 해제하시면
          <br />
          아래에 입력하신 데이터도 함께 사라집니다.
        </Typography.Text>
      ),
      okText: '체크 해제',
      width: 400,
      onOk: handleOk,
    });
  };

  const handleNoUpdateSubmit = () => {
    if (materialNmpaBasicInfoId) {
      openAlertModal({
        content: `
'보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

보완할 내용 없이 처리 하시겠습니까?
`,
        onOk: () => {
          nmpaCorrectRequestWithNoUpdate(
            {},
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');
                history.goBack();
              },
            },
          );
        },
      });
    }
  };

  const handleSubmit = () => {
    // 형식 변경 formData to API params
    const materialNmpaControlIndicatorses: any[] = [];
    const formValues = form.getFieldsValue();

    if (
      initFormData !== undefined &&
      isEqual(formValues, initFormData as any)
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    if (initFormData) {
      const newInitFormData = {
        ...initFormData,
        microbe: initFormData.microbe?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
        heavyMetal: initFormData.heavyMetal?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
        etc: initFormData.etc?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
        pesticide: initFormData.pesticide?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
      };

      const newFormData = {
        ...formValues,
        microbe: formValues.microbe?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
        heavyMetal: formValues.heavyMetal?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
        etc: formValues.etc?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
        pesticide: formValues.pesticide?.map((item) => ({
          ...item,
          key: item.qualityOrHazardousSubstances,
        })),
      };

      nmpaOverwriteHistory(
        getChangedValues({ obj1: newInitFormData, obj2: newFormData }),
      );
    }

    const categoriesAndDangerTypes: [Category, NMPAEtcDataDangerType][] = [
      [Category.HEAVY_METAL, NMPAEtcDataDangerType.METAL],
      [Category.MICROBE, NMPAEtcDataDangerType.MICROBE],
      [Category.PESTICIDE, NMPAEtcDataDangerType.PESTICIDE_RESIDUE],
      [Category.ETC, NMPAEtcDataDangerType.ETC],
    ];

    for (const [category, dangerType] of categoriesAndDangerTypes) {
      const dataList = formValues[category];
      if (!dataList?.length) continue;

      for (const data of dataList) {
        const {
          qualityOrHazardousSubstances,
          casNo,
          figure,
          unit,
          note,
        } = data;

        materialNmpaControlIndicatorses.push({
          dangerType,
          qualityOrHazardousSubstances,
          casNo,
          figure,
          unit,
          note,
        });
      }
    }

    const dataToSend = {
      materialId,
      isDangerMetal: formValues['heavyMetal_checked'] || false,
      isDangerMicrobe: formValues['microbe_checked'] || false,
      isDangerPesticideResidue: formValues['pesticide_checked'] || false,
      isDangerEtc: formValues['etc_checked'] || false,
      materialNmpaControlIndicatorses,
    };

    updateNMPADangerInfos(dataToSend, {
      onSuccess: () => {
        message.success(`${ctaName}되었습니다.`);
        history.replace(`${path.material.nmpa.material.root}/${materialId}`);
      },
    });
  };

  useEffect(() => {
    if (!dangerInfos) return;

    const dangerTypesAndCategories: {
      [key: string]: Category;
    } = {
      [NMPAEtcDataDangerType.METAL]: Category.HEAVY_METAL,
      [NMPAEtcDataDangerType.MICROBE]: Category.MICROBE,
      [NMPAEtcDataDangerType.PESTICIDE_RESIDUE]: Category.PESTICIDE,
      [NMPAEtcDataDangerType.ETC]: Category.ETC,
    };
    const newFormData: any = {
      [getCheckboxNameFromListName(
        Category.HEAVY_METAL,
      )]: dangerInfos.isDangerMetal,
      [getCheckboxNameFromListName(
        Category.MICROBE,
      )]: dangerInfos.isDangerMicrobe,
      [getCheckboxNameFromListName(
        Category.PESTICIDE,
      )]: dangerInfos.isDangerPesticideResidue,
      [getCheckboxNameFromListName(Category.ETC)]: dangerInfos.isDangerEtc,
    };

    const addListItem = (
      category: Category,
      data: MaterialNmpaControlIndicatorse,
    ) => {
      if (!newFormData[category]) {
        newFormData[category] = [];
      }

      const { qualityOrHazardousSubstances, casNo, figure, unit, note } = data;

      newFormData[category].push({
        qualityOrHazardousSubstances,
        qualityOrHazardousSubstancesCategory: isEtcOption(
          category,
          qualityOrHazardousSubstances,
        )
          ? ETC_OPTION_VALUE
          : qualityOrHazardousSubstances,
        casNo,
        figure,
        unit,
        note,
      });
    };

    for (const data of dangerInfos.materialNmpaControlIndicatorses) {
      addListItem(dangerTypesAndCategories[data.dangerType], data);
    }

    form.setFieldsValue(newFormData);
    setTimeout(() => setInitFormData(form.getFieldsValue()), 0);
  }, [dangerInfos]);

  return (
    <Container>
      {loading && <FullLoading />}
      {basicInfoStatus?.isNmpaDocModStatus && (
        <Flex justify="end" style={{ marginBottom: 16 }}>
          <CorrectButton
            onClick={() => {
              openNMPACorrectChatModal({
                title: nmpaBasicInfo?.tradeName,
                nmpaBasicInfoId: materialNmpaBasicInfoId,
                documentCode: NMPADocumentCode.DIF,
                isFirstOpenChatModal,
              });
              setIsFirstOpenChatModal(true);
            }}
            disableAnim={false}
          />
        </Flex>
      )}
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
      >
        <CollapseBoxContainer
          justify="space-between"
          style={{ marginBottom: 16 }}
        >
          <Flex align="center" gap={8}>
            <CollapseIcon />
            <Typography.Text color="SLATE_GRAY70" medium>
              위험 정보 및 통제 지표를 선택해 주세요.
            </Typography.Text>
          </Flex>
        </CollapseBoxContainer>
        <MaterialTypeSelectBox>
          <Typography.Text medium>
            기타 다른 원료 특징 (중복 가능)
          </Typography.Text>
          <MaterialTypeSelectGroup columnGap={98} justify="center">
            <Flex columnGap={8}>
              <Form.Item
                noStyle
                valuePropName="checked"
                name={getCheckboxNameFromListName(Category.HEAVY_METAL)}
              >
                <Checkbox
                  disabled={readOnly}
                  onChange={handleCheckboxChange(Category.HEAVY_METAL)}
                />
              </Form.Item>
              <Typography.Text>중금속 위험</Typography.Text>
            </Flex>
            <Flex columnGap={8}>
              <Form.Item
                noStyle
                valuePropName="checked"
                name={getCheckboxNameFromListName(Category.MICROBE)}
              >
                <Checkbox
                  disabled={readOnly}
                  onChange={handleCheckboxChange(Category.MICROBE)}
                />
              </Form.Item>
              <Typography.Text>미생물 위험</Typography.Text>
            </Flex>
            <Flex columnGap={8}>
              <Form.Item
                noStyle
                valuePropName="checked"
                name={getCheckboxNameFromListName(Category.PESTICIDE)}
              >
                <Checkbox
                  disabled={readOnly}
                  onChange={handleCheckboxChange(Category.PESTICIDE)}
                />
              </Form.Item>
              <Typography.Text>잔류 농약 위험</Typography.Text>
            </Flex>
            <Flex columnGap={8}>
              <Form.Item
                noStyle
                valuePropName="checked"
                name={getCheckboxNameFromListName(Category.ETC)}
              >
                <Checkbox
                  disabled={readOnly}
                  onChange={handleCheckboxChange(Category.ETC)}
                />
              </Form.Item>
              <Typography.Text>기타 위험</Typography.Text>
            </Flex>
          </MaterialTypeSelectGroup>
        </MaterialTypeSelectBox>
        <Content>
          <DangerousElementList
            form={form}
            readOnly={readOnly}
            formListName={Category.HEAVY_METAL}
            title="중금속 위험 품질관리/위험물질 항목에 대해 입력해 주세요."
          />
          <DangerousElementList
            form={form}
            readOnly={readOnly}
            formListName={Category.MICROBE}
            title="미생물 위험 품질관리/위험물질 항목에 대해 입력해 주세요."
          />
          <DangerousElementList
            form={form}
            readOnly={readOnly}
            formListName={Category.PESTICIDE}
            title="잔류 농약 위험 품질관리/위험물질 항목에 대해 입력해 주세요."
            nameSelectHidden={true}
            nameInputPlaceholder="직접 입력"
          />
          <DangerousElementList
            form={form}
            readOnly={readOnly}
            formListName={Category.ETC}
            title="기타 위험 품질관리/위험물질 항목에 대해 입력해 주세요."
            prefixNameLabel="기타 "
          />
        </Content>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnly}>
            <Flex>
              {basicInfoStatus?.isNmpaDocModStatus && (
                <Button
                  loading={nmpaCorrectRequestWithNoUpdateLoading}
                  onClick={handleNoUpdateSubmit}
                >
                  보완할 내용 없음
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  isUpdateNMPADangerInfosLoading || nmpaOverwriteHistoryLoading
                }
              >
                {ctaName}
              </Button>
            </Flex>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

const DangerousElementList = ({
  formListName,
  title,
  prefixNameLabel,
  nameSelectHidden,
  nameInputPlaceholder,
  readOnly,
  form,
}: {
  formListName: string;
  title: string;
  prefixNameLabel?: string;
  nameSelectHidden?: boolean;
  nameInputPlaceholder?: string;
  readOnly?: boolean;
  form: FormInstance;
}) => {
  const [activeCollapse, setActiveCollapse] = useState(true);

  const handleNameSelect = (name: number) => (value: string) => {
    const newData = [...form.getFieldValue(formListName)];

    newData[name]['qualityOrHazardousSubstances'] =
      value === ETC_OPTION_VALUE ? '' : value;

    newData[name]['casNo'] = nameOptions[formListName].find(
      (i) => i?.label === value,
    )?.casno;

    form.setFieldsValue({
      [formListName]: newData,
    });
    form.validateFields([[formListName, name, 'qualityOrHazardousSubstances']]);
  };

  return (
    <DangerousElementListContainer>
      <Form.List name={formListName}>
        {(fields, { add, remove }) =>
          fields.length > 0 && (
            <>
              <CollapseBox
                activeCollapse={activeCollapse}
                onActiveCollapseChange={setActiveCollapse}
                title={title}
              >
                <>
                  {fields.map(({ name, key }, index) => (
                    <Descriptions style={{ marginTop: 16 }} key={key} bordered>
                      <Descriptions.Item
                        label={
                          <>
                            <Typography.Text
                              style={{
                                alignItems: 'flex-start',
                                position: 'relative',
                              }}
                              medium
                              asterisk
                            >
                              {prefixNameLabel}품질관리 항목/위험물질 명칭
                            </Typography.Text>
                            {!readOnly && index > 0 && (
                              <MinusIcon
                                style={{
                                  position: 'absolute',
                                  left: -40,
                                  top: 24,
                                }}
                                onClick={() => remove(index)}
                              />
                            )}
                          </>
                        }
                        span={3}
                      >
                        <Flex columnGap={8}>
                          {!nameSelectHidden && (
                            <Form.Item
                              name={[
                                name,
                                'qualityOrHazardousSubstancesCategory',
                              ]}
                              style={{ flex: 1 }}
                              rules={[requireRule]}
                            >
                              <Select
                                showSearch
                                disabled={readOnly}
                                style={{ width: '100%' }}
                                options={nameOptions[formListName]}
                                filterOption={filterOptionForStringLabel}
                                onSelect={handleNameSelect(name)}
                              />
                            </Form.Item>
                          )}

                          <Form.Item
                            name={[name, 'qualityOrHazardousSubstances']}
                            style={{ flex: 1 }}
                            rules={[
                              requireRule,
                              getTextRule({
                                end: 100,
                                message: '최대 100자',
                              }),
                            ]}
                          >
                            <Input
                              disabled={
                                readOnly ||
                                form.getFieldValue([
                                  formListName,
                                  name,
                                  'qualityOrHazardousSubstancesCategory',
                                ]) !== ETC_OPTION_VALUE
                              }
                              placeholder={nameInputPlaceholder}
                            />
                          </Form.Item>
                        </Flex>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text medium>CAS No.</Typography.Text>
                        }
                      >
                        <Form.Item
                          name={[name, 'casNo']}
                          rules={[
                            getTextRule({
                              end: 13,
                              message: '최대 13자',
                            }),
                          ]}
                          normalize={normalizeExeceptKoreanRule({
                            start: 0,
                            end: 13,
                          })}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text medium asterisk>
                            수치
                          </Typography.Text>
                        }
                      >
                        <Form.Item
                          name={[name, 'figure']}
                          rules={[
                            requireRule,
                            getExeceptKoreanRule({
                              end: 50,
                              message: '최대 50자',
                            }),
                          ]}
                          normalize={normalizeExeceptKoreanRule({
                            start: 0,
                            end: 50,
                          })}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text medium asterisk>
                            단위
                          </Typography.Text>
                        }
                      >
                        <Form.Item
                          name={[name, 'unit']}
                          rules={[
                            requireRule,
                            getTextRule({
                              end: 10,
                              message: '최대 10자',
                            }),
                          ]}
                          normalize={normalizeExeceptKoreanRule({
                            start: 0,
                            end: 10,
                          })}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<Typography.Text medium>비고</Typography.Text>}
                        span={3}
                      >
                        <Form.Item
                          name={[name, 'note']}
                          rules={[
                            getTextRule({
                              start: 0,
                              end: 500,
                              message: '최대 500자',
                            }),
                          ]}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  ))}
                  {!readOnly && (
                    <Button
                      type="dashed"
                      icon={
                        <Icon
                          name="plus"
                          size={14}
                          color="PRIMARY50"
                          style={{ marginRight: 4 }}
                        />
                      }
                      block
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 16,
                        maxWidth: 520,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      onClick={() => {
                        if (fields.length >= 20) {
                          message.warning('최대 20개까지 입력 가능합니다.');
                          return;
                        }
                        add({});
                      }}
                    >
                      품질관리 항목/위험물질 추가
                    </Button>
                  )}
                </>
              </CollapseBox>
            </>
          )
        }
      </Form.List>
    </DangerousElementListContainer>
  );
};

const CollapseBox = ({
  children,
  onActiveCollapseChange,
  activeCollapse,
  title,
}: {
  children?: ReactElement | ReactElement[];
  onActiveCollapseChange?: React.Dispatch<SetStateAction<boolean>>;
  activeCollapse?: boolean;
  title?: string;
}) => {
  return (
    <>
      <CollapseBoxContainer
        justify="space-between"
        onClick={() => onActiveCollapseChange?.((prev) => !prev)}
      >
        <Flex align="center" gap={8}>
          <CollapseIcon />
          <Typography.Text color="SLATE_GRAY70" medium>
            {title}
          </Typography.Text>
        </Flex>
        <Icon name={activeCollapse ? 'up' : 'down'} />
      </CollapseBoxContainer>
      {activeCollapse && children}
    </>
  );
};

const getCheckboxNameFromListName = (listName: string) => listName + '_checked';

export default NMPADIF;
