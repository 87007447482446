import { Button, Form, FormInstance, Select, message } from 'antd';
import isEqual from 'lodash/isEqual';
import { ComponentProps, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import GoBackIfThereIsNoCountryId from 'components/common/GoBackIfThereIsNoCountryId';
import UpdateLog from 'components/product/UpdateLog';
import { Collapse, Typography } from 'components/system';
import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
} from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import useGA, { EGAActionType } from 'hook/useGA';
import { useModal } from 'hook/useModal';
import { messages } from 'lib/consts';
import { filterOptionForStringLabel, focusToInvalidatedField } from 'lib/form';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import {
  EfficacyChina,
  useCountryId,
  useCurrentProduct,
  useEfficacyChinaList,
  useProductEfficacyChina,
} from 'service/brand/product/product';
import { useProductDocStatus } from 'service/product';
import { DocumentCode } from 'types/product';

export interface Efficacy {
  name?: string;
  code?: string;
}

export interface EfficacyForm {
  efficacies: {
    productEfficacyChinaId?: number;
    efficacyChinaId?: number;
  }[];
}

type GuideText = {
  text: ReactNode;
  subtext: ReactNode;
};

const Container = styled.div``;

const GuideTextWrapper = styled.div`
  padding: 16px;
  margin-right: 56px;
  border-radius: 4px;
  background-color: ${palette.PRIMARY10};

  > *:first-of-type {
    margin-bottom: 4px;
  }
`;

const ProductEfficacyUIContainer = styled.div``;

const HighlightedLetter = styled.span`
  color: ${palette.PRIMARY50};
`;

const efficaciesInitialValue = [{}];

export const guideTextMap: {
  [key: string]: GuideText;
} = {
  PROCEEDING_WITHOUT_EFFICACY_EVALUATION: {
    text: (
      <Typography.Text>
        해당 효능은{' '}
        <HighlightedLetter>효능 평가자료 없이 진행</HighlightedLetter>이
        가능합니다.
      </Typography.Text>
    ),
    subtext: (
      <Typography.Text color="SLATE_GRAY70" type="SMALL">
        (염모, 퍼머, 청결, 메이크업 리무버, 미용수식, 방향, 체취,
        상쾌/보송(땀띠파우더), 헤어컬러 프로텍트, 제모, 면도/제모 보조)
      </Typography.Text>
    ),
  },
  THREE_EFFICACY_EVALUATION: {
    text: (
      <Typography.Text>
        <HighlightedLetter>인체 효능 평가 시험</HighlightedLetter> 또는{' '}
        <HighlightedLetter>소비자 사용 테스트</HighlightedLetter> 또는{' '}
        <HighlightedLetter>실험실 시험</HighlightedLetter>
      </Typography.Text>
    ),
    subtext: (
      <Typography.Text color="SLATE_GRAY70" type="SMALL">
        반드시 위 항목들 중 한 가지 이상의 증빙이 필요합니다.
      </Typography.Text>
    ),
  },
  FOUR_EFFICACY_EVALUATION: {
    text: (
      <Typography.Text>
        <HighlightedLetter>문헌 자료 / 연구 데이터</HighlightedLetter> 또는{' '}
        <HighlightedLetter>인체 효능 평가 시험</HighlightedLetter> 또는{' '}
        <HighlightedLetter>소비자 사용 테스트</HighlightedLetter> 또는{' '}
        <HighlightedLetter>실험실 시험</HighlightedLetter>
      </Typography.Text>
    ),
    subtext: (
      <Typography.Text color="SLATE_GRAY70" type="SMALL">
        반드시 위 항목들 중 한 가지 이상의 증빙이 필요합니다.
      </Typography.Text>
    ),
  },
  SPECIAL_EFFICACY: {
    text: (
      <Typography.Text>
        해당 효능은 <HighlightedLetter>특수 효능</HighlightedLetter>으로,
        certicos 플랫폼상에서는 진행이 불가합니다.
      </Typography.Text>
    ),
    subtext: (
      <Typography.Text color="SLATE_GRAY70" type="SMALL">
        진행을 원하실 경우 전화나 하단의 ‘이용 문의’로 문의 부탁드립니다.
      </Typography.Text>
    ),
  },
  ONE_EFFICACY_EVALUATION: {
    text: (
      <Typography.Text>
        <HighlightedLetter>인체 효능 평가 시험</HighlightedLetter>
      </Typography.Text>
    ),
    subtext: (
      <Typography.Text color="SLATE_GRAY70" type="SMALL">
        해당 효능은 반드시 인체 효능 평가 시험으로만 증빙이 가능합니다.
      </Typography.Text>
    ),
  },
  TWO_EFFICACY_EVALUATION: {
    text: (
      <Typography.Text>
        <HighlightedLetter>인체 효능 평가 시험</HighlightedLetter> 또는{' '}
        <HighlightedLetter>소비자 사용 테스트</HighlightedLetter>
      </Typography.Text>
    ),
    subtext: (
      <Typography.Text color="SLATE_GRAY70" type="SMALL">
        반드시 위 항목들 중 한 가지 이상의 증빙이 필요합니다.
      </Typography.Text>
    ),
  },
};

export const ProductEfficacyChina = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [form] = Form.useForm<EfficacyForm>();
  const { sendEventToGA } = useGA();
  const currentProduct = useCurrentProduct();
  const countryId = useCountryId() || 0;
  const productId = currentProduct.productId;

  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PEI,
  });

  const {
    isLoading: isUseEfficacyChinaListLoading,
    efficacyChinaList,
  } = useEfficacyChinaList();

  const [initialEfficacyChinaList, setInitialEfficacyChinaList] = useState<
    EfficacyForm['efficacies']
  >([]);

  const {
    isGetProductEfficacyChinaListLoading,
    productEfficacyChinaList,
    addProductEfficacyChina,
    isAddProductEfficacyChinaLoading,
    updateProductEfficacyChina,
    isUpdateProductEfficacyChinaLoading,
  } = useProductEfficacyChina({ productId });

  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );

  const defaultActiveKey = productEfficacyChinaList ? '0' : undefined;

  const updateMode = productEfficacyChinaList.length > 0;

  const loading =
    isUseEfficacyChinaListLoading || isGetProductEfficacyChinaListLoading;
  const submitting =
    isAddProductEfficacyChinaLoading || isUpdateProductEfficacyChinaLoading;
  const cta = useMemo(() => {
    if (docStatus?.status === 'MOD') {
      return '보완 완료';
    }

    return '등록';
  }, [docStatus]);

  const handleSubmit: ComponentProps<
    typeof ProductEfficacyUI
  >['onSubmit'] = () => {
    const { efficacies } = form.getFieldsValue();

    if (docStatus?.status === 'MOD') {
      if (isEqual(initialEfficacyChinaList, efficacies)) {
        message.warn(messages.NO_NEED_TO_UPDATE);
        return;
      }

      updateProductEfficacyChina(
        {
          countryId,
          productEfficacyChinas: efficacies.map(
            ({ productEfficacyChinaId, efficacyChinaId }) => ({
              ...(productEfficacyChinaList.find(
                (item) =>
                  item.productEfficacyChinaId === productEfficacyChinaId,
              )?.efficacyChinaId === efficacyChinaId && {
                productEfficacyChinaId,
              }),
              efficacyChinaId,
            }),
          ) as {
            productEfficacyChinaId?: number;
            efficacyChinaId: number;
          }[],
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: '제품 효능 정보',
              actionType: EGAActionType.MODIFY,
            });
            message.success('보완 완료되었습니다.');
            history.goBack();
          },
        },
      );
    } else {
      addProductEfficacyChina(
        {
          countryId,
          efficacyChinaIds: efficacies
            .filter((efficacy) => efficacy.efficacyChinaId !== undefined)
            .map((efficacy) => efficacy.efficacyChinaId) as number[],
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: '제품 효능 정보',
              actionType: EGAActionType.REGISTER,
            });
            message.success(`등록되었습니다.`);
            history.goBack();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (!productEfficacyChinaList?.length) return;
    const newEfficacies: EfficacyForm['efficacies'] = productEfficacyChinaList;
    setInitialEfficacyChinaList(newEfficacies);
    form.setFieldsValue({
      efficacies: newEfficacies,
    });
  }, [productEfficacyChinaList]);

  return (
    <Container>
      {loading && <FullLoading />}
      <GoBackIfThereIsNoCountryId />
      <Flex justify="end" gap={8} style={{ marginBottom: 24 }}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.PEI}
        />
        {updateMode && (
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.PEI}
          />
        )}
      </Flex>
      <ProductEfficacyUI
        form={form}
        cta={cta}
        efficacyChinaList={efficacyChinaList}
        defaultActiveKey={defaultActiveKey}
        readOnly={readOnlyMode}
        submitting={submitting}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

const ProductEfficacyUI = ({
  form,
  cta,
  readOnly = false,
  onSubmit,
  submitting,
  efficacyChinaList,
  defaultActiveKey,
}: {
  form?: FormInstance<EfficacyForm>;
  cta?: string;
  readOnly?: boolean;
  onSubmit?: (values: EfficacyForm) => void;
  efficacyChinaList?: EfficacyChina[];
  submitting?: boolean;
  defaultActiveKey?: string;
}) => {
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const { openAlertModal } = useModal();

  const efficacyChinaOptions = useMemo(() => {
    return efficacyChinaList?.map((efficacyChina) => ({
      label: efficacyChina.efficacyName,
      value: efficacyChina.efficacyChinaId,
    }));
  }, [efficacyChinaList]);

  const handleCollapseActiveKeyChange = (key: string[]) => {
    setActiveKey(key.length > 0 ? key[key.length - 1] : undefined);
  };

  const handleClickSubmitButton = () => {
    if (cta !== '등록') {
      return form?.submit();
    }
    openAlertModal({
      content: `확인을 클릭하시면, 선택하신 효능에 따른 증빙자료를\n[최종 심사 자료] 리스트의 [효능 평가 자료] 에\n입력하실 수 있으며 해당 정보는 담당자의 안내 이후\n입력해 주세요.`,
      onOk: () => {
        form?.submit();
      },
      okLoading: submitting,
    });
  };

  const handleSubmit = (values: EfficacyForm) => {
    onSubmit?.(values);
  };

  const getEfficacyNameById = (efficacyChinaId: number | undefined) =>
    efficacyChinaId === undefined
      ? ''
      : efficacyChinaList?.find(
          (efficacyChina) => efficacyChina.efficacyChinaId === efficacyChinaId,
        )?.efficacyName || '';

  const getEfficacyGuideTextById = (efficacyChinaId: number | undefined) => {
    if (efficacyChinaId === undefined) return undefined;

    const descriptionCode = efficacyChinaList?.find(
      (efficacyChina) => efficacyChina.efficacyChinaId === efficacyChinaId,
    )?.descriptionCode;

    return (descriptionCode && guideTextMap[descriptionCode]) || undefined;
  };

  useEffect(() => {
    if (defaultActiveKey === undefined) return;

    setActiveKey(defaultActiveKey);
  }, [defaultActiveKey]);

  return (
    <ProductEfficacyUIContainer>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={
          form ? focusToInvalidatedField({ form, offsetY: -300 }) : undefined
        }
      >
        <Form.List name="efficacies" initialValue={efficaciesInitialValue}>
          {(fields, { add, remove }) => (
            <>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 16 }}
              >
                <Typography.Text type="TITLE_2" asterisk>
                  제품 효능 선택
                </Typography.Text>
                {!readOnly && (
                  <Button
                    icon={
                      <Icon
                        name="plus"
                        color="PRIMARY50"
                        size={14}
                        style={{ marginRight: 4 }}
                      />
                    }
                    block
                    style={{
                      width: 95,
                      height: 32,
                      padding: '8px 6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      add({});
                      handleCollapseActiveKeyChange([fields.length.toString()]);
                    }}
                  >
                    효능 추가
                  </Button>
                )}
              </Flex>
              <Collapse
                activeKey={activeKey}
                onActiveKeyChange={handleCollapseActiveKeyChange}
                defaultActiveKey={defaultActiveKey}
              >
                {fields.map(({ key, name }) => {
                  const efficacyName = getEfficacyNameById(
                    form?.getFieldValue([
                      'efficacies',
                      name,
                      'efficacyChinaId',
                    ]),
                  );
                  const efficacyGuideText = getEfficacyGuideTextById(
                    form?.getFieldValue([
                      'efficacies',
                      name,
                      'efficacyChinaId',
                    ]),
                  );
                  const headerText =
                    efficacyName || generateDefaultEfficacyName(name + 1);

                  const handleEfficacyChange = (efficacyChinaId: number) => {
                    if (!form) return;

                    const newEfficacies = form.getFieldValue('efficacies');
                    newEfficacies[name].efficacyChinaId = efficacyChinaId;

                    form.setFieldsValue({
                      efficacies: newEfficacies,
                    });

                    (document.activeElement as HTMLElement).blur();
                  };

                  const efficacyOptions = efficacyChinaOptions?.filter(
                    (option) => {
                      const selectedEfficacyChinaId = form?.getFieldValue([
                        'efficacies',
                        name,
                        'efficacyChinaId',
                      ]);

                      return (
                        option.value === selectedEfficacyChinaId ||
                        !form
                          ?.getFieldValue('efficacies')
                          .map(
                            (efficacy: EfficacyForm['efficacies'][0]) =>
                              efficacy.efficacyChinaId,
                          )
                          .includes(option.value)
                      );
                    },
                  );

                  return (
                    <Collapse.Panel
                      key={key}
                      headerText={headerText}
                      headerIcon={<Icon name="protect" size={18} />}
                      canDelete={!readOnly && fields.length > 1}
                      onDelete={() => {
                        remove(name);
                      }}
                    >
                      <Form.Item key={name} noStyle>
                        <EfficacyListItem
                          name={name}
                          readOnly={readOnly}
                          efficacyName={efficacyName}
                          guideText={efficacyGuideText}
                          onEfficacyChange={handleEfficacyChange}
                          efficacyOptions={efficacyOptions}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </>
          )}
        </Form.List>
      </Form>
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnly}>
          {cta === '보완 완료' && (
            <CorrectRequestWithoutChangeButton
              documentCode={DocumentCode.PEI}
            />
          )}
          <Button
            type="primary"
            onClick={handleClickSubmitButton}
            loading={submitting}
          >
            {cta}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductEfficacyUIContainer>
  );
};

const EfficacyListItem = ({
  name,
  efficacyName,
  efficacyOptions,
  guideText,
  readOnly,
  onEfficacyChange,
}: {
  name: number;
  efficacyName?: string;
  efficacyOptions?: { label: string; value: number }[];
  guideText?: GuideText;
  readOnly?: boolean;
  onEfficacyChange?: (efficacyChinaId: number) => void;
}) => {
  return (
    <OrderList>
      <OrderListItem>
        <OrderListItemLabel type="BODY_1" medium>
          제품의 효능을 선택해 주세요.
        </OrderListItemLabel>
        <OrderListItemContent gutter={{ top: 8, left: 28, right: 28 }}>
          <Typography.Text type="SMALL">
            *제품당 반드시 최소 1가지의 효능 선택이 필요하며, 2가지 이상
            선택하실 경우 임상 비용과 인증에 소요되는 시간이 늘어날 수 있습니다.
          </Typography.Text>
          <Typography.Text type="SMALL">
            *해당 효능은 중국 기준으로 각 효능별 정의의 확인을 원하실 경우{' '}
            <Link
              to={{
                pathname: `${path.service}/guide`,
                search: '?useGuideId=9',
              }}
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              여기
            </Link>
            를 클릭해 주세요.
          </Typography.Text>
          <Flex justify="center" style={{ marginTop: 24 }}>
            <Form.Item
              name={[name, 'efficacyChinaId']}
              rules={generateFormRules({ required: true })}
            >
              <Select
                showSearch
                style={{ width: 520 }}
                disabled={readOnly}
                options={efficacyOptions}
                filterOption={filterOptionForStringLabel}
                placeholder="확정 효능을 검색 또는 선택"
                onSelect={onEfficacyChange}
              />
            </Form.Item>
          </Flex>
        </OrderListItemContent>
      </OrderListItem>
      {guideText && (
        <OrderListItem>
          <OrderListItemLabel type="BODY_1" medium>
            선택하신 효능 <HighlightedLetter>{efficacyName}</HighlightedLetter>
            는(은) 아래와 같은 방법으로 증빙이 가능합니다.
          </OrderListItemLabel>
          <OrderListItemContent gutter={{ top: 24, left: 28, right: 28 }}>
            <GuideTextWrapper>
              {guideText.text}
              {guideText.subtext}
            </GuideTextWrapper>
          </OrderListItemContent>
        </OrderListItem>
      )}
    </OrderList>
  );
};

const generateDefaultEfficacyName = (num: number) => `효능 ${num}`;

export default ProductEfficacyUI;
