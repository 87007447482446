import { Modal, Steps } from 'antd';

interface Step {
  code: string;
  title?: string;
  content?: React.ReactNode;
  customContent?: React.ReactNode;
}

const ProgressModal = ({
  title,
  steps,
  currentStepCode,
  width = 1000,
  onClose,
}: {
  title: string;
  steps: Step[];
  currentStepCode: string;
  width?: number;
  onClose: () => void;
}) => {
  const currentStepIndex = steps.findIndex(
    (step) => step.code === currentStepCode,
  );

  return (
    <Modal
      title={title}
      footer={null}
      width={width}
      visible={currentStepCode !== null}
      onCancel={onClose}
    >
      <Steps>
        {steps.map(({ code, title }, index) => {
          const status =
            currentStepIndex === index
              ? 'process'
              : currentStepIndex > index
              ? 'finish'
              : 'wait';
          return <Steps.Step key={code} title={title} status={status} />;
        })}
      </Steps>
      <ol style={{ paddingLeft: 16, marginTop: 32, marginBottom: 0 }}>
        {steps.map(({ code, title, content, customContent }, index) => (
          <li
            key={code}
            style={{ ...(index !== steps.length && { marginBottom: 8 }) }}
          >
            {customContent || `${title} : ${content}`}
          </li>
        ))}
      </ol>
    </Modal>
  );
};

export default ProgressModal;
