import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Col, message, Row } from 'antd';
import { useMutation, useQuery } from 'react-query';

import { messages } from 'lib/consts';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import UpdateLog from 'components/product/UpdateLog';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import * as productApi from 'lib/api/product';
import client from 'lib/api/client';
import history from 'lib/history';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';

const queryKey = '/product-third-party-test';

const ProductThirdPartyTestReportContainer = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const [files, setFiles] = useState([]);
  const { currentProduct, readOnlyMode } = useSelector(
    ({ product, certificate }) => ({
      currentProduct: product.currentProduct,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );

  const { data: thirdPartyTestReports } = useQuery(
    [queryKey, productId, countryId],
    () =>
      productApi.getProductThirdPartyTestReports({
        productId,
        countryId,
      }),
    {
      select: (res) => res.data.result,
    },
  );

  const updateMode = thirdPartyTestReports && thirdPartyTestReports.length > 0;

  const {
    mutate: addProductThirdPartyTestReport,
    isLoading: addLoading,
  } = useMutation((productThirdPartyTestReport) =>
    client.post('/product-third-party-test', productThirdPartyTestReport),
  );

  const {
    mutate: deleteProductThirdPartyTestReport,
    isLoading: deleteLoading,
  } = useMutation((productThirdPartyTestId) =>
    client.delete(
      `/product-third-party-test?productThirdPartyTestId=${productThirdPartyTestId}`,
    ),
  );

  const fetchLoading = addLoading || deleteLoading;

  const onUpload = (file) => {
    if (files.find((f) => f.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }
    setFiles((draft) => draft.concat(file));
    return true;
  };
  const onDelete = (target) => {
    if (updateMode && files.length === 1) {
      message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
      return true;
    }
    setFiles(files.filter((file) => file !== target));
  };

  const onSubmit = () => {
    if (!files.length) {
      return message.warn(messages.REQUIRED_FILES);
    }
    if (!updateMode) {
      addProductThirdPartyTestReport(
        {
          productId: currentProduct.productId,
          countryId,
          documentFiles: files,
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Third Party Test Report',
              actionType: EGAActionType.REGISTER,
            });
            message.success('입력되었습니다.');
            history.goBack();
          },
        },
      );
    } else {
      const existProductIds = files
        .filter(({ productThirdPartyTestId }) => productThirdPartyTestId)
        .map(({ productThirdPartyTestId }) => productThirdPartyTestId);

      const productThirdPartyTestIdsBeDeleted = thirdPartyTestReports
        .filter(
          ({ productThirdPartyTestId }) =>
            !existProductIds.includes(productThirdPartyTestId),
        )
        .map(({ productThirdPartyTestId }) => productThirdPartyTestId);

      if (
        files.filter(({ productThirdPartyTestId }) => !productThirdPartyTestId)
          .length > 0
      ) {
        addProductThirdPartyTestReport(
          {
            productId,
            countryId,
            documentFiles: files.filter(
              ({ productThirdPartyTestId }) => !productThirdPartyTestId,
            ),
          },
          {
            onSuccess: () => {
              if (productThirdPartyTestIdsBeDeleted.length > 0) {
                for (const productThirdPartyTestId of productThirdPartyTestIdsBeDeleted) {
                  deleteProductThirdPartyTestReport(productThirdPartyTestId, {
                    onSuccess: () => {
                      sendEventToGA({
                        documentName: 'Third Party Test Report',
                        actionType: EGAActionType.MODIFY,
                      });
                      message.success('보완 완료되었습니다.');
                      history.goBack();
                    },
                  });
                }
              } else {
                sendEventToGA({
                  documentName: 'Third Party Test Report',
                  actionType: EGAActionType.MODIFY,
                });
                message.success('보완 완료되었습니다.');
                history.goBack();
              }
            },
          },
        );
      } else if (productThirdPartyTestIdsBeDeleted.length > 0) {
        for (const productThirdPartyTestId of productThirdPartyTestIdsBeDeleted) {
          deleteProductThirdPartyTestReport(productThirdPartyTestId, {
            onSuccess: () => {
              sendEventToGA({
                documentName: 'Third Party Test Report',
                actionType: EGAActionType.MODIFY,
              });
              message.success('보완 완료되었습니다.');
              history.goBack();
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (thirdPartyTestReports && thirdPartyTestReports.length > 0) {
      setFiles(
        thirdPartyTestReports.map(
          ({ productThirdPartyTestId, filename, uploadFileUrl }) => ({
            productThirdPartyTestId,
            name: filename,
            url: uploadFileUrl,
          }),
        ),
      );
    }
  }, [thirdPartyTestReports]);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode="third"
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode="third"
            />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        updateMode={updateMode}
        readOnly={readOnlyMode}
        files={files}
        viewerVisible
        status="MOD"
        fetchLoading={fetchLoading}
        onUpload={onUpload}
        onDelete={onDelete}
        onSubmit={onSubmit}
        documentCode="third"
        correctRequestWithNoUpdate={true}
      />
    </>
  );
};

export default ProductThirdPartyTestReportContainer;
