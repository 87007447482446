import { Button, Table, message } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';

import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { useMyAllRP, useMyRP } from 'service/brand/product/product';
import { MyRPItem } from 'types/product';
import { CompanyType } from 'types/company';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import React from 'react';

const RPList = () => {
  const [selectedRPIds, setSelectedRPIds] = useState<number[]>([]);
  const { openProductRPAddModal, openAlertModal } = useModal();
  const { myAllRP } = useMyAllRP();
  const { deleteMyRP, usingProductRPs } = useMyRP({});

  const columns: ColumnsType<MyRPItem> = useMemo(
    () => [
      {
        title: 'RP회사명',
        align: 'center',
        width: '15.3%',
        render: ({ companyName }) => companyName,
      },
      {
        title: 'RP연락처',
        align: 'center',
        width: '23%',
        render: ({ companyTel, companyEmail, companyHomepage }) => (
          <Flex
            dir="column"
            justify="center"
            style={{ fontSize: 14, textAlign: 'start' }}
          >
            {companyTel && (
              <>
                전화번호: {companyTel}
                <br />
              </>
            )}
            {companyEmail && (
              <>
                이메일 주소: {companyEmail}
                <br />
              </>
            )}
            {companyHomepage && <>홈페이지 주소: {companyHomepage}</>}
          </Flex>
        ),
      },
      {
        title: 'RP주소',
        align: 'center',
        width: '26.9%',
        render: ({ companyAddress }) => companyAddress,
      },
      {
        title: 'RP종류',
        align: 'center',
        width: '10%',
        render: ({ companyType }) =>
          companyType === CompanyType.BRAND
            ? 'Distributor'
            : companyType === CompanyType.MANUFACTURE
            ? 'Manufacturer'
            : 'Packer',
      },
      {
        title: '중소기업 여부',
        width: '10.7%',
        align: 'center',
        render: ({ companySize }) =>
          companySize === 'CORPORATE'
            ? '중소기업 아님'
            : !companySize
            ? '미지정'
            : '중소기업',
      },
      {
        title: '등록일/수정일',
        width: '10%',
        align: 'center',
        render: ({ updateDt }) => updateDt,
      },
    ],
    [myAllRP],
  );

  const handleDelete = () => {
    usingProductRPs(selectedRPIds, {
      onSuccess: (res) => {
        const usingProducts = res.data.result;
        if (usingProducts.length === 0) {
          openAlertModal({
            content: `삭제하시겠습니까?`,
            okText: '삭제',
            closeText: '취소',
            onOk: () => {
              deleteMyRP(selectedRPIds, {
                onSuccess: () => {
                  message.success('삭제되었습니다.');
                  setSelectedRPIds([]);
                },
              });
            },
          });
        } else {
          openAlertModal({
            width: 400,
            noCancelButton: true,
            content: (
              <>
                <Typography.Text
                  type="BODY_2"
                  style={{ textAlign: 'center', marginBottom: 16 }}
                >
                  아래 제품에 입력된 RP이므로
                  <br />
                  삭제하실 수 없습니다.
                </Typography.Text>
                <ProductListContainer>
                  <Flex dir="column" gap={8}>
                    {usingProducts.map(
                      ({ responsiblePersonId, companyName, products }) => (
                        <React.Fragment key={responsiblePersonId}>
                          <Typography.Text
                            style={{
                              color: palette.PRIMARY50,
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                            key={responsiblePersonId}
                          >
                            {companyName}
                          </Typography.Text>
                          <Flex dir="column" gap={4}>
                            {products.map(
                              ({
                                productNameEn,
                                productId,
                                netWeight,
                                netWeightUnit,
                              }) => (
                                <Typography.Text
                                  style={{
                                    color: palette.SLATE_GRAY70,
                                    fontSize: 12,
                                    fontWeight: 400,
                                  }}
                                  key={productId}
                                >
                                  {`${productNameEn} (${netWeight}${netWeightUnit})`}
                                </Typography.Text>
                              ),
                            )}
                          </Flex>
                        </React.Fragment>
                      ),
                    )}
                  </Flex>
                </ProductListContainer>
              </>
            ),
          });
        }
      },
    });
  };

  return (
    <Container>
      <Flex
        gap={8}
        gutter={{ bottom: 16 }}
        style={{ width: '100%' }}
        justify="end"
      >
        <Button
          onClick={() => openProductRPAddModal({})}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            background: 'white',
          }}
        >
          <Icon
            name="plus"
            size={18}
            color={'PRIMARY50'}
            style={{ marginRight: 4 }}
          />
          RP 추가
        </Button>
        <Button
          icon={
            <Icon
              name="delete"
              size={18}
              style={{ marginRight: 4 }}
              color={'PRIMARY50'}
            />
          }
          onClick={() => {
            if (selectedRPIds.length > 0) {
              handleDelete();
            }
            if (selectedRPIds.length === 0) {
              return message.warn('RP를 선택해주세요.');
            }
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            width: 106,
          }}
        >
          선택 삭제
        </Button>
      </Flex>
      <Table
        rowKey="responsiblePersonId"
        columns={columns}
        dataSource={myAllRP}
        rowSelection={{
          selectedRowKeys: selectedRPIds,
          onChange: (key) => {
            setSelectedRPIds(key as number[]);
          },
        }}
        onRow={({ responsiblePersonId }) => ({
          onClick: () => {
            openProductRPAddModal({ responsiblePersonId });
          },
          style: {
            cursor: 'pointer',
          },
        })}
        pagination={false}
      />
    </Container>
  );
};

const Container = styled.div``;

const ProductListContainer = styled.div`
  background-color: ${palette.SLATE_GRAY10};
  padding: 16px 40px;
  text-align: start;
`;

export default RPList;
