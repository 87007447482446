import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import path from 'lib/path';
import { screenMax, screenMin } from 'lib/styles';
import { useToggleDrawer } from 'service/navigator';

const StyledMenu = styled(Menu)`
  ${(props) =>
    props.mode !== 'inline' &&
    css`
      display: flex;
      margin: 0 auto;
      justify-content: center;

      &::before,
      &::after {
        display: none;
      }
      @media (min-width: ${screenMin.lg}) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .ant-menu-submenu-horizontal:not(.ant-menu-overflowed-submenu),
      .ant-menu-item-only-child:not(.ant-menu-overflowed-submenu) {
        line-height: 77px;
        margin: 0 20px;

        @media (max-width: ${screenMax.md}) {
          margin: 0 8px;
        }
      }

      .ant-menu-submenu-title,
      .ant-menu-item {
        font-size: 18px;
        letter-spacing: -1px;
      }
    `}
`;

const TopMenu = ({ inlineMode }: { inlineMode: boolean }) => {
  const { pathname } = useLocation();
  const toggleDrawer = useToggleDrawer();

  return (
    <StyledMenu
      mode={inlineMode ? 'inline' : 'horizontal'}
      selectedKeys={[pathname]}
      triggerSubMenuAction="click"
      onClick={() => {
        if (inlineMode) {
          toggleDrawer();
        }
      }}
    >
      <Menu.SubMenu title="원료·성분">
        <Menu.Item key={path.material.rawMaterial.search}>
          <Link to={path.material.rawMaterial.search}>원료 검색</Link>
        </Menu.Item>
        <Menu.Item key={path.material.ingredient.dictionary}>
          <Link to={path.material.ingredient.dictionary}>성분 사전</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title="원료 등록">
        <Menu.Item key={path.material.rawMaterial.basic}>
          <Link to={path.material.rawMaterial.basic}>신규 원료 등록</Link>
        </Menu.Item>
        <Menu.Item key={path.material.manage.ready}>
          <Link to={path.material.manage.ready}>등록 중 원료 관리</Link>
        </Menu.Item>
        <Menu.Item key={path.material.manage.done}>
          <Link to={path.material.manage.done}>등록 완료 원료 관리</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title="ICID 등재">
        <Menu.Item key={path.material.icid.request}>
          <Link to={path.material.icid.request}>신규 등재 의뢰</Link>
        </Menu.Item>
        <Menu.Item key={path.material.icid.requestList}>
          <Link to={path.material.icid.requestList}>등재 의뢰 리스트</Link>
        </Menu.Item>
        <Menu.Item key={path.material.icid.ongoing}>
          <Link to={path.material.icid.ongoing}>등재 중 원료 관리</Link>
        </Menu.Item>
        <Menu.Item key={path.material.icid.registered}>
          <Link to={path.material.icid.registered}>등재 완료 원료 관리</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title="중국 NMPA 신고번호 취득">
        <Menu.Item key={path.material.nmpa.estimate.request}>
          <Link to={path.material.nmpa.estimate.request}>신규 취득 의뢰</Link>
        </Menu.Item>
        <Menu.Item key={path.material.nmpa.requestList}>
          <Link to={path.material.nmpa.requestList}>취득 의뢰 리스트</Link>
        </Menu.Item>
        <Menu.Item key={path.material.nmpa.material.list}>
          <Link to={path.material.nmpa.material.list}>원료 자료 입력</Link>
        </Menu.Item>
        <Menu.Item key={path.material.nmpa.ongoing}>
          <Link to={path.material.nmpa.ongoing}>취득 중 원료 관리</Link>
        </Menu.Item>
        <Menu.Item key={path.material.nmpa.registered}>
          <Link to={path.material.nmpa.registered}>취득 완료 원료 관리</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={path.service}>
        <Link to={path.service}>고객센터</Link>
      </Menu.Item>
    </StyledMenu>
  );
};

export default TopMenu;
