import { memo } from 'react';
import { Form, Input } from 'antd';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import styled from 'styled-components';
import AlphabetLabel from './AlphabetLabel';

const MSDSEcologicalBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSEcological = () => {
  return (
    <MSDSEcologicalBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">Ecotoxicity effects</AlphabetLabel>}
        name={['ecological', 'ecotoxicityEffects']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="생태독성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">
            Elimination information (persistence and degradability)
          </AlphabetLabel>
        }
        name={['ecological', 'eliminationInformation']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="잔류성 및 분해성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="C">Bioaccumulative potential</AlphabetLabel>
        }
        name={['ecological', 'bioaccumulativePotential']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="생물 농축성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="D">Mobility in soil</AlphabetLabel>}
        name={['ecological', 'mobilityInSoil']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="토양 이동성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="E">Other adverse effects</AlphabetLabel>
        }
        name={['ecological', 'otherAdverseEffects']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="기타 유해 영향"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSEcologicalBlock>
  );
};

export default memo(MSDSEcological);
