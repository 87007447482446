import { Button, Form, Input, Select } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';

import FooterBox from 'components/FooterBox';
import { validateAuthCode } from 'lib/api/product';
import { alphabetOrNumberRule, requireRule } from 'lib/validate';
import { useAddProductWithCode } from 'service/brand/product/productImport';
import { useManufacturerCompanies } from 'service/manufacturer/company';

const ProductImportBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

interface FormValue {
  manufacturerId: number;
  authCode: string;
}

const ProductImport = () => {
  const codeInputRef = useRef<Input | null>(null);
  const { data: manufacturers = [] } = useManufacturerCompanies();
  const options = useMemo(
    () =>
      manufacturers.map(
        ({
          manufacturerId,
          companyNameKo,
        }: {
          manufacturerId: number;
          companyNameKo: string;
        }) => ({
          label: companyNameKo,
          value: manufacturerId,
        }),
      ),
    [manufacturers],
  );
  const [form] = useForm<FormValue>();
  const [searchValue, setSearchValue] = useState('');
  const handleSelectSearch = (value: string) => {
    setSearchValue(value.replace(/\s/gi, ''));
  };
  const {
    AddProductWithCode,
    AddProductWithCodeLoading,
  } = useAddProductWithCode();
  const handleSubmit = (value: FormValue) => {
    AddProductWithCode(value);
  };

  return (
    <ProductImportBlock>
      <Form
        layout="vertical"
        colon={false}
        validateTrigger="onChange"
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item label="제조사명" rules={[requireRule]} name="manufacturerId">
          <Select
            searchValue={searchValue}
            onSearch={handleSelectSearch}
            placeholder="제조사 검색"
            showSearch
            filterOption={(keyword, option) =>
              (option?.label as string).includes(keyword)
            }
            onChange={() => {
              form.validateFields();
              codeInputRef.current?.focus();
            }}
            options={options}
          />
        </Form.Item>
        <Form.Item
          name="authCode"
          label="제품 코드"
          required
          hasFeedback
          rules={[
            requireRule,
            alphabetOrNumberRule,
            {
              pattern: /^.{16}$/,
              message: '16자리 모두 입력 필수',
            },
            ({ getFieldValue }) => ({
              validator: async (_, authCode) => {
                const manufacturerId = getFieldValue('manufacturerId');
                if (!/^.{16}$/.test(authCode)) {
                  return;
                }
                if (!manufacturerId) {
                  throw Error('제조사를 선택해 주세요.');
                }
                const {
                  data: { errors },
                } = await validateAuthCode({
                  manufacturerId,
                  authCode,
                });
                if (errors[0].code !== '0') {
                  throw Error(errors[0].message);
                }
              },
            }),
          ]}
          normalize={(value) => value.toUpperCase()}
        >
          <Input
            ref={codeInputRef}
            maxLength={16}
            placeholder="16자리, 알파벳 + 숫자"
          />
        </Form.Item>
        <FooterBox>
          <Button
            loading={AddProductWithCodeLoading}
            type="primary"
            htmlType="submit"
          >
            등록
          </Button>
        </FooterBox>
      </Form>
    </ProductImportBlock>
  );
};

export default React.memo(ProductImport);
