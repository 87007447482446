import { useHistory } from 'react-router-dom';

import NMPARegistered from 'components/material/nmpa/NMPARegistered';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import { NMPARegisteredPageState } from 'types/material/nmpa/nmpa';

const NMPARegisteredPage = () => {
  const history = useHistory<NMPARegisteredPageState>();
  const pageState = history.location.state || {
    page: 1,
    searchValue: '',
  };

  const handleChangePageState = (state: NMPARegisteredPageState) => {
    history.replace(path.material.nmpa.registered, state);
  };

  return (
    <PageTemplate
      title="취득 완료 원료 관리"
      description="취득이 완료된 원료를 확인해 보세요."
      back={false}
    >
      <NMPARegistered
        pageState={pageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default NMPARegisteredPage;
