import { Upload } from 'antd';
import styled from 'styled-components';

import { s3AssetDomain } from 'lib/consts';
import palette from 'lib/styles/palette';

const StyledUploadDragger = styled(Upload.Dragger)<{
  height?: number;
  width?: number;
}>`
  width: ${(props) => (props.width ? props.width : 350)}px !important;
  height: ${(props) => (props.height ? props.height : 175)}px !important;

  border: none !important;
  background: ${palette.SLATE_GRAY10} !important;

  padding: 0px !important;

  .ant-upload {
    padding: 0px !important;
  }
`;

const PictureNoticeWrapper = styled.div<{
  height?: number;
  width?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : 350)}px !important;
  height: ${(props) => (props.height ? props.height : 175)}px !important;
`;

const AlbumIcon = styled.img`
  width: 56px;
  height: 56px;

  margin-bottom: 8px;
`;

const StyledText = styled.div`
  height: 15px;
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.45px;
  color: #a9a6a6;
`;

interface UploadDraggerProps {
  width?: number;
  height?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
  initialImageUrl?: string;
  imageDescription: string;
  className?: string;
  onDragger?: (file: File) => void;
}

const UploadDragger = ({
  width,
  height,
  disabled,
  style,
  initialImageUrl,
  imageDescription,
  className,
  onDragger,
}: UploadDraggerProps) => {
  return (
    <StyledUploadDragger
      style={style}
      disabled={disabled}
      width={width}
      height={height}
      name="uploadFile"
      accept=".png, .jpeg, .jpg"
      onChange={(info) => {
        if (info.file.originFileObj) {
          if (onDragger) onDragger(info.file.originFileObj as File);
        }
      }}
      beforeUpload={() => {
        return false;
      }}
      itemRender={() => null}
    >
      <PictureNoticeWrapper width={width} height={height} className={className}>
        {!initialImageUrl ? (
          <>
            <AlbumIcon src={`${s3AssetDomain}/album_icon.png`} alt="album" />
            <StyledText>
              클릭 혹은 이미지 끌어서 넣기 <br />
              크기 {`${imageDescription}`} 이하 권장
            </StyledText>
          </>
        ) : (
          <img
            src={initialImageUrl}
            alt="file"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        )}
      </PictureNoticeWrapper>
    </StyledUploadDragger>
  );
};

export default UploadDragger;
