import { useLocation } from 'react-router-dom';

import history from 'lib/history';
import path from 'lib/path';
import ProductList from 'components/product/ProductList';
import PageTemplate from 'templates/PageTemplate';
import { ProductSearchType, ProductListPageState } from 'types/product';

const ProductRegisteredPage = () => {
  const {
    state: pageState = {
      page: 1,
      searchType: ProductSearchType.ALL,
      searchKeyword: '',
    },
  } = useLocation<ProductListPageState>();

  const handleChangePageState = (state: ProductListPageState) => {
    history.replace(path.product.registered, state);
  };

  return (
    <PageTemplate
      title="등록 완료 제품 관리"
      back={false}
      description="아래 등록 완료된 제품을 관리해보세요."
    >
      <ProductList
        pageState={pageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default ProductRegisteredPage;
