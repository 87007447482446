import produce from 'immer';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { createAction, handleActions } from 'redux-actions';
import { message } from 'antd';

import * as productApi from 'lib/api/product';
import history from 'lib/history';
import { createRequestSaga, createRequestActionTypes } from 'lib/saga';
import * as certificateApi from 'lib/api/certificate';
import { finishLoading, startLoading } from 'modules/loading';
import { setCurrentProduct } from './product';

const SET_READ_ONLY_MODE = 'certificate/SET_READ_ONLY_MODE';
const INITIALIZE_CERTIFICATES = 'certificate/INITIALIZE_CERTIFICATES';
const INITIALIZE_CERTIFICATE = 'certificate/INITIALIZE_CERTIFICATE';
const [GET_CERTIFICATE, GET_CERTIFICATE_SUCCESS] = createRequestActionTypes(
  'certificate/GET_CERTIFICATE',
);

const SET_CERTIFICATE = 'certificate/SET_CERTIFICATE';
const [GET_DOCUMENTS, GET_DOCUMENTS_SUCCESS] = createRequestActionTypes(
  'certificate/GET_DOCUMENTS',
);
const [
  GET_COMPLETED_DOCUMENTS,
  GET_COMPLETED_DOCUMENTS_SUCCESS,
] = createRequestActionTypes('certificate/GET_COMPLETED_DOCUMENTS');
const TOGGLE_COMPANY_INFO_MODAL_VISIBLE =
  'certificate/TOGGLE_COMPANY_INFO_MODAL_VISIBLE';
const TOGGLE_PRODUCT_NAME_MODAL_VISIBLE =
  'certificate/TOGGLE_PRODUCT_NAME_MODAL_VISIBLE';
const TOGGLE_JAPANESE_PRODUCT_NAME_MODAL_VISIBLE =
  'certificate/TOGGLE_JAPANESE_PRODUCT_NAME_MODAL_VISIBLE';
const TOGGLE_SERIES_PRODUCT_CHECK_MODAL_VISIBLE =
  'certificate/TOGGLE_SERIES_PRODUCT_CHECK_MODAL_VISIBLE';
const TOGGLE_SHELF_LIFE_MONTH_MODAL_VISIBLE =
  'certificate/TOGGLE_SHELF_LIFE_MONTH_MODAL_VISIBLE';
const INITIALIZE_PREVIEWED_URLS = 'certificate/INITIALIZE_PREVIEWED_URLS';
const [FIX_DOCUMENT, FIX_DOCUMENT_SUCCESS] = createRequestActionTypes(
  'certificate/FIX_DOCUMENT',
);
const FIX_FORMULA = 'certificate/FIX_FORMULA';
export const setReadOnlyMode = createAction(SET_READ_ONLY_MODE);
export const initializeCertificates = createAction(INITIALIZE_CERTIFICATES);
export const initializeCertificate = createAction(INITIALIZE_CERTIFICATE);
export const getCertificate = createAction(GET_CERTIFICATE);
const getCertificateSaga = createRequestSaga(
  GET_CERTIFICATE,
  certificateApi.getCertificate,
  {
    onSuccess: function* () {
      const { productDetail, product } = yield select(
        ({ certificate, product }) => ({
          productDetail: certificate.certificate.estimateTarget.productDetail,
          product: product.currentProduct,
        }),
      );
      yield put(setCurrentProduct({ ...product, productDetail }));
    },
  },
);
export const setCertificate = createAction(SET_CERTIFICATE);

export const getDocuments = createAction(GET_DOCUMENTS);
const getDocumentsSaga = createRequestSaga(
  GET_DOCUMENTS,
  certificateApi.getDocuments,
);
export const getCompletedDocuments = createAction(GET_COMPLETED_DOCUMENTS);
const getCompletedDocumentsSaga = function* ({ payload }) {
  const { certTargetId, productId, countryId } = payload;
  yield put(startLoading(GET_COMPLETED_DOCUMENTS));
  try {
    const {
      data: { result },
    } = yield call(certificateApi.getCompletedDocuments, certTargetId);
    const artwork = result.find(({ documentCode }) => documentCode === 'ART01');
    if (artwork) {
      const {
        data: { result },
      } = yield call(productApi.getProductArtworks, {
        productId,
        countryId,
      });
      artwork.documentUrls = result?.productArtworks
        ?.filter(({ documentUrl }) => documentUrl !== null)
        .map(({ documentUrl }) => documentUrl);
      artwork.uploadFileUrls = result?.productArtwors?.map(
        ({ uploadFileUrl }) => uploadFileUrl,
      );
    }

    const thirdPartyTest = result.find(
      ({ documentCode }) => documentCode === 'THIRD01',
    );

    if (thirdPartyTest) {
      const {
        data: { result },
      } = yield call(productApi.getProductThirdPartyTestReports, {
        productId,
        countryId,
      });
      thirdPartyTest.documentUrls = result
        .filter(({ documentUrl }) => documentUrl !== null)
        .map(({ documentUrl }) => documentUrl);
      thirdPartyTest.uploadFileUrls = result.map(
        ({ uploadFileUrl }) => uploadFileUrl,
      );
    }

    yield put({
      type: GET_COMPLETED_DOCUMENTS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    console.error('[SAGA ERROR]', error);
  }
  yield put(finishLoading(GET_COMPLETED_DOCUMENTS));
};

export const toggleProductNameModalVisible = createAction(
  TOGGLE_PRODUCT_NAME_MODAL_VISIBLE,
);

export const toggleJapaneseProductNameModalVisible = createAction(
  TOGGLE_JAPANESE_PRODUCT_NAME_MODAL_VISIBLE,
);

export const toggleSeriesProductInfoModalVisible = createAction(
  TOGGLE_SERIES_PRODUCT_CHECK_MODAL_VISIBLE,
);

export const toggleCompanyInfoModalVisible = createAction(
  TOGGLE_COMPANY_INFO_MODAL_VISIBLE,
);

export const toggleShelfLifeMonthModalVisible = createAction(
  TOGGLE_SHELF_LIFE_MONTH_MODAL_VISIBLE,
);

export const fixDocument = createAction(FIX_DOCUMENT);
const fixDocumentSaga = function* ({ payload }) {
  yield put(startLoading(FIX_DOCUMENT));
  try {
    const response = yield call(certificateApi.fixDocument, payload);
    yield put({
      type: FIX_DOCUMENT_SUCCESS,
      payload: payload,
      meta: response,
    });
  } catch (error) {}
  yield put(finishLoading(FIX_DOCUMENT));
};

export const fixFormula = createAction(FIX_FORMULA);
const fixFormulaSaga = function* ({ payload: productDocStatusId }) {
  yield put(startLoading(FIX_FORMULA));
  try {
    yield call(certificateApi.fixDocument, productDocStatusId);
    message.success('보완 완료되었습니다.');
    history.goBack();
  } catch (error) {}
  yield put(finishLoading(FIX_FORMULA));
};

export const initializePreviewedUrls = createAction(INITIALIZE_PREVIEWED_URLS);

export function* certificateSaga() {
  yield takeLatest(GET_CERTIFICATE, getCertificateSaga);
  yield takeLatest(GET_DOCUMENTS, getDocumentsSaga);
  yield takeLatest(GET_COMPLETED_DOCUMENTS, getCompletedDocumentsSaga);
  yield takeLatest(FIX_DOCUMENT, fixDocumentSaga);
  yield takeLatest(FIX_FORMULA, fixFormulaSaga);
}

const initialState = {
  readOnlyMode: false,
  certificates: { content: [] },
  certificate: null,
  productDocStatus: null,
  documents: [],
  completedDocuments: [],
  productNameModalVisible: false,
  japaneseProductNameModalVisible: false,
  shelfLifeMonthModalVisible: false,
};

export default handleActions(
  {
    [SET_READ_ONLY_MODE]: (state, { payload: readOnlyMode }) =>
      produce(state, (draft) => {
        draft.readOnlyMode = readOnlyMode;
      }),
    [INITIALIZE_CERTIFICATES]: (state) =>
      produce(state, (draft) => {
        draft.certificates = [];
      }),
    [INITIALIZE_CERTIFICATE]: (state) =>
      produce(state, (draft) => {
        draft.certificate = null;
        draft.documents = [];
      }),
    [GET_CERTIFICATE_SUCCESS]: (state, { payload: certificate }) =>
      produce(state, (draft) => {
        draft.certificate = certificate;
      }),
    [SET_CERTIFICATE]: (state, { payload: certificate }) =>
      produce(state, (draft) => {
        draft.certificate = certificate;
      }),
    [GET_DOCUMENTS_SUCCESS]: (state, { payload: documents }) =>
      produce(state, (draft) => {
        draft.documents = documents.map((document) => ({
          ...document,
          documentCode: document.documentCode.replace(/\d+/, '').toLowerCase(),
        }));
      }),
    [GET_COMPLETED_DOCUMENTS_SUCCESS]: (
      state,
      { payload: completedDocuments },
    ) =>
      produce(state, (draft) => {
        draft.completedDocuments = completedDocuments.map(
          (document, index) => ({
            ...document,
            no: index + 1,
            documentCode: document.documentCode,
          }),
        );
      }),
    [TOGGLE_PRODUCT_NAME_MODAL_VISIBLE]: (state) =>
      produce(state, (draft) => {
        draft.productNameModalVisible = !state.productNameModalVisible;
      }),
    [TOGGLE_JAPANESE_PRODUCT_NAME_MODAL_VISIBLE]: (state) =>
      produce(state, (draft) => {
        draft.japaneseProductNameModalVisible = !state.japaneseProductNameModalVisible;
      }),
    [TOGGLE_SERIES_PRODUCT_CHECK_MODAL_VISIBLE]: (state) =>
      produce(state, (draft) => {
        draft.seriesProductInfoModalVisible = !state.seriesProductInfoModalVisible;
      }),
    [TOGGLE_COMPANY_INFO_MODAL_VISIBLE]: (state) =>
      produce(state, (draft) => {
        draft.companyInfoModalVisible = !state.companyInfoModalVisible;
      }),
    [TOGGLE_SHELF_LIFE_MONTH_MODAL_VISIBLE]: (state) =>
      produce(state, (draft) => {
        draft.shelfLifeMonthModalVisible = !state.shelfLifeMonthModalVisible;
      }),
    [FIX_DOCUMENT_SUCCESS]: (state, { payload: productDocStatusId }) =>
      produce(state, (draft) => {
        const document = draft.documents.find(
          (document) => document.productDocStatusId === productDocStatusId,
        );
        document.status = 'ONG';
        document.statusString = '검토중';
      }),
  },
  initialState,
);
