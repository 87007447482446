import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { pdfjs } from 'react-pdf';
import { ConfigProvider } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import moment from 'moment';
import 'moment/locale/ko';

import rootReducer, { rootSaga } from 'modules';
import App from 'App';
import 'index.less';
import history from 'lib/history';
import GlobalStyle from 'lib/styles/GlobalStyle';
import ScrollToTop from 'components/ScrollToTop';
import { ModalContextProvider } from 'hook/useModal';

moment.locale('ko');

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});
const sagaMiddleware = createSagaMiddleware({
  context: {
    history,
    queryClient,
  },
});
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <ModalContextProvider>
          <ConfigProvider locale={koKR}>
            <GlobalStyle />
            <ScrollToTop />
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </ConfigProvider>
        </ModalContextProvider>
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);
