import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useMemo } from 'react';

import palette from 'lib/styles/palette';
import { skipPage } from 'modules/product';
import { useManuMode, useRetailerMode } from 'hook/company';
import PageTemplate, { PageTemplateProps } from 'templates/PageTemplate';
import { Typography } from 'components/system';
import * as S from 'templates/PageTemplateStyle';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

interface Props extends PageTemplateProps {
  subtitle: React.ReactNode;
  hasSkip?: boolean;
  maxWidth?: number;
  back?: boolean;
  noNeedEtc?: boolean;
  fixedTitle?: string;
}

const ProductPageTemplate = ({
  subtitle,
  hasSkip = true,
  maxWidth = 920,
  back = true,
  noNeedEtc = false,
  fixedTitle = '',
  ...rest
}: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const countryId = searchParams.get('countryId');
  const history = useHistory();
  const dispatch = useDispatch();
  const manuMode = useManuMode();
  const retailerMode = useRetailerMode();
  const params = useParams<{ productId?: string }>();
  const productId = Number(params.productId) || undefined;
  const { currentProduct, skipMode } = useSelector(
    ({ product }: any) => ({
      skipMode: product.skipMode,
      currentProduct: product.currentProduct,
    }),
    shallowEqual,
  );
  const status = currentProduct?.status || 'PRD-RDY';

  const targetCountryProductDetail = useMemo(() => {
    if (!countryId || Number.isNaN(countryId) || !currentProduct.productDetails)
      return currentProduct?.productDetail;

    const nCountryId = Number(countryId);

    return currentProduct.productDetails.find(
      (productDetail: any) => productDetail.countryId === nCountryId,
    );
  }, [currentProduct, countryId]);

  const moveToNextPage = () => {
    dispatch(skipPage());
  };

  const checkHasProduct = () => {
    if (productId === undefined) {
      return false;
    }

    if (retailerMode) {
      return !!targetCountryProductDetail;
    }

    if (!manuMode) {
      return !!currentProduct?.productDetail;
    }

    return !!currentProduct;
  };

  const generateSubtitle = () => {
    if (manuMode) {
      return '자체 품목명 (용량)';
    } else if (retailerMode) {
      return '국문 제품명 (용량)';
    }

    return '제품명 (용량)';
  };

  const generateProductName = () => {
    if (retailerMode) {
      return `${targetCountryProductDetail?.productNameKo || '-'} (${
        currentProduct?.netWeight
      } ${currentProduct?.netWeightUnit})`;
    }
    if (!manuMode) {
      return `${targetCountryProductDetail?.productNameEn || '-'} (${
        currentProduct?.netWeight
      } ${currentProduct?.netWeightUnit})`;
    }

    return `${currentProduct?.manufacturerItemNameKo || '-'} (${
      currentProduct?.netWeight
    } ${currentProduct?.netWeightUnit})`;
  };

  const title =
    productId === undefined || status === 'PRD-RDY' ? '제품 등록' : '제품 관리';

  return (
    <S.PageTemplateBlock>
      <PageTemplate
        {...rest}
        title={fixedTitle === '' ? title : fixedTitle}
        subtitle={
          <>
            <S.NameContainer style={{ margin: 0 }}>
              <Typography.Text style={{ fontSize: 20 }} gutter={{ right: 16 }}>
                {subtitle}
              </Typography.Text>
              {checkHasProduct() && (
                <Flex align="flex-start" style={{ maxWidth: '100%' }}>
                  <Typography.Text type="BODY_2" inline className="label">
                    {!noNeedEtc && generateSubtitle()}
                  </Typography.Text>
                  <Typography.Text
                    type="TITLE_2"
                    medium
                    className="product-name"
                    inline
                    style={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {!noNeedEtc && generateProductName()}
                  </Typography.Text>
                </Flex>
              )}
            </S.NameContainer>
          </>
        }
        back={back}
        onBack={() =>
          skipMode && productId && currentProduct.productCountries?.length > 0
            ? history.replace(
                manuMode
                  ? `/manufacturer/product/${productId}`
                  : `/product/${productId}`,
              )
            : history.goBack()
        }
        headerStyle={hasSkip && skipMode ? { maxWidth: 800 } : { margin: 0 }}
      />

      {hasSkip && skipMode && (
        <Button
          style={{
            position: 'absolute',
            top: 20,
            right: `calc((100% - ${maxWidth}px) / 2)`,
            zIndex: 1,
            border: `1px solid ${palette.GRAY40}`,
            color: `${palette.GRAY70}`,
            height: 32,
            display: 'flex',
            alignItems: 'center',
          }}
          type="ghost"
          onClick={moveToNextPage}
        >
          <Typography.Text type="BODY_2">나중에 입력</Typography.Text>
          <Icon
            name="back"
            color="GRAY70"
            size={12}
            style={{
              transform: 'rotate(180deg)',
              marginLeft: 4,
            }}
          />
        </Button>
      )}
    </S.PageTemplateBlock>
  );
};

export default ProductPageTemplate;
