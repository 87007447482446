import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PageTemplate from 'templates/PageTemplate';
import ICIDEstimate from 'components/material/icid/ICIDEstimate';
import * as estimateActions from 'modules/estimate';
import { RootState } from 'modules';
import ICIDContract from 'components/material/icid/ICIDContract';

const ICIDContractPage = () => {
  const params = useParams<{ icidApplicationId: string }>();
  const icidApplicationId = Number(params.icidApplicationId);
  const dispatch = useDispatch();
  const contractStep = useSelector(
    ({ estimate }: RootState) => estimate.contractStep,
  );

  useEffect(() => {
    dispatch(estimateActions.initializeEstimate());
    dispatch(estimateActions.setContractStep('viewer'));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [contractStep]);

  return (
    <PageTemplate
      back={false}
      title="계약 체결"
      description={
        contractStep === 'viewer'
          ? '아래 견적서를 확인 후 계약을 진행해 보세요.'
          : '아래 내용을 확인 후 계약을 체결해 주세요.'
      }
    >
      {contractStep === 'viewer' && (
        <ICIDEstimate icidApplicationId={icidApplicationId} />
      )}
      {contractStep === 'contract' && (
        <ICIDContract icidApplicationId={icidApplicationId} />
      )}
    </PageTemplate>
  );
};
export default ICIDContractPage;
