export enum CompanyStatus {
  RDY = 'RDY', // READY
  CFM = 'CFM', // CONFIRM
  REJ = 'REJ', // REJECT
  WIH = 'WIH', // WITHDRAW
  STP = 'STP', // STP
}

export interface Company {
  companyNameKo: string;
  companyNameEn?: string;
  representer?: string;
  address?: string;
}

export interface CompanyBase {
  companyNameKo: string;
  companyNameEn: string;
  addressKo: string;
  addressEn: string;
  ceoNameKo: string;
  ceoNameEn: string;
  email?: string;
  tel: string;
  fax: string;
  homepageUrl?: string;
  bizNumber: string;
  companyType: CompanyType;
}

export interface CompanyGet extends CompanyBase {
  companyId: number;
  bizLicenseUrl?: string;
  nameplateUrl?: string;
  signatureUrl?: string;
  useSealUrl?: string;
  statusString: string;
  registerDt: Date;
  updateDt: Date;
  rejectReason?: string;
  status: CompanyStatus;
}

export interface CompanyRegister extends CompanyBase {
  companyNameKo: string;
  companyNameEn: string;
  bizNumber: string;
  addressKo: string;
  addressEn: string;
  ceoNameKo: string;
  ceoNameEn: string;
  tel: string;
  fax: string;
  isServiceTermsAgreed: boolean;
  isPrivacyTermsAgreed: boolean;
  isUseOfLogoAgreed: boolean;
  homepageUrl?: string;
  companyType: CompanyType;
  bizLicenseFile: File;
  bizLicenseEnFile?: File;
}
export interface CompanyUpdate extends CompanyBase {
  companyId: number;
  addressEn: string;
  addressKo: string;
  bizNumber: string;
  homepageUrl?: string;
  isPrivacyTermsAgreed: boolean;
  isServiceTermsAgreed: boolean;
}

export interface MaterialCompanyBase extends CompanyBase {
  companyId: number;
  mainItem: string;
  introduction: string;
}

export enum BuyerStatus {
  NOR = 'NOR',
  DEL = 'DEL',
}

export enum CompanySearchType {
  COMPANY_NAME_KO = 'companyNameKo',
  COMPANY_NAME_EN = 'companyNameEn',
  BIZ_NUMBER = 'bizNumber',
  CEO_NAME = 'ceoNameKo',
}

export enum CompanyType {
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  MATERIAL = 'MATERIAL',
  PACKER = 'PACKER',
}
export interface MaterialCompanyFactory {
  companyId: number;
  addressKo: string;
  addressEn: string;
  seq: number;
}

export interface ManufactureCompanyFactory {
  companyId: number;
  companyFactoryId: number;
  manufacturerId: number;
  cgmpUploadFile: File;
  iso22716UploadFile: File;
  iso22716ExpireDate: string;
  addressKo: string;
  addressEn: string;
  seq: number;
}

export interface ManufactureCompanyFactoryUpdate
  extends ManufactureCompanyFactory {
  companyFactoryId: number;
  cgmpUploadFileUrl: string;
  iso22716UploadFileUrl: string;
}
