import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { deviceSize } from 'lib/styles';
import COLOR from './colors';
import { Body1, H1 } from './introStyles';
import { ReactComponent as CheckRed } from 'asset/svg/intro/check_red.svg';

import useIntersectionObserver from 'hook/useIntersectionObserver';

const DescItem = styled.div<{
  isIntersecting: boolean;
  isMobile: boolean;
  isAnimated: boolean;
}>`
  padding: 40px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: ${COLOR.BG3};
  margin-bottom: 34px;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  transform: translateY(100%);
  transition: all 1s;

  @media ${deviceSize.sm} {
    padding: 24px 20px;
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`;

const Container = styled.div<{
  isIntersecting: boolean;
  isMobile: boolean;
}>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 180px 24px;

  @media ${deviceSize.sm} {
    height: auto;
    padding: 90px 24px;
  }
`;

const ItemContainer = styled.div`
  margin-top: 80px;

  @media ${deviceSize.sm} {
    margin-top: 50px;
  }
`;

const CheckBox = styled.div<{
  color?: string;
  isIntersecting: boolean;
  isMobile: boolean;
  isAnimated: boolean;
  borderColor: string;
}>`
  min-width: 30px;
  min-height: 30px;
  height: 30px;
  width: 30px;
  border: 2px solid ${({ borderColor }) => borderColor && borderColor};
  border-radius: 5px;
  margin-right: 32px;
  transform: translateY(1px);
  background: white;

  @media ${deviceSize.sm} {
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    width: 24px;
    margin-right: 16px;
    margin-top: 0;
    ${({ borderColor }) =>
      borderColor === '#CDCDCD' &&
      css`
        margin-top: 4px;
      `}
  }

  > svg {
    width: 120%;
    height: 120%;
    position: relative;
    bottom: 18%;
    left: 14%;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    fill: white;

    ${({ isIntersecting, isAnimated }) =>
      (isIntersecting || isAnimated) &&
      css`
        animation-name: check;
        animation-duration: 7s;
        animation-direction: alternate;
        animation-iteration-count: 1;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
      `}

    @keyframes check {
      0% {
        stroke-dashoffset: 500;
      }

      100% {
        stroke-dashoffset: 0;
      }
    }
  }
`;

const Desc = styled(Body1)`
  margin: 0;

  @media ${deviceSize.sm} {
    width: 90%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
`;
const Span = styled.span<{
  isIntersecting: boolean;
  isMobile: boolean;
  isAnimated: boolean;
}>`
  font-size: 38px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 24px;
    `}

  > svg {
    position: absolute;
    left: 0;
    bottom: 0;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;

    @keyframes line {
      0% {
        stroke-dashoffset: 500;
      }

      100% {
        stroke-dashoffset: 0;
      }
    }
    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        animation-name: line;
        animation-duration: 7s;
        animation-direction: alternate;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0.6s;
      `}
    ${({ isAnimated }) =>
      isAnimated &&
      css`
        animation-name: line;
        animation-duration: 7s;
        animation-direction: alternate;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0.6s;
      `}
  }
`;
const Desc2 = styled.h4`
  font-size: 32px;
  margin-bottom: 0;

  @media ${deviceSize.sm} {
    width: 90%;
    font-size: 22px;
    font-weight: 500;
  }
`;

const TextContainer = styled.div<{
  isIntersecting: boolean;
  isMobile: boolean;
}>`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  width: 70%;
  align-items: center;

  @media ${deviceSize.sm} {
    margin-top: 40px;
    width: 90%;
    align-items: flex-start;
  }
`;

const Item = ({ desc }: any) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  useEffect(() => {
    if (isIntersecting) {
      setIsAnimated(true);
    }
  }, [isIntersecting]);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.7,
  });
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });
  return (
    <DescItem
      ref={target}
      key={desc}
      isIntersecting={isIntersecting}
      isMobile={isMobile}
      isAnimated={isAnimated}
    >
      <CheckBox
        borderColor={COLOR.C_MAIN}
        isIntersecting={isIntersecting}
        isMobile={isMobile}
        isAnimated={isAnimated}
      />
      <Desc>{desc}</Desc>
    </DescItem>
  );
};

const CheckContainer = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.7,
  });
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });
  useEffect(() => {
    if (isIntersecting) {
      setIsAnimated(true);
    }
  }, [isIntersecting]);

  return (
    <TextContainer
      ref={target}
      isIntersecting={isIntersecting}
      isMobile={isMobile}
    >
      <CheckBox
        borderColor="#CDCDCD"
        ref={target}
        isIntersecting={isIntersecting}
        isMobile={isMobile}
        isAnimated={isAnimated}
      >
        <CheckRed className="check-box" />
      </CheckBox>
      <Desc2>
        하나라도 해당이 되신다면,{' '}
        <span>
          <Span
            isIntersecting={isIntersecting}
            isMobile={isMobile}
            isAnimated={isAnimated}
            style={{
              color: COLOR.C_MAIN,
              position: 'relative',
            }}
          >
            certicos
            <svg
              width={isMobile ? '88' : '230'}
              viewBox="0 0 30 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0"
                y1="10"
                x2="100"
                y2="10"
                stroke={COLOR.C_MAIN}
                opacity={0.1}
                strokeWidth={4}
              />
            </svg>
          </Span>
          가 정답
        </span>
        입니다!
      </Desc2>
    </TextContainer>
  );
};

const SectionD = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.1,
  });

  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  const textArr: string[] = [
    `새로운 규제로 인해 금지 성분이 포함된 제품 또는 처방만 모아볼 수 없어 
      답답하셨나요?`,

    `MSDS, COA 등 서류 요청을 주 1회 이상 받거나 요청하고 계신가요?`,

    `제조사, 원료사, 브랜드사, 인증 대행사 등으로부터 서류가 오지 않아 
    재요청 메일을 보낸 적이 있으신가요?`,

    `중국 NMPA 3개월, 유럽 CPNP는 1개월 이상 걸리셨나요?`,

    `수출하려는 제품의 전성분이나 문안이 적합하지 않아서 라벨링을 했던 
      경험이 있으신가요?`,

    `비교 견적을 통해 더 나은 조건을 찾고 계신가요?`,

    `서류 관련 인수인계 때문에 스트레스를 받은 적이 있으신가요?`,
  ];

  return (
    <Container ref={target} isIntersecting={isIntersecting} isMobile={isMobile}>
      <H1>혹시, 여기에 해당 되시나요?</H1>
      <ItemContainer>
        {textArr?.map((item, itemIdx) => (
          <Item key={itemIdx} desc={item} />
        ))}
      </ItemContainer>
      <CheckContainer />
    </Container>
  );
};

export default SectionD;
