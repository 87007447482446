import { Col, Modal, Row, Select, Switch, Tabs, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import useUpdateEffect from 'hook/useUpdateEffect';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useUser } from 'service/auth';
import {
  calculateRecipeDetailItemsRowSpan,
  useProductSamples,
} from 'service/manufacturer/productSample';
import {
  ProductSample,
  ProductSampleRecipeDetailItem,
  ProductSampleSearchStatus,
  ProductSampleSearchType,
} from 'types/manufacturer/productSample';
import ProductSampleRecipeDetailModalContent from './ProductSampleRecipeDetailModalContent';

const ProductSampleListBlock = styled.div``;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }

      &:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
        color: ${palette.GRAY70};
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

const searchTypeOptions = [
  { label: '전체', value: ProductSampleSearchType.ALL },
  {
    label: '샘플명 (가칭)',
    value: ProductSampleSearchType.PRODUCT_SAMPLE_NAME,
  },
  { label: '랩넘버', value: ProductSampleSearchType.LAB_NO },
  {
    label: '입력자',
    value: ProductSampleSearchType.REGISTER_USER_NAME,
  },
  { label: '부서명', value: ProductSampleSearchType.DEPT_NAME },
  { label: '자체 개발용', value: ProductSampleSearchType.IS_SELF_DEVELOP },
  { label: '책임판매사', value: ProductSampleSearchType.VENDOR_NAME },
];

const ProductSampleList = () => {
  const { userId } = useUser();
  const {
    productSamples,
    totalElements,
    getLoading,
    isMyProductSample,
    setIsMyProductSample,
    status,
    setStatus,
    searchType,
    setSearchType,
    searchKeyword,
    setSearchKeyword,
    page,
    setPage,
    size,
    setSize,
  } = useProductSamples(ProductSampleSearchStatus.CONFIRMED);
  const [searchTypeBuffer, setSearchTypeBuffer] = useState(searchType);
  const [searchKeywordBuffer, setSearchKeywordBuffer] = useState(searchKeyword);
  const handleClickRecipeDetail = ({
    recipeDetailItems,
    formulaExcelUrl,
    formulaBreakdownExcelUrl,
    formulaInciNameList,
  }: {
    recipeDetailItems: ProductSampleRecipeDetailItem[];
    formulaExcelUrl: string;
    formulaBreakdownExcelUrl: string;
    formulaInciNameList: string;
  }) => {
    calculateRecipeDetailItemsRowSpan(recipeDetailItems);
    Modal.info({
      icon: null,
      closable: true,
      width: 804,
      okButtonProps: { hidden: true },
      content: (
        <ProductSampleRecipeDetailModalContent
          recipeDetailItems={recipeDetailItems}
          formulaExcelUrl={formulaExcelUrl}
          formulaBreakdownExcelUrl={formulaBreakdownExcelUrl}
          formulaInciNameList={formulaInciNameList}
        />
      ),
    });
  };

  const columns: ColumnsType<ProductSample> = useMemo(
    () => [
      {
        title: '샘플명 (가칭)',
        render: (_, { productSampleId, productSampleName, registerUserId }) => (
          <Typography.Text
            type="BODY_2"
            medium
            color="GRAY90"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (registerUserId === userId) {
                history.push(
                  `${path.manufacturer.productSample.detail}/${productSampleId}`,
                );
              } else {
                message.info('해당 샘플의 정보는 입력자만 볼 수 있습니다.');
              }
            }}
          >
            {productSampleName}
          </Typography.Text>
        ),
      },
      {
        title: '랩넘버',
        align: 'center',
        dataIndex: 'labNo',
      },
      {
        title: '입력자',
        align: 'center',
        render: (_, { registerUserName, registerDeptName }) =>
          `${registerUserName} (${registerDeptName})`,
      },
      {
        title: '자체/책임판매사',
        align: 'center',
        render: (_, { vendorName, isSelfDevelop }) =>
          isSelfDevelop ? '자체 개발용' : vendorName,
      },
      {
        title: '최초 입력일',
        align: 'center',
        dataIndex: 'registerDt',
      },
      {
        title: '처방 상세',
        align: 'center',
        dataIndex: 'recipeDetailItems',
        render: (
          _,
          {
            recipeDetailItems,
            isAccessibleToRecipeDetail,
            formulaExcelUrl,
            formulaBreakdownExcelUrl,
            formulaInciNameList,
          },
        ) =>
          isAccessibleToRecipeDetail ? (
            <Icon
              name="page"
              size={22}
              onClick={() =>
                handleClickRecipeDetail({
                  recipeDetailItems: recipeDetailItems!,
                  formulaExcelUrl: formulaExcelUrl!,
                  formulaBreakdownExcelUrl: formulaBreakdownExcelUrl!,
                  formulaInciNameList: formulaInciNameList!,
                })
              }
              style={{ margin: '0 auto' }}
            />
          ) : (
            <Icon
              name="lock"
              color="GRAY50"
              style={{ margin: '0 auto' }}
              onClick={() => {
                message.info('처방 상세가 비공개로 설정되어 있습니다.');
              }}
            />
          ),
      },
    ],
    [isMyProductSample, userId],
  );
  const handleSearch = () => {
    setSearchType(searchTypeBuffer);
    setSearchKeyword(searchKeywordBuffer);
  };

  useUpdateEffect(() => {
    setPage(1);
    setSearchTypeBuffer(ProductSampleSearchType.ALL);
    setSearchKeywordBuffer('');
    setSearchType(ProductSampleSearchType.ALL);
    setSearchKeyword('');
  }, [status]);

  return (
    <ProductSampleListBlock>
      <StyledTabs
        activeKey={status}
        onTabClick={(key) => setStatus(key as ProductSampleSearchStatus)}
      >
        <Tabs.TabPane
          key={ProductSampleSearchStatus.CONFIRMED}
          tab="확정 완료 샘플"
        />
        <Tabs.TabPane
          key={ProductSampleSearchStatus.BE_USED}
          tab="완제품 등록 샘플"
        />
      </StyledTabs>
      <Row align="middle" justify="end" gutter={8}>
        <Col flex="1 1 auto" style={{ alignSelf: 'end' }}>
          <Typography.Text medium>
            총{' '}
            <Typography.Text color="PRIMARY50" inline medium>
              {totalElements}
            </Typography.Text>{' '}
            건
          </Typography.Text>
        </Col>
        <Col>
          <Select
            value={searchTypeBuffer}
            options={searchTypeOptions}
            style={{ width: 164 }}
            onChange={(searchType) => {
              if (searchType === ProductSampleSearchType.IS_SELF_DEVELOP) {
                setSearchType(searchType);
                setSearchKeywordBuffer('');
                setSearchKeyword('');
              }
              setSearchTypeBuffer(searchType);
            }}
          />
        </Col>
        <Col flex="0 0 250px">
          <SearchInput
            disabled={
              searchTypeBuffer === ProductSampleSearchType.IS_SELF_DEVELOP
            }
            defaultValue={searchKeywordBuffer}
            onChange={(e) => setSearchKeywordBuffer(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
        </Col>
        <Col style={{ marginLeft: 8 }}>
          <Select
            value={size}
            size="small"
            options={[
              { label: '10개씩', value: 10 },
              { label: '30개씩', value: 30 },
              { label: '50개씩', value: 50 },
            ]}
            onChange={(count: 10 | 30 | 50) => {
              setPage(1);
              setSize(count);
            }}
          />
        </Col>
        <Col>
          <Flex align="center" gap={8}>
            <Switch
              size="small"
              checked={isMyProductSample}
              onChange={() => setIsMyProductSample(!isMyProductSample)}
              style={{ marginLeft: 8 }}
            />
            <Typography.Text>내가 확정한 샘플만</Typography.Text>
          </Flex>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={productSamples}
        loading={getLoading}
        style={{ marginTop: 16 }}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: size,
          onChange: setPage,
        }}
        rowKey="productSampleId"
      />
    </ProductSampleListBlock>
  );
};

export default ProductSampleList;
