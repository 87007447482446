import { message } from 'antd';
import { Key, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EstimatePick from 'components/estimate/EstimatePick';
import ChecklistModal from 'components/modal/checklist/ChecklistModal';
import { Typography } from 'components/system';
import { useModal } from 'hook/useModal';
import * as estimateActions from 'modules/estimate';
import {
  useAddEstimateTargets,
  useCanSelfEstimateCountries,
  useDeleteEstimateTargets,
  useSelfEstimateTargets,
  useSimpleProducts,
} from 'service/brand/estimate';
import {
  CanSelfEstimateCountry,
  SelfEstimateTarget,
  SimpleProduct,
} from 'types/brand/estimate';

const EstimatePickContainer = () => {
  const [selectedProduct, setSelectedProduct] = useState<SimpleProduct | null>(
    null,
  );
  const [selectedCountries, setSelectedCountries] = useState<
    CanSelfEstimateCountry[]
  >([]);

  const { selectedEstimateTargetIds, publishLoading } = useSelector(
    ({ auth, estimate, loading }: any) => ({
      companyId: auth.user.companyId,
      selectedEstimateTargetIds: estimate.selectedEstimateTargetIds,
      deleteLoading: loading['estimate/DELETE_ESTIMATE_TARGETS'],
      publishLoading: loading['estimate/PUBLISH_ESTIMATE'],
    }),
  );

  const dispatch = useDispatch();
  const { openAlertModal } = useModal();
  const { simpleProducts, isLoading: getProductsLoading } = useSimpleProducts();
  const { canSelfEstimateCountries } = useCanSelfEstimateCountries(
    selectedProduct?.productId,
  );
  const {
    selfEstimateTargets,
    isLoading: getEstimateTargetsLoading,
  } = useSelfEstimateTargets();
  const { addEstimateTargets, addLoading } = useAddEstimateTargets();
  const { deleteEstimateTargets, deleteLoading } = useDeleteEstimateTargets();

  const selectProduct = (product: SimpleProduct) => {
    setSelectedProduct(product);
    setSelectedCountries([]);
  };
  const selectCountry = (target: CanSelfEstimateCountry) => {
    if (selectedCountries.includes(target)) {
      setSelectedCountries((draft) =>
        draft.filter((country) => country !== target),
      );
    } else {
      setSelectedCountries((draft) => draft.concat(target));
    }
  };
  const selectAllCountries = () => {
    setSelectedCountries(canSelfEstimateCountries);
  };
  const handleAddEstimateTargets = () => {
    if (!selectedProduct) {
      message.warn('제품을 선택해 주세요.');
      return;
    }
    if (!selectedCountries.length) {
      message.warn('국가를 선택해 주세요.');
      return;
    }
    setSelectedCountries([]);
    addEstimateTargets({
      productId: selectedProduct.productId,
      countryCodes: selectedCountries.map(({ countryCode }) => countryCode),
    });
  };
  const handleChangeSelectedEstimateTargetIds = (ids: Key[]) => {
    dispatch(estimateActions.setSelectedEstimateTargetIds(ids));
  };
  const publishEstimate = () => {
    if (
      selfEstimateTargets
        .filter(({ estimateTargetId }) =>
          selectedEstimateTargetIds.includes(estimateTargetId),
        )
        .find(({ status }) => status === 'INI')
    ) {
      return message.warn('체크리스트를 작성해 주세요.');
    }
    dispatch(
      estimateActions.publishEstimate({
        estimateTargetIds: selectedEstimateTargetIds,
      }),
    );
  };
  const handleDeleteEstimateTargets = () => {
    if (!selectedEstimateTargetIds.length) return;
    openAlertModal({
      content: (
        <Typography.Text style={{ fontSize: '14px' }}>
          정말 삭제하시겠습니까?
        </Typography.Text>
      ),
      onOk: () => deleteEstimateTargets(selectedEstimateTargetIds),
    });
  };

  const [checklistModalInfo, setChecklistModalInfo] = useState<
    { estimateTarget: SelfEstimateTarget } | undefined
  >();

  const showChecklist = (estimateTarget: SelfEstimateTarget) => {
    setChecklistModalInfo({ estimateTarget });
  };
  const closeChecklistModal = () => {
    setChecklistModalInfo(undefined);
  };

  return (
    <>
      {checklistModalInfo && (
        <ChecklistModal
          estimateTarget={checklistModalInfo.estimateTarget}
          onClose={closeChecklistModal}
        />
      )}
      <EstimatePick
        estimateTargets={selfEstimateTargets}
        selectedEstimateKeys={selectedEstimateTargetIds}
        products={simpleProducts}
        selectedProduct={selectedProduct}
        countries={canSelfEstimateCountries}
        selectedCountries={selectedCountries}
        getProductsLoading={getProductsLoading}
        getEstimateTargetsLoading={getEstimateTargetsLoading}
        deleteLoading={deleteLoading}
        publishLoading={publishLoading}
        addLoading={addLoading}
        onSelectProduct={selectProduct}
        onSelectCountry={selectCountry}
        onSelectAllCountries={selectAllCountries}
        onAddCountries={handleAddEstimateTargets}
        onChangeEstimateKeys={handleChangeSelectedEstimateTargetIds}
        onPublishEstimate={publishEstimate}
        onDeleteEstimateTargets={handleDeleteEstimateTargets}
        onClickChecklist={showChecklist}
      />
    </>
  );
};

export default EstimatePickContainer;
