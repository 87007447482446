import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import ProductFDAAccount from 'components/product/ProductFDAAccount';

const ProductFDAAccountPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId()!;
  const notices = [
    <>
      <span style={{ fontWeight: 500 }}>FDA</span> 계정의{' '}
      <span style={{ fontWeight: 500 }}>사용자명 (ID), 비밀번호</span>는 오직
      귀사의 제품 등록을 위해서만 사용됩니다. 기타 다른 목적으로 사용되지
      않습니다.
    </>,
    'certicos의 담당자는 귀사의 계정으로 제품 등록을 진행합니다.',
    '회사 당 하나의 계정만 등록 가능합니다.',
    '입력된 계정 정보 변경 시, FDA 등록을 신청하신 모든 제품에 변경된 계정정보가 반영됩니다.',
    <MistakeNoticeItem />,
  ];

  return (
    <ProductPageTemplate subtitle="보유 FDA 계정 정보" notices={notices}>
      <ProductFDAAccount productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductFDAAccountPage;
