import {
  Button,
  Checkbox,
  Col,
  Empty,
  Modal,
  Pagination,
  Row,
  Select,
  Switch,
  Tabs,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cx from 'classnames';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import useUpdateEffect from 'hook/useUpdateEffect';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useUser } from 'service/auth';
import {
  calculateRecipeDetailItemsRowSpan,
  useChangeProductSampleInternalDisclosure,
  useConfirmProductSamples,
  useProductSamples,
} from 'service/manufacturer/productSample';
import {
  ProductSample,
  ProductSampleRecipeDetailItem,
  ProductSampleSearchStatus,
  ProductSampleSearchType,
} from 'types/manufacturer/productSample';
import ProductSampleRecipeDetailModalContent from './ProductSampleRecipeDetailModalContent';

const ProductSampleReadyListBlock = styled.div``;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }

      &:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
        color: ${palette.GRAY70};
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

const ProductSampleReadyList = () => {
  const [selectedProductSampleIds, setSelectedProductSampleIds] = useState<
    number[]
  >([]);
  const { userId } = useUser();
  const {
    productSamples,
    totalElements,
    getLoading,
    refetchProductSamples,
    isMyProductSample,
    setIsMyProductSample,
    searchType,
    setSearchType,
    searchKeyword,
    setSearchKeyword,
    page,
    setPage,
    size,
    setSize,
  } = useProductSamples(ProductSampleSearchStatus.REGISTERING);
  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: ProductSampleSearchType.ALL },
      {
        label: '샘플명 (가칭)',
        value: ProductSampleSearchType.PRODUCT_SAMPLE_NAME,
      },
      { label: '랩넘버', value: ProductSampleSearchType.LAB_NO },
      ...(!isMyProductSample
        ? [
            {
              label: '입력자',
              value: ProductSampleSearchType.REGISTER_USER_NAME,
            },
            { label: '부서명', value: ProductSampleSearchType.DEPT_NAME },
          ]
        : []),
      { label: '자체 개발용', value: ProductSampleSearchType.IS_SELF_DEVELOP },
      { label: '책임판매사', value: ProductSampleSearchType.VENDOR_NAME },
    ],
    [isMyProductSample],
  );
  const [searchTypeBuffer, setSearchTypeBuffer] = useState(searchType);
  const [searchKeywordBuffer, setSearchKeywordBuffer] = useState(searchKeyword);
  const {
    confirmProductSamples,
    isLoading: confirmLoading,
  } = useConfirmProductSamples();
  const {
    changeProductSampleInternalDisclosure,
    isLoading: changeInternalDisclosureLoading,
  } = useChangeProductSampleInternalDisclosure();

  const handleClickProductSampleRecipeDetail = ({
    recipeDetailItems,
    recipeDetailUploadFileUrl,
  }: {
    recipeDetailItems: ProductSampleRecipeDetailItem[] | null;
    recipeDetailUploadFileUrl: string | null;
  }) => {
    if (recipeDetailItems && recipeDetailItems.length) {
      calculateRecipeDetailItemsRowSpan(recipeDetailItems);
      Modal.info({
        icon: null,
        closable: true,
        width: 804,
        okButtonProps: { hidden: true },
        content: (
          <ProductSampleRecipeDetailModalContent
            recipeDetailItems={recipeDetailItems}
            recipeDetailUploadFileUrl={recipeDetailUploadFileUrl!}
          />
        ),
      });
    } else {
      Modal.info({
        icon: null,
        content: (
          <>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={null}
              style={{ marginBottom: 0 }}
            />
            <Typography.Text type="BODY_2" color="GRAY50" align="center">
              현재 ‘처방 상세’가 입력되어 있지 않습니다.
            </Typography.Text>
          </>
        ),
      });
    }
  };

  const columns: ColumnsType<ProductSample> = useMemo(
    () => [
      {
        title: '샘플명 (가칭)',
        width: '25%',
        render: (_, { productSampleId, productSampleName, registerUserId }) => (
          <Typography.Text
            type="BODY_2"
            medium
            color="GRAY90"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (registerUserId === userId) {
                history.push(
                  `${path.manufacturer.productSample.detail}/${productSampleId}`,
                );
              } else {
                message.info('해당 샘플의 정보는 입력자만 볼 수 있습니다.');
              }
            }}
          >
            {productSampleName}
          </Typography.Text>
        ),
      },
      {
        title: '랩넘버',
        align: 'center',
        dataIndex: 'labNo',
      },
      ...(!isMyProductSample
        ? ([
            {
              title: '입력자',
              align: 'center',
              render: (_, { registerUserName, registerDeptName }) =>
                `${registerUserName} (${registerDeptName})`,
            },
          ] as ColumnsType<ProductSample>)
        : []),
      {
        title: '자체/책임판매사',
        align: 'center',
        render: (_, { vendorName, isSelfDevelop }) =>
          isSelfDevelop ? '자체 개발용' : vendorName,
      },
      {
        title: '최초 입력일',
        align: 'center',
        dataIndex: 'registerDt',
      },
      {
        title: '처방 상세',
        align: 'center',
        dataIndex: 'recipeDetailItems',
        render: (
          _,
          {
            recipeDetailItems,
            recipeDetailUploadFileUrl,
            isAccessibleToRecipeDetail,
          },
        ) =>
          isAccessibleToRecipeDetail ? (
            <Icon
              name="page"
              style={{ margin: '0 auto' }}
              size={22}
              onClick={() =>
                handleClickProductSampleRecipeDetail({
                  recipeDetailItems,
                  recipeDetailUploadFileUrl,
                })
              }
            />
          ) : (
            <Icon
              name="lock"
              color="GRAY50"
              style={{ margin: '0 auto' }}
              onClick={() => {
                message.info('처방 상세가 비공개로 설정되어 있습니다.');
              }}
            />
          ),
      },
      ...(isMyProductSample
        ? ([
            {
              title: '처방 상세 공개',
              align: 'center',
              dataIndex: 'isInternalDisclosure',
              render: (_, { productSampleId, isInternalDisclosure }) => (
                <Flex gap={8} align="center" justify="center">
                  <Switch
                    size="small"
                    checked={isInternalDisclosure}
                    onChange={() => {
                      changeProductSampleInternalDisclosure(
                        {
                          productSampleId,
                          isInternalDisclosure: !isInternalDisclosure,
                        },
                        {
                          onSuccess: () => {
                            if (isInternalDisclosure) {
                              message.success(
                                '처방 상세가 비공개로 설정되었습니다.',
                              );
                            } else {
                              message.success(
                                '처방 상세가 내부 공개로 설정되었습니다.',
                              );
                            }
                            refetchProductSamples();
                          },
                        },
                      );
                    }}
                  />
                  <Typography.Text color="GRAY90">
                    {isInternalDisclosure ? '내부 공개' : '비공개'}
                  </Typography.Text>
                </Flex>
              ),
            },
          ] as ColumnsType<ProductSample>)
        : []),
    ],
    [userId, isMyProductSample],
  );
  const handleSearch = () => {
    setSearchType(searchTypeBuffer);
    setSearchKeyword(searchKeywordBuffer);
  };
  const handleClickConfirm = () => {
    if (selectedProductSampleIds.length === 0) {
      return message.warn('한 개 이상의 샘플을 선택해 주세요.');
    }
    const selectedProductSamples = productSamples.filter(
      ({ productSampleId }) =>
        selectedProductSampleIds.includes(productSampleId),
    );
    Modal.confirm({
      icon: null,
      width: 400,
      bodyStyle: {
        paddingLeft: 16,
        paddingRight: 28,
      },
      okText: '샘플 확정',
      content: (
        <>
          <Typography.Text type="BODY_2">
            확정 완료 샘플은 제품 등록에서 사용할 수 있습니다.
            <br /> 아래 항목들을 샘플 확정하시겠습니까?
          </Typography.Text>
          <Typography.Text type="SMALL" color="GRAY50">
            (수정·삭제 불가하니 완제품 기준 확정 데이터로 진행해 주세요.)
          </Typography.Text>
          <br />
          {selectedProductSamples.map(({ productSampleName, labNo }) => (
            <Typography.Text type="BODY_2" medium>
              - {productSampleName} ({labNo})
            </Typography.Text>
          ))}
        </>
      ),
      onOk: () => {
        confirmProductSamples(selectedProductSampleIds, {
          onSuccess: () => {
            history.push(path.manufacturer.productSample.list);
          },
        });
      },
    });
  };
  const showConfirmWarningMessage = _.throttle(
    () => {
      message.warn(
        '입력 필요 항목이 있습니다. 입력 완료 후 샘플 확정을 시도해 주세요.',
      );
    },
    3000,
    { trailing: false },
  );
  useUpdateEffect(() => {
    setPage(1);
    setSearchTypeBuffer(ProductSampleSearchType.ALL);
    setSearchKeywordBuffer('');
    setSearchType(ProductSampleSearchType.ALL);
    setSearchKeyword('');
  }, [isMyProductSample]);
  return (
    <ProductSampleReadyListBlock>
      <StyledTabs
        activeKey={isMyProductSample ? 'MY' : 'ALL'}
        onTabClick={(key) => setIsMyProductSample(key === 'MY')}
      >
        <Tabs.TabPane key="MY" tab="나의 샘플 목록" />
        <Tabs.TabPane key="ALL" tab="전체 샘플 목록" />
      </StyledTabs>
      <Row align="middle" justify="end" gutter={8}>
        <Col flex="1 1 auto" style={{ alignSelf: 'end' }}>
          <Typography.Text medium>
            총{' '}
            <Typography.Text color="PRIMARY50" inline medium>
              {totalElements}
            </Typography.Text>{' '}
            건
          </Typography.Text>
        </Col>
        <Col>
          <Select
            value={searchTypeBuffer}
            options={searchTypeOptions}
            style={{ width: 164 }}
            onChange={(searchType) => {
              if (searchType === ProductSampleSearchType.IS_SELF_DEVELOP) {
                setSearchType(searchType);
                setSearchKeywordBuffer('');
                setSearchKeyword('');
              }
              setSearchTypeBuffer(searchType);
            }}
          />
        </Col>
        <Col flex="0 0 250px">
          <SearchInput
            disabled={
              searchTypeBuffer === ProductSampleSearchType.IS_SELF_DEVELOP
            }
            defaultValue={searchKeywordBuffer}
            onChange={(e) => setSearchKeywordBuffer(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
        </Col>
        <Col style={{ marginLeft: 8 }}>
          <Select
            value={size}
            size="small"
            options={[
              { label: '10개씩', value: 10 },
              { label: '30개씩', value: 30 },
              { label: '50개씩', value: 50 },
            ]}
            onChange={(count: 10 | 30 | 50) => {
              setPage(1);
              setSize(count);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={productSamples}
        loading={
          getLoading || confirmLoading || changeInternalDisclosureLoading
        }
        style={{ marginTop: 16 }}
        pagination={false}
        rowKey="productSampleId"
        rowSelection={
          isMyProductSample
            ? {
                selectedRowKeys: selectedProductSampleIds,
                onChange: (rowKeys) => {
                  setSelectedProductSampleIds(rowKeys as number[]);
                },
                renderCell: (checked, { isConfirmable }, _, originNode) => (
                  <Checkbox
                    checked={checked}
                    className={cx({
                      'ant-checkbox-disabled': !isConfirmable,
                    })}
                    onChange={(e) => {
                      if (!isConfirmable) {
                        return showConfirmWarningMessage();
                      }
                      (originNode as React.ReactElement).props.onChange(e);
                    }}
                  />
                ),
                getCheckboxProps: ({ isConfirmable }) => ({
                  disabled: !isConfirmable,
                }),
              }
            : undefined
        }
        footer={() => (
          <Row
            justify="space-between"
            style={{ paddingTop: 16, paddingBottom: 16 }}
          >
            <Col flex="0 0 152px">
              {isMyProductSample && (
                <Button onClick={handleClickConfirm}>
                  선택한 항목 샘플 확정
                </Button>
              )}
            </Col>
            <Col>
              <Pagination
                current={page}
                total={totalElements}
                pageSize={size}
                onChange={(page) => {
                  setPage(page);
                  setSelectedProductSampleIds([]);
                }}
              />
            </Col>
            <Col flex="0 0 152px"></Col>
          </Row>
        )}
      />
    </ProductSampleReadyListBlock>
  );
};

export default ProductSampleReadyList;
