import { Button, Col, message, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { SearchInput } from 'components/system';
import Typography from 'components/system/general/Typography';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import { useDeleteProducts } from 'service/brand/product/product';
import { useRetailerApplyProducts } from 'service/brand/retailer/retailerApply/product';
import { useGetProductIsCertificated } from 'service/product';
import {
  ProductListItem,
  ProductListPageState,
} from 'types/brand/retailer/retailerApply';

const Container = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 10px 16px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 10px 16px;
  }

  .ant-table-wrapper table tr {
    & > th:first-child,
    & > td:first-child {
      padding-left: 24px;
    }
  }
`;

const ProductList = ({
  initialPageState,
  onChangePageState,
}: {
  initialPageState: ProductListPageState;
  onChangePageState: (state: ProductListPageState) => void;
}) => {
  const history = useHistory<ProductListPageState>();
  const [page, setPage] = useState(initialPageState.page);
  const [searchValue, setSearchValue] = useState(initialPageState.searchValue);
  const { openAlertModal } = useModal();
  const searchProduct = (searchValue: string) => {
    setPage(1);
    setSearchValue(searchValue);
  };
  useEffect(() => {
    setPage(initialPageState.page);
    setSearchValue(initialPageState.searchValue);
  }, [initialPageState]);

  const {
    retailerProducts,
    totalElements,
    isLoading,
    refetch,
  } = useRetailerApplyProducts({
    page,
    searchValue,
  });

  const handleClickProductListItem = (productListItem: ProductListItem) => {
    history.push(
      `${
        path.retailer.retailerApply.product[
          productListItem.productCategoryId ? 'root' : 'cat'
        ]
      }/${productListItem.productId}`,
      {
        page,
        searchValue,
      },
    );
  };

  const getProductIsCertificated = useGetProductIsCertificated();
  const { deleteProducts } = useDeleteProducts();
  const handleClickDelete = async (productId: number) => {
    const isCertificated = await getProductIsCertificated(productId);
    if (!isCertificated) {
      openAlertModal({
        content: (
          <Typography.Text type="BODY_2" align="center">
            해당 리스트에서 제품을 삭제할 경우
            <br /> [등록 중 제품 관리] 메뉴에
            <br /> 등록된 제품도 함께 삭제됩니다.
            <br /> 정말로 삭제하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => {
          deleteProducts([productId], {
            onSuccess: () => {
              message.success('삭제되었습니다.');
              refetch();
            },
          });
        },
      });
    } else {
      openAlertModal({
        content: `인증 진행 혹은 인증 완료 제품은 삭제하실 수 없습니다.\n(단, 수정은 가능합니다.)`,
        noCancelButton: true,
      });
    }
  };

  const columns: ColumnsType<ProductListItem> = useMemo(
    () => [
      {
        title: '영문 제품명 (용량)',
        width: '33%',
        render: (_, product) => (
          <Typography.Text
            type="BODY_2"
            color="GRAY90"
            style={{ fontWeight: 500, cursor: 'pointer', maxWidth: 280 }}
            onClick={() => handleClickProductListItem(product)}
          >
            {`${product.productNameEn} ${
              product.netWeight
                ? `(${product.netWeight} ${product.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '국문 제품명',
        width: '33%',
        dataIndex: 'productNameKo',
        render: (productNameKo) => (
          <Typography.Text
            type="BODY_2"
            color="GRAY70"
            style={{ maxWidth: 280 }}
          >
            {productNameKo}
          </Typography.Text>
        ),
      },
      {
        title: '제조사',
        width: '20%',
        dataIndex: 'manufacturerNameKo',
        render: (manufacturerNameKo) => (
          <Typography.Text
            type="BODY_2"
            color="GRAY70"
            style={{ maxWidth: 160 }}
          >
            {manufacturerNameKo}
          </Typography.Text>
        ),
      },

      {
        width: '13%',
        align: 'center',
        render: (_, product) => (
          <Icon
            name="delete"
            size={20}
            color="GRAY60"
            onClick={() => handleClickDelete(product.productId)}
            style={{ margin: '0 auto' }}
          />
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    onChangePageState({
      page,
      searchValue,
    });
  }, [page, searchValue]);

  return (
    <Container>
      <Row justify="center" style={{ marginBottom: 16, position: 'relative' }}>
        <Col>
          <SearchInput
            placeholder="제품명"
            defaultValue={searchValue}
            onSearch={searchProduct}
            style={{ width: 400 }}
          />
        </Col>
        <Col style={{ position: 'absolute', right: 0 }}>
          <Button
            icon={
              <Icon
                name="multipleAdd"
                color="PRIMARY50"
                size={20}
                style={{ marginRight: 4 }}
              />
            }
            onClick={() =>
              history.push(path.retailer.retailerApply.product.basic)
            }
            style={{ display: 'flex', alignItems: 'center' }}
          >
            신규 제품 등록
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={retailerProducts}
        rowKey="productId"
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: 10,
          total: totalElements,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </Container>
  );
};

export default ProductList;
