import { Button, message, Modal, Spin, Upload } from 'antd';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import FooterBox from 'components/FooterBox';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import Table from 'components/Table';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import Tags from 'components/ui/Tags';
import { useCertificateMode } from 'hook/certificate';
import useGA, { EGAActionType } from 'hook/useGA';
import { downloadFile } from 'lib/file';
import history from 'lib/history';
import palette from 'lib/styles/palette';
import { updateCurrentPathname } from 'modules/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';
import {
  useProductDocStatus,
  useProductFormulaBreakdownChina,
} from 'service/product';
import { CountryProhibitInfo } from 'types/formula';
import { DocumentCode, ProductFormulaBreakdownChinaItem } from 'types/product';
import UpdateLog from './UpdateLog';

const Container = styled.div``;

const ProductFormulaBreakdownContainer = styled.div<{
  success?: boolean;
  isWtSumError: boolean;
  isSubstanceSumError: boolean;
}>`
  table th {
    text-align: center;
  }

  .ant-table-cell {
    font-size: 12px;

    &.bigo {
      display: none;
    }
  }
  .ant-table-wrapper {
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  ${({ success }) =>
    typeof success !== 'undefined' &&
    css`
      .ant-table-wrapper {
        border-color: ${success
          ? palette.MESSAGE_SUCCESS
          : palette.MESSAGE_ERROR};
      }

      ${success === false &&
      css`
        .ant-table-cell.bigo {
          display: table-cell;
        }
      `}

      td.ant-table-cell.bigo {
        color: ${palette.MESSAGE_ERROR};
      }
    `}

  ${({ isWtSumError }) =>
    isWtSumError &&
    css`
      td.ant-table-cell.wt {
        color: ${palette.MESSAGE_ERROR};
      }
    `}
      
      ${({ isSubstanceSumError }) =>
    isSubstanceSumError &&
    css`
      td.ant-table-cell.sub-percent {
        color: ${palette.MESSAGE_ERROR};
      }
    `}
`;

const StyledButton = styled(Button)<{ isDownload: boolean; disabled: boolean }>`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${({ disabled }) => !disabled && palette.PRIMARY50};
      transition: ${({ isDownload }) =>
        isDownload ? 'all 300ms ease' : 'all 150ms ease'};
    }
  }

  svg,
  path {
    transition: ${({ isDownload }) =>
      isDownload ? 'all 300ms ease' : 'all 150ms ease'};
  }
`;

const ProductFormulaBreakdownChina = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const { sendEventToGA } = useGA();
  const {
    formulaBreakdownChinaItems,
    getLoading,
    productFormulaBreakdownChinaDraft,
    uploadProductFormulaBreakdownChina,
    uploadLoading,
  } = useProductFormulaBreakdownChina({ productId, countryId });

  const documentCode = DocumentCode.FORMBRC;
  const dispatch = useDispatch();

  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });

  const filename = productDocStatus?.filename || 'Formula Breakdown_china.xlsx';

  const { skipMode, readOnlyMode } = useSelector(
    ({ product, certificate }: any) => ({
      currentProduct: product.currentProduct,
      skipMode: product.skipMode,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const certificateMode = useCertificateMode();

  const success = productFormulaBreakdownChinaDraft
    ? !productFormulaBreakdownChinaDraft.isWtSumError &&
      !productFormulaBreakdownChinaDraft.isSubstanceSumError &&
      productFormulaBreakdownChinaDraft.uploadedExcelUrl === null
    : undefined;

  const handleUpload = (file: File) => {
    uploadProductFormulaBreakdownChina(file, {
      onSuccess: () => {
        sendEventToGA({
          documentName: 'Product Formula Breakdown (China)',
          actionType: certificateMode
            ? EGAActionType.MODIFY
            : updateMode
            ? EGAActionType.UPDATE
            : EGAActionType.REGISTER,
        });
        if (certificateMode) {
          message.success('보완 완료되었습니다.');
        }
      },
    });
  };

  const handleMoveNext = () => {
    dispatch(updateCurrentPathname());
  };

  return (
    <Container>
      <LoadingModal
        visible={(updateMode && getLoading) || uploadLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 스크리닝 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <ProductFormulaBreakdownContainer
        success={success}
        isWtSumError={
          productFormulaBreakdownChinaDraft
            ? productFormulaBreakdownChinaDraft.isWtSumError
            : false
        }
        isSubstanceSumError={
          productFormulaBreakdownChinaDraft
            ? productFormulaBreakdownChinaDraft.isWtSumError
            : false
        }
      >
        <Flex justify="end" gap={8}>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={documentCode}
          />
          {updateMode && (
            <UpdateLog
              productId={productId!}
              countryId={countryId}
              documentCode={documentCode}
            />
          )}
        </Flex>
        <Flex
          gap={8}
          justify="center"
          align="center"
          style={{ marginBottom: 24 }}
        >
          <StyledButton
            type="dashed"
            isDownload={true}
            icon={<Icon name="download" size={14} style={{ marginRight: 4 }} />}
            href="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Product%2BFormula%2BBreakdown%2B(China).xlsx"
            disabled={readOnlyMode || (success && certificateMode)}
            style={{
              cursor: readOnlyMode && 'not-allowed',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            엑셀 서식 다운로드
          </StyledButton>
          <Upload
            accept=".xlsx"
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            itemRender={() => null}
          >
            <StyledButton
              isDownload={false}
              loading={uploadLoading}
              icon={<Icon name="upload" size={14} style={{ marginRight: 4 }} />}
              disabled={readOnlyMode || (success && certificateMode)}
              style={{
                width: 122,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onDrop={(e) => e.stopPropagation()}
            >
              파일 업로드
            </StyledButton>
          </Upload>
          {productDocStatus && typeof success === 'undefined' && (
            <Typography.Text
              type="BODY_2"
              color="PRIMARY50"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                downloadFile(productDocStatus.uploadFileUrl, filename)
              }
            >
              {filename}
            </Typography.Text>
          )}
        </Flex>
        {(productFormulaBreakdownChinaDraft ||
          formulaBreakdownChinaItems.length > 0) && (
          <>
            <Flex style={{ marginTop: 16, marginBottom: 8 }} align="center">
              <div style={{ flex: 'auto' }}>
                {productFormulaBreakdownChinaDraft && (
                  <>
                    {success && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="success" />
                        정상적으로 업로드되었습니다.
                      </Typography.Text>
                    )}
                    {productFormulaBreakdownChinaDraft.uploadedExcelUrl && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="error" />
                        업로드를 시도한 파일의 특정 셀에 오류가 있습니다.{' '}
                        <Typography.Text
                          type="BODY_2"
                          inline
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            downloadFile(
                              productFormulaBreakdownChinaDraft.uploadedExcelUrl,
                            )
                          }
                        >
                          여기
                        </Typography.Text>
                        를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
                      </Typography.Text>
                    )}
                    {productFormulaBreakdownChinaDraft.isWtSumError && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="error" />
                        모든 성분의 함량(WT %)을 더했을 때 100이 아닙니다.
                        수정하여 업로드해 주세요.
                      </Typography.Text>
                    )}
                    {productFormulaBreakdownChinaDraft.isSubstanceSumError && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="error" />
                        모든 성분의 함량(% calculated for each substance)을
                        더했을 때 100이 아닙니다. 수정하여 업로드해 주세요.
                      </Typography.Text>
                    )}
                  </>
                )}
              </div>
              <Tags.Mark color="lightGray">DB 업데이트</Tags.Mark>
              <Typography.Text
                type="SMALL"
                medium
                color="SLATE_GRAY70"
                inline
                gutter={{ left: 8 }}
              >
                [국가별 성분 규제]
              </Typography.Text>
              <Typography.Text
                type="SMALL"
                medium
                color="SLATE_GRAY70"
                inline
                gutter={{ left: 4 }}
              >
                {prohibitDataUpdatedDate}
              </Typography.Text>
            </Flex>
            <Spin spinning={getLoading}>
              <ProductFormulaBreakdownTable
                formulaBreakdownItems={
                  productFormulaBreakdownChinaDraft
                    ? productFormulaBreakdownChinaDraft.list
                    : formulaBreakdownChinaItems
                }
                isChina={true}
              />
            </Spin>
          </>
        )}
        {success && (
          <FooterBox>
            {skipMode ? (
              <Button type="primary" onClick={() => handleMoveNext()}>
                다음 단계로 이동
              </Button>
            ) : (
              !certificateMode && (
                <Button type="primary" onClick={history.goBack}>
                  확인
                </Button>
              )
            )}
          </FooterBox>
        )}
      </ProductFormulaBreakdownContainer>
    </Container>
  );
};

const ProductFormulaBreakdownTableBlock = styled.div``;

const AdditionalItemInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 181px;
  transform: translateY(-50%);
  padding: 8px 16px;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  background-color: #fff;
  width: max-content;
  text-align: left;
  color: ${palette.GRAY90};
  z-index: 1;
  white-space: pre-wrap;
  max-width: 304px;
`;

const ProductFormulaBreakdownTable = ({
  formulaBreakdownItems,
  isChina,
}: {
  formulaBreakdownItems: ProductFormulaBreakdownChinaItem[];
  isChina: boolean;
}) => {
  const [
    chinaExportInformationModalVisible,
    setChinaExportInformationModalVisible,
  ] = useState(false);
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [
    selectedFormulaCountryInfos,
    setSelectedFormulaCountryInfos,
  ] = useState<CountryProhibitInfo[]>([]);
  const [selectedCountryName, setSelectedCountryName] = useState<string | null>(
    null,
  );

  const handleClickMore = (
    formulaCountryInfos: CountryProhibitInfo[],
    countryNameKo?: string,
  ) => {
    setSelectedFormulaCountryInfos(formulaCountryInfos);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  };

  const handleCloseCountryInfosModal = () => {
    setSelectedCountryName(null);
    setSelectedFormulaCountryInfos([]);
  };

  return (
    <ProductFormulaBreakdownTableBlock>
      <CountryProhibitInfosModal
        defaultCountryName={selectedCountryName || undefined}
        countryInfos={selectedFormulaCountryInfos || []}
        onClose={handleCloseCountryInfosModal}
      />
      <ChinaExportInformationModal
        visible={chinaExportInformationModalVisible}
        onClose={() => setChinaExportInformationModalVisible(false)}
      />
      <Table bordered>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>No.</Table.Th>
            <Table.Th>INCI Name</Table.Th>
            <Table.Th>WT %</Table.Th>
            <Table.Th>% in raw material</Table.Th>
            <Table.Th>% calculated for each substance</Table.Th>
            <Table.Th>Function</Table.Th>
            <Table.Th>CAS No.</Table.Th>
            <Table.Th
              style={{ whiteSpace: 'pre-wrap' }}
            >{`Remark\n(추출물 추출부위)`}</Table.Th>
            <Table.Th className="bigo">실패 사유</Table.Th>
            <Table.Th colSpan={!isChina ? 2 : 1}>
              {!isChina ? '국가별 성분 규제' : '규제사항'}
            </Table.Th>
            <Table.Th colSpan={!isChina ? 2 : 1} width="68">
              수출 정보
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {formulaBreakdownItems.map(
            ({
              no,
              noSync,
              noRowSpan,
              inciName,
              formulaWt,
              formulaWtRowSpan,
              formulaRawMaterialPercent,
              formulaSubstancePercent,
              formulaFunction,
              formulaCasNo,
              remark,
              bigo,
              useDescription,
              limitedFormulaCountryInfos,
              nmpaDeclarationCode,
              hasAnnex14,
              tradeName,
              supplier,
            }) => {
              const formulaTypes = Array.from(
                new Set(limitedFormulaCountryInfos.map(({ type }) => type)),
              ).sort();
              return (
                <Table.Tr
                  key={nanoid()}
                  style={
                    currentRow && (currentRow === no || currentRow === noSync)
                      ? { backgroundColor: '#fafafa' }
                      : {}
                  }
                  onMouseEnter={() => {
                    setCurrentRow(noSync || no);
                  }}
                  onMouseLeave={() => setCurrentRow(null)}
                >
                  {noRowSpan && (
                    <Table.Td
                      style={{ textAlign: 'center' }}
                      rowSpan={noRowSpan}
                    >
                      {no}
                      {currentRow === no && (
                        <AdditionalItemInfo>
                          <Typography.Text type="SMALL">
                            NMPA 원료 신고번호 :{' '}
                            {nmpaDeclarationCode || '(미입력)'}
                          </Typography.Text>
                          <Typography.Text type="SMALL">
                            ANNEX 14 보유 여부 :{' '}
                            {hasAnnex14 !== null
                              ? hasAnnex14
                                ? 'O'
                                : 'X'
                              : '(미입력)'}
                          </Typography.Text>
                          <Typography.Text type="SMALL">
                            TradeName / Supplier : {tradeName || '(미입력)'} /{' '}
                            {supplier || '(미입력)'}
                          </Typography.Text>
                        </AdditionalItemInfo>
                      )}
                    </Table.Td>
                  )}
                  <Table.Td>{inciName}</Table.Td>
                  {formulaWtRowSpan && (
                    <Table.Td
                      className="wt"
                      rowSpan={formulaWtRowSpan}
                      style={{ textAlign: 'right' }}
                    >
                      {formulaWt}
                    </Table.Td>
                  )}
                  <Table.Td width="60" style={{ textAlign: 'right' }}>
                    {formulaRawMaterialPercent}
                  </Table.Td>
                  <Table.Td
                    width="100"
                    className="sub-percent"
                    style={{ textAlign: 'right' }}
                  >
                    {formulaSubstancePercent}
                  </Table.Td>
                  <Table.Td>{formulaFunction}</Table.Td>
                  <Table.Td>{formulaCasNo}</Table.Td>
                  <Table.Td width="106" style={{ textAlign: 'center' }}>
                    {remark}
                  </Table.Td>
                  <Table.Td className="bigo">{bigo}</Table.Td>
                  <Table.Td width="60" style={{ textAlign: 'center' }}>
                    {formulaTypes.map((type) => (
                      <Tags.Chip
                        key={type}
                        type="line"
                        color={
                          type === 'limit' ? 'MESSAGE_CHECK' : 'MESSAGE_ERROR'
                        }
                        style={{
                          display: 'inline-flex',
                        }}
                        onClick={() => {
                          handleClickMore(limitedFormulaCountryInfos);
                        }}
                      >
                        {type === 'limit' ? '제한' : '금지'}
                      </Tags.Chip>
                    ))}
                  </Table.Td>
                  <Table.Td
                    style={{
                      color: palette.MESSAGE_ERROR,
                      textAlign: 'center',
                    }}
                  >
                    {useDescription && (
                      <Tags.Chip
                        type="line"
                        color="MESSAGE_ERROR"
                        onClick={() =>
                          setChinaExportInformationModalVisible(true)
                        }
                      >
                        불가
                      </Tags.Chip>
                    )}
                  </Table.Td>
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
    </ProductFormulaBreakdownTableBlock>
  );
};

const ChinaExportInformationModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      visible={visible}
      width={896}
      onCancel={onClose}
      footer={null}
      centered
    >
      <Typography.Text type="TITLE_2" style={{ fontSize: 24 }}>
        중국 수출 정보
      </Typography.Text>
      <Table
        bordered
        size="small"
        className="horizontal"
        style={{
          marginTop: 16,
          border: `1px solid ${palette.PRIMARY50}`,
          borderRadius: 0,
        }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Th
              style={{
                width: 200,
              }}
            >
              구분
            </Table.Th>
            <Table.Td>불가</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>제한 사항</Table.Th>
            <Table.Td style={{ whiteSpace: 'pre-line' }}>
              중국기사용화장품원료목록에 없는 원료로, 사용이 불가능합니다.
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Modal>
  );
};

export default ProductFormulaBreakdownChina;
