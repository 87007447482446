import { Col, Row, Statistic } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { useMemo } from 'react';
import styled from 'styled-components';

import StatisticCard from 'components/StatisticCard';
import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import palette from 'lib/styles/palette';
import { DocumentCode } from 'types/product';

const CertificateDetailBlock = styled.div`
  .ant-table-cell:nth-child(2) {
    width: 388px;
  }
`;

const CertificateFinalDocumentContainer = styled.div`
  padding-top: 56px;

  .ant-table-cell:nth-child(2) {
    width: 386px;
  }
`;

const CertificateDetail = ({
  certificate,
  documents,
  fixingDocumentIndex,
  fetchLoading,
  fixDocumentLoading,
  onClickDocument,
}: any) => {
  const normalDocuments = documents.filter(
    (item: any) =>
      item.documentCode !== DocumentCode.MANUC &&
      item.documentCode !== DocumentCode.PEEC &&
      item.documentCode !== DocumentCode.CFS &&
      item.documentCode !== DocumentCode.PCPA,
  );

  const finalDocuments = documents.filter(
    (item: any) =>
      item.documentCode === DocumentCode.MANUC ||
      item.documentCode === DocumentCode.PEEC ||
      (item.documentCode === DocumentCode.CFS &&
        item.productDocStatusId !== null) ||
      (item.documentCode === DocumentCode.PCPA &&
        item.productDocStatusId !== null),
  );

  const {
    certRequestNo,
    estimateTarget: {
      countryName,
      countryId,
      productDetail,
      netWeight,
      netWeightUnit,
    },
  } = certificate;

  const isChina = certificate.estimateTarget.countryName === '중국';

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center',
        width: '10%',
        render: (_, __, index) => {
          return index + 1;
        },
      },
      {
        title: '입력 정보',
        render: (document) => (
          <Flex align="center" gap={8}>
            <Typography.Text
              type="BODY_2"
              onClick={() => {
                onClickDocument(document);
              }}
              style={{
                cursor: 'pointer',
                fontWeight: 500,
                color: palette.GRAY90,
              }}
            >
              {document.documentName}
            </Typography.Text>
            {document.isPermit === false && (
              <Icon name="lock" size={18} color="GRAY70" />
            )}
          </Flex>
        ),
      },
      {
        title: '처리현황',
        align: 'center',
        render: (document: any) => {
          const { status, statusString } = document;
          return (
            <Row justify="center" align="middle" gutter={8}>
              <Col>
                <Tags.Mark
                  onClick={
                    status === 'INP' ||
                    status === 'MOD' ||
                    status === 'OPT' ||
                    status === 'CHK'
                      ? () => onClickDocument(document)
                      : undefined
                  }
                  type={
                    status === 'INP' ||
                    status === 'MOD' ||
                    status === 'OPT' ||
                    status === 'CHK'
                      ? 'arrow'
                      : 'state'
                  }
                  color={
                    status === 'INP' ||
                    status === 'MOD' ||
                    status === 'OPT' ||
                    status === 'CHK'
                      ? 'blue'
                      : 'lightGray'
                  }
                  style={{ width: 71 }}
                >
                  {statusString}
                </Tags.Mark>
              </Col>
            </Row>
          );
        },
      },
      {
        title: '최근 입력자 명',
        align: 'center',
        render: ({ modifierName, modifierCompanyName }) =>
          modifierName ? `${modifierName} (${modifierCompanyName})` : '',
      },
      { title: '입력 일시', dataIndex: 'updateDate', align: 'center' },
    ],
    [
      onClickDocument,
      fixingDocumentIndex,
      fixDocumentLoading,
      certificate,
      countryId,
    ],
  );

  return (
    <CertificateDetailBlock>
      <StatisticCard>
        <Row wrap={false} gutter={16}>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 6 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 8 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="제품명 (용량)"
              value={`${productDetail.productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 5 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="국가" value={countryName} />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 5 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="제조사"
              value={productDetail?.manufacturerNameKo}
            />
          </Col>
        </Row>
      </StatisticCard>
      <Table
        columns={columns}
        rowKey={({ documentCode }) => documentCode}
        dataSource={isChina ? normalDocuments : documents}
        pagination={false}
        loading={fetchLoading}
        style={{ marginTop: 24 }}
        scroll={{ x: 576 }}
      />
      {isChina && (
        <CertificateFinalDocumentContainer>
          <Typography.Text type="TITLE_2">최종 심사 자료</Typography.Text>
          <Typography.Text
            type="BODY_2"
            style={{ color: palette.GRAY70, marginTop: '5px' }}
          >
            식약국 심사에 필요한 최종 자료로, 인증 진행 담당자의 안내 이후에
            입력해 주세요.
          </Typography.Text>
          <Table
            columns={columns}
            rowKey={({ documentCode }) => documentCode}
            dataSource={finalDocuments}
            pagination={false}
            loading={fetchLoading}
            style={{ marginTop: 24 }}
            scroll={{ x: 576 }}
          />
        </CertificateFinalDocumentContainer>
      )}
    </CertificateDetailBlock>
  );
};

export default CertificateDetail;
