import CertificateDocumentsContainer from 'containers/certificate/CertificateDocumentsContainer';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '인증 진행에 사용된 서류 파일입니다.',
  '(주)CDRI 를 통해 해외인허가 인증 용도로 발행된 서류입니다. 용도 외 사용은 금지해 주세요.',
  '화장품책임판매업자의 품질서류관리 및 보관 의무 [화장품법 시행규칙 제 12조] 이행을 위해 하단의 서류들을 보관하시기 바랍니다.',
];

const CertificateDocumentsPage = () => {
  return (
    <PageTemplate title="인증 완료 정보" notices={notices}>
      <CertificateDocumentsContainer />
    </PageTemplate>
  );
};

export default CertificateDocumentsPage;
