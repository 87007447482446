import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { DocumentCode } from 'types/product';

const notices = [
  '원료사에서 제공받은 파일을 집(.zip)파일로 압축해서 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const ProductRawMaterialCoAPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Raw Material CoA"
      notices={notices}
      exampleImg="Raw_Material_CoA.jpg"
    >
      <ProductSingleFileContainer
        documentCode={DocumentCode.RMCOA}
        accept=".zip"
      />
    </ProductPageTemplate>
  );
};

export default ProductRawMaterialCoAPage;
