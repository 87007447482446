import { Modal, message } from 'antd';
import moment from 'moment';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { Typography } from 'components/system';
import { useModal } from 'hook/useModal';
import client from 'lib/api/client';
import * as productApi from 'lib/api/product';
import { base64ToFile, createFormData, downloadFile } from 'lib/file';
import history from 'lib/history';
import path from 'lib/path';
import * as certificateActions from 'modules/certificate';
import * as productActions from 'modules/product';
import { APIResponse } from 'types/common';
import {
  AddProductAnnex14Params,
  AddProductArtworkParams,
  AllDocumentCodeType,
  DocumentCode,
  PeriodOnMarkets,
  Product,
  ProductAddParams,
  ProductAnnex14Item,
  ProductArtworks,
  ProductCountryArtwork,
  ProductEtcData,
  ProductEtcDatas,
  ProductFormulaBreakdownChinaDraft,
  ProductFormulaBreakdownChinaItem,
  ProductFormulaBreakdownDraft,
  ProductFormulaBreakdownItem,
  ProductSearchType,
  ProductStatus,
  ProductUpdateParams,
  UpdateProductAnnex14Params,
} from 'types/product';
import { apiUrls, useBasicMutation, useBasicQuery } from './core';

export interface ProdctDocStatusAddParams {
  isSkip?: boolean;
  documentCode: string;
  productId: number;
  countryId?: number;
  documentFile?: File | { name: string; url: string };
}

export interface ProductInformation {
  productId: number;
  productDetailId: number;
  brandNameEn: string;
  brandNameEnMeaning: string;
  countryBrandName: string;
  countryBrandNameMeaning: string;
  productNameEn: string;
  countryProductName: string;
  productTrademarkCertificateChinas: ProductTrademarkCertificateChina[];
}

export interface UpdateProductInformationParams {
  productId: number;
  productDetail: {
    productDetailId: number;
    brandNameEnMeaning: string;
    countryBrandName?: string;
    countryBrandNameMeaning?: string;
    countryProductName: string;
  };
  productTrademarkCertificateChinas: {
    productTrademarkCertificateChinaId?: number;
    file?: File;
  }[];
}

export interface ProductMaterialData {
  productMaterialDataId: number;
  filename: string;
  uploadFileUrl: string;
}

export interface ProductTrademarkCertificateChina {
  productTrademarkCertificateChinaId: number;
  filename: string;
  uploadFileUrl: string;
}

export interface UpdateProductMaterialDataParams {
  productId: number;
  countryId: number;
  productMaterials: {
    productMaterialDataId?: number;
    file?: File;
  }[];
}

export interface ProductQuarantineSamples {
  productQuarantineSampleId: number;
  productId: number;
  lotNo: string;
  manufacturingDate: string;
  expiryDate: string;
  frequencyOfUse: number;
  shelfLife: number;
  frequencyPeriodOfUse: FrequencyPeriodOfUseType;
}

export enum FrequencyPeriodOfUseType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface AddProductQuarantineSamplesParams {
  countryId: number;
  lotNo: string;
  manufacturingDate: string;
  expiryDate: string;
  frequencyOfUse: number;
  frequencyPeriodOfUse: FrequencyPeriodOfUseType;
  shelfLife: number;
}

export type UpdateProductQuarantineSamplesParams = AddProductQuarantineSamplesParams;

export interface ProductConsignmentProcessingAgreement {
  productConsignmentProcessingAgreementId: number;
  templateUrl: string | null;
  templateFilename: string | null;
  productId: number;
  filename: string;
  uploadFileUrl: string;
}

export interface UpdateProductConsignmentProcessingAgreementParams {
  countryId: number;
  productConsignmentProcessingAgreement: {
    file: File;
  };
}

const queryKeys = {
  useProducts: 'product/useProducts',
  useProductDocStatus: 'product/useProductDocStatus',
  useProductFormulaBreakdown: 'product/useProductFormulaBreakdown',
  usePeriodOnMarkets: 'product/usePeriodOnMarkets',
  useProductInformation: 'product/useProductInformation',
  useProductMaterialData: 'product/useProductMaterialData',
};

export const useProductBasic = () => {
  const {
    mutate: addProductBasic,
    isLoading: addProductBasicLoading,
  } = useMutation((productAddParams: ProductAddParams) =>
    client.post('/v2/product', productAddParams),
  );

  const {
    mutate: updateProductBasic,
    isLoading: updateProductBasicLoading,
  } = useMutation((productUpdateParams: ProductUpdateParams) =>
    client.patch('/v1/product', productUpdateParams),
  );

  return {
    addProductBasic,
    addProductBasicLoading,
    updateProductBasic,
    updateProductBasicLoading,
  };
};

export const useProductCountry = () => {
  const {
    mutate: getProductCountries,
    isLoading: getProductCountriesLoading,
  } = useMutation((productId: number) =>
    client.get(`/product-countries/${productId}`),
  );

  const {
    mutate: addProductCountry,
    mutateAsync: addProductCountryAsync,
    isLoading: addProductCountryLoading,
  } = useMutation((body: { productId: number; countryIds: number[] }) =>
    client.post('/product-country', body),
  );

  const {
    mutate: deleteProductCountry,
    mutateAsync: deleteProductCountryAsync,
    isLoading: deleteProductCountryLoading,
  } = useMutation((productCountryIds: number[]) =>
    client.delete(
      `/product-country?${qs.stringify(
        { productCountryIds },
        { indices: false },
      )}`,
    ),
  );

  return {
    getProductCountries,
    getProductCountriesLoading,
    addProductCountry,
    addProductCountryAsync,
    addProductCountryLoading,
    deleteProductCountry,
    deleteProductCountryAsync,
    deleteProductCountryLoading,
  };
};

export const useProducts = ({
  status = null,
  size = 10,
  page,
  searchType,
  searchKeyword,
}: {
  status?: ProductStatus | null;
  page: number;
  size?: number;
  searchType: ProductSearchType;
  searchKeyword: string;
}) => {
  const {
    data: { content: products, totalElements } = {
      content: [],
      totalElements: 0,
    },
    isFetching: getLoading,
    refetch,
  } = useQuery(
    [queryKeys.useProducts, page, searchType, searchKeyword],
    () =>
      productApi.getProducts({
        page,
        size,
        searchType,
        searchKeyword,
        status,
      }),
    { select: (res) => res.data.result },
  );
  const { mutate: deleteProductMutation } = useMutation(
    (productId: number) => productApi.deleteProducts([productId]),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  const getProductIsCertificated = useGetProductIsCertificated();
  const { openAlertModal } = useModal();
  const deleteProduct = async (productId: number) => {
    const isCertificated = await getProductIsCertificated(productId);
    if (!isCertificated) {
      if (status === ProductStatus.PRD_RDY) {
        openAlertModal({
          content: (
            <Typography.Text type="BODY_2" align="center">
              해당 리스트에서 제품을 삭제할 경우
              <br /> [국내 입점 서류 관리] 메뉴에
              <br /> 등록된 제품도 함께 삭제됩니다.
              <br /> 정말로 삭제하시겠습니까?
            </Typography.Text>
          ),
          onOk: () => deleteProductMutation(productId),
        });
      } else {
        const product = products.find(
          (product) => product.productId === productId,
        )!;
        openAlertModal({
          content: (
            <>
              다음 품목의 제품 정보가 완전히 삭제됩니다.
              <Typography.Text
                align="center"
                style={{ fontSize: '14px', fontWeight: 500 }}
              >
                {product.productDetail.productNameEn} /{' '}
                {product.productCountries![0].country.countryNameKo}
                {product.productCountries!.length > 1
                  ? ` 외 ${product.productCountries!.length - 1}개 국가`
                  : ''}
              </Typography.Text>
              <br />
              정말 삭제하시겠습니까?
            </>
          ),
          onOk: () => deleteProductMutation(productId),
        });
      }
    } else {
      Modal.info({
        icon: null,
        closable: true,
        maskClosable: true,
        content: (
          <Typography.Text type="BODY_2" style={{ textAlign: 'center' }}>
            인증 진행 혹은 인증 완료 제품은 삭제하실 수 없습니다.
            <br />
            (단, 수정은 가능합니다.)
          </Typography.Text>
        ),
      });
    }
  };

  const dispatch = useDispatch();

  const showProduct = useCallback((product: Product) => {
    const { productId } = product;
    // 정책 : 코드로 발행한 제품 중 기본정보가 입력 완료가 아닌 경우 기본 정보 입력 페이지로 바로 이동
    if (!product.productDetail.productNameEn) {
      history.push(`/product/${productId}/${DocumentCode.BASIC}`);
      return;
    }
    // 정책 : 중복된 제품의 카테고리가 입력전이면 카테고리 등록 페이지로 바로 이동
    if (!product.productCategory) {
      dispatch(productActions.setSkipMode(true));
      dispatch(productActions.setNextPathname('cat'));
      history.push(`/product/${productId}/${DocumentCode.CAT}`);
      return;
    }
    // 정책 : 중복된 제품의 국가가 입력전이면 국가 등록 페이지로 바로 이동
    if (!product.productCountries) {
      dispatch(productActions.setSkipMode(true));
      dispatch(productActions.setNextPathname('country'));
      history.push(`/product/${productId}/${DocumentCode.COUNTRY}`);
      return;
    }
    history.push(`${path.product.root}/${product.productId}`);
  }, []);

  return useMemo(
    () => ({
      products,
      totalElements,
      getLoading,
      deleteProduct,
      showProduct,
    }),
    [products, totalElements, getLoading, deleteProduct, showProduct],
  );
};

export const useGetProductIsCertificated = () => {
  const { mutateAsync: getProductIsCertificated } = useMutation(
    async (productId: number) => {
      const res = await productApi.getProductIsCertificated(productId);
      return res.data.result;
    },
  );

  return getProductIsCertificated;
};

export const useProductDocStatus = ({
  productId,
  countryId,
  documentCode,
}: {
  productId?: number;
  countryId?: number;
  documentCode?: AllDocumentCodeType;
}) => {
  const dispatch = useDispatch();

  const { data: productDocStatus = null } = useQuery(
    [queryKeys.useProductDocStatus, productId, countryId, documentCode],
    () =>
      productApi.getProductDocStatus({
        productId: productId!,
        countryId,
        documentCode: documentCode!,
      }),
    {
      select: (res) => res.data.result,
      suspense: true,
      enabled:
        typeof productId !== 'undefined' && typeof documentCode !== 'undefined',
      cacheTime: 0,
    },
  );

  const {
    mutate: addProdctDocStatus,
    isLoading: addProdctDocStatusLoading,
  } = useMutation(
    ({
      isSkip = false,
      documentCode,
      productId,
      countryId,
      documentFile,
    }: ProdctDocStatusAddParams) =>
      client.post(
        `/products/${productId}/doc-statuses/${documentCode.toUpperCase()}01`,
        createFormData({
          isSkip,
          ...(documentFile && { documentFile }),
          ...(countryId && { countryId }),
        }),
      ),
  );

  const {
    mutate: updateProdctDocStatus,
    isLoading: updateProdctDocStatusLoading,
  } = useMutation(
    ({
      isSkip = false,
      documentCode,
      productId,
      countryId,
      documentFile,
    }: ProdctDocStatusAddParams) =>
      client.post(
        `/products/${productId}/doc-statuses/${documentCode.toUpperCase()}01`,
        createFormData({
          isSkip,
          ...(documentFile && { documentFile }),
          ...(countryId && { countryId }),
        }),
      ),
    {
      onSuccess: () => {
        if (productDocStatus?.status === 'MOD') {
          dispatch(
            certificateActions.fixDocument(productDocStatus.productDocStatusId),
          );
          message.success('보완 완료되었습니다.');
        } else {
          message.success('수정되었습니다.');
        }
        history.goBack();
      },
    },
  );

  return {
    productDocStatus,
    addProdctDocStatus,
    addProdctDocStatusLoading,
    updateProdctDocStatus,
    updateProdctDocStatusLoading,
  };
};

export const useGetProductDocStatus = () => {
  const { mutate: getProductDocStatus } = useMutation(
    ({
      productId,
      countryId,
      documentCode,
    }: {
      productId: number;
      countryId: number;
      documentCode?: DocumentCode;
    }) =>
      productApi.getProductDocStatus({
        productId,
        countryId,
        documentCode: documentCode!,
      }),
  );

  return getProductDocStatus;
};

const calculateFormulaBreakdownRowSpan = (
  formulaBreakdownItems:
    | ProductFormulaBreakdownItem[]
    | ProductFormulaBreakdownChinaItem[],
) => {
  const targetKeys: ['no', 'formulaWt'] = ['no', 'formulaWt'];
  let noSyncStack: number[] = [];
  let rowSpanAcc = 1;

  targetKeys.forEach((targetKey) => {
    for (let i = formulaBreakdownItems.length - 1; i >= 0; i--) {
      if (formulaBreakdownItems[i][targetKey] === null) {
        rowSpanAcc++;
        noSyncStack.push(i);
      } else {
        switch (targetKey) {
          case 'no': {
            formulaBreakdownItems[i].noRowSpan = rowSpanAcc;
            noSyncStack.forEach((nullIndex) => {
              formulaBreakdownItems[nullIndex].noSync =
                formulaBreakdownItems[i].no;
            });
            noSyncStack = [];
            break;
          }
          case 'formulaWt': {
            formulaBreakdownItems[i].formulaWtRowSpan = rowSpanAcc;
            break;
          }
        }
        rowSpanAcc = 1;
      }
    }
  });
};

export const useProductFormulaBreakdown = (
  productId: number,
  countryId?: number,
) => {
  const [
    productFormulaBreakdownDraft,
    setProductFormulaBreakdownDraft,
  ] = useState<ProductFormulaBreakdownDraft | null>(null);

  const {
    data: formulaBreakdownItems = [],
    isFetching: getLoading,
    refetch,
  } = useQuery(
    [queryKeys.useProductFormulaBreakdown, productId, countryId],
    () =>
      client.get<APIResponse<ProductFormulaBreakdownItem[]>>(
        `/products/${productId}/formula-breakdowns${
          countryId ? `?countryId=${countryId}` : ''
        }`,
      ),
    {
      select: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result);
        return res.data.result;
      },
    },
  );

  const {
    mutate: uploadProductFormulaBreakdown,
    isLoading: uploadLoading,
  } = useMutation(
    (file: File) =>
      client.post<APIResponse<ProductFormulaBreakdownDraft>>(
        `/products/${productId}/formula-breakdowns${
          countryId ? `?countryId=${countryId}` : ''
        }`,
        {
          file,
        },
      ),
    {
      onSuccess: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result.list);
        setProductFormulaBreakdownDraft(res.data.result);
        if (
          !res.data.result.isWtSumError &&
          !res.data.result.isSubstanceSumError &&
          res.data.result.uploadedExcelUrl === null
        ) {
          message.success('정상적으로 업로드 되었습니다.');
          refetch();
        } else {
          message.warning('업로드한 파일에 오류가 있습니다.');
        }
      },
      onError: () => {
        setProductFormulaBreakdownDraft(null);
      },
    },
  );

  return useMemo(
    () => ({
      formulaBreakdownItems,
      getLoading,
      uploadProductFormulaBreakdown,
      uploadLoading,
      productFormulaBreakdownDraft,
    }),
    [
      formulaBreakdownItems,
      getLoading,
      uploadLoading,
      productFormulaBreakdownDraft,
    ],
  );
};

export const useProductFormulaBreakdownChina = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const {
    data: formulaBreakdownChinaItems = [],
    isFetching: getLoading,
    refetch,
  } = useQuery(
    ['product/getProductFormulaBreakdownChina', productId],
    () =>
      client.get<APIResponse<ProductFormulaBreakdownChinaItem[]>>(
        `/products/${productId}/product-formula-breakdown-chinas`,
        {
          params: {
            countryId,
          },
        },
      ),
    {
      select: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result);
        return res.data.result;
      },
    },
  );

  const [
    productFormulaBreakdownChinaDraft,
    setProductFormulaBreakdownChinaDraft,
  ] = useState<ProductFormulaBreakdownChinaDraft | null>(null);

  const {
    mutate: uploadProductFormulaBreakdownChina,
    isLoading: uploadLoading,
  } = useMutation(
    (file: File) =>
      client.post<APIResponse<ProductFormulaBreakdownChinaDraft>>(
        `/products/${productId}/product-formula-breakdown-chinas`,
        {
          file,
        },
        {
          params: {
            countryId,
          },
        },
      ),
    {
      onSuccess: (res) => {
        calculateFormulaBreakdownRowSpan(res.data.result.list);
        setProductFormulaBreakdownChinaDraft(res.data.result);
        if (
          !res.data.result.isWtSumError &&
          !res.data.result.isSubstanceSumError &&
          res.data.result.uploadedExcelUrl === null
        ) {
          message.success('정상적으로 업로드 되었습니다.');
          refetch();
        } else {
          message.warning('업로드한 파일에 오류가 있습니다.');
        }
      },
      onError: () => {
        setProductFormulaBreakdownChinaDraft(null);
      },
    },
  );

  return useMemo(
    () => ({
      formulaBreakdownChinaItems,
      getLoading,
      uploadProductFormulaBreakdownChina,
      uploadLoading,
      productFormulaBreakdownChinaDraft,
    }),
    [
      formulaBreakdownChinaItems,
      getLoading,
      uploadLoading,
      productFormulaBreakdownChinaDraft,
    ],
  );
};

export const useProductArtwork = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const {
    data: productCountryArtworks,
    isLoading: isProductCountryArtworksLoading,
  } = useBasicQuery<ProductCountryArtwork>({
    apiUrl: apiUrls.productArtworks,
    urlParams: { productId },
    qs: { countryId },
  });
  const {
    mutate: addProductArtwork,
    isLoading: addProductArtworkLoading,
  } = useBasicMutation<AddProductArtworkParams>({
    apiUrl: apiUrls.productArtworks,
    useFormData: true,
    method: 'post',
    urlParams: { productId },
  });

  return {
    addProductArtwork,
    addProductArtworkLoading,
    productCountryArtworks,
    isProductCountryArtworksLoading,
  };
};

export const useManufacturerDeclarations = () => {
  const {
    mutate: createManufacturerDeclaration,
    isLoading: isCreateManufacturerDeclarationLoading,
  } = useMutation(
    ({
      productId,
      countryId,
      file,
    }: {
      productId: number;
      countryId?: number;
      file: File;
    }) =>
      client.post(`/products/${productId}/manufacturer-declarations`, {
        countryId,
        file,
      }),
  );

  const {
    mutate: updateManufacturerDeclaration,
    isLoading: isUpdateManufacturerDeclarationLoading,
  } = useMutation(
    ({
      productId,
      countryId,
      file,
    }: {
      productId: number;
      countryId?: number;
      file: File;
    }) =>
      client.post(`/products/${productId}/manufacturer-declarations/update`, {
        countryId,
        file,
      }),
  );

  return {
    createManufacturerDeclaration,
    isCreateManufacturerDeclarationLoading,
    updateManufacturerDeclaration,
    isUpdateManufacturerDeclarationLoading,
  };
};

export const useStatementOfMicrobiologicalPurities = () => {
  const {
    mutate: createStatementOfMicrobiologicalPurities,
    isLoading: isCreateStatementOfMicrobiologicalPuritiesLoading,
  } = useMutation(
    ({
      productId,
      countryId,
      file,
    }: {
      productId: number;
      countryId: number;
      file: File;
    }) =>
      client.post(
        `/products/${productId}/statement-of-microbiological-purities`,
        {
          countryId,
          file,
        },
      ),
  );

  const {
    mutate: updateStatementOfMicrobiologicalPurities,
    isLoading: isUpdateStatementOfMicrobiologicalPuritiesLoading,
  } = useMutation(
    ({
      productId,
      countryId,
      file,
    }: {
      productId: number;
      countryId: number;
      file: File;
    }) =>
      client.post(
        `/products/${productId}/statement-of-microbiological-purities/update`,
        {
          countryId,
          file,
        },
      ),
  );

  return {
    createStatementOfMicrobiologicalPurities,
    isCreateStatementOfMicrobiologicalPuritiesLoading,
    updateStatementOfMicrobiologicalPurities,
    isUpdateStatementOfMicrobiologicalPuritiesLoading,
  };
};

export const usePeriodOnMarkets = ({
  productId,
  countryId = 0,
}: {
  productId: number;
  countryId?: number;
}) => {
  const {
    data: periodOnMarkets,
    isLoading: isPeriodOnMarketsLoading,
  } = useQuery(
    [queryKeys.usePeriodOnMarkets, productId, countryId],
    () =>
      client.get<APIResponse<PeriodOnMarkets>>(
        `/products/${productId}/period-on-markets`,
        {
          params: {
            countryId,
          },
        },
      ),
    {
      enabled: countryId > 0,
      select: (res) => res.data.result,
      suspense: true,
    },
  );

  const {
    mutate: createPeriodOnMarkets,
    isLoading: isCreatePeriodOnMarketsLoading,
  } = useMutation(
    ({
      productId,
      countryId,
      file,
      productLauncherYear,
      productLauncherMonth,
      productSalePeriod,
      productSalePeriodUnit,
    }: {
      productId: number;
      countryId: number;
      file: File;
      productLauncherYear: number;
      productLauncherMonth: number;
      productSalePeriod: number;
      productSalePeriodUnit: string;
    }) =>
      client.post(`/products/${productId}/period-on-markets`, {
        countryId,
        file,
        productLauncherYear,
        productLauncherMonth,
        productSalePeriod,
        productSalePeriodUnit,
      }),
  );

  const {
    mutate: updatePeriodOnMarkets,
    isLoading: isUpdatePeriodOnMarketsLoading,
  } = useMutation(
    ({
      productId,
      countryId,
      file,
      productLauncherYear,
      productLauncherMonth,
      productSalePeriod,
      productSalePeriodUnit,
    }: {
      productId: number;
      countryId: number;
      file?: File;
      productLauncherYear: number;
      productLauncherMonth: number;
      productSalePeriod: number;
      productSalePeriodUnit: string;
    }) =>
      client.post(
        `/products/${productId}/period-on-markets/update`,
        createFormData({
          countryId,
          file,
          productLauncherYear,
          productLauncherMonth,
          productSalePeriod,
          productSalePeriodUnit,
        }),
      ),
  );

  return {
    periodOnMarkets,
    isPeriodOnMarketsLoading,
    createPeriodOnMarkets,
    isCreatePeriodOnMarketsLoading,
    updatePeriodOnMarkets,
    isUpdatePeriodOnMarketsLoading,
  };
};

// Manufacturer's Declarations
export const useMDDocumentForm = () => {
  const { mutate: downloadMDDocumentForm, isLoading } = useMutation(
    ({ productId, countryId }: { productId: number; countryId?: number }) =>
      client.post<APIResponse<ArrayBuffer>>(
        `/v1/products/${productId}/manufacturer-declarations/document-form`,
        undefined,
        {
          params: {
            countryId,
          },
        },
      ),
    {
      onSuccess: (res) => {
        const file = base64ToFile(
          `data:application/pdf;base64,${res.data.result}`,
          'Manufacturers Declarations.pdf',
        );
        downloadFile(file);
      },
    },
  );

  return {
    downloadMDDocumentForm,
    isLoading,
  };
};

// Statement Of Microbiological Purity
export const useSMPDocumentForm = () => {
  const { mutate: downloadMDDocumentForm, isLoading } = useMutation(
    ({ productId, countryId }: { productId: number; countryId?: number }) =>
      client.get<APIResponse<ArrayBuffer>>(
        `/v1/products/${productId}/statement-of-microbiological-purities/document-form`,
        {
          params: {
            countryId,
          },
        },
      ),
    {
      onSuccess: (res) => {
        const file = base64ToFile(
          `data:application/pdf;base64,${res.data.result}`,
          'Statement Of Microbiological Purity.pdf',
        );
        downloadFile(file);
      },
    },
  );

  return {
    downloadMDDocumentForm,
    isLoading,
  };
};

// Period On Markets
export const usePOMDocumentForm = () => {
  const { mutate: downloadPOMDocumentForm, isLoading } = useMutation(
    ({
      productId,
      countryId,
      productLauncherYear,
      productLauncherMonth,
      productSalePeriod,
      productSalePeriodUnit,
    }: {
      productId: number;
      countryId?: number;
      productLauncherYear: number;
      productLauncherMonth: number;
      productSalePeriod: number;
      productSalePeriodUnit: string;
    }) =>
      client.get<APIResponse<ArrayBuffer>>(
        `/v1/products/${productId}/period-on-markets/document-form`,
        {
          params: {
            countryId,
            productLauncherYear,
            productLauncherMonth,
            productSalePeriod,
            productSalePeriodUnit,
          },
        },
      ),
    {
      onSuccess: (res) => {
        const file = base64ToFile(
          `data:application/pdf;base64,${res.data.result}`,
          'Period On Markets.pdf',
        );
        downloadFile(file);
      },
    },
  );

  return {
    downloadPOMDocumentForm,
    isLoading,
  };
};

export const useDownloadCompletionDocumentFiles = () => {
  const { mutate: downloadCompletionDocumentFiles, isLoading } = useMutation(
    ({
      productId,
      countryId,
      documentCode,
    }: {
      productId: number;
      countryId?: number;
      documentCode: string;
    }) =>
      client.get<APIResponse<{ documentUrl: string }>>(
        `/v1/products/${productId}/completion-document-files/${documentCode}`,
        {
          params: {
            countryId,
          },
        },
      ),
    {},
  );

  return {
    downloadCompletionDocumentFiles,
    isLoading,
  };
};

export const useProductArtworkList = () => {
  const {
    mutate: getProductArtworkList,
    isLoading,
  } = useMutation(
    ({ productId, countryId }: { productId: number; countryId?: number }) =>
      client.get<APIResponse<ProductArtworks>>(
        `/products/${productId}/product-artworks${
          countryId ? `?countryId=${countryId}` : ''
        }`,
      ),
  );

  return {
    getProductArtworkList,
    isLoading,
  };
};

export const useProductInformation = ({
  productId,
  productDetailId,
}: {
  productId: number;
  productDetailId?: number;
}) => {
  const {
    data: productInformation,
    isLoading: isGetProductInformationLoading,
  } = useBasicQuery<ProductInformation>({
    apiUrl: apiUrls.productInformation,
    urlParams: { productId },
    qs: {
      productDetailId,
    },
    option: {
      enabled: productId !== undefined && productDetailId !== undefined,
    },
  });

  const {
    mutate: updateChinaProductInformation,
    isLoading: isUpdateChinaProductInformationLoading,
  } = useBasicMutation<UpdateProductInformationParams>({
    apiUrl: apiUrls.productInformation,
    urlParams: { productId },
    useFormData: true,
    refetchUrl: apiUrls.productInformation,
  });

  return {
    productInformation,
    isGetProductInformationLoading,
    updateChinaProductInformation,
    isUpdateChinaProductInformationLoading,
  };
};

export const useProductMaterialData = ({
  productId,
}: {
  productId?: number;
}) => {
  const [initialData] = useState([]);

  const {
    data: productMaterialData = initialData,
    isLoading: isGetProductMaterialDataLoading,
  } = useBasicQuery<ProductMaterialData[]>({
    apiUrl: apiUrls.productMaterialData,
    qs: {
      productId,
    },
    option: {
      enabled: productId !== undefined,
    },
  });

  const {
    mutate: updateProductMaterialData,
    isLoading: isUpdateProductMaterialDataLoading,
  } = useBasicMutation<UpdateProductMaterialDataParams>({
    apiUrl: apiUrls.productMaterialData,
    useFormData: true,
    refetchUrl: apiUrls.productMaterialData,
  });

  return {
    productMaterialData,
    isGetProductMaterialDataLoading,
    updateProductMaterialData,
    isUpdateProductMaterialDataLoading,
  };
};

export const useProductQuarantineSamples = ({
  productId,
  productQuarantineSampleId,
}: {
  productId: number;
  productQuarantineSampleId?: number;
}) => {
  const {
    data: productQuarantineSamples,
    isLoading: isGetProductQuarantineSamplesLoading,
  } = useBasicQuery<ProductQuarantineSamples>({
    apiUrl: apiUrls.productQuarantineSamples,
    urlParams: { productId },
  });

  const {
    mutate: addProductQuarantineSamples,
    isLoading: isAddProductQuarantineSamplesLoading,
  } = useBasicMutation<AddProductQuarantineSamplesParams>({
    apiUrl: apiUrls.productQuarantineSamples,
    urlParams: { productId },
    refetchUrl: apiUrls.productQuarantineSamples,
  });

  const {
    mutate: updateProductQuarantineSamples,
    isLoading: isUpdateProductQuarantineSamplesLoading,
  } = useBasicMutation<UpdateProductQuarantineSamplesParams>({
    apiUrl: apiUrls.updateProductQuarantineSamples,
    method: 'patch',
    urlParams: productQuarantineSampleId
      ? { productId, productQuarantineSampleId }
      : undefined,
    refetchUrl: apiUrls.productQuarantineSamples,
  });

  return {
    productQuarantineSamples,
    isGetProductQuarantineSamplesLoading,
    addProductQuarantineSamples,
    isAddProductQuarantineSamplesLoading,
    updateProductQuarantineSamples,
    isUpdateProductQuarantineSamplesLoading,
  };
};

export const useProductPCPA = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const {
    data: productPCPA,
    isLoading: isGetProductPCPALoading,
  } = useBasicQuery<ProductConsignmentProcessingAgreement>({
    apiUrl: apiUrls.productConsignmentProcessingAgreements,
    urlParams: {
      productId,
    },
    option: {
      enabled: productId !== undefined,
    },
  });

  const {
    mutate: updateProductPCPA,
    isLoading: isUpdateProductPCPALoading,
  } = useBasicMutation<UpdateProductConsignmentProcessingAgreementParams>({
    apiUrl: apiUrls.productConsignmentProcessingAgreements,
    urlParams: {
      productId,
    },
    useFormData: true,
    refetchUrl: apiUrls.productConsignmentProcessingAgreements,
  });

  // PCPA 서류양식 조회
  const {
    mutate: pcpaFileData,
    isLoading: isPcpaFileDataLoading,
  } = useMutation(
    ({ productId, countryId }: { productId: number; countryId: number }) =>
      client.get(
        `/files/pcpa/products/${productId}/pdf?countryId=${countryId}`,
        {
          responseType: 'blob',
        },
      ),
  );

  return {
    productPCPA,
    isGetProductPCPALoading,
    updateProductPCPA,
    isUpdateProductPCPALoading,
    pcpaFileData,
    isPcpaFileDataLoading,
  };
};

export const useProductEtcDatas = (productId: number) => {
  const MB = 10 ** 6;
  const [etcDatas, setEtcDatas] = useState<ProductEtcData[]>([]);
  const [addedEtcDatas, setAddedEtcDatas] = useState<File[]>([]);
  const [deletedEtcDatas, setDeletedEtcDatas] = useState<number[]>([]);

  const {
    data: {
      productEtcDatas: originEtcDatas = [],
      totalFileSize: originalTotalFileSize = 0,
    } = {},
    isLoading: getLoading,
  } = useBasicQuery<ProductEtcDatas>({
    apiUrl: apiUrls.productEtcDatas,
    urlParams: { productId },
  });

  const {
    mutate: addProductEtcDatas,
    isLoading: addLoading,
  } = useBasicMutation<{ files: File[] }>({
    apiUrl: apiUrls.productEtcDatas,
    urlParams: { productId },
    useFormData: true,
    refetchUrl: apiUrls.productEtcDatas,
    option: {
      onSuccess: () => {
        setAddedEtcDatas([]);
      },
    },
  });

  const {
    mutate: deleteProductEtcDatas,
    isLoading: deleteLoading,
  } = useBasicMutation<{ productEtcDataIds: number[] }>({
    apiUrl: apiUrls.productEtcDatas,
    urlParams: { productId },
    method: 'delete',
    refetchUrl: apiUrls.productEtcDatas,
  });

  const updateMode = originEtcDatas.length > 0;

  const totalFileSize = useMemo(() => {
    return etcDatas.reduce((acc, curr) => acc + curr.fileSize, 0);
  }, [etcDatas, originalTotalFileSize]);

  const isFileSizeExceed = totalFileSize > 100 * MB;

  const addEtcData = useCallback(
    (file: File) => {
      if (file.size > 100 * MB) {
        return message.warn(`${file.name} : 100 MB 초과`);
      }
      if (etcDatas.some(({ filename }) => filename === file.name)) {
        return message.warn(`${file.name} : 이미 업로드한 파일`);
      }
      setAddedEtcDatas((addedEtcDatas) => [...addedEtcDatas, file]);
      setEtcDatas((draftEtcDatas) =>
        draftEtcDatas.concat({
          file: file,
          filename: file.name,
          fileSize: file.size,
          registerDt: moment().format('YYYY-MM-DD'),
        }),
      );
    },
    [etcDatas],
  );

  const deleteFile = useCallback(
    (index: number) => {
      const deletedFileId = etcDatas[index].productEtcDataId || undefined;

      if (deletedFileId === undefined) {
        setAddedEtcDatas((addedEtcDatas) =>
          addedEtcDatas.filter((_, i) => i !== index),
        );
      } else {
        setDeletedEtcDatas((deletedEtcDatas) => [
          ...deletedEtcDatas,
          deletedFileId,
        ]);
      }

      setEtcDatas((draftEtcDatas) =>
        draftEtcDatas.filter((_, i) => i !== index),
      );
    },
    [etcDatas],
  );

  useEffect(() => {
    if (originEtcDatas.length > 0) {
      setEtcDatas(originEtcDatas);
    }
  }, [originEtcDatas]);

  return {
    updateMode,
    etcDatas,
    addedEtcDatas,
    deletedEtcDatas,
    totalFileSize,
    isFileSizeExceed,
    getLoading,
    addEtcData,
    deleteFile,
    addProductEtcDatas,
    deleteProductEtcDatas,
    saveLoading: addLoading || deleteLoading,
  };
};

//Annex
export const useProductAnnex14 = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const {
    data: productAnnex14Data,
    isLoading: isProductAnnex14DataLoading,
  } = useBasicQuery<ProductAnnex14Item[]>({
    apiUrl: apiUrls.productAnnex14Data,
    urlParams: { productId },
    qs: { countryId },
  });

  const {
    mutate: addProductAnnex14Data,
    isLoading: isAddProductAnnex14DataLoading,
  } = useBasicMutation<AddProductAnnex14Params>({
    apiUrl: apiUrls.productAnnex14Data,
    method: 'post',
    urlParams: { productId },
    useFormData: true,
    refetchUrl: apiUrls.productAnnex14Data,
  });

  const {
    mutate: updateProductAnnex14Data,
    isLoading: isUpdateProductAnnex14DataLoading,
  } = useBasicMutation<UpdateProductAnnex14Params>({
    apiUrl: apiUrls.updateProductAnnex14Data,
    method: 'post',
    urlParams: { productId },
    useFormData: true,
    refetchUrl: apiUrls.productAnnex14Data,
  });

  return {
    productAnnex14Data,
    isProductAnnex14DataLoading,
    addProductAnnex14Data,
    isAddProductAnnex14DataLoading,
    updateProductAnnex14Data,
    isUpdateProductAnnex14DataLoading,
  };
};
