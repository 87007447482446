import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { useMutation, useQuery } from 'react-query';

import { messages } from 'lib/consts';
import { CountryProductName, SeriesProductInfo } from 'types/certificate';
import { getUpdatingObject } from 'lib/form';
import * as certificateApi from 'lib/api/certificate';
import * as certificateActions from 'modules/certificate';
import { useCurrentProduct } from '../product/product';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';

export const useGetDuplicatedProductName = (productId: number) => {
  const { mutate: getDuplicatedProductName } = useMutation(
    (params: {
      productNameEn: string;
      netWeight: number;
      netWeightUnit: string;
    }) =>
      client.get<APIResponse<{ isDuplicate: boolean }>>(
        `/products/${productId}/duplicate-check`,
        {
          params,
        },
      ),
  );

  return { getDuplicatedProductName };
};

export const useCountryProductName = () => {
  const [form] = Form.useForm<CountryProductName>();
  const { productDetail } = useCurrentProduct();

  const { estimateTarget, readOnlyMode, status } = useSelector(
    ({ certificate }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
      status: certificate.documents.find(
        (doc: any) => doc.documentCode === 'pn',
      )?.status,
    }),
    shallowEqual,
  );

  const { productId, countryId, netWeight, netWeightUnit } = estimateTarget;

  const { getDuplicatedProductName } = useGetDuplicatedProductName(productId);

  const onSubmit = useCallback(
    (
      formValues: CountryProductName,
      onSuccess: (params: {
        countryBrandName?: string | undefined;
        productNameEn?: string | undefined;
        countryProductName?: string | undefined;
        productId: any;
        countryId: any;
      }) => void,
    ) => {
      const updatingObject = getUpdatingObject(formValues, productDetail);
      if (!updatingObject) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
      const params = { productId, countryId, ...updatingObject };

      if (updatingObject.productNameEn) {
        getDuplicatedProductName(
          {
            productNameEn: updatingObject.productNameEn,
            netWeight,
            netWeightUnit,
          },
          {
            onSuccess: ({
              data: {
                result: { isDuplicate },
              },
            }) => {
              if (isDuplicate) {
                message.error(
                  '이미 사용중인 제품명입니다. 다른 제품명을 사용하시거나 고객센터로 문의해주세요.',
                );
                return;
              }
              onSuccess(params);
            },
          },
        );

        return;
      }

      onSuccess(params);
    },
    [productDetail],
  );

  useEffect(() => {
    if (productDetail) {
      form.setFieldsValue({
        countryBrandName: productDetail.countryBrandName,
        productNameEn: productDetail.productNameEn,
        countryProductName: productDetail.countryProductName,
      });
    }
  }, [productDetail]);

  return useMemo(
    () => ({
      form,
      status,
      productId,
      countryId,
      readOnlyMode,
      onSubmit,
    }),
    [form, readOnlyMode, readOnlyMode, status, onSubmit],
  );
};

export const useSeriesProductInfo = (
  productId: number,
  countryId: number,
  certTargetId: number,
) => {
  const dispatch = useDispatch();

  const currentDocStatus = useSelector(
    ({ product }: any) => product.currentDocStatus,
  );
  const { data: isSeriesProduct = null, isFetching: getLoading } = useQuery(
    [`products/${productId}/series-info`, countryId],
    () => certificateApi.getSeriesInfo({ productId, countryId }),
    {
      select: (res) => res.data.result.isSeriesProduct,
      enabled: productId !== undefined,
    },
  );
  const { mutate: updateSeriesInfo, isLoading: updateLoading } = useMutation(
    (seriesProduct: SeriesProductInfo) =>
      certificateApi.updateSeriesInfo(seriesProduct),
    {
      onSuccess: () => {
        dispatch(certificateActions.toggleSeriesProductInfoModalVisible());

        if (currentDocStatus && currentDocStatus.status === 'MOD') {
          dispatch(
            certificateActions.fixDocument(currentDocStatus.productDocStatusId),
          );
          message.success('보완 완료되었습니다.');
        } else {
          dispatch(certificateActions.getDocuments(certTargetId));
          message.success('등록되었습니다.');
        }
      },
    },
  );

  return useMemo(
    () => ({
      isSeriesProduct,
      getLoading,
      updateLoading,
      updateSeriesInfo,
    }),
    [isSeriesProduct, getLoading, updateLoading],
  );
};
