import { useHistory } from 'react-router-dom';

import path from 'lib/path';
import { NMPAOngoingPageState } from 'types/material/nmpa/nmpa';
import NMPAOngoing from 'components/material/nmpa/NMPAOngoing';
import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const NMPAOngoingPage = () => {
  const history = useHistory<NMPAOngoingPageState>();
  const pageState = history.location.state || {
    page: 1,
    tradeName: '',
  };

  const handlePageStateChange = (state: NMPAOngoingPageState) => {
    history.replace(path.material.nmpa.ongoing, state);
  };

  return (
    <NMPAPageTemplate
      title="취득 중 원료 관리"
      back={false}
      description="취득 중인 원료를 관리해보세요."
    >
      <NMPAOngoing
        pageState={pageState}
        onChangePageState={handlePageStateChange}
      />
    </NMPAPageTemplate>
  );
};

export default NMPAOngoingPage;
