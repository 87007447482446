import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as checklistApi from 'lib/api/checklist';
import { ChecklistItem } from 'types/brand/checklist';
import { apiUrls } from 'service/core';

export const useChecklist = ({
  productId,
  countryId,
  updateMode,
}: {
  productId: number;
  countryId: number;
  updateMode: boolean;
}) => {
  const { data: originalChecklist = [], isLoading: getLoading } = useQuery(
    ['estimate/getChecklist', productId, countryId],
    () => checklistApi.getChecklist({ productId, countryId }),
    { select: (res) => res.data.result, cacheTime: 0 },
  );
  const [checklist, setChecklist] = useState<ChecklistItem[]>([]);
  const firstItemChecklistId = useMemo(() => {
    const firstItem = originalChecklist.find((item) => item.isStartQuestion);
    return firstItem?.countryChecklistId || -1;
  }, [originalChecklist]);

  const messageGenerator = useMemo(
    () =>
      (() => {
        let no = 0,
          subNo = 0;

        return {
          generateQuestionMsg: (checklist: ChecklistItem) => {
            if (checklist.parentCountryChecklistId) {
              subNo += 1;
            } else {
              no += 1;
              subNo = 0;
            }

            if (subNo >= 1) {
              return `${no}-${subNo}. ${checklist.questionMsg}`;
            } else {
              return `${no}. ${checklist.questionMsg}`;
            }
          },
          init: () => {
            no = subNo = 0;
          },
        };
      })(),
    [originalChecklist],
  );

  const checklistMap = ((checklists: ChecklistItem[]) => {
    const map = new Map<number, ChecklistItem>();
    let _firstItem: ChecklistItem;
    for (const item of checklists) {
      if (item.isStartQuestion) {
        _firstItem = item;
      }
      map.set(item.countryChecklistId, { ...item });
    }

    const _getChecklist = (checklistItem: ChecklistItem, isYes: boolean) => {
      let nextChecklistItem = map.get(
        isYes ? checklistItem.yesNextId : checklistItem.noNextId,
      );

      while (
        nextChecklistItem?.isUseRedirectNextId &&
        nextChecklistItem.redirectNextId
      ) {
        nextChecklistItem = map.get(nextChecklistItem.redirectNextId);
      }

      return structuredClone(nextChecklistItem);
    };

    return {
      getNextChecklist: (param: ChecklistItem | number, isYes: boolean) => {
        return _getChecklist(
          typeof param === 'number' ? (map.get(param) as ChecklistItem) : param,
          isYes,
        );
      },
      getFirstChecklistItem: () => _firstItem,
      getChecklistByCode: (code: 'PARTNER_BUYER') =>
        checklists.find((checklist) => checklist.checklistCode === code),
    };
  })(originalChecklist);

  const resetChecklist = () => {
    const firstItem = checklistMap.getFirstChecklistItem();
    if (!firstItem) return;
    messageGenerator.init();
    const newFirstItem = { ...firstItem };
    newFirstItem.questionMsg = messageGenerator.generateQuestionMsg(
      newFirstItem,
    );
    setChecklist([firstItem]);
  };

  const addChecklistItem = (
    checklistItem: ChecklistItem | number,
    isYes: boolean,
  ) => {
    const nextChecklistItem = checklistMap.getNextChecklist(
      checklistItem,
      isYes,
    );

    if (nextChecklistItem) {
      (nextChecklistItem as any).questionMsg = messageGenerator.generateQuestionMsg(
        nextChecklistItem,
      );
      setChecklist((prevChecklist) => {
        return [...prevChecklist, nextChecklistItem];
      });
    }

    return nextChecklistItem;
  };

  // 체크리스트를 생성
  useEffect(() => {
    if (firstItemChecklistId === -1) return;
    const firstItem = checklistMap.getFirstChecklistItem();

    if (!firstItem) return;
    firstItem.questionMsg = messageGenerator.generateQuestionMsg({
      ...firstItem,
    });
    setChecklist([firstItem]);

    if (!updateMode) {
      return;
    }

    let current: ChecklistItem | undefined = firstItem;

    while (current) {
      let answer = current?.answer;
      current = addChecklistItem(
        current,
        answer?.isYes || answer?.subjectAnswer !== null,
      );
    }
  }, [firstItemChecklistId]);

  return useMemo(
    () => ({
      checklist,
      getLoading,
      resetChecklist,
      addChecklistItem,
      getChecklistByCode: checklistMap.getChecklistByCode,
    }),
    [
      getLoading,
      checklist,
      resetChecklist,
      addChecklistItem,
      checklistMap.getChecklistByCode,
    ],
  );
};

export const useAnswerChecklist = () => {
  const queryClient = useQueryClient();
  const { mutate: answerChecklist, isLoading } = useMutation(
    (params: Parameters<typeof checklistApi.answerChecklist>[0]) =>
      checklistApi.answerChecklist(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.selfEstimateTargets);
      },
    },
  );
  return useMemo(() => ({ answerChecklist, isLoading }), [
    answerChecklist,
    isLoading,
  ]);
};
