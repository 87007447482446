import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductPhysicalProperty from 'components/product/physicalProperty/ProductPhysicalProperty';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useEffect } from 'react';

const notices = [
  'CoA, MSDS,  SPEC 서류를 보고 하단의 정보를 입력해 주세요.',
  <MistakeNoticeItem />,
];

const ProductPhysicalPropertyPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();

  useEffect(() => {
    const rootPageTemplete = document.querySelector(
      '[class*="PageTemplateStyle"]',
    );
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate subtitle="Physical Properties" notices={notices}>
      <ProductPhysicalProperty productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductPhysicalPropertyPage;
