import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { Typography } from 'components/system';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductRNISPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="이슈물질 불검출 성적서"
      notices={[
        <Typography.Text type="BODY_2">
          아래의 항목은 certicos에서 안내드리는 제품 유형별 이슈 물질입니다.
          <br />
          실제 입점시 유통사에서 요청받은 특정 이슈 물질이 있는 경우, 해당 시험
          성적서를 업로드해 주세요.
          <Typography.Text type="SMALL">
            1. 씻어내는 제형, 헤어 제품류, 물티슈 : 메칠이소치아졸리논,
            메칠클로로이소치아졸리논 (CMIT, MIT) 불검출 성적서 <br />
            2. 기초 제품류 및 피부에 흡수시키는 제형 : 트리클로산 불검출 성적서
            (단, 트리클로산이 법적으로 들어간 기능성화장품의 경우 제외) <br />
            3. 색조 제품 및 펄(반짝이)이 들어간 기초 제품류 : 6가크롬 불검출
            성적서
          </Typography.Text>
        </Typography.Text>,
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.RNIS}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductRNISPage;
