import { Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';
import palette from 'lib/styles/palette';
import { ScreeningFormData } from 'pages/brand/formulaScreening/FormulaScreeningPage';
import { useFormulaScreeningIngredient } from 'service/brand/formulaScreening/formulaScreening';

const FormulaIngredientSelect = ({
  field,
  value,
  onChange,
  onPaste,
}: {
  field: FormListFieldData;
  value?: string;
  onChange?: (value: string | undefined) => void;
  onPaste: (
    e: React.ClipboardEvent<HTMLElement>,
    field: FormListFieldData,
    name: keyof ScreeningFormData['ingredients'][number],
  ) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    [],
  );

  const ref = useRef<HTMLInputElement>(null);
  const {
    ingredientsForScreening,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useFormulaScreeningIngredient({ name });

  const finalOptions = isFetching
    ? [...options, { value: 'loading', label: 'loading' }]
    : options;

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;

    if (
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      !isFetching &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const debouncedSetName = useCallback(
    debounce((value: string) => {
      setName(value);
    }, 200),
    [],
  );

  useEffect(() => {
    if (!isClicked) return;
    debouncedSetName(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setOptions([
      ...(!name ||
      (ingredientsForScreening.length > 0 &&
        ingredientsForScreening[0].name === name)
        ? []
        : [
            {
              label: name,
              value: name,
            },
          ]),
      ...ingredientsForScreening.map((item) => ({
        label: item.name,
        value: item.name,
      })),
    ]);
  }, [ingredientsForScreening]);

  useEffect(() => {
    if (value) setSearchValue(value);
  }, [value]);

  return (
    <Container className={`select_input_${field.name}`}>
      <div onPaste={(e) => onPaste(e, field, 'name')}>
        <Select
          ref={ref}
          onPopupScroll={handleScroll}
          value={value}
          open={true}
          searchValue={searchValue}
          placeholder={searchValue ? '' : '직접 입력 또는 검색'}
          style={{ width: 568 }}
          filterOption={(keyword, option) => {
            return (
              option?.value?.toLowerCase().includes(keyword.toLowerCase()) ||
              option?.value === 'loading'
            );
          }}
          onClick={() => {
            setName(value || '');
            setOpen(true);
            setIsClicked(true);
          }}
          onSearch={(e: string) => {
            if (e.length > searchValue.length) {
              setOptions([{ label: e, value: e }, ...options]);
            } else if (e === '') {
              setOptions([...options.slice(1)]);
            } else {
              setOptions([{ label: e, value: e }, ...options.slice(1)]);
            }

            setSearchValue(e);
          }}
          dropdownStyle={{
            ...(!open && { display: 'none' }),
          }}
          onChange={(e) => {
            onChange?.(e);
            setSearchValue(e);
            setTimeout(() => {
              setOpen(false);
              ref.current?.blur();
            }, 0);
          }}
          onBlur={() => {
            onChange?.(searchValue === '' ? undefined : searchValue);
            setTimeout(() => {
              setOpen(false);
            }, 0);
          }}
          onSelect={(e) => {
            setSearchValue(e);
            setTimeout(() => {
              setOpen(false);
              ref.current?.blur();
            }, 0);
          }}
          showSearch
        >
          {finalOptions.map((item, index) => (
            <Select.Option
              value={item.value}
              key={index}
              disabled={item.label === 'loading'}
              style={{
                ...(item.label === 'loading' && {
                  textAlign: 'center',
                  cursor: 'default',
                }),
              }}
            >
              {item.label === 'loading' ? (
                <LoadingOutlined style={{ color: palette.PRIMARY50 }} />
              ) : (
                item.label
              )}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  input {
    font-size: 14px !important;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 42px;
    padding-left: 5px;
  }

  .ant-select-item-option-disabled {
    cursor: default !important;
    text-align: center;
    color: ${palette.GRAY70};
  }
`;

export default FormulaIngredientSelect;
