import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import path from 'lib/path';
import ManagerPage from 'pages/auth/ManagerPage';
import UserInfoPage from 'pages/auth/UserInfoPage';
import BuyerPage from 'pages/buyer/BuyerPage';
import Error404Page from 'pages/error/Error404Page';
import BrandContractsPage from 'pages/estimate/BrandContractsPage';
import ManufacturerManagePage from 'pages/manufacturer/ManufacturerManagePage';
import MaterialContractsPage from 'pages/material/estimate/MaterialContractsPage';
import NMPACompanyInfoPage from 'pages/material/nmpa/NMPACompanyInfoPage';
import SharedDocsPage from 'pages/my/SharedDocsPage';
import VCRPAccountPage from 'pages/vcrp/VCRPAccountPage';
import { UserType } from 'types/auth';
import MyRPPage from 'pages/my/MyRPPage';
import MyFDAPage from 'pages/my/MyFDAPage';

const MyRouter = () => {
  const { authority, userType } = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="MyPageBlock"]');
    rootPageTemplete.style.maxWidth = '1040px';
  }, []);

  return (
    <Switch>
      <Route
        path={[`${path.my}/userInfo`, path.my]}
        exact
        component={UserInfoPage}
      />
      {userType === UserType.BRAND && (
        <Route path={`${path.my}/docs`} component={SharedDocsPage} />
      )}
      {userType === UserType.BRAND && (
        <Route path={`${path.my}/estimate`} component={BrandContractsPage} />
      )}
      {userType === UserType.MATERIAL && (
        <Route path={`${path.my}/estimate`} component={MaterialContractsPage} />
      )}
      {userType === UserType.BRAND && (
        <Route
          path={`${path.my}/manufacturer`}
          component={ManufacturerManagePage}
        />
      )}
      {userType === UserType.MATERIAL && (
        <Route path={`${path.my}/nmpa`} component={NMPACompanyInfoPage} />
      )}
      {authority === 'SUPER_USER' && (
        <Route path={`${path.my}/manager`} component={ManagerPage} />
      )}
      {userType === UserType.BRAND && (
        <Route path={`${path.my}/buyer`} component={BuyerPage} />
      )}
      {userType === UserType.BRAND && (
        <Route path={`${path.my}/rp`} component={MyRPPage} />
      )}
      {userType === UserType.BRAND && (
        <Route path={`${path.my}/fdaar`} component={MyFDAPage} />
      )}
      {userType === UserType.BRAND && (
        <Route path={`${path.my}/vcrp`} component={VCRPAccountPage} />
      )}
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default MyRouter;
