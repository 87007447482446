import { Switch, Route } from 'react-router-dom';

import path from 'lib/path';
import ServiceTermsPage from 'pages/terms/ServiceTermsPage';
import PrivacyProcessTermsPage from 'pages/terms/PrivacyProcessTermsPage';
import Error404Page from 'pages/error/Error404Page';
import PrivacyThirdPartyTermsPage from 'pages/terms/PrivacyThirdPartyTermsPage';
import PrivacyServiceTermsPage from 'pages/terms/PrivacyServiceTermsPage';
import CIBIServiceTermsPage from 'pages/terms/CIBIServiceTermsPage';

const TermsRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={[path.terms.root, path.terms.service]}
        component={ServiceTermsPage}
      />
      <Route
        path={path.terms.privacyProcess}
        component={PrivacyProcessTermsPage}
      />
      <Route
        path={path.terms.privacyService}
        component={PrivacyServiceTermsPage}
      />
      <Route
        path={path.terms.privacyThirdParty}
        component={PrivacyThirdPartyTermsPage}
      />
      <Route path={path.terms.cibiService} component={CIBIServiceTermsPage} />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default TermsRouter;
