import { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import * as estimateApi from 'lib/api/estimate';
import { setSelectedEstimateTargetIds } from 'modules/estimate';
import { apiUrls, useBasicQuery } from 'service/core';
import {
  CanSelfEstimateCountry,
  SelfEstimateTarget,
  SimpleProduct,
} from 'types/brand/estimate';

export const useAddEstimateTargets = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const selectedEstimateTargetIds = useSelector(
    ({ estimate }: any) => estimate.selectedEstimateTargetIds as number[],
  );
  const { mutate: addEstimateTargets, isLoading: addLoading } = useMutation(
    ({
      productId,
      countryCodes,
    }: {
      productId: number;
      countryCodes: string[];
    }) => estimateApi.addEstimateTargets({ productId, countryCodes }),
    {
      onSuccess: (res) => {
        const estimateTargets = res.data.result;
        queryClient.invalidateQueries(apiUrls.selfEstimateTargets);
        queryClient.invalidateQueries(apiUrls.canSelfEstimateCountries);
        const newEstimateTargetIds = estimateTargets.map(
          ({ estimateTargetId }) => estimateTargetId,
        );
        dispatch(
          setSelectedEstimateTargetIds(
            selectedEstimateTargetIds.concat(newEstimateTargetIds),
          ),
        );
      },
    },
  );
  return useMemo(() => ({ addEstimateTargets, addLoading }), [
    addEstimateTargets,
    addLoading,
  ]);
};

export const useDeleteEstimateTargets = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    mutate: deleteEstimateTargets,
    isLoading: deleteLoading,
  } = useMutation(
    (estimateTargetIds: number[]) =>
      estimateApi.deleteEstimateTargets(estimateTargetIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.selfEstimateTargets);
        queryClient.invalidateQueries(apiUrls.canSelfEstimateCountries);
        dispatch(setSelectedEstimateTargetIds([]));
      },
    },
  );
  return useMemo(() => ({ deleteEstimateTargets, deleteLoading }), [
    deleteEstimateTargets,
    deleteLoading,
  ]);
};

// 제품 간단정보 조회
export const useSimpleProducts = () => {
  const [initialData] = useState([]);
  const { data: simpleProducts = initialData, isLoading } = useBasicQuery<
    SimpleProduct[]
  >({
    apiUrl: apiUrls.simpleProducts,
  });

  return { simpleProducts, isLoading };
};

// 제품별 셀프견적 가능 국가 조회
export const useCanSelfEstimateCountries = (productId?: number) => {
  const [initialData] = useState([]);
  const {
    data: canSelfEstimateCountries = initialData,
    isLoading,
  } = useBasicQuery<CanSelfEstimateCountry[]>({
    apiUrl: apiUrls.canSelfEstimateCountries,
    urlParams: { ...(productId && { productId }) },
    option: {
      enabled: typeof productId === 'number',
    },
  });

  return {
    canSelfEstimateCountries,
    isLoading,
  };
};

// 셀프 견적 리스트 조회
export const useSelfEstimateTargets = () => {
  const [initialData] = useState([]);
  const { data: selfEstimateTargets = initialData, isLoading } = useBasicQuery<
    SelfEstimateTarget[]
  >({ apiUrl: apiUrls.selfEstimateTargets });

  return { selfEstimateTargets, isLoading };
};
