import { useState } from 'react';
import styled from 'styled-components';

import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { s3AssetDomain } from 'lib/consts';
import Tags from 'components/ui/Tags';
import { downloadFile } from 'lib/file';

const selfInspects: {
  title: string;
  titleAdditionals?: string[];
  documents: { names: string[]; remark?: string }[];
}[] = [
  {
    title:
      '품질관리 업무를 적정하고 원활하게 수행할 능력이 있는 인력이 갖추어져 있는가?',
    documents: [{ names: ['조직도'] }],
  },
  {
    title: '교육ㆍ훈련을 정기적으로 실시하고 문서로 관리되고 있는가?',
    documents: [{ names: ['교육훈련 기본계획서', '교육훈련 실시·평가기록'] }],
  },
  {
    title: '책임판매관리자는 실질적으로 근무하고 있는가?',
    documents: [
      {
        names: ['책임판매관리자 근무 여부를 확인할 수 있는 서류'],
        remark:
          '*예) 근로소득원천징수증명서 또는 국민연금, 또는 건강보험 납입증명서',
      },
    ],
  },
  {
    title:
      '품질관리 업무를 적정하고 원활하게 수행하기 위하여 품질 관리 업무 절차서를 작성·보관하고 있는가?',
    documents: [
      {
        names: ['품질매뉴얼'],
        remark: '*평가보고서에 적힌 제조번호로, 각 품목별 제출',
      },
    ],
  },
  {
    title:
      '화장품제조업자가 화장품을 적정하고 원활하게 제조한 것임을 확인하고 기록하고 있는가?',
    documents: [
      {
        names: ['제품표준서', '제조관리기록서(제조번호, 제조일자)'],
        remark: '*평가보고서에 적힌 제조번호로, 각 품목별 제출',
      },
    ],
  },
  {
    title:
      '제품의 품질 등에 관한 정보를 얻었을 때에는 해당 정보가 인체에 영향을 미치는 경우에는 그 원인을 밝히고, 개선이 필요한 경우에는 적정한 조치를 하고 기록하고 있는가?',
    documents: [
      {
        names: ['품질관리기준매뉴얼'],
      },
      {
        names: [
          '품질정보 등의 조사·개선 지시서',
          '품질정보 등 처리 결과보고서',
        ],
        remark: '*해당 사례가 있었을 경우 제출',
      },
    ],
  },
  {
    title:
      '책임판매한 제품의 품질이 불량하거나 품질이 불량할 우려가 있는 경우 회수 등 신속한 조치를 하고 기록하고 있는가?',
    documents: [
      {
        names: ['회수(반품) 요청서', '회수처리 기록'],
        remark: '*해당 사례가 있었을 경우 제출',
      },
    ],
  },
  {
    title: '시장출하에 관하여 기록하고 있는가?',
    documents: [{ names: ['시장출하 적부판정 기록'] }],
  },
  {
    title:
      '책임판매관리자는 품질관리에 관한 기록 및 제조업자의 관리에 관한 기록을 작성하고 이를 해당 제품의 제조일(수입의 경우 수입일을 말한다)부터 3년간 보관하고 있는가?',
    documents: [
      {
        names: [
          '제조업자로부터 입고 관련 서류(제조번호, 유효기간, 입고일자, 입고수량 등)',
        ],
      },
    ],
  },
  {
    title:
      '책임판매관리자는 회수한 화장품은 구분하여 일정 기간 보관한 후 폐기 등 적정한 방법으로 처리하고, 회수내용을 기록하고 있는가?',
    documents: [
      {
        names: ['회수(반품) 요청서', '회수처리 기록'],
        remark: '*해당 사례가 있었을 경우 제출',
      },
    ],
  },
  {
    title:
      '문서를 작성하거나 개정하였을 때에는 품질관리 업무 절차서에 따라 해당 문서를 승인, 배포, 보관하고 있는가?',
    documents: [{ names: ['품질문서등록대장'] }],
  },
  {
    title:
      '품질관리 업무 절차서를 작성하거나 개정하였을 때에는 해당 품질관리 업무 절차서에 그 날짜를 적고 개정 내용을 보관하고 있는가?',
    documents: [{ names: ['품질문서등록대장'] }],
  },
  {
    title:
      '책임판매관리자는 안전관리 정보의 검토 및 그 결과에 따른 안전확보 조치를 하고 있는가?',
    documents: [
      {
        names: ['책임판매 후 안전관리기준 문서 및 기록 목록'],
      },
    ],
  },
  {
    title:
      '책임판매관리자는 안전확보 업무를 총괄하고, 안전확보 업무가 적정하고 원활하게 수행되는 것을 확인하여 기록·보관하고 있는가?',
    documents: [
      {
        names: ['안전관리정보의 검토 및 조치 기록'],
        remark: '*해당 사례가 있었을 경우 제출',
      },
    ],
  },
  {
    title:
      '화장품제조업자로부터 받은 제품표준서 및 품질관리 기록서(전자문서 형식을 포함한다)를 보관하고 있는가?',
    documents: [
      {
        names: ['제품표준서', '제조관리기록서(제조번호, 제조일자)'],
        remark: '*평가보고서에 적힌 제조번호로, 각 품목별 제출',
      },
      {
        names: [
          '제조업자로부터 입고 관련 서류(제조번호, 유효기간, 입고일자, 입고수량 등)',
        ],
      },
    ],
  },
  {
    title:
      '수입한 화장품에 대하여 다음 각 목의 사항을 적거나 또는 첨부한 수입관리기록서를 작성·보관하고 있는가?',
    documents: [
      { names: ['수입관리기록서'], remark: '*수입화장품의 경우만 제출' },
    ],
  },
  {
    title: '제조번호별로 품질검사를 철저히 한 후 유통하고 있는가?',
    documents: [
      {
        names: ['품질매뉴얼'],
        remark: '*평가보고서에 적힌 제조번호로, 각 품목별 제출',
      },
    ],
  },
  {
    title:
      '제조 또는 품질검사를 위탁하는 경우 제조 또는 품질 검사가 적절하게 이루어지고 있는지 수탁자에 대한 관리·감독을 철저히 하고, 제조 및 품질관리에 관한 기록을 받아 유지·관리하고 있는가?',
    documents: [
      {
        names: [
          '제조 위수탁계약서',
          '시험 위수탁계약서',
          '제조업자의 제조·품질관리의 확인서',
          '제조업자의 제조·품질관리 개선지시서',
          '제조업자의 제조·품질관리 개선결과 평가서',
        ],
      },
    ],
  },
  {
    title:
      '제품과 관련하여 국민보건에 직접 영향을 미칠 수 있는 안전성·유효성에 관한 새로운 자료, 정보사항(화장품 사용에 의한 부작용 발생사례를 포함한다) 등을 알게 되었을 때에는 식품의약품안전처장이 정하여 고시하는 바에 따라 보고하고, 필요한 안전대책을 마련하고 있는가?',
    documents: [
      {
        names: ['책임판매 후 안전관리기준 문서 및 기록 목록'],
      },
      {
        names: ['안전관리정보의 검토 및 조치 기록'],
        remark: '*해당 사례가 있었을 경우 제출',
      },
    ],
  },
  {
    title:
      '제조 또는 품질검영유아 또는 어린이가 사용할 수 있는 화장품임을 표시·광고하려는 경우에 제품별로 안전과 품질을 입증할수 있는 자료를 작성·보관하고 있는가? 사를 위탁하는 경우 제조 또는 품질 검사가 적절하게 이루어지고 있는지 수탁자에 대한 관리·감독을 철저히 하고, 제조 및 품질관리에 관한 기록을 받아 유지·관리하고 있는가?',
    documents: [
      {
        names: [
          '가. 제품 및 제조방법에 대한 설명 자료',
          '나. 화장품의 안전성 평가 자료',
          '다. 제품의 효능·효과에 대한 증명 자료',
        ],
        remark:
          '*영유아, 어린이가 사용할 수 있는 화장품을 판매한 적이 있을 경우 3가지 자료 제출',
      },
    ],
  },
  {
    title:
      '다음 각 목의 어느 하나에 해당하는 성분을 0.5퍼센트 이상 함유하는 제품의 경우에는 해당 품목의 안정성시험 자료를 최종 제조된 제품의 사용기한이 만료되는 날부터 1년간 보존하고 있는가?',
    titleAdditionals: [
      '가. 레티놀(비타민A) 및 그 유도체',
      '나. 아스코빅애시드(비타민C) 및 그 유도체',
      '다. 토코페롤(비타민E)',
      '라. 과산화화합물',
      '마. 효소',
    ],
    documents: [
      {
        names: ['안정성시험 자료'],
        remark:
          '*해당 성분이 함유된 제품을 판매한 적이 있을 경우, 안정성시험 자료 제출',
      },
    ],
  },
];

const QCQASelfInspect = () => {
  const [isSelfInspectListOpen, setIsSelfInspectListOpen] = useState(false);

  return (
    <Container>
      <TitleBackground>
        <TitleContent>
          <IconWrapper>
            <Icon name="toDo" size={40} />
          </IconWrapper>
          <Typography.Text type="HEADLINE_1" gutter={{ top: 16 }}>
            자율점검 관리
          </Typography.Text>
          <Typography.Text
            type="TITLE_2"
            color="SLATE_GRAY70"
            gutter={{ top: 24 }}
          >
            화장품 자율점검으로 품질관리를 점검해 보세요.
          </Typography.Text>
        </TitleContent>
      </TitleBackground>
      <Content>
        <SelfInspectSummary gap={46} align="center">
          <div>
            <Typography.Text type="TITLE_1" medium>
              자율점검이란?
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              gutter={{ top: 24 }}
            >
              화장품법에 따라 식약청이 화장품 책임판매업자에게 품질관리를 스스로
              점검할 수 있도록 하는 것을 말합니다. 화장품 자율관리 역량을
              향상시키고, 품질 안정성을 높이고자 정기감시의 일환으로 실시하는
              제도로 자율점검 대상업체로 선정되면 자율점검을 실시하고 결과를
              제출해야 합니다.
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              gutter={{ top: 8 }}
            >
              자료 미제출 또는 제출자료 미흡 시 현장감시 실시함 ⟶ ‘
              <LawLinkText>화장품법 제 18조</LawLinkText>’ 및 ‘
              <LawLinkText>24조</LawLinkText>
              ’에 따라 최대 “등록 취소" 까지 받을 수 있습니다.
            </Typography.Text>
          </div>
          <StyledImg
            src={`${s3AssetDomain}/self_inspect_intro.png`}
            alt="self_inspect_intro"
          />
        </SelfInspectSummary>
        <Typography.Text type="TITLE_1" gutter={{ top: 80 }} medium>
          자료 준비 안내
        </Typography.Text>
        <Flex gap={16} gutter={{ top: 24 }}>
          <Card title="제출 품목 선정" height={262}>
            <Typography.Text type="BODY_1" color="SLATE_GRAY70" medium>
              생산 또는 수입실적 상위 2품목(또는 주요 2품목)의 제품을
              선정합니다.
            </Typography.Text>
            <Flex align="center" gutter={{ top: 12 }}>
              <DotWrapper>
                <Dot />
              </DotWrapper>
              <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                기능성화장품이 있는 경우 기능성화장품을 포함하여 제출
              </Typography.Text>
            </Flex>
            <Typography.Text
              type="BODY_2"
              color="SLATE_GRAY70"
              gutter={{ top: 4, left: 21 }}
            >
              (예시 1) 기능성화장품 실적 상위 2품목
            </Typography.Text>
            <Typography.Text
              type="BODY_2"
              color="SLATE_GRAY70"
              gutter={{ top: 2, left: 21 }}
            >
              (예시 2) 기능성화장품 실적 상위 1품목+일반화장품 중 실적 상위
              1품목
            </Typography.Text>
            <Flex gutter={{ top: 8 }}>
              <DotWrapper>
                <Dot />
              </DotWrapper>
              <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                최근 3년간 기능성화장품의 생산(수입)실적이 없는 경우: 가급적
                화장품 유형이 서로 다른 것을 제출
              </Typography.Text>
            </Flex>
          </Card>
          <Card title="제출 범위" height={262}>
            <Typography.Text type="BODY_1" color="SLATE_GRAY70" medium>
              선정한 제출품목의 전년도 생산 또는 수입품목 1개 제조번호(Lot
              No.)에 대해 점검합니다.
            </Typography.Text>
            <Flex align="center" gutter={{ top: 12 }}>
              <DotWrapper>
                <Dot />
              </DotWrapper>
              <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                제품에 변경사항이 있는 경우 변경 전후 제조번호 각각 1개씩 제출
              </Typography.Text>
            </Flex>
          </Card>
        </Flex>
        <Typography.Text type="TITLE_1" gutter={{ top: 56 }} medium>
          제출 자료 안내
        </Typography.Text>
        <Flex gap={16} gutter={{ top: 24 }}>
          <Card title="제출 자료 목록" height={350}>
            <Typography.Text type="BODY_1" color="SLATE_GRAY70" medium>
              ① 점검 리스트 1부
            </Typography.Text>
            <Typography.Text
              type="BODY_2"
              color="SLATE_GRAY70"
              gutter={{ top: 4 }}
            >
              *당해 점검 리스트 세부 양식은 관할 식약청별로 차이가 있을 수
              있습니다.
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              medium
              gutter={{ top: 12 }}
            >
              ② 점검 리스트 21개 항목별 근거서류 각 1부
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              medium
              gutter={{ top: 12 }}
            >
              ③ 자체평가보고서 1부
            </Typography.Text>
            <Typography.Text
              type="BODY_2"
              color="SLATE_GRAY70"
              gutter={{ top: 4 }}
            >
              *당해 점검리스트 세부 양식은 관할 식약청별로 차이가 있을 수
              있습니다.
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              medium
              gutter={{ top: 12 }}
            >
              ④ 화장품 책임판매 등록증 사본 (앞, 뒷면) 1부
            </Typography.Text>
            <Flex
              align="center"
              justify="space-between"
              gutter={{ top: 24 }}
              style={{
                padding: '8px 16px',
                borderRadius: 4,
                background: palette.SLATE_GRAY10,
              }}
            >
              <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                자율점검에 필요한 서식을 다운로드 받으세요!
              </Typography.Text>
              <Flex
                gap={4}
                align="center"
                style={{ padding: '2px 4px', cursor: 'pointer' }}
                onClick={() => {
                  downloadFile(
                    'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/2024년 자율점검 서식.zip',
                  );
                }}
              >
                <Icon name="download" size={16} color="PRIMARY50" />
                <Typography.Text type="BODY_2" color="PRIMARY50">
                  다운로드
                </Typography.Text>
              </Flex>
            </Flex>
          </Card>
          <Card title="제출 방법" height={350}>
            <Typography.Text type="BODY_1" color="SLATE_GRAY70">
              자료 제출은 아래 2가지 중 하나의 방법으로 할 수 있습니다.
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              medium
              gutter={{ top: 12 }}
            >
              ① 의약품안전나라(nedrug.mfds.go.kr) 전자보고
            </Typography.Text>
            <Typography.Text
              type="BODY_1"
              color="SLATE_GRAY70"
              medium
              gutter={{ top: 12 }}
            >
              ② 식약청 의료제품안전과로 등기 또는 방문하여 제출
            </Typography.Text>
          </Card>
        </Flex>
        <SelfInspectListCollapse
          onClick={() => setIsSelfInspectListOpen(!isSelfInspectListOpen)}
        >
          <Typography.Text type="BODY_1" color="SLATE_GRAY70" medium>
            점검 리스트 내용 상세보기
          </Typography.Text>
          <Icon
            name="down"
            size={24}
            style={{
              transform: isSelfInspectListOpen ? 'rotate(-180deg)' : '',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </SelfInspectListCollapse>
        {isSelfInspectListOpen && (
          <SelfInspectListPanel>
            {selfInspects.map(({ title, titleAdditionals, documents }, idx) => (
              <div key={idx}>
                <Flex key={idx} gap={8} gutter={{ top: idx !== 0 ? 24 : 0 }}>
                  <Number>{idx + 1}</Number>
                  <div>
                    <Typography.Text type="BODY_1" color="SLATE_GRAY70" medium>
                      {title}
                    </Typography.Text>
                    {titleAdditionals?.map((item, idx) => (
                      <Typography.Text
                        key={idx}
                        type="BODY_1"
                        color="SLATE_GRAY70"
                        medium
                        style={{ marginTop: idx !== 0 ? 0 : 8 }}
                      >
                        {item}
                      </Typography.Text>
                    ))}
                    <div>
                      {documents.map(({ names, remark }, idx) => (
                        <Flex
                          key={idx}
                          gap={8}
                          align="center"
                          style={{ marginTop: idx === 0 ? 8 : 4 }}
                        >
                          <Flex gap={4}>
                            {names.map((name, idx) => (
                              <Tags.Chip key={idx} color="GRAY30">
                                {name}
                              </Tags.Chip>
                            ))}
                          </Flex>
                          {remark && (
                            <Typography.Text
                              type="SMALL"
                              color="SLATE_GRAY70"
                              medium
                            >
                              {remark}
                            </Typography.Text>
                          )}
                        </Flex>
                      ))}
                    </div>
                  </div>
                </Flex>
                {idx !== selfInspects.length - 1 && <Divider />}
              </div>
            ))}
          </SelfInspectListPanel>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div``;

const TitleBackground = styled.div`
  background-color: ${palette.PRIMARY20};
  padding: 91px 0;
`;

const TitleContent = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 50%;
  background-color: ${palette.ETC_WHITE};
  width: max-content;
`;

const Content = styled.section`
  margin: 0 auto;
  padding: 80px 0;
  max-width: 1040px;
`;

const SelfInspectSummary = styled(Flex)`
  padding: 51px 48px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 12px;
`;

const StyledImg = styled.img`
  width: 220px;
  height: 158px;
`;

const DotWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 20px;
  display: inline-flex;
`;
const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${palette.SLATE_GRAY70};
`;

const SelfInspectListCollapse = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  border-radius: 8px;
  padding: 16px 36px;
  background-color: ${palette.SLATE_GRAY10};
  height: 64px;
  cursor: pointer;
`;

const SelfInspectListPanel = styled.div`
  margin-top: 16px;
  padding: 24px 36px;
  background-color: ${palette.ETC_WHITE};
  border: 1px solid ${palette.GRAY30};
  border-radius: 8px;
`;

const Number = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${palette.PRIMARY50};
  font-size: 12px;
  color: ${palette.ETC_WHITE};
`;

const Divider = styled.div`
  margin-top: 24px;
  height: 1px;
  background-color: ${palette.GRAY30};
`;

const LawLinkText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography.Text
      inline
      color="PRIMARY50"
      hover
      onClick={() => {
        window.open(
          'https://www.law.go.kr/LSW/lsInfoP.do?lsId=002015#0000',
          '_blank',
        );
      }}
    >
      {children}
    </Typography.Text>
  );
};

const Card = ({
  title,
  children,
  height,
}: {
  title: string;
  children: React.ReactNode;
  height: number;
}) => {
  return (
    <CardWrapper height={height}>
      <Typography.Text
        type="TITLE_2"
        color="PRIMARY50"
        semiBold
        gutter={{ bottom: 16 }}
      >
        {title}
      </Typography.Text>
      {children}
    </CardWrapper>
  );
};

const CardWrapper = styled.div<{ height: number }>`
  flex: 1;
  padding: 32px;
  border: 1px solid ${palette.GRAY30};
  border-radius: 12px;
  background-color: ${palette.ETC_WHITE};
  height: ${({ height }) => height}px;
`;

export default QCQASelfInspect;
