import { memo } from 'react';
import { Form, Input } from 'antd';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import styled from 'styled-components';
import AlphabetLabel from './AlphabetLabel';

const MSDSRegulatoryBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSRegulatory = () => {
  return (
    <MSDSRegulatoryBlock>
      <Form.Item
        label={
          <AlphabetLabel alphabet="A">
            Regulation under the Occupational Safety and Health Act
          </AlphabetLabel>
        }
        name={['regulatory', 'regulationUnderSafetyAndHealth']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Follow the governing regulations"
      >
        <Input.TextArea
          placeholder="산업안전보건법에 의한 규제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">
            Act on the Registration and Evaluation, etc. of Chemical Substances,
            Chemicals Control Act
          </AlphabetLabel>
        }
        name={['regulatory', 'actRegistrationAndEvaluation']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Follow the governing regulations"
      >
        <Input.TextArea
          placeholder="화학물질관리법에 의한 규제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="C">
            Dangerous Substances Safety Management Act
          </AlphabetLabel>
        }
        name={['regulatory', 'dangerousSubstancesAct']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Follow the governing regulations"
      >
        <Input.TextArea
          placeholder="위험물안전관리법에 의한 규제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="D">Wastes Control Act</AlphabetLabel>}
        name={['regulatory', 'wastesControlAct']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Follow the governing regulations"
      >
        <Input.TextArea
          placeholder="폐기물관리법에 의한 규제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="E">Other regulations</AlphabetLabel>}
        name={['regulatory', 'otherRegulations']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Follow the governing regulations"
      >
        <Input.TextArea
          placeholder="기타 국내 및 외국법에 의한 규제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="KECI Number"
        name={['regulatory', 'keciNumber']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="No data"
      >
        <Input.TextArea
          placeholder="한국 화학 물질 번호"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSRegulatoryBlock>
  );
};

export default memo(MSDSRegulatory);
