import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

interface DatePickerProps {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  checkIsValidDate?: (current: moment.Moment) => boolean;
  onClick?: VoidFunction;
  style?: CSSProperties;
  placeholder?: string;
}

const DatePicker = ({
  disabled = false,
  value,
  onChange,
  checkIsValidDate,
  onClick,
  style,
  placeholder,
}: DatePickerProps) => {
  const today = moment();
  const initialMonth = value
    ? moment(value.slice(0, 10), 'YYYY-MM-DD').month() + 1
    : today.month() + 1;
  const initialYear = value ? moment(value, 'YYYY-MM-DD').year() : today.year();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);
  const [currentYear, setCurrentYear] = useState(initialYear);

  useEffect(() => {
    if (value && /\d{4}-\d{2}-\d{2}/.test(value)) {
      setCurrentMonth(moment(value.slice(0, 10), 'YYYY-MM-DD').month() + 1);
      setCurrentYear(moment(value.slice(0, 10), 'YYYY-MM-DD').year());
    }
  }, [value]);

  useEffect(() => {
    /**
     * HINT: 특정 영역 이외 클릭시 닫는 함수.
     * not-close 클래스가 있는 영역도 닫지 않음.
     */
    const handleClick = (e: MouseEvent) => {
      const clickedElement = e.target as HTMLElement;
      const targetClasses = ['not-close'];

      let element: HTMLElement | null = clickedElement;

      while (element !== null) {
        let isBreak = false;

        for (const targetClass of targetClasses) {
          if (element.classList && element.classList.contains(targetClass)) {
            isBreak = true;
            break;
          }
        }

        if (isBreak) {
          break;
        }

        element = element.parentNode as HTMLElement | null;
      }

      if (element === null) {
        // 클릭시 닫는 함수가 아래에 들어감.
        setDropdownVisible(false);
      }
    };

    document.body.addEventListener('click', handleClick);

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <OuterContainer>
      <Container
        gap={16}
        className="not-close custom-date-picker"
        align="center"
        justify="space-between"
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          setDropdownVisible(true);
          onClick?.();
        }}
        style={style}
      >
        <input
          value={value || ''}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={placeholder || 'YYYY-MM-DD'}
          disabled={disabled}
          style={{ flex: 1 }}
        />
        <Icon
          name="calendar"
          size={20}
          color="GRAY50"
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        />
      </Container>
      {dropdownVisible && (
        <Dropdown
          value={value}
          onChange={onChange}
          onChangeDropdownVisible={setDropdownVisible}
          checkIsValidDate={checkIsValidDate}
          today={today}
          currentMonth={currentMonth}
          currentYear={currentYear}
          onChangeCurrentMonth={setCurrentMonth}
          onChangeCurrentYear={setCurrentYear}
        />
      )}
    </OuterContainer>
  );
};

const Dropdown = ({
  value,
  onChange,
  onChangeDropdownVisible,
  checkIsValidDate,
  today,
  currentMonth,
  currentYear,
  onChangeCurrentMonth,
  onChangeCurrentYear,
}: {
  value?: string;
  onChange?: (value: string) => void;
  onChangeDropdownVisible: (visible: boolean) => void;
  checkIsValidDate?: (current: moment.Moment) => boolean;
  today: moment.Moment;
  currentMonth: number;
  currentYear: number;
  onChangeCurrentMonth: (month: number) => void;
  onChangeCurrentYear: (year: number) => void;
}) => {
  const handleClickPrev = () => {
    if (currentMonth === 1) {
      onChangeCurrentMonth(12);
      onChangeCurrentYear(currentYear - 1);
    } else {
      onChangeCurrentMonth(currentMonth - 1);
    }
  };

  const handleClickNext = () => {
    if (currentMonth === 12) {
      onChangeCurrentMonth(1);
      onChangeCurrentYear(currentYear + 1);
    } else {
      onChangeCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <DropdownContainer className="not-close calendar-dropdown">
      <Calendar
        year={currentYear}
        month={currentMonth}
        today={today}
        momentInstance={moment(`${currentYear}-${currentMonth}`, 'YYYY-MM')}
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
        value={value}
        onChange={onChange}
        onChangeDropdownVisible={onChangeDropdownVisible}
        checkIsValidDate={checkIsValidDate}
      />
    </DropdownContainer>
  );
};

const Calendar = ({
  year,
  month,
  today,
  onClickPrev,
  onClickNext,
  momentInstance,
  value,
  onChange,
  onChangeDropdownVisible,
  checkIsValidDate,
}: {
  year: number;
  month: number;
  today: moment.Moment;
  onClickPrev: VoidFunction;
  onClickNext: VoidFunction;
  momentInstance: moment.Moment;
  value?: string;
  onChange?: (value: string) => void;
  onChangeDropdownVisible: (visible: boolean) => void;
  checkIsValidDate?: (current: moment.Moment) => boolean;
}) => {
  const firstDayOfMonth = moment(momentInstance).startOf('month');
  const lastDayOfMonth = moment(momentInstance).endOf('month');
  const startDate = moment(firstDayOfMonth).startOf('week');
  const endDate = moment(lastDayOfMonth).endOf('week');
  const daysInMonth = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate) || daysInMonth.length < 42) {
    daysInMonth.push(moment(currentDate));
    currentDate.add(1, 'day');
  }

  return (
    <CalendarContainer>
      <CalendarHeader align="center" justify="space-between">
        <Icon name="left" size={18} onClick={onClickPrev} />
        <Typography.Text semiBold>
          {year}년 {month}월
        </Typography.Text>
        <Icon name="right" size={18} onClick={onClickNext} />
      </CalendarHeader>
      <CalendarBody>
        {['일', '월', '화', '수', '목', '금', '토'].map((item) => (
          <CalendarWeeks key={item}>{item}</CalendarWeeks>
        ))}
        {daysInMonth.map((cellMoment, index) => {
          const formatDate = cellMoment.format('YYYY-MM-DD');
          const date = cellMoment.date();
          const cellMonth = cellMoment.month() + 1;
          const disabled = checkIsValidDate
            ? !checkIsValidDate(cellMoment)
            : false;
          const isThisMonth = month === cellMonth;
          const isSelected = formatDate === value;

          return (
            <CalendarCell
              key={index}
              isToday={today.format('YYYY-MM-DD') === formatDate}
              isThisMonth={isThisMonth}
              isSelected={isSelected}
              disabled={disabled}
              onClick={() => {
                if (disabled) return;
                onChange?.(formatDate);
                // 이전 달 cell을 클릭한 경우
                if (!isThisMonth && momentInstance.isAfter(cellMoment)) {
                  onClickPrev();
                  // 다음 달 cell을 클릭한 경우
                } else if (
                  !isThisMonth &&
                  momentInstance.isBefore(cellMoment)
                ) {
                  onClickNext();
                }

                setTimeout(() => {
                  onChangeDropdownVisible(false);
                }, 200);
              }}
            >
              {date}
            </CalendarCell>
          );
        })}
      </CalendarBody>
    </CalendarContainer>
  );
};

export default DatePicker;

const OuterContainer = styled.div`
  position: relative;
`;

const Container = styled(Flex)<{ disabled: boolean }>`
  position: relative;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid ${palette.GRAY40};
  background: ${palette.ETC_WHITE};
  width: 256px;
  height: 44px;

  input,
  input:focus,
  input:active {
    padding: 0;
    width: 80px;
    font-size: 14px;
    border: none;
    outline: none;
  }
  input::placeholder {
    color: ${palette.GRAY50};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${palette.GRAY20};

          input {
            background-color: ${palette.GRAY20};
            color: ${palette.GRAY50};
          }
        `
      : css`
          &:hover,
          &:focus,
          &:focus-within,
          &:active {
            border-color: ${palette.PRIMARY50};
          }
        `}
`;

const DropdownContainer = styled(Flex)`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background: white;
  z-index: 10;
  box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
  border-radius: 4px;
  user-select: none;
`;

const CalendarContainer = styled.div`
  padding: 24px;
  width: 272px;
`;

const CalendarHeader = styled(Flex)``;

const CalendarBody = styled(Flex)`
  margin-top: 24px;
  row-gap: 8px;
  flex-wrap: wrap;
`;

const CalendarWeeks = styled(Flex)`
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

const CalendarCell = styled(Flex)<{
  isToday: boolean;
  isThisMonth: boolean;
  isSelected: boolean;
  disabled: boolean;
}>`
  position: relative;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${palette.GRAY20};
  }

  ${({ isToday, isThisMonth }) =>
    isToday === true &&
    isThisMonth &&
    css`
      border: 1px solid ${palette.PRIMARY50};
    `}

  ${({ isThisMonth }) =>
    !isThisMonth &&
    css`
      color: ${palette.GRAY50};
    `}

    ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${palette.PRIMARY50};
      color: ${palette.ETC_WHITE};

      &:hover {
        background-color: ${palette.PRIMARY50};
      }
    `}

    ${({ disabled, isToday, isThisMonth }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${palette.GRAY20};
      color: ${palette.GRAY50};
      border-radius: 0;
      border: none;

      &:hover {
        background-color: ${palette.GRAY20};
      }

      ${isToday &&
      isThisMonth &&
      css`
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border: 1px solid ${palette.PRIMARY50};
          border-radius: 50%;
        }
      `}
    `}
`;
