import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import { useMemo, useState } from 'react';

import {
  CoaAdd,
  CoaUpdate,
  CoaItemAdd,
  CoaItemUpdate,
  CoaItemDelete,
  CoaItemGet,
} from 'types/material/coa';
import * as coaApi from 'lib/api/material/coa';
import history from 'lib/history';

export const useCoaPhases = () => {
  const { data: coaPhases = [], isFetching: getCoaPhasesLoading } = useQuery(
    'material/coa/getCoaPhases',
    coaApi.getCoaPhases,
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(
    () => ({
      coaPhases,
      getCoaPhasesLoading,
    }),
    [coaPhases, getCoaPhasesLoading],
  );
};

export const useCoaTests = (materialCategoryId?: number) => {
  const { data: coaTests = [], isFetching: getCoaTestsLoading } = useQuery(
    ['material/coa/getCoaTests', materialCategoryId],
    () => coaApi.getCoaTests(materialCategoryId!),
    {
      enabled: typeof materialCategoryId !== 'undefined',
      select: (res) => res.data.result,
    },
  );
  return useMemo(() => ({ coaTests, getCoaTestsLoading }), [
    coaTests,
    getCoaTestsLoading,
  ]);
};

export const useGetRegisteredCoa = () => {
  const { mutate: getRegisteredCoa } = useMutation((coaId: number) =>
    coaApi.getCoa(coaId),
  );
  return getRegisteredCoa;
};

export const useCoa = (materialId: number) => {
  const [isAddSuccess, setIsAddSuccess] = useState(false);
  const { data: coa = null, isFetching: getLoading } = useQuery(
    ['material/coa/getCoa', materialId],
    () => coaApi.getCoa(materialId),
    { select: (res) => res.data.result, cacheTime: 0 },
  );

  const { mutate: addCoa, isLoading: addLoading } = useMutation(
    (coa: CoaAdd) => coaApi.addCoa(coa),
    {
      onMutate: () => {
        setIsAddSuccess(true);
      },
      onSuccess: () => {
        message.success('저장되었습니다.');
        history.goBack();
      },
    },
  );
  const {
    mutate: addTemporaryCoa,
    isLoading: addTemporaryLoading,
  } = useMutation((coa: CoaAdd) => coaApi.addTemporaryCoa(coa), {
    onMutate: () => {
      setIsAddSuccess(true);
    },
    onSuccess: () => {
      message.success('입력하신 데이터가 임시 저장되었습니다.');
      history.goBack();
    },
  });
  const { mutate: updateCoaMutate, isLoading: updateLoading } = useMutation(
    (coa: CoaUpdate) => coaApi.updateCoa(coa),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        history.goBack();
      },
    },
  );
  const updateCoa = ({
    lotNo,
    expiryDate,
    manufacturingDate,
    shelfLife,
    storageCondition,
    materialCOAItems,
  }: CoaAdd) => {
    if (!coa) throw new Error('Invalid coa');

    const getMaterialCOAItemsDiff = (
      originalItems: CoaItemGet[],
      currentItems: CoaItemAdd[],
    ) => {
      const deletingItems: CoaItemDelete[] = originalItems
        .filter(
          (originalItem) =>
            !currentItems.find(
              (newItem) =>
                originalItem.materialCoaItemId === newItem.materialCoaItemId,
            ),
        )
        .map(({ materialCoaItemId }) => ({
          materialCoaItemId: materialCoaItemId!,
          isDelete: true,
        }));
      const addingItems = currentItems.filter(
        ({ materialCoaItemId }) => !materialCoaItemId,
      );
      const updatingItems = currentItems.reduce<CoaItemUpdate[]>(
        (
          acc,
          {
            materialCoaItemId,
            testName,
            specification,
            testResult,
            testUnit,
            testMethod,
            isNoData,
          },
        ) => {
          if (materialCoaItemId) {
            const originalItem = originalItems.find(
              (item) => item.materialCoaItemId === materialCoaItemId,
            )!;
            const updatingItem = {
              ...(testName !== originalItem.testName && { testName }),
              ...(specification !== originalItem.specification && {
                specification,
              }),
              ...(testResult !== originalItem.testResult && { testResult }),
              ...(testUnit !== originalItem.testUnit && { testUnit }),
              ...(testMethod !== originalItem.testMethod && { testMethod }),
              ...(isNoData !== originalItem.isNoData && { isNoData }),
            };
            if (Object.keys(updatingItem).length) {
              acc.push({ materialCoaItemId, ...updatingItem });
            }
          }
          return acc;
        },
        [],
      );
      return [...deletingItems, ...addingItems, ...updatingItems];
    };
    if (!coa) {
      throw new Error('Invalid coa');
    }
    const coaToBeUpdated: CoaUpdate = {
      materialId: coa.materialId!,
      materialCOAId: coa.materialCOAId,
      ...(coa.lotNo !== lotNo && { lotNo }),
      ...(coa.expiryDate !== expiryDate && { expiryDate }),
      ...(coa.manufacturingDate !== manufacturingDate && { manufacturingDate }),
      ...(coa.shelfLife !== shelfLife && { shelfLife }),
      ...(coa.storageCondition !== storageCondition && { storageCondition }),
    };
    const newMaterialCOAItems = getMaterialCOAItemsDiff(
      coa.materialCOAItems,
      materialCOAItems,
    );
    if (newMaterialCOAItems.length) {
      coaToBeUpdated.materialCOAItems = newMaterialCOAItems;
    }
    updateCoaMutate(coaToBeUpdated);
  };

  return useMemo(
    () => ({
      coa,
      getLoading,
      addCoa,
      addLoading,
      addTemporaryCoa,
      addTemporaryLoading,
      updateCoa,
      updateLoading,
      isAddSuccess,
    }),
    [
      coa,
      getLoading,
      addLoading,
      addTemporaryLoading,
      updateLoading,
      isAddSuccess,
    ],
  );
};
