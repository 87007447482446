import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import ChannelService from 'lib/channel';
import { getReactEnvVar } from 'lib/common';
import path from 'lib/path';
import ArtworkScreeningPage from 'pages/brand/artworkScreening/ArtworkScreeningPage';
import FormulaScreeningPage from 'pages/brand/formulaScreening/FormulaScreeningPage';
import AdInquiryPage from 'pages/common/AdInquiryPage';
import UserInquiryPage from 'pages/common/UserInquiryPage';
import Error404Page from 'pages/error/Error404Page';
import MyPage from 'pages/my/MyPage';
import NotarizationPage from 'pages/notarization/NotarizationPage';
import ServicePage from 'pages/service/ServicePage';
import TermsPage from 'pages/terms/TermsPage';
import ProductRouter from 'routers/ProductRouter';
import ManufactureRouter from 'routers/manufacturer/ManufacturerRouter';
import MaterialRouter from 'routers/material/MaterialRouter';
import CertificateRouter from './CertificateRouter';
import EstimateRouter from './EstimateRouter';
import MainRouter from './MainRouter';
import QCQARouter from './QCQARouter';
import RetailerRouter from './retailer/RetailerRouter';

const HomeRouter = () => {
  const user = useSelector(({ auth }: any) => auth.user);
  const isLoggedIn = user !== null;
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && getReactEnvVar('SERVER') === 'REAL') {
      ChannelService.boot({
        pluginKey: '185ffe25-d453-4457-81d8-33316c118cf3',
        memberId: user.username,
        name: user.name,
        trackUtmSource: true,
        profile: {
          name: user.name,
          mobileNumber: user.mobile,
          email: user.email,
        },
      });
      return ChannelService.shutdown;
    }
  }, [isLoggedIn, location]);
  return (
    <Switch>
      <PrivateRoute
        path={[
          '/product-ready',
          '/product-registered',
          '/product-import',
          '/product-art-screening/:productId',
          `${path.product.root}/:productId/:documentCode`,
          `${path.product.root}/:productId`,
          path.product.root,
        ]}
        component={ProductRouter}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={path.estimate.root}
        component={EstimateRouter}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={path.certificate.root}
        component={CertificateRouter}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={path.notarization}
        component={NotarizationPage}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={path.qcqa.root}
        component={QCQARouter}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={path.retailer.root}
        component={RetailerRouter}
        check={isLoggedIn}
        redirect={path.login}
      />
      <Route
        path={[`${path.service}/:tab`, path.service]}
        component={ServicePage}
      />
      <Route path={path.adInquiry} component={AdInquiryPage} />
      <Route path={path.userInquiry} component={UserInquiryPage} />
      <PrivateRoute
        path={[`${path.my}/:tab`, path.my]}
        component={MyPage}
        check={isLoggedIn}
        redirect={path.my}
      />
      <Route exact path={path.main} component={MainRouter} />
      <Route
        path={[`${path.terms.root}/:tab`, path.terms.root]}
        component={TermsPage}
      />
      <PrivateRoute
        path={path.formulaScreening}
        component={FormulaScreeningPage}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={`${path.artworkScreening}/:screeningId`}
        component={ArtworkScreeningPage}
        check={isLoggedIn}
        redirect={path.login}
      />
      <PrivateRoute
        path={path.artworkScreening}
        component={ArtworkScreeningPage}
        check={isLoggedIn}
        redirect={path.login}
      />
      <Route path={path.manufacturer.root} component={ManufactureRouter} />
      <Route path={path.material.root} component={MaterialRouter} />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default HomeRouter;
