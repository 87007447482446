import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { pickBy } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import ProductInternalResponsibleCompany from 'components/product/ProductInternalResponsibleCompany';
import UpdateLog from 'components/product/UpdateLog';
import { messages } from 'lib/consts';
import { createFormData } from 'lib/file';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import client from 'lib/api/client';
import { useProductDocStatus } from 'service/product';
import { DocumentCode } from 'types/product';
import history from 'lib/history';
import useGA, { EGAActionType } from 'hook/useGA';

const queryKey = '/internal-responsible-company';

const ProductInternalResponsibleCompanyContainer = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(undefined);

  const { readOnlyMode } = useSelector(
    ({ certificate }) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );

  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();

  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.IRC,
  });

  const updateMode = productDocStatus && productDocStatus.status !== 'INP';

  const { data: internalResponsibleCompany, isLoading: getLoading } = useQuery(
    [queryKey, productId, countryId],
    () => client.get(`/internal-responsible-company/${productId}/${countryId}`),
    {
      select: (res) => res.data.result,
      enabled: updateMode === true,
    },
  );

  const {
    mutate: addProductInternalResponsibleCompany,
    isLoading: addLoading,
  } = useMutation(
    (productInternalResponsibleCompany) =>
      client.post(
        `/internal-responsible-company`,
        productInternalResponsibleCompany,
      ),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '경내책임회사 정보',
          actionType: EGAActionType.REGISTER,
        });
        history.goBack();
        message.success('입력되었습니다.');
      },
    },
  );

  const {
    mutate: updateProductInternalResponsibleCompany,
    isLoading: updateLoading,
  } = useMutation(
    (productInternalResponsibleCompany) =>
      client.patch(
        `/internal-responsible-company`,
        productInternalResponsibleCompany,
      ),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '경내책임회사 정보',
          actionType: EGAActionType.MODIFY,
        });
        history.goBack();
        message.success('보완 완료되었습니다.');
      },
    },
  );

  const [isVisibleNMPA, setIsVisibleNMPA] = useState(
    internalResponsibleCompany?.isOwnSubsidiary,
  );
  const [isVisibleNMPAInput, setIsVisibleNMPAInput] = useState(
    internalResponsibleCompany?.isCreateNewNMPAAccount,
  );

  useEffect(() => {
    if (internalResponsibleCompany) {
      const {
        companyEmail,
        companyTel,
        isCreateNewNMPAAccount,
        isOwnSubsidiary,
        newNMPAAccountId,
        newNMPAAccountPassword,
      } = internalResponsibleCompany;
      form.setFieldsValue({
        companyEmail,
        companyTel,
        isOwnSubsidiary,
        isCreateNewNMPAAccount,
        newNMPAAccountId,
        newNMPAAccountPassword,
      });

      setIsVisibleNMPA(isOwnSubsidiary);
      setIsVisibleNMPAInput(isCreateNewNMPAAccount);
    }
  }, [internalResponsibleCompany]);

  const onFormFinished = () => {
    if (!updateMode) {
      const filteredFormValues = pickBy(
        {
          productId,
          countryId,
          ...form.getFieldsValue(),
          file,
        },
        (value) => value !== undefined,
      );

      addProductInternalResponsibleCompany(
        createFormData({
          ...filteredFormValues,
        }),
      );
    } else {
      const {
        companyEmail,
        companyTel,
        isCreateNewNMPAAccount,
        isOwnSubsidiary,
        newNMPAAccountId,
        newNMPAAccountPassword,
      } = form.getFieldsValue();

      const updateFormValues = {
        ...(companyEmail !== internalResponsibleCompany.companyEmail && {
          companyEmail,
        }),
        ...(companyTel !== internalResponsibleCompany.companyTel && {
          companyTel,
        }),
        ...(isCreateNewNMPAAccount !==
          internalResponsibleCompany.isCreateNewNMPAAccount && {
          isCreateNewNMPAAccount,
        }),
        ...(isOwnSubsidiary !== internalResponsibleCompany.isOwnSubsidiary && {
          isOwnSubsidiary,
        }),
        ...(newNMPAAccountId !==
          internalResponsibleCompany.newNMPAAccountId && {
          newNMPAAccountId,
        }),
        ...(newNMPAAccountPassword !==
          internalResponsibleCompany.newNMPAAccountPassword && {
          newNMPAAccountPassword,
        }),
      };

      const filteredFormValues = pickBy(
        {
          ...updateFormValues,
          file,
        },
        (value) => value !== undefined,
      );

      if (Object.keys(filteredFormValues).length === 0) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }

      updateProductInternalResponsibleCompany(
        createFormData({
          productId,
          countryId,
          internalResponsibleCompanyId:
            internalResponsibleCompany.internalResponsibleCompanyId,
          ...filteredFormValues,
        }),
      );
    }
  };

  return (
    <>
      {updateMode && (
        <UpdateLog
          productId={productId}
          countryId={countryId}
          documentCode="irc"
        />
      )}
      <ProductInternalResponsibleCompany
        updateMode={updateMode}
        form={form}
        fileName={internalResponsibleCompany?.fileName}
        uploadFileUrl={internalResponsibleCompany?.documentFile}
        onFormFinished={onFormFinished}
        onChangeFile={setFile}
        isOwnSubsidiary={internalResponsibleCompany?.isOwnSubsidiary}
        isCreateNewNMPAAccount={
          internalResponsibleCompany?.isCreateNewNMPAAccount
        }
        bizLicenseFile={file}
        isVisibleNMPA={isVisibleNMPA}
        isVisibleNMPAInput={isVisibleNMPAInput}
        setIsVisibleNMPA={setIsVisibleNMPA}
        setIsVisibleNMPAInput={setIsVisibleNMPAInput}
        readOnlyMode={readOnlyMode}
        getLoading={!!getLoading}
        submitLoading={addLoading || updateLoading}
      />
    </>
  );
};

export default ProductInternalResponsibleCompanyContainer;
