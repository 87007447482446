import palette from 'lib/styles/palette';

export const checkParentElementsForColor = (
  element: HTMLElement | null,
): boolean => {
  if (!element) return false;

  const color = element.style.color;
  if (color && color !== 'rgb(34, 34, 34)' && color !== '#222222') return true;

  const parentElement = element.parentElement;
  if (parentElement && parentElement.tagName.toLowerCase() !== 'pre') {
    return checkParentElementsForColor(parentElement);
  }

  return false;
};

export const checkParentElementsForBackground = (
  element: HTMLElement | null,
): boolean => {
  if (!element) return false;

  const background = element.style.backgroundColor;
  if (
    background &&
    background !== 'transparent' &&
    background !== 'rgb(245, 248, 251)'
  )
    return true;

  const parentElement = element.parentElement;
  if (parentElement && parentElement.tagName.toLowerCase() !== 'pre') {
    return checkParentElementsForBackground(parentElement);
  }

  return false;
};

const textMap: Record<string, string> = {
  'rgb(255, 77, 79)': palette.MESSAGE_ERROR,
  'rgb(24, 144, 255)': palette.MESSAGE_INFO,
  'rgb(41, 191, 95)': palette.MESSAGE_SUCCESS,
};

const textBackgroundMap: Record<string, string> = {
  'rgb(245, 248, 251)': 'transparent',
  'rgb(255, 237, 101)': '#FFED65',
};

export const getTextColor = (element: HTMLElement | null): string => {
  if (!element) return palette.GRAY90;

  const color = element.style.color;
  if (color && color !== 'rgb(34, 34, 34)' && color !== '#222222')
    return textMap[color];

  const parentElement = element.parentElement;
  if (parentElement && parentElement.tagName.toLowerCase() !== 'pre') {
    return getTextColor(parentElement);
  }

  return palette.GRAY90;
};

export const getTextBackground = (element: HTMLElement | null): string => {
  if (!element) return 'transparent';

  const backgroundColor = element.style.backgroundColor;
  if (backgroundColor && backgroundColor !== 'transparent') {
    return textBackgroundMap[backgroundColor];
  }

  const parentElement = element.parentElement;
  if (parentElement && parentElement.tagName.toLowerCase() !== 'pre') {
    return getTextBackground(parentElement);
  }

  return 'transparent';
};

export const getTextStatus = () => {
  const selection: Selection | null = window.getSelection();
  const range = selection?.rangeCount !== 0 && selection?.getRangeAt(0);

  let isBold = false;
  let isUnderlined = false;
  let hasColor = false;
  let hasBackground = false;
  let fontColor = palette.GRAY90;
  let backgroundColor = 'transparent';

  if (range) {
    const startContainer = range.startContainer.parentElement;
    const endContainer = range.endContainer.parentElement;
    const commonContainer = range.commonAncestorContainer.parentElement;

    fontColor =
      getTextColor(startContainer) === getTextColor(endContainer)
        ? getTextColor(endContainer)
        : getTextColor(commonContainer);
    backgroundColor =
      getTextBackground(startContainer) === getTextBackground(endContainer)
        ? getTextBackground(endContainer)
        : getTextBackground(commonContainer);
    hasColor =
      fontColor !== palette.GRAY90 &&
      ((checkParentElementsForColor(startContainer) &&
        checkParentElementsForColor(endContainer)) ||
        checkParentElementsForColor(commonContainer));
    hasBackground =
      (checkParentElementsForBackground(startContainer) &&
        checkParentElementsForBackground(endContainer)) ||
      checkParentElementsForBackground(commonContainer);

    isBold = document.queryCommandState('bold');
    isUnderlined = document.queryCommandState('underline');
  }

  return {
    isBold,
    isUnderlined,
    hasColor,
    hasBackground,
    fontColor,
    backgroundColor,
  };
};
