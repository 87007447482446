import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

type Gutter =
  | number
  | { top?: number; bottom?: number; left?: number; right?: number };

const Container = styled.div<{
  gutter?: Gutter;
}>`
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};
  padding: 24px;

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return `
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return `
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}
`;

const Content = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  white-space: pre-wrap;
  color: ${palette.SLATE_GRAY70};
`;

const SubNoticeCard = ({
  title,
  content,
  bottomText,
  gutter,
}: {
  title?: React.ReactNode;
  content?: React.ReactNode;
  bottomText?: React.ReactNode;
  gutter?: Gutter;
}) => {
  return (
    <Container gutter={gutter}>
      {title && (
        <Typography.Text medium gutter={{ bottom: 8 }}>
          {title}
        </Typography.Text>
      )}
      {content && <Content>{content}</Content>}
      {bottomText && (
        <Typography.Text
          color="SLATE_GRAY70"
          type="BODY_2"
          gutter={{ top: 16 }}
          style={{ letterSpacing: 0 }}
        >
          {bottomText}
        </Typography.Text>
      )}
    </Container>
  );
};

export default SubNoticeCard;
