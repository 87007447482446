import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as estimateActions from 'modules/estimate';

export const useEstimate = () => {
  const dispatch = useDispatch();
  const { companyId, estimate, getLoading } = useSelector(
    ({ auth, company, estimate, loading }) => ({
      companyId: auth.user.companyId,
      company: company.company,
      estimate: estimate.estimate,
      getLoading: loading['estimate/GET_ESTIMATE'],
      submitLoading: loading['estimate/CONTRACT'],
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(estimateActions.getEstimate(companyId));
  }, []);

  return useMemo(() => ({ estimate, loading: getLoading }), [
    estimate,
    getLoading,
  ]);
};
