import qs from 'qs';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import {
  CountryVendor,
  CountryVendorInfo,
  CountryVendorInfoAdd,
  CountryVendorInfoUpdate,
  ProductKeepWarning,
  ProductKeepWarningFetch,
  SeparateWasteCategory,
  SeriesProductInfo,
} from 'types/certificate';
import { ProductCompletedDocument } from 'types/product';

export const getCertificate = (certTargetId: number) =>
  client.get(`/cert-targets/${certTargetId}`);
export const getDocuments = (certTargetId: number) =>
  client.get(`/cert-targets/on/detail/${certTargetId}`);
export const getCompletedDocuments = (certTargetId: number) =>
  client.get<APIResponse<ProductCompletedDocument[]>>(
    `/cert-targets/fin/detail/${certTargetId}`,
  );
export const fixDocument = (productDocStatusId: number) =>
  client.patch(`/doc-status/status/confirm/${productDocStatusId}`);
export const getSeriesInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<{ isSeriesProduct: boolean }>>(
    `/products/${productId}/series-info?${qs.stringify({ countryId })}`,
  );
export const updateSeriesInfo = ({
  productId,
  countryId,
  isSeriesProduct,
}: SeriesProductInfo) =>
  client.put(`/products/${productId}/series-info`, {
    countryId,
    isSeriesProduct,
  });

export const getCountryVendor = (countryId: number) =>
  client.get<APIResponse<CountryVendor>>(
    `/partner-vendor?${qs.stringify({ countryId })}`,
  );

export const getCountryVendorInfo = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<CountryVendorInfo>>(
    `/products/${params.productId}/country-vendor?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );

export const addCountryVendor = (params: CountryVendorInfoAdd) => {
  const { productId, ...rest } = params;
  return client.post(`/products/${productId}/country-vendor`, rest);
};

export const updateCountryVendor = (params: CountryVendorInfoUpdate) => {
  const { productId, ...rest } = params;
  return client.patch(`/products/${productId}/country-vendor`, rest);
};

export const getProductKeepWarning = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<ProductKeepWarning>>(
    `/products/${params.productId}/keep-warning?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );
export const getSeparateWasteCategories = () =>
  client.get<APIResponse<SeparateWasteCategory[]>>(
    '/separate-waste/container-materials',
  );

export const getSeparateWasteCategoryIds = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<
    APIResponse<{
      categoryDataIds: number[];
    }>
  >(
    `/products/${params.productId}/separate-waste?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );

export const addProductKeepWarning = (params: ProductKeepWarningFetch) => {
  const { productId, ...rest } = params;
  return client.post(`/products/${productId}/keep-warning`, rest);
};

export const updateProductKeepWarning = (params: ProductKeepWarningFetch) => {
  const { productId, ...rest } = params;
  return client.patch(`/products/${productId}/keep-warning`, rest);
};

export const putSeparateWaste = (params: {
  productId: number;
  countryId: number;
  categoryDataIds: number[];
}) => {
  const { productId, ...rest } = params;
  return client.put(`products/${productId}/separate-waste`, rest);
};
