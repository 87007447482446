import { useEffect } from 'react';
import { message } from 'antd';

import history from 'lib/history';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { messages } from 'lib/consts';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductRP from 'components/product/ProductRP';

const ProductRPPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const notices = [
    '미국 시장에서 화장품 제품을 유통하는 모든 화장품 제조업체는 FDA의 새로운 포털에 등록해야 합니다.',
    'RP는 제조·포장·유통업자만 가능하며, 반드시 미국 내에 소재할 필요는 없고 미국 외 소재도 가능합니다.',
    '기존 RP 목록에서 RP 추가를 하여 신규 RP를 등록하실 수 있으며 RP 수정은 마이페이지 > RP 관리에서 가능합니다.',
    <MistakeNoticeItem />,
  ];
  const subtitle = 'RP정보';

  useEffect(() => {
    if (!countryId) {
      message.warning(messages.INVALID_APPROACH);
      history.goBack();
    }
  }, []);

  useEffect(() => {
    const rootPageTemplete = document.querySelector(
      '[class*="PageTemplateStyle"]',
    );
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  if (!countryId) return null;

  return (
    <ProductPageTemplate subtitle={subtitle} hasSkip={false} notices={notices}>
      <ProductRP
        productId={productId}
        countryId={countryId}
        subtitle={subtitle}
      />
    </ProductPageTemplate>
  );
};

export default ProductRPPage;
