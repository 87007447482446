import { Badge, Row, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import SearchInput from 'components/system/form/SearchInput';
import Typography from 'components/system/general/Typography';
import Icon from 'components/ui/Icon/Icon';
import ProgressModal from 'components/ui/Modal/ProgressModal';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useOngoing } from 'service/material/icid/ongoing';
import { DocumentStatus } from 'types/common';
import {
  ICIDApplicationStatus,
  ICIDOngoingPageState,
  MaterialIcidApplicationOngoing,
} from 'types/material/icid/icid';
import PCPCQuestionModal from './PCPCQuestionModal/PCPCQuestionModal';

const Container = styled.div`
  .ant-table-container table > thead > tr:first-child {
    th:first-child {
      padding-left: 16px;
    }
    th:last-child {
      padding-right: 16px;
    }
  }
  .ant-table-thead > tr > th {
    padding: 10px 8px;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 8px;
  }
`;

const getStatusTitle = (status: ICIDApplicationStatus) => {
  switch (status) {
    case ICIDApplicationStatus.INPUT:
      return '정보 입력중';
    case ICIDApplicationStatus.ONGOING:
      return '서류 검토 중';
    case ICIDApplicationStatus.APPLYING:
      return 'PCPC 검토중';
    case ICIDApplicationStatus.FINISH:
      return '등재 완료';
    case ICIDApplicationStatus.CANCEL:
      return '진행 취소';
    default:
      return '-';
  }
};

const ICIDOngoing = ({
  pageState,
  onChangePageState,
}: {
  pageState: ICIDOngoingPageState;
  onChangePageState: (state: ICIDOngoingPageState) => void;
}) => {
  const { tradeName, page } = pageState;
  const { icidApplicationOngoings, totalElements } = useOngoing({
    page,
    tradeName,
  });

  const [
    ongoingItemStatus,
    setOngoingItemStatus,
  ] = useState<ICIDApplicationStatus | null>(null);
  const [icidApplicationId, setIcidApplicationId] = useState<number | null>(
    null,
  );

  const handleChangePage = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        tradeName,
      });
    },
    [tradeName],
  );

  const handleSearch = useCallback((tradeName: string) => {
    onChangePageState({
      page: 1,
      tradeName,
    });
  }, []);

  const columns: ColumnsType<MaterialIcidApplicationOngoing> = useMemo(
    () => [
      {
        title: '등재 신청 번호 / 계약 체결일',
        width: 200,
        align: 'center',
        render: (_, { applicationCode, contractDt }) => (
          <Row style={{ flexDirection: 'column' }} align="middle">
            <Typography.Text medium type="BODY_2" color="GRAY90">
              {applicationCode}
            </Typography.Text>
            <Typography.Text type="BODY_2" color="GRAY70">
              {`(${contractDt.slice(0, 10)})`}
            </Typography.Text>
          </Row>
        ),
      },
      {
        title: '원료명(영문)',
        width: 240,
        render: (
          _,
          { tradeName, materialIcidApplicationId, processStatus, status },
        ) => (
          <Typography.Text
            type="BODY_2"
            style={{
              width: 240,
              fontWeight: 500,
              color: palette.GRAY90,
            }}
            onClick={
              status !== ICIDApplicationStatus.CANCEL
                ? () =>
                    history.push(
                      `${path.material.icid.root}/${materialIcidApplicationId}`,
                      {
                        status:
                          processStatus === DocumentStatus.FIN
                            ? 'FIN'
                            : undefined,
                      },
                    )
                : undefined
            }
          >
            {tradeName || '-'}
          </Typography.Text>
        ),
      },
      {
        title: '진행 단계',
        width: 90,
        align: 'center',
        render: (_, { status }) => (
          <Typography.Text
            hover={status !== ICIDApplicationStatus.CANCEL}
            type="BODY_2"
            style={{
              width: 90,
              ...(status !== ICIDApplicationStatus.CANCEL && {
                cursor: 'pointer',
              }),
            }}
            color={
              status !== ICIDApplicationStatus.CANCEL ? 'GRAY90' : 'GRAY50'
            }
            onClick={
              status !== ICIDApplicationStatus.CANCEL
                ? () => setOngoingItemStatus(status)
                : undefined
            }
          >
            {getStatusTitle(status)}
          </Typography.Text>
        ),
      },
      {
        title: '처리 필요',
        align: 'center',
        render: (_, { processStatus, materialIcidApplicationId, status }) => {
          switch (processStatus) {
            case DocumentStatus.INP:
              return (
                <Tags.Mark
                  type="arrow"
                  color="blue"
                  onClick={
                    status !== ICIDApplicationStatus.CANCEL
                      ? () =>
                          history.push(
                            `${path.material.icid.root}/${materialIcidApplicationId}`,
                          )
                      : undefined
                  }
                >
                  필수 입력
                </Tags.Mark>
              );
            case DocumentStatus.OPT:
              return (
                <Tags.Mark
                  type="arrow"
                  color="blue"
                  onClick={
                    status !== ICIDApplicationStatus.CANCEL
                      ? () =>
                          history.push(
                            `${path.material.icid.root}/${materialIcidApplicationId}`,
                          )
                      : undefined
                  }
                >
                  선택 입력
                </Tags.Mark>
              );
            case DocumentStatus.MOD:
              return (
                <Tags.Mark
                  type="arrow"
                  color="blue"
                  onClick={
                    status !== ICIDApplicationStatus.CANCEL
                      ? () =>
                          history.push(
                            `${path.material.icid.root}/${materialIcidApplicationId}`,
                          )
                      : undefined
                  }
                >
                  보완 필요
                </Tags.Mark>
              );
            case DocumentStatus.ONG:
              return (
                <Tags.Mark
                  type="arrow"
                  color="blue"
                  onClick={
                    status !== ICIDApplicationStatus.CANCEL
                      ? () =>
                          history.push(
                            `${path.material.icid.root}/${materialIcidApplicationId}`,
                          )
                      : undefined
                  }
                >
                  검토 중
                </Tags.Mark>
              );
            case DocumentStatus.FIN:
              return (
                <Typography.Text
                  type="BODY_2"
                  onClick={
                    status !== ICIDApplicationStatus.CANCEL
                      ? () =>
                          history.push(
                            `${path.material.icid.root}/${materialIcidApplicationId}`,
                            {
                              status: 'FIN',
                            },
                          )
                      : undefined
                  }
                >
                  입력 완료
                </Typography.Text>
              );
          }
        },
      },
      {
        title: 'PCPC Question',
        width: 128,
        align: 'center',
        render: (_, { hasPcpcQuestion, status, materialIcidApplicationId }) =>
          status === ICIDApplicationStatus.CANCEL || !hasPcpcQuestion ? (
            ''
          ) : (
            <div
              style={{ cursor: 'pointer', paddingTop: 6 }}
              onClick={() => setIcidApplicationId(materialIcidApplicationId)}
            >
              <Badge
                dot
                style={{
                  top: 2,
                  right: 2,
                  backgroundColor: palette.MESSAGE_ERROR,
                  boxShadow: 'none',
                }}
              >
                <Icon name="question" />
              </Badge>
            </div>
          ),
      },
    ],
    [getStatusTitle, setOngoingItemStatus],
  );

  return (
    <Container>
      {ongoingItemStatus && (
        <ProgressModal
          width={872}
          title="ICID 원료 등재 진행 상태 단계별 안내"
          steps={[
            {
              code: ICIDApplicationStatus.INPUT,
              title: '정보 입력중',
              content: '등재에 필요한 필수 정보를 입력 중인 단계입니다.',
            },
            {
              code: ICIDApplicationStatus.ONGOING,
              title: '서류 검토중',
              content:
                '입력해 주신 정보를 certicos에서 담당자가 검토 중에 있습니다.',
            },
            {
              code: ICIDApplicationStatus.APPLYING,
              title: 'PCPC 검토중',
              content:
                '입력해 주신 Application Form에 맞춰 PCPC에 제출하였으며, 해당 원료가 바로 등재되거나 PCPC 위원회로부터 보완이 올수 있는 단계입니다. ICID 등재 메인 화면 배너에 고시된 PCPC 위원회 일정에 따라 해당 상태가 길어질 수도 있습니다.',
            },
            {
              code: ICIDApplicationStatus.FINISH,
              title: '등재 완료',
              customContent: (
                <Typography.Text type="BODY_2">
                  등재 완료 : 등재가 완료되어 해당 원료가{' '}
                  <Typography.Text
                    type="BODY_2"
                    inline
                    underline
                    color="PRIMARY50"
                    onClick={() => history.push(path.material.icid.registered)}
                  >
                    등재 완료 원료 관리
                  </Typography.Text>
                  로 이동됩니다.
                </Typography.Text>
              ),
            },
          ]}
          currentStepCode={ongoingItemStatus}
          onClose={() => setOngoingItemStatus(null)}
        />
      )}
      {icidApplicationId && (
        <PCPCQuestionModal
          icidApplicationId={icidApplicationId}
          onClose={() => setIcidApplicationId(null)}
        />
      )}
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <SearchInput
          placeholder="원료명(영문) 검색"
          defaultValue={tradeName}
          onSearch={handleSearch}
          style={{ width: 320 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={icidApplicationOngoings}
        rowKey="materialIcidApplicationId"
        pagination={{
          current: page,
          onChange: handleChangePage,
          total: totalElements,
        }}
        style={{ marginTop: 24 }}
      />
    </Container>
  );
};

export default ICIDOngoing;
