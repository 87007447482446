import { Button } from 'antd';
import styled, { css } from 'styled-components';
import { Typography } from 'components/system';
import Icon from '../Icon/Icon';

const StyledButton = styled(Button)<{
  $disableAnim?: boolean;
}>`
  position: relative;
  height: 32px;

  .material-icons {
    transform: scaleX(-1);
    font-size: 16px;
  }

  ${({ $disableAnim }) =>
    !$disableAnim &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 50%;
        display: block;
        width: 16px;
        height: 16px;
        background-color: #2cf4f0;
        transform: translateX(-50%);
        opacity: 0.44;
        border-radius: 50%;
        animation-name: flicker;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;

        @keyframes flicker {
          from {
            transform: scale(1);
          }

          50% {
            transform: scale(3.5);
          }

          to {
            transform: scale(1);
          }
        }
      }
    `}
`;

interface CorrectButtonProps extends React.ComponentProps<typeof Button> {
  disableAnim?: boolean;
}

/**
 * HINT: index.js:1 Warning: React does not recognize the `disableAnim` prop on a DOM element.
 * If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `disableanim` instead.
 * If you accidentally passed it from a parent component, remove it from the DOM element.
 * 위와 같은 에러가 발생하여 본 스타일드 컴포넌트를 사용한 버튼의 disableAnim 속성에 $ prefix 추가
 */
const CorrectButton = ({ disableAnim, ...props }: CorrectButtonProps) => (
  <StyledButton
    {...props}
    $disableAnim={disableAnim}
    className="ant-btn-gray"
    icon={
      <Icon
        name="textsms"
        color="GRAY70"
        size={16}
        style={{ marginRight: 4 }}
      />
    }
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography.Text type="BODY_2" inline>
      보완 문구 보기
    </Typography.Text>
  </StyledButton>
);

export default CorrectButton;
