import ProductPageTemplate from 'templates/ProductPageTemplate';
import Typography from 'components/system/general/Typography';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPMA from 'components/product/ProductPMA';

const notices = [
  '중국 “화장품 허가 등록 자료 관리 규정" 제 29조에 의거하여 처방에 포함되어 있는 원료에 필요한 추가 자료가 있을 시 업로드해 주세요.',
  <>
    해당 서류는 반드시{' '}
    <Typography.Text type="BODY_2" medium inline>
      원료사의 레터헤드
    </Typography.Text>
    로 된{' '}
    <Typography.Text type="BODY_2" medium inline>
      영문 서류
    </Typography.Text>
    여야 하며,{' '}
    <Typography.Text type="BODY_2" medium inline>
      담당자의 서명이 포함
    </Typography.Text>
    되어야 합니다.
  </>,
  <MistakeNoticeItem />,
];

const ProductPMAPage = () => {
  return (
    <ProductPageTemplate
      subtitle="원료 자료"
      notices={notices}
      exampleImg="PMA.png"
      subnotices={{
        title: '화장품 허가 등록 자료 관리 규정 제 29조 기준',
        content: `*변성에탄올 : 변성제의 명칭 및 용량\n*식물 유래 원료 : 구체적인 사용부위 (추출물, 식물성 오일 등)\n*향료 : Allergens List & IFRA 자료\n*동물, 기타 Origin 원료 : 출처 및 제조공정도, 원료 국가 관련 서류\n*원료 안전성 보고서 (ANNEX 14) 또는 NMPA 원료신고번호`,
        bottomText: (
          <>
            <Typography.Text type="BODY_2" medium inline>
              “화장품 허가 등록 자료 관리 규정"
            </Typography.Text>{' '}
            제 29조에 따라, 원료별로 필요한 추가 자료이며 인증 과정에서 법령에
            명시되어 있는 내용 외의 보완사항이
            <br />
            있을 수 있습니다.
          </>
        ),
      }}
    >
      <ProductPMA />
    </ProductPageTemplate>
  );
};

export default ProductPMAPage;
