import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

import { Typography } from 'components/system';
import ContractGuide from 'components/contract/ContractGuide';
import FooterBox from 'components/FooterBox';
import history from 'lib/history';
import path from 'lib/path';
import Icon from 'components/ui/Icon/Icon';

const EstimatePaymentBlock = styled.div``;

const EstimatePayment = ({
  isNMPAEstimate = false,
}: {
  isNMPAEstimate?: boolean;
}) => {
  return (
    <EstimatePaymentBlock>
      <Row
        justify="center"
        align="middle"
        gutter={[16, 16]}
        style={{ marginBottom: isNMPAEstimate ? 80 : 120 }}
      >
        <Col xs={{ flex: '0 0 100%' }} sm={{ flex: 'auto' }}>
          <Icon name="check" size={96} color="PRIMARY50" />
        </Col>
        <Col xs={{ flex: '0 0 100%' }} sm={{ flex: 'auto' }}>
          <Typography.Text light type="HEADLINE_1">
            계약이 체결되었습니다.
            <br />서 결제 진행 및 계약서를 송부해 주세요.
          </Typography.Text>
        </Col>
      </Row>
      <ContractGuide isNMPAEstimate={isNMPAEstimate} />
      {isNMPAEstimate && (
        <FooterBox>
          <Button
            type="primary"
            size="large"
            onClick={() => history.push(`${path.my}/nmpa`)}
          >
            회사 정보 입력하기
          </Button>
        </FooterBox>
      )}
    </EstimatePaymentBlock>
  );
};

export default EstimatePayment;
