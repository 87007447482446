import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Row, Col, Drawer, Divider, Badge } from 'antd';

import TopSubMenuContainer from 'containers/menu/TopSubMenuContainer';
import UserNoticeDrawerContainer from 'containers/service/UserNoticeDrawerContainer';
import { deviceSize, size } from 'lib/styles';
import MaterialTopMenu from './material/menu/TopMenu';
import ManufactureTopMenu from './manufacturer/menu/TopMenu';
import { CompanyType } from 'types/company';
import BrandTopMenu from './menu/BrandTopMenu';
import Logo from './system/general/icon/Logo';
import Icon from './ui/Icon/Icon';

const NavigatorBlock = styled.div`
  max-width: ${size.layout.navigatorMax};
  margin: 0 auto;

  .ant-badge {
    vertical-align: middle;
  }
`;

const CollapsableCol = styled(Col)<{
  collapsedsize: 'sm' | 'mdOver';
  position: 'relative' | 'static';
}>`
  position: ${({ position }) => position};
  ${({ collapsedsize }) => css`
    @media ${deviceSize[collapsedsize]} {
      display: none;
    }
  `}
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Navigator = ({
  drawerVisible,
  newUserNoticeCount,
  companyType,
  onToggleDrawer,
  onClickUserNotice,
}: {
  drawerVisible: boolean;
  newUserNoticeCount: number;
  companyType: CompanyType;
  onToggleDrawer: () => void;
  onClickUserNotice: () => void;
}) => {
  const user = useSelector(({ auth }: any) => auth.user);
  const isLoggedIn = user !== null;
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  const renderTopMenu = (inlineMode = false) => {
    switch (companyType) {
      case CompanyType.MANUFACTURE:
        return <ManufactureTopMenu inlineMode={inlineMode} />;
      case CompanyType.MATERIAL:
        return <MaterialTopMenu inlineMode={inlineMode} />;
      default:
        return <BrandTopMenu inlineMode={inlineMode} />;
    }
  };
  return (
    <NavigatorBlock>
      <Row
        align="middle"
        justify="space-between"
        wrap={false}
        style={{
          height: isMobile ? 56 : 80,
          maxWidth: '1088px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Col>
          <Link to="/">
            <Logo height={isMobile ? 24 : 32} />
          </Link>
        </Col>
        <CollapsableCol flex="1 1 100%" position="static" collapsedsize="sm">
          {renderTopMenu()}
        </CollapsableCol>
        <CollapsableCol flex="0 0 auto" collapsedsize="sm" position="relative">
          <TopSubMenuContainer />
        </CollapsableCol>
        <CollapsableCol collapsedsize="mdOver" position="relative">
          <Row gutter={30}>
            {isLoggedIn && (
              <Col>
                <Badge
                  dot={newUserNoticeCount > 0}
                  style={{
                    top: -2,
                    right: -2,
                    width: 5,
                    height: 5,
                    minWidth: 5,
                  }}
                >
                  <Icon
                    name="bell"
                    size={18}
                    onClick={onClickUserNotice}
                    style={{ position: 'relative', top: -4, cursor: 'pointer' }}
                  />
                </Badge>
              </Col>
            )}
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name="hamburgerMenu"
                size={24}
                onClick={onToggleDrawer}
                style={{ marginBottom: 4 }}
              />
            </Col>
          </Row>
        </CollapsableCol>
      </Row>
      <Drawer
        placement="right"
        closable={false}
        visible={drawerVisible}
        maskClosable
        bodyStyle={{ padding: 12 }}
        onClose={onToggleDrawer}
      >
        <DrawerHeader onClick={onToggleDrawer}>
          <Icon name="close" size={16} style={{ cursor: 'pointer' }} />
        </DrawerHeader>
        <Divider style={{ margin: '12px -12px', width: 265 }} />
        {renderTopMenu(true)}
        <TopSubMenuContainer inlineMode />
      </Drawer>
      <UserNoticeDrawerContainer />
    </NavigatorBlock>
  );
};

export default Navigator;
