import { Button, FormInstance } from 'antd';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';

import Table from 'components/Table';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { ScreeningFormData } from 'pages/brand/formulaScreening/FormulaScreeningPage';
import { FormulaArtworkScreeningItem } from 'types/brand/artworkScreening/artworkScreening';
import StickyButtonWrapper from './StickyButtonWrapper';

const ArtworkStep2 = ({
  form,
  file,
  method,
  __html,
  formulaScreeningResult,
  setHTML,
  setFile,
  setStep,
}: {
  form: FormInstance<ScreeningFormData>;
  file: File | null;
  method: number;
  __html?: string;
  formulaScreeningResult?: FormulaArtworkScreeningItem | null;
  setHTML?: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const canGoNextStep =
    method === 2
      ? formulaScreeningResult?.list.every(({ isWtValid }) => isWtValid) &&
        !formulaScreeningResult.isRowError
      : formulaScreeningResult?.list.every(({ isWtValid }) => isWtValid);
  return (
    <Container>
      <InnerContainer>
        <Flex
          gap={8}
          style={{
            marginTop: 56,
            paddingBottom: 8,
            borderBottom: `2px solid ${palette.PRIMARY50}`,
            width: '100%',
          }}
          align="center"
        >
          <Flex gap={4} align="center">
            <Icon name="robot" size={24} />
            <Typography.Text type="TITLE_1">전성분표 분석</Typography.Text>
          </Flex>
        </Flex>
        <DescriptionContainer gap={4} dir="column">
          <Typography.Text type="BODY_2">
            · 성분사전과 비교 분석한 내용이 표시됩니다.
          </Typography.Text>
          <Typography.Text type="BODY_2">
            · 성분사전에 등재된 원료들이 화장품에 반드시 사용 가능한 것은
            아니며, 국가별 성분의 사용 여부는 '성분 스크리닝' 페이지에서
            확인하실 수 있습니다.
          </Typography.Text>
          <Typography.Text type="BODY_2">
            · 전성분표에서 알러젠에 표기 방식이 제조사마다 상이하여, 퍼센트 총
            합 계산은 해당 단계에서 진행되지 않습니다.
          </Typography.Text>
        </DescriptionContainer>
        <ResultContainer>
          {formulaScreeningResult?.list.every(
            ({ ingredientName }) => ingredientName,
          ) &&
            formulaScreeningResult?.list.some(
              ({ isNameValid }) => !isNameValid,
            ) && (
              <Flex gap={4} align="center" gutter={{ bottom: 4 }}>
                <StatusIcon status="warning" />
                <Typography.Text color="GRAY90" type="BODY_2">
                  표시된 성분명을 확인해 주세요.
                </Typography.Text>
              </Flex>
            )}
          {formulaScreeningResult?.list.some(
            ({ ingredientName }) => !ingredientName,
          ) &&
            formulaScreeningResult?.isRowError &&
            formulaScreeningResult?.list.some(
              ({ isNameValid }) => !isNameValid,
            ) && (
              <Flex gap={4} align="center">
                <StatusIcon status="error" />
                <Typography.Text color="GRAY90" type="BODY_2">
                  성분명을 입력해 주세요.
                </Typography.Text>
              </Flex>
            )}
          {formulaScreeningResult?.list.some(({ isWtValid }) => !isWtValid) && (
            <Flex gap={4} align="center">
              <StatusIcon status="error" />
              <Typography.Text color="GRAY90" type="BODY_2">
                특정 셀에 오류가 있습니다. 수정 후 다시 업로드해 주세요.
              </Typography.Text>
            </Flex>
          )}
          {!formulaScreeningResult?.isRowError &&
            formulaScreeningResult?.list.every(
              ({ isWtValid, isNameValid }) => isWtValid && isNameValid,
            ) && (
              <Flex gap={4} align="center">
                <StatusIcon status="success" />
                <Typography.Text color="GRAY90" type="BODY_2">
                  {method === 2
                    ? '정상적으로 업로드되었습니다.'
                    : '정상적으로 입력되었습니다.'}
                </Typography.Text>
              </Flex>
            )}
          <Table style={{ marginTop: 8 }} bordered>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ textAlign: 'center' }}>No.</Table.Th>
                <Table.Th style={{ textAlign: 'center' }}>성분명</Table.Th>
                <Table.Th style={{ textAlign: 'center' }}>WT %</Table.Th>
                <Table.Th style={{ textAlign: 'center' }}>CAS No.</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {formulaScreeningResult?.list?.map(
                (
                  {
                    ingredientId,
                    ingredientName,
                    wt,
                    casNo,
                    isNameValid,
                    isWtValid,
                  },
                  index,
                ) => {
                  const decimalError =
                    wt &&
                    !isNaN(Number(wt)) &&
                    !/^-?\d+(\.\d{1,25})?$/g.test(wt);

                  return (
                    <Table.Tr key={nanoid()} className="tb_row">
                      <Table.Td width={36} style={{ textAlign: 'center' }}>
                        {index + 1}
                      </Table.Td>
                      <Table.Td width={758} style={{ textAlign: 'left' }}>
                        <Typography.Text
                          type="SMALL"
                          color={isNameValid ? 'GRAY70' : 'YELLOW_60'}
                        >
                          {ingredientName || ''}
                        </Typography.Text>
                      </Table.Td>
                      <Table.Td
                        width={120}
                        className="wt"
                        style={{ textAlign: 'left' }}
                      >
                        <Typography.Text
                          type="SMALL"
                          color={isWtValid ? 'GRAY70' : 'MESSAGE_ERROR'}
                        >
                          {wt || ''}
                        </Typography.Text>
                        {!isWtValid && (
                          <MessageTip
                            style={{
                              width: decimalError ? 208 : 128,
                            }}
                          >
                            WT % :{' '}
                            {decimalError
                              ? '소수점 최대 25자리까지 입력'
                              : '숫자로 입력'}
                          </MessageTip>
                        )}
                      </Table.Td>
                      <Table.Td width={120} style={{ textAlign: 'start' }}>
                        {casNo || ''}
                      </Table.Td>
                    </Table.Tr>
                  );
                },
              )}
            </Table.Tbody>
          </Table>
        </ResultContainer>
      </InnerContainer>
      <StickyButtonWrapper>
        <Button
          htmlType="button"
          style={{
            width: 200,
            height: 56,
            fontSize: 18,
          }}
          type="default"
          onClick={() => setStep(1)}
        >
          이전
        </Button>
        <Button
          style={{
            width: 200,
            height: 56,
            fontSize: 18,
          }}
          htmlType="button"
          type="primary"
          onClick={() => setStep(3)}
          disabled={!canGoNextStep}
        >
          다음
        </Button>
      </StickyButtonWrapper>
    </Container>
  );
};

const MessageTip = styled(Flex)`
  background-color: white;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  align-items: center;
  left: 56px;
  bottom: -14px;
  left: 16px;
  z-index: 10;
  font-size: 12px;
  color: ${palette.GRAY90};
  height: 32px;
  justify-content: center;
  box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
`;

const Container = styled.div``;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;

  .ant-table {
    font-size: 12px;
    color: ${palette.GRAY70};
    border-color: ${palette.GRAY30};
  }

  .ant-table-wrapper .ant-table {
    overflow: visible;
  }

  .ant-table-thead > tr > th {
    padding: 10px 12px;
    border-bottom: 1px solid ${palette.GRAY30};
    &:not(:last-of-type) {
      border-right: none !important;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${palette.GRAY30};
    padding: 18px 16px;
    position: relative;
    &:not(:last-of-type) {
      border-right: none !important;
    }
  }
  .tb_row {
    transition: background-color 150ms ease;
  }
  .tb_row:hover {
    background-color: ${palette.GRAY10};
    ${MessageTip} {
      visibility: visible;
    }
  }
`;

const ResultContainer = styled.div`
  margin-top: 24px;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  color: ${palette.SLATE_GRAY70};
`;

export default ArtworkStep2;
