import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Typography } from 'components/system';
import InputNumber from 'components/ui/InputNumber';
import { useModal } from 'hook/useModal';
import { filterOptionForStringLabel } from 'lib/form';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import { useAddEstimateInquiry, useEstimateServiceTypes } from 'service/common';
import { useCountries } from 'service/country';
import { AddEstimateInquiryParams } from 'types/common';

interface EstimateContactForm {
  companyName: string;
  inquiryUserName: string;
  inquiryUserMobile: string;
  inquiryUserEmail: string;
  serviceElementIds: string[];
  etcServiceElement?: string; // 그 외 기타, 선택 필드
  skuCount: string;
  countryIds: string[];
  inquiryContent: string;
  isAgreePrivacyTerms: boolean;
}

type SelectOptions = {
  label: string;
  value: string;
}[];

export interface EstimateContactModalProps {
  onClose?: () => void;
}

interface EstimateContactModalUIProps extends EstimateContactModalProps {
  initialFormValues?: Partial<EstimateContactForm>;
  onSubmit?: (values: EstimateContactForm) => void;
  serviceElementOptions?: SelectOptions;
  countryOptions?: SelectOptions;
  submitting?: boolean;
}

const Body = styled.div`
  margin-bottom: 32px;

  textarea {
    resize: none;
    padding: 12px 16px;
  }

  .select-country .ant-select-selector,
  .ant-select-selection-overflow {
    max-height: 56px;
    overflow-y: auto;
  }

  .ant-select-selector {
    overflow: hidden !important;
  }
`;

const Footer = styled.div`
  > button {
    width: 100%;
  }
`;

const RemoveFormErrorHide = styled.div`
  .ant-form-item-explain {
    height: 0px;
    max-height: 0px;
    min-height: 0px;
  }
`;

export const EstimateContactModalUI = ({
  initialFormValues,
  onSubmit,
  onClose,
  submitting,
  serviceElementOptions = [],
  countryOptions = [],
}: EstimateContactModalUIProps) => {
  const [serviceElementEtcVisible, setServiceElementEtcVisible] = useState(
    false,
  );
  const [isAgreePrivacyTerms, setIsAgreePrivacyTerms] = useState(false);
  const [content, setContent] = useState<string>('');
  const [form] = Form.useForm<EstimateContactForm>();
  const { openPrivacyModal } = useModal();

  const handleAgreePrivacyTermsClick = () => {
    if (isAgreePrivacyTerms) {
      setIsAgreePrivacyTerms(false);
      form.validateFields(['isAgreePrivacyTerms']);
      return;
    }

    openPrivacyModal({
      type: 'estimateContact',
      onAgree: () => {
        setIsAgreePrivacyTerms(true);
        form.validateFields(['isAgreePrivacyTerms']);
      },
    });
  };

  const handleFormValueChange: ComponentProps<
    typeof Form
  >['onValuesChange'] = () => {
    const { serviceElementIds, inquiryContent } = form.getFieldsValue();
    setServiceElementEtcVisible(
      serviceElementIds?.find(
        (value) =>
          value ===
          serviceElementOptions.find((option) => option.label === '그 외 기타')
            ?.value,
      ) !== undefined,
    );
    setContent(inquiryContent || '');
  };

  const handleSubmit = (values: EstimateContactForm) => {
    onSubmit?.(values);
  };

  useEffect(() => {
    if (!initialFormValues) return;

    form.setFieldsValue(initialFormValues);
  }, [initialFormValues]);

  return (
    <Modal
      visible={true}
      width={584}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        form={form}
        onValuesChange={handleFormValueChange}
        onFinish={handleSubmit}
      >
        <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
          견적 문의
        </Typography.Text>
        <Body>
          <Form.Item
            required
            label="회사명 (국문)"
            name="companyName"
            rules={generateFormRules({ required: true, maxLength: 100 })}
          >
            <Input placeholder="국문" />
          </Form.Item>
          <Row gutter={8} align="top">
            <Col xs={{ span: 12 }}>
              <Form.Item
                required
                label="문의자명"
                name="inquiryUserName"
                rules={generateFormRules({ required: true, maxLength: 50 })}
              >
                <Input placeholder="문의하시는 분의 성함을 입력" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item
                required
                label="연락처"
                name="inquiryUserMobile"
                rules={generateFormRules({
                  required: true,
                  maxLength: 11,
                  onlyNumber: true,
                })}
              >
                <Input placeholder="숫자만 입력 가능" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            required
            label="이메일"
            name="inquiryUserEmail"
            rules={generateFormRules({
              required: true,
              maxLength: 50,
              exceptKorean: true,
              email: true,
            })}
          >
            <Input placeholder="영문" />
          </Form.Item>
          <Form.Item
            required
            label="서비스 항목"
            name="serviceElementIds"
            rules={generateFormRules({ required: true })}
          >
            <Select
              placeholder="서비스 항목 선택"
              showArrow
              mode="multiple"
              options={serviceElementOptions}
              filterOption={filterOptionForStringLabel}
            />
          </Form.Item>
          {serviceElementEtcVisible && (
            <Form.Item
              required
              name="etcServiceElement"
              rules={generateFormRules({ required: true, maxLength: 50 })}
            >
              <Input placeholder="그 외 기타 입력" />
            </Form.Item>
          )}
          <Row gutter={8} align="top">
            <Col xs={{ span: 12 }}>
              <Form.Item
                required
                label="SKU"
                name="skuCount"
                rules={generateFormRules({ required: true })}
              >
                <InputNumber
                  placeholder="숫자 입력"
                  style={{ width: '100%', height: '100%' }}
                  min={1}
                  max={999}
                  keyboard={false}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item label="국가" name="countryIds">
                <Select
                  className="select-country"
                  placeholder="국가 선택"
                  mode="multiple"
                  options={countryOptions}
                  filterOption={filterOptionForStringLabel}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="문의 내용"
            name="inquiryContent"
            rules={generateFormRules({
              maxLength: 1000,
            })}
            style={{
              marginBottom: 0,
            }}
          >
            <TextArea rows={3} placeholder="문의 내용 입력" maxLength={1000} />
          </Form.Item>
          <Typography.Text
            type="BODY_2"
            style={{ textAlign: 'right' }}
            color="GRAY70"
          >
            {content.length} / 1000
          </Typography.Text>
          <RemoveFormErrorHide>
            <Form.Item
              name="isAgreePrivacyTerms"
              rules={[
                {
                  validator: () =>
                    isAgreePrivacyTerms
                      ? Promise.resolve()
                      : Promise.reject('필수'),
                },
              ]}
            >
              <Checkbox
                checked={isAgreePrivacyTerms}
                onClick={handleAgreePrivacyTermsClick}
                style={{
                  width: '100%',
                  backgroundColor: palette.SLATE_GRAY10,
                  borderRadius: 8,
                  padding: '16px 24px',
                  marginTop: 24,
                }}
              >
                <Typography.Text color="SLATE_GRAY70" inline medium>
                  개인 정보 수집 및 이용 동의
                </Typography.Text>
              </Checkbox>
            </Form.Item>
          </RemoveFormErrorHide>
        </Body>
        <Footer>
          <Button type="primary" htmlType="submit" loading={submitting}>
            문의하기
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

const EstimateContactModal = (props: EstimateContactModalProps) => {
  const [initialFormValues, setInitialFormValues] = useState<
    Partial<EstimateContactForm>
  >();
  const { estimateServiceTypes } = useEstimateServiceTypes();
  const { addEstimateInquiry, isLoading } = useAddEstimateInquiry();
  const countries = useCountries();
  const user = useSelector(({ auth }: any) => auth.user);

  // Warning: `value` of Option should not use number type when `mode` is `tags` or `combobox`.
  // 위 에러로 value string으로 변경 사용
  const serviceElementOptions = useMemo(() => {
    return estimateServiceTypes.map((type) => ({
      label: type.serviceTypeName,
      value: type.estimateServiceTypeId.toString(),
    }));
  }, [estimateServiceTypes]);

  // Warning: `value` of Option should not use number type when `mode` is `tags` or `combobox`.
  // 위 에러로 value string으로 변경 사용
  const countryOptions = useMemo(() => {
    return countries
      .map((country) => ({
        label: country.countryNameKo,
        value: country.countryId.toString(),
      }))
      .sort((a, b) => {
        if (b.label === '유럽연합') return 1;
        else if (b.label === '영국') return 1;
        else if (a.label === '유럽연합') return -1;
        else if (a.label === '영국') return -1;
        return 0;
      });
  }, [countries]);

  const handleSubmit: EstimateContactModalUIProps['onSubmit'] = ({
    companyName,
    countryIds,
    inquiryContent,
    inquiryUserEmail,
    inquiryUserMobile,
    inquiryUserName,
    serviceElementIds,
    skuCount,
    etcServiceElement,
  }) => {
    const etcServiceElementId = estimateServiceTypes.find(
      (type) => type.serviceTypeName === '그 외 기타',
    )?.estimateServiceTypeId;

    const serviceTypes = serviceElementIds?.map((serviceElementId) => ({
      estimateServiceTypeId: Number(serviceElementId),
      etcServiceTypeName:
        Number(serviceElementId) === etcServiceElementId
          ? etcServiceElement || ''
          : null,
    }));

    const params: AddEstimateInquiryParams = {
      companyName,
      inquiryUserName,
      inquiryUserMobile,
      inquiryUserEmail,
      skuCount: Number(skuCount),
      serviceTypes,
      isAgreePrivacyTerms: true,
      countryIds: countryIds?.map(Number),
      inquiryContent: inquiryContent || '',
    };

    addEstimateInquiry(params, {
      onSuccess: () => {
        message.success('견적 문의가 등록되었습니다.');
        props.onClose?.();
      },
    });
  };

  useEffect(() => {
    if (!user) return;

    setInitialFormValues({
      companyName: user.company.companyNameKo,
      inquiryUserMobile: user.mobile,
      inquiryUserEmail: user.email,
      inquiryUserName: user.name,
    });
  }, [user]);

  return (
    <EstimateContactModalUI
      {...props}
      initialFormValues={initialFormValues}
      serviceElementOptions={serviceElementOptions}
      countryOptions={countryOptions}
      submitting={isLoading}
      onSubmit={handleSubmit}
    />
  );
};

export default EstimateContactModal;
