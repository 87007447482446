import { ReactNode } from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

export interface PrivacyModalProps {
  type: 'adInquiry' | 'estimateContact';
  onAgree?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

const contents = {
  adInquiry: {
    guide:
      '주식회사 씨디알아이는 이용자의 개인 정보 보호를 중요시하며, "정보통신망 이용 촉진 및 정보 보호"에 관한 법률을 준수합니다. 광고 및 제휴 문의에 대한 개인 정보 수집, 이용 목적은 아래 내용과 같습니다.',
    elements: (
      <>
        [필수] 회사(소속)명, 담당자 이름, 이메일주소, 내용
        <br />
        [선택] 휴대폰번호
      </>
    ),
    purpose: '광고 및 제휴 문의에 따른 원활한 안내 제공',
    period: '목적 달성 시까지',
  },
  estimateContact: {
    guide:
      '주식회사 씨디알아이는 이용자의 개인 정보 보호를 중요시하며, "정보통신망 이용 촉진 및 정보 보호"에 관한 법률을 준수합니다. 견적 문의에 대한 개인 정보 수집, 이용 목적은 아래 내용과 같습니다.',
    elements: (
      <>
        [필수] 회사명(국문), 문의자명, 연락처, 이메일 주소, 인증 유형, SKU
        <br />
        [선택] 국가, 문의 내용
      </>
    ),
    purpose: '견적 문의에 따른 원활한 안내 제공',
    period: '목적 달성 시까지',
  },
};

const Card = styled.div`
  padding: 24px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 13px;
  margin-bottom: 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: center;
`;

const CancelButton = styled(Button)`
  cursor: pointer;
  color: ${palette.SLATE_GRAY60} !important;
  background-color: ${palette.SLATE_GRAY30} !important;
  border: none;
  font-weight: 500;

  &:hover {
    opacity: 0.7;
    color: ${palette.SLATE_GRAY60};
    background-color: ${palette.SLATE_GRAY30};
  }
`;

const CardTitle = (props: { children: ReactNode }) => (
  <Typography.Text medium gutter={{ bottom: 4 }} {...props} />
);

const PrivacyModal = ({
  type,
  onAgree,
  onCancel,
  onClose,
}: PrivacyModalProps) => {
  return (
    <Modal visible={true} width={560} footer={null} onCancel={onClose}>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 32 }}>
        개인 정보 수집 및 이용 동의
      </Typography.Text>
      <Typography.Text type="BODY_2" gutter={{ bottom: 16 }} color="GRAY70">
        {contents[type].guide}
      </Typography.Text>
      <Card>
        <CardTitle>수집 항목</CardTitle>
        <Typography.Text
          type="SMALL"
          gutter={{ bottom: 16 }}
          color="SLATE_GRAY70"
        >
          {contents[type].elements}
        </Typography.Text>
        <CardTitle>수집 목적</CardTitle>
        <Typography.Text
          type="SMALL"
          gutter={{ bottom: 16 }}
          color="SLATE_GRAY70"
        >
          {contents[type].purpose}
        </Typography.Text>
        <CardTitle>보유 기간</CardTitle>
        <Typography.Text type="SMALL" color="SLATE_GRAY70">
          {contents[type].period}
        </Typography.Text>
      </Card>
      <ButtonGroup>
        <CancelButton
          onClick={() => {
            onCancel?.();
            onClose?.();
          }}
        >
          취소
        </CancelButton>
        <Button
          type="primary"
          onClick={() => {
            onAgree?.();
            onClose?.();
          }}
        >
          동의합니다.
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default PrivacyModal;
