import { useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import CompanyStat from 'components/main/CompanyStat';
import CompanyStatsBlock from 'components/main/CompanyStatsBlock';
import Shortcut from 'components/main/Shortcut';
import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import history from 'lib/history';
import path from 'lib/path';
import { useCompanyStatistics } from 'service/material/company';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { ReactComponent as ArtworkScreeningImg } from 'asset/svg/artwork_screening.svg';

const MainPage = () => {
  const { openStibeePopUpModal } = useModal();
  const { companyName } = useSelector(
    ({ company }: any) => ({
      companyName: company.company?.companyNameKo,
    }),
    shallowEqual,
  );
  const { companyStatistics, isLoading } = useCompanyStatistics();
  const {
    materialOnCount = 0,
    materialFinCount = 0,
    icidCompleteCount = 0,
    nmpaCompleteCount = 0,
  } = { ...companyStatistics };

  useEffect(() => {
    const expireDate = window.localStorage.getItem('ST_POP');
    let today = new Date();

    if (!expireDate || Number(today.getTime()) > Number(expireDate)) {
      openStibeePopUpModal({});
    }
    if (expireDate === 'Infinity') {
      return;
    }
  }, []);

  return (
    <MainPageBlock>
      <Row gutter={[16, 32]} justify="center">
        <Col>
          <CompanyStatsBlock>
            <Typography.Text light type="HEADLINE_1" align="center" mark>
              안녕하세요. <br className="mobile-br" />
              <mark>{companyName}</mark> 님!
              <br /> 화장품 산업 발전 연구소 CDRI 입니다.
            </Typography.Text>
            <Spin spinning={isLoading}>
              <Flex gap={16} style={{ marginTop: 52 }} justify="center">
                <CompanyStat
                  amount={materialOnCount || 0}
                  opacity={0.1}
                  label="등록 중 원료"
                  onClick={() => history.push(path.material.manage.ready)}
                />
                <CompanyStat
                  amount={materialFinCount || 0}
                  opacity={0.3}
                  label="등록 완료 원료"
                  onClick={() => history.push(path.material.manage.done)}
                />
                <CompanyStat
                  amount={icidCompleteCount || 0}
                  opacity={0.5}
                  label="ICID 등재 완료"
                  onClick={() => history.push(path.material.icid.registered)}
                />
                <CompanyStat
                  amount={nmpaCompleteCount || 0}
                  opacity={1}
                  label="신고번호 취득 완료"
                  onClick={() => history.push(path.material.nmpa.registered)}
                />
              </Flex>
            </Spin>
          </CompanyStatsBlock>
        </Col>
      </Row>
      <Flex gap={16} gutter={{ top: 32 }} align="end">
        <FavoriteMenu>
          <Typography.Text type="TITLE_1">자주 찾는 메뉴</Typography.Text>
          <Flex gap={16} wrap="true" gutter={{ top: 16 }}>
            <Shortcut
              icon={<Icon name="multipleAdd" color="PRIMARY50" />}
              label="원료 등록"
              description="지금 바로 원료를 등록해 보세요."
              onClick={() => history.push(`${path.material.rawMaterial.basic}`)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="flask" color="PRIMARY50" />}
              label="등록 원료 관리"
              description="등록된 원료 정보들을 확인하고 관리해보세요."
              onClick={() => history.push(path.material.manage.done)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="book" color="PRIMARY50" />}
              label="ICID 등재 의뢰"
              description={
                <>
                  새로운 성분을 국제 화장품 성분
                  <br />
                  사전에 등록해 보세요.
                </>
              }
              onClick={() => history.push(path.material.icid.request)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="code" color="PRIMARY50" />}
              label="신고번호 취득 의뢰"
              description="중국 NMPA 원료 안전성 자료
              신고번호를 취득해 보세요."
              onClick={() => history.push(path.material.nmpa.estimate.request)}
              flex={'1 1 calc(50% - 8px)'}
            />
          </Flex>
        </FavoriteMenu>
        {/* Artwork Screening */}
        <ArtworkScreeningContainer
          justify="center"
          align="center"
          onClick={() => history.push(path.artworkScreening)}
        >
          <Flex dir="column" justify="center" align="center" gap={24}>
            <Flex dir="column" gap={4} align="center">
              <Typography.Text
                type="TITLE_2"
                color="PRIMARY50"
                style={{ fontWeight: 600 }}
              >
                아트웍 스크리닝
              </Typography.Text>
              <Typography.Text type="BODY_2" color="GRAY90">
                AI 기술을 활용한 Artwork의 전성분 라벨링 확인
              </Typography.Text>
            </Flex>
            <ArtworkScreeningImg style={{ height: 190 }} />
          </Flex>
        </ArtworkScreeningContainer>
      </Flex>
    </MainPageBlock>
  );
};

const MainPageBlock = styled.div`
  padding-top: 40px;
  max-width: 1024px !important;
`;

const FavoriteMenu = styled.div``;

const ArtworkScreeningContainer = styled(Flex)`
  border-radius: 12px;
  background-color: ${palette.PRIMARY10};
  border: 1px solid transparent;
  height: 100%;
  height: 312px;
  padding: 24px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${palette.PRIMARY50};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
  }
`;

export default MainPage;
