import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import styled from 'styled-components';

import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { deviceSize } from 'lib/styles';
import { DepositStatus } from 'types/estimate';

const EstimateListBlock = styled.div`
  padding: 0 16px;

  @media ${deviceSize.sm} {
    padding: 0;
  }
`;

const getDepositStatusColor = (depositStatus: DepositStatus) => {
  if (depositStatus === DepositStatus.READY) return 'lightGray';
  else if (depositStatus === DepositStatus.PARTIAL) return 'red';
  return 'blue';
};

const getDepositStatusText = (depositStatus: DepositStatus) => {
  if (depositStatus === DepositStatus.READY) return '미납';
  else if (depositStatus === DepositStatus.PARTIAL) return '분납';
  return '완납';
};

const EstimateList = ({
  estimates,
  page,
  getLoading,
  onPreview,
  onChangePage,
  onShowEstimateHistories,
  onShowEstimateAdditionals,
}: any) => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      { title: '견적서 번호', dataIndex: 'estimateCode', align: 'center' },
      {
        title: '견적 발행일',
        align: 'center',
        render: (_, { registerDt }) => registerDt.slice(0, 10),
      },
      {
        title: '입금 처리 / 견적 금액',
        align: 'center',
        render: (_, { paidAmount, estimatePrice }) =>
          `${paidAmount?.toLocaleString()}원 / ${Math.floor(
            estimatePrice * 1.1,
          )?.toLocaleString()}원`,
      },
      {
        title: '입금 상태',
        align: 'center',
        render: (_, { depositStatus }) => (
          <Tags.Mark color={getDepositStatusColor(depositStatus)}>
            {getDepositStatusText(depositStatus)}
          </Tags.Mark>
        ),
      },
      {
        title: '계약서 수령',
        align: 'center',
        render: (_, { isReceiveContractFinish }) => (
          <Tags.Mark color={isReceiveContractFinish ? 'blue' : 'lightGray'}>
            {isReceiveContractFinish ? '완료' : '대기중'}
          </Tags.Mark>
        ),
      },
      {
        title: '견적서',
        align: 'center',
        render: (_, { fileUrl }) => (
          <Icon
            name="page"
            style={{ margin: '0 auto' }}
            size={22}
            onClick={() => onPreview(fileUrl)}
          />
        ),
      },
      {
        title: '견적서 수정 이력',
        align: 'center',
        render: (_, { estimateId }) => (
          <Tags.Mark
            type="arrow"
            color="blue"
            onClick={() => onShowEstimateHistories(estimateId)}
          >
            확인하기
          </Tags.Mark>
        ),
      },
      {
        title: '추가 견적',
        align: 'center',
        render: (_, { estimateAdditionals }) =>
          estimateAdditionals && (
            <Tags.Mark
              type="arrow"
              color="blue"
              onClick={() => onShowEstimateAdditionals(estimateAdditionals)}
            >
              확인하기
            </Tags.Mark>
          ),
      },
    ],
    [onShowEstimateHistories],
  );

  return (
    <EstimateListBlock>
      <Table
        loading={getLoading}
        columns={columns}
        dataSource={estimates.list}
        rowKey={({ estimateId }) => estimateId}
        pagination={{
          pageSize: estimates.size,
          current: page,
          total: estimates.total,
          onChange: onChangePage,
        }}
      />
    </EstimateListBlock>
  );
};

export default EstimateList;
