import { useQuery } from 'react-query';

import ingredientClient from 'lib/api/ingredientClient';
import * as ingredientApi from 'lib/api/material/ingredient';
import { apiUrls, inciApiUrls, useBasicQuery } from 'service/core';
import { APIPageableResponse } from 'types/common';
import {
  CompareIngredientItem,
  IngredientDetail,
  IngredientListItem,
  IngredientSearchType,
} from 'types/material/ingredient';

export const useIngredients = ({
  page,
  firstSearchType,
  secondSearchType,
  firstKeyword,
  secondKeyword,
}: {
  page: number;
  firstSearchType: IngredientSearchType;
  secondSearchType: IngredientSearchType;
  firstKeyword: string;
  secondKeyword: string;
}) => {
  const { data, isLoading: getIngredientListItemsLoading } = useQuery(
    [
      inciApiUrls.ingredientInfos,
      page,
      firstSearchType,
      secondSearchType,
      firstKeyword,
      secondKeyword,
    ],
    () =>
      ingredientClient.post<APIPageableResponse<IngredientListItem[]>>(
        `${inciApiUrls.ingredientInfos}?page=${page}&size=10`,
        [
          {
            searchType: firstSearchType,
            keyword: firstKeyword,
          },
          ...(secondKeyword
            ? [
                {
                  searchType: secondSearchType,
                  keyword: secondKeyword,
                },
              ]
            : []),
        ],
      ),
    {
      select: (res) => res.data.result,
      enabled: !!firstKeyword,
    },
  );

  return {
    ingredientListItems: data?.content || [],
    total: data?.totalElements || 0,
    getIngredientListItemsLoading,
  };
};

export const useIngredientDetail = (ingredientId: number) => {
  const {
    data: ingredientDetail,
    isFetching: getIngredientDetailLoading,
  } = useBasicQuery<IngredientDetail>({
    apiUrl: inciApiUrls.ingredientDetail,
    isInciApi: true,
    urlParams: { ingredientId },
  });

  const {
    data: ingredientMaterialCount = 0,
    isFetching: getIngredientMaterialCountLoading,
  } = useBasicQuery<number>({
    apiUrl: apiUrls.ingredientMaterialCount,
    qs: {
      ingredientNameEn: ingredientDetail?.inciName?.[0]?.name,
    },
    option: {
      enabled: !!ingredientDetail?.inciName?.[0]?.name,
    },
  });

  return {
    ingredientDetail,
    getIngredientDetailLoading,
    ingredientMaterialCount,
    getIngredientMaterialCountLoading,
  };
};

export const useCompareIngredients = (ingredientIds: number[]) => {
  const {
    data: compareIngredientItems = [],
    isLoading: getCompareIngredientItemsLoading,
  } = useBasicQuery<CompareIngredientItem[]>({
    apiUrl: inciApiUrls.compareIngredients,
    isInciApi: true,
    qs: {
      ingredientIds,
    },
    option: {
      enabled: ingredientIds && ingredientIds.length > 0,
    },
  });

  return {
    compareIngredientItems,
    getCompareIngredientItemsLoading,
  };
};

export const useCountryProhibitsUpdatedDate = () => {
  const {
    data: countryProhibitUpdatedDate,
  } = useQuery(
    'material/ingredient/getCountryProhibitsUpdatedDate',
    ingredientApi.getCountryProhibitsUpdatedDate,
    { select: (res) => res.data.result },
  );

  return countryProhibitUpdatedDate;
};

export const useEwgUpdatedDate = () => {
  const {
    data: countryProhibitUpdatedDate,
  } = useQuery(
    'material/ingredient/getEwgUpdatedDate',
    ingredientApi.getEwgUpdatedDate,
    { select: (res) => res.data.result },
  );

  return countryProhibitUpdatedDate;
};
