import { BrandCompany } from 'types/brand/company';
import { CountryProhibitInfo } from 'types/formula';

export enum ProductSampleSearchType {
  ALL = 'ALL',
  PRODUCT_SAMPLE_NAME = 'PRODUCT_SAMPLE_NAME',
  LAB_NO = 'LAB_NO',
  REGISTER_USER_NAME = 'REGISTER_USER_NAME',
  IS_SELF_DEVELOP = 'IS_SELF_DEVELOP',
  VENDOR_NAME = 'VENDOR_NAME',
  DEPT_NAME = 'DEPT_NAME',
}

export enum ProductSampleSearchStatus {
  REGISTERING = 'REGISTERING',
  CONFIRMED = 'CONFIRMED',
  BE_USED = 'BE_USED',
}

export interface ProductSampleSearchParams {
  status: ProductSampleSearchStatus;
  isMyProductSample: boolean;
  page: number;
  size: 10 | 30 | 50;
  searchType: ProductSampleSearchType;
  searchKeyword: string | null;
}
export interface ProductSample {
  productSampleId: number;
  productSampleName: string;
  labNo: string;
  registerUserId: number;
  registerUserName: string;
  registerDeptName: string;
  isSelfDevelop: boolean;
  vendorName: string;
  registerDt: string;
  isInternalDisclosure: boolean;
  isConfirmable: boolean;
  formulaExcelUrl: string | null;
  formulaBreakdownExcelUrl: string | null;
  formulaInciNameList: string | null;
  recipeDetailItems: ProductSampleRecipeDetailItem[] | null;
  recipeDetailUploadFileUrl: string | null;
  isAccessibleToRecipeDetail: boolean;
}
export interface UsableProductSample
  extends Pick<
    ProductSample,
    'productSampleId' | 'productSampleName' | 'labNo'
  > {}

export enum ProductSampleDocumentCode {
  BASIC = 'BASIC01',
  RECIPE = 'RECIPE01',
  RDI = 'RDI01',
  PC = 'PC01',
}

export enum ProductSampleDocumentStatus {
  INP = 'INP',
  FIN = 'FIN',
  OPT = 'OPT',
}
export interface ProductSampleDocument {
  productSampleDocId: number;
  documentSeq: {
    documentName: string;
    documentCode: ProductSampleDocumentCode;
  };
  isOptional: boolean;
  status: ProductSampleDocumentStatus;
  registerDt: string;
}

export enum ProductSampleProductType {
  LEAVE_ON = 'LEAVE_ON',
  RINSE_OFF = 'RINSE_OFF',
}

export enum CountryTarget {
  EUROPE = 'EUROPE',
  CHINA = 'CHINA',
  USA = 'USA',
}
export interface ProductSampleBasicForm {
  productSampleName: string;
  labNo: string;
  vendorId: number;
  isInternalDisclosure: boolean;
  productType: ProductSampleProductType;
  countryTargets: CountryTarget[];
}
export interface ProductSampleBasicAdd
  extends Omit<ProductSampleBasicForm, 'vendorId'> {
  isSelfDevelop: boolean;
  vendorId: number | null;
}
export enum ProductSampleStatus {
  RDY = 'RDY',
  REG = 'REG',
  CFM = 'CFM',
  DEL = 'DEL',
}
export interface ProductSampleBasicGet extends ProductSampleBasicAdd {
  productSampleId: number;
  brandCompany: BrandCompany;
  status: ProductSampleStatus;
}
export interface ProductSampleBasicUpdate
  extends Partial<ProductSampleBasicAdd> {
  productSampleId: number;
}
export enum ProductSampleRecipeErrorCode {
  PHASE = 'PHASE',
  MATERIAL_NAME = 'MATERIAL_NAME',
  MATERIAL_COMPANY_NAME = 'MATERIAL_COMPANY_NAME',
  MATERIAL_PERCENT = 'MATERIAL_PERCENT',
  REMARK = 'REMARK',
}
interface ProductSampleRecipeItemBase {
  phase: string;
  materialName: string;
  materialCompanyName: string;
  remark: string;
}
export interface ProductSampleRecipeItem extends ProductSampleRecipeItemBase {
  materialPercent: number;
  errorCodes?: ProductSampleRecipeErrorCode[];
}

export interface ProductSampleRecipeAdd {
  filename: string;
  memo: string;
  recipeItems: ProductSampleRecipeItem[];
}
export interface ProductSampleRecipeParam
  extends Omit<ProductSampleRecipeAdd, 'productSampleId'> {
  productSampleRecipeId: number;
  registerDt: string;
}
export interface ProductSampleRecipeUpdate {
  productSampleRecipeId: number;
  memo: string;
}
export enum ProductSampleRecipeDraftInvalidCode {
  TOTAL_MATERIAL_PERCENT_INVALID = 'TOTAL_MATERIAL_PERCENT_INVALID',
  EXCEL_INVALID = 'EXCEL_INVALID',
}
export interface ProductSampleRecipeDraft
  extends Pick<ProductSampleRecipeParam, 'filename' | 'recipeItems'> {
  invalidCodes: {
    code: ProductSampleRecipeDraftInvalidCode;
    message: string;
  }[];
}
export enum ProductSampleRecipeDetailErrorCode {
  PHASE = 'PHASE',
  MATERIAL_NAME = 'MATERIAL_NAME',
  MATERIAL_COMPANY_NAME = 'MATERIAL_COMPANY_NAME',
  INCI_NAME = 'INCI_NAME',
  FLA_INGREDIENT_PERCENT = 'FLA_INGREDIENT_PERCENT',
  RM_INGREDIENT_PERCENT = 'RM_INGREDIENT_PERCENT',
  ACTUAL_WT = 'ACTUAL_WT',
  INGREDIENT_FUNCTION = 'INGREDIENT_FUNCTION',
  CAS_NO = 'CAS_NO',
  REMARK = 'REMARK',
  INCI_NAME_KO = 'INCI_NAME_KO',
}
export interface ProductSampleRecipeDetailItem {
  rowNo?: number;
  phase: string | null;
  phaseRowSpan: number;
  materialName: string | null;
  materialNameRowSpan: number;
  materialCompanyName: string | null;
  materialCompanyNameRowSpan: number;
  inciNameKo?: string;
  inciName: string;
  flaIngredientPercent: number | null;
  flaIngredientPercentRowSpan: number;
  rmIngredientPercent: number;
  actualWt: number;
  ingredientFunction: string;
  casNo: string;
  remark: string;
  errorCodes?: ProductSampleRecipeDetailErrorCode[];
  limitedFormulaCountryInfos: CountryProhibitInfo[];
}

export interface ProductSampleRecipeDetail {
  formulaExcelUrl: string;
  recipeDetailItems: ProductSampleRecipeDetailItem[];
}

export interface ProductSampleRecipeDetailAdd {
  productSampleId: number;
  file: File;
}

export enum ProductSampleRecipeDetailDraftInvalidCode {
  INVALID_TOTAL_ACTUAL_PERCENT = 'INVALID_TOTAL_ACTUAL_PERCENT',
  INVALID_TOTAL_RM_INGREDIENT_IN_FLA_PERCENT = 'INVALID_TOTAL_RM_INGREDIENT_IN_FLA_PERCENT',
  INVALID_CELL = 'INVALID_CELL',
  INVALID_EXCEL = 'INVALID_EXCEL',
}
export interface ProductSampleRecipeDetailDraft
  extends Pick<ProductSampleRecipeDetail, 'recipeDetailItems'> {
  errorFileUrl: string | null;
  formulaExcelUrl: string | null;
  invalidCodes: {
    code: ProductSampleRecipeDetailDraftInvalidCode;
    message: string;
  }[];
}

export enum ProductSampleProcessMapProcessType {
  DISSOLVING = 'DISSOLVING', // 별도 용해
  MIXING = 'MIXING', // 혼합 (단일)
  MULTIPLE_MIXING = 'MULTIPLE_MIXING', // 혼합 (다중)
  EMULSIONING = 'EMULSIONING', // 유화
  MULTIPLE_EMULSIONING = 'MULTIPLE_EMULSIONING', // 다중 유화
  SOLUBILIZING = 'SOLUBILIZING', // 가용화
  COOLING = 'COOLING', // 냉각
  NEUTRALIZING = 'NEUTRALIZING', // 중화
  ROLLING = 'ROLLING', // 롤링
  DEGASSING = 'DEGASSING', // 탈포
  FILTERING = 'FILTERING', // 필터링
  DISPERING = 'DISPERING', // 분산
  DIRECT_INPUT = 'DIRECT_INPUT', // 직접 입력
}
export interface ProductSampleProcessMapItemForm {
  phase: string;
  processType?: ProductSampleProcessMapProcessType;
  temperature?: string;
  isNotApplicableTemperature?: boolean;
  rpm?: string;
  isNotApplicableRpm?: boolean;
  processTime?: string;
  isUntilProcessCompletion?: boolean;
  directInputText?: string;
  mixedDatas?: (string | undefined)[];
}
export interface ProductSampleProcessMapItemGet
  extends ProductSampleProcessMapItemForm {
  productSampleProcessMapItemId: number;
}
export interface ProductSampleProcessMapItemUpdate {
  productSampleProcessMapItemId: number;
  processType?: ProductSampleProcessMapProcessType | null;
  temperature?: string | null;
  isNotApplicableTemperature?: boolean | null;
  rpm?: string | null;
  isNotApplicableRpm?: boolean | null;
  processTime?: string | null;
  isUntilProcessCompletion?: boolean | null;
  directInputText?: string | null;
  mixedDatas?: (string | undefined)[] | null;
}
