import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd';
import _ from 'lodash';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import FooterBox from 'components/FooterBox';
import { useBrandCompanies } from 'service/brand/company';
import { Tip, Typography } from 'components/system';
import {
  useCheckProductSampleIsDuplicated,
  useIsProductSampleConfirmed,
  useProductSample,
  useProductSampleId,
} from 'service/manufacturer/productSample';
import {
  CountryTarget,
  ProductSampleProductType,
  ProductSampleBasicForm,
} from 'types/manufacturer/productSample';
import { useLocation } from 'react-router-dom';
import useUpdateEffect from 'hook/useUpdateEffect';

const CountryTargets = ({
  countryTargets = [],
  onChange,
  readOnly,
}: {
  countryTargets?: CountryTarget[];
  onChange?: (e: { target: { countryTargets: CountryTarget[] } }) => void;
  readOnly: boolean;
}) => {
  const handleChangeCountryTarget = (
    countryTarget: CountryTarget,
    checked: boolean,
  ) => {
    onChange &&
      onChange({
        target: {
          countryTargets: checked
            ? countryTargets.concat(countryTarget)
            : countryTargets.filter((target) => target !== countryTarget),
        },
      });
  };
  return (
    <Row>
      <Col flex="auto">
        <Checkbox disabled checked>
          <Typography.Text type="BODY_2" inline color="GRAY90">
            글로벌 (유럽, 중국 외)
          </Typography.Text>
        </Checkbox>
      </Col>
      <Col>
        <Checkbox
          checked={countryTargets.includes(CountryTarget.EUROPE)}
          onChange={(e) =>
            handleChangeCountryTarget(CountryTarget.EUROPE, e.target.checked)
          }
          disabled={readOnly}
        >
          유럽
        </Checkbox>
        <Checkbox
          checked={countryTargets.includes(CountryTarget.CHINA)}
          onChange={(e) =>
            handleChangeCountryTarget(CountryTarget.CHINA, e.target.checked)
          }
          disabled={readOnly}
        >
          중국
        </Checkbox>
      </Col>
    </Row>
  );
};

const ProductSampleBasicBlock = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-control-input {
    min-height: auto;
  }
`;

const ProductSampleBasic = () => {
  const location = useLocation();
  const [form] = Form.useForm<ProductSampleBasicForm>();
  const { data: brands = [], isLoading } = useBrandCompanies({ enabled: true });
  const checkProductSampleIsDuplicated = useCheckProductSampleIsDuplicated();
  const productSampleId = useProductSampleId();
  const updateMode = typeof productSampleId !== 'undefined';
  const {
    productSample,
    addProductSample,
    addLoading,
    updateProductSample,
    updateLoading,
  } = useProductSample(productSampleId);
  const isProductSampleConfirmed = useIsProductSampleConfirmed(productSampleId);
  const brandOptions = useMemo(
    () => [
      { label: '자체 개발용', value: 0 },
      ...brands.map(({ brandCompanyId, companyNameKo }) => ({
        value: brandCompanyId,
        label: companyNameKo,
      })),
    ],
    [brands],
  );
  const debouncedCheckProductSampleIsDuplicated = _.debounce(
    checkProductSampleIsDuplicated,
    300,
  );
  const handleSubmit = (sampleBasicForm: ProductSampleBasicForm) => {
    if (!updateMode) {
      addProductSample({
        ...sampleBasicForm,
        vendorId: sampleBasicForm.vendorId || null,
        isSelfDevelop: sampleBasicForm.vendorId === 0,
      });
    } else {
      updateProductSample({
        ...sampleBasicForm,
        vendorId: sampleBasicForm.vendorId || null,
        isSelfDevelop: sampleBasicForm.vendorId === 0,
      });
    }
  };
  useEffect(() => {
    if (productSample) {
      const {
        productSampleName,
        labNo,
        vendorId,
        isInternalDisclosure,
        productType,
        countryTargets,
      } = productSample;
      form.setFieldsValue({
        productSampleName,
        labNo,
        vendorId: vendorId || 0,
        isInternalDisclosure,
        productType,
        countryTargets,
      });
    }
  }, [productSample]);
  useUpdateEffect(() => {
    if (!updateMode) {
      form.resetFields();
    }
  }, [location]);
  return (
    <ProductSampleBasicBlock>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <StyledFormItem
          label="대상 국가"
          required
          name="countryTargets"
          valuePropName="countryTargets"
        >
          <CountryTargets readOnly={isProductSampleConfirmed} />
        </StyledFormItem>
        <Form.Item
          label="샘플명 (가칭)"
          rules={[requireRule]}
          name="productSampleName"
        >
          <Input maxLength={100} disabled={isProductSampleConfirmed} />
        </Form.Item>
        <Row gutter={8}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="랩넘버"
              rules={[
                requireRule,
                exceptKoreanRule,
                {
                  validator: (_, value) => {
                    if (value && !updateMode) {
                      return new Promise((resolve, reject) => {
                        debouncedCheckProductSampleIsDuplicated(value, {
                          onSuccess: ({
                            data: {
                              result: { isDuplicated },
                            },
                          }) => {
                            if (isDuplicated) {
                              reject('중복된 랩넘버');
                            }
                          },
                          onSettled: () => {
                            resolve('');
                          },
                        });
                      });
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name="labNo"
            >
              <Input maxLength={30} autoComplete="off" disabled={updateMode} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="자체 / 책임판매사"
              rules={[requireRule, exceptKoreanRule]}
              name="vendorId"
            >
              <Select
                showSearch
                loading={isLoading}
                filterOption={(searchKeyword, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase()) ||
                  option?.value === 0
                }
                options={brandOptions}
                disabled={isProductSampleConfirmed}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Type of Product"
          rules={[requireRule]}
          name="productType"
        >
          <Radio.Group disabled={isProductSampleConfirmed}>
            <Radio value={ProductSampleProductType.LEAVE_ON}>Leave-On</Radio>
            <Radio value={ProductSampleProductType.RINSE_OFF}>Rinse-Off</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col>처방 상세 공개 여부</Col>
              <Col>
                <Tip trigger="click">
                  <Typography.Text type="SMALL">
                    처방 관련 정보(원료명, 원료사, INCI Name, Actual WT% 등)를
                    <br />
                    회사 내 유저에게 공개, 비공개 설정할 수 있습니다.
                  </Typography.Text>
                </Tip>
              </Col>
            </Row>
          }
          rules={[requireRule]}
          name="isInternalDisclosure"
          initialValue={false}
        >
          <Radio.Group>
            <Radio value={true}>내부 공개</Radio>
            <Radio value={false}>비공개</Radio>
          </Radio.Group>
        </Form.Item>
        <FooterBox>
          <Button
            type="primary"
            htmlType="submit"
            loading={addLoading || updateLoading}
          >
            {!updateMode ? '등록' : '수정'}
          </Button>
        </FooterBox>
      </Form>
    </ProductSampleBasicBlock>
  );
};

export default ProductSampleBasic;
