import { Button, Col, message, Row, Spin, Upload } from 'antd';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import FooterBox from 'components/FooterBox';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import JapanExportInformationModal from 'components/modal/formula/JapanExportInformationModal';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import Table from 'components/Table';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import Tags from 'components/ui/Tags';
import { useCertificateMode } from 'hook/certificate';
import useGA, { EGAActionType } from 'hook/useGA';
import { countryCodeMap } from 'lib/consts';
import { downloadFile } from 'lib/file';
import history from 'lib/history';
import palette from 'lib/styles/palette';
import { updateCurrentPathname } from 'modules/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';
import {
  useProductDocStatus,
  useProductFormulaBreakdown,
} from 'service/product';
import { CountryProhibitInfo } from 'types/formula';
import { DocumentCode, ProductFormulaBreakdownItem } from 'types/product';
import UpdateLog from './UpdateLog';

const Container = styled.div``;
const ProductFormulaBreakdownTableBlock = styled.div``;

const ProductFormulaBreakdownBlock = styled.div<{
  success?: boolean;
  isWtSumError: boolean;
  isSubstanceSumError: boolean;
}>`
  table th {
    text-align: center;
  }

  .ant-table-cell {
    font-size: 12px;

    &.bigo {
      display: none;
    }
  }
  .ant-table-wrapper {
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  ${({ success }) =>
    typeof success !== 'undefined' &&
    css`
      .ant-table-wrapper {
        border-color: ${success
          ? palette.MESSAGE_SUCCESS
          : palette.MESSAGE_ERROR};
      }

      ${success === false &&
      css`
        .ant-table-cell.bigo {
          display: table-cell;
        }
      `}

      td.ant-table-cell.bigo {
        color: ${palette.MESSAGE_ERROR};
      }
    `}

  ${({ isWtSumError }) =>
    isWtSumError &&
    css`
      td.ant-table-cell.wt {
        color: ${palette.MESSAGE_ERROR};
      }
    `}

  ${({ isSubstanceSumError }) =>
    isSubstanceSumError &&
    css`
      td.ant-table-cell.sub-percent {
        color: ${palette.MESSAGE_ERROR};
      }
    `}
`;

const AdditionalItemInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 176px;
  transform: translateY(-50%);
  padding: 8px 16px;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  background-color: #fff;
  width: max-content;
  text-align: left;
  color: ${palette.GRAY90};
  z-index: 1;
  white-space: pre-wrap;
  max-width: 304px;
`;

const StyledButton = styled(Button)<{ isDownload: boolean; disabled: boolean }>`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${({ disabled }) => !disabled && palette.PRIMARY50};
      transition: ${({ isDownload }) =>
        isDownload ? 'all 300ms ease' : 'all 150ms ease'};
    }
  }

  svg,
  path {
    transition: ${({ isDownload }) =>
      isDownload ? 'all 300ms ease' : 'all 150ms ease'};
  }
`;

const ProductFormulaBreakdown = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const documentCode = DocumentCode.FORMBR;
  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });
  const filename = productDocStatus?.filename || 'Formula Breakdown.xlsx';
  const { skipMode, readOnlyMode } = useSelector(
    ({ product, certificate }: any) => ({
      skipMode: product.skipMode,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const certificateMode = useCertificateMode();
  const {
    formulaBreakdownItems,
    getLoading,
    uploadProductFormulaBreakdown,
    uploadLoading,
    productFormulaBreakdownDraft,
  } = useProductFormulaBreakdown(productId, countryId);

  const success = productFormulaBreakdownDraft
    ? !productFormulaBreakdownDraft.isWtSumError &&
      !productFormulaBreakdownDraft.isSubstanceSumError &&
      productFormulaBreakdownDraft.uploadedExcelUrl === null
    : undefined;

  const handleUpload = (file: File) => {
    uploadProductFormulaBreakdown(file, {
      onSuccess: () => {
        sendEventToGA({
          documentName: 'Product Formula Breakdown',
          actionType: certificateMode
            ? EGAActionType.MODIFY
            : updateMode
            ? EGAActionType.UPDATE
            : EGAActionType.REGISTER,
        });
        if (certificateMode) {
          message.success('보완 완료되었습니다.');
        }
      },
    });
  };

  const handleMoveNext = () => {
    dispatch(updateCurrentPathname());
  };

  return (
    <Container>
      <LoadingModal
        visible={(updateMode && getLoading) || uploadLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 스크리닝 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <ProductFormulaBreakdownBlock
        success={success}
        isWtSumError={
          productFormulaBreakdownDraft
            ? productFormulaBreakdownDraft.isWtSumError
            : false
        }
        isSubstanceSumError={
          productFormulaBreakdownDraft
            ? productFormulaBreakdownDraft.isWtSumError
            : false
        }
      >
        <Flex justify="end" gap={8}>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={documentCode}
          />
          {updateMode && (
            <UpdateLog
              productId={productId!}
              countryId={countryId}
              documentCode={documentCode}
            />
          )}
        </Flex>
        <Flex
          gap={8}
          justify="center"
          align="center"
          style={{ marginBottom: 24 }}
        >
          <StyledButton
            type="dashed"
            isDownload={true}
            icon={<Icon name="download" size={14} style={{ marginRight: 4 }} />}
            href="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Product%2BFormula_Breakdown.xlsx"
            disabled={readOnlyMode || (success && certificateMode)}
            style={{
              cursor: readOnlyMode && 'not-allowed',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            엑셀 서식 다운로드
          </StyledButton>
          <Upload
            accept=".xlsx"
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            itemRender={() => null}
          >
            <StyledButton
              isDownload={false}
              loading={uploadLoading}
              icon={<Icon name="upload" size={14} style={{ marginRight: 4 }} />}
              disabled={readOnlyMode || (success && certificateMode)}
              style={{
                width: 122,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onDrop={(e) => e.stopPropagation()}
            >
              파일 업로드
            </StyledButton>
          </Upload>
          {productDocStatus && typeof success === 'undefined' && (
            <Typography.Text
              type="BODY_2"
              color="PRIMARY50"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                downloadFile(productDocStatus.uploadFileUrl, filename)
              }
            >
              {filename}
            </Typography.Text>
          )}
        </Flex>
        {(productFormulaBreakdownDraft || formulaBreakdownItems.length > 0) && (
          <>
            <Row style={{ marginTop: 16, marginBottom: 8 }} align="bottom">
              <Col flex="auto">
                {productFormulaBreakdownDraft && (
                  <>
                    {success && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="success" />
                        정상적으로 업로드 되었습니다.
                      </Typography.Text>
                    )}
                    {productFormulaBreakdownDraft.uploadedExcelUrl && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="error" />
                        업로드를 시도한 파일의 특정 셀에 오류가 있습니다.{' '}
                        <Typography.Text
                          type="BODY_2"
                          inline
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            downloadFile(
                              productFormulaBreakdownDraft.uploadedExcelUrl,
                            )
                          }
                        >
                          여기
                        </Typography.Text>
                        를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
                      </Typography.Text>
                    )}
                    {productFormulaBreakdownDraft.isWtSumError && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="error" />
                        모든 성분의 함량(WT %)을 더했을 때 100이 아닙니다.
                        수정하여 업로드해 주세요.
                      </Typography.Text>
                    )}
                    {productFormulaBreakdownDraft.isSubstanceSumError && (
                      <Typography.Text
                        type="BODY_2"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <StatusIcon status="error" />
                        모든 성분의 함량(% calculated for each substance)을
                        더했을 때 100이 아닙니다. 수정하여 업로드해 주세요.
                      </Typography.Text>
                    )}
                  </>
                )}
              </Col>
              <Col>
                <Tags.Mark
                  color="lightGray"
                  style={{ display: 'inline-block' }}
                >
                  DB 업데이트
                </Tags.Mark>
                <Typography.Text
                  type="SMALL"
                  medium
                  color="SLATE_GRAY70"
                  inline
                  gutter={{ left: 8 }}
                >
                  [국가별 성분 규제]
                </Typography.Text>
                <Typography.Text
                  type="SMALL"
                  medium
                  color="SLATE_GRAY70"
                  inline
                  gutter={{ left: 4 }}
                >
                  {prohibitDataUpdatedDate}
                </Typography.Text>
              </Col>
            </Row>
            <Spin spinning={getLoading}>
              <ProductFormulaBreakdownTable
                success={success}
                formulaBreakdownItems={
                  productFormulaBreakdownDraft
                    ? productFormulaBreakdownDraft.list
                    : formulaBreakdownItems
                }
              />
            </Spin>
          </>
        )}
        {success && (
          <FooterBox>
            {skipMode ? (
              <Button type="primary" onClick={() => handleMoveNext()}>
                다음 단계로 이동
              </Button>
            ) : (
              !certificateMode && (
                <Button type="primary" onClick={history.goBack}>
                  확인
                </Button>
              )
            )}
          </FooterBox>
        )}
      </ProductFormulaBreakdownBlock>
    </Container>
  );
};

export const ProductFormulaBreakdownTable = ({
  success,
  formulaBreakdownItems,
}: {
  success?: boolean;
  formulaBreakdownItems: ProductFormulaBreakdownItem[];
}) => {
  const fail = success === false;
  const [isCITES, setIsCITES] = useState<boolean | null>(null);
  const [selectedCountryName, setSelectedCountryName] = useState<string | null>(
    null,
  );
  const [currentRow, setCurrentRow] = useState<number | null>(null);

  const [
    selectedFormulaCountryInfos,
    setSelectedFormulaCountryInfos,
  ] = useState<CountryProhibitInfo[]>([]);
  const handleClickMore = (
    formulaCountryInfos: CountryProhibitInfo[],
    countryNameKo?: string,
  ) => {
    setSelectedFormulaCountryInfos(formulaCountryInfos);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  };
  const handleCloseCountryInfosModal = () => {
    setSelectedCountryName(null);
    setSelectedFormulaCountryInfos([]);
  };

  return (
    <ProductFormulaBreakdownTableBlock>
      <CountryProhibitInfosModal
        defaultCountryName={selectedCountryName || undefined}
        countryInfos={selectedFormulaCountryInfos || []}
        onClose={handleCloseCountryInfosModal}
      />
      <JapanExportInformationModal
        isCITES={isCITES}
        onClose={() => setIsCITES(null)}
      />
      <Table bordered>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>No.</Table.Th>
            <Table.Th>INCI Name</Table.Th>
            <Table.Th>WT %</Table.Th>
            <Table.Th>% in raw material</Table.Th>
            <Table.Th>% calculated for each substance</Table.Th>
            <Table.Th>Function</Table.Th>
            <Table.Th>CAS No.</Table.Th>
            <Table.Th className="bigo">실패 사유</Table.Th>
            <Table.Th colSpan={2}>국가별 성분 규제</Table.Th>
            <Table.Th colSpan={2}>수출 정보</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {formulaBreakdownItems.map(
            ({
              no,
              noSync,
              noRowSpan,
              inciName,
              formulaWt,
              formulaWtRowSpan,
              formulaRawMaterialPercent,
              formulaSubstancePercent,
              formulaFunction,
              formulaCasNo,
              bigo,
              limitedFormulaCountryInfos,
              isJapanCitesProhibited,
              isJapanProhibited,
              remark,
            }) => {
              const formulaTypes = Array.from(
                new Set(limitedFormulaCountryInfos.map(({ type }) => type)),
              ).sort();
              return (
                <Table.Tr
                  key={nanoid()}
                  style={
                    currentRow && (currentRow === no || currentRow === noSync)
                      ? { backgroundColor: '#fafafa' }
                      : {}
                  }
                  onMouseEnter={() => {
                    setCurrentRow(noSync || no);
                  }}
                  onMouseLeave={() => setCurrentRow(null)}
                >
                  {noRowSpan && (
                    <Table.Td
                      style={{
                        textAlign: 'center',
                        width: fail ? 27 : 36,
                        maxWidth: fail ? 27 : 36,
                        whiteSpace: 'pre-wrap',
                      }}
                      rowSpan={noRowSpan}
                    >
                      {no}
                      {currentRow === no && remark && (
                        <AdditionalItemInfo>
                          <Typography.Text type="SMALL">{`비고 : ${
                            remark || '-'
                          }`}</Typography.Text>
                        </AdditionalItemInfo>
                      )}
                    </Table.Td>
                  )}
                  <Table.Td
                    style={{
                      width: fail ? 177 : 190,
                      maxWidth: fail ? 177 : 190,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {inciName}
                  </Table.Td>
                  {formulaWtRowSpan && (
                    <Table.Td
                      className="wt"
                      rowSpan={formulaWtRowSpan}
                      style={{
                        textAlign: 'right',
                        width: fail ? 57 : 47,
                        maxWidth: fail ? 57 : 47,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {formulaWt}
                    </Table.Td>
                  )}
                  <Table.Td
                    style={{
                      textAlign: 'right',
                      width: fail ? 65 : 69,
                      maxWidth: fail ? 65 : 69,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {formulaRawMaterialPercent}
                  </Table.Td>
                  <Table.Td
                    className="sub-percent"
                    style={{
                      textAlign: 'right',
                      width: fail ? 81 : 111,
                      maxWidth: fail ? 81 : 111,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {formulaSubstancePercent}
                  </Table.Td>
                  <Table.Td
                    style={{
                      width: fail ? 105 : 175,
                      maxWidth: fail ? 105 : 175,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {formulaFunction}
                  </Table.Td>
                  <Table.Td
                    style={{
                      width: fail ? 65 : 61,
                      maxWidth: fail ? 65 : 61,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {formulaCasNo}
                  </Table.Td>
                  <Table.Td
                    className="bigo"
                    style={{
                      width: 113,
                      maxWidth: 113,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {bigo}
                  </Table.Td>
                  <Table.Td
                    style={{
                      textAlign: 'center',
                      width: fail ? 58 : 59,
                      maxWidth: fail ? 58 : 59,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {formulaTypes.map((type) => (
                      <Tags.Chip
                        key={type}
                        type="line"
                        color={
                          type === 'limit' ? 'MESSAGE_CHECK' : 'MESSAGE_ERROR'
                        }
                        style={{
                          display: 'inline-flex',
                        }}
                        onClick={() => {
                          handleClickMore(limitedFormulaCountryInfos);
                        }}
                      >
                        {type === 'limit' ? '제한' : '금지'}
                      </Tags.Chip>
                    ))}
                  </Table.Td>
                  <Table.Td
                    style={{
                      textAlign: 'center',
                      width: 58,
                      maxWidth: 58,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <Row gutter={[2, 2]} justify="center">
                      {limitedFormulaCountryInfos.map((item) => {
                        const { countryNameKo } = item;

                        return (
                          <Col key={countryNameKo}>
                            <img
                              src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                              alt=""
                              style={{
                                width: 20,
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleClickMore(
                                  limitedFormulaCountryInfos,
                                  countryNameKo,
                                )
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Table.Td>
                  {/* HINT : 수출 정보 */}
                  <Table.Td
                    style={{
                      textAlign: 'center',
                      width: 57,
                      maxWidth: 57,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {(isJapanCitesProhibited || isJapanProhibited) && (
                      <Tags.Chip
                        type="line"
                        color="MESSAGE_ERROR"
                        onClick={() => setIsCITES(isJapanCitesProhibited)}
                      >
                        불가
                      </Tags.Chip>
                    )}
                  </Table.Td>
                  <Table.Td
                    style={{
                      textAlign: 'center',
                      width: 57,
                      maxWidth: 57,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {(isJapanCitesProhibited || isJapanProhibited) && (
                      <img
                        src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap['일본']}.png`}
                        alt=""
                        style={{
                          width: 20,
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsCITES(isJapanCitesProhibited)}
                      />
                    )}
                  </Table.Td>
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
    </ProductFormulaBreakdownTableBlock>
  );
};

export default ProductFormulaBreakdown;
