import { useCallback, useMemo, useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';

import TabButton from 'components/TabButton';
import { Typography } from 'components/system';
import { downloadFile, downloadFiles, getFilenameFromUrl } from 'lib/file';
import usePreviewModal from 'hook/usePreviewModal';
import { useUpdateLogs } from 'service/material/updateLogs';
import { DocumentCode, UpdateLogListItem } from 'types/material/common';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const Container = styled(Flex)`
  margin-bottom: 8px;

  .ant-btn-gray {
    height: 32px;
  }
`;

const UpdateLog = ({
  id,
  documentCode,
}: {
  id: number;
  documentCode: DocumentCode;
}) => {
  const [isVisibleLogs, setIsVisibleLogs] = useState(false);
  const {
    allUpdateLogs,
    fields,
    logLoading,
    selectedField,
    changeSelectedField,
  } = useUpdateLogs({
    id,
    documentCode,
    isVisibleLogs,
  });

  const { PreviewModal, setUrl } = usePreviewModal();

  const updateLogs =
    allUpdateLogs?.logs.find(
      (item) => item.documentFieldId === selectedField?.documentFieldId,
    )?.list || [];

  const fileMode = useMemo(
    () => updateLogs.length > 0 && updateLogs[0]?.uploadFileUrl !== null,
    [updateLogs],
  );
  const checkFileExtension = useCallback(
    (url: string) =>
      url.endsWith('.xlsx') || url.endsWith('.ai') || url.endsWith('.zip'),
    [],
  );

  const columns: ColumnsType<UpdateLogListItem> = useMemo(
    () => [
      {
        title: !fileMode ? '내용' : '파일명',
        dataIndex: !fileMode ? 'content' : 'filename',
        align: 'center',
        width: !fileMode ? '60%' : '40%',
        ...(!fileMode && {
          render: (content) => (
            <pre style={{ whiteSpace: 'pre-wrap' }}>{content}</pre>
          ),
        }),
      },
      ...(fileMode
        ? [
            {
              title: '업로드 파일',
              align: 'center' as const,
              dataIndex: 'uploadFileUrl',
              render: (uploadFileUrl: string) => (
                <Icon
                  name="page"
                  size={22}
                  style={{ margin: '0 auto' }}
                  onClick={() => {
                    if (uploadFileUrl.includes(',https://')) {
                      const uploadFileUrls = uploadFileUrl
                        .split(',https://')
                        .map((url, index) =>
                          index === 0 ? url : `https://${url}`,
                        );
                      if (uploadFileUrls.some(checkFileExtension)) {
                        downloadFiles(
                          uploadFileUrls.map((url) => ({
                            name: getFilenameFromUrl(url),
                            url,
                          })),
                        );
                      } else {
                        setUrl(uploadFileUrls);
                      }
                    } else {
                      if (checkFileExtension(uploadFileUrl)) {
                        downloadFile(uploadFileUrl);
                      } else {
                        setUrl(uploadFileUrl);
                      }
                    }
                  }}
                />
              ),
            },
          ]
        : []),
      {
        title: '입력자 명',
        align: 'center',
        render: (_, { name, companyName }) => `${name} (${companyName})`,
      },
      {
        title: '입력 일시',
        dataIndex: 'registerDt',
        align: 'center',
        render: (_, { registerDt }) => registerDt.replace('T', ' '),
      },
    ],
    [fileMode, updateLogs],
  );
  const showLog = useCallback(() => {
    changeSelectedField(fields[0]);
    setIsVisibleLogs(true);
  }, [fields]);
  const closeModal = useCallback(() => {
    changeSelectedField(null);
    setIsVisibleLogs(false);
  }, []);

  return (
    <Container justify="flex-end">
      <PreviewModal />
      <Modal
        visible={selectedField !== null}
        centered
        width={800}
        footer={null}
        onCancel={closeModal}
      >
        <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
          수정 이력 보기
        </Typography.Text>
        {fields.length > 1 && (
          <Row gutter={[4, 4]}>
            {fields.map((field) => (
              <Col key={field.code}>
                <TabButton
                  selected={field.code === selectedField?.code}
                  onClick={() => changeSelectedField(field)}
                >
                  {field.name}
                </TabButton>
              </Col>
            ))}
          </Row>
        )}
        <Table
          columns={columns}
          loading={logLoading}
          dataSource={updateLogs}
          rowKey="documentChangeLogId"
          pagination={{ pageSize: 5 }}
          style={{ marginTop: 24, height: 320, overflowY: 'scroll' }}
        />
      </Modal>
      {fields.length > 0 && (
        <Button
          className="ant-btn-gray"
          icon={
            <Icon
              name="history"
              size={16}
              color="GRAY70"
              style={{ marginRight: 4 }}
            />
          }
          onClick={showLog}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          수정 이력
        </Button>
      )}
    </Container>
  );
};

export default UpdateLog;
