import { useMutation, useQuery } from 'react-query';

import client from 'lib/api/client';
import { AdProductCode, AdBanner } from 'types/ad';
import { APIResponse } from 'types/common';

export const useAdBanner = ({
  productCode,
}: {
  productCode: AdProductCode;
}) => {
  const { data: adBanners = [], isLoading } = useQuery(
    ['ad/adBanner/login', productCode],
    () =>
      client.get<APIResponse<AdBanner[]>>(`/pub/banner-advertisings`, {
        params: { productCode },
      }),
    { select: (res) => res.data.result, cacheTime: 0 },
  );

  return {
    adBanners,
    isLoading,
  };
};

export const useAdStatisticCount = () => {
  const { mutate: addAdExposureCount } = useMutation(
    (companyPurchaseProductIds: number[]) =>
      client.post<APIResponse<null>>(`/pub/advertising/statistic-exposures`, {
        companyPurchaseProductIds,
      }),
  );

  const {
    mutate: addAdClickCount,
  } = useMutation((companyPurchaseProductId: number) =>
    client.post<APIResponse<null>>(
      `/pub/advertisings/${companyPurchaseProductId}/statistic-click`,
    ),
  );

  return { addAdExposureCount, addAdClickCount };
};
