import qs from 'qs';

import client from 'lib/api/client';
import { APIPageableResponse, APIResponse } from 'types/common';
import {
  ProductSampleBasicAdd,
  ProductSampleBasicGet,
  ProductSampleBasicUpdate,
  ProductSampleRecipeParam,
  ProductSampleRecipeAdd,
  ProductSampleRecipeDetail,
  ProductSampleRecipeDetailAdd,
  ProductSampleRecipeDetailDraft,
  ProductSampleRecipeDraft,
  ProductSampleRecipeUpdate,
  ProductSampleProcessMapItemForm,
  ProductSampleProcessMapItemGet,
  ProductSampleProcessMapItemUpdate,
  ProductSample,
  ProductSampleSearchParams,
  ProductSampleDocument,
  UsableProductSample,
} from 'types/manufacturer/productSample';

export const checkProductSampleIsDuplicated = (labNo: string) =>
  client.get<APIResponse<{ isDuplicated: boolean }>>(
    `/manufacturer/product-sample/duplication?labNo=${labNo}`,
  );

export const getProductSamples = ({
  page,
  searchType,
  ...restParams
}: ProductSampleSearchParams) =>
  client.get<APIPageableResponse<ProductSample[]>>(
    `/manufacturer/product-samples?${qs.stringify({
      pageNo: page,
      searchKey: searchType,
      ...restParams,
    })}`,
  );

export const confirmProductSamples = (productSampleIds: number[]) =>
  client.patch('/manufacturer/product-samples/confirmation', {
    productSampleIds,
  });

export const getProductSampleDocuments = (productSampleId: number) =>
  client.get<APIResponse<ProductSampleDocument[]>>(
    `/manufacturer/product-samples/${productSampleId}/pre-list`,
  );

export const addProductSample = (productSample: ProductSampleBasicAdd) =>
  client.post<APIResponse<ProductSampleBasicGet>>(
    '/manufacturer/product-samples',
    productSample,
  );

export const getProductSample = (productSampleId: number) =>
  client.get<APIResponse<ProductSampleBasicGet>>(
    `/manufacturer/product-samples/${productSampleId}`,
  );

export const updateProductSample = ({
  productSampleId,
  ...rest
}: ProductSampleBasicUpdate) =>
  client.patch(`/manufacturer/product-samples/${productSampleId}`, rest);

export const validateProductSampleRecipe = (file: File) =>
  client.post<APIResponse<ProductSampleRecipeDraft>>(
    '/manufacturer/product-sample/recipe/excel-validation',
    { file },
  );

export const addProductSampleRecipe = (
  productSampleId: number,
  productSampleRecipe: ProductSampleRecipeAdd,
) =>
  client.post(
    `/manufacturer/product-samples/${productSampleId}/recipe`,
    productSampleRecipe,
  );

export const getProductSampleRecipes = (productSampleId: number) =>
  client.get<APIResponse<ProductSampleRecipeParam[]>>(
    `/manufacturer/product-samples/${productSampleId}/recipes`,
  );

export const updateProductSampleRecipe = (
  productSampleId: number,
  { productSampleRecipeId, memo }: ProductSampleRecipeUpdate,
) =>
  client.patch(
    `/manufacturer/product-samples/${productSampleId}/recipes/${productSampleRecipeId}`,
    { memo },
  );

export const validateProductSampleRecipeDetail = (file: File) =>
  client.post<APIResponse<ProductSampleRecipeDetailDraft>>(
    '/manufacturer/product-sample/recipe-detail/excel-validation',
    { file },
  );

export const addProductSampleRecipeDetail = ({
  productSampleId,
  file,
}: ProductSampleRecipeDetailAdd) =>
  client.post<APIResponse<ProductSampleRecipeDetailDraft>>(
    `/manufacturer/product-samples/${productSampleId}/recipe-detail`,
    { file },
  );

export const getProductSampleRecipeDetail = (productSampleId: number) =>
  client.get<APIResponse<ProductSampleRecipeDetail>>(
    `/manufacturer/product-samples/${productSampleId}/recipe-detail`,
  );
export const getProductSampleProcessMapPhases = (productSampleId: number) =>
  client.get<APIResponse<string[]>>(
    `/manufacturer/product-samples/${productSampleId}/process-map/phases`,
  );

export const getProductSampleProcessMap = (productSampleId: number) =>
  client.get<
    APIResponse<{ processMapItems: ProductSampleProcessMapItemGet[] }>
  >(`/manufacturer/product-samples/${productSampleId}/process-map`);

export const addProductSampleProcessMap = ({
  productSampleId,
  processMapItems,
}: {
  productSampleId: number;
  processMapItems: ProductSampleProcessMapItemForm[];
}) =>
  client.post(`/manufacturer/product-samples/${productSampleId}/process-map`, {
    processMapItems,
  });

export const updateProductSampleProcessMap = ({
  productSampleId,
  processMapItems,
}: {
  productSampleId: number;
  processMapItems: ProductSampleProcessMapItemUpdate[];
}) =>
  client.patch(`/manufacturer/product-samples/${productSampleId}/process-map`, {
    processMapItems,
  });

export const publishProcessMapPreview = ({
  productSampleId,
  processMapItems,
}: {
  productSampleId: number;
  processMapItems: ProductSampleProcessMapItemForm[];
}) =>
  client.post(
    `/manufacturer/product-samples/${productSampleId}/process-map/preview`,
    {
      processMapItems,
    },
  );

export const getUsableProductSamples = () =>
  client.get<APIResponse<UsableProductSample[]>>(
    '/manufacturer/product-samples/usable-targets',
  );
