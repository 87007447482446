import { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message, Modal } from 'antd';
import { useMutation, useQuery } from 'react-query';

import { updateCurrentPathname } from 'modules/product';
import { messages } from 'lib/consts';
import { getUndefinedFilteredObject, getUpdatingObject } from 'lib/form';
import {
  DocumentCode,
  ProductChallengeTest,
  ProductChallengeTestAddParam,
  ProductChallengeTestForm,
  ProductChallengeTestUpdateParam,
} from 'types/product';
import useGA, { EGAActionType } from 'hook/useGA';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { useCertificateMode } from 'hook/certificate';
import { fixDocument } from 'modules/certificate';
import history from 'lib/history';
import { useProductDocStatus } from 'service/product';

const queryKey = 'product-challenge-test';

export const useChallengeTest = (productId: number, countryId?: number) => {
  const dispatch = useDispatch();
  const { sendEventToGA } = useGA();
  const certificateMode = useCertificateMode();
  const [form] = Form.useForm<ProductChallengeTestForm>();
  const [isMergeBacteria, setIsMergeBacteria] = useState(false);
  const [isMergeYeast, setIsMergeYeast] = useState(false);
  const [isMergeMold, setIsMergeMold] = useState(false);

  const { currentProduct } = useSelector(({ product }: any) => ({
    currentProduct: product.currentProduct,
  }));

  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.CHAL,
  });

  const { data: challengeTest, isLoading: getLoading } = useQuery(
    [queryKey, productId, countryId],
    () =>
      client.get<APIResponse<ProductChallengeTest>>(
        `/product-challenge-test/${productId}${
          countryId ? `/${countryId}` : ''
        }`,
      ),
    {
      select: (res) => {
        const result = res.data.result;
        if (result) {
          return Object.fromEntries(
            Object.entries(result).filter((entry) => entry[1] !== null),
          ) as ProductChallengeTest;
        }
      },
    },
  );

  const {
    mutate: addProductChallengeTest,
    isLoading: addLoading,
  } = useMutation((params: ProductChallengeTestAddParam) =>
    client.post('/product-challenge-test', params),
  );

  const {
    mutate: updateProductChallengeTest,
    isLoading: updateLoading,
  } = useMutation((params: ProductChallengeTestUpdateParam) =>
    client.patch('/product-challenge-test', params),
  );

  const [checkMap, setCheckMap] = useState({
    isSaureus: true,
    isEcoli: true,
    isPaeruginosa: true,
    isBacillus: false,
    isEnterobacter: false,
  });
  const updateMode = challengeTest && challengeTest !== null;
  const selectedBacteriaCount = useMemo(
    () => Object.values(checkMap).filter((checked) => checked).length,
    [checkMap],
  );
  const toggleIsMergeBacteria = () => setIsMergeBacteria(!isMergeBacteria);
  const toggleIsMergeYeast = () => {
    setIsMergeYeast(!isMergeYeast);
  };
  const toggleIsMergeMold = () => setIsMergeMold(!isMergeMold);
  const toggleCheck = (key: keyof typeof checkMap) => {
    setCheckMap({ ...checkMap, [key]: !checkMap[key] });
  };

  const fetchProductChallengeTest = useCallback(
    (formValues: ProductChallengeTestForm) => {
      if (!updateMode) {
        if (!formValues.isReportExist) {
          addProductChallengeTest(
            {
              productId: currentProduct.productId,
              isReportExist: false,
            },
            {
              onSuccess: () => {
                sendEventToGA({
                  documentName: 'Challenge Test',
                  actionType: EGAActionType.REGISTER,
                });
                dispatch(updateCurrentPathname());
              },
            },
          );
        } else {
          addProductChallengeTest(
            {
              productId: currentProduct.productId,
              ...formValues,
              isMergeBacteria,
              isMergeYeast,
              isMergeMold,
              isSaureus: checkMap.isSaureus,
              isEcoli: checkMap.isEcoli,
              isPaeruginosa: checkMap.isPaeruginosa,
              isBacillus: checkMap.isBacillus,
              isEnterobacter: checkMap.isEnterobacter,
            },
            {
              onSuccess: () => {
                sendEventToGA({
                  documentName: 'Challenge Test',
                  actionType: EGAActionType.REGISTER,
                });
                dispatch(updateCurrentPathname());
              },
            },
          );
        }
      } else {
        const { isReportExist } = formValues;
        if (!isReportExist) {
          if (
            isReportExist !== challengeTest?.isReportExist &&
            challengeTest?.productChallengeTestId
          ) {
            updateProductChallengeTest(
              {
                productChallengeTestId: challengeTest?.productChallengeTestId,
                isReportExist,
              },
              {
                onSuccess: () => {
                  sendEventToGA({
                    documentName: 'Challenge Test',
                    actionType: certificateMode
                      ? EGAActionType.MODIFY
                      : EGAActionType.UPDATE,
                  });
                  if (certificateMode) {
                    dispatch(fixDocument(productDocStatus?.productDocStatusId));
                    message.success('보완 완료되었습니다.');
                  } else {
                    message.success('수정되었습니다.');
                  }
                  history.goBack();
                },
              },
            );
            return;
          }
        } else {
          const updatingValues = {
            ...getUpdatingObject(
              {
                ...formValues,
                isMergeBacteria,
                isMergeYeast,
                isMergeMold,
                ...checkMap,
              },
              challengeTest,
            ),
          };
          const filteredValues = getUndefinedFilteredObject(updatingValues);
          if (
            Object.keys(filteredValues).length !== 0 &&
            challengeTest?.productChallengeTestId
          ) {
            updateProductChallengeTest(
              {
                productChallengeTestId: challengeTest?.productChallengeTestId,
                ...filteredValues,
              },
              {
                onSuccess: () => {
                  sendEventToGA({
                    documentName: 'Challenge Test',
                    actionType: certificateMode
                      ? EGAActionType.MODIFY
                      : EGAActionType.UPDATE,
                  });
                  if (certificateMode) {
                    dispatch(fixDocument(productDocStatus?.productDocStatusId));
                    message.success('보완 완료되었습니다.');
                  } else {
                    message.success('수정되었습니다.');
                  }
                  history.goBack();
                },
              },
            );
            return;
          }
        }
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }
    },
    [
      currentProduct,
      checkMap,
      isMergeBacteria,
      isMergeYeast,
      isMergeMold,
      challengeTest,
    ],
  );

  const showConfirmModal = useCallback(
    (formValues: ProductChallengeTestForm) => {
      Modal.confirm({
        width: 420,
        style: { textAlign: 'center' },
        content: (
          <>
            추후 인증 진행시 위의 기준에 적합한지
            <br />
            판단하기 위한 근거자료를 요청할 수 있습니다.
            <br />
            {!updateMode ? '등록' : '수정'}하시겠습니까?
          </>
        ),
        okText: '등록',
        cancelText: '취소',
        icon: null,
        onOk: () => {
          fetchProductChallengeTest(formValues);
        },
      });
    },
    [updateMode, fetchProductChallengeTest],
  );

  const onSubmit = useCallback(
    (formValues: ProductChallengeTestForm) => {
      if (
        !formValues.isReportExist &&
        formValues.isReportExist !== challengeTest?.isReportExist
      ) {
        showConfirmModal(formValues);
      } else {
        fetchProductChallengeTest(formValues);
      }
    },
    [challengeTest, showConfirmModal, fetchProductChallengeTest],
  );

  useEffect(() => {
    if (updateMode && challengeTest) {
      form.setFieldsValue(challengeTest);
      if (challengeTest.isReportExist) {
        setIsMergeBacteria(challengeTest.isMergeBacteria as boolean);
        setIsMergeYeast(challengeTest.isMergeYeast as boolean);
        setIsMergeMold(challengeTest.isMergeMold as boolean);
        setCheckMap({
          isSaureus: challengeTest.isSaureus as boolean,
          isEcoli: challengeTest.isEcoli as boolean,
          isPaeruginosa: challengeTest.isPaeruginosa as boolean,
          isBacillus: challengeTest.isBacillus as boolean,
          isEnterobacter: challengeTest.isEnterobacter as boolean,
        });
      }
    } else {
      form.setFieldsValue({
        yeastName: 'Candida albicans',
        moldName: 'Aspergillus brasiliensis',
      });
    }
  }, [updateMode, challengeTest]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        isReportExist: false,
        bacteriaTemperature: 33.1,
        yeastTemperature: 24.1,
        moldTemperature: 25.1,
        saureus: 3.11,
        saureusExponent: 2,
        ecoli: 3.12,
        ecoliExponent: 3,
        paeruginosa: 3.13,
        paeruginosaExponent: 4,
        enterobacter: 3.15,
        enterobacterExponent: 6,
        bacillus: 3.14,
        bacillusExponent: 5,
        yeast: 2.16,
        yeastExponent: 7,
        mold: 2.17,
        moldExponent: 8,
      });
    }
  }, []);
  return useMemo(
    () => ({
      updateMode,
      form,
      getLoading,
      addLoading,
      updateLoading,
      selectedBacteriaCount,
      checkMap,
      isMergeBacteria,
      isMergeYeast,
      isMergeMold,
      toggleIsMergeBacteria,
      toggleIsMergeYeast,
      toggleIsMergeMold,
      toggleCheck,
      onSubmit,
    }),
    [
      updateMode,
      getLoading,
      addLoading,
      updateLoading,
      updateMode,
      checkMap,
      isMergeYeast,
      isMergeBacteria,
      isMergeMold,
    ],
  );
};
