import { Button } from 'antd';
import styled from 'styled-components';

import FileViewer from 'components/file/FileViewer';
import FooterBox from 'components/FooterBox';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import history from 'lib/history';
import path from 'lib/path';
import { downloadFile, printFile } from 'lib/file';
import { NMPAContractStatus } from 'types/material/nmpa/nmpa';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 920px;
`;

const StyledButton = styled(Button)`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${palette.PRIMARY50};
      transition: all 200ms ease;
    }
  }

  svg,
  path {
    transition: all 200ms ease;
  }
`;

const NMPAEstimate = ({
  estimateFileUrl,
  materialNmpaEstimateId,
}: {
  estimateFileUrl?: string;
  materialNmpaEstimateId: number;
}) => {
  const handleDownload = () => {
    downloadFile(estimateFileUrl);
  };

  const handlePrint = () => {
    if (estimateFileUrl) {
      printFile(estimateFileUrl);
    }
  };

  return (
    <Container>
      <Flex
        gap={8}
        justify="end"
        style={{
          width: '100%',
          marginBottom: 8,
        }}
      >
        <StyledButton
          icon={<Icon name="download" size={14} style={{ marginRight: 4 }} />}
          style={{
            width: 144,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleDownload}
        >
          다운로드
        </StyledButton>
        <StyledButton
          icon={<Icon name="print" size={14} style={{ marginRight: 4 }} />}
          style={{
            width: 144,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handlePrint}
        >
          인쇄하기
        </StyledButton>
      </Flex>
      <FileViewer title="" file={estimateFileUrl} />
      <FooterBox>
        <Button
          style={{
            color: palette.SLATE_GRAY60,
            background: palette.SLATE_GRAY30,
            border: 'none',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
          }}
          onClick={() => history.push(path.material.nmpa.requestList)}
        >
          나중에 하기
        </Button>
        <Button
          style={{
            fontSize: '16px',
          }}
          type="primary"
          onClick={() =>
            history.push(
              `${path.material.nmpa.estimate.root}/${materialNmpaEstimateId}/contract`,
              { status: NMPAContractStatus.ISS_FIN },
            )
          }
        >
          이 견적으로 계약하기
        </Button>
      </FooterBox>
    </Container>
  );
};

export default NMPAEstimate;
