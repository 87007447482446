import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Empty, Tabs } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';

import path from 'lib/path';
import { NMAPCompanyType } from 'types/material/nmpa/nmpa';
import { useNMPACompanyInfo } from 'service/material/nmpa/nmpa';
import PageTemplate from 'templates/PageTemplate';
import { Typography } from 'components/system';
import NMPAPlatformAccount from 'components/material/nmpa/NMPAPlatformAccount';
import NMPAApplyingInformation from 'components/material/nmpa/NMPAApplyingInformation';
import NMPAAuthorization from 'components/material/nmpa/NMPAAuthorization';

const Container = styled.div``;

const EmptyContainer = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 4px;
  width: 200px;
  height: 56px;
  font-size: 16px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 20px;

    .ant-tabs-tab {
      justify-content: center;
      width: 148px;
      font-size: 16px;
      color: #a9a6a6;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    font-weight: 500;
  }
`;

const NMPACompanyInfoPage = () => {
  const { nmpaCompanyInfoType, nmpaCompanyInfo } = useNMPACompanyInfo();
  const location = useLocation<{ type?: NMAPCompanyType }>();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const { type } = location.state || {};
  const activeKey = searchParams.get('type') || type || undefined;

  const handleActiveKeyChange = (activeKey: NMAPCompanyType) => {
    history.replace(path.my + '/nmpa', activeKey);
  };

  useEffect(() => {
    if (!nmpaCompanyInfoType || searchParams.get('type')) return;

    const { isDirect, isProxy } = nmpaCompanyInfoType;

    if (!isProxy && isDirect) {
      handleActiveKeyChange(NMAPCompanyType.PLATFORM);
    } else if (isProxy) {
      handleActiveKeyChange(NMAPCompanyType.PROXY);
    }
  }, [nmpaCompanyInfoType]);

  return (
    <Container>
      {!nmpaCompanyInfoType?.isProxy && !nmpaCompanyInfoType?.isDirect ? (
        <EmptyContainer>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <Typography.Text color="GRAY50" type="BODY_2">
                  중국 NMPA 신고번호 취득 계약 이력이 없습니다.
                </Typography.Text>
                <Typography.Text color="GRAY50" type="BODY_2">
                  취득을 의뢰해 보세요.
                </Typography.Text>
              </>
            }
          />
          <StyledButton type="primary">
            <Link to={path.material.nmpa.estimate.request}>
              취득 의뢰하러 가기
            </Link>
          </StyledButton>
        </EmptyContainer>
      ) : (
        <StyledTabs
          activeKey={activeKey}
          onTabClick={(activeKey) =>
            handleActiveKeyChange(activeKey as NMAPCompanyType)
          }
        >
          {nmpaCompanyInfoType?.isProxy && (
            <Tabs.TabPane key={NMAPCompanyType.PROXY} tab="기업정보표">
              <PageTemplate
                notices={[
                  '최초 1회에 한하여 중국 원료 등록 플랫폼에 등록하기 위해 필요한 회사 정보를 기입하는 곳입니다.',
                  "추후 '등록 정보 확인' 탭을 통하여 중국 원료 등록 플랫폼에 등록된 정보를 확인하실 수 있습니다.",
                ]}
              >
                <NMPAAuthorization nmpaCompanyInfo={nmpaCompanyInfo} />
              </PageTemplate>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane key={NMAPCompanyType.PLATFORM} tab="플랫폼 계정 정보">
            <PageTemplate
              notices={[
                '최초 1회에 한하여 중국 원료 등록 플랫폼에 등록하기 위해 필요한 회사 정보를 기입하는 곳입니다.',
                '플랫폼의 ID / PW를 가지고 계신 경우 해당 란에 정보를 기입해 주세요.',
              ]}
            >
              <NMPAPlatformAccount nmpaCompanyInfo={nmpaCompanyInfo} />
            </PageTemplate>
          </Tabs.TabPane>
          {nmpaCompanyInfoType?.isProxy && (
            <Tabs.TabPane key={NMAPCompanyType.INFO} tab="등록 정보 확인">
              <PageTemplate
                notices={[
                  '이 곳에서 중국 원료 안정성자료 신고 플랫폼에 등록된 원료사의 정보를 확인하실 수 있습니다.',
                  'certicos 관리자의 입력 전까지 확인하실 수 없습니다.',
                ]}
              >
                <NMPAApplyingInformation nmpaCompanyInfo={nmpaCompanyInfo} />
              </PageTemplate>
            </Tabs.TabPane>
          )}
        </StyledTabs>
      )}
    </Container>
  );
};

export default NMPACompanyInfoPage;
