import { useQuery } from 'react-query';

import { APIResponse } from 'types/common';
import { RawMaterialDetail } from 'types/material/rawMaterialDetail';
import client from 'lib/api/client';

export const useRawMaterialDetail = (materialId: number) => {
  const { data: rawMaterialDetail, isFetching: getLoading } = useQuery(
    ['material/rawMaterialDetail/getRawMaterialDetail', materialId],
    () =>
      client.get<APIResponse<RawMaterialDetail>>(
        `/v1/public-materials/${materialId}`,
      ),
    { select: (res) => res.data.result },
  );

  return {
    rawMaterialDetail,
    getLoading,
  };
};
