import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { Spin } from 'antd';

import palette from 'lib/styles/palette';
import { useAdBanner, useAdStatisticCount } from 'service/ad/ad';
import { AdProductCode } from 'types/ad';
import { Flex } from 'components/ui';

const AdBannerCarouselBlock = styled.div<{
  adBannerLength: number;
  productCode: AdProductCode;
}>`
  max-width: 920px;
  margin: ${(props) => (props.productCode === AdProductCode.LOGIN ? 56 : 16)}px
    auto 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .splide__pagination {
    position: relative;
    bottom: 0;
    margin-top: 16px;
  }

  .splide__pagination__page {
    background-color: ${palette.PRIMARY50};
    opacity: 0.3;
    margin: 0px;
    margin-right: 8px;
  }

  .splide__pagination__page.is-active {
    background-color: ${palette.PRIMARY50};
    opacity: 1;
    transform: scale(1);
  }

  .splide__slide {
    width: 296px !important;
    height: 100px;
    border: 1px solid #dfdfdf;
    cursor: pointer;
  }

  ${(props) =>
    props.adBannerLength < 3 &&
    `
    .splide__list {
      transform: translateX(${
        312 - 156 * (props.adBannerLength - 1)
      }px) !important;
    }
  `}

  ${(props) =>
    props.productCode === AdProductCode.MAIN &&
    `
    max-width: 960px;

    img {
      border-radius: 8px;
    }

    .splide__pagination {
      position: absolute;
      bottom: 16px;
    }

    .splide__pagination__page, .splide__pagination__page.is-active {
      background-color: #fff;
    }

    .splide__slide {
      width: 960px !important;
      height: 260px;
      border: none;
      border-radius: 8px;
    }

    .splide__arrow {
      background: rgba(255, 255, 255, 0.3);
      opacity: 1;
  
      svg {
        fill: #fff;
        height: 13px;
        width: 13px;
      }
  
      &:hover {
        background: rgba(255, 255, 255, 0.3);
        opacity: 1;
      }
    }
  
    .splide__arrow--next {
      right: 24px;
    }
  
    .splide__arrow--prev {
      left: 24px;
    }
  `}
`;

let prevIndexes: number[] = [];

const AdBannerCarousel = ({ productCode }: { productCode: AdProductCode }) => {
  const { adBanners, isLoading } = useAdBanner({
    productCode,
  });

  const { addAdExposureCount, addAdClickCount } = useAdStatisticCount();

  const handleClickSlide = useCallback(
    (index: number) => {
      const newIndex =
        index === -1
          ? adBanners.length - 1
          : index === adBanners.length
          ? 0
          : index;

      const { eventUrl, companyPurchaseProductId } = adBanners[newIndex];

      window.open(eventUrl.includes('://') ? eventUrl : `//${eventUrl}`);
      addAdClickCount(companyPurchaseProductId);
    },
    [adBanners],
  );

  const handleActiveSlide = useCallback(
    (index: number) => {
      if (index >= adBanners.length || index < 0) {
        return;
      }

      if (adBanners.length < 3) {
        addAdExposureCount(
          adBanners.map(
            ({ companyPurchaseProductId }) => companyPurchaseProductId,
          ),
        );

        return;
      }

      const activeIndexes = [
        index <= 0 ? adBanners.length - 1 : index - 1,
        index,
        index >= adBanners.length - 1 ? 0 : index + 1,
      ];

      const companyPurchaseProductIds = activeIndexes
        .filter((activeIndex) => !prevIndexes.includes(activeIndex))
        .map(
          (nextActiveIndex) =>
            adBanners[nextActiveIndex].companyPurchaseProductId,
        );

      if (companyPurchaseProductIds.length !== 0) {
        addAdExposureCount(companyPurchaseProductIds);
      }

      prevIndexes = [...new Set([...prevIndexes, ...activeIndexes])];
    },
    [adBanners],
  );

  useEffect(() => {
    return () => {
      prevIndexes = [];
    };
  }, []);

  if (isLoading)
    return (
      <Flex style={{ marginTop: 94 }} justify="center">
        <Spin spinning={isLoading}></Spin>
      </Flex>
    );

  return (
    <>
      {adBanners.length === 0 ? (
        <></>
      ) : (
        <AdBannerCarouselBlock
          productCode={productCode}
          adBannerLength={adBanners.length}
        >
          <Splide
            onClick={(_, slide) => handleClickSlide(slide.index)}
            onActive={(_, slide) => handleActiveSlide(slide.index)}
            options={{
              autoplay: adBanners.length >= 3 ? true : 'pause',
              interval: adBanners.length >= 3 ? 3000 : undefined,
              type: adBanners.length >= 3 ? 'loop' : undefined,
              perPage: 3,
              perMove: 1,
              focus: 'center',
              gap: '16px',
              arrows:
                adBanners.length !== 1 && productCode === AdProductCode.MAIN
                  ? true
                  : false,
            }}
          >
            {adBanners.map((adBanner) => (
              <SplideSlide key={adBanner.companyPurchaseProductId}>
                <img src={adBanner.fileUrl} alt={adBanner.filename} />
              </SplideSlide>
            ))}
          </Splide>
        </AdBannerCarouselBlock>
      )}
    </>
  );
};

export default AdBannerCarousel;
