import { useMutation } from 'react-query';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';

export const useHeavyMetalsAndMicrobiologicalStandard = (productId: number) => {
  const {
    mutate: addHeavyMetalsAndMicrobiologicalStandard,
    isLoading,
  } = useMutation((isChemicalMicrobiologicalStandardConfirm: boolean) =>
    client.put<APIResponse<null>>(
      `/v1/products/${productId}/microbiological-standard`,
      {
        isChemicalMicrobiologicalStandardConfirm,
      },
    ),
  );

  return {
    addHeavyMetalsAndMicrobiologicalStandard,
    isLoading,
  };
};
