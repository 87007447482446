import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Row, Button } from 'antd';

import {
  getProductArtworks,
  initializeArtwork,
  updateCurrentPathname,
} from 'modules/product';
import { downloadFile } from 'lib/file';
import { messages } from 'lib/consts';
import history from 'lib/history';
import {
  useCountryId,
  useProductArtworkScreeningStatus,
} from 'service/brand/product/product';
import { useProductArtwork, useProductDocStatus } from 'service/product';
import UpdateLog from 'components/product/UpdateLog';
import ProductArtwork from 'components/product/ProductArtwork';
import FlickerAnimation from 'components/FlickerAnimation';
import { Flex } from 'components/ui';
import * as certificateActions from 'modules/certificate';
import useGA, { EGAActionType } from 'hook/useGA';
import { useCertificateMode } from 'hook/certificate';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { DocumentCode } from 'types/product';
import {
  ArtworkItem,
  FileItemProp,
} from 'components/file/ArtworkCountryFileUpload';
import Icon from 'components/ui/Icon/Icon';

const ProductArtworkContainer = ({ countryCode }: { countryCode?: string }) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const dispatch = useDispatch();
  const certificateMode = useCertificateMode();
  const { sendEventToGA } = useGA();
  const {
    currentProduct,
    savedArtworks,
    readOnlyMode,
    isArtworkLegacy,
  } = useSelector(({ product, certificate }: any) => ({
    currentProduct: product.currentProduct,
    savedArtworks: product.artwork,
    isArtworkLegacy: product.isArtworkLegacy,
    readOnlyMode: certificate.readOnlyMode,
  }));
  const productId = currentProduct.productId;
  const countryId = useCountryId();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.ART,
  });

  const { addProductArtwork, addProductArtworkLoading } = useProductArtwork({
    productId,
    countryId,
  });

  const updateMode = savedArtworks?.length > 0;
  const [artworks, setArtworks] = useState<(File | FileItemProp)[]>([]);
  const [artworksRegisterData, setArtworkRegisterData] = useState<
    Record<number, ArtworkItem>
  >({});
  const [deletedArtworkIds, setDeletedArtworkIds] = useState<number[]>([]);

  const countriesCanSeeAI = countryCode === 'EU' || countryCode === 'GB';

  const productArtworkScreeningStatus = useProductArtworkScreeningStatus({
    productId,
    ...(countryId && { countryId }),
    countriesCanSeeAI,
  });

  const onUpload = (file: File) => {
    setArtworks((draft) => draft.concat(file));
    return true;
  };

  const onDeleteArtwork = (artwork: File | FileItemProp) => {
    if (updateMode && (artwork as FileItemProp).uploadFileUrl) {
      setDeletedArtworkIds((draft) => [
        ...draft,
        (artwork as FileItemProp).productArtworkId,
      ]);
      const newArtworks = artworks.filter(
        (item) =>
          (item as FileItemProp).productArtworkId !==
          (artwork as FileItemProp).productArtworkId,
      );
      setArtworks(newArtworks);
    }
    if (artwork instanceof File) {
      const newArtworks = artworks.filter((item) => item !== artwork);

      setArtworks(newArtworks);
    }
  };

  const onSubmit = () => {
    if (certificateMode) {
      if (artworks.length === 0) {
        message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
        return;
      }

      const newArtworks = artworks.filter((artwork) => artwork instanceof File);

      if (!newArtworks.length && !deletedArtworkIds.length) {
        if (productDocStatus?.status === 'CHK') {
          addProductArtwork(
            {
              productId,
              countryId,
              isConfirmOnly: true,
            },
            {
              onSuccess: () => {
                sendEventToGA({
                  documentName: 'Artwork',
                  actionType: EGAActionType.CHECK,
                });
                dispatch(updateCurrentPathname());
              },
            },
          );
        } else {
          message.warning(messages.NO_NEED_TO_UPDATE);
        }

        return;
      }
      if (!updateMode) {
        if (!countryId) return;
        addProductArtwork(
          {
            productId: currentProduct.productId,
            productArtworkFiles: [
              {
                targetCountryId: countryId,
                files: newArtworks as File[],
              },
            ],
            countryId,
          },
          {
            onSuccess: () => {
              sendEventToGA({
                documentName: 'Artwork',
                actionType: EGAActionType.REGISTER,
              });
              dispatch(updateCurrentPathname());
            },
          },
        );
      } else {
        if (newArtworks.length || deletedArtworkIds.length) {
          if (!countryId) return;

          addProductArtwork(
            {
              ...(countryId && { countryId }),
              ...(newArtworks &&
                newArtworks.length > 0 && {
                  productArtworkFiles: [
                    {
                      targetCountryId: countryId,
                      files: newArtworks as File[],
                    },
                  ],
                }),
              ...(deletedArtworkIds && {
                deleteProductArtworkIds: deletedArtworkIds,
              }),
            },
            {
              onSuccess: () => {
                const isMOD =
                  productDocStatus && productDocStatus.status === 'MOD';
                sendEventToGA({
                  documentName: 'Artwork',
                  actionType: isMOD
                    ? EGAActionType.MODIFY
                    : EGAActionType.UPDATE,
                });
                if (isMOD) {
                  dispatch(
                    certificateActions.fixDocument(
                      productDocStatus.productDocStatusId,
                    ),
                  );
                  message.success('보완 완료되었습니다.');
                } else {
                  message.success('수정되었습니다.');
                }
                history.goBack();
              },
            },
          );
        }
      }
    } else {
      const chinaData = Object.values(artworksRegisterData).find(
        (item) => item.countryCode === 'CN' || item.countryId === 3,
      );

      if (chinaData && chinaData?.files?.length === 0) {
        return message.warn('중국 Artwork은 필수 업로드 해주세요.');
      }

      const params: {
        targetCountryId: number;
        files: File[];
      }[] = [];
      const hasFilesCountries = Object.values(artworksRegisterData)
        .map((ctr) => ({
          ...ctr,
          files: ctr.files?.map((file) => file),
        }))
        ?.filter((item) => item.files && item.files.length > 0);

      hasFilesCountries.forEach((ctr) => {
        const uploadFiles = ctr.files?.filter((file) => file instanceof File);
        if (!uploadFiles) return;
        if (uploadFiles.length > 0) {
          params.push({
            targetCountryId: ctr.countryId,
            files: uploadFiles as File[],
          });
        }
      });

      if (!updateMode && hasFilesCountries.length === 0) {
        message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
        return;
      }

      if (
        updateMode &&
        (hasFilesCountries.length === 0 || params.length === 0) &&
        deletedArtworkIds.length === 0
      )
        return message.warning(messages.NO_NEED_TO_UPDATE);

      addProductArtwork(
        {
          productId: currentProduct.productId,
          productArtworkFiles: params,
          ...(updateMode && {
            ...(countryId && { countryId }),
            ...(deletedArtworkIds && {
              deleteProductArtworkIds: deletedArtworkIds,
            }),
          }),
        },
        {
          onSuccess: () => {
            if (!updateMode) {
              sendEventToGA({
                documentName: 'Artwork',
                actionType: EGAActionType.REGISTER,
              });
              dispatch(updateCurrentPathname());
            } else {
              message.success('수정되었습니다.');
              history.goBack();
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProductArtworks({
        productId,
        countryId,
      }),
    );
    return () => {
      dispatch(initializeArtwork());
    };
  }, []);

  useEffect(() => {
    if (savedArtworks?.length) {
      setArtworks(savedArtworks);
    }
  }, [savedArtworks]);

  return (
    <>
      <Row justify="space-between">
        <Flex
          align="center"
          justify={
            (productDocStatus?.status === 'CHK' ||
              productDocStatus?.status === 'MOD') &&
            (countryCode === 'GB' || countryCode === 'EU')
              ? 'space-between'
              : 'end'
          }
          columnGap={8}
          style={{ width: 864, margin: '0 auto' }}
        >
          {(productDocStatus?.status === 'CHK' ||
            productDocStatus?.status === 'MOD') &&
            (countryCode === 'GB' || countryCode === 'EU') && (
              <Flex>
                <Button
                  icon={
                    <Icon
                      name="download"
                      color="PRIMARY50"
                      size={14}
                      style={{ marginRight: 4 }}
                    />
                  }
                  style={{
                    borderRadius: 4,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    let fileUrl = '',
                      fileName = '';

                    if (countryCode === 'GB') {
                      fileUrl = `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%80%E1%85%AE%E1%86%A8+%E1%84%85%E1%85%A1%E1%84%87%E1%85%A6%E1%86%AF+%E1%84%91%E1%85%B5%E1%86%AF%E1%84%89%E1%85%AE+%E1%84%91%E1%85%AD%E1%84%80%E1%85%B5%E1%84%89%E1%85%A1%E1%84%92%E1%85%A1%E1%86%BC.pdf`;
                      fileName = 'UK Cosmetics Labelling Requirements.pdf';
                    } else {
                      fileUrl = `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/%E1%84%8B%E1%85%B2%E1%84%85%E1%85%A5%E1%86%B8+%E1%84%85%E1%85%A1%E1%84%87%E1%85%A6%E1%86%AF+%E1%84%91%E1%85%B5%E1%86%AF%E1%84%89%E1%85%AE+%E1%84%91%E1%85%AD%E1%84%80%E1%85%B5%E1%84%89%E1%85%A1%E1%84%92%E1%85%A1%E1%86%BC.pdf`;
                      fileName = 'EU Cosmetics Labelling Requirements.pdf';
                    }

                    downloadFile(fileUrl, fileName);
                  }}
                >
                  {productDocStatus?.status === 'CHK' && <FlickerAnimation />}
                  필수 기재 사항 다운로드
                </Button>
              </Flex>
            )}
          {countriesCanSeeAI &&
          !isArtworkLegacy &&
          productArtworkScreeningStatus &&
          productDocStatus?.status === 'MOD' ? (
            <Button
              className="ant-btn-gray"
              style={{
                height: 32,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={
                <Icon
                  name="textsms"
                  color="GRAY70"
                  size={14}
                  style={{ marginRight: 4 }}
                />
              }
              onClick={() =>
                history.push({
                  pathname: `/product-art-screening/${productId}`,
                  state: {
                    productId,
                    countryId,
                  },
                })
              }
            >
              <FlickerAnimation />
              AI 스크리닝 결과 확인
            </Button>
          ) : (
            <ProductCorrectButton
              disableAnim={productDocStatus?.status === 'CHK'}
              isFirstOpenChatModal={isFirstOpenChatModal}
              onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
              documentCode={DocumentCode.ART}
            />
          )}
          {updateMode && (
            <UpdateLog
              gutter={0}
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.ART}
            />
          )}
        </Flex>
      </Row>
      <ProductArtwork
        certificateMode={certificateMode}
        setArtworkRegisterData={setArtworkRegisterData}
        status={productDocStatus?.status}
        updateMode={updateMode}
        readOnlyMode={readOnlyMode}
        artworks={artworks}
        savedArtworks={savedArtworks}
        onUpload={onUpload}
        fetchLoading={addProductArtworkLoading}
        onDeleteArtwork={onDeleteArtwork}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ProductArtworkContainer;
