import React from 'react';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Flex } from '.';

export const Background = styled(Flex)`
  padding: 3px;
  width: 18px;
  height: 18px;
  background-color: #e6f0ff;
  border-radius: 4px;
`;

const PrimaryIconWithBackground = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  if (!children) return null;

  const childrenArray = React.Children.toArray(children);

  const icons = childrenArray.map((child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        style: {
          color: palette.PRIMARY50,
          fontSize: 12,
        },
      });
    }

    return null;
  });

  return (
    <Background align="center" justify="center">
      {icons}
    </Background>
  );
};

export default PrimaryIconWithBackground;
