import { useState } from 'react';
import { Button, Checkbox, Col, Form, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import UpdateLog from 'components/product/UpdateLog';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { useProductSeparateWaste } from 'service/brand/product/separateWaste';
import { DocumentCode } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const ProductSeparateWasteBlock = styled.div``;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-checkbox-group {
    & > *:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  .ant-checkbox-wrapper {
    font-size: 16px;
  }
`;

const CategoryTitle = styled.div<{ active: boolean }>`
  border-radius: 8px;
  background-color: ${(props) =>
    props.active ? palette.LIGHT_BLUE20 : palette.SLATE_GRAY10};
  padding: 16px 32px;
  margin-bottom: 8px;
`;

const CategoryContent = styled.div`
  flex-grow: 1;
  border-radius: 4px;
  border: solid 1px ${palette.GRAY40};
  background-color: #fff;
  padding: 16px 32px;
`;

const ProductSeparateWaste = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const {
    form,
    plasticOptions,
    paperOptions,
    updateMode,
    getLoading,
    putLoading,
    onSubmit,
  } = useProductSeparateWaste(productId, countryId);
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.SWI}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.SWI}
            />
          </Col>
        )}
      </Row>
      <ProductSeparateWasteBlock>
        <Spin spinning={getLoading}>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const plasticIds = getFieldValue('plasticIds');
                const paperIds = getFieldValue('paperIds');
                return (
                  <Row gutter={16}>
                    <Col span={12}>
                      <CategoryWrapper>
                        <CategoryTitle
                          active={plasticIds && plasticIds.length > 0}
                        >
                          <Typography.Text medium color="SLATE_GRAY70">
                            플라스틱
                          </Typography.Text>
                        </CategoryTitle>
                        <CategoryContent>
                          <Form.Item
                            name="plasticIds"
                            noStyle
                            initialValue={[]}
                          >
                            <Checkbox.Group
                              options={plasticOptions}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              disabled={readOnlyMode}
                            />
                          </Form.Item>
                        </CategoryContent>
                      </CategoryWrapper>
                    </Col>
                    <Col span={12}>
                      <CategoryWrapper>
                        <CategoryTitle active={paperIds && paperIds.length > 0}>
                          <Typography.Text medium color="SLATE_GRAY70">
                            종이
                          </Typography.Text>
                        </CategoryTitle>
                        <CategoryContent>
                          <Form.Item name="paperIds" noStyle initialValue={[]}>
                            <Checkbox.Group
                              options={paperOptions}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              disabled={readOnlyMode}
                            />
                          </Form.Item>
                        </CategoryContent>
                      </CategoryWrapper>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
            <FooterBox style={{ paddingTop: 40 }}>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                {updateMode && (
                  <CorrectRequestWithoutChangeButton
                    documentCode={DocumentCode.SWI}
                  />
                )}
                <Button loading={putLoading} type="primary" htmlType="submit">
                  {!updateMode ? '등록' : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </Form>
        </Spin>
      </ProductSeparateWasteBlock>
    </>
  );
};

export default ProductSeparateWaste;
