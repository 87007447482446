export const breakpoint = {
  sm: 600,
  md: 920,
  lg: 1440,
};

export const screenMin = {
  md: `${breakpoint.sm}px`,
  lg: `${breakpoint.md}px`,
  xl: `${breakpoint.lg}px`,
};

export const screenMax = {
  sm: `${breakpoint.sm - 1}px`,
  md: `${breakpoint.md - 1}px`,
};

export const deviceSize = {
  sm: `(max-width: ${screenMax.sm})`,
  md: `(min-width: ${screenMin.md}) and (max-width: ${screenMax.md})`,
  mdOver: `(min-width: ${screenMin.md})`,
  lg: `(min-width: ${screenMin.lg})`,
};

export const size = {
  modal: {
    lg: '992px',
  },
  layout: {
    navigatorMax: '1144px',
    contentMax: screenMin.lg,
    footerMax: '1144px',
  },
};
