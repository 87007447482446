import { Button, Form, FormInstance, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import ingredientClient from 'lib/api/ingredientClient';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import { ScreeningFormData } from 'pages/brand/formulaScreening/FormulaScreeningPage';
import { inciApiUrls } from 'service/core';
import FormulaIngredientSelect from './FormulaIngredientSelect';
import { StepModalContentItem } from 'components/modal/StepModal';
import { getOS } from 'lib/common';

const ScreeningForm = ({
  form,
  isArtworkScreening = false,
}: {
  form: FormInstance<ScreeningFormData>;
  isArtworkScreening?: boolean;
}) => {
  const os = getOS();

  const handlePaste = (addRow: VoidFunction) => (
    e: React.ClipboardEvent<HTMLElement>,
    field: FormListFieldData,
    name: keyof ScreeningFormData['ingredients'][number],
  ) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData('text');
    const rows = pasteText
      .split(os === 'MacOS' ? '\n' : '\r\n')
      .map((row: string) => row.split('\t'));
    const { ingredients } = form.getFieldsValue();

    const getTransformedRows = () => {
      switch (name) {
        case 'name':
          return rows.map((row) => ({
            name: row[0],
            ...(row[1] && { wt: row[1].trimStart().trimEnd() }),
            ...(row[2] && { casNo: row[2] }),
          }));
        case 'wt':
          return rows.map((row) => ({
            wt: row[0].trimStart().trimEnd(),
            ...(row[1] && { casNo: row[1] }),
          }));
        case 'casNo':
          return rows.map((row) => ({
            casNo: row[0],
          }));
      }
    };

    const transformedRows = getTransformedRows();
    const validateFieldNames: (string | number)[][] = [];
    const additionalRowCount =
      transformedRows.length + field.key - ingredients.length;

    if (additionalRowCount) {
      for (let i = 0; i < additionalRowCount; i++) {
        addRow();
      }
    }

    transformedRows.forEach((item, idx) => {
      ingredients[idx + field.key] = {
        ...ingredients[idx + field.key],
        ...item,
      };

      validateFieldNames.push(
        ...Object.keys(item).map((fieldName) => [
          'ingredients',
          idx + field.key,
          fieldName,
        ]),
      );
    });

    form.setFieldsValue({
      ingredients,
    });
    setTimeout(() => {
      form.validateFields(validateFieldNames);
    }, 0);
  };

  return (
    <ScreeningContainer>
      <Form.List name="ingredients" initialValue={[{}, {}, {}, {}, {}]}>
        {(fields, { add, remove }) => {
          const columns = ['No', '성분명', 'WT %', 'CAS No.'];

          return (
            <>
              <FormHeader>
                {columns.map((name, idx) => (
                  <Flex
                    key={idx}
                    align="center"
                    justify="center"
                    style={{ height: 40 }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: 14,
                        color: palette.GRAY90,
                        width:
                          idx === 0
                            ? 40
                            : idx === 1
                            ? 600
                            : idx === 2
                            ? 184
                            : 216,
                        textAlign: 'center',
                      }}
                    >
                      {name}
                    </Typography.Text>
                  </Flex>
                ))}
              </FormHeader>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.ingredients !== next.ingredients
                }
              >
                {({ getFieldsValue }) => {
                  return (
                    <>
                      {fields.map((field) => {
                        const fieldItem = getFieldsValue().ingredients?.[
                          field.name
                        ];

                        const hasData =
                          fieldItem?.wt ||
                          fieldItem?.name ||
                          fieldItem?.name !== '';

                        const getRequired = () => {
                          if (isArtworkScreening) {
                            if (hasData) {
                              return (
                                (fieldItem?.wt &&
                                  (!fieldItem?.name ||
                                    fieldItem?.name === '')) ||
                                (!fieldItem?.wt &&
                                  fieldItem?.name &&
                                  fieldItem?.name !== '')
                              );
                            } else {
                              return false;
                            }
                          } else {
                            return (
                              fieldItem?.wt !== null &&
                              fieldItem?.wt !== undefined &&
                              (!fieldItem?.name || fieldItem?.name === '')
                            );
                          }
                        };

                        const isReguiredItem = getRequired();

                        return (
                          <RowItem key={field.key} gap={32}>
                            {fields.length > 1 && (
                              <Icon
                                name="controlMinus"
                                size={20}
                                style={{
                                  position: 'absolute',
                                  left: -32,
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                }}
                                onClick={() => remove(field.name)}
                              />
                            )}
                            <Form.Item>
                              <Typography.Text
                                style={{
                                  fontSize: 14,
                                  color: palette.GRAY90,
                                  textAlign: 'center',
                                  minWidth: 18,
                                }}
                              >
                                {field.name + 1}
                              </Typography.Text>
                            </Form.Item>
                            <Form.Item
                              required={isReguiredItem}
                              rules={generateFormRules({
                                required: isReguiredItem,
                              })}
                              name={[field.name, 'name']}
                            >
                              <FormulaIngredientSelect
                                field={field}
                                onPaste={handlePaste(() => {
                                  add({ name: null, wt: null, casNo: null });
                                })}
                              />
                            </Form.Item>
                            <Form.Item
                              rules={[
                                ...(generateFormRules({
                                  onlyNumber: true,
                                  required: isReguiredItem,
                                }) || []),
                                {
                                  validator: async (_, value) => {
                                    if (
                                      value &&
                                      !isNaN(value) &&
                                      !/^-?\d+(\.\d{1,25})?$/g.test(value)
                                    ) {
                                      return Promise.reject(
                                        '소수점 최대 25자리까지 가능',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              name={[field.name, 'wt']}
                              required={isReguiredItem}
                            >
                              <Input
                                style={{ width: 152 }}
                                placeholder="WT % 입력"
                                onPaste={(e) =>
                                  handlePaste(() => {
                                    add({ name: null, wt: null, casNo: null });
                                  })(e, field, 'wt')
                                }
                              />
                            </Form.Item>
                            <Form.Item name={[field.name, 'casNo']}>
                              <Input
                                style={{ width: 184 }}
                                placeholder="‘-’로 구분하여 숫자만 입력"
                                onPaste={(e) =>
                                  handlePaste(() => {
                                    add({ name: null, wt: null, casNo: null });
                                  })(e, field, 'casNo')
                                }
                              />
                            </Form.Item>
                          </RowItem>
                        );
                      })}
                    </>
                  );
                }}
              </Form.Item>

              <Form.Item>
                <AddRowButton
                  onClick={() => add({ name: null, wt: null, casNo: null })}
                  type="dashed"
                  htmlType="button"
                >
                  <Icon name="plus" color="PRIMARY50" size={18} />
                  입력 항목 추가
                </AddRowButton>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </ScreeningContainer>
  );
};

const FormulaScreeningTemplete = ({
  form,
  isArtworkScreening = false,
}: {
  form: FormInstance<ScreeningFormData>;
  isArtworkScreening?: boolean;
}) => {
  const [excelLoading, setExcelLoading] = useState(false);
  const { openAlertModal, openStepModal } = useModal();

  const handleInit = () => {
    openAlertModal({
      content: '초기화 하시겠습니까?',
      onOk: () => {
        form.resetFields();
      },
      okText: '초기화',
    });
  };

  const modalContentArr: StepModalContentItem[] = [
    {
      imgSrc:
        'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/formula-screening_img1.png',
      title: (
        <>
          엑셀 서식에 정보 입력 후<br />
          입력 내용 복사하기
        </>
      ),
      imageAlt: 'certicos',
      imgSize: 272,
      description: (
        <>
          엑셀 서식에 성분명, WT %, CAS No.를 입력 후<br />
          No를 제외한 입력 내용을 드래그하여 복사해 주세요.
        </>
      ),
    },
    {
      imgSrc:
        'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/formula-screening_img2.png',
      imageAlt: 'certicos',
      title: (
        <>
          certicos에서 제공하는 템플릿에
          <br />
          입력 내용 붙여넣기
        </>
      ),
      imgSize: 272,
      description: (
        <>
          첫번째 셀에 있는 성분명 Select Box를 클릭 후<br />
          붙여넣기 해 주세요.
        </>
      ),
    },
    {
      imgSrc:
        'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/formula-screening_img3.png',
      title: (
        <>
          템플릿에 자동으로
          <br />
          입력된 항목 확인하기
        </>
      ),
      imageAlt: 'certicos',
      imgSize: 270,
      description: (
        <>
          엑셀 서식에 공백이 있는 경우
          <br />
          원하는 셀에 붙여넣기가 되지 않을 수 있어요.
        </>
      ),
    },
  ];

  const handleShowInputExampleModal = () => {
    openStepModal({ modalContentArr });
  };

  const today = new Date().toISOString().split('T')[0];

  const handleDownloadToExcel = async () => {
    const ingredients = form.getFieldValue('ingredients');
    if (!ingredients) return;

    setExcelLoading(true);
    const { data: blobFile } = await ingredientClient.post(
      `${inciApiUrls.formulaJsonExcelDownload}`,
      ingredients,
      {
        responseType: 'blob',
      },
    );

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blobFile);
    a.download = decodeURIComponent(`${today}_성분스크리닝.xlsx`);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setExcelLoading(false);
  };

  return (
    <Container>
      <Flex
        gap={8}
        style={{
          marginTop: 56,
          paddingBottom: 8,
          borderBottom: `2px solid ${palette.PRIMARY50}`,
          width: '100%',
        }}
        align="center"
        justify="space-between"
      >
        <Flex gap={8} align="center">
          <Flex gap={4} align="center">
            <Icon name={isArtworkScreening ? 'robot' : 'screening'} size={24} />
            <Typography.Text type="TITLE_1">
              {isArtworkScreening ? '전성분표 입력' : '성분 스크리닝 정보 입력'}
            </Typography.Text>
          </Flex>
          <SmallGrayButton type="button" onClick={handleInit}>
            <Icon name="refresh" size={16} color="SLATE_GRAY60" />
            초기화
          </SmallGrayButton>
        </Flex>
        <SmallGrayButton
          style={{ width: 127 }}
          type="button"
          onClick={handleDownloadToExcel}
        >
          <Icon
            loading={excelLoading}
            name="download"
            size={16}
            color="SLATE_GRAY60"
          />
          엑셀로 다운로드
        </SmallGrayButton>
      </Flex>
      <DescriptionContainer gap={4} dir="column">
        <Flex align="center" gap={8}>
          <Typography.Text type="BODY_2">
            · 엑셀 서식에 입력 내용을 복사한 후 certicos에서 제공하는 템플릿에
            붙여넣기하면 자동으로 입력됩니다.
          </Typography.Text>
          <ExampleButton
            justify="center"
            align="center"
            onClick={handleShowInputExampleModal}
            gap={4}
          >
            <Icon name="find" size={12} color="PRIMARY50" />
            가이드 보기
          </ExampleButton>
        </Flex>
        <Typography.Text style={{ fontSize: 14 }}>
          · CAS No. 가 없거나 모르시는 경우는 입력하지 않으셔도 됩니다.
        </Typography.Text>
        {isArtworkScreening && (
          <>
            <Typography.Text style={{ fontSize: 14 }}>
              · 필요한{' '}
              <Typography.Text
                type="BODY_2"
                inline
                color="MESSAGE_ERROR"
                style={{ fontWeight: 500 }}
              >
                단일 전성분표
              </Typography.Text>
              는 제조사에 요청해야 하며 미리 서류 준비 부탁드립니다.
            </Typography.Text>
            <Typography.Text type="BODY_2">
              · Artwork와 동일한 언어를 사용하여 전성분표에 작성해 주세요.
            </Typography.Text>
            <Typography.Text type="BODY_2">
              · 단상자 전성분표에 있는 성분의 함량이 1% 이상이면 함량이 높은
              순서대로 입력해 주세요.
            </Typography.Text>
          </>
        )}
      </DescriptionContainer>
      <DataContainer>
        <ScreeningForm isArtworkScreening={isArtworkScreening} form={form} />
      </DataContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .editable-cell-value-wrap {
    padding: 0 !important;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    text-align: start !important;
  }
`;

const SmallGrayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.SLATE_GRAY70};
  background-color: ${palette.SLATE_GRAY30};
  padding: 6px 8px;
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  border: none;
  border-radius: 4px;
  gap: 4px;
  cursor: pointer;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const DataContainer = styled.div`
  margin-top: 24px;
`;

const AddRowButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 520px;
  height: 44px;
  color: ${palette.GRAY90};
  font-size: 14px;
  gap: 4px;
  margin: 0 auto;
  margin-top: 16px;
`;

const ScreeningContainer = styled.div``;

const FormHeader = styled(Flex)`
  background-color: ${palette.PRIMARY10};
  color: ${palette.GRAY90};
`;

const RowItem = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid ${palette.GRAY20};
  padding: 12px 0;
`;

const ExampleButton = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${palette.PRIMARY50};
  background: white;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.GRAY90};
  height: 24px;
  cursor: pointer;
  padding: 0 8px;
  transition: all 200ms ease-in-out;

  &:hover {
    color: ${palette.PRIMARY50};
  }
`;

export default FormulaScreeningTemplete;
