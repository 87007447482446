import { Link, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Form, Tabs } from 'antd';
import { useEffect } from 'react';

import path from 'lib/path';
import { scrollToFirstErrorOption } from 'lib/consts';
import FooterBox from 'components/FooterBox';
import SuperUserForm from 'components/auth/SuperUserForm';
import MaterialForm from './MaterialForm';
import { useMaterialRegister } from 'service/material/auth';
import { NoticeCard } from 'components/system';

const MaterialRegisterForm = ({
  onChangeTab,
}: {
  onChangeTab?: (tab: 'company' | 'user') => void;
}) => {
  const {
    form,
    updateMode,
    materials,
    tab,
    submitLoading,
    bizLicenseEnUrl,
    handleClickChangePassword,
    handleClickBack,
    handleClickNext,
    handleSubmit,
    setTab,
  } = useMaterialRegister();

  const mypageRoute = useRouteMatch(path.my);
  const isMyPage = path.my === mypageRoute?.path;

  useEffect(() => {
    onChangeTab && onChangeTab(tab);
  }, [tab]);
  return (
    <MaterialRegisterFormBlock updateMode={updateMode}>
      {isMyPage ? (
        <StyledTabs
          defaultActiveKey={'company'}
          onTabClick={(key) => setTab(key as 'company' | 'user')}
        >
          <Tabs.TabPane key="company" tab="회사 정보">
            <Form
              form={form}
              colon={false}
              layout="vertical"
              scrollToFirstError={scrollToFirstErrorOption}
              onFinish={handleSubmit}
              style={{ maxWidth: 688, margin: '0 auto' }}
            >
              <MaterialForm
                visible={updateMode || tab === 'company'}
                form={form}
                updateMode={updateMode}
                materials={materials}
                bizLicenseEnUrl={bizLicenseEnUrl}
              />

              <FooterBox>
                {!updateMode && tab === 'company' && (
                  <Button>
                    <Link to={path.login}>취소</Link>
                  </Button>
                )}
                {!updateMode && tab === 'user' && (
                  <Button onClick={handleClickBack}>뒤로</Button>
                )}
                {!updateMode && tab === 'company' && (
                  <Button type="primary" onClick={handleClickNext}>
                    다음
                  </Button>
                )}
                {(updateMode || tab === 'user') && (
                  <Button
                    type="primary"
                    loading={submitLoading}
                    htmlType="submit"
                  >
                    {!updateMode ? '회원가입' : '수정'}
                  </Button>
                )}
              </FooterBox>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane key="user" tab="슈퍼유저 정보">
            <NoticeCard title="안내사항" style={{ marginTop: 0 }}>
              <ul>
                <li>
                  슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능하며, 모든
                  영역에서 최고 권한을 가진 계정입니다.
                </li>
                <li>슈퍼유저는 하위 담당자를 추가하거나 삭제할 수 있습니다.</li>
              </ul>
            </NoticeCard>
            <Form
              form={form}
              colon={false}
              layout="vertical"
              scrollToFirstError={scrollToFirstErrorOption}
              onFinish={handleSubmit}
              style={{ maxWidth: 688, margin: '0 auto' }}
            >
              {(updateMode || tab === 'user') && (
                <SuperUserForm
                  form={form}
                  updateMode={updateMode}
                  onClickChangePassword={handleClickChangePassword}
                  onClickBack={handleClickBack}
                />
              )}
              <FooterBox>
                {!updateMode && tab === 'company' && (
                  <Button>
                    <Link to={path.login}>취소</Link>
                  </Button>
                )}
                {!updateMode && tab === 'user' && (
                  <Button onClick={handleClickBack}>뒤로</Button>
                )}
                {!updateMode && tab === 'company' && (
                  <Button type="primary" onClick={handleClickNext}>
                    다음
                  </Button>
                )}
                {(updateMode || tab === 'user') && (
                  <Button
                    type="primary"
                    loading={submitLoading}
                    htmlType="submit"
                  >
                    {!updateMode ? '회원가입' : '수정'}
                  </Button>
                )}
              </FooterBox>
            </Form>
          </Tabs.TabPane>
        </StyledTabs>
      ) : (
        <Form
          form={form}
          colon={false}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          onFinish={handleSubmit}
          style={{ maxWidth: 688, margin: '0 auto' }}
        >
          <MaterialForm
            visible={updateMode || tab === 'company'}
            form={form}
            updateMode={updateMode}
            materials={materials}
            bizLicenseEnUrl={bizLicenseEnUrl}
          />
          {(updateMode || tab === 'user') && (
            <SuperUserForm
              form={form}
              updateMode={updateMode}
              onClickChangePassword={handleClickChangePassword}
              onClickBack={handleClickBack}
            />
          )}
          <FooterBox>
            {!updateMode && tab === 'company' && (
              <Button>
                <Link to={path.login}>취소</Link>
              </Button>
            )}
            {!updateMode && tab === 'user' && (
              <Button onClick={handleClickBack}>뒤로</Button>
            )}
            {!updateMode && tab === 'company' && (
              <Button type="primary" onClick={handleClickNext}>
                다음
              </Button>
            )}
            {(updateMode || tab === 'user') && (
              <Button type="primary" loading={submitLoading} htmlType="submit">
                {!updateMode ? '회원가입' : '수정'}
              </Button>
            )}
          </FooterBox>
        </Form>
      )}
    </MaterialRegisterFormBlock>
  );
};

const MaterialRegisterFormBlock = styled.div<{ updateMode: boolean }>`
  max-width: 1040px;
  margin: 0 auto;
  ${(props) =>
    !props.updateMode &&
    css`
      padding: 40px 0;
    `}
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;
    cursor: pointer;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

export default MaterialRegisterForm;
