import { Modal } from 'antd';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import parse from 'html-react-parser';

import palette from 'lib/styles/palette';
import { ProductArtworkImageCorrection } from 'types/brand/product/artworkScreening';
import { Typography } from 'components/system';

const Container = styled.div`
  padding: 32px 40px;
`;
const ImageWrapper = styled.div<{
  canvasWidth?: number;
  canvasHeight?: number;
  zoomLevel?: number;
}>`
  border: 1px solid ${palette.GRAY30};
  width: ${({ zoomLevel }) => {
    if (!zoomLevel) return;
    return zoomLevel > 499 ? 'auto' : '816px';
  }};
  height: 576px;
  margin-top: 22px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ canvasWidth, canvasHeight }) =>
    canvasWidth !== undefined &&
    canvasHeight !== undefined &&
    `
    canvas, .canvas-container {
      width: ${canvasWidth}px !important;
      height: ${canvasHeight}px !important;
    }
  `}
`;

const TextWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const MessageWrapper = styled.div`
  font-size: 12px;

  p {
    margin: 0;
  }
`;

const AiResultModal = ({
  onClose,
  imageUrl,
  angle = 0,
  imgCorrection,
  drawingElements,
}: {
  onClose: () => void;
  imageUrl: string | undefined;
  angle?: number;
  imgCorrection: ProductArtworkImageCorrection;
  drawingElements: any[];
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [canvasSize, setCanvasSize] = useState<{
    width: number;
    height: number;
  }>();
  const [zoomLevel, setZoomLevel] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
      setZoomLevel(browserZoomLevel);
    });
  }, [zoomLevel]);

  useEffect(() => {
    if (!canvas || !imageUrl) return;

    const image = new Image();
    image.src = imageUrl;

    image.addEventListener('load', () => {
      const canvasWidth = 814;
      const canvasHeight = 574;
      const fabricImage = new fabric.Image(image);
      const parsedFabricData = JSON.parse(
        drawingElements[0]?.elementData || '{}',
      );

      imageRotate(fabricImage, angle);

      // imageRotate(fabricImage);
      const makeObjectsNotSelected = () => {
        if (!parsedFabricData.objects?.length) return;

        for (const object of parsedFabricData.objects) {
          object.selectable = false;
        }
      };

      const calcTargetPosSAndSize = ({ angle }: { angle: number }) => {
        const min = {
          x: 0,
          y: 0,
        };

        const max = {
          x: image.width,
          y: image.height,
        };

        if (angle === 90 || angle === 270) {
          max.x = image.height;
          max.y = image.width;
        }

        for (const object of parsedFabricData.objects || []) {
          // plus 2는 border-width가 잘려보이는 문제를 해결하기 위한 패딩값
          const rightBottomX = object.left + object.width + 2;
          const rightBottomY = object.top + object.height + 2;

          if (object.left < min.x) {
            min.x = object.left;
          }

          if (object.top < min.y) {
            min.y = object.top;
          }

          if (rightBottomX > max.x) {
            max.x = rightBottomX;
          }

          if (rightBottomY > max.y) {
            max.y = rightBottomY;
          }
        }

        return {
          min,
          max,
          width: Math.floor(Math.abs(min.x) + Math.abs(max.x)),
          height: Math.floor(Math.abs(min.y) + Math.abs(max.y)),
        };
      };

      makeObjectsNotSelected();
      const { width, height, min } = calcTargetPosSAndSize({
        angle,
      });

      const canvasSize = {
        width,
        height,
      };

      if (width < canvasWidth && height < canvasHeight) {
        canvasSize.width = width;
        canvasSize.height = height;
      } else {
        let scale = canvasWidth / width;

        if (height * scale > canvasHeight) {
          scale = canvasHeight / height;
        }

        canvasSize.width = width * scale;
        canvasSize.height = height * scale;
      }

      canvas.setViewportTransform([1, 0, 0, 1, -min.x, -min.y]);
      canvas.setDimensions({
        width,
        height,
      });
      setCanvasSize(canvasSize);

      if (parsedFabricData.objects) {
        parsedFabricData.backgroundImage = undefined;
        canvas.loadFromJSON(parsedFabricData, () => {
          canvas.setBackgroundImage(fabricImage, () => {
            canvas.renderAll();
          });
        });
      } else {
        canvas.setBackgroundImage(fabricImage, () => {
          canvas.renderAll();
        });
      }
    });
  }, [canvas, imageUrl, drawingElements]);

  useEffect(() => {
    const canvasEl = canvasRef.current;
    if (!canvasEl) return;

    const fabricCanvas = new fabric.Canvas(canvasEl, {
      selection: false,
      hoverCursor: 'default',
    });

    setCanvas(fabricCanvas);

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  return (
    <Container>
      <Modal
        closable={true}
        visible={true}
        width={896}
        footer={null}
        onCancel={onClose}
        destroyOnClose
      >
        <ImageWrapper
          canvasWidth={canvasSize?.width}
          canvasHeight={canvasSize?.height}
          zoomLevel={zoomLevel}
        >
          <canvas ref={canvasRef} />
        </ImageWrapper>
        {imgCorrection?.correctRequestMessage && (
          <TextWrapper>
            <Typography.Text type="TITLE_2" gutter={{ bottom: 16 }}>
              보완사항
            </Typography.Text>
            <MessageWrapper>
              {parse(imgCorrection?.correctRequestMessage ?? '')}
            </MessageWrapper>
          </TextWrapper>
        )}
      </Modal>
    </Container>
  );
};

/**
 * 90 180 270도에서만 이미지의 left, top의 예상 되는 변경이 이루어집니다.
 */
export function imageRotate(image: fabric.Image, angle: number) {
  image.rotate(angle);

  if (angle === 90) {
    image.left = image.height;
    image.top = 0;
  } else if (angle === 180) {
    image.left = image.width;
    image.top = image.height;
  } else if (angle === 270) {
    image.left = 0;
    image.top = image.width;
  } else {
    image.left = 0;
    image.top = 0;
  }
}

export default AiResultModal;
