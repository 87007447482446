import { useParams } from 'react-router-dom';

import ICIDBasic from 'components/material/icid/ICIDBasic';
import ICIDPageTemplate from './ICIDPageTemplate';
import { DocumentCode } from 'types/material/icid/icid';

const ICIDBasicPage = () => {
  const { icidApplicationId } = useParams<{
    icidApplicationId: string | undefined;
  }>();
  const updateMode = typeof icidApplicationId !== 'undefined';

  return (
    <ICIDPageTemplate
      documentCode={DocumentCode.BASIC}
      subtitle="기본 정보"
      back={updateMode}
      isICIDApplying={!updateMode}
    >
      <ICIDBasic
        icidApplicationId={
          icidApplicationId ? Number(icidApplicationId) : undefined
        }
      />
    </ICIDPageTemplate>
  );
};

export default ICIDBasicPage;
