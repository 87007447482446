import { useParams } from 'react-router-dom';
import moment from 'moment';

import path from 'lib/path';
import QCQALotList from 'components/brand/qcqa/QCQALotList';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';
import { useSearchParams } from 'hook/useSearchParams';
import history from 'lib/history';

export interface QCQALotListPageSearchParams {
  page: number;
  productYear: number;
}

const QCQALotListPage = () => {
  const today = moment();
  const params = useParams<{
    qcqaProductId: string;
  }>();
  const qcqaProductId = Number(params.qcqaProductId);
  const { searchParams, replaceSearchParams } = useSearchParams({
    path: `${path.qcqa.management.root}/${qcqaProductId}/lot`,
  });

  const queryParams: QCQALotListPageSearchParams = {
    page: Number(searchParams.get('page')) || 1,
    productYear: Number(searchParams.get('productYear')) || today.year(),
  };

  return (
    <QCQAPageTemplate
      title="제조번호 관리"
      onBack={() => {
        history.replace(path.qcqa.management.root);
      }}
    >
      <QCQALotList
        queryParams={queryParams}
        replaceSearchParams={replaceSearchParams}
        qcqaProductId={qcqaProductId}
      />
    </QCQAPageTemplate>
  );
};

export default QCQALotListPage;
