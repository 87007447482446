import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ProductDetail } from 'components/manufacturer/product/ProductDetail';
import { useModal } from 'hook/useModal';
import history from 'lib/history';
import PageTemplate from 'templates/PageTemplate';

const ProductDetailPage = () => {
  const [isRegisterable, setRegisterable] = useState(false);
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  const location = useLocation();
  const { openAlertModal } = useModal();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get('status') || undefined;
  const documentCode = searchParams.get('documentCode') || undefined;
  return (
    <PageTemplate
      title="제품 입력정보"
      notices={[
        '각 항목을 클릭하시면, 해당 자료를 등록하셨던 화면으로 연결되며 내용을 수정할 수 있습니다.',
        '기본 정보에서 샘플을 연결하여 자동 입력된 항목은 ‘입력 완료’ 처리됩니다.',
      ]}
      onBack={() => {
        if (isRegisterable) {
          openAlertModal({
            content: (
              <>
                등록 완료 가능한 제품입니다.
                <br />
                '등록 완료' 버튼을 누르시면 제품 코드 부여가 가능합니다.
                <br />
                정말로 뒤로 가시겠습니까?
              </>
            ),
            onOk: () => history.goBack(),
          });
        } else {
          history.goBack();
        }
      }}
    >
      <ProductDetail
        productId={productId}
        status={status}
        documentCode={documentCode}
        setRegisterable={setRegisterable}
      />
    </PageTemplate>
  );
};

export default ProductDetailPage;
