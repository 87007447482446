import { Button, message } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { cloneDeep, isArray } from 'lodash';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { useFormulaArtworkScreeingResult } from 'service/brand/artworkScreening/artworkScreening';
import { useCountryCodeToNameMap } from 'service/country';
import {
  AnalysisItem,
  AnalyzeResultListItem,
  Coordinate,
  CountryRules,
  ImageIngredient,
  Ingredient,
  IngredientCoordinate,
  PostIngredient,
  PostIngredientListItem,
  RuleCode,
  RuleSubCode,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import IngredientImageModal from './step4/IngredientImageModal';
import InquiryButton from './step4/InquiryButton';
import ReportModalContent from './step4/ReportModalContent';
import FullLoading from 'components/FullLoading';
import history from 'lib/history';
import path from 'lib/path';
import TooltipIcon from './step4/TooltipIcon';
import CollapseIngredient from './step4/CollapseIngredient';
import StickyButtonWrapper from './StickyButtonWrapper';

export interface AllError {
  ruleType: RuleType;
  analysisItems: AnalysisItem[];
  errors: {
    countryRules: CountryRules;
    ruleCode: RuleCode;
    postIngredient: PostIngredientListItem | PostIngredient;
  }[];
  isUpdate: boolean;
}

export interface UpdateInfo {
  [key: string]: {
    initialIngredientName: string;
    ingredientName: string;
    directName: string;
    color: keyof typeof palette;
    initialColor: keyof typeof palette;
    additionalIngredientName?: string;
    additionalIngredientNameSeparator?: 'SLASH' | 'PARENTHESIS' | null;
    formulaScreeningArtworkImageIngredientId: number | undefined;
    formulaScreeningArtworkIngredientId: number | undefined;
    allErrors: AllError[];
    order: number;
    initialOrder: number;
  };
}

export interface UpdatedIngredient extends UpdatedIngredientItem {
  relatedIngredients: UpdatedIngredientItem[];
}

export interface UpdatedIngredientItem {
  formulaScreeningAnalyzeItemId: number;
  color: keyof typeof palette;
  initialColor: keyof typeof palette;
  initialIngredientName: string;
  ingredientName: string;
  additionalIngredientName: string;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  directName: string;
  name: string;
  isNotMatch: boolean;
  isMiss: boolean;
  formulaScreeningArtworkImageIngredientId: number | undefined;
  coordinates: Coordinate[];
  allErrors: AllError[];
  order: number;
  initialOrder: number;
}

export const ruleCategories: {
  type: RuleType;
  text: string;
  color: keyof typeof palette;
}[] = [
  {
    type: RuleType.TYPO,
    text: '표기 오류',
    color: 'MESSAGE_ERROR',
  },
  {
    type: RuleType.ORDER,
    text: '함량 순서',
    color: 'LIGHT_BLUE40',
  },
  {
    type: RuleType.MISS,
    text: '미기입',
    color: 'PURPLE_50',
  },
  {
    type: RuleType.NOT_MATCH,
    text: '매칭 결과 없음',
    color: 'GREEN_50',
  },
];

export const getTextColorByRule = (rule?: RuleType) => {
  return (
    ruleCategories.find((item) => item.type === rule)?.color || 'SLATE_GRAY70'
  );
};

const findLastMatchingItem = (
  items: PostIngredientListItem[],
  subCodes: string[],
) => {
  for (let i = items.length - 1; i >= 0; i--) {
    const ruleSubCodes = items[i].ruleSubCode;
    if (ruleSubCodes.every((subCode) => subCodes.includes(subCode))) {
      return items[i];
    }
  }
  return null;
};

export const getIngerdientNameWithAdditional = ({
  ingredientName,
  additionalIngredientName,
  additionalIngredientNameSeparator,
}: {
  ingredientName?: string;
  additionalIngredientName?: string | null;
  additionalIngredientNameSeparator?: 'SLASH' | 'PARENTHESIS' | null;
}) => {
  if (
    ingredientName &&
    additionalIngredientName &&
    additionalIngredientNameSeparator
  ) {
    return `${ingredientName}${
      additionalIngredientNameSeparator === 'SLASH'
        ? `/${additionalIngredientName}`
        : `(${additionalIngredientName})`
    }`;
  }
  return ingredientName || '';
};

const getAllErrors = ({
  analysisItems,
  subCodes,
}: {
  analysisItems: Record<RuleType, AnalysisItem[]> | null | undefined;
  subCodes: RuleSubCode[];
}) => {
  return Object.entries(analysisItems || {})
    .reduce(
      (acc, cur) => {
        const [ruleType, analysisItems] = cur;

        const result: {
          ruleType: RuleType;
          analysisItems: AnalysisItem[];
          errors: {
            countryRules: CountryRules;
            ruleCode: RuleCode;
            postIngredient: PostIngredientListItem | PostIngredient;
          }[];
        }[] = [
          ...acc,
          {
            ruleType: ruleType as RuleType,
            analysisItems,
            errors: analysisItems
              .map((error) => {
                let lastMatchingPostIngredient:
                  | PostIngredientListItem
                  | PostIngredient
                  | null = null;
                if (isArray(error.postIngredient)) {
                  lastMatchingPostIngredient = findLastMatchingItem(
                    error.postIngredient,
                    subCodes,
                  );
                } else {
                  const postIngredientItem = error.postIngredient;
                  if (
                    postIngredientItem?.ruleSubCode.every((subCode) =>
                      subCodes.includes(subCode),
                    )
                  ) {
                    lastMatchingPostIngredient = postIngredientItem;
                  }
                }
                return {
                  countryRules: error.countryRules,
                  ruleCode: error.ruleCode,
                  postIngredient: lastMatchingPostIngredient,
                };
              })
              .filter(
                (
                  item,
                ): item is {
                  countryRules: CountryRules;
                  ruleCode: RuleCode;
                  postIngredient: PostIngredientListItem | PostIngredient;
                } => item.postIngredient !== null,
              ),
          },
        ];

        return result;
      },
      [] as {
        ruleType: RuleType;
        analysisItems: AnalysisItem[];
        errors: {
          countryRules: CountryRules;
          ruleCode: RuleCode;
          postIngredient: PostIngredientListItem | PostIngredient;
        }[];
      }[],
    )
    .filter((item) => item.errors.length > 0)
    .map((item) => ({ ...item, isUpdate: false }));
};

const ArtworkStep4 = ({
  setStep,
  formulaScreeningId,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formulaScreeningId: number;
}) => {
  const [selectedCountryCodes, setSelectedCountryCodes] = useState<string[]>(
    [],
  );
  const [ingredientCoordinate, setIngredientCoordinate] = useState<
    IngredientCoordinate[]
  >([]);
  const [updateInfo, setUpdateInfo] = useState<UpdateInfo>({});
  const [order, setOrder] = useState<number[]>([]);
  const [duplicatedIds, setDuplicatedIds] = useState<number[]>([]);
  const [targetRect, setTargetRect] = useState<DOMRect>();
  const [textRect, setTextRect] = useState<DOMRect>();
  const [openCollapseId, setOpenCollapseId] = useState<number>();
  const [clickedChip, setClickedChip] = useState<{
    type?:
      | 'formulaScreeningAnalyzeItemId'
      | 'formulaScreeningArtworkImageIngredientId';
    id?: number;
  }>({});
  const [isMinimize, setIsMinimize] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(false);

  const ruleContainerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Map<number, HTMLDivElement>>(new Map());
  const { openConfirmModal, openAlertModal } = useModal();
  const countryCodeToNameMap = useCountryCodeToNameMap();
  const {
    artworkScreeningResult,
    reportIngredients,
  } = useFormulaArtworkScreeingResult({ formulaScreeningId });

  const initializeUpdateInfo = ({
    isAllSubCodes,
  }: {
    isAllSubCodes: boolean;
  }) => {
    if (!artworkScreeningResult) return;

    const uniqueSubCodes = Array.from(
      new Set(
        Object.values(
          artworkScreeningResult.rules.map((item) => ({
            ...item,
            countryRules: isAllSubCodes
              ? item.countryRules
              : item.countryRules?.filter(({ countryCode }) =>
                  selectedCountryCodes.includes(countryCode),
                ) || null,
          })),
        ).flatMap(
          (rule) => rule.countryRules?.map((item) => item.ruleSubCode) || [],
        ),
      ),
    );

    const updateInfo = artworkScreeningResult.analyzeResult.reduce(
      (acc, cur, idx) => {
        const ingredientName = getIngerdientNameWithAdditional({
          ingredientName:
            cur.imageIngredient?.ingredientName ||
            cur.ingredient?.ingredientName ||
            '',
          additionalIngredientName:
            cur.imageIngredient?.additionalIngredientName,
          additionalIngredientNameSeparator:
            cur.imageIngredient?.additionalIngredientNameSeparator,
        });

        const allErrors = getAllErrors({
          analysisItems: cur.analysisItems,
          subCodes: uniqueSubCodes,
        });

        const color: keyof typeof palette =
          allErrors.length > 0 && allErrors.every((item) => item.isUpdate)
            ? 'PRIMARY50'
            : getTextColorByRule(
                allErrors.find((item) => !item.isUpdate)?.ruleType,
              );

        acc[cur.formulaScreeningAnalyzeItemId] = {
          directName: '',
          initialIngredientName: ingredientName,
          ingredientName,
          initialColor: color,
          color,
          formulaScreeningArtworkIngredientId:
            cur.ingredient?.formulaScreeningIngredientId,
          formulaScreeningArtworkImageIngredientId:
            cur.imageIngredient?.formulaScreeningArtworkImageIngredientId,
          allErrors,
          order: idx,
          initialOrder: idx,
        };
        return acc;
      },
      {} as UpdateInfo,
    );

    setUpdateInfo(updateInfo);
    setOpenCollapseId(
      artworkScreeningResult.analyzeResult.find(
        (item) => item.analysisItems !== null,
      )?.formulaScreeningAnalyzeItemId,
    );
    setClickedChip(
      artworkScreeningResult.analyzeResult.find(
        (item) => item.analysisItems !== null,
      )?.imageIngredient
        ? {
            type: 'formulaScreeningArtworkImageIngredientId',
            id: artworkScreeningResult.analyzeResult.find(
              (item) => item.analysisItems !== null,
            )?.imageIngredient?.formulaScreeningArtworkImageIngredientId,
          }
        : {
            type: 'formulaScreeningAnalyzeItemId',
            id: artworkScreeningResult.analyzeResult.find(
              (item) => item.analysisItems !== null,
            )?.formulaScreeningAnalyzeItemId,
          },
    );
    setOrder([]);
    setDuplicatedIds([]);
  };

  useEffect(() => {
    if (!artworkScreeningResult) return;

    setIsAutoSave(false);
    initializeUpdateInfo({ isAllSubCodes: false });
  }, [selectedCountryCodes, artworkScreeningResult]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setTargetRect(undefined);
      setTextRect(undefined);
    };

    ruleContainerRef.current?.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      ruleContainerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [targetRect, textRect]);

  useEffect(() => {
    if (!artworkScreeningResult) return;

    if (
      artworkScreeningResult?.availableCountryCodes &&
      artworkScreeningResult?.availableCountryCodes.length > 0
    ) {
      setSelectedCountryCodes(artworkScreeningResult.availableCountryCodes);
    }
  }, [artworkScreeningResult]);

  // 칩 클릭시 해당 성분으로 스크롤 및 밑줄 표시
  useEffect(() => {
    if (!clickedChip.type || !clickedChip.id) {
      return;
    }

    const scrollToText = ({
      target,
      targetIngredient,
    }: {
      target?: HTMLDivElement;
      targetIngredient?: UpdatedIngredientItem;
    }) => {
      if (target && targetIngredient) {
        const scrollContainer = target.parentElement;
        const firstElement = scrollContainer?.firstElementChild as
          | HTMLElement
          | undefined;
        const targetPosition =
          (target?.offsetTop || 0) -
          (firstElement?.offsetTop || 0) -
          ((scrollContainer?.clientHeight || 0) - 72) / 2;

        scrollContainer?.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    };

    if (clickedChip.type === 'formulaScreeningAnalyzeItemId') {
      const targetIngredient = filteredIngredients.find(
        (item) => item.formulaScreeningAnalyzeItemId === clickedChip.id,
      );
      const target = itemsRef.current.get(clickedChip.id);
      scrollToText({ target, targetIngredient });
    } else {
      const targetIngredient = filteredIngredients.find(
        (item) =>
          item.formulaScreeningArtworkImageIngredientId === clickedChip.id,
      );
      const formulaScreeningAnalyzeItemId =
        targetIngredient?.formulaScreeningAnalyzeItemId;

      if (formulaScreeningAnalyzeItemId) {
        const target = itemsRef.current.get(formulaScreeningAnalyzeItemId);

        scrollToText({ target, targetIngredient });
        setIngredientCoordinate(targetIngredient.coordinates);
        setIsMinimize(false);
      }
    }
  }, [clickedChip]);

  const { analyzeResult = [], availableCountryCodes = [] } =
    artworkScreeningResult || {};

  const filteredAnalyzeResult = useMemo(() => {
    if (!artworkScreeningResult || selectedCountryCodes.length === 0) return [];

    return cloneDeep(analyzeResult).map((item) => {
      const { formulaScreeningAnalyzeItemId } = item;

      const { allErrors } = updateInfo[formulaScreeningAnalyzeItemId] || {};

      return { ...item, allErrors };
    });
  }, [artworkScreeningResult, selectedCountryCodes, updateInfo]);

  const allMissIngredients = filteredAnalyzeResult.filter(
    (item) => !!item.analysisItems?.MISS,
  );

  const getUpdatedIngredient = ({
    imageIngredient,
    ingredient,
    formulaScreeningAnalyzeItemId,
    analysisItems,
    allErrors,
  }: {
    imageIngredient: ImageIngredient | null;
    ingredient: Ingredient | null;
    formulaScreeningAnalyzeItemId: number;
    analysisItems: AnalyzeResultListItem['analysisItems'];
    allErrors: AllError[];
  }) => {
    const item = imageIngredient || ingredient;

    const {
      initialColor,
      color,
      ingredientName,
      initialIngredientName,
      additionalIngredientName,
      additionalIngredientNameSeparator,
      directName,
      formulaScreeningArtworkImageIngredientId,
      order,
    } = updateInfo[formulaScreeningAnalyzeItemId] || {};
    const name =
      directName ||
      (additionalIngredientName
        ? getIngerdientNameWithAdditional({
            ingredientName,
            additionalIngredientName,
            additionalIngredientNameSeparator,
          })
        : ingredientName);

    return item
      ? {
          formulaScreeningAnalyzeItemId,
          color,
          initialColor,
          ingredientName,
          initialIngredientName,
          additionalIngredientName,
          directName,
          name,
          isNotMatch: !!analysisItems?.NOT_MATCH,
          isMiss: !!analysisItems?.MISS,
          formulaScreeningArtworkImageIngredientId,
          coordinates: imageIngredient?.coordinates || [],
          allErrors,
          order,
        }
      : null;
  };

  const allIngredients: UpdatedIngredient[] = useMemo(() => {
    if (!artworkScreeningResult || selectedCountryCodes.length === 0) return [];

    return filteredAnalyzeResult
      .map(
        ({
          imageIngredient,
          ingredient,
          formulaScreeningAnalyzeItemId,
          analysisItems,
          allErrors,
        }) => {
          const item = imageIngredient || ingredient;

          const {
            initialColor,
            color,
            ingredientName,
            initialIngredientName,
            additionalIngredientName,
            additionalIngredientNameSeparator,
            directName,
            formulaScreeningArtworkImageIngredientId,
            order,
          } = updateInfo[formulaScreeningAnalyzeItemId] || {};
          const name =
            directName ||
            (additionalIngredientName
              ? getIngerdientNameWithAdditional({
                  ingredientName,
                  additionalIngredientName,
                  additionalIngredientNameSeparator,
                })
              : ingredientName);

          const preRelatedIngredients = allMissIngredients
            .filter((item) => {
              const postIngredient =
                item.analysisItems?.MISS[0]?.postIngredient;

              if (postIngredient && !isArray(postIngredient)) {
                const imageId =
                  postIngredient.post?.formulaScreeningArtworkImageIngredientId;

                if (
                  imageId &&
                  formulaScreeningArtworkImageIngredientId === imageId
                ) {
                  return true;
                }
              }

              return false;
            })
            .map((item) =>
              getUpdatedIngredient({
                imageIngredient: item.imageIngredient,
                ingredient: item.ingredient,
                formulaScreeningAnalyzeItemId:
                  item.formulaScreeningAnalyzeItemId,
                analysisItems: item.analysisItems,
                allErrors: item.allErrors,
              }),
            );

          const postRelatedIngredients = allMissIngredients
            .filter((item) => {
              const postIngredient =
                item.analysisItems?.MISS[0]?.postIngredient;

              if (postIngredient && !isArray(postIngredient)) {
                const imageId =
                  postIngredient.pre?.formulaScreeningArtworkImageIngredientId;

                if (
                  imageId &&
                  formulaScreeningArtworkImageIngredientId === imageId
                ) {
                  return true;
                }
              }

              return false;
            })
            .map((item) =>
              getUpdatedIngredient({
                imageIngredient: item.imageIngredient,
                ingredient: item.ingredient,
                formulaScreeningAnalyzeItemId:
                  item.formulaScreeningAnalyzeItemId,
                analysisItems: item.analysisItems,
                allErrors: item.allErrors,
              }),
            );

          const value = {
            formulaScreeningAnalyzeItemId,
            color,
            initialColor,
            ingredientName,
            initialIngredientName,
            additionalIngredientName,
            directName,
            name,
            isNotMatch: !!analysisItems?.NOT_MATCH,
            isMiss: !!analysisItems?.MISS,
            formulaScreeningArtworkImageIngredientId,
            coordinates: imageIngredient?.coordinates || [],
            allErrors,
            order,
          };

          return item
            ? {
                ...value,
                relatedIngredients: [
                  ...preRelatedIngredients,
                  value,
                  ...postRelatedIngredients,
                ],
              }
            : null;
        },
      )
      .filter((item): item is UpdatedIngredient => item !== null);
  }, [artworkScreeningResult, selectedCountryCodes, updateInfo]);

  const filteredIngredients = useMemo(() => {
    if (!artworkScreeningResult || selectedCountryCodes.length === 0) return [];

    return cloneDeep(allIngredients)
      .filter((item) => {
        const postIngredient = item.allErrors[0]?.errors[0]?.postIngredient;

        return !(
          (item.isMiss &&
            postIngredient &&
            (('pre' in postIngredient && postIngredient.pre !== null) ||
              ('post' in postIngredient && postIngredient.post !== null))) ||
          (item.isNotMatch && item.allErrors.every((item) => item.isUpdate)) ||
          (item.formulaScreeningArtworkImageIngredientId &&
            duplicatedIds.includes(
              item.formulaScreeningArtworkImageIngredientId,
            ))
        );
      })
      .sort((a, b) => a.order - b.order)
      .flatMap(({ relatedIngredients }) =>
        relatedIngredients.filter(
          (item) =>
            !(
              item.isMiss &&
              !item.allErrors.find((item) => item.ruleType === RuleType.MISS)
                ?.isUpdate
            ),
        ),
      );
  }, [artworkScreeningResult, selectedCountryCodes, updateInfo]);

  const handleReport = () => {
    const params = filteredIngredients.map(
      ({
        formulaScreeningAnalyzeItemId,
        additionalIngredientName,
        ingredientName,
        directName,
        allErrors,
      }) => {
        const isUpdate =
          allErrors.length > 0 && allErrors.every(({ isUpdate }) => isUpdate);

        return {
          formulaScreeningAnalyzeItemId,
          ingredientName: directName || ingredientName,
          additionalIngredientName,
          isUpdate,
        };
      },
    );
    reportIngredients(params);
  };

  const errorCount = filteredAnalyzeResult.filter(
    (item) => !!item.analysisItems && item.allErrors.length > 0,
  ).length;
  const updateCount = allIngredients.filter(
    (item) =>
      item.allErrors.length > 0 &&
      item.allErrors.every(({ isUpdate }) => isUpdate),
  ).length;

  // 오류 수정시 자동 저장
  useEffect(() => {
    if (
      !artworkScreeningResult ||
      selectedCountryCodes.length === 0 ||
      !isAutoSave
    )
      return;

    handleReport();
  }, [updateInfo]);

  if (!artworkScreeningResult || selectedCountryCodes.length === 0)
    return <FullLoading />;

  return (
    <Container>
      <InnerContainer>
        <Flex
          justify="space-between"
          style={{
            paddingBottom: 8,
            borderBottom: `2px solid ${palette.PRIMARY50}`,
            marginBottom: 16,
          }}
        >
          <Flex gap={8}>
            <Icon name="robot" size={24} />
            <Typography.Text type="TITLE_1">스크리닝 분석 결과</Typography.Text>
          </Flex>
          <InquiryButton formulaScreeningId={formulaScreeningId} />
        </Flex>
        <CountrySelectWrapper>
          <Flex gap={8}>
            <Typography.Text>🌎</Typography.Text>
            <Typography.Text semiBold color="SLATE_GRAY70">
              국가별 전성분 규칙에 맞게 국가를 선택하실 수 있습니다.
            </Typography.Text>
          </Flex>
          <Flex wrap="true" gap={8} gutter={{ top: 16 }}>
            {availableCountryCodes.map((countryCode) => {
              const isSelected = selectedCountryCodes.includes(countryCode);
              const countryNameKo = countryCodeToNameMap[countryCode];

              return (
                <CountryItem
                  key={countryCode}
                  gap={8}
                  isSelected={isSelected}
                  onClick={() => {
                    const newSelectedCountryCodes = isSelected
                      ? selectedCountryCodes.filter(
                          (code) => code !== countryCode,
                        )
                      : [...selectedCountryCodes, countryCode];

                    if (newSelectedCountryCodes.length === 0) {
                      message.warning('최소 1개 이상의 국가를 선택해 주세요.');
                      return;
                    }

                    const isChanged =
                      allIngredients.filter(
                        (item) =>
                          item.allErrors.length > 0 &&
                          item.allErrors.some(({ isUpdate }) => isUpdate),
                      ).length > 0;
                    if (isChanged) {
                      openAlertModal({
                        width: 400,
                        content:
                          '선택한 국가를 변경하면 변경한 국가 구성에 따라\n진단 항목이 변경되고, 적용 리스트가 초기화됩니다.',
                        okText: '변경',
                        onOk: () => {
                          setSelectedCountryCodes(newSelectedCountryCodes);
                        },
                      });
                    } else {
                      setSelectedCountryCodes(newSelectedCountryCodes);
                    }
                  }}
                >
                  <Icon
                    name={
                      isSelected ? 'checkCircleActived' : 'checkCircleInactived'
                    }
                    size={18}
                  />
                  <Typography.Text
                    type="BODY_2"
                    medium={isSelected}
                    color={isSelected ? 'GRAY90' : 'SLATE_GRAY50'}
                  >
                    {countryNameKo || '-'}
                  </Typography.Text>
                </CountryItem>
              );
            })}
          </Flex>
        </CountrySelectWrapper>
        <CompareBox gap={8} gutter={{ top: 32 }}>
          <AnalysisResult>
            <Flex gap={16} justify="flex-end" style={{ height: 20 }}>
              {ruleCategories.map(({ type, text }) => (
                <Flex key={text} gap={4}>
                  <div
                    style={{
                      width: 6,
                      height: 6,
                      background: palette[getTextColorByRule(type)],
                      borderRadius: '50%',
                    }}
                  ></div>
                  <Typography.Text medium color="SLATE_GRAY70" type="BODY_2">
                    {text}
                  </Typography.Text>
                </Flex>
              ))}
            </Flex>
            <div
              style={{
                borderRadius: 8,
                border: `1px solid ${palette.GRAY30}`,
                marginTop: 8,
              }}
            >
              <Flex
                gap={8}
                justify="space-between"
                style={{
                  borderRadius: '8px 8px 0 0',
                  padding: '16px 23px',
                  background: palette.SLATE_GRAY10,
                  borderBottom: `1px solid ${palette.GRAY30}`,
                }}
              >
                <Typography.Text color="SLATE_GRAY70" medium>
                  Artwork 전성분 리스트
                </Typography.Text>
                <Flex gap={4}>
                  <Typography.Text color="SLATE_GRAY70" medium>
                    진단 항목
                  </Typography.Text>
                  <Typography.Text color="SLATE_GRAY70" medium>
                    :
                  </Typography.Text>
                  <Typography.Text color="SLATE_GRAY70" medium>
                    {errorCount ? '오류 성분' : '오류 성분 없음'}
                  </Typography.Text>
                  {errorCount !== 0 && (
                    <Flex gap={2}>
                      <Typography.Text color="MESSAGE_ERROR" medium inline>
                        {errorCount}
                      </Typography.Text>
                      <Typography.Text color="SLATE_GRAY70" medium inline>
                        건
                      </Typography.Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <div
                style={{ padding: 24, height: 736, overflowY: 'scroll' }}
                ref={ruleContainerRef}
              >
                {filteredAnalyzeResult.map((analyzeResultListItem, idx) => (
                  <CollapseIngredient
                    key={analyzeResultListItem.formulaScreeningAnalyzeItemId}
                    formulaScreeningId={formulaScreeningId}
                    analyzeResultListItem={analyzeResultListItem}
                    selectedCountryCodes={selectedCountryCodes}
                    availableCountryCodes={availableCountryCodes}
                    textRect={textRect}
                    setTextRect={setTextRect}
                    updateInfo={updateInfo}
                    setUpdateInfo={setUpdateInfo}
                    openCollapseId={openCollapseId}
                    setOpenCollapseId={setOpenCollapseId}
                    order={order}
                    setOrder={setOrder}
                    duplicatedIds={duplicatedIds}
                    setDuplicatedIds={setDuplicatedIds}
                    setClickedChip={setClickedChip}
                    idx={idx}
                    targetRect={targetRect}
                    setTargetRect={setTargetRect}
                    setIsAutoSave={setIsAutoSave}
                  />
                ))}
              </div>
            </div>
          </AnalysisResult>
          <Divider dir="column" gap={24}>
            <VerticalLine />
            <Flex
              justify="center"
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                border: `1px solid ${palette.GRAY40}`,
              }}
            >
              <Icon name="front" size={18} color="GRAY40" />
            </Flex>
            <VerticalLine />
          </Divider>
          <ArtworkIngredientList>
            <Flex
              gap={4}
              style={{
                padding: '16px 23px',
                background: palette.SLATE_GRAY10,
                borderRadius: '8px 8px 0 0',
                borderBottom: `1px solid ${palette.GRAY30}`,
              }}
            >
              <Typography.Text color="SLATE_GRAY70" medium>
                적용 리스트
              </Typography.Text>
              {errorCount !== 0 && (
                <>
                  <Typography.Text color="SLATE_GRAY70" medium>
                    :
                  </Typography.Text>
                  <Flex gap={2}>
                    <Typography.Text color="PRIMARY50" medium>
                      {updateCount}
                    </Typography.Text>
                    <Typography.Text color="SLATE_GRAY70" medium>
                      /
                    </Typography.Text>
                    <Typography.Text color="MESSAGE_ERROR" medium>
                      {errorCount}
                    </Typography.Text>
                    <Typography.Text color="SLATE_GRAY70" medium>
                      건
                    </Typography.Text>
                  </Flex>
                </>
              )}
            </Flex>
            <div>
              <Flex
                dir="column"
                gap={16}
                style={{
                  padding: 24,
                  height: 680,
                  overflowY: 'scroll',
                  alignItems: 'flex-start',
                }}
              >
                {filteredIngredients.map(
                  (
                    {
                      formulaScreeningAnalyzeItemId,
                      color,
                      coordinates,
                      name,
                      allErrors,
                      formulaScreeningArtworkImageIngredientId,
                    },
                    idx,
                  ) => (
                    <div
                      key={idx}
                      ref={(el) => {
                        if (el) {
                          itemsRef.current.set(
                            formulaScreeningAnalyzeItemId,
                            el,
                          );
                        } else {
                          itemsRef.current.delete(
                            formulaScreeningAnalyzeItemId,
                          );
                        }
                      }}
                    >
                      <Typography.Text
                        color={allErrors.length === 0 ? 'SLATE_GRAY70' : color}
                        inline
                        onClick={() => {
                          setIngredientCoordinate(coordinates);
                          if (coordinates.length > 0) {
                            setIsMinimize(false);
                          }
                        }}
                        style={{
                          background:
                            clickedChip.type &&
                            ((clickedChip.type ===
                              'formulaScreeningAnalyzeItemId' &&
                              clickedChip.id ===
                                formulaScreeningAnalyzeItemId) ||
                              clickedChip.id ===
                                formulaScreeningArtworkImageIngredientId)
                              ? 'linear-gradient(to bottom, transparent 13px, RGBA(44, 244, 241, 0.5) 0%)'
                              : '',
                        }}
                      >
                        {name}
                      </Typography.Text>
                    </div>
                  ),
                )}
              </Flex>
              <Flex
                gap={16}
                style={{
                  padding: '15px 24px',
                  borderTop: `1px solid ${palette.GRAY30}`,
                }}
              >
                <TooltipIcon
                  name="copy"
                  size={24}
                  targetRect={targetRect}
                  setTargetRect={setTargetRect}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        filteredIngredients.map((item) => item.name).join(', '),
                      )
                      .then(() => {
                        message.success('텍스트를 복사했습니다.');
                      });
                  }}
                />
                <TooltipIcon
                  name="refresh"
                  size={24}
                  targetRect={targetRect}
                  setTargetRect={setTargetRect}
                  onClick={() => {
                    openAlertModal({
                      content: '수정한 내용을 초기화 하시겠습니까?',
                      okText: '초기화',
                      onOk: () => {
                        initializeUpdateInfo({ isAllSubCodes: true });
                      },
                    });
                  }}
                />
              </Flex>
            </div>
          </ArtworkIngredientList>
        </CompareBox>
        <ReportModalDescription justify="center" gap={16}>
          <Typography.Text color="SLATE_GRAY70">
            검토 내용을 비교하고 언어별로 확인해 보세요.
          </Typography.Text>
          <Typography.Text
            color="PRIMARY50"
            semiBold
            underline
            onClick={() => {
              openConfirmModal({
                title: '',
                width: 832,
                content: (
                  <ReportModalContent
                    originalIngredients={allIngredients}
                    updatedIngredients={filteredIngredients}
                    analyzeResult={filteredAnalyzeResult}
                    selectedCountryCodes={selectedCountryCodes}
                    availableCountryCodes={availableCountryCodes}
                  />
                ),
                footer: null,
              });
            }}
            style={{ padding: '2px 4px', textUnderlineOffset: 2 }}
          >
            확인하기
          </Typography.Text>
        </ReportModalDescription>
        <IngredientImageModal
          ingredientCoordinate={ingredientCoordinate}
          url={artworkScreeningResult.artworkImage.url}
          isMinimize={isMinimize}
          setIsMinimize={setIsMinimize}
        />
      </InnerContainer>
      <StickyButtonWrapper>
        <Button
          style={{ width: 200, height: 56, fontSize: 18 }}
          onClick={() => setStep(3)}
        >
          이전
        </Button>
        <RestartButton
          justify="center"
          onClick={() => {
            openAlertModal({
              content:
                '처음부터 다시 하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
              onOk: () => {
                history.replace(path.artworkScreening);
                window.location.reload();
              },
            });
          }}
        >
          처음부터 다시 하기
        </RestartButton>
      </StickyButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 58px;

  ${Flex} {
    align-items: center;
  }
`;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;

const CountrySelectWrapper = styled.div`
  padding: 24px 32px;
  background-color: ${palette.ETC_BG_BLUE};
  border-radius: 8px;
  min-height: 190px;
`;

const CompareBox = styled(Flex)`
  .ant-empty-normal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 736px;
    margin: 0;
  }
  .ant-empty-description {
    color: ${palette.GRAY90};
    opacity: 0.25;
  }
`;

const AnalysisResult = styled.div`
  flex: 0 0 628px;
  max-width: 628px;
  word-break: break-word;
  position: relative;
`;

const Divider = styled(Flex)`
  flex: 0 0 40px;
  align-self: stretch;
  margin-top: 28px;
`;

const VerticalLine = styled.div`
  flex: 1;
  width: 1px;
  background-color: ${palette.GRAY30};
`;

const ArtworkIngredientList = styled.div`
  flex: 0 0 356px;
  word-break: break-word;
  border-radius: 8px;
  border: 1px solid ${palette.GRAY30};
  margin-top: 27px;
`;

const CountryItem = styled(Flex)<{ isSelected: boolean }>`
  background-color: #fff;
  width: 156px;
  border-radius: 4px;
  padding: 13px 15px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 1px solid ${palette.PRIMARY50};
          box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
        `
      : css`
          border: 1px solid ${palette.GRAY30};
        `};
`;

const ReportModalDescription = styled(Flex)`
  margin-top: 16px;
  height: 80px;
  background-color: ${palette.PRIMARY10};
  border-radius: 8px;
`;

const RestartButton = styled(Flex)`
  width: 200px;
  height: 56px;
  font-size: 18px;
  color: ${palette.GRAY80};
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  cursor: pointer;
`;

export default ArtworkStep4;
