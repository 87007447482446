import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import RightBottomIconBar from 'components/common/RightBottomIconBar';
import IngredientCompareBox from 'components/material/ingredient/IngredientCompareBox';
import LoginModal from 'components/ui/Modal/LoginModal';
import path from 'lib/path';
import { initIngredientToBox } from 'modules/material/ingredient';
import { closeLoginModal } from 'modules/modal';
import AppRouter from 'routers/AppRouter';
import RootLayout from 'components/ui/Layout/RootLayout';
import FormulaScreeningPage from 'pages/brand/formulaScreening/FormulaScreeningPage';

const App = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const [initialized, setInitialized] = useState(false);

  const { isOpenLoginModal, auth } = useSelector(
    ({ modal, auth }: any) => ({
      isOpenLoginModal: modal.isOpenLoginModal,
      auth,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (process.env.REACT_APP_SERVER === 'DEV') {
        ReactGA.initialize('G-YS2YDNBCMT');
        setInitialized(true);
      } else if (process.env.REACT_APP_SERVER === 'REAL') {
        ReactGA.initialize('G-SRX29TG92Q');
        setInitialized(true);
      }
    }
    // HINT: local 환경 테스트시 port 5173 설정후 테스트 (export PORT=5173 && npm start)
    // else {
    //   ReactGA.initialize('G-3CC880NPRT');
    //   setInitialized(true);
    // }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({
        userId: auth?.user?.userId ? auth.user.userId : null,
      });
    }
  }, [initialized, auth]);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [initialized, location]);

  useEffect(() => {
    if (
      !pathname.startsWith(path.material.ingredient.dictionary) &&
      !pathname.startsWith(path.material.ingredient.detail)
    ) {
      dispatch(initIngredientToBox());
    }
  }, [pathname]);

  // 관리자 권한으로 성분 스크리닝 페이지 접속 시
  if (
    pathname === path.formulaScreening &&
    new URLSearchParams(search).get('source') === 'admin'
  ) {
    return (
      <RootLayout hasFooter={false} hasNavigator={false}>
        <FormulaScreeningPage isAdmin={true} />
      </RootLayout>
    );
  }

  return (
    <RootLayout
      extra={
        <>
          <RightBottomIconBar />
          <IngredientCompareBox />
          {isOpenLoginModal && (
            <LoginModal
              isOnlyBrand
              visible={isOpenLoginModal}
              onClose={() => dispatch(closeLoginModal())}
            />
          )}
        </>
      }
    >
      <AppRouter />
    </RootLayout>
  );
};

export default App;
