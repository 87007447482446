import { Button, Col, Row } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { DocumentCode, FDAAccountItem } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import {
  useFDAAccount,
  useProductFDAAccount,
} from 'service/brand/product/product';
import { useModal } from 'hook/useModal';

const ProductFDAAccountContainer = styled.div`
  .ant-table-content {
    th:first-child,
    td:first-child {
      padding-left: 36px;
    }
  }
`;

const FDAPasswordContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${palette.GRAY30};
  border-radius: 4px;
`;

const ProductFDAAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { fdaAccount, fdaAccountLoading } = useFDAAccount({});
  const {
    addProductFDAccount,
    addProductFDAccountLoading,
    updateMode,
    readOnlyMode,
  } = useProductFDAAccount({ productId, countryId });
  const { openFDAAccountAddModal } = useModal();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const columns: ColumnsType<FDAAccountItem> = useMemo(
    () => [
      {
        title: '사용자명 (ID)',
        width: 360,
        align: 'left',
        dataIndex: 'fdaAccount',
      },
      {
        title: '비밀번호',
        align: 'left',
        width:
          fdaAccount?.fdaPassword && fdaAccount.fdaPassword.length > 15
            ? 270
            : 150,
        render: (_, { fdaPassword }) => (
          <FDAPasswordContainer>
            {isPasswordVisible ? fdaPassword : '*'.repeat(fdaPassword.length)}
          </FDAPasswordContainer>
        ),
      },
      {
        align: 'left',
        render: () => (
          <>
            {isPasswordVisible ? (
              <IconWrapper
                onClick={() => setIsPasswordVisible(false)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="show" size={14} color="GRAY60" />
              </IconWrapper>
            ) : (
              <IconWrapper
                onClick={() => setIsPasswordVisible(true)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="hide" size={14} color="GRAY60" />
              </IconWrapper>
            )}
          </>
        ),
      },
    ],
    [fdaAccount, isPasswordVisible],
  );

  const handleSubmit = () => {
    addProductFDAccount({});
  };

  return (
    <ProductFDAAccountContainer>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.FDAAR}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: 8, marginBottom: 16 }}>
        <Col>
          <Button
            onClick={() => openFDAAccountAddModal({ fdaAccount })}
            disabled={readOnlyMode}
            style={{
              border: readOnlyMode
                ? `1px solid ${palette.GRAY40}`
                : `1px solid ${palette.PRIMARY50}`,
              background: 'white',
              color: readOnlyMode ? palette.GRAY50 : palette.GRAY90,
            }}
          >
            {fdaAccount === null ? '계정 정보 입력' : '계정 정보 변경'}
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="fdaAccountId"
        columns={columns}
        dataSource={fdaAccount ? [fdaAccount] : []}
        loading={fdaAccountLoading}
        pagination={false}
      />
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          {updateMode && (
            <CorrectRequestWithoutChangeButton
              documentCode={DocumentCode.FDAAR}
            />
          )}
          <Button
            type="primary"
            loading={addProductFDAccountLoading}
            onClick={handleSubmit}
          >
            {!updateMode ? '등록' : '보완 완료'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductFDAAccountContainer>
  );
};

export default ProductFDAAccount;
