import { useEffect } from 'react';
import { message } from 'antd';

import history from 'lib/history';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryCode } from 'service/country';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { messages } from 'lib/consts';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductBuyers from 'components/product/ProductBuyers';

const ProductBuyersPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const countryCode = useCountryCode(countryId || 0);
  const notices = [
    countryCode !== 'JP'
      ? '한 제품에 여러명의 바이어를 등록하실 수 있습니다.'
      : '',
    '기존 바이어 목록에 바이어 추가를 하여 신규 바이어를 등록하실 수 있으며 바이어 수정은 마이페이지 > 바이어 관리에서 가능합니다.',
    <MistakeNoticeItem />,
  ];
  const subtitle =
    countryCode === 'EU' || countryCode === 'GB' || countryCode === 'CN'
      ? '현지 바이어 정보'
      : '바이어/에이전시 정보';

  useEffect(() => {
    if (!countryId) {
      message.warning(messages.INVALID_APPROACH);
      history.goBack();
    }
  }, []);

  if (!countryId) return null;

  return (
    <ProductPageTemplate subtitle={subtitle} hasSkip={false} notices={notices}>
      <ProductBuyers
        productId={productId}
        countryId={countryId}
        subtitle={subtitle}
      />
    </ProductPageTemplate>
  );
};

export default ProductBuyersPage;
