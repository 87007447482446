import { Button, Form, message } from 'antd';
import { isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import RouteLeaveGuard from 'components/RouteLeaveGuard';
import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { CompanyDocsDataParmas } from 'service/brand/company';
import { useQCQAProduct, useQCQAProductDocs } from 'service/brand/qcqa/qcqa';
import { CompanyDocsAttach } from 'types/brand/company';
import { QCQACreateProductRecordItem } from 'types/brand/qcqa';
import QCQAProductBasic from './QCQAProductBasic';
import QCQAProductDocs from './QCQAProductUpdateDocs';
import { messages } from 'lib/consts';

const Container = styled.div``;

const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  width: 528px;
  background-color: ${palette.ETC_BG_BLUE};
`;

const Tab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 44px;
  background-color: ${({ active }) => (active ? '#fff' : palette.ETC_BG_BLUE)};
  color: ${({ active }) => (active ? palette.GRAY90 : palette.GRAY50)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
`;

export interface QCQAProductForm {
  brandName: string;
  productNameKo: string;
  productNameEn: string;
}

// 전성분표 코드
const FORMULA_BREAKDOWN_CODE = 'CODE_044';

const QCQAProductUpdate = ({
  initialTab,
  qcqaProductId,
  qcqaUserDocumentId,
}: {
  initialTab: 'basic' | 'document';
  qcqaProductId: number;
  qcqaUserDocumentId: number;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const isBlockingGoBack = useRef<boolean>(true);
  const [form] = Form.useForm<QCQAProductForm>();
  const [docsList, setDocsList] = useState<CompanyDocsDataParmas | undefined>();
  const [docsCheckList, setDocsCheckList] = useState<
    CompanyDocsDataParmas | undefined
  >();
  const [tab, setTab] = useState<'basic' | 'document'>(initialTab);

  const [isRegistable, setIsRegistable] = useState<boolean | undefined>();
  const [screeningFileData, setScreeningFileData] = useState<
    Record<string, File | undefined>
  >({});
  const [fileMapBeforeUploading, setFileMapBeforeUploading] = useState<
    Record<string, CompanyDocsAttach[]>
  >({});

  const { qcqaProductDocs } = useQCQAProductDocs({
    qcqaProductId,
  });
  const { openAlertModal } = useModal();

  const {
    qcProduct,
    updateQCProduct,
    isUpdateQCProductLoading,
  } = useQCQAProduct({
    qcqaProductId,
  });

  useEffect(() => {
    if (!qcProduct) return;
    form.setFieldsValue({
      brandName: qcProduct.brandName,
      productNameEn: qcProduct.productNameEn,
      productNameKo: qcProduct.productNameKo,
    });
  }, [qcProduct]);

  const handleChangeTab = useCallback(
    (tab: 'basic' | 'document') => {
      const { brandName } = form.getFieldsValue(true);
      if (!isRegistable && brandName !== qcProduct?.brandName) {
        return;
      }
      setTab(tab);
    },
    [isRegistable, qcProduct],
  );

  useEffect(() => {
    if (!qcqaProductDocs) return;
    docsListToData();
  }, [qcqaProductDocs]);

  useEffect(() => {
    if (!qcqaProductDocs) return;

    const newHistoryMap: Record<string, CompanyDocsAttach[]> = {};

    qcqaProductDocs.forEach((doc) => {
      doc.records?.forEach((history) => {
        if (!history?.qcqaDocumentRecordId) return;
        if (history.attaches) {
          newHistoryMap[history.qcqaDocumentRecordId] = history.attaches;
        } else {
          newHistoryMap[history.qcqaDocumentRecordId] = [];
        }
      });
    });

    setFileMapBeforeUploading(newHistoryMap);
  }, [qcqaProductDocs]);

  const docsListToData = useCallback(() => {
    if (!qcqaProductDocs) return;

    const newFiles = { ...docsList?.files };
    const newHistories = { ...docsList?.histories };
    const newColumns = { ...docsList?.columns };
    const newColumnOrder: string[] = [];

    qcqaProductDocs.forEach((doc) => {
      newColumns[doc.qcqaUserDocumentId] = {
        id: String(doc.qcqaUserDocumentId),
        relationType: doc.relationType,
        name: doc.name,
        description: doc.description,
        visible: doc.isDisplay,
        editable: doc.relationType === 'BASIC' ? false : true,
        isForCertification: doc.isForCertification,
        open: doc.records && doc.records.length > 0,
        attachIds: [],
        historyIds:
          doc.records && doc.records.length > 0
            ? doc.records?.map((record) => String(record.qcqaDocumentRecordId))
            : [],
        code: doc.code,
      };

      doc.records?.forEach((history) => {
        if (!history.qcqaDocumentRecordId) return;

        newHistories[history.qcqaDocumentRecordId] = {
          qcqaDocumentRecordId: history.qcqaDocumentRecordId,
          name: history.name,
          recordDate: history.recordDate,
          attachIds: history.attaches?.map((attach) => String(attach.attachId)),
          qcqaDocumentRecordAttachIds: history.attaches?.map((attach) =>
            String(attach.qcqaDocumentRecordAttachId),
          ),
        };

        history?.attaches?.forEach((attach) => {
          newFiles[attach.attachId] = {
            attachId: attach.attachId,
            filename: attach.filename,
            uploadFileUrl: attach.uploadFileUrl,
            registerDt: attach.registerDt,
            qcqaDocumentRecordAttachId: attach.qcqaDocumentRecordAttachId,
          };
        });
      });
      newColumnOrder.push(String(doc.qcqaUserDocumentId));
    });

    const newDocsList = {
      ...docsList,
      columns: newColumns,
      columnOrder: newColumnOrder,
      histories: newHistories,
      files: newFiles,
    };

    setDocsList(newDocsList);
    setDocsCheckList(structuredClone(newDocsList));
  }, [qcqaProductDocs]);

  const checkIsEqaul = useCallback(() => {
    const docsListCopy = structuredClone(docsList);
    const docsCheckListCopy = structuredClone(docsCheckList);

    if (docsListCopy && docsCheckListCopy) {
      for (const key of Object.keys(docsListCopy.columns)) {
        delete docsListCopy.columns[key].open;
      }

      for (const key of Object.keys(docsCheckListCopy.columns)) {
        delete docsCheckListCopy.columns[key].open;
      }

      return isEqual(docsListCopy, docsCheckListCopy);
    }

    return true;
  }, [docsList]);

  const deletedHistoryArr = useMemo(() => {
    if (!docsCheckList || !docsList) return;
    return Object.keys(docsCheckList.histories).filter(
      (name) => !Object.keys(docsList.histories).includes(name),
    );
  }, [docsList, docsCheckList]);
  const handleSubmit = (_: QCQAProductForm) => {
    const formValues = form.getFieldsValue(true);

    if (qcProduct) {
      if (!docsList || !docsCheckList) return;
      const newRecords: Record<string, QCQACreateProductRecordItem> = {};

      const changedValues = getChangedValues({
        obj1: docsCheckList,
        obj2: docsList,
      });

      const changedFiles = changedValues.files || {};

      Object.keys(changedFiles).forEach((attachId) => {
        Object.entries(docsCheckList.histories).forEach(
          ([id, { attachIds }]) => {
            if (
              attachIds?.includes(attachId) &&
              !(changedValues.histories || {})[id]
            ) {
              changedValues.histories = {
                ...changedValues.histories,
                [id]: {
                  attachIds: attachIds.filter((item) => item !== attachId),
                },
              };
            } else if (attachIds?.includes(attachId)) {
              changedValues.histories = {
                ...changedValues.histories,
                [id]: {
                  attachIds: changedValues.histories[id].attachIds?.filter(
                    (item: string) => item !== attachId,
                  ),
                },
              };
            }
          },
        );
      });

      const deletedRecordAttachIds: number[] = []; // HINT: 삭제시에는 deleteQcqaDocumentRecordAttachIds를 전달
      const deletedAttachIds: number[] = [];

      Object.keys(changedFiles).forEach((id) => {
        const attach = Object.values(docsCheckList.files).find(
          ({ attachId }) => attachId === Number(id),
        );
        if (!attach) return;
        deletedRecordAttachIds.push(attach.qcqaDocumentRecordAttachId);
        deletedAttachIds.push(attach.attachId);
      });

      const changedHistories = changedValues.histories || {};

      Object.entries(docsList.columnOrder).forEach(([_, order]) => {
        const column = docsList.columns[order];
        const { historyIds } = column;
        historyIds.forEach((id) => {
          const history = docsList.histories[id];
          if (!history) return;

          if (Object.keys(changedHistories).includes(id)) {
            const changedHistory = changedHistories[id];
            const { recordDate, name, attachIds } = changedHistory;
            const addedAttachIds: number[] = [];

            for (const id of attachIds) {
              if (
                !deletedAttachIds.includes(id) &&
                !Object.values(docsCheckList.files)
                  .flatMap(({ attachId }) => attachId)
                  .includes(Number(id)) &&
                !!id
              ) {
                addedAttachIds.push(id);
              }
            }

            newRecords[id] = {
              ...(!isNaN(Number(id)) && {
                qcqaDocumentRecordId: Number(id),
              }),
              qcqaUserDocumentId: Number(column.id),
              ...(recordDate && { recordDate: recordDate.slice(0, 10) }),
              ...(name && { name }),
              ...(addedAttachIds &&
                addedAttachIds.length > 0 && {
                  attachIds: addedAttachIds,
                }),
              ...(column.code === FORMULA_BREAKDOWN_CODE &&
                screeningFileData && {
                  formulaFile:
                    screeningFileData[
                      String(changedHistory.qcqaDocumentRecordId)
                    ],
                }),
              ...newRecords[id],
              ...(deletedRecordAttachIds.length > 0 && {
                deleteQcqaDocumentRecordAttachIds: deletedRecordAttachIds,
              }),
            };
          }
        });
      });

      deletedHistoryArr?.forEach((id) => {
        const history = docsCheckList.histories[id];
        if (!history) return;
        newRecords[id] = {
          qcqaDocumentRecordId: Number(history.qcqaDocumentRecordId),
          isDelete: true,
        };
      });

      const isSameBrandName = formValues.brandName === qcProduct.brandName;

      if (checkIsEqaul() && isSameBrandName) {
        message.warn(messages.NO_NEED_TO_UPDATE);
        return;
      }

      updateQCProduct(
        {
          ...(!isSameBrandName && {
            brandName: formValues.brandName,
          }),
          records: Object.values(newRecords),
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            setIsRegistable(false);
            history.push(`${path.qcqa.management.root}`);
          },
        },
      );
      return;
    }
    return;
  };

  const handleFormFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    const brandName = form.getFieldValue('brandName');

    if (allFields.every((field) => field.errors && field.errors.length === 0)) {
      if (!isEqual(brandName, qcProduct?.brandName)) {
        setIsRegistable(true);
      } else {
        setIsRegistable(false);
      }
    } else {
      setIsRegistable(false);
    }
  };

  useEffect(() => {
    setIsRegistable(!checkIsEqaul());
  }, [checkIsEqaul()]);

  useEffect(() => {
    if (!checkIsEqaul()) {
      const unblock = history.block(({ pathname }, action) => {
        if (
          isBlockingGoBack.current &&
          (action !== 'REPLACE' || pathname === path.logout)
        ) {
          openAlertModal({
            content:
              '변경 사항이 저장되지 않았습니다.\n페이지를 이동하시겠습니까?',
            okText: '이동하기',
            closeText: '취소',
            onOk: () => {
              isBlockingGoBack.current = false;
              if (action === 'POP') {
                history.goBack();
              } else {
                history.push(pathname);
              }
            },
          });

          return false;
        }
      });

      const preventLeave = (e: BeforeUnloadEvent) => {
        e.preventDefault();

        const a = document.activeElement as HTMLAnchorElement | null;

        if ((a?.href || '').endsWith('.xlsx')) {
          a?.blur();
          return;
        }

        e.returnValue = '';
        return false;
      };

      window.addEventListener('beforeunload', preventLeave);

      return () => {
        window.removeEventListener('beforeunload', preventLeave);
        unblock();
      };
    }
  }, [checkIsEqaul()]);

  return (
    <Container>
      <RouteLeaveGuard
        width={400}
        when={!checkIsEqaul() && isRegistable}
        description={
          '변경 사항이 저장되지 않았습니다.\n페이지를 이동하시겠습니까?'
        }
        continueText="이동하기"
        cancelText="취소"
      />
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onFinish={handleSubmit}
        onFieldsChange={handleFormFieldsChange}
      >
        <Flex justify="space-between" align="center">
          <TabContainer>
            <Tab
              active={tab === 'basic'}
              onClick={() => handleChangeTab('basic')}
            >
              기본 정보
            </Tab>
            <Tab
              active={tab === 'document'}
              onClick={() => handleChangeTab('document')}
            >
              서류 등록
            </Tab>
          </TabContainer>
          <Button
            loading={isUpdateQCProductLoading}
            type="primary"
            onClick={form.submit}
            style={{ width: 160, height: 44 }}
          >
            수정
          </Button>
        </Flex>
        {tab === 'basic' ? (
          <QCQAProductBasic
            form={form}
            qcProduct={qcProduct}
            isUpdateMode={true}
          />
        ) : (
          <QCQAProductDocs
            fileMapBeforeUploading={fileMapBeforeUploading}
            setFileMapBeforeUploading={setFileMapBeforeUploading}
            qcqaProductId={qcqaProductId}
            docsList={docsList}
            setDocsList={setDocsList}
            setScreeningFileData={setScreeningFileData}
            screeningFileData={screeningFileData}
            qcqaUserDocumentId={qcqaUserDocumentId}
            isMounted={isMounted}
            setIsMounted={setIsMounted}
          />
        )}
      </Form>
    </Container>
  );
};

export default QCQAProductUpdate;
