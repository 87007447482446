import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, message, Row, Spin } from 'antd';

import { messages } from 'lib/consts';
import { createBlobFile } from 'lib/file';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import UpdateLog from 'components/product/UpdateLog';
import { useManufacturerBizLicenseEnUrl } from 'service/company';
import { DocumentCode } from 'types/product';
import { useProductDocStatus } from 'service/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { updateCurrentPathname } from 'modules/product';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';

const ProductCOBRMContainer = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const documentCode = DocumentCode.COBRM;
  const params = useParams<{ productId: string; countryId: string }>();
  const { sendEventToGA } = useGA();
  const currentProduct = useCurrentProduct();
  const { productId } = currentProduct;
  const countryId = useCountryId();
  const dispatch = useDispatch();
  const {
    productDocStatus,
    addProdctDocStatus,
    addProdctDocStatusLoading,
    updateProdctDocStatus,
    updateProdctDocStatusLoading,
  } = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const [file, setFile] = useState<null | File | { name: string; url: string }>(
    null,
  );
  const {
    manufacturerBizLicenseEnUrl,
    getLoading: getManufacturerBizLicenseEnUrlLoading,
  } = useManufacturerBizLicenseEnUrl(!updateMode ? productId : undefined);
  const [
    convertManufacturerBizLicenseEnLoading,
    setConvertManufacturerBizLicenseEnLoading,
  ] = useState(false);
  const onDelete = () => {
    setFile(null);
  };

  useEffect(() => {
    if (productDocStatus?.filename && productDocStatus.uploadFileUrl) {
      setFile({
        name:
          productDocStatus.filename ||
          productDocStatus.uploadFileUrl
            .slice(productDocStatus.uploadFileUrl.lastIndexOf('/') + 1)
            .replace(/_\d{14}/, ''),
        url: productDocStatus.uploadFileUrl,
      });
    }
  }, [productDocStatus]);

  const onSubmit = () => {
    if (!file) return message.warning(messages.REQUIRED_FILE);
    if ('url' in file && file.url) {
      return message.warning('파일이 기존 파일과 같아 수정할 필요가 없습니다.');
    }
    if (!updateMode) {
      addProdctDocStatus(
        {
          documentCode,
          productId: currentProduct.productId,
          countryId,
          documentFile: file,
          ...(params.countryId && { countryId: Number(params.countryId) }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Certificate of Business Registration_Manufacturer',
              actionType: EGAActionType.REGISTER,
            });
            dispatch(updateCurrentPathname());
          },
        },
      );
    } else {
      updateProdctDocStatus(
        {
          documentCode,
          productId,
          countryId,
          documentFile: file,
          ...(params.countryId && { countryId: Number(params.countryId) }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Certificate of Business Registration_Manufacturer',
              actionType: EGAActionType.MODIFY,
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (manufacturerBizLicenseEnUrl !== null) {
        setConvertManufacturerBizLicenseEnLoading(true);
        const { data: blob } = await createBlobFile(
          manufacturerBizLicenseEnUrl,
        );
        const file = new File(
          [blob],
          decodeURIComponent(
            manufacturerBizLicenseEnUrl.slice(
              manufacturerBizLicenseEnUrl.lastIndexOf('/') + 1,
            ),
          ),
          {
            type: 'application/pdf',
          },
        );
        setFile(file);
        setConvertManufacturerBizLicenseEnLoading(false);
      }
    })();
  }, [manufacturerBizLicenseEnUrl]);
  return (
    <Spin
      spinning={
        getManufacturerBizLicenseEnUrlLoading ||
        convertManufacturerBizLicenseEnLoading
      }
    >
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={documentCode}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={documentCode}
            />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        updateMode={updateMode}
        readOnly={readOnlyMode}
        files={file}
        fetchLoading={addProdctDocStatusLoading || updateProdctDocStatusLoading}
        accept=".pdf"
        viewerVisible={true}
        footerVisible={true}
        status={productDocStatus?.status}
        onUpload={setFile}
        onDelete={onDelete}
        onSubmit={onSubmit}
        documentCode={documentCode}
        correctRequestWithNoUpdate={true}
      />
    </Spin>
  );
};

export default ProductCOBRMContainer;
