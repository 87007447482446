import React from 'react';
import { Badge } from 'antd';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { TypographyProps } from 'components/system/general/Typography';

export interface OrderListProps {
  rowGap?: number;
  children?: React.ReactNode | React.ReactNode[];
}

const getOffsetOfBadgeFromType = (type: TypographyProps['type']) => {
  return type === 'TITLE_1' ? 6.8 : type === 'TITLE_2' ? 3 : 2;
};

const OrderListItemContentContainer = styled.div<{
  gutter?:
    | number
    | { top?: number; bottom?: number; left?: number; right?: number };
  padding?:
    | number
    | { top?: number; bottom?: number; left?: number; right?: number };
}>`
  width: 100%;

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}
  ${({ padding }) => {
    if (typeof padding === 'number') {
      return css`
        padding: ${padding}px;
      `;
    }
    if (typeof padding === 'object') {
      return css`
        padding-top: ${padding.top ? `${padding.top}px` : 0};
        padding-bottom: ${padding.bottom ? `${padding.bottom}px` : 0};
        padding-left: ${padding.left ? `${padding.left}px` : 0};
        padding-right: ${padding.right ? `${padding.right}px` : 0};
      `;
    }
  }}
`;

const OrderList = ({ rowGap = 32, children }: OrderListProps) => {
  const childrenArray = React.Children.toArray(children);

  const orderListChildren = childrenArray.map((child, childIdx) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        orderNum: childIdx + 1,
      });
    }

    return null;
  });

  return (
    <Flex dir="column" rowGap={rowGap} style={{ width: '100%' }}>
      {orderListChildren}
    </Flex>
  );
};

export const OrderListItem = ({
  children,
  orderNum,
  horizontal,
  columnGap,
  rowGap,
}: {
  children?: React.ReactNode | React.ReactNode[];
  orderNum?: number;
  horizontal?: boolean;
  columnGap?: number;
  rowGap?: number;
}) => {
  const childrenArray = React.Children.toArray(children);

  const orderListItemChildren = childrenArray.map((child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        orderNum,
      });
    }

    return null;
  });

  return (
    <Flex
      dir={horizontal ? 'row' : 'column'}
      align={horizontal ? 'center' : undefined}
      columnGap={columnGap}
      rowGap={rowGap}
      style={{
        width: '100%',
      }}
    >
      {orderListItemChildren}
    </Flex>
  );
};

export const OrderListItemLabel = ({
  orderNum,
  children,
  type = 'TITLE_1',
  bold,
  medium,
}: {
  orderNum?: number;
  children?: string | React.ReactNode;
  type?: TypographyProps['type'];
  bold?: boolean;
  medium?: boolean;
}) => {
  return (
    <Flex
      dir="row"
      align="flex-start"
      columnGap={8}
      style={{ whiteSpace: 'pre-wrap' }}
    >
      <Badge
        count={orderNum}
        style={{
          backgroundColor: palette.PRIMARY50,
          transform: `translateY(${getOffsetOfBadgeFromType(type)}px)`,
        }}
      />
      <Typography.Text type={type} bold={bold} medium={medium}>
        {children}
      </Typography.Text>
    </Flex>
  );
};

export const OrderListItemContent = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
  gutter?:
    | number
    | { top?: number; bottom?: number; left?: number; right?: number };
  padding?:
    | number
    | { top?: number; bottom?: number; left?: number; right?: number };
}) => {
  return (
    <OrderListItemContentContainer {...props}>
      {children}
    </OrderListItemContentContainer>
  );
};

export default OrderList;
