import styled from 'styled-components';
import { Button, Col, Form, Input, message, Radio, Row } from 'antd';

import { Typography } from 'components/system';
import { messages } from 'lib/consts';
import { exceptKoreanRule } from 'lib/validate';

const ChecklistItemBlock = styled.div`
  & + & {
    margin-top: 24px;
  }

  .ant-radio-wrapper {
    line-height: 24px;
  }

  .ant-input {
    height: 36px;
    line-height: 36px;
  }

  .ant-btn {
    height: 36px;
    padding: 6px 10px;
    font-size: 12px;

    &.skip {
      border: none;
      background-color: #eff1f8;
      color: #9195a5;
      font-weight: 500;
    }
  }

  .ant-form-item-control-input {
    min-height: 0px;
  }
`;

const QuestionTitle = styled.h4`
  margin-bottom: 0;
`;

const QuestionMsg = styled(Typography.Text)`
  margin-top: 4px;
  padding-left: 4px;
  white-space: pre-wrap;
  font-size: 12px;
  padding-left: 8px;
`;

const ChecklistItem = ({
  form,
  countryChecklistId,
  questionMsg,
  questionSubMsg,
  answerType,
  yesNextId,
  noNextId,
  country,
  yesMsg,
  noMsg,
  yesType,
  noType,
  disabled,
  onChoice,
}: any) => {
  const getInputRules = () => {
    if (country.countryCode === 'JP') {
      return [
        exceptKoreanRule,
        {
          pattern: /^.{1,20}$/i,
          message: '최대 20자',
        },
      ];
    }

    return [];
  };
  return (
    <ChecklistItemBlock>
      <Row justify="space-between" wrap={false} gutter={16}>
        <Col flex="1 0 0">
          <QuestionTitle>{questionMsg}</QuestionTitle>
          {questionSubMsg && (
            <QuestionMsg type="BODY_2" color="GRAY70">
              {questionSubMsg}
            </QuestionMsg>
          )}
        </Col>
        <Col>
          {answerType === 'OBJ' ? (
            <Form.Item noStyle name={countryChecklistId}>
              <Radio.Group disabled={disabled}>
                <Radio
                  onClick={() =>
                    onChoice({
                      stop: yesType === 'STP',
                      nextId: yesNextId,
                      msg: yesMsg,
                      countryChecklistId,
                    })
                  }
                  value={true}
                >
                  네
                </Radio>
                <Radio
                  onClick={() =>
                    onChoice({
                      stop: noType === 'STP',
                      nextId: noNextId,
                      msg: noMsg,
                      countryChecklistId,
                    })
                  }
                  value={false}
                >
                  아니오
                </Radio>
              </Radio.Group>
            </Form.Item>
          ) : (
            <Row gutter={4}>
              <Col style={{ marginRight: 4 }}>
                <Form.Item name={countryChecklistId} rules={getInputRules()}>
                  <Input
                    style={{ width: 158, height: 36 }}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  disabled={disabled}
                  onClick={() => {
                    const inputValue = form.getFieldValue(countryChecklistId);
                    if (!inputValue) {
                      return message.warn('내용을 입력해 주세요.');
                    }
                    if (
                      typeof inputValue !== 'undefined' &&
                      /[가-힣ㄱ-ㅎㅏ-ㅣ]/i.test(inputValue)
                    ) {
                      return message.warn(messages.SHOULD_EXCEPT_KOREAN);
                    }
                    onChoice({
                      stop: false,
                      nextId: yesNextId,
                      msg: yesMsg,
                      countryChecklistId,
                    });
                  }}
                >
                  입력
                </Button>
              </Col>
              <Col>
                <Button
                  className={`skip ${disabled ? 'disabled' : ''}`}
                  disabled={disabled}
                  onClick={() => {
                    form.setFieldsValue({ [countryChecklistId]: '' });
                    onChoice({
                      stop: false,
                      nextId: yesNextId,
                      msg: yesMsg,
                      countryChecklistId,
                    });
                  }}
                >
                  Skip
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </ChecklistItemBlock>
  );
};

export default ChecklistItem;
