import { useState } from 'react';
import { useMutation } from 'react-query';

import client from 'lib/api/client';
import {
  AddEstimateInquiryParams,
  DocumentField,
  DocumentFieldDetail,
  DocumentSeq,
  EstimateServiceType,
  RegisterInquiryParams,
  UploadBrandFile,
  UploadFile,
} from 'types/common';
import { apiUrls, useBasicMutation, useBasicQuery } from './core';

export const useRegisterInquiry = ({ type }: { type: 'ad' | 'user' }) => {
  const {
    mutate: registerInquiry,
    isLoading,
  } = useMutation((params: RegisterInquiryParams) =>
    client.post(
      `/v1/pub/${
        type === 'ad' ? 'advertising-affiliation-inquiry' : 'user-inquiries'
      }`,
      params,
    ),
  );

  return {
    registerInquiry,
    isLoading,
  };
};

export const useEstimateServiceTypes = () => {
  const [emptyEstimateServiceTypes] = useState([]);

  const {
    data: estimateServiceTypes = emptyEstimateServiceTypes,
    isLoading,
  } = useBasicQuery<EstimateServiceType[]>({
    apiUrl: apiUrls.estimateServiceTypes,
    option: {
      staleTime: Number.MAX_VALUE,
    },
  });

  return {
    estimateServiceTypes,
    isLoading,
  };
};

export const useAddEstimateInquiry = () => {
  const {
    mutate: addEstimateInquiry,
    isLoading,
  } = useBasicMutation<AddEstimateInquiryParams>({
    apiUrl: apiUrls.addEstimateInquiry,
  });

  return {
    addEstimateInquiry,
    isLoading,
  };
};

export const useUploadBrandFiles = () => {
  // 브랜드사_파일_업로드_bac001
  const {
    mutate: uploadBrandFiles,
    mutateAsync: uploadBrandFilesAsync,
    isLoading: isUploadBrandFilesLoading,
  } = useBasicMutation<
    { attachFiles: File[]; attachType: 'MANUFACTURE_ATTACH' },
    UploadBrandFile[]
  >({
    apiUrl: apiUrls.uploadBrandFiles,
    useFormData: true,
  });

  return {
    uploadBrandFiles,
    uploadBrandFilesAsync,
    isUploadBrandFilesLoading,
  };
};

export const useUploadFiles = () => {
  const {
    mutate: uploadFiles,
    mutateAsync: uploadFilesAsync,
    isLoading: isUploadFilesLoading,
  } = useBasicMutation<
    { files: File[]; uploadDestinationType: 'QCQA' },
    UploadFile[]
  >({
    apiUrl: apiUrls.uploadFiles,
    useFormData: true,
  });

  const {
    mutate: deleteFiles,
    mutateAsync: deleteFilesAsync,
    isLoading: isDeleteFilesLoading,
  } = useBasicMutation<{ attachIds: number[] }>({
    method: 'delete',
    apiUrl: apiUrls.deleteFiles,
    customBodyData: (params) => params.attachIds,
  });

  return {
    uploadFiles,
    uploadFilesAsync,
    isUploadFilesLoading,
    deleteFiles,
    deleteFilesAsync,
    isDeleteFilesLoading,
  };
};

export const useServerDateTimeNow = () => {
  const {
    data: now,
    isLoading: isServerDateTimeNowLoading,
  } = useBasicQuery<string>({
    apiUrl: apiUrls.serverDateTimeNow,
  });

  return {
    now,
    isServerDateTimeNowLoading,
  };
};

export const useDocumentFieldMap = () => {
  // 서류 노출순서 조회 (로그인 필요) (DOC001)
  const { data: documentSeqs = [] } = useBasicQuery<DocumentSeq[]>({
    apiUrl: '/v1/document-seqs',
    option: {
      staleTime: Number.MAX_VALUE,
    },
  });

  const documentSeqMap = documentSeqs.reduce(
    (hash, { documentSeqId, documentCode }) => {
      hash.set(documentSeqId, documentCode.replace('01', '').toLowerCase());
      return hash;
    },
    new Map<number, string>(),
  );

  const { data: documentFields = [] } = useBasicQuery<DocumentFieldDetail[]>({
    apiUrl: '/v2/doc/fields',
    option: {
      staleTime: Number.MAX_VALUE,
    },
  });

  const documentFieldMap =
    documentSeqs.length > 0
      ? documentFields.reduce(
          (map, { documentFieldId, documentSeqId, code, name }) => {
            if (!documentSeqMap.has(documentSeqId))
              console.log(
                `Document sequence is not exist (ID:${documentSeqId})`,
              );
            const documentCode = documentSeqMap.get(documentSeqId);

            if (!documentCode) return map;

            if (!map[documentCode]) {
              map[documentCode] = [
                { documentFieldId, documentSeqId, name, code },
              ];
            } else {
              map[documentCode].push({
                documentFieldId,
                documentSeqId,
                name,
                code,
              });
            }
            return map;
          },
          {} as {
            [key: string]: DocumentField[];
          },
        )
      : {};

  return {
    documentFieldMap,
  };
};
