import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Button, Input, message } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { cloneDeep, isArray } from 'lodash';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import {
  AnalyzeResultListItem,
  CountryRules,
  RuleSubCode,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import TooltipChip from './TooltipChip';
import { useModal } from 'hook/useModal';
import { countryImages } from 'lib/consts';
import { useCountryCodeToNameMap, usePubCountries } from 'service/country';
import { useFormulaScreeningArtworkInquiry } from 'service/brand/artworkScreening/artworkScreening';
import {
  AllError,
  UpdateInfo,
  getIngerdientNameWithAdditional,
  getTextColorByRule,
} from '../ArtworkStep4';
import TooltipIcon from './TooltipIcon';

const initialInquiry = {
  [RuleType.TYPO]: {
    isOpen: false,
    content: '',
  },
  [RuleType.ORDER]: {
    isOpen: false,
    content: '',
  },
  [RuleType.MISS]: {
    isOpen: false,
    content: '',
  },
  [RuleType.NOT_MATCH]: {
    isOpen: false,
    content: '',
  },
};

const makeNewUpdateInfo = ({
  newUpdateInfo,
  updateInfo,
  ingredientIds = [],
  imageIds = [],
  newDuplicatedIds = [],
  isUpdate,
  originalOrder = [],
  newOrder = [],
}: {
  newUpdateInfo: UpdateInfo;
  updateInfo?: UpdateInfo;
  ingredientIds?: number[];
  imageIds?: number[];
  newDuplicatedIds?: number[];
  isUpdate: boolean;
  originalOrder?: number[];
  newOrder?: number[];
}) => {
  Object.entries(newUpdateInfo).forEach(
    ([formulaScreeningAnalyzeItemId, value]) => {
      const formulaScreeningArtworkImageIngredientId =
        newUpdateInfo[formulaScreeningAnalyzeItemId]
          .formulaScreeningArtworkImageIngredientId;
      const formulaScreeningArtworkIngredientId =
        newUpdateInfo[formulaScreeningAnalyzeItemId]
          .formulaScreeningArtworkIngredientId;

      if (
        formulaScreeningArtworkImageIngredientId &&
        (imageIds.includes(formulaScreeningArtworkImageIngredientId) ||
          newDuplicatedIds.includes(formulaScreeningArtworkImageIngredientId))
      ) {
        const newAllErrors: AllError[] = value.allErrors.map((error) => ({
          ...error,
          ...(error.ruleType === RuleType.ORDER &&
            imageIds.includes(formulaScreeningArtworkImageIngredientId) && {
              isUpdate,
            }),
          ...(error.ruleType === RuleType.NOT_MATCH &&
            imageIds.includes(formulaScreeningArtworkImageIngredientId) && {
              isUpdate,
            }),
          ...(error.ruleType === RuleType.TYPO &&
            newDuplicatedIds.includes(
              formulaScreeningArtworkImageIngredientId,
            ) && {
              isUpdate,
            }),
        }));
        const newColor = newAllErrors.every((item) => item.isUpdate)
          ? 'PRIMARY50'
          : getTextColorByRule(
              newAllErrors.find((item) => !item.isUpdate)?.ruleType,
            );

        const targetImageId =
          originalOrder[
            newOrder.findIndex(
              (item) =>
                item ===
                originalOrder.find(
                  (item) => item === formulaScreeningArtworkImageIngredientId,
                ),
            )
          ];
        const targetOrder = Object.values(updateInfo || {}).find(
          (item) =>
            item.formulaScreeningArtworkImageIngredientId === targetImageId,
        )?.order;

        newUpdateInfo[formulaScreeningAnalyzeItemId] = {
          ...value,
          allErrors: newAllErrors,
          color: newColor,
          ...(imageIds.includes(formulaScreeningArtworkImageIngredientId) &&
            newOrder.length > 0 &&
            targetOrder && {
              order: isUpdate ? targetOrder : value.initialOrder,
            }),
        };
      } else if (
        formulaScreeningArtworkIngredientId &&
        ingredientIds.includes(formulaScreeningArtworkIngredientId)
      ) {
        const newAllErrors: AllError[] = value.allErrors.map((error) => ({
          ...error,
          ...(error.ruleType === RuleType.MISS &&
            ingredientIds.includes(formulaScreeningArtworkIngredientId) && {
              isUpdate,
            }),
        }));

        const newColor = newAllErrors.every((item) => item.isUpdate)
          ? 'PRIMARY50'
          : getTextColorByRule(
              newAllErrors.find((item) => !item.isUpdate)?.ruleType,
            );

        newUpdateInfo[formulaScreeningAnalyzeItemId] = {
          ...value,
          allErrors: newAllErrors,
          color: newColor,
        };
      }
    },
  );
};

const CollapseIngredient = ({
  idx,
  formulaScreeningId,
  openCollapseId,
  setOpenCollapseId,
  analyzeResultListItem,
  selectedCountryCodes,
  availableCountryCodes,
  textRect,
  setTextRect,
  updateInfo,
  setUpdateInfo,
  order,
  setOrder,
  duplicatedIds,
  setDuplicatedIds,
  setClickedChip,
  targetRect,
  setTargetRect,
  setIsAutoSave,
}: {
  idx: number;
  formulaScreeningId: number;
  openCollapseId?: number;
  setOpenCollapseId: Dispatch<SetStateAction<number | undefined>>;
  analyzeResultListItem: AnalyzeResultListItem & {
    allErrors: AllError[];
  };
  availableCountryCodes: string[];
  selectedCountryCodes: string[];
  textRect?: DOMRect;
  setTextRect: Dispatch<SetStateAction<DOMRect | undefined>>;
  updateInfo: UpdateInfo;
  setUpdateInfo: Dispatch<SetStateAction<UpdateInfo>>;
  order: number[];
  setOrder: Dispatch<SetStateAction<number[]>>;
  duplicatedIds: number[];
  setDuplicatedIds: Dispatch<SetStateAction<number[]>>;
  setClickedChip: Dispatch<
    SetStateAction<{
      type?:
        | 'formulaScreeningAnalyzeItemId'
        | 'formulaScreeningArtworkImageIngredientId';
      id?: number;
    }>
  >;
  targetRect?: DOMRect;
  setTargetRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setIsAutoSave: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    ingredient,
    imageIngredient,
    analysisItems,
    formulaScreeningAnalyzeItemId,
    allErrors,
  } = analyzeResultListItem;
  const ingredientName = getIngerdientNameWithAdditional({
    ingredientName:
      imageIngredient?.ingredientName || ingredient?.ingredientName || '',
    additionalIngredientName: imageIngredient?.additionalIngredientName,
    additionalIngredientNameSeparator:
      imageIngredient?.additionalIngredientNameSeparator,
  });

  const isOpen = openCollapseId === formulaScreeningAnalyzeItemId;
  const [isHover, setIsHover] = useState(false);
  const [inquiry, setInquiry] = useState<
    Record<RuleType, { isOpen: boolean; content: string }>
  >(initialInquiry);
  const [directName, setDirectName] = useState('');

  const textareaRef = useRef<Map<RuleType, TextAreaRef>>(new Map());
  const { openConfirmModal } = useModal();
  const {
    registerFormulaScreeningArtworkInquiry,
  } = useFormulaScreeningArtworkInquiry();

  useEffect(() => {
    setDirectName('');
    setInquiry(initialInquiry);
  }, [selectedCountryCodes]);

  if (!analysisItems || allErrors.length === 0) {
    return (
      <Container>
        <Header
          gap={8}
          idx={idx}
          onClick={() => {
            setClickedChip(
              isOpen
                ? {}
                : {
                    type: imageIngredient
                      ? 'formulaScreeningArtworkImageIngredientId'
                      : 'formulaScreeningAnalyzeItemId',
                    id: imageIngredient
                      ? imageIngredient.formulaScreeningArtworkImageIngredientId
                      : formulaScreeningAnalyzeItemId,
                  },
            );
          }}
        >
          <Typography.Text color="SLATE_GRAY70">
            {ingredientName}
          </Typography.Text>
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      <Header
        gap={8}
        justify={isHover ? 'space-between' : 'flex-start'}
        idx={idx}
        isHover={isHover}
        onClick={() => {
          setOpenCollapseId(isOpen ? undefined : formulaScreeningAnalyzeItemId);
          setClickedChip(
            isOpen
              ? {}
              : {
                  type: imageIngredient
                    ? 'formulaScreeningArtworkImageIngredientId'
                    : 'formulaScreeningAnalyzeItemId',
                  id: imageIngredient
                    ? imageIngredient.formulaScreeningArtworkImageIngredientId
                    : formulaScreeningAnalyzeItemId,
                },
          );
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Typography.Text color={getTextColorByRule(allErrors[0]?.ruleType)}>
          {ingredientName}
        </Typography.Text>
        <Icon
          name={isOpen ? 'up' : 'down'}
          color="SLATE_GRAY70"
          size={18}
          style={{
            flex: '0 0 18px',
          }}
        />
        {isHover && <HoveredBorder />}
      </Header>
      {isOpen && (
        <Flex
          dir="column"
          gap={8}
          style={{ alignItems: 'stretch' }}
          gutter={{ top: 12 }}
        >
          {allErrors.map(
            ({ ruleType, errors, analysisItems, isUpdate }, errorIdx) => {
              const color = getTextColorByRule(ruleType);
              const { preIngredient, pre, post } = analysisItems[0];

              const allDescriptions: string[] = errors.map(
                (item) => item.postIngredient.description,
              );

              const countryRules = errors.find(
                (error) => Object.keys(error.countryRules).length !== 0,
              )?.countryRules;

              const sortedSelectedCountryCodes = selectedCountryCodes.sort(
                (a: string, b: string) => {
                  const indexA = availableCountryCodes?.indexOf(a);
                  const indexB = availableCountryCodes?.indexOf(b);

                  return indexA - indexB;
                },
              );

              const countryModalRules = sortedSelectedCountryCodes.reduce(
                (acc, countryCode) => {
                  if (countryRules?.[countryCode]) {
                    acc[countryCode] = countryRules[countryCode];
                  }
                  return acc;
                },
                {} as CountryRules,
              );
              const postIngredient =
                (errors.length > 0 &&
                  errors[errors.length - 1].postIngredient) ||
                null;
              const updateIngredient = postIngredient?.updateIngredient || [];

              const getPreIngredientName = (color: keyof typeof palette) => {
                const preIngredientName = isArray(preIngredient)
                  ? preIngredient
                      .map((item) =>
                        getIngerdientNameWithAdditional({
                          ingredientName: item.ingredientName,
                          additionalIngredientName:
                            item.additionalIngredientName,
                          additionalIngredientNameSeparator:
                            item.additionalIngredientNameSeparator,
                        }),
                      )
                      .join(', ')
                  : getIngerdientNameWithAdditional({
                      ingredientName: preIngredient?.ingredientName,
                      additionalIngredientName:
                        preIngredient?.additionalIngredientName,
                      additionalIngredientNameSeparator:
                        preIngredient?.additionalIngredientNameSeparator,
                    }) || ingredientName;

                return (
                  <>
                    {pre && (
                      <Typography.Text color="SLATE_GRAY70" inline>
                        {getIngerdientNameWithAdditional({
                          ingredientName: pre.ingredientName,
                          additionalIngredientName:
                            pre.additionalIngredientName,
                          additionalIngredientNameSeparator:
                            pre.additionalIngredientNameSeparator,
                        })}
                        ,{' '}
                      </Typography.Text>
                    )}
                    <Typography.Text color={color} inline>
                      {preIngredientName}
                      {post && ', '}
                    </Typography.Text>
                    {post && (
                      <Typography.Text color="SLATE_GRAY70" inline>
                        {getIngerdientNameWithAdditional({
                          ingredientName: post.ingredientName,
                          additionalIngredientName:
                            post.additionalIngredientName,
                          additionalIngredientNameSeparator:
                            post.additionalIngredientNameSeparator,
                        })}
                      </Typography.Text>
                    )}
                  </>
                );
              };

              const hasNoChip =
                !(
                  postIngredient &&
                  'pre' in postIngredient &&
                  postIngredient.pre
                ) &&
                updateIngredient.length === 0 &&
                !(
                  postIngredient &&
                  'post' in postIngredient &&
                  postIngredient.post
                );

              return (
                <Panel key={ruleType}>
                  <Typography.Text color={color}>
                    {getPreIngredientName(color)}
                  </Typography.Text>
                  <Flex
                    gutter={{ top: 8 }}
                    gap={8}
                    style={{ alignItems: 'flex-start' }}
                  >
                    <Icon
                      name="front"
                      size={18}
                      color="SLATE_GRAY70"
                      style={{ marginTop: hasNoChip ? 2 : 7 }}
                    />
                    <div>
                      <Flex gap={4} wrap="true" style={{ flex: 1 }}>
                        <>
                          {postIngredient &&
                            'pre' in postIngredient &&
                            postIngredient.pre && (
                              <TooltipChip
                                ingredientName={getIngerdientNameWithAdditional(
                                  {
                                    ingredientName:
                                      postIngredient.pre.ingredientName,
                                    additionalIngredientName:
                                      postIngredient.pre
                                        .additionalIngredientName,
                                    additionalIngredientNameSeparator:
                                      postIngredient.pre
                                        .additionalIngredientNameSeparator,
                                  },
                                )}
                                color={palette.SLATE_GRAY50}
                                textRect={textRect}
                                setTextRect={setTextRect}
                              />
                            )}
                          {updateIngredient.map(
                            (
                              {
                                ingredientName,
                                additionalIngredientName,
                                additionalIngredientNameSeparator,
                              },
                              idx,
                            ) => (
                              <TooltipChip
                                key={idx}
                                ingredientName={getIngerdientNameWithAdditional(
                                  {
                                    ingredientName,
                                    additionalIngredientName,
                                    additionalIngredientNameSeparator,
                                  },
                                )}
                                color={palette[color]}
                                textRect={textRect}
                                setTextRect={setTextRect}
                              />
                            ),
                          )}
                          {postIngredient &&
                            'post' in postIngredient &&
                            postIngredient.post && (
                              <TooltipChip
                                ingredientName={getIngerdientNameWithAdditional(
                                  {
                                    ingredientName:
                                      postIngredient.post.ingredientName,
                                    additionalIngredientName:
                                      postIngredient.post
                                        .additionalIngredientName,
                                    additionalIngredientNameSeparator:
                                      postIngredient.post
                                        .additionalIngredientNameSeparator,
                                  },
                                )}
                                color={palette.SLATE_GRAY50}
                                textRect={textRect}
                                setTextRect={setTextRect}
                              />
                            )}
                        </>
                      </Flex>
                      <Flex
                        dir="column"
                        gutter={{ top: hasNoChip ? 0 : 12 }}
                        gap={4}
                        style={{ alignItems: 'stretch' }}
                      >
                        {allDescriptions
                          .flatMap((description) => description.split('\n'))
                          .map((description, idx) => (
                            <Flex gap={8} key={idx} style={{ maxWidth: 522 }}>
                              <Bullet />
                              <Typography.Text
                                color="SLATE_GRAY70"
                                medium
                                style={{ whiteSpace: 'pre-wrap' }}
                              >
                                {description}
                              </Typography.Text>
                            </Flex>
                          ))}
                      </Flex>
                    </div>
                  </Flex>
                  <Flex
                    gutter={{ top: 12, left: 26 }}
                    justify="space-between"
                    style={{ padding: '2px 0', maxWidth: 522 }}
                  >
                    <Flex gap={8}>
                      {Object.keys(countryModalRules).length > 0 && (
                        <Typography.Text
                          color="PRIMARY50"
                          type="BODY_2"
                          hover
                          onClick={() => {
                            openConfirmModal({
                              title: '국가별 규칙 보기',
                              content: (
                                <CountryModalContent
                                  countryModalRules={countryModalRules}
                                />
                              ),
                              footer: null,
                            });
                          }}
                        >
                          국가별 규칙 보기
                        </Typography.Text>
                      )}
                      <TooltipIcon
                        name="textsms"
                        targetRect={targetRect}
                        setTargetRect={setTargetRect}
                        size={32}
                        icon={
                          <InquiryIconWrapper>
                            <Icon
                              name="textsms"
                              size={18}
                              color="SLATE_GRAY60"
                              hoverColor="SLATE_GRAY70"
                              style={{ width: 30, height: 30 }}
                            />
                          </InquiryIconWrapper>
                        }
                        onClick={() =>
                          setInquiry({
                            ...inquiry,
                            [ruleType]: {
                              ...inquiry[ruleType],
                              isOpen: !inquiry[ruleType].isOpen,
                            },
                          })
                        }
                      />
                    </Flex>
                    <Flex gap={4}>
                      {isUpdate &&
                      !(
                        ruleType === RuleType.TYPO &&
                        updateInfo[formulaScreeningAnalyzeItemId].directName
                      ) ? (
                        <CancelButton
                          onClick={() => {
                            const newUpdateInfo = cloneDeep(updateInfo);
                            const imageIds =
                              ruleType === RuleType.NOT_MATCH &&
                              isArray(preIngredient)
                                ? preIngredient.map(
                                    (item) =>
                                      item.formulaScreeningArtworkImageIngredientId,
                                  )
                                : updateIngredient.map(
                                    (item) =>
                                      item.formulaScreeningArtworkImageIngredientId!,
                                  );
                            const ingredientIds = updateIngredient.map(
                              (item) =>
                                item.formulaScreeningArtworkIngredientId!,
                            );

                            const newAllErrors: AllError[] = allErrors.map(
                              (error, idx) => ({
                                ...error,
                                ...(idx === errorIdx && { isUpdate: false }),
                              }),
                            );
                            const newColor = newAllErrors.every(
                              (item) => item.isUpdate,
                            )
                              ? 'PRIMARY50'
                              : getTextColorByRule(
                                  newAllErrors.find((item) => !item.isUpdate)
                                    ?.ruleType,
                                );

                            if (
                              ruleType === RuleType.MISS ||
                              ruleType === RuleType.NOT_MATCH
                            ) {
                              makeNewUpdateInfo({
                                newUpdateInfo,
                                ...(ruleType === RuleType.MISS
                                  ? { ingredientIds }
                                  : { imageIds }),
                                isUpdate: false,
                              });
                              setUpdateInfo({
                                ...newUpdateInfo,
                                [formulaScreeningAnalyzeItemId]: {
                                  ...updateInfo[formulaScreeningAnalyzeItemId],
                                  allErrors: newAllErrors,
                                  color: newColor,
                                },
                              });
                              setIsAutoSave(true);
                            } else if (ruleType === RuleType.TYPO) {
                              const hasDuplication = errors.some((error) =>
                                error.postIngredient.ruleSubCode.some(
                                  (code) => code === RuleSubCode.FCAR01_01,
                                ),
                              );
                              let newDuplicatedIds: number[] = [];

                              if (hasDuplication && isArray(preIngredient)) {
                                newDuplicatedIds = preIngredient
                                  .map(
                                    (item) =>
                                      item.formulaScreeningArtworkImageIngredientId,
                                  )
                                  .filter(
                                    (item) =>
                                      item !==
                                      updateIngredient[0]
                                        ?.formulaScreeningArtworkImageIngredientId,
                                  );
                              }

                              makeNewUpdateInfo({
                                newUpdateInfo,
                                imageIds,
                                isUpdate: false,
                                newDuplicatedIds,
                              });
                              setUpdateInfo({
                                ...newUpdateInfo,
                                [formulaScreeningAnalyzeItemId]: {
                                  ...updateInfo[formulaScreeningAnalyzeItemId],
                                  allErrors: newAllErrors,
                                  color: newColor,
                                  ingredientName:
                                    updateInfo[formulaScreeningAnalyzeItemId]
                                      .initialIngredientName,
                                  additionalIngredientName: undefined,
                                  additionalIngredientNameSeparator: undefined,
                                },
                              });
                              setIsAutoSave(true);
                              setDuplicatedIds(
                                duplicatedIds.filter(
                                  (item) => !newDuplicatedIds.includes(item),
                                ),
                              );
                            } else if (ruleType === RuleType.ORDER) {
                              makeNewUpdateInfo({
                                newUpdateInfo,
                                imageIds,
                                isUpdate: false,
                                updateInfo,
                                originalOrder: isArray(preIngredient)
                                  ? preIngredient.map(
                                      (item) =>
                                        item.formulaScreeningArtworkImageIngredientId,
                                    )
                                  : [],
                                newOrder: imageIds,
                              });
                              setUpdateInfo(newUpdateInfo);
                              setIsAutoSave(true);
                              setOrder(
                                order.filter(
                                  (item) => !imageIds.includes(item),
                                ),
                              );
                            }
                            message.success('취소되었습니다.');
                          }}
                        >
                          취소
                        </CancelButton>
                      ) : (
                        <ApplyButton
                          onClick={() => {
                            const newUpdateInfo = cloneDeep(updateInfo);
                            const imageIds =
                              ruleType === RuleType.NOT_MATCH &&
                              isArray(preIngredient)
                                ? preIngredient.map(
                                    (item) =>
                                      item.formulaScreeningArtworkImageIngredientId,
                                  )
                                : updateIngredient.map(
                                    (item) =>
                                      item.formulaScreeningArtworkImageIngredientId!,
                                  );
                            const ingredientIds = updateIngredient.map(
                              (item) =>
                                item.formulaScreeningArtworkIngredientId!,
                            );
                            const newAllErrors: AllError[] = allErrors.map(
                              (error, idx) => ({
                                ...error,
                                ...(idx === errorIdx && { isUpdate: true }),
                              }),
                            );
                            const newColor = newAllErrors.every(
                              (item) => item.isUpdate,
                            )
                              ? 'PRIMARY50'
                              : getTextColorByRule(
                                  newAllErrors.find((item) => !item.isUpdate)
                                    ?.ruleType,
                                );

                            if (
                              ruleType === RuleType.MISS ||
                              ruleType === RuleType.NOT_MATCH
                            ) {
                              makeNewUpdateInfo({
                                newUpdateInfo,
                                ...(ruleType === RuleType.MISS
                                  ? { ingredientIds }
                                  : { imageIds }),
                                isUpdate: true,
                              });
                              setUpdateInfo({
                                ...newUpdateInfo,
                                [formulaScreeningAnalyzeItemId]: {
                                  ...updateInfo[formulaScreeningAnalyzeItemId],
                                  allErrors: newAllErrors,
                                  color: newColor,
                                },
                              });
                              setIsAutoSave(true);
                            } else if (ruleType === RuleType.TYPO) {
                              const hasDuplication = errors.some((error) =>
                                error.postIngredient.ruleSubCode.some(
                                  (code) => code === RuleSubCode.FCAR01_01,
                                ),
                              );
                              let newDuplicatedIds: number[] = [];

                              if (hasDuplication && isArray(preIngredient)) {
                                newDuplicatedIds = preIngredient
                                  .map(
                                    (item) =>
                                      item.formulaScreeningArtworkImageIngredientId,
                                  )
                                  .filter(
                                    (item) =>
                                      item !==
                                      updateIngredient[0]
                                        ?.formulaScreeningArtworkImageIngredientId,
                                  );
                              }

                              makeNewUpdateInfo({
                                newUpdateInfo,
                                imageIds,
                                newDuplicatedIds,
                                isUpdate: true,
                              });
                              setUpdateInfo({
                                ...newUpdateInfo,
                                [formulaScreeningAnalyzeItemId]: {
                                  ...updateInfo[formulaScreeningAnalyzeItemId],
                                  allErrors: newAllErrors,
                                  directName: '',
                                  color: newColor,
                                  ingredientName:
                                    updateIngredient[0]?.ingredientName,
                                  additionalIngredientName:
                                    updateIngredient[0]
                                      ?.additionalIngredientName,
                                  additionalIngredientNameSeparator:
                                    updateIngredient[0]
                                      ?.additionalIngredientNameSeparator,
                                },
                              });
                              setIsAutoSave(true);
                              setDirectName('');
                              setDuplicatedIds([
                                ...duplicatedIds,
                                ...newDuplicatedIds,
                              ]);
                            } else if (ruleType === RuleType.ORDER) {
                              makeNewUpdateInfo({
                                updateInfo,
                                newUpdateInfo,
                                imageIds,
                                isUpdate: true,
                                originalOrder: isArray(preIngredient)
                                  ? preIngredient.map(
                                      (item) =>
                                        item.formulaScreeningArtworkImageIngredientId,
                                    )
                                  : [],
                                newOrder: imageIds,
                              });
                              setUpdateInfo(newUpdateInfo);
                              setIsAutoSave(true);
                              setOrder([...order, ...imageIds]);
                            }
                            message.success('적용되었습니다.');
                          }}
                        >
                          적용
                        </ApplyButton>
                      )}
                    </Flex>
                  </Flex>
                  {(inquiry[ruleType].isOpen ||
                    (ruleType === RuleType.TYPO &&
                      !postIngredient?.ruleSubCode.includes(
                        RuleSubCode.FCAR01_01,
                      ))) && <HorizontalDivider />}
                  {inquiry[ruleType].isOpen ? (
                    <Flex style={{ alignItems: 'flex-start' }} gap={8}>
                      <Icon
                        name="front"
                        size={18}
                        color="SLATE_GRAY70"
                        style={{ marginTop: 13 }}
                      />
                      <TextAreaWrapper
                        onClick={() => {
                          const targetRef = textareaRef.current.get(ruleType);
                          if (targetRef) targetRef.focus();
                        }}
                      >
                        <TextArea
                          autoSize
                          ref={(el) => {
                            if (el) {
                              textareaRef.current.set(ruleType, el);
                            } else {
                              textareaRef.current.delete(ruleType);
                            }
                          }}
                          placeholder="검토 결과에 대한 의견 입력"
                          value={inquiry[ruleType].content}
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              [ruleType]: {
                                isOpen: true,
                                content: e.target.value,
                              },
                            })
                          }
                        />
                        <Flex justify="flex-end" gap={4} gutter={{ top: 8 }}>
                          <Typography.Text
                            type="BODY_2"
                            color="GRAY70"
                            style={{ padding: '2px 4px' }}
                            onClick={() =>
                              setInquiry({
                                ...inquiry,
                                [ruleType]: {
                                  ...inquiry[ruleType],
                                  isOpen: false,
                                },
                              })
                            }
                          >
                            닫기
                          </Typography.Text>
                          <Typography.Text
                            type="BODY_2"
                            color="PRIMARY50"
                            style={{ padding: '2px 4px' }}
                            onClick={() => {
                              if (!inquiry[ruleType].content) {
                                message.warn('입력한 내용이 없습니다.');
                                return;
                              }

                              if (inquiry[ruleType].content.length > 1000) {
                                message.warn(
                                  '최대 1,000자까지 입력 가능합니다.',
                                );
                                return;
                              }
                              registerFormulaScreeningArtworkInquiry(
                                {
                                  formulaScreeningId,
                                  content: inquiry[ruleType].content,
                                  formulaScreeningAnalyzeItemId,
                                },
                                {
                                  onSuccess: () => {
                                    message.success(
                                      '제출되었습니다. 담당자가 검토 후 메일로 전달드립니다.',
                                    );
                                    setInquiry({
                                      ...inquiry,
                                      [ruleType]: {
                                        isOpen: false,
                                        content: '',
                                      },
                                    });
                                  },
                                },
                              );
                            }}
                          >
                            제출
                          </Typography.Text>
                        </Flex>
                      </TextAreaWrapper>
                    </Flex>
                  ) : (
                    ruleType === RuleType.TYPO &&
                    !postIngredient?.ruleSubCode.includes(
                      RuleSubCode.FCAR01_01,
                    ) && (
                      <Flex gap={8} style={{ alignItems: 'flex-start' }}>
                        <Icon
                          name="front"
                          size={18}
                          color="SLATE_GRAY70"
                          style={{ marginTop: 13 }}
                        />
                        <TextAreaWrapper
                          onClick={() => {
                            const targetRef = textareaRef.current.get(ruleType);
                            if (targetRef) targetRef.focus();
                          }}
                        >
                          <TextArea
                            autoSize
                            ref={(el) => {
                              if (el) {
                                textareaRef.current.set(ruleType, el);
                              } else {
                                textareaRef.current.delete(ruleType);
                              }
                            }}
                            placeholder="성분명 직접 입력"
                            value={directName}
                            onChange={(e) => {
                              setDirectName(e.target.value);
                            }}
                          />
                          <Flex justify="flex-end" gutter={{ top: 8 }}>
                            <Typography.Text
                              color={
                                updateInfo[formulaScreeningAnalyzeItemId]
                                  .directName
                                  ? 'GRAY70'
                                  : 'PRIMARY50'
                              }
                              type="BODY_2"
                              style={{ padding: '2px 4px' }}
                              onClick={() => {
                                if (!directName) {
                                  message.warn('입력한 내용이 없습니다.');
                                  return;
                                }
                                if (directName.length > 2000) {
                                  message.warn(
                                    '최대 2,000자까지 입력 가능합니다.',
                                  );
                                  return;
                                }

                                const hasDirectName =
                                  updateInfo[formulaScreeningAnalyzeItemId]
                                    .directName;
                                const newAllErrors = allErrors.map(
                                  (error, idx) => ({
                                    ...error,
                                    ...(idx === errorIdx && {
                                      isUpdate: hasDirectName ? false : true,
                                    }),
                                  }),
                                );
                                const newColor = newAllErrors.every(
                                  (item) => item.isUpdate,
                                )
                                  ? 'PRIMARY50'
                                  : getTextColorByRule(
                                      newAllErrors.find(
                                        (item) => !item.isUpdate,
                                      )?.ruleType,
                                    );

                                if (hasDirectName) {
                                  setDirectName('');
                                }

                                setUpdateInfo({
                                  ...updateInfo,
                                  [formulaScreeningAnalyzeItemId]: {
                                    ...updateInfo[
                                      formulaScreeningAnalyzeItemId
                                    ],
                                    allErrors: newAllErrors,
                                    color: newColor,
                                    ...(hasDirectName && {
                                      additionalIngredientName: '',
                                      additionalIngredientNameSeparator: '',
                                    }),
                                    directName: hasDirectName ? '' : directName,
                                  },
                                });
                                setIsAutoSave(true);
                                message.success(
                                  hasDirectName
                                    ? '취소되었습니다.'
                                    : '적용되었습니다.',
                                );
                              }}
                            >
                              {updateInfo[formulaScreeningAnalyzeItemId]
                                .directName
                                ? '취소'
                                : '적용'}
                            </Typography.Text>
                          </Flex>
                        </TextAreaWrapper>
                      </Flex>
                    )
                  )}
                </Panel>
              );
            },
          )}
        </Flex>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Header = styled(Flex)<{ idx: number; isHover?: boolean }>`
  margin-top: ${({ idx }) => (idx === 0 ? 0 : 16)}px;
  cursor: pointer;
  position: relative;
  width: ${({ isHover }) => (isHover ? `100%` : `580px`)};
`;

const Panel = styled.div`
  border-radius: 4px;
  padding: 16px;
  background: ${palette.SLATE_GRAY10};
`;

const HoveredBorder = styled.div`
  position: absolute;
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  left: -16px;
  top: -12px;
  bottom: -12px;
  right: -16px;
`;

const HorizontalDivider = styled.div`
  height: 1px;
  background-color: ${palette.GRAY30};
  margin: 16px 0;
`;

const Bullet = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${palette.SLATE_GRAY50};
  align-self: flex-start;
  margin-top: 9px;
`;

const InquiryIconWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${palette.GRAY30};
  background: #fff;
  cursor: pointer;
`;

const CancelButton = styled(Flex)`
  justify-content: center;
  height: 32px;
  width: 41px;
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  color: ${palette.GRAY70};
  cursor: pointer;
`;

const ApplyButton = styled(Button)`
  height: 32px;
  width: 41px;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextAreaWrapper = styled.div`
  flex: 1;
  padding: 11px 15px;
  border-radius: 4px;
  border: 1px solid ${palette.GRAY40};
  background-color: #fff;
  transition: all 0.5s ease;
  cursor: text;

  &:hover,
  &:focus,
  &:active {
    border-color: #61a5ff;
    transition: all 0.5s ease;
  }
`;
const TextArea = styled(Input.TextArea)`
  border: none;
  padding: 0;
  line-height: 20px !important;
  min-height: 20px !important;
  max-height: 264px !important;
  resize: none;
`;

export type GroupedRule = {
  rules: {
    subCode: string;
    description: string;
  }[];
  countryCodes: string[];
};

const CountryModalContent = ({
  countryModalRules,
}: {
  countryModalRules: CountryRules;
}) => {
  const [groupedCountryRules, setGroupedCountryRules] = useState<GroupedRule[]>(
    [],
  );
  const countryCodeToNameMap = useCountryCodeToNameMap();
  const countries = usePubCountries();

  useEffect(() => {
    const groupedByRules: { [rulesKey: string]: GroupedRule } = {};

    for (const countryCode in countryModalRules) {
      const rules = countryModalRules[countryCode];
      const rulesKey = JSON.stringify(rules.map((rule) => rule.subCode).sort());

      if (!groupedByRules[rulesKey]) {
        groupedByRules[rulesKey] = {
          rules: rules.map((rule) => ({
            subCode: rule.subCode,
            description: rule.description,
          })),
          countryCodes: [],
        };
      }
      groupedByRules[rulesKey].countryCodes.push(countryCode);
    }

    const groupedCountryRules: GroupedRule[] = Object.values(groupedByRules);
    setGroupedCountryRules(groupedCountryRules);
  }, []);

  return (
    <Flex gap={8} dir="column" align="stretch">
      {groupedCountryRules.map(({ countryCodes, rules }, idx) => (
        <div
          key={idx}
          style={{
            border: `1px solid ${palette.GRAY30}`,
            borderRadius: 8,
            padding: 24,
          }}
        >
          <Flex gap={8}>
            {countryCodes.map((countryCode) => (
              <CountryImgWrapper key={countryCode}>
                <img
                  key={countryCode}
                  src={countryImages[countryCodeToNameMap[countryCode]]}
                  alt={countryCode}
                  style={{
                    width: 20,
                  }}
                />
                <Tooltip>
                  {countryCode === 'ETC'
                    ? '기타'
                    : countries?.find(
                        (item) => item.countryCode === countryCode,
                      )?.countryNameKo}
                </Tooltip>
              </CountryImgWrapper>
            ))}
          </Flex>
          <div style={{ marginTop: 8 }}>
            {rules
              .flatMap(({ description }) => description.split('\n'))
              .map((description, idx) => (
                <Flex gap={4} align="center" gutter={{ top: 4 }} key={idx}>
                  <Bullet />
                  <Typography.Text
                    color="SLATE_GRAY70"
                    type="BODY_2"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {description}
                  </Typography.Text>
                </Flex>
              ))}
          </div>
        </div>
      ))}
    </Flex>
  );
};

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 4px 10px;
  border-radius: 4px;
  background: ${palette.SLATE_GRAY70};
  color: #fff;
  font-size: 12px;
  width: max-content;
  z-index: 2;
`;

const CountryImgWrapper = styled.div`
  position: relative;
  height: 20px;

  img {
    vertical-align: initial;
  }

  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

export default CollapseIngredient;
