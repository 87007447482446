import { SetStateAction, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import { getTextStatus } from 'lib/editor';

export interface TextStatus {
  isBold: boolean;
  isUnderlined: boolean;
  isFontColorClicked: boolean;
  isBackgroundClicked: boolean;
  fontColor: string;
  backgroundColor: string;
  hasColor: boolean;
  hasBackground: boolean;
}

const customScrollbar = css`
  &::-webkit-scrollbar {
    width: 4px; /* 스크롤바의 너비 */
    height: 4px; /* 스크롤바의 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.SLATE_GRAY70}; /* 스크롤바의 색상 */
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;

const EditorWrapper = styled.div<{ contentMaxHeight: number }>`
  position: relative;
  width: 100%;
  max-height: ${({ contentMaxHeight }) => `${contentMaxHeight}px`};
  border: none;
  background: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 14px;

  & *::selection {
    background-color: #1890ffb3;
  }

  &:focus {
    outline: none;
  }

  & > pre {
    white-space: pre-wrap;

    &:focus-visible {
      outline: none;
    }
  }

  ${customScrollbar}
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  pointer-events: none;
  color: ${palette.SLATE_GRAY50};
`;

const SimpleEditor = ({
  contentMaxHeight,
  message,
  placeholder,
  onChange,
  onChangeTextStatus,
}: {
  contentMaxHeight: number;
  message: string;
  placeholder: string;
  onChange: (e: ContentEditableEvent) => void;
  onChangeTextStatus: React.Dispatch<SetStateAction<TextStatus>>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);

  const handleSelection = () => {
    const {
      isBold,
      isUnderlined,
      hasColor,
      hasBackground,
      fontColor,
      backgroundColor,
    } = getTextStatus();

    onChangeTextStatus((textStatus) => ({
      ...textStatus,
      isBold,
      isUnderlined,
      hasColor,
      hasBackground,
      fontColor,
      backgroundColor,
    }));
  };

  return (
    <EditorWrapper contentMaxHeight={contentMaxHeight}>
      <ContentEditable
        spellCheck={false} // 빨간 밑줄 제거
        tagName="pre"
        innerRef={ref}
        html={message} // innerHTML of the editable div
        disabled={false} // use true to disable editing
        onChange={onChange}
        onMouseUp={handleSelection}
        onKeyUp={handleSelection}
        onFocus={() => {
          setPlaceholderVisible(false);
        }}
        onBlur={() => {
          setPlaceholderVisible(true);
          onChangeTextStatus((textStatus) => ({
            ...textStatus,
            isBold: false,
            isUnderlined: false,
            isFontColorClicked: false,
            isBackgroundClicked: false,
            hasBackground: false,
            hasColor: false,
          }));
        }}
      />
      {!message && placeholderVisible && (
        <Placeholder>{placeholder}</Placeholder>
      )}
    </EditorWrapper>
  );
};

export default SimpleEditor;
