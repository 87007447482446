import { useEffect, useMemo, useState, ComponentProps } from 'react';
import isEqual from 'lodash/isEqual';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Select,
} from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { DocumentCode } from 'types/product';
import {
  checkIsFutureDate,
  checkIsPastDate,
  generateFormRules,
  noFutureDateRule,
  noPastDateRule,
  normalizeDate,
} from 'lib/validate';
import { focusToInvalidatedField } from 'lib/form';
import { messages } from 'lib/consts';
import history from 'lib/history';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import {
  AddProductQuarantineSamplesParams,
  FrequencyPeriodOfUseType,
  useProductDocStatus,
  useProductQuarantineSamples,
} from 'service/product';
import { Flex } from 'components/ui';
import FooterBox from 'components/FooterBox';
import { Tip } from 'components/system';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import { Typography } from 'components/system';
import GoBackIfThereIsNoCountryId from 'components/common/GoBackIfThereIsNoCountryId';
import FullLoading from 'components/FullLoading';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import InputNumber from 'components/ui/InputNumber';

export interface PQSForm {
  lotNo: string;
  manuFacturingDate: string;
  expiryDate: string;
  frequencyOfUse: string;
  frequencyPeriodOfUse: FrequencyPeriodOfUseType;
  usagePeriod: string;
}

const Container = styled.div``;

const ProductPQSUIContainer = styled.div`
  width: 520px;
`;

export const ProductPQS = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [form] = Form.useForm<PQSForm>();
  const [initFormData, setInitFormData] = useState<PQSForm | undefined>();
  const [productQuarantineSampleId, setProductQuarantineSampleId] = useState<
    number | undefined
  >();
  const { sendEventToGA } = useGA();
  const currentProduct = useCurrentProduct();
  const countryId = useCountryId() || 0;
  const productId = currentProduct.productId;
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PQS,
  });
  const {
    productQuarantineSamples,
    isGetProductQuarantineSamplesLoading,
    addProductQuarantineSamples,
    isAddProductQuarantineSamplesLoading,
    updateProductQuarantineSamples,
    isUpdateProductQuarantineSamplesLoading,
  } = useProductQuarantineSamples({
    productId,
    productQuarantineSampleId: productQuarantineSampleId,
  });
  const updateMode =
    productQuarantineSamples?.productQuarantineSampleId !== undefined;
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );
  const loading = isGetProductQuarantineSamplesLoading;
  const submitting =
    isAddProductQuarantineSamplesLoading ||
    isUpdateProductQuarantineSamplesLoading;
  const cta = useMemo(() => {
    if (!updateMode) {
      return '등록';
    } else if (docStatus?.status === 'MOD') {
      return '보완 완료';
    }

    return '수정';
  }, [updateMode, docStatus]);

  const handleSubmit: ComponentProps<typeof ProductPQSUI>['onSubmit'] = (
    values,
  ) => {
    const params: AddProductQuarantineSamplesParams = {
      countryId,
      expiryDate: values.expiryDate,
      lotNo: values.lotNo,
      manufacturingDate: values.manuFacturingDate,
      shelfLife: Number(values.usagePeriod),
      frequencyOfUse: Number(values.frequencyOfUse),
      frequencyPeriodOfUse: values.frequencyPeriodOfUse,
    };

    if (updateMode) {
      if (isEqual(values, initFormData)) {
        message.warn(messages.NO_NEED_TO_UPDATE);
        return;
      }

      updateProductQuarantineSamples(params, {
        onSuccess: () => {
          sendEventToGA({
            documentName: '검역 샘플 정보',
            actionType:
              cta === '보완 완료' ? EGAActionType.MODIFY : EGAActionType.UPDATE,
          });
          message.success('수정되었습니다.');
          history.goBack();
        },
      });
    } else {
      addProductQuarantineSamples(params, {
        onSuccess: () => {
          sendEventToGA({
            documentName: '검역 샘플 정보',
            actionType: EGAActionType.REGISTER,
          });
          message.success(`등록되었습니다.`);
          history.goBack();
        },
      });
    }
  };

  useEffect(() => {
    if (!productQuarantineSamples) return;

    form.setFieldsValue({
      expiryDate: productQuarantineSamples.expiryDate,
      lotNo: productQuarantineSamples.lotNo,
      manuFacturingDate: productQuarantineSamples.manufacturingDate,
      usagePeriod: productQuarantineSamples.shelfLife.toString(),
      frequencyOfUse: productQuarantineSamples.frequencyOfUse.toString(),
      frequencyPeriodOfUse: productQuarantineSamples.frequencyPeriodOfUse,
    });
    setProductQuarantineSampleId(
      productQuarantineSamples.productQuarantineSampleId,
    );
    setTimeout(() => {
      setInitFormData(form.getFieldsValue());
    }, 0);
  }, [productQuarantineSamples]);

  return (
    <Container>
      {loading && <FullLoading />}
      <GoBackIfThereIsNoCountryId />
      <Flex justify="end" gap={8} style={{ marginBottom: 24 }}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.PQS}
        />
        {updateMode && (
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.PQS}
          />
        )}
      </Flex>
      <Flex justify="center">
        <ProductPQSUI
          form={form}
          cta={cta}
          readOnly={readOnlyMode}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
      </Flex>
    </Container>
  );
};

const ProductPQSUI = ({
  form,
  cta,
  readOnly = false,
  onSubmit,
  submitting,
}: {
  form?: FormInstance<PQSForm>;
  cta?: string;
  readOnly?: boolean;
  onSubmit?: (values: PQSForm) => void;
  submitting?: boolean;
}) => {
  return (
    <ProductPQSUIContainer>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={
          form ? focusToInvalidatedField({ form, offsetY: -300 }) : undefined
        }
      >
        <Form.Item
          required
          label={
            <>
              Lot No. (Batch number)
              <Tip
                style={{
                  marginLeft: 4,
                  zIndex: 1,
                }}
                trigger="click"
              >
                <Typography.Text type="SMALL">
                  샘플 발송시, 모든 제품을 반드시 같은 Lot No.로 발송하셔야
                  합니다.
                </Typography.Text>
              </Tip>
            </>
          }
          name="lotNo"
          rules={generateFormRules({
            required: true,
            maxLength: 10,
            exceptKorean: true,
          })}
        >
          <Input
            disabled={readOnly}
            placeholder="검역용으로 발송하는 샘플의 Lot No."
          />
        </Form.Item>
        <Flex justify="space-between">
          <Form.Item
            label="Manufacturing Date"
            name="manuFacturingDate"
            rules={[noFutureDateRule]}
            normalize={normalizeDate}
          >
            <DatePicker
              disabled={readOnly}
              checkIsValidDate={checkIsPastDate}
            />
          </Form.Item>
          <Form.Item
            required
            label="Expiry Date"
            name="expiryDate"
            normalize={normalizeDate}
            rules={[
              ...generateFormRules({
                required: true,
              }),
              noPastDateRule,
            ]}
          >
            <DatePicker
              disabled={readOnly}
              checkIsValidDate={checkIsFutureDate}
            />
          </Form.Item>
        </Flex>
        <Row>
          <Col span={8}>
            <Flex align="center">
              <Form.Item
                required
                label="사용 빈도"
                name="frequencyOfUse"
                rules={generateFormRules({
                  required: true,
                  onlyNumber: true,
                  maxLength: 2,
                })}
              >
                <InputNumber
                  disabled={readOnly}
                  min={1}
                  maxLength={2}
                  style={{ width: 100, paddingRight: 28 }}
                />
              </Form.Item>
              <Form.Item
                required
                name="frequencyPeriodOfUse"
                rules={generateFormRules({
                  required: true,
                })}
              >
                <Select
                  placeholder="주기 선택"
                  disabled={readOnly}
                  options={[
                    { label: '1일', value: FrequencyPeriodOfUseType.DAY },
                    { label: '1주', value: FrequencyPeriodOfUseType.WEEK },
                    { label: '1개월', value: FrequencyPeriodOfUseType.MONTH },
                  ]}
                  style={{ width: 120, marginTop: 36, marginLeft: 8 }}
                />
              </Form.Item>
              <Typography.Text
                type="BODY_2"
                inline
                style={{
                  position: 'absolute',
                  left: 54,
                  top: 49,
                  color: readOnly ? '#a9a6a6' : 'auto',
                }}
              >
                회
              </Typography.Text>
            </Flex>
          </Col>
          <Col span={6} offset={5}>
            <Flex align="center">
              <Form.Item
                required
                label="총 사용기한"
                name="usagePeriod"
                rules={generateFormRules({
                  required: true,
                  maxLength: 2,
                })}
              >
                <InputNumber
                  min={1}
                  max={99}
                  maxLength={2}
                  disabled={readOnly}
                />
              </Form.Item>
              <Typography.Text
                inline
                style={{ paddingTop: 19, paddingLeft: 8 }}
              >
                months
              </Typography.Text>
            </Flex>
          </Col>
        </Row>
      </Form>
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnly}>
          {cta === '보완 완료' && (
            <CorrectRequestWithoutChangeButton
              documentCode={DocumentCode.PQS}
            />
          )}
          <Button type="primary" onClick={form?.submit} loading={submitting}>
            {cta}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductPQSUIContainer>
  );
};

export default ProductPQSUI;
