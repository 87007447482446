import { Button, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';

import FooterBox from 'components/FooterBox';
import { PasswordInput, Typography } from 'components/system';
import history from 'lib/history';
import path from 'lib/path';
import { requireRule } from 'lib/validate';
import { useNMPAPlatformAccount } from 'service/material/nmpa/nmpa';
import { NMPACompanyInfo } from 'types/material/nmpa/nmpa';
import { messages } from 'lib/consts';

const Container = styled.div`
  margin: 20px auto 0;
  max-width: 520px;
`;

const NMPAPlatformAccount = ({
  nmpaCompanyInfo,
}: {
  nmpaCompanyInfo?: NMPACompanyInfo | null;
}) => {
  const [form] = Form.useForm();
  const { addNMPAPlatformAccount, isLoading } = useNMPAPlatformAccount();
  const updateMode = typeof nmpaCompanyInfo?.platformUsername === 'string';

  const handleSubmit = (formValues: {
    platformUsername: string;
    platformPassword: string;
  }) => {
    if (
      formValues.platformUsername === nmpaCompanyInfo?.platformUsername &&
      formValues.platformPassword === nmpaCompanyInfo?.platformPassword
    ) {
      message.warn(messages.NO_NEED_TO_UPDATE);

      return;
    }

    addNMPAPlatformAccount(formValues, {
      onSuccess: () => {
        if (updateMode) {
          message.success('변경되었습니다.');

          return;
        }

        Modal.confirm({
          bodyStyle: {
            textAlign: 'center',
          },
          width: 400,
          icon: null,
          content: (
            <Typography.Text type="BODY_2">
              회사 정보 입력이 완료되었습니다.
              <br />
              <br />
              취득 신청을 위해
              <br />
              원료 신고 정보 입력으로 이동하시겠습니까?
            </Typography.Text>
          ),
          okText: '원료 정보 입력',
          onOk: () => {
            history.push(path.material.nmpa.material.list);
          },
        });
      },
    });
  };

  useEffect(() => {
    if (nmpaCompanyInfo) {
      form.setFieldsValue({
        platformUsername: nmpaCompanyInfo.platformUsername,
        platformPassword: nmpaCompanyInfo.platformPassword,
      });
    }
  }, [nmpaCompanyInfo]);

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="플랫폼 ID"
          name="platformUsername"
          required
          rules={[
            requireRule,
            {
              pattern: /^[a-z].{2,19}$/i,
              message: '영문으로 시작하는 3~20자의 문자',
            },
          ]}
        >
          <Input
            autoComplete="username"
            placeholder="영문으로 시작하는 3~20자의 문자"
          />
        </Form.Item>
        <Form.Item
          label="플랫폼 PW"
          name="platformPassword"
          required
          rules={[
            requireRule,
            {
              pattern: /^[A-Z](?=.*[a-z])(?=.*\d).{7,19}$/,
              message: '대문자+소문자+숫자 조합 8~20자',
            },
          ]}
        >
          <PasswordInput
            autoComplete="new-password"
            placeholder="대문자+소문자+숫자 조합 8~20자"
          />
        </Form.Item>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {!updateMode ? '등록' : '수정'}
          </Button>
        </FooterBox>
      </Form>
    </Container>
  );
};

export default NMPAPlatformAccount;
