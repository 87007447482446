import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { message } from 'antd';

interface Props {
  path: string | string[];
  component: (props: any) => JSX.Element;
  check?: boolean;
  redirect: string;
  exact?: boolean;
  message?: string;
}

const PrivateRoute = ({
  path,
  component: Component,
  check,
  redirect,
  exact,
  message: msg,
}: Props) => {
  useEffect(() => {
    if (!check && msg) {
      message.warning(msg);
    }
  }, []);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        check ? <Component {...props} /> : <Redirect to={redirect} />
      }
    />
  );
};

export default PrivateRoute;
