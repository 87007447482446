import client from 'lib/api/client';
import { ChecklistItem } from 'types/brand/checklist';
import { APIResponse } from 'types/common';

export const getChecklist = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<APIResponse<ChecklistItem[]>>(
    `/products/${productId}/countries/${countryId}/checklists`,
  );
export const answerChecklist = (body: {
  estimateTargetId: number;
  checklistAnswers: {
    countryChecklistId: number;
    isYes?: boolean;
    subjectAnswer?: string;
  }[];
}) =>
  client.post(
    `/checklist/answer/${body.estimateTargetId}`,
    body.checklistAnswers,
  );
