import { Modal } from 'antd';

import { Typography } from 'components/system';
import TabButton from 'components/TabButton';
import Table from 'components/Table';
import palette from 'lib/styles/palette';

const JapanExportInformationModal = ({
  isCITES,
  onClose,
}: {
  isCITES: boolean | null;
  onClose: () => void;
}) => {
  return (
    <Modal
      visible={isCITES !== null}
      width={945}
      onCancel={onClose}
      footer={null}
    >
      <Typography.Text
        type="TITLE_2"
        style={{ fontSize: 22 }}
        gutter={{ bottom: 22 }}
      >
        국가별 수출 정보
      </Typography.Text>
      <TabButton selected>일본</TabButton>
      <Table
        bordered
        size="small"
        className="horizontal"
        style={{
          marginTop: 16,
          border: `1px solid ${palette.PRIMARY50}`,
          borderRadius: 0,
        }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Th
              style={{
                width: 200,
              }}
            >
              구분
            </Table.Th>
            <Table.Td>{isCITES ? '불가 (CITES)' : '불가'}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>제한사항</Table.Th>
            <Table.Td style={{ whiteSpace: 'pre-line' }}>
              {isCITES
                ? '일본에 수출이 불가한 CITES 성분으로 해당 성분들은 수출입 허가서가 있다고 해도 대부분 사용이 불가능합니다.'
                : '일본 화장품공업연합회 화장품성분표시명칭목록에 없는 원료이기 때문에 대부분 사용이 불가능함.'}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Modal>
  );
};

export default JapanExportInformationModal;
