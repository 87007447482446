import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, message } from 'antd';

import { DocumentCode } from 'types/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useProductDocStatus, useProductMaterialData } from 'service/product';
import { messages } from 'lib/consts';
import history from 'lib/history';
import { urlToFile } from 'lib/file';
import UpdateLog from 'components/product/UpdateLog';
import { Flex } from 'components/ui';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import FullLoading from 'components/FullLoading';
import GoBackIfThereIsNoCountryId from 'components/common/GoBackIfThereIsNoCountryId';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

interface ProductMaterial {
  productMaterialDataId: number;
  file: File;
}

const ProductPMA = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [productMaterials, setProductMaterials] = useState<ProductMaterial[]>(
    [],
  );
  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const { readOnlyMode } = useSelector(({ certificate }: any) => ({
    readOnlyMode: certificate.readOnlyMode,
  }));
  const countryId = useCountryId();
  const {
    isUpdateProductMaterialDataLoading,
    isGetProductMaterialDataLoading,
    productMaterialData,
    updateProductMaterialData,
  } = useProductMaterialData({ productId });
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PMA,
  });
  const [files, setFiles] = useState<File[]>([]);
  const updateMode = productMaterials.length > 0;
  const cta = useMemo(() => {
    if (!updateMode) {
      return '등록';
    } else if (docStatus?.status === 'MOD') {
      return '보완 완료';
    }

    return '수정';
  }, [updateMode, docStatus]);
  const loading = isGetProductMaterialDataLoading;
  const submitting = isUpdateProductMaterialDataLoading;

  const handleFileUpload: ComponentProps<
    typeof FileUploadContainer
  >['onUpload'] = (file: File) => {
    setFiles((prevFiles) => prevFiles.concat(file));
    return true;
  };

  const handleFileDelete: ComponentProps<
    typeof FileUploadContainer
  >['onDelete'] = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
    return false;
  };

  const handleSubmit = () => {
    if (!countryId) return;

    if (!files.length) {
      message.warn(messages.REQUIRE_ONE_OR_MORE_FILES);
      return;
    }

    const productMaterialDataIds: number[] = [];
    const newFiles: { file: File }[] = [];

    for (const file of files) {
      const productMaterial = productMaterials.find((pm) => pm.file === file);

      // 전에 업로드 되었던 파일인 경우
      if (productMaterial) {
        productMaterialDataIds.push(productMaterial.productMaterialDataId);
      } else {
        newFiles.push({ file });
      }
    }

    if (
      productMaterialDataIds.length === productMaterials.length &&
      newFiles.length === 0
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    const newProductMaterials: {
      productMaterialDataId?: number;
      file?: File;
    }[] = productMaterialDataIds.map((id) => ({
      productMaterialDataId: id,
    }));

    newProductMaterials.push(...newFiles);

    const params = {
      productId,
      countryId,
      productMaterials: newProductMaterials,
    };

    updateProductMaterialData(params, {
      onSuccess: () => {
        sendEventToGA({
          documentName: '원료 자료',
          actionType:
            cta === '보완 완료'
              ? EGAActionType.MODIFY
              : cta === '등록'
              ? EGAActionType.REGISTER
              : EGAActionType.UPDATE,
        });
        message.success(`${cta}되었습니다.`);
        history.goBack();
      },
    });
  };

  useEffect(() => {
    if (!productMaterialData) return;

    (async () => {
      const newProductMaterial: ProductMaterial[] = [];

      for (const data of productMaterialData) {
        newProductMaterial.push({
          productMaterialDataId: data.productMaterialDataId,
          file: await urlToFile(data.uploadFileUrl, data.filename),
        });
      }

      setFiles(newProductMaterial.map((material) => material.file));
      setProductMaterials(newProductMaterial);
    })();
  }, [productMaterialData]);

  return (
    <>
      <GoBackIfThereIsNoCountryId />
      {loading && <FullLoading />}
      <Flex
        align="center"
        justify="flex-end"
        columnGap={8}
        style={{ marginBottom: 20 }}
      >
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.PMA}
        />
        {updateMode && (
          <UpdateLog
            gutter={0}
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.PMA}
          />
        )}
      </Flex>
      <FileUploadContainer
        files={files}
        readOnly={readOnlyMode}
        onUpload={handleFileUpload}
        onDelete={handleFileDelete}
        accept=".docx,.pdf,.xlsx,.hwp,.pptx"
        max={30}
      />
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          {cta === '보완 완료' && (
            <CorrectRequestWithoutChangeButton
              documentCode={DocumentCode.PMA}
            />
          )}
          <Button type="primary" loading={submitting} onClick={handleSubmit}>
            {cta}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </>
  );
};

export default ProductPMA;
