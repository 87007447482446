import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModal } from 'hook/useModal';
import { useCompanyDocsList } from 'service/brand/company';
import { CompanyDocsUserType } from 'types/brand/company';
import CompanyDocsListRegistration from './CompanyDocsListRegistration';
import CompanyDocsListUpdate from './CompanyDocsListUpdate';
import LotDocsList from './LotDocsList';
import ProductDocsList from './ProductDocsList';

const Container = styled.div``;

const StyledTabs = styled(Tabs)`
  overflow: visible;

  .ant-tabs-nav {
    margin-bottom: 36px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

export enum MyDocTabKey {
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  LOT_NO = 'LOT_NO',
}

const SharedDocsTabs = ({
  qcqaUserDocumentId,
}: {
  qcqaUserDocumentId: number;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [type, setType] = useState<'update' | 'register'>('register');
  const [tabKey, setTabKey] = useState<string>(MyDocTabKey.COMPANY);
  const [isBlockTab, setIsBlockTab] = useState<boolean | undefined>();
  const { openAlertModal } = useModal();
  const { companyDocsList, isCompanyDocsListLoading } = useCompanyDocsList({
    useType:
      tabKey === CompanyDocsUserType.COMPANY
        ? CompanyDocsUserType.COMPANY
        : tabKey === CompanyDocsUserType.PRODUCT
        ? CompanyDocsUserType.PRODUCT
        : CompanyDocsUserType.LOT_NO,
  });

  useEffect(() => {
    const isUpdateMode =
      companyDocsList.filter((doc) => doc.relationType === 'CUSTOM').length >
        0 ||
      companyDocsList.find((doc) =>
        doc.records?.filter((record) => record.attaches?.length > 0),
      );

    if (!isUpdateMode) {
      setType('register');
    } else {
      setType('update');
    }
  }, [companyDocsList]);

  return (
    <Container>
      <StyledTabs
        defaultActiveKey={tabKey}
        activeKey={tabKey}
        onTabClick={(key: string) => {
          if (key !== tabKey && isBlockTab) {
            openAlertModal({
              content:
                '변경사항이 저장되지 않았습니다.\n페이지를 이동하시겠습니까?',
              okText: '이동하기',
              closeText: '취소',
              onOk: () => {
                setTabKey(key);
              },
            });
          } else {
            setTabKey(key);
          }
        }}
      >
        <Tabs.TabPane key={MyDocTabKey.COMPANY} tab="회사 서류">
          {type === 'register' && (
            <CompanyDocsListRegistration
              tabKey={tabKey}
              onChangeIsBlockTab={setIsBlockTab}
              companyDocsList={companyDocsList}
              isCompanyDocsListLoading={isCompanyDocsListLoading}
              qcqaUserDocumentId={qcqaUserDocumentId}
              isMounted={isMounted}
              setIsMounted={setIsMounted}
            />
          )}
          {type === 'update' && (
            <CompanyDocsListUpdate
              onChangeIsBlockTab={setIsBlockTab}
              tabKey={tabKey}
              companyDocsList={companyDocsList}
              isCompanyDocsListLoading={isCompanyDocsListLoading}
              qcqaUserDocumentId={qcqaUserDocumentId}
              isMounted={isMounted}
              setIsMounted={setIsMounted}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key={MyDocTabKey.PRODUCT} tab="제품 서류">
          <ProductDocsList
            companyDocsList={companyDocsList}
            tabKey={tabKey}
            onChangeIsBlockTab={setIsBlockTab}
            isCompanyDocsListLoading={isCompanyDocsListLoading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={MyDocTabKey.LOT_NO} tab="제조번호 서류">
          <LotDocsList
            companyDocsList={companyDocsList}
            tabKey={tabKey}
            onChangeIsBlockTab={setIsBlockTab}
            isCompanyDocsListLoading={isCompanyDocsListLoading}
          />
        </Tabs.TabPane>
      </StyledTabs>
    </Container>
  );
};

export default SharedDocsTabs;
