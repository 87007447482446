import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';
import { Typography } from 'components/system';

const ProductPFAPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="광고 입증 자료"
      notices={[
        <Typography.Text type="BODY_2">
          해당 서류는 입증이 필요한 화장품 표시 및 광고를 한 경우에만 필요한
          서류입니다.
          <Typography.Text type="SMALL">
            예시) 인체적용시험 자료, 인체 외 시험 자료, 시험분석 자료, SCI급
            논문, 특허증 등
          </Typography.Text>
        </Typography.Text>,
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.PFA}
        accept="application/pdf, image/*"
        max={10}
      />
    </ProductPageTemplate>
  );
};

export default ProductPFAPage;
