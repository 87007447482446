import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Col, message, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import FileViewer from 'components/file/FileViewer';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import FileList from './FileList';
import { messages } from 'lib/consts';
import Icon from 'components/ui/Icon/Icon';

const FileUploadBlock = styled.div<{ readOnly: boolean }>`
  margin: 0 auto;
  ${(props) =>
    props.readOnly &&
    css`
      max-width: 408px;
    `}
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  border: 1px solid ${palette.GRAY40} !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  height: 406px !important;

  .ant-btn {
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: #727272;

    .material-icons {
      margin-right: 0;
      color: ${palette.GRAY60};
    }
  }

  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: #7e7e7e;
  }
`;

const FileUpload = ({
  files,
  previewedFile,
  viewerVisible = true,
  accept = 'application/pdf',
  readOnly,
  max,
  style,
  fileListFullWidth,
  onUpload,
  onFileClick,
  onDelete,
}: {
  files: any;
  previewedFile?: any;
  viewerVisible?: boolean;
  accept?: string;
  readOnly: boolean;
  max?: number;
  style?: React.CSSProperties;
  fileListFullWidth?: boolean;
  onUpload: (file: File) => void;
  onFileClick: (file: any) => void;
  onDelete?: (file: any) => void;
}) => {
  const multiple = Array.isArray(files);
  const [uploadedFileList, setUploadedFileList] = useState<RcFile[]>([]);

  // TODO: files의 type이 any로 되어 있어 추후 변경
  const filenameList = multiple
    ? files
        .map((item: any) =>
          item instanceof File ? item.name : Object.values(item),
        )
        .flat(2)
    : [
        ...(files
          ? [files instanceof File ? files.name : Object.values(files)]
          : []),
      ].flat(2);

  useEffect(() => {
    if (!uploadedFileList?.length) return;

    const existFileNameAndShowWarning = (filename: string) => {
      const exist = filenameList?.includes(filename);
      if (exist) {
        message.warn(messages.DUPLICATE_FILE_NAME);
      }
      return exist;
    };

    // 단일 파일
    if (!multiple) {
      if (existFileNameAndShowWarning(uploadedFileList[0].name)) return;
      onUpload(uploadedFileList[0]);
      return;
    }

    // 여러 파일
    let availableToUploadCnt = (max || Number.MAX_VALUE) - files.length;

    for (const file of uploadedFileList) {
      if (availableToUploadCnt <= 0) {
        message.warn(`최대 ${max}개의 파일만 업로드 가능합니다.`);
        continue;
      } else if (existFileNameAndShowWarning(file.name)) {
        continue;
      }

      onUpload(file);
      availableToUploadCnt--;
    }
  }, [uploadedFileList]);

  return (
    <FileUploadBlock style={style} readOnly={readOnly}>
      <Row gutter={[16, 16]} justify="center">
        <Col
          xs={{ span: 24 }}
          sm={{ span: fileListFullWidth || readOnly ? 24 : 12 }}
        >
          <FileList
            header={
              <Typography.Text type="BODY_2" gutter={{ bottom: 8 }}>
                업로드 된 파일 {multiple ? '목록' : ''}
              </Typography.Text>
            }
            selectedFile={previewedFile}
            files={files}
            upload={
              !readOnly && (
                <StyledUploadDragger
                  accept={accept}
                  multiple={multiple}
                  disabled={max === (files?.length || null)}
                  beforeUpload={(_, fileList) => {
                    setUploadedFileList(fileList);
                    return false;
                  }}
                  itemRender={() => null}
                >
                  <Button
                    onClick={() => {
                      if (max === (files?.length || null)) {
                        message.warn(
                          `최대 ${max}개의 파일만 업로드 가능합니다.`,
                        );
                      }
                    }}
                  >
                    <Icon
                      name="plus"
                      color="GRAY60"
                      style={{ margin: '0 auto' }}
                    />
                    파일 선택
                  </Button>
                  <Typography.Text type="BODY_2" gutter={{ top: 8 }}>
                    버튼 클릭 혹은 드래그하여
                    <br />
                    파일을 첨부하세요.
                  </Typography.Text>
                </StyledUploadDragger>
              )
            }
            onFileClick={onFileClick}
            onDelete={readOnly ? undefined : onDelete}
          />
        </Col>
        {viewerVisible && accept !== '.ai' && accept !== '.zip' && (
          <Col xs={{ span: 24 }} sm={{ span: readOnly ? 24 : 12 }}>
            <FileViewer file={previewedFile} bodyStyle={{ height: 556 }} />
          </Col>
        )}
      </Row>
    </FileUploadBlock>
  );
};

export default FileUpload;
