import styled from 'styled-components';
import { Modal } from 'antd';

import { Flex } from 'components/ui';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';

const Container = styled(Flex)`
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 3px 7px;
  cursor: pointer;
  height: 24px;
`;

const InputGuideButton = ({
  visible,
  onClick,
  onClose,
}: {
  visible: boolean;
  onClick: () => void;
  onClose: () => void;
}) => (
  <Container onClick={onClick} align="center" gap={2}>
    <Modal
      visible={visible}
      footer={null}
      width={800}
      style={{ padding: '24px 40px', boxSizing: 'content-box' }}
      closeIcon={<></>}
    >
      <Flex align="flex-start" justify="space-between">
        <Typography.Text type="TITLE_1">
          Allergens List 입력 가이드
        </Typography.Text>
        <Icon name="close" size={30} onClick={onClose} />
      </Flex>
      <img
        src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/formula_single_guide.png"
        alt="guide"
        style={{ maxWidth: 720 }}
      />
    </Modal>
    <Icon name="search" size={12} color="GRAY70" />
    <Typography.Text type="SMALL" color="GRAY70">
      입력 가이드
    </Typography.Text>
  </Container>
);
export default InputGuideButton;
