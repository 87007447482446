import { UploadFile } from 'types/common';

export interface QCQAProductListItem {
  brandName: string;
  productNameEn: string;
  productNameKo: string;
  qcqaProductId: number;
  registerDate: string;
  registerUserId: number;
  registerUsername: string;
  status: 'NORMAL' | 'DELETE';
}

export interface QCQALotListItem {
  companyManufacturerId: number;
  qcqaProductLotnoId: number;
  manufacturingDate: string;
  lotNo: string;
  netWeight: number;
  netWeightUnit: string;
  output: number;
  manufacturerNameKo: string;
  registerUserId: number;
  registerDate: string;
  registerUsername: string;
  qcqaProductId: number;
  status: 'NORMAL' | 'DELETE';
  remark: string;
}

export interface QCQAProductLotRecord {
  qcqaUserDocumentId: number;
  relationType: string;
  qcqaRelationDocumentRecordId: number;
}

export interface QCQAProductLotAttach {
  qcqaUserDocumentId: number;
  attachIds: number[];
}
export interface QCQADetailLotListItem {
  qcqaProductId: number;
  productNameKo: string;
  productNameEn: string;
  lotNos: {
    qcqaProductLotnoId: number;
    lotNo: string;
  }[];
  registerDt: string;
  updateDt: string | null;
}

export interface QCQADocumentItem {
  qcqaDocumentAttachId: number;
  qcqaDocumentId: number;
  attachId: number;
  filename: string;
  uploadFileUrl: string;
}

export interface QCQAProductDocumentItem {
  qcqaDocumentAttachId: number;
  qcqaDocumentId: number;
  attachId: number;
  filename: string;
  uploadFileUrl: string;
  registerDt: string;
}

export interface QCQABasicForm {
  brandNameEn: string;
  productNameKo: string;
  productNameEn: string;
  netWeight: number;
  netWeightUnit: string;
  netWeightUnitDirect: string | null;
  productYear: number;
  lotNo: string;
  manufacturerId: number;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerFax: string;
  remark?: string;
}

export interface QCQACreateProductRecordItem {
  qcqaDocumentRecordId?: number;
  qcqaUserDocumentId?: number;
  recordDate?: string;
  name?: string;
  attachIds?: number[];
  deleteQcqaDocumentRecordAttachIds?: number[];
  isDelete?: boolean;
  formulaFile?: File;
}

export interface QCQACreateProduct {
  brandName: string;
  productNameKo?: string;
  productNameEn?: string;
  records?: {
    qcqaUserDocumentId: number;
    attachIds: number[];
    formulaFile?: File;
  }[];
}

export interface QCQAUpdateProduct {
  brandName?: string;
  productNameKo?: string;
  productNameEn?: string;
  records?: {
    qcqaUserDocumentId?: number;
    attachIds?: number[];
    formulaFile?: File;
  }[];
}

export interface QCQACreateLot {
  qcqaProductId: number;
  companyManufacturerId: number;
  manufacturingDate: string;
  lotNo: string;
  netWeight: number;
  netWeightUnit: string;
  output: number;
  manufacturerNameKo: string;
  manufacturerNameEn?: string;
  manufacturerAddressKo: string;
  manufacturerTel: string;
  manufacturerFax?: string;
  remark?: string;
  records?: {
    qcqaUserDocumentId: number;
    relationType: string;
    qcqaRelationDocumentRecordId: number;
  }[];
  attaches?: {
    qcqaUserDocumentId: number;
    attachIds: number[];
  }[];
}

export enum QCQALotDocumentUseType {
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  LOT_NO = 'LOT_NO',
}

export interface QCQALotDocsListItem {
  qcqaUserDocumentId: number;
  relationType: 'BASIC' | 'CUSTOM';
  name: string;
  code: string;
  useType: QCQALotDocumentUseType;
  isForCertification: boolean;
  isDisplay: boolean;
  displayOrder: number;
  description: string | null;
  registerDt: string;
  records: QCQALotRecord[] | null;
  selected: {
    qcqaProductLotnoRecordId: number;
    qcqaRelationDocumentRecordId: number;
  } | null;
  attaches: QCQALotAttach[] | null;
}

export interface QCQALotRecord {
  qcqaDocumentRecordId: number;
  name: string;
  recordDate: string;
  attaches: QCQALotRecordAttach[] | null;
}

export interface QCQALotRecordAttach extends UploadFile {
  qcqaDocumentRecordAttachId: number;
}

export interface QCQALotAttach extends UploadFile {
  qcqaProductLotnoAttachId?: number;
}

export interface QCQALotBasic {
  companyManufacturerId: number;
  qcqaProductLotnoId: number;
  manufacturingDate: string;
  lotNo: string;
  netWeight: number;
  netWeightUnit: string;
  output: number;
  manufacturerTel: string;
  manufacturerFax: string;
  manufacturerAddressKo: string;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  remark: string;
}

export interface QCQACompanyDocsHistory {
  qcqaDocumentRecordId: number;
  name: string;
  recordDate: string;
  attaches: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
    qcqaDocumentRecordAttachId: number;
  }[];
}

export interface QCQALotDocsHistoryUpdate {
  qcqaUserDocumentId: number;
  relationType: QCQALotDocumentUseType;
  qcqaRelationDocumentRecordId: number;
}

export interface QCQAUpdateDocument {
  brandNameEn: string;
  productNameEn: string;
  productYear: number;
  lotNo: string;
  manufacturerId: number;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerFax: string;
  remark?: string;
}

export interface QCQAProduct {
  qcqaProductId: number;
  productNameKo: string;
  productNameEn: string;
  brandName: string;
}

export interface QCQAFormDataStore {
  basic: QCQABasicForm;
  lotDetail?: {
    fileMapBeforeUploading: Record<number, FileInfo[]>;
    screeningFileData?: {
      fileInfo: UploadFile;
      file: File;
    };
  };
}

export interface QCQADocumentFile {
  qcqaDocumentAttachId: number;
  qcqaDocumentId: number;
  attachId: number;
  filename: string;
  uploadFileUrl: string;
  registerDt: string;
}

export type FileInfo = Omit<
  QCQADocumentFile,
  'qcqaDocumentAttachId' | 'qcqaDocumentId'
> & {
  qcqaDocumentAttachId?: number;
  qcqaDocumentId?: number;
};

export interface QCQAProdcutLotno {
  qcqaSelfCheckProductId?: number;
  qcqaProductId: number;
  qcqaProductLotnoId?: number;
}

export interface QCQAManufacturerInfo {
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressKo: string;
  manufacturerTel: string;
  manufacturerFax: string;
}

export interface QCQALotItem {
  qcqaProductLotnoId: number;
  manufacturingDate: string;
  netWeight: number;
  netWeightUnit: string;
  manufacturerNameKo: string;
  output: number;
  lotNo: string;
}

export interface QCQAProductOutputItem {
  qcqaProductId: number;
  productNameKo: string;
  productNameEn: string;
  lotnos: QCQALotItem[];
}

export interface QCQALotItem extends QCQAManufacturerInfo {
  companyManufacturerId: number;
  lotNo: string;
  manufacturingDate: string;
  netWeight: number;
  netWeightUnit: string;
  manufacturerNameKo: string;
  output: number;
  qcqaProductLotnoId: number;
  remark: string | null;
}
