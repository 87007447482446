import qs from 'qs';
import { useQuery } from 'react-query';

import client from 'lib/api/client';
import {
  apiUrls,
  useBasicMutation,
  useBasicQuery,
  usePageQuery,
} from 'service/core';
import { APIResponse } from 'types/common';
import {
  ManufacturerAddParams,
  ManufacturerCompany,
  ManufacturerDetail,
  QCQAProductByManufacturers,
} from 'types/manufacturer/company';

export const useManufacturerCompanies = (manufacturerCompaniesParams?: {
  isExcludeUserInserted: boolean;
  enabled?: boolean;
}) => {
  const { isExcludeUserInserted, enabled = true } =
    manufacturerCompaniesParams || {};

  return useQuery(
    ['pub/manufacturer/name', manufacturerCompaniesParams],
    () =>
      client.get<APIResponse<ManufacturerCompany[]>>(
        `/pub/manufacturers?${qs.stringify({ isExcludeUserInserted })}`,
      ),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
      enabled,
    },
  );
};

export const useAllManufacturer = () => {
  // 관리_제조사_검색_cmc005 (페이지네이션 X)
  const {
    data: allManufacturers = [],
    isLoading: getAllManufacturersLoading,
  } = useBasicQuery<ManufacturerDetail[]>({
    apiUrl: apiUrls.manufacturers,
  });

  return { allManufacturers, getAllManufacturersLoading };
};

export const useManufacturer = ({ page }: { page: number }) => {
  // 관리_제조사_검색_cmc005
  const {
    data: manufacturers,
    isLoading: getManufacturersLoading,
    totalElements: total,
  } = usePageQuery<ManufacturerDetail>({
    apiUrl: apiUrls.manufacturers,
    qs: {
      size: 10,
      page,
    },
  });

  // 관리_제조사_삭제_cmc003
  const {
    mutate: deleteManufacturers,
    isLoading: deleteLoading,
  } = useBasicMutation<{ companyManufacturerIds: number[] }>({
    apiUrl: apiUrls.manufacturers,
    refetchUrl: apiUrls.manufacturers,
    method: 'delete',
  });

  // 관리_제조사_사용중인_qcqa_제품_조회_cmc004
  const {
    mutate: getQCQAProductsByManufacturers,
    isLoading: getQCQAProductsByManufacturersLoading,
  } = useBasicMutation<
    { companyManufacturerIds: number[] },
    QCQAProductByManufacturers[]
  >({
    apiUrl: apiUrls.getQCQAProductsByManufacturers,
    method: 'get',
  });

  return {
    manufacturers,
    total,
    getManufacturersLoading,
    deleteManufacturers,
    deleteLoading,
    getQCQAProductsByManufacturers,
    getQCQAProductsByManufacturersLoading,
  };
};

export const useManufacturerDetail = ({
  companyManufacturerId,
}: {
  companyManufacturerId?: number;
}) => {
  // 관리_제조사_상세_정보_조회_cmc006
  const {
    data: manufacturerDetail,
    isLoading: getManufacturerDetailLoading,
  } = useBasicQuery<ManufacturerDetail>({
    apiUrl: apiUrls.manufacturerDetail,
    option: {
      enabled: companyManufacturerId !== undefined,
    },
    ...(companyManufacturerId && { urlParams: { companyManufacturerId } }),
  });

  // 관리_제조사_등록_cmc001
  const {
    mutate: addManufacturer,
    isLoading: addManufacturerLoading,
  } = useBasicMutation<ManufacturerAddParams>({
    apiUrl: apiUrls.manufacturers,
    refetchUrl: apiUrls.manufacturers,
  });

  // 관리_제조사_수정_cmc002
  const {
    mutate: updateManufacturer,
    isLoading: updateManufacturerLoading,
  } = useBasicMutation<Partial<ManufacturerAddParams>>({
    apiUrl: apiUrls.manufacturerDetail,
    method: 'patch',
    refetchUrl: [apiUrls.manufacturers, apiUrls.manufacturerDetail],
    ...(companyManufacturerId && { urlParams: { companyManufacturerId } }),
  });

  return {
    manufacturerDetail,
    getManufacturerDetailLoading,
    addManufacturer,
    addManufacturerLoading,
    updateManufacturer,
    updateManufacturerLoading,
  };
};
