import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Table from 'components/ui/Table/Table';
import palette from 'lib/styles/palette';
import { useNMPADetailUseGuideInfo } from 'service/material/nmpa/nmpa';
import { NMPADetailUseGuide } from 'types/material/nmpa/nmpa';

export interface UseGuideModalProps {
  onClose?: () => void;
  materialId: number;
}

const Container = styled.div`
  .ant-modal-content {
    height: 560px !important;
  }
  .inci-name {
    max-width: 360px;
  }
`;

const StyledDiv = styled.div`
  .ant-table-body::-webkit-scrollbar {
    width: 4px; /* 스크롤바의 너비 */
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background: ${palette.SLATE_GRAY70}; /* 스크롤바의 색상 */
    border-radius: 2px;
  }

  .ant-table-body::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;

const Title = styled(Typography.Text)`
  font-size: 18px;
`;

const UseGuideModal: FunctionComponent<UseGuideModalProps> = ({
  materialId,
  onClose,
}) => {
  const {
    nmpaDetailUseGuideInfo,
    isNmpaDetailUseGuideInfoLoading,
  } = useNMPADetailUseGuideInfo({
    materialNmpaBasicInfoId: materialId,
  });

  // HINT: 소수점 셋째자리. 100% 넘는 경우는 100%로 표기
  const getReplacedValue = (value: string, num: number) => {
    if (value === '-') {
      return '역사사용량 없음';
    }
    if (Number(value) > 100) {
      return '100';
    }

    if (value.includes('.')) {
      const integer = value.split('.')[0];
      const decimalToPointThree = value.split('.')[1].slice(0, num);

      return `${integer}.${decimalToPointThree}`;
    } else {
      return value;
    }
  };

  const columns: ColumnsType<NMPADetailUseGuide> = useMemo(
    () => [
      {
        title: 'No.',
        width: '16.6%',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'INCI Name',
        width: '50%',
        align: 'center',
        className: 'inci-name',
        render: (_, { nameEn }) => nameEn,
      },
      {
        title: 'Leave on (%) / Rinse-off (%)',
        width: '33.3%',
        align: 'center',
        render: (_, { leaveOnValue, rinseOffValue }) =>
          `${getReplacedValue(leaveOnValue, 4)} / ${getReplacedValue(
            rinseOffValue,
            4,
          )}`,
      },
    ],
    [],
  );
  return (
    <Container>
      <Modal
        title={
          <Flex align="center">
            <Title>권장 사용량 가이드</Title>
            <Tip
              style={{ marginLeft: 4 }}
              trigger="click"
              closeIconStyle={{
                fontSize: 12,
              }}
              bodyStyle={{
                width: 304,
                whiteSpace: 'pre-line',
                lineHeight: '18px',
              }}
            >
              <Typography.Text inline type="SMALL">
                권장 사용량의 최소값은 0%로 시작하여 작성 하시
                <br />
                는것을 권장합니다.
              </Typography.Text>
            </Tip>
          </Flex>
        }
        width={800}
        footer={null}
        onCancel={onClose}
        visible={true}
        bodyStyle={{ minHeight: '498px' }}
      >
        <StyledDiv>
          <Table
            rowKey="nameEn"
            columns={columns}
            loading={isNmpaDetailUseGuideInfoLoading}
            dataSource={nmpaDetailUseGuideInfo}
            pagination={false}
            scroll={{
              y: 400,
            }}
          />
        </StyledDiv>
      </Modal>
    </Container>
  );
};

export default UseGuideModal;
