import { Button, Checkbox, Form, FormInstance, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { Margin } from 'components/system/general/Margin';
import { checkIsPageEmpty } from 'lib/validate';
import { useMarketingTemplate } from 'service/material/marketing';
import { useRawMaterial } from 'service/material/rawMaterial';
import {
  MarketingDisplayType,
  MarketingFile,
  MarketingFormDataBase,
  MarketingTemplateFormData,
  MarketingTemplateStatus,
} from 'types/material/marketing';
import MarketingTemplateCollapse from './MarketingTemplateCollapse';

const MarketingTemplateBlock = styled.div``;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 16px;
`;

const StyledCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const MarketingTemplate = ({
  materialId,
  onChangeMarketingTemplateForms,
}: {
  materialId?: number;
  onChangeMarketingTemplateForms: React.Dispatch<
    React.SetStateAction<FormInstance<MarketingTemplateFormData>[]>
  >;
}) => {
  const [displayTypes, setDisplayTypes] = useState<MarketingDisplayType[]>([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [formForLimit] = Form.useForm();
  const [formForPublic] = Form.useForm();
  const [fileForPublic, setFileForPublic] = useState<MarketingFile>();
  const [fileForLimit, setFileForLimit] = useState<MarketingFile>();
  const history = useHistory();
  const {
    marketingTemplateData,
    getMarketingTemplateDataLoading,
    updateModeTemplateLimit,
    updateModeTemplatePublic,
    addMarketingTemplateLoading,
    updateMarketingTemplateLoading,
    onDownloadOnly,
    onComplete,
    onTempSave,
  } = useMarketingTemplate(materialId);
  const { rawMaterial } = useRawMaterial(materialId);

  useEffect(() => {
    const initialDisplayTypes = [];
    if (!!marketingTemplateData?.publicPages.length) {
      initialDisplayTypes.push(MarketingDisplayType.PUBLIC);
      formForPublic.setFieldsValue({
        displayType: MarketingDisplayType.PUBLIC,
        pages: marketingTemplateData?.publicPages,
      });
    }
    if (!!marketingTemplateData?.limitedPages.length) {
      initialDisplayTypes.push(MarketingDisplayType.LIMIT);
      formForLimit.setFieldsValue({
        displayType: MarketingDisplayType.LIMIT,
        pages: marketingTemplateData?.limitedPages,
      });
    }
    setDisplayTypes(initialDisplayTypes);
  }, [marketingTemplateData]);

  useEffect(() => {
    onChangeMarketingTemplateForms([formForLimit, formForPublic]);
  }, []);

  const setFile = (files: MarketingFile[]) => {
    files.forEach((fileData) => {
      fileData.displayType === MarketingDisplayType.PUBLIC
        ? setFileForPublic(fileData)
        : setFileForLimit(fileData);
    });
  };
  useEffect(() => {
    if (marketingTemplateData && rawMaterial?.isDiscontinued) {
      setFile(marketingTemplateData.files);
    }
  }, [marketingTemplateData, rawMaterial]);

  const handleCheckbox = (type: MarketingDisplayType) => {
    const selectedType = displayTypes.find(
      (displayType) => displayType === type,
    );
    if (selectedType) {
      const index = displayTypes.indexOf(selectedType);
      setDisplayTypes([
        ...displayTypes.slice(0, index),
        ...displayTypes.slice(index + 1),
      ]);
    } else {
      setDisplayTypes([...displayTypes, type]);
    }
  };

  const getFormData = (type: MarketingDisplayType): MarketingFormDataBase => {
    if (type === MarketingDisplayType.PUBLIC) {
      return formForPublic.getFieldsValue();
    } else {
      return formForLimit.getFieldsValue();
    }
  };

  const handleSubmit = async (status: MarketingTemplateStatus) => {
    for (let i = 0; i < displayTypes.length; i++) {
      const formData: MarketingFormDataBase = getFormData(displayTypes[i]);
      if (checkIsPageEmpty(formData)) {
        message.warn('빈 페이지가 존재합니다. 삭제 후 다시 시도해 주세요.');
        return;
      }
    }

    for (let i = 0; i < displayTypes.length; i++) {
      const formData: MarketingFormDataBase = getFormData(displayTypes[i]);
      const newFormData = {
        displayType: displayTypes[i],
        status,
        ...formData,
      };

      switch (status) {
        case MarketingTemplateStatus.TMP:
          await onTempSave(newFormData);
          break;
        case MarketingTemplateStatus.COM:
          await onComplete(newFormData);
          break;
      }
    }

    if (status === MarketingTemplateStatus.TMP) {
      message.success('임시 저장 되었습니다.');
    } else {
      message.success(
        `${
          updateModeTemplateLimit || updateModeTemplatePublic ? '수정' : '저장'
        }되었습니다.`,
      );
      history.goBack();
    }
  };

  const generateMarketingTemplateCollapse = (
    displayType: MarketingDisplayType,
  ) => {
    const form =
      displayType === MarketingDisplayType.PUBLIC
        ? formForPublic
        : formForLimit;
    return (
      <MarketingTemplateCollapse
        key={displayType}
        title={
          displayType === MarketingDisplayType.PUBLIC
            ? '공개형 템플릿'
            : '제한형 템플릿'
        }
        file={
          displayType === MarketingDisplayType.PUBLIC
            ? fileForPublic
            : fileForLimit
        }
        form={form}
        disabled={rawMaterial?.isDiscontinued}
        onDownloadOnly={() =>
          onDownloadOnly(
            {
              displayTypes: displayType,
              status: MarketingTemplateStatus.DO,
              ...form.getFieldsValue(),
            },
            setDownloadLoading,
          )
        }
        onChangeDownloadLoading={setDownloadLoading}
      />
    );
  };

  return (
    <MarketingTemplateBlock>
      <Spin
        spinning={
          getMarketingTemplateDataLoading ||
          addMarketingTemplateLoading ||
          updateMarketingTemplateLoading ||
          downloadLoading
        }
      >
        <StyledCheckboxGroup
          value={[...displayTypes]}
          disabled={rawMaterial?.isDiscontinued}
        >
          <StyledCheckBox
            value={MarketingDisplayType.PUBLIC}
            onChange={(event) => handleCheckbox(event.target.value)}
          >
            <Typography.Text type="TITLE_1">공개형</Typography.Text>
          </StyledCheckBox>
          <StyledCheckBox
            value={MarketingDisplayType.LIMIT}
            onChange={(event) => handleCheckbox(event.target.value)}
          >
            <Typography.Text type="TITLE_1">제한형</Typography.Text>
          </StyledCheckBox>
        </StyledCheckboxGroup>
        {displayTypes[0] && generateMarketingTemplateCollapse(displayTypes[0])}
        {displayTypes[1] && (
          <>
            <Margin size={40} />
            {generateMarketingTemplateCollapse(displayTypes[1])}
          </>
        )}
        {!rawMaterial?.isDiscontinued && (
          <FooterBox>
            {displayTypes.length !== 0 && (
              <>
                <Button
                  htmlType="submit"
                  onClick={() => handleSubmit(MarketingTemplateStatus.TMP)}
                >
                  임시 저장
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => handleSubmit(MarketingTemplateStatus.COM)}
                >
                  {updateModeTemplateLimit || updateModeTemplatePublic
                    ? '수정'
                    : '저장'}
                </Button>
              </>
            )}
          </FooterBox>
        )}
      </Spin>
    </MarketingTemplateBlock>
  );
};

export default MarketingTemplate;
