import { Col, Row, Select, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import SearchInput from 'components/system/form/SearchInput';
import Typography from 'components/system/general/Typography';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { useCertificates } from 'hook/certificate';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import {
  CertificateOngoingPageSearchParams,
  SearchType,
} from 'pages/certificate/CertificateOngoingPage';
import { DepositStatus } from 'types/estimate';

const CertificateOngoingBlock = styled.div`
  margin-top: 24px;
  .ant-table-thead > tr > th {
    padding: 10px 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 8px;
  }
`;

const CertificateOngoing = ({
  onChangeQueryParams,
  queryParams,
}: {
  queryParams: URLSearchParams;
  onChangeQueryParams: (
    queryParams: Partial<CertificateOngoingPageSearchParams>,
  ) => void;
}) => {
  const history = useHistory();
  const searchValue = queryParams.get('searchValue') || '';
  const searchType =
    (queryParams.get('searchType') as SearchType) || SearchType.ALL;
  const page = Number(queryParams.get('page')) || 1;

  const [searchTypeBuffer, setSearchTypeBuffer] = useState<SearchType>(
    searchType,
  );
  const { openCertificateProgressModal } = useModal();
  const { certificatesListItems, total, isLoading } = useCertificates({
    page,
    isFinish: false,
    searchType,
    searchValue,
  });

  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: 'all' },
      { label: '제품명', value: 'productNameEn' },
      {
        label: '국가명',
        value: 'countryNameKo',
      },
    ],
    [],
  );
  const showCertificate = (certificate: any) => {
    const { certTargetId } = certificate;
    history.push(`${path.certificate.ongoing}/${certTargetId}`);
  };

  const handleSearch = (searchValue: string) => {
    onChangeQueryParams({ page: 1, searchType: searchTypeBuffer, searchValue });
  };

  const handleChangePage = (page: number) => {
    onChangeQueryParams({ page });
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '인증신청번호 / 접수일',
        width: 200,
        align: 'center',
        render: (row) => (
          <Row style={{ flexDirection: 'column' }} align="middle">
            <Typography.Text medium type="BODY_2" color="GRAY90">
              {row.certRequestNo}
            </Typography.Text>
            <Typography.Text type="BODY_2" color="GRAY70">
              {`(${row.registerDt.slice(0, 10)})`}
            </Typography.Text>
          </Row>
        ),
      },
      {
        title: '제품명 (용량)',
        align: 'center',
        width: 310,
        ellipsis: true,
        render: (row) => (
          <Typography.Text
            type="BODY_2"
            onClick={() => showCertificate(row)}
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              color: palette.GRAY90,
              whiteSpace: 'pre-wrap',
            }}
          >
            {`${row.estimateTarget.productDetail.productNameEn} ${
              row.estimateTarget.netWeight
                ? `(${row.estimateTarget.netWeight} ${row.estimateTarget.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '국가',
        width: 102,
        align: 'center',
        render: (row: any) => (
          <Typography.Text type="BODY_2" color="GRAY70">
            {row.estimateTarget.countryName}
          </Typography.Text>
        ),
        onFilter: (value, record) =>
          record.estimateTarget.countryName.indexOf(value) === 0,
      },
      {
        title: '진행 단계',
        width: 100,
        align: 'center',
        render: (_, { status, statusString, estimateTarget }) => (
          <Typography.Text
            type="BODY_2"
            hover={status !== 'CER-CAN'}
            style={{
              cursor: status !== 'CER-CAN' ? 'pointer' : 'default',
              fontWeight: 500,
              color: palette.GRAY90,
            }}
            onClick={() =>
              status !== 'CER-CAN' &&
              openCertificateProgressModal({
                currentStep: status,
                countryName: estimateTarget.countryName,
              })
            }
          >
            {statusString}
          </Typography.Text>
        ),
        onFilter: (value, record) => record.status.indexOf(value) === 0,
      },
      {
        title: '입금 / 계약서',
        width: 108,
        align: 'center',
        render: ({ estimate: { depositStatus, isReceiveContractFinish } }) => (
          <Row
            style={{ flexDirection: 'column' }}
            align="middle"
            gutter={[4, 4]}
          >
            <Col>
              <Tags.Mark
                color={
                  depositStatus === DepositStatus.FINISH ? 'green' : 'lightGray'
                }
                style={{
                  width: depositStatus === DepositStatus.FINISH ? 95 : 85,
                }}
              >
                {depositStatus === DepositStatus.FINISH
                  ? '입금 확인 완료'
                  : '입금 확인 중'}
              </Tags.Mark>
            </Col>
            <Col>
              <Tags.Mark
                color={isReceiveContractFinish ? 'green' : 'lightGray'}
                style={{
                  width: isReceiveContractFinish ? 95 : 85,
                }}
              >
                {isReceiveContractFinish
                  ? '계약서 수령 완료'
                  : '계약서 수령 전'}
              </Tags.Mark>
            </Col>
          </Row>
        ),
      },
      {
        title: '처리 필요',
        width: 100,
        align: 'center',
        render: (row, { estimate: { estimateAdditionals }, status }) => (
          <Row
            style={{ flexDirection: 'column', width: 88 }}
            align="middle"
            gutter={[4, 4]}
          >
            {row.etcStatus && status !== 'CER-CAN' && (
              <Col>
                <Tags.Mark
                  type="arrow"
                  color="blue"
                  onClick={() => showCertificate(row)}
                >
                  입력·보완
                </Tags.Mark>
              </Col>
            )}
            {estimateAdditionals && (
              <Col>
                <Tags.Mark
                  type="arrow"
                  color="blue"
                  onClick={() => history.push(`${path.my}/estimate`)}
                >
                  추가 견적
                </Tags.Mark>
              </Col>
            )}
          </Row>
        ),
      },
    ],
    [],
  );

  return (
    <CertificateOngoingBlock>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Select
          options={searchTypeOptions}
          value={searchTypeBuffer}
          onChange={setSearchTypeBuffer}
          style={{ width: 100 }}
        />
        <SearchInput
          placeholder="검색"
          defaultValue={searchValue}
          onSearch={handleSearch}
          style={{ width: 200 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={certificatesListItems}
        rowKey="certTargetId"
        pagination={{
          current: page,
          pageSize: 10,
          total,
          onChange: handleChangePage,
        }}
        loading={isLoading}
        style={{ marginTop: 24 }}
        scroll={{ x: 768 }}
      />
    </CertificateOngoingBlock>
  );
};

export default CertificateOngoing;
