export enum TermsType {
  SERVICE_BRAND = 'SERVICE_BRAND', // 브랜드사 이용 약관
  SERVICE_MANUFACTURER = 'SERVICE_MANUFACTURER', // 제조사 이용 약관
  SERVICE_MATERIAL = 'SERVICE_MATERIAL', // 원료사 이용 약관
  PRIVACY_SERVICE_BRAND = 'PRIVACY_SERVICE_BRAND', // 브랜드사 개인정보 수집·이용
  PRIVACY_SERVICE_MANUFACTURER = 'PRIVACY_SERVICE_MANUFACTURER', // 제조사 개인정보 수집·이용
  PRIVACY_SERVICE_MATERIAL = 'PRIVACY_SERVICE_MATERIAL', // 원료사 개인정보 수집·이용
  PRIVACY_THIRD_PARTY_BRAND = 'PRIVACY_THIRD_PARTY_BRAND', // 브랜드사 개인정보 제3자 제공
  PRIVACY_THIRD_PARTY_MANUFACTURER = 'PRIVACY_THIRD_PARTY_MANUFACTURER', // 제조사 개인정보 제3자 제공
  PRIVACY_THIRD_PARTY_MATERIAL = 'PRIVACY_THIRD_PARTY_MATERIAL', // 원료사 개인정보 제3자 제공
  PRIVACY_PROCESS_BRAND = 'PRIVACY_PROCESS_BRAND', // 브랜드사 개인정보 처리방침
  PRIVACY_PROCESS_MANUFACTURER = 'PRIVACY_PROCESS_MANUFACTURER', // 제조사 개인정보 처리방침
  PRIVACY_PROCESS_MATERIAL = 'PRIVACY_PROCESS_MATERIAL', // 원료사 개인정보 처리방침
  CI_BI_SERVICE_BRAND = 'CI_BI_SERVICE_BRAND', // 브랜드사 브랜드명, 회사명, 로고, 슬로건의 사용 및 게시에 관한 약정 동의
  CI_BI_SERVICE_MANUFACTURER = 'CI_BI_SERVICE_MANUFACTURER', // 제조사 브랜드명, 회사명, 로고, 슬로건의 사용 및 게시에 관한 약정 동의
  CI_BI_SERVICE_MATERIAL = 'CI_BI_SERVICE_MATERIAL', // 원료사 브랜드명, 회사명, 로고, 슬로건의 사용 및 게시에 관한 약정 동의
}

export interface Terms {
  serviceTermId: number;
  version: string;
  termType: TermsType;
  isDisplay: boolean;
  content: string;
}
