import { Button, Form, Input, Modal, Select, message } from 'antd';
import { omit } from 'lodash';
import { useEffect } from 'react';
import styled from 'styled-components';

import { FileUpload, Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { generateFormRules } from 'lib/validate';
import { useUploadBrandFiles } from 'service/common';
import {
  useManufacturerCompanies,
  useManufacturerDetail,
} from 'service/manufacturer/company';
import { UploadBrandFile } from 'types/common';
import {
  ManufacturerForm,
  ManufacturerFormFile,
} from 'types/manufacturer/company';

const ManufacturerFormModal = ({
  onClose,
  companyManufacturerId,
}: {
  onClose: VoidFunction;
  companyManufacturerId?: number;
}) => {
  const updateMode = companyManufacturerId !== undefined;
  const [form] = Form.useForm<ManufacturerForm>();
  const {
    data: manufacturerCompanies = [],
    isLoading,
  } = useManufacturerCompanies();
  const {
    addManufacturer,
    addManufacturerLoading,
    manufacturerDetail,
    updateManufacturer,
    updateManufacturerLoading,
  } = useManufacturerDetail({
    companyManufacturerId,
  });
  const {
    isUploadBrandFilesLoading,
    uploadBrandFilesAsync,
  } = useUploadBrandFiles();

  const handleSumbmit = async (formValues: ManufacturerForm) => {
    const formValuesExceptFiles = omit(formValues, [
      'businessRegisters',
      'cosmeticRegisters',
      'isoAttaches',
      'etcAttaches',
    ]);

    const newFiles = [
      formValues.businessRegisters?.filter(
        (item): item is File => item instanceof File,
      ) || [],
      formValues.cosmeticRegisters?.filter(
        (item): item is File => item instanceof File,
      ) || [],
      formValues.isoAttaches?.filter(
        (item): item is File => item instanceof File,
      ) || [],
      formValues.etcAttaches?.filter(
        (item): item is File => item instanceof File,
      ) || [],
    ];

    const allResponses = await Promise.all(
      newFiles.map((attachFiles) =>
        attachFiles.length > 0
          ? uploadBrandFilesAsync({
              attachFiles,
              attachType: 'MANUFACTURE_ATTACH',
            })
          : Promise.resolve({ data: { result: [] as UploadBrandFile[] } }),
      ),
    );

    const [
      businessRegisters,
      cosmeticRegisters,
      isoAttaches,
      etcAttaches,
    ] = allResponses.map((res) => res.data.result);

    if (!updateMode) {
      addManufacturer(
        {
          ...formValuesExceptFiles,
          attach: {
            businessRegisters,
            cosmeticRegisters,
            isoAttaches,
            etcAttaches,
          },
        },
        {
          onSuccess: () => {
            message.success('등록되었습니다.');
            onClose();
          },
        },
      );
      return;
    }

    const registeredFiles = [
      formValues.businessRegisters?.filter(
        (item): item is ManufacturerFormFile => !(item instanceof File),
      ) || [],
      formValues.cosmeticRegisters?.filter(
        (item): item is ManufacturerFormFile => !(item instanceof File),
      ) || [],
      formValues.isoAttaches?.filter(
        (item): item is ManufacturerFormFile => !(item instanceof File),
      ) || [],
      formValues.etcAttaches?.filter(
        (item): item is ManufacturerFormFile => !(item instanceof File),
      ) || [],
    ];

    const isChanged = {
      businessRegisters:
        businessRegisters.length > 0 ||
        (manufacturerDetail?.attach.businessRegisters
          ? registeredFiles[0].length !==
            manufacturerDetail.attach.businessRegisters.length
          : false),
      cosmeticRegisters:
        cosmeticRegisters.length > 0 ||
        (manufacturerDetail?.attach.cosmeticRegisters
          ? registeredFiles[1].length !==
            manufacturerDetail.attach.cosmeticRegisters.length
          : false),
      isoAttaches:
        isoAttaches.length > 0 ||
        (manufacturerDetail?.attach.isoAttaches
          ? registeredFiles[2].length !==
            manufacturerDetail.attach.isoAttaches.length
          : false),
      etcAttaches:
        etcAttaches.length > 0 ||
        (manufacturerDetail?.attach.etcAttaches
          ? registeredFiles[3].length !==
            manufacturerDetail.attach.etcAttaches.length
          : false),
    };

    const changedValues = getChangedValues({
      obj1: {
        ...omit(manufacturerDetail, 'attach'),
      },
      obj2: {
        ...formValuesExceptFiles,
        companyManufacturerId: manufacturerDetail?.companyManufacturerId,
      },
    });

    const isFileChanged =
      Object.values(isChanged).filter((value) => !!value).length > 0;

    if (
      Object.values(changedValues).filter((value) => value !== undefined)
        .length === 0 &&
      !isFileChanged
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
    } else {
      const getFinalFileArray = (
        originalFiles: (File | ManufacturerFormFile)[],
        transformedFiles: UploadBrandFile[],
      ) => {
        const result: UploadBrandFile[] = [];
        let i = 0;

        originalFiles?.forEach((item) => {
          if (item instanceof File) {
            result.push(transformedFiles[i]);
            i++;
          } else {
            result.push({
              filename: item.name,
              size: item.size,
              url: item.url,
              updateDt: item.updateDt,
            });
          }
        });

        return result;
      };

      updateManufacturer(
        {
          ...changedValues,
          ...(isFileChanged && {
            attach: {
              ...(isChanged.businessRegisters && {
                businessRegisters: getFinalFileArray(
                  formValues.businessRegisters,
                  businessRegisters,
                ),
              }),
              ...(isChanged.cosmeticRegisters && {
                cosmeticRegisters: getFinalFileArray(
                  formValues.cosmeticRegisters,
                  cosmeticRegisters,
                ),
              }),
              ...(isChanged.isoAttaches && {
                isoAttaches: getFinalFileArray(
                  formValues.isoAttaches,
                  isoAttaches,
                ),
              }),
              ...(isChanged.etcAttaches && {
                etcAttaches: getFinalFileArray(
                  formValues.etcAttaches,
                  etcAttaches,
                ),
              }),
            },
          }),
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            onClose();
          },
        },
      );
    }
  };

  const convertToFileForm = (file: UploadBrandFile) => {
    return {
      size: file.size,
      name: file.filename,
      url: file.url,
    };
  };

  useEffect(() => {
    if (manufacturerDetail) {
      const {
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressKo,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerFax,
        nickname,
        attach,
      } = manufacturerDetail;

      form.setFieldsValue({
        manufacturerId,
        ...(manufacturerNameEn && { manufacturerNameEn }),
        ...(manufacturerAddressKo && { manufacturerAddressKo }),
        ...(manufacturerAddressEn && { manufacturerAddressEn }),
        manufacturerTel,
        manufacturerFax,
        ...(nickname && { nickname }),
        ...(attach.businessRegisters.length > 0 && {
          businessRegisters: attach.businessRegisters.map((item) =>
            convertToFileForm(item),
          ),
        }),
        ...(attach.cosmeticRegisters.length > 0 && {
          cosmeticRegisters: attach.cosmeticRegisters.map((item) =>
            convertToFileForm(item),
          ),
        }),
        ...(attach.isoAttaches.length > 0 && {
          isoAttaches: attach.isoAttaches.map((item) =>
            convertToFileForm(item),
          ),
        }),
        ...(attach.etcAttaches.length > 0 && {
          etcAttaches: attach.etcAttaches.map((item) =>
            convertToFileForm(item),
          ),
        }),
      });
    }
  }, [manufacturerDetail]);

  return (
    <StyledModal
      title={
        <Flex align="center" justify="space-between">
          <Typography.Text type="TITLE_2" medium>
            {updateMode ? '제조사 수정' : '제조사 추가'}
          </Typography.Text>
          <Icon name="close" size={24} onClick={onClose} />
        </Flex>
      }
      visible={true}
      width={600}
      centered
      closable={false}
      footer={false}
      bodyStyle={{
        padding: '24px 24px 32px 36px',
      }}
    >
      <Form layout="vertical" form={form} onFinish={handleSumbmit}>
        <div
          style={{
            maxHeight: 615,
            overflowY: 'scroll',
            scrollMargin: '10px !important',
          }}
        >
          <Form.Item
            label="별칭"
            name="nickname"
            rules={generateFormRules({ maxLength: 10 })}
          >
            <StyledInput autoComplete="off" placeholder="별칭 입력" />
          </Form.Item>
          <Form.Item
            label={
              <>
                화장품제조업자
                <Tip
                  style={{ marginLeft: 4, textAlign: 'left' }}
                  trigger="click"
                >
                  <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                    영문 예시 : <span className="primary">CDRI CO., LTD.</span>
                    <br />
                    (cGMP 또는 ISO 22716에 등록된 영문과 동일하게 입력)
                  </Typography.Text>
                </Tip>
              </>
            }
            required
            colon={false}
            style={{ marginBottom: 8 }}
          >
            <Form.Item
              name="manufacturerId"
              rules={generateFormRules({
                required: true,
              })}
            >
              <Select
                loading={isLoading}
                placeholder="국문명 선택"
                showSearch
                filterOption={(keyword, option) =>
                  (option?.label &&
                    typeof option.label === 'string' &&
                    option.label.includes(keyword)) ||
                  false
                }
                options={manufacturerCompanies.map(
                  ({ companyNameKo, manufacturerId }) => ({
                    label: companyNameKo,
                    value: manufacturerId,
                  }),
                )}
                style={{ maxWidth: 520 }}
              />
            </Form.Item>
            <Form.Item
              name="manufacturerNameEn"
              rules={generateFormRules({
                exceptKorean: true,
                maxLength: 255,
              })}
            >
              <StyledInput
                autoComplete="off"
                placeholder="cGMP 또는 ISO22716 보유시 같은 영문"
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="화장품제조업자 주소"
            required
            colon={false}
            style={{ marginBottom: 8 }}
          >
            <Form.Item
              name="manufacturerAddressKo"
              dependencies={['manufacturerAddressEn']}
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (!value && !getFieldValue('manufacturerAddressEn')) {
                      return Promise.reject(new Error('필수 항목'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <StyledInput autoComplete="off" placeholder="국문주소 입력" />
            </Form.Item>
            <Form.Item
              name="manufacturerAddressEn"
              dependencies={['manufacturerAddressKo']}
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (!value && !getFieldValue('manufacturerAddressKo')) {
                      return Promise.reject(new Error('필수 항목'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <StyledInput autoComplete="off" placeholder="영문주소 입력" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="화장품제조업자 연락처"
            required
            style={{ marginBottom: 8 }}
          >
            <Flex gap={8} style={{ maxWidth: 520 }}>
              <Form.Item
                style={{ flex: 1 }}
                name={'manufacturerTel'}
                rules={generateFormRules({
                  required: true,
                  onlyNumber: true,
                  minLength: 8,
                  maxLength: 12,
                })}
              >
                <Input placeholder="전화번호 입력" />
              </Form.Item>
              <Form.Item
                name={'manufacturerFax'}
                rules={generateFormRules({
                  onlyNumber: true,
                  minLength: 8,
                  maxLength: 12,
                })}
                style={{ flex: 1 }}
              >
                <Input placeholder="팩스번호 입력" />
              </Form.Item>
            </Flex>
          </Form.Item>
          <Form.Item label="제조사 사업자등록증" name="businessRegisters">
            <FileUpload
              readOnly={false}
              isView={false}
              text="파일 업로드"
              accept="all"
              deletable
              multiple
              max={1}
            />
          </Form.Item>
          <Form.Item label="화장품제조업 등록필증" name="cosmeticRegisters">
            <FileUpload
              readOnly={false}
              isView={false}
              text="파일 업로드"
              deletable
              accept="all"
              multiple
              max={1}
            />
          </Form.Item>
          <Form.Item label="ISO 인증 서류" name="isoAttaches">
            <FileUpload
              multiple
              readOnly={false}
              isView={false}
              text="파일 업로드"
              deletable
              accept="all"
              max={5}
            />
          </Form.Item>
          <Form.Item label="기타 서류" name="etcAttaches">
            <FileUpload
              multiple
              readOnly={false}
              isView={false}
              text="파일 업로드"
              accept="all"
              deletable
              max={5}
            />
          </Form.Item>
        </div>
        <Flex align="center" justify="center" gutter={{ top: 24 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={
              addManufacturerLoading ||
              updateManufacturerLoading ||
              isUploadBrandFilesLoading
            }
            style={{ height: 44, width: 73 }}
          >
            {updateMode ? '수정' : '추가'}
          </Button>
        </Flex>
      </Form>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-col {
    max-width: 520px !important;
  }
`;

const StyledInput = styled(Input)`
  max-width: 520px;
`;

export default ManufacturerFormModal;
