import { Badge, Divider, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import path from 'lib/path';
import styled, { css } from 'styled-components';
import palette from 'lib/styles/palette';
import Icon from 'components/ui/Icon/Icon';

const TopSubMenuBlock = styled.div<{ is_inline_mode: 'true' | 'false' }>`
  ${(props) =>
    props.is_inline_mode === 'false' &&
    css`
      .ant-menu-item {
        line-height: 77px;
      }
    `}

  .ant-badge {
    vertical-align: middle;
  }
`;

const TopSubMenu = ({
  inlineMode,
  newUserNoticeCount,
  isLoggedIn,
  logout,
  toggleDrawer,
  showUserNoticeDrawer,
}: any) => {
  const { pathname } = useLocation();
  return (
    <TopSubMenuBlock is_inline_mode={inlineMode ? 'true' : 'false'}>
      {inlineMode && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
      <Menu
        mode={inlineMode ? 'inline' : 'horizontal'}
        selectedKeys={[pathname]}
        style={{ borderRight: 'none' }}
        onClick={() => {
          inlineMode && toggleDrawer();
        }}
      >
        {isLoggedIn ? (
          <>
            {!inlineMode && (
              <Menu.Item
                key="userNotice"
                onClick={showUserNoticeDrawer}
                style={{
                  marginLeft: 0,
                  marginRight: 30,
                  position: 'relative',
                }}
              >
                <Badge
                  dot={newUserNoticeCount > 0}
                  style={{
                    top: -2,
                    right: -2,
                    width: 5,
                    height: 5,
                    minWidth: 5,
                  }}
                >
                  <Icon
                    name="bell"
                    size={18}
                    onClick={showUserNoticeDrawer}
                    style={{ position: 'relative', top: -2, cursor: 'pointer' }}
                  />
                </Badge>
              </Menu.Item>
            )}
            <Menu.Item key={path.my} style={{ margin: 0, marginRight: 18 }}>
              <Link to={path.my} style={{ color: palette.GRAY70 }}>
                마이페이지
              </Link>
            </Menu.Item>
            <Menu.Item
              key="logout"
              onClick={logout}
              style={{ margin: 0, color: palette.GRAY70 }}
            >
              로그아웃
            </Menu.Item>
          </>
        ) : (
          <Menu.Item key="login">
            <Link to={path.login} style={{ color: palette.GRAY70 }}>
              로그인
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </TopSubMenuBlock>
  );
};

export default TopSubMenu;
