import { Modal, Typography } from 'antd';
import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const StyledModal = styled(Modal)<{ slideIdx?: number }>`
  border-radius: 16px;
  position: relative;

  .ant-modal-content {
    border-radius: 16px;
    height: 480px;
    border: none;
    position: relative;
  }

  .ant-modal-header {
    height: min-content !important;
    border: none;
    border-radius: 16px 16px 0 0;
    padding-top: 24px !important;
    padding-bottom: 0 !important;
    .ant-modal-title {
      font-size: 18px !important;
      font-weight: 400 !important;
    }
  }

  .ant-modal-close-x {
    width: 50px;
  }

  .ant-modal-body {
    padding: 44px 24px 18px 24px !important;
    white-space: pre-wrap !important;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 32px;

    button {
      padding: 12px 16px !important;
      height: 44px !important;
      border-radius: 4px;
      font-size: 14px !important;
      align-items: center !important;
      display: flex !important;
      justify-content: center !important;
    }
  }

  .ant-form-item {
    padding-left: 16px;
  }

  .splide__pagination__page {
    background-color: ${palette.GRAY30};
    margin: 6px;
  }

  .splide__pagination__page.is-active {
    background-color: ${palette.PRIMARY50};
    opacity: 1;
    transform: scale(1);
  }

  .splide__slide {
    position: relative;
    background-color: white;
  }

  .splide__pagination {
    position: absolute;
    bottom: -58px;
  }

  .splide__arrows {
    opacity: 1;
    padding: 0 8px;

    &:hover {
      opacity: 1;
    }
    display: flex;
    justify-content: space-between;

    position: absolute;
    width: 100%;
    bottom: -60px;

    .splide__arrow--prev {
      color: ${palette.SLATE_GRAY60};
      font-weight: 400;
      &:hover {
        cursor: pointer;
      }
      ${({ slideIdx }) =>
        (slideIdx === 0 || slideIdx === undefined) &&
        css`
          opacity: 0;
          pointer-events: none;
          &:hover {
            cursor: default;
          }
        `}
    }
    .splide__arrow--next {
      color: ${palette.PRIMARY50};
      font-weight: 400;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const SlideDiv = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  position: relative;
`;

const ImgContainer = styled(Flex)`
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 12px;
  height: 200px;
  width: 296px;
`;

const DescriptionConatiner = styled(Flex)`
  background-color: white;
`;

const Description = styled(Typography.Text)`
  letter-spacing: 0;
  display: flex;
  text-align: center;
  color: ${palette.SLATE_GRAY70};
`;

export interface StepModalContentItem {
  imgSrc: string;
  title: React.ReactNode;
  description: React.ReactNode;
  imageAlt: string;
  imgSize: number;
}

export interface StepModalProps {
  onClose?: () => void;
  modalContentArr: StepModalContentItem[];
}
const StepModal = ({ onClose, modalContentArr }: StepModalProps) => {
  const [slideIdx, setSlideIdx] = useState<number | undefined>();

  useEffect(() => {
    const next_arrow = document.querySelector('.splide__arrow--next');
    if (!next_arrow) return;
    if (slideIdx === 2) {
      next_arrow.classList.add('end_arrow');
    }

    const end_arrow = document.querySelector('.end_arrow');
    const modalClose = () => {
      if (slideIdx === 2) {
        onClose?.();
        window.localStorage.setItem('SF_MODAL_CLOSE', 'true');
      }
    };
    end_arrow?.addEventListener('click', modalClose);
    return () => end_arrow?.removeEventListener('click', modalClose);
  }, [slideIdx]);

  return (
    <StyledModal
      centered
      width={360}
      visible={true}
      onCancel={onClose}
      footer={null}
      closeIcon={
        <Icon
          name="smallClose"
          size={20}
          color="GRAY60"
          style={{ margin: '0 auto', height: 50 }}
        />
      }
      slideIdx={slideIdx}
    >
      <Splide
        renderControls={() => (
          <div className="splide__arrows">
            <div className="splide__arrow--prev" role="button">
              이전
            </div>
            <div className="splide__arrow--next" role="button">
              {slideIdx === 2 ? '확인' : '다음'}
            </div>
          </div>
        )}
        onMove={(_, newIndex) => {
          setSlideIdx(newIndex);
        }}
        options={{
          interval: 3000,
          perMove: 1,
          focus: 'center',
          gap: '16px',
          drag: false,
        }}
      >
        {modalContentArr.map((content, idx) => (
          <SplideSlide key={idx}>
            <Flex justify="center">
              <SlideDiv align="cneter" justify="center">
                <ImgContainer align="center" justify="center">
                  <img
                    width={content.imgSize}
                    src={content.imgSrc}
                    alt={content.imageAlt}
                  />
                </ImgContainer>
                <DescriptionConatiner dir="column" align="center" gap={16}>
                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      color: palette.GRAY90,
                      textAlign: 'center',
                    }}
                  >
                    {content.title}
                  </Typography.Text>
                  <Description>
                    <Typography.Text
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        letterSpacing: 0,
                        color: palette.SLATE_GRAY70,
                      }}
                    >
                      {content.description}
                    </Typography.Text>
                  </Description>
                </DescriptionConatiner>
              </SlideDiv>
            </Flex>
          </SplideSlide>
        ))}
      </Splide>
    </StyledModal>
  );
};

export default StepModal;
