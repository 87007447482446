import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import Error404Page from 'pages/error/Error404Page';
import { DocumentCode } from 'types/material/icid/icid';
import {
  MSDSPage,
  TDSPage,
  CompositionPage,
  COAPage,
  MFCPage,
} from 'pages/material/icid';
import ICIDOngoingPage from 'pages/material/icid/ICIDOngoingPage';
import ICIDRegisteredPage from 'pages/material/icid/ICIDRegisteredPage';
import ICIDBasicPage from 'pages/material/icid/ICIDBasicPage';
import ICIDDocumentListPage from 'pages/material/icid/ICIDDocumentListPage';
import ICIDDetailPage from 'pages/material/icid/ICIDDetailPage';
import ICIDRequestPage from 'pages/material/icid/ICIDRequestPage';
import ICIDRequestListPage from 'pages/material/icid/ICIDRequestListPage';
import ICIDContractPage from 'pages/material/icid/ICIDContractPage';
import ICIDRegisteredDetailPage from 'pages/material/icid/ICIDRegisteredDetailPage';

const ICIDRouter = () => {
  return (
    <Switch>
      <Route
        path={path.material.icid.request}
        component={ICIDRequestPage}
        exact
      />
      <Route
        path={[
          path.material.icid.basic,
          `${path.material.icid.root}/:icidApplicationId/${DocumentCode.BASIC}`,
        ]}
        component={ICIDBasicPage}
      />
      <Route
        path={path.material.icid.requestList}
        component={ICIDRequestListPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/contract`}
        component={ICIDContractPage}
      />
      <Route
        path={path.material.icid.registered}
        component={ICIDRegisteredPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/registered`}
        component={ICIDRegisteredDetailPage}
      />
      <Route path={path.material.icid.ongoing} component={ICIDOngoingPage} />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId`}
        component={ICIDDocumentListPage}
        exact
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/${DocumentCode.DETAIL}`}
        component={ICIDDetailPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/${DocumentCode.MSDS}`}
        component={MSDSPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/${DocumentCode.TDS}`}
        component={TDSPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/${DocumentCode.MCI}`}
        component={CompositionPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/${DocumentCode.COA}`}
        component={COAPage}
      />
      <Route
        path={`${path.material.icid.root}/:icidApplicationId/${DocumentCode.MFC}`}
        component={MFCPage}
      />

      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default ICIDRouter;
