import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';

import { s3AssetDomain } from 'lib/consts';
import { deviceSize } from 'lib/styles';
import { Body1, Body3, H1, H3, LogoText } from './introStyles';
import { Flex } from 'components/ui';
import useIntersectionObserver from 'hook/useIntersectionObserver';

const Container = styled.div`
  height: 1199px;
  background-image: url(${s3AssetDomain}/intro_security.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${deviceSize.sm} {
    height: auto;
    background-image: url(${s3AssetDomain}/intro_security_m.png);
  }
`;

const InnerContainer = styled.div`
  max-width: 1200px;
  padding: 150px 24px;
  margin: 0 auto;

  @media ${deviceSize.sm} {
    padding: 70px 24px;
  }
`;

const RelativeWrapper = styled.div`
  margin-top: 30px;
  position: relative;
`;

const SecurityInfosWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  white-space: pre-wrap;

  @media (max-width: 1224px) {
    gap: 16px;
  }

  @media ${deviceSize.sm} {
    flex-direction: column;
    position: static;
    margin-top: 69px;
  }
`;

const SecurityInfoWrapper = styled.div<{
  $visibility: boolean;
  isIntersecting: boolean;
  isAnimated: boolean;
}>`
  padding: 30px;
  border-radius: 10px;
  opacity: 0;
  width: 278px;
  height: 228px;
  background: #fff;
  flex: 1 0 calc(25% - 30px);
  visibility: ${({ $visibility }) => ($visibility ? 'visible' : 'hidden')};
  transform: translateY(30%);
  transition: all 1s ease;

  ${({ isIntersecting }) =>
    isIntersecting &&
    css`
      opacity: 0.9;
      transform: translateY(0);
    `}

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      opacity: 0.9;
      transform: translateY(0);
    `}

  @media (max-width: 1224px) {
    padding: 18px;
  }

  @media ${deviceSize.sm} {
    display: ${({ $visibility }) => ($visibility ? 'block' : 'none')};
    padding: 24px;
    flex: unset;
    width: 100%;
    height: 162px;
  }
`;

const SecurityInfoTitle = styled(Body1)`
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;

  @media (max-width: 1224px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceSize.sm} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const SecurityInfoDescription = styled(Body3)`
  margin-top: 18px;
  letter-spacing: -0.5px;

  @media (max-width: 1224px) {
    margin-top: 14px;
    font-size: 14px;
    line-height: 20px;
  }

  @media ${deviceSize.sm} {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const SectionJ = () => {
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  const securityInfos: { title: string; description: string }[] = useMemo(
    () => [
      {
        title: '',
        description: '',
      },
      {
        title: '',
        description: '',
      },
      {
        title: '개인 정보 및\n민감 데이터 암호화',
        description: isMobile
          ? `AES256 암호화 기술을 활용한\n강력한 데이터베이스 데이터 암호화`
          : `AES256 암호화 기술을 활용한\n강력한 데이터베이스\n데이터 암호화`,
      },
      {
        title: '데이터 통신 암호화',
        description: isMobile
          ? `SSL을 통한 종단간 네트워크 암호화로\n데이터 패킷 탈취 불가능`
          : `SSL을 통한\n종단간 네트워크 암호화로\n데이터 패킷 탈취 불가능`,
      },
      {
        title: '',
        description: '',
      },
      {
        title: `블록체인을 활용한\n보안 강화 및 위변조 방지`,
        description: `핵심 서비스 영역에 Private Blockchain 기술${
          isMobile ? '\n' : ' '
        }적용을 통한${
          isMobile ? ' ' : '\n'
        }접근 통제를 수행하고, 블록체인의 특징인 위변조 방지 기능 적용`,
      },
      {
        title: isMobile
          ? `아마존 클라우드 웹서비스를\n활용한 접근 통제`
          : `아마존 클라우드\n웹서비스를 활용한\n접근 통제`,
        description: isMobile
          ? 'AWS로 DDOS 공격 방지, 서버 접근 통제,\n주기적인 취약점 점검 및 보완'
          : 'AWS로 DDOS 공격 방지, 서버\n접근 통제, 주기적인 취약점\n점검 및 보완',
      },
      {
        title: '감사 로깅',
        description: isMobile
          ? '서비스내 주요 데이터 수정에 대한 로그\n제공으로 데이터 수정자에 대한 기록 유지'
          : '서비스내 주요 데이터\n수정에 대한 로그 제공으로\n데이터 수정자에 대한 기록 유지',
      },
      {
        title: '모든 사내 인력에 대해\n비밀유지계약 체결',
        description: isMobile
          ? '사내 모든 서비스 개발 및\n운영 인력에 대한 비밀유지계약 체결'
          : '사내 모든 서비스 개발 및\n운영 인력에 대한\n비밀유지계약 체결',
      },
      {
        title: '주기적인\n보안 교육 실시',
        description: isMobile
          ? '매년 보안에 대한 교육을 통한 경각심 유지'
          : '매년 보안에 대한 교육을 통한\n경각심 유지',
      },
      {
        title: '민감 데이터베이스\n데이터 접근 통제',
        description: '정보보호관리자 이외\n상용 DB 접근 불가 정책 실행',
      },
      {
        title: isMobile ? '개인 업무 PC 암호 설정' : '개인 업무 PC\n암호 설정',
        description: isMobile
          ? '모든 사내 인력의 PC에 대해\n암호 설정 및 바이러스 백신 설치 정책 실행'
          : '모든 사내 인력의 PC에 대해\n암호 설정 및 바이러스 백신 설치\n정책 실행',
      },
    ],
    [isMobile],
  );

  return (
    <Container>
      <InnerContainer>
        <H1 color="WHITE">
          <LogoText>certicos</LogoText>의 모든 데이터는
          <br />
          안전하게 보호됩니다.
        </H1>
        <RelativeWrapper>
          <H3 color="WHITE" style={{ opacity: 0.8 }}>
            안심하고 사용하세요.
          </H3>
          <SecurityInfosWrapper gap={30} wrap="true">
            {securityInfos.map(({ title, description }, index) => (
              <SecurityInfo
                key={index}
                title={title}
                description={description}
                index={index}
              />
            ))}
          </SecurityInfosWrapper>
        </RelativeWrapper>
      </InnerContainer>
    </Container>
  );
};

const SecurityInfo = ({
  index,
  title,
  description,
}: {
  index: number;
  title: string;
  description: string;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.7,
  });

  useEffect(() => {
    if (isIntersecting) {
      setIsAnimated(true);
    }
  }, [isIntersecting]);

  return (
    <SecurityInfoWrapper
      ref={target}
      key={index}
      $visibility={title !== ''}
      isIntersecting={isIntersecting}
      isAnimated={isAnimated}
    >
      <SecurityInfoTitle>{title}</SecurityInfoTitle>
      <SecurityInfoDescription>{description}</SecurityInfoDescription>
    </SecurityInfoWrapper>
  );
};

export default SectionJ;
