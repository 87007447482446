import { Modal } from 'antd';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  DetectionProhibitIngredientsOfDistributorItem,
  IngredientDetectionRegulationItem,
  LanguageCode,
} from 'service/brand/formulaScreening/formulaScreening';

const SeparateStoreRegulationsModal = ({
  detectionProhibitIngredientsOfDistributor,
  onClose,
}: {
  detectionProhibitIngredientsOfDistributor: DetectionProhibitIngredientsOfDistributorItem[];
  onClose: () => void;
}) => {
  const tabRef = useRef<HTMLDivElement>(null);
  const collapseRef = useRef<HTMLDivElement>(null);
  const tabs = detectionProhibitIngredientsOfDistributor
    .filter((item) => !!item.ingredientDetectionRegulation)
    .map(({ distributor }) => ({
      label: distributor.distributorName || '',
      value: distributor.distributorId,
    }));
  const [selectedDistributorId, setSelectedDistributorId] = useState(
    tabs[0].value,
  );
  const [ingredientsInfo, setIngredientsInfo] = useState<{
    [distributorId: number]: (IngredientDetectionRegulationItem & {
      isOpen: boolean;
    })[];
  }>();

  const getTypeText = (type: 'LIMIT' | 'PROHIBIT') => {
    switch (type) {
      case 'PROHIBIT':
        return (
          <Typography.Text color="MESSAGE_ERROR" inline>
            [금지]
          </Typography.Text>
        );
      case 'LIMIT':
        return (
          <Typography.Text color="MESSAGE_CHECK" inline>
            [제한]
          </Typography.Text>
        );
    }
  };

  useEffect(() => {
    const ingredientsInfo: {
      [distributorId: number]: (IngredientDetectionRegulationItem & {
        isOpen: boolean;
      })[];
    } = {};

    detectionProhibitIngredientsOfDistributor
      .filter((item) => !!item.ingredientDetectionRegulation)
      .forEach((item) => {
        const distributorId = item.distributor.distributorId;
        ingredientsInfo[distributorId] = item.ingredientDetectionRegulation.map(
          (regulation) => ({
            ...regulation,
            isOpen: false,
          }),
        );
      });

    setIngredientsInfo(ingredientsInfo);
  }, [detectionProhibitIngredientsOfDistributor]);

  useLayoutEffect(() => {
    if (tabRef.current && collapseRef.current) {
      const tabHeight = tabRef.current.clientHeight;

      // ModalBody 높이 - ModalBody 패딩 - gap - tab 높이
      collapseRef.current.style.maxHeight = `${502 - 56 - 24 - tabHeight}px`;
    }
  }, []);

  return (
    <Modal
      visible={true}
      width={896}
      onCancel={onClose}
      closable={false}
      centered={true}
      footer={null}
      bodyStyle={{ padding: 0, height: 560 }}
    >
      <ModalHeader justify="space-between" align="center">
        <Typography.Text type="TITLE_2" semiBold>
          스토어 별도 규제사항
        </Typography.Text>
        <Icon name="close" size={24} onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex gap={8} wrap="true" ref={tabRef}>
          {tabs.map(({ label, value }) => (
            <Tab
              label={label}
              key={value}
              isActive={value === selectedDistributorId}
              onClick={() => setSelectedDistributorId(value)}
            >
              {label}
            </Tab>
          ))}
        </Flex>
        <Collapse
          ref={collapseRef}
          dir="column"
          align="stretch"
          gap={8}
          gutter={{ top: 24 }}
        >
          {ingredientsInfo?.[selectedDistributorId].map(
            (
              {
                regulationLanguages,
                chemicalGroupName,
                ingredientDetectionRegulationId,
                isOpen,
                type,
              },
              idx,
            ) => (
              <Panel
                key={ingredientDetectionRegulationId}
                onClick={() => {
                  const newIngredientsInfo = { ...ingredientsInfo };
                  newIngredientsInfo[selectedDistributorId][
                    idx
                  ].isOpen = !isOpen;
                  setIngredientsInfo(newIngredientsInfo);
                }}
              >
                <Flex justify="space-between" align="center" gap={16}>
                  <Typography.Text>
                    {getTypeText(type)} {chemicalGroupName}
                  </Typography.Text>
                  <Icon name={isOpen ? 'up' : 'down'} size={18} />
                </Flex>
                {isOpen && (
                  <Typography.Text
                    gutter={{ top: 12 }}
                    type="BODY_2"
                    color="GRAY80"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {regulationLanguages.find(
                      (item) => item.languageCode === LanguageCode.KO,
                    )?.limitedMatter || '-'}
                  </Typography.Text>
                )}
              </Panel>
            ),
          )}
        </Collapse>
      </ModalBody>
    </Modal>
  );
};

const ModalHeader = styled(Flex)`
  padding: 16px 24px;
  border-bottom: 1px solid ${palette.GRAY20};
  height: 58px;
`;

const ModalBody = styled.div`
  padding: 24px 40px 32px;
  height: 502px;
`;

const Tab = styled.div<{ isActive: boolean; label: string }>`
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          font-weight: 500;
          cursor: default;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background-color: ${palette.ETC_WHITE};
          color: ${palette.SLATE_GRAY60};
          cursor: pointer;
        `};

  &::after {
    content: '${({ label }) => label}';
    font-weight: bold;
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const Collapse = styled(Flex)`
  overflow: scroll;
`;
const Panel = styled.div`
  padding: 24px;
  border-radius: 4px;
  background-color: ${palette.SLATE_GRAY10};
  cursor: pointer;
`;

export default SeparateStoreRegulationsModal;
