import { Button, Form, FormInstance, message } from 'antd';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { focusToInvalidatedField } from 'lib/form';
import palette from 'lib/styles/palette';
import { ScreeningFormData } from 'pages/brand/formulaScreening/FormulaScreeningPage';
import FormulaScreening from './FormulaScreening';
import FormulaScreeningTemplete from './FormulaScreeningTemplete';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import { FormulaScreeningItem } from 'service/brand/formulaScreening/formulaScreening';
import StickyButtonWrapper from '../artworkScreening/StickyButtonWrapper';

const Step1 = ({
  form,
  file,
  method,
  isArtworkScreening = false,
  __html,
  setHTML,
  setFile,
  setStep,
  setMethod,
  setStep2DisabledMode,
  formulaArtworkScreeningLoading = false,
  handleFormulaScreeningForArtwork,
  formulaScreeningResult,
}: {
  form: FormInstance<ScreeningFormData>;
  file: File | null;
  method: number;
  isArtworkScreening?: boolean;
  __html?: string;
  setHTML?: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setMethod: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setStep2DisabledMode: React.Dispatch<React.SetStateAction<boolean>>;
  formulaArtworkScreeningLoading?: boolean;
  handleFormulaScreeningForArtwork?: () => void;
  formulaScreeningResult?: FormulaScreeningItem | null;
}) => {
  const methods = [
    {
      id: 1,
      svg: <Icon name="formulaTemplete" />,
      title: '템플릿 사용하기',
      background: `#D0DDFF`,
      content: (
        <>
          certicos 에서 제공하는
          <br />
          템플릿에 바로 입력할게요.
        </>
      ),
    },
    {
      id: 2,
      svg: <Icon name="formulaDirect" />,
      title: '직접 업로드하기',
      background: `${palette.LIGHT_BLUE20}`,
      content: (
        <>
          certicos 에서 제공하는
          <br />
          엑셀 파일을 다운받아 직접 업로드할게요.
        </>
      ),
    },
  ];

  const prevStep2 = () => {
    if (isArtworkScreening) {
      handleFormulaScreeningForArtwork?.();
    } else {
      setStep(2);
    }
  };

  const goNextPage = () => {
    if (method === 1) {
      form
        .validateFields()
        .then(() => {
          prevStep2();
        })
        .catch((err) => {});
    } else {
      if (file) {
        prevStep2();
      } else {
        message.warn('파일을 업로드해주세요.');
      }
    }
  };

  return (
    <Container>
      {isArtworkScreening && (
        <LoadingModal
          visible={formulaArtworkScreeningLoading}
          textType="BODY_2"
          content={`업로드하신 파일의 전성분표 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
        />
      )}
      <Form
        form={form}
        style={{ width: '100%' }}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
        onFieldsChange={(_, allFields) => {
          setStep2DisabledMode(
            allFields
              .filter(
                ({ name }) =>
                  Array.isArray(name) &&
                  name.length === 3 &&
                  (name[2] === 'name' || name[2] === 'wt'),
              )
              .every(({ value }) => !value),
          );
        }}
      >
        <InnerContainer isArtworkScreening={isArtworkScreening}>
          {isArtworkScreening && (
            <Flex dir="column" gap={6} gutter={{ top: 60 }}>
              <Typography.Text
                type="HEADLINE_1"
                color="GRAY90"
                style={{ fontWeight: 300 }}
              >
                아트웍 스크리닝
              </Typography.Text>
              <Typography.Text type="BODY_2" color="GRAY70">
                전성분표를 기준으로 Artwork의 전성분 내용을 국가 규제별로
                스크리닝 할 수 있습니다.(알레르기 유발 성분에 대한 스크리닝은
                추후 제공 예정)
              </Typography.Text>
            </Flex>
          )}

          <MethodSelectContainer gap={24}>
            <Typography.Text style={{ fontSize: 24, color: palette.GRAY90 }}>
              입력 방식 선택
            </Typography.Text>
            <Flex gap={16}>
              {(isArtworkScreening ? methods.reverse() : methods).map(
                ({ id, title, svg, content, background }) => (
                  <MethodDiv
                    key={id}
                    selected={method === id}
                    onClick={() => setMethod(id)}
                  >
                    {method === id && (
                      <Icon
                        name="checkCircleActived"
                        size={20}
                        style={{
                          position: 'absolute',
                          top: '8px',
                          left: '8px',
                        }}
                      />
                    )}
                    <ColorDiv style={{ background }}>{svg}</ColorDiv>
                    <Typography.Text
                      style={{
                        color: palette.GRAY90,
                        marginBottom: 8,
                        fontWeight: 500,
                      }}
                      type="BODY_1"
                    >
                      {title}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        color: palette.SLATE_GRAY60,
                        whiteSpace: 'pre-line',
                      }}
                      type="BODY_2"
                    >
                      {content}
                    </Typography.Text>
                  </MethodDiv>
                ),
              )}
            </Flex>
          </MethodSelectContainer>
          {method === 1 ? (
            <FormulaScreeningTemplete
              isArtworkScreening={isArtworkScreening}
              form={form}
            />
          ) : (
            <FormulaScreening
              isArtworkScreening={isArtworkScreening}
              file={file}
              setFile={setFile}
              __html={__html}
              setHTML={setHTML}
              formulaScreeningResult={formulaScreeningResult}
              uploadLoading={formulaArtworkScreeningLoading}
            />
          )}
          {!isArtworkScreening && (
            <Form.Item shouldUpdate={() => true} noStyle>
              {({ getFieldValue }) => {
                const disabledMode =
                  method === 1
                    ? getFieldValue('ingredients')?.filter(
                        ({ name, wt }: { name: string; wt: string }) =>
                          (name === '' || !name) && !wt,
                      ).length === getFieldValue('ingredients').length
                    : !file;
                return (
                  <Form.Item noStyle>
                    <Flex style={{ width: '100%' }}>
                      <Button
                        style={{
                          width: 200,
                          height: 56,
                          fontSize: 18,
                          margin: '56px auto 0 auto',
                        }}
                        htmlType="submit"
                        type="primary"
                        onClick={goNextPage}
                        disabled={disabledMode}
                      >
                        다음
                      </Button>
                    </Flex>
                  </Form.Item>
                );
              }}
            </Form.Item>
          )}
        </InnerContainer>
        {isArtworkScreening && (
          <StickyButtonWrapper>
            <Form.Item shouldUpdate={() => true} noStyle>
              {({ getFieldValue }) => {
                const disabledMode =
                  method === 1
                    ? getFieldValue('ingredients')?.filter(
                        ({ name, wt }: { name: string; wt: string }) =>
                          name === '' || !name || !wt,
                      ).length === getFieldValue('ingredients').length
                    : !file;

                return (
                  <Form.Item noStyle>
                    <Flex>
                      <Button
                        style={{
                          width: 200,
                          height: 56,
                          fontSize: 18,
                        }}
                        htmlType="submit"
                        type="primary"
                        onClick={goNextPage}
                        disabled={disabledMode}
                      >
                        분석하기
                      </Button>
                    </Flex>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </StickyButtonWrapper>
        )}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const InnerContainer = styled(Flex)<{ isArtworkScreening: boolean }>`
  width: 100%;
  flex-direction: column;
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: ${({ isArtworkScreening }) =>
    isArtworkScreening ? '80px' : '0'};
`;

const MethodSelectContainer = styled(Flex)`
  flex-direction: column;
  margin-top: 40px;
`;

const MethodDiv = styled(Flex)<{ selected: boolean }>`
  position: relative;
  width: 336px;
  height: 206px;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  padding: 24px;
  border-radius: 8px;
  border: ${({ selected }) =>
    selected
      ? `1px solid ${palette.PRIMARY50}`
      : `1px solid ${palette.GRAY30}`};
  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
    `}
  justify-content: center;
  align-items: center;
  transition: border 300ms ease;
  &:hover {
    box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
    border: 1px solid ${palette.PRIMARY50};
  }
`;

const ColorDiv = styled.div`
  padding: 24px 58px;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export default Step1;
