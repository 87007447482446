import { message } from 'antd';
import { useEffect, useState } from 'react';

import history from 'lib/history';
import { useDocuments } from 'service/brand/retailer/retailerApply';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import { DocumentCode, Document } from 'types/brand/retailer/retailerApply';

const DocumentsUpload = ({
  productId,
  documentCode,
  max = 1,
  accept = 'application/pdf',
  sample,
}: {
  productId?: number;
  documentCode: DocumentCode;
  multiple?: boolean;
  max?: number;
  accept?: string;
  sample?: React.ReactChild;
}) => {
  const { documents, uploadDocuments, uploadLoading } = useDocuments({
    productId,
    documentCode,
  });
  const [files, setFiles] = useState<(File | Document)[]>([]);

  const handleUpload = (newFile: any) => {
    if (max === 1) {
      setFiles([newFile]);
    } else {
      if (files.length === max) {
        message.warn(
          `${max}개 까지만 업로드 가능합니다. 다른 파일을 삭제 후 업로드해 주세요.`,
        );
      } else {
        setFiles((draft) => {
          if (draft.length === max) {
            return draft;
          }
          return draft.concat(newFile);
        });
      }
    }
  };
  const handleDeleteFile = (target: any) => {
    setFiles((draft) => draft.filter((file) => file !== target));
  };
  const handleUploadDocuments = () => {
    if (files.length === 0) {
      return message.warn('파일을 업로드해 주세요.');
    }
    uploadDocuments(files, {
      onSuccess: history.goBack,
    });
  };

  useEffect(() => {
    if (documents.length > 0) {
      setFiles(documents);
    }
  }, [documents]);

  return (
    <>
      {sample}
      <FileUploadTemplate
        fetchLoading={uploadLoading}
        accept={accept}
        max={max}
        files={max === 1 ? files[0] : files}
        onDelete={handleDeleteFile}
        onSubmit={handleUploadDocuments}
        onUpload={handleUpload}
        updateMode={documents.length > 0}
      />
    </>
  );
};

export default DocumentsUpload;
