import { useParams } from 'react-router-dom';
import moment from 'moment';

import path from 'lib/path';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';
import { useSearchParams } from 'hook/useSearchParams';
import history from 'lib/history';
import QCQAOutputList from 'components/brand/qcqa/QCQAOutputList';

export interface QCQAOutputListPageSearchParams {
  productYear: number;
  outputDateLower: string;
  outputDateUpper: string;
}

const QCQAOutputListPage = () => {
  const today = moment();
  const currYear = String(today.year());
  const params = useParams<{
    qcqaCompanyId: string;
  }>();
  const qcqaCompanyId = Number(params.qcqaCompanyId);
  const { searchParams, replaceSearchParams } = useSearchParams({
    path: `${path.qcqa.outputs.root}/${qcqaCompanyId}`,
  });

  const queryParams: QCQAOutputListPageSearchParams = {
    productYear: Number(searchParams.get('productYear')) || today.year(),
    outputDateLower: `${currYear}-01-01`,
    outputDateUpper: today.format('YYYY-MM-DD'),
  };

  return (
    <QCQAPageTemplate
      title="생산실적 현황"
      onBack={() => {
        history.replace(path.qcqa.management.root);
      }}
    >
      <QCQAOutputList
        queryParams={queryParams}
        replaceSearchParams={replaceSearchParams}
      />
    </QCQAPageTemplate>
  );
};

export default QCQAOutputListPage;
