import { Button, Col, message, Row, Statistic } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import StatisticCard from 'components/StatisticCard';
import usePreviewModal from 'hook/usePreviewModal';
import { downloadFiles } from 'lib/file';
import {
  useICIDApplication,
  useICIDRegisteredDetail,
} from 'service/material/icid/icid';
import { ICIDRegisteredDetailParam } from 'types/material/icid/icid';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const Container = styled.div`
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 18px 4px;
  }
`;

const ICIDRegisteredDetail = ({
  icidApplicationId,
}: {
  icidApplicationId: number;
}) => {
  const [downloadFilesLoading, setDownloadFilesLoading] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const { PreviewModal, setUrl } = usePreviewModal();
  const { icidApplication } = useICIDApplication(icidApplicationId);
  const { icidRegisteredDetail, isLoading } = useICIDRegisteredDetail({
    icidApplicationId,
  });

  const downloadDocuments = async () => {
    if (icidApplication) {
      setDownloadFilesLoading(true);
      const files = icidRegisteredDetail.reduce(
        (documentFiles, { documentName, uploadedFiles }) =>
          uploadedFiles && uploadedFiles.length > 0
            ? documentFiles.concat({
                name: documentName,
                url: uploadedFiles[0].fileUrl,
              })
            : documentFiles,
        [] as { name: string; url: string }[],
      );

      if (files.length === 0) {
        message.warn('다운로드 가능한 파일이 존재하지 않습니다.');
        setDownloadFilesLoading(false);

        return;
      }

      await downloadFiles(files, `${icidApplication.listedInciName}.zip`);

      setDownloadFilesLoading(false);
    }
  };

  const previewDocument = (fileUrl: string, documentName: string) => {
    if (!fileUrl) {
      message.warning('파일이 존재하지 않습니다.');

      return;
    }

    setDocumentName(documentName);
    setUrl(fileUrl);
  };

  const columns: ColumnsType<ICIDRegisteredDetailParam> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center',
        width: '13%',
        render: (_, __, index) => index + 1,
      },
      {
        title: '문서명',
        width: '72%',
        dataIndex: 'documentName',
      },
      {
        title: '미리보기',
        width: '15%',
        align: 'center',
        render: (_, { uploadedFiles, documentName }) =>
          uploadedFiles && uploadedFiles.length > 0 ? (
            <Icon
              name="page"
              size={22}
              onClick={() =>
                previewDocument(uploadedFiles[0].fileUrl, documentName)
              }
              style={{ margin: '0 auto' }}
            />
          ) : null,
      },
    ],
    [icidRegisteredDetail],
  );

  return (
    <Container>
      <PreviewModal documentName={documentName} />
      <StatisticCard>
        <Row gutter={[16, 24]}>
          <Col span={9} style={{ textAlign: 'center' }}>
            <Statistic
              title="등재 신청 번호"
              value={icidApplication?.applicationCode || '-'}
            />
          </Col>
          <Col span={15} style={{ textAlign: 'center' }}>
            <Statistic
              title="INCI명 (INCI Name)"
              value={icidApplication?.listedInciName || '-'}
            />
          </Col>
          <Col span={9} style={{ textAlign: 'center' }}>
            <Statistic
              title="원료명 (Trade Name)"
              value={icidApplication?.tradeName || '-'}
            />
          </Col>
          <Col span={15} style={{ textAlign: 'center' }}>
            <Statistic
              title="카테고리"
              value={icidApplication?.category.categoryName || '-'}
            />
          </Col>
        </Row>
      </StatisticCard>
      <Row justify="center" gutter={[16, 16]} style={{ marginTop: 40 }}>
        <Col>
          <Button
            icon={
              <Icon
                name="clipboard"
                size={18}
                color="PRIMARY50"
                style={{ display: 'inline-block', marginRight: 4 }}
              />
            }
            style={{
              width: 170,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setDocumentName('PCPC Letter');
              setUrl(icidApplication?.pcpcLetterUrl || '');
            }}
          >
            PCPC Letter 확인
          </Button>
        </Col>
        <Col>
          <Button
            loading={downloadFilesLoading || isLoading}
            icon={
              <Icon
                name="download"
                size={18}
                color="PRIMARY50"
                style={{ display: 'inline-block', marginRight: 4 }}
              />
            }
            style={{
              width: 170,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={downloadDocuments}
          >
            모든 서류 다운로드
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={icidRegisteredDetail}
        pagination={false}
        loading={isLoading}
        style={{ marginTop: 24 }}
        rowKey="materialIcidApplicationDocStatusId"
      />
    </Container>
  );
};

export default ICIDRegisteredDetail;
