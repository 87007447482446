import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useCallback, useMemo, useState } from 'react';

import {
  ProductSearchType,
  ProductStatus,
  ProductSearchParams,
} from 'types/manufacturer/product';
import history from 'lib/history';
import * as productApi from 'lib/api/manufacturer/product';
import useUpdateEffect from 'hook/useUpdateEffect';

export const useProduct = (productId?: number) => {
  const { data: product = null, isFetching: getProductLoading } = useQuery(
    ['manufacturer/products', productId],
    () => productApi.getProduct(productId!),
    {
      select: (res) => res.data.result,
      enabled: productId !== undefined,
    },
  );
  return useMemo(
    () => ({
      product,
      getProductLoading,
    }),
    [product, getProductLoading],
  );
};

export const useGetProduct = () => {
  const { mutateAsync: getProduct, isLoading } = useMutation(
    productApi.getProductForCheckDuplicate,
  );

  return useMemo(
    () => ({
      getProduct,
      isLoading,
    }),
    [getProduct, isLoading],
  );
};

export const useProducts = (status: ProductStatus) => {
  const { pathname, state: savedSearchParams = {} } = useLocation<
    Partial<ProductSearchParams>
  >();
  const [page, setPage] = useState(savedSearchParams.page ?? 1);
  const [searchType, setSearchType] = useState<ProductSearchType>(
    savedSearchParams.searchType ?? ProductSearchType.ALL,
  );
  const [searchKeyword, setSearchKeyword] = useState(
    savedSearchParams.searchKeyword ?? '',
  );
  const {
    data: { content: products = [], totalElements = 0 } = {},
    isFetching: getLoading,
    refetch,
  } = useQuery(
    ['manufacturer/getProductSamples', page, searchType, searchKeyword, status],
    () => productApi.getProducts({ page, searchType, searchKeyword, status }),
    {
      select: (res) => ({
        ...res.data.result,
        content: res.data.result.content.map((product: any) => ({
          ...product,
          productDetail: product.productDetails[0],
        })),
      }),
    },
  );

  const showProductDetail = useCallback((product: any) => {
    history.push(`/manufacturer/product/${product.productId}`);
  }, []);

  const {
    mutate: issueAuthCode,
    isLoading: issueAuthCodeLoading,
  } = useMutation(productApi.issueAuthCode, {
    onSuccess: () => {
      refetch();
    },
  });

  useUpdateEffect(() => {
    history.replace(pathname, {
      searchType,
      searchKeyword,
      page,
      status,
    });
  }, [searchType, searchKeyword, page, status]);

  return useMemo(
    () => ({
      page,
      setPage,
      searchType,
      setSearchType,
      searchKeyword,
      setSearchKeyword,
      products,
      totalElements,
      getLoading,
      showProductDetail,
      issueAuthCode,
      issueAuthCodeLoading,
    }),
    [
      page,
      searchType,
      searchKeyword,
      products,
      totalElements,
      getLoading,
      showProductDetail,
      issueAuthCode,
    ],
  );
};
