import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'components/system';
import MyPageTabs from 'components/ui/MyPageTabs';
import path from 'lib/path';
import MyRouter from 'routers/MyRouter';
import { useAuthority, useUserType } from 'service/auth';
import { useCompanyType } from 'service/company';
import { UserType } from 'types/auth';
import { CompanyType } from 'types/company';

const MyPageBlock = styled.div``;

const MyPage = () => {
  const { tab = 'userInfo' } = useParams<{ tab: string }>();
  const history = useHistory();
  const companyType = useCompanyType();
  const userType = useUserType();
  const authority = useAuthority();

  const handleChangeTab = (tab: string) => {
    history.push(`${path.my}/${tab}`);
  };

  const tabs: { label: string; value: string }[] = [
    {
      label: '회원정보 수정',
      value: 'userInfo',
    },
    ...(companyType === CompanyType.BRAND && userType === UserType.BRAND
      ? [
          {
            label: '공통 서류 관리',
            value: 'docs',
          },
        ]
      : []),
    ...((companyType === CompanyType.BRAND && userType === UserType.BRAND) ||
    (companyType === CompanyType.MATERIAL && userType === UserType.MATERIAL)
      ? [
          {
            label: '계약 보관함',
            value: 'estimate',
          },
        ]
      : []),
    ...(companyType === CompanyType.BRAND && userType === UserType.BRAND
      ? [
          {
            label: '제조사 관리',
            value: 'manufacturer',
          },
        ]
      : []),
    ...(companyType === CompanyType.MATERIAL && userType === UserType.MATERIAL
      ? [
          {
            label: '중국 NMPA 회사 정보',
            value: 'nmpa',
          },
        ]
      : []),
    ...(authority === 'SUPER_USER'
      ? [
          {
            label: '담당자 관리',
            value: 'manager',
          },
        ]
      : []),
    ...(companyType === CompanyType.BRAND && userType === UserType.BRAND
      ? [
          {
            label: '바이어 관리',
            value: 'buyer',
          },
        ]
      : []),
    ...(companyType === CompanyType.BRAND && userType === UserType.BRAND
      ? [
          {
            label: 'RP관리',
            value: 'rp',
          },
        ]
      : []),
    ...(companyType === CompanyType.BRAND && userType === UserType.BRAND
      ? [
          {
            label: 'FDA 계정',
            value: 'fdaar',
          },
        ]
      : []),
    ...(companyType === CompanyType.BRAND && userType === UserType.BRAND
      ? [
          {
            label: 'VCRP 계정',
            value: 'vcrp',
          },
        ]
      : []),
  ];

  return (
    <MyPageBlock>
      <Typography.Text light type="HEADLINE_2" gutter={{ bottom: 32 }}>
        {tabs.find(({ value }) => tab === value)?.label}
      </Typography.Text>
      <MyPageTabs activeTab={tab} tabs={tabs} onChange={handleChangeTab} />
      <MyRouter />
    </MyPageBlock>
  );
};

export default MyPage;
