import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

interface Props {
  title?: string;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  children: React.ReactNode;
}

const NoticeCardBlock = styled.div`
  margin-top: 16px;

  .card {
    padding: 12px 24px;
    border: 1px solid #d7e7fe;
    background-color: ${palette.PRIMARY20};
    border-radius: 4px;

    ul {
      padding-left: 14px;
      margin-bottom: 0;

      li {
        list-style: none;
        position: relative;
        letter-spacing: -0.5px;

        &::before {
          content: '';
          position: absolute;
          top: 9px;
          left: -12px;
          display: inline-block;
          width: 4px;
          height: 4px;
          margin-right: 4px;
          background-color: ${palette.PRIMARY50};
        }
      }
      li + li {
        margin-top: 4px;
      }
    }
  }
`;

const NoticeCard = ({
  title = '안내 사항',
  titleStyle,
  style,
  bodyStyle,
  children,
}: Props) => {
  return (
    <NoticeCardBlock style={style}>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 8 }} style={titleStyle}>
        {title}
      </Typography.Text>
      <div className="card" style={bodyStyle}>
        {children}
      </div>
    </NoticeCardBlock>
  );
};

export default NoticeCard;
