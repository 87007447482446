import React from 'react';
import { Button, Col, Row, Upload } from 'antd';
import styled, { css } from 'styled-components';
import FileList from 'components/file/FileList';

import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import { UploadFileBasic } from 'types/material/marketing';
import { downloadFile } from 'lib/file';
import Icon from 'components/ui/Icon/Icon';

const FileUploadBlock = styled.div<{ readOnly?: boolean }>`
  margin: 0 auto;
  ${(props) =>
    props.readOnly &&
    css`
      max-width: 400px;
    `}
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  border: 1px solid ${palette.GRAY40} !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  height: 406px !important;

  .ant-btn {
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: #727272;

    .material-icons {
      margin-right: 0;
      color: ${palette.GRAY60};
    }
  }
  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: #7e7e7e;
  }
`;

interface Props {
  file: File | UploadFileBasic | number | null;
  previewedFile?: File;
  accept?: string;
  readOnly: boolean;
  disabled?: boolean;
  onSetFile: React.Dispatch<
    React.SetStateAction<File | UploadFileBasic | number | null>
  >;
}

const MarketingFileUpload = ({
  file,
  previewedFile,
  accept = 'application/pdf',
  readOnly,
  disabled,
  onSetFile,
}: Props) => {
  const handleDelete = (file: File | UploadFileBasic | number | null) => {
    if (file instanceof Object) {
      onSetFile((file as UploadFileBasic).materialMarketingDataId);
    } else {
      onSetFile(null);
    }
  };
  const handleFileClick = (file: File | UploadFileBasic | number | null) => {
    if (file instanceof Object) {
      downloadFile((file as UploadFileBasic).url, file.name);
    }
  };
  return (
    <FileUploadBlock>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={{ span: 24 }} sm={{ span: readOnly ? 24 : 12 }}>
          <FileList
            style={undefined}
            header={
              <Typography.Text type="BODY_2" gutter={{ bottom: 8 }}>
                업로드 된 파일
              </Typography.Text>
            }
            selectedFile={previewedFile}
            files={file}
            upload={
              !readOnly &&
              !disabled && (
                <StyledUploadDragger
                  disabled={disabled}
                  accept={accept}
                  beforeUpload={(file) => {
                    onSetFile(file);
                    return false;
                  }}
                  itemRender={() => null}
                >
                  <Button>
                    <Icon
                      name="plus"
                      color="GRAY60"
                      style={{ margin: '0 auto' }}
                    />
                    파일 선택
                  </Button>
                  <Typography.Text type="BODY_2" gutter={{ top: 8 }}>
                    버튼 클릭 혹은 드래그하여
                    <br />
                    파일을 첨부하세요.
                  </Typography.Text>
                </StyledUploadDragger>
              )
            }
            onFileClick={handleFileClick}
            onDelete={disabled ? undefined : handleDelete}
            width={400}
          />
        </Col>
      </Row>
    </FileUploadBlock>
  );
};

export default MarketingFileUpload;
