import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { isEqual } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { messages } from 'lib/consts';
import {
  colorOptions,
  formOptions,
  hardnessUnitOptions,
  odourOptions,
  viscosityUnitOptions,
} from 'lib/selectOption';
import { capitalize } from 'lib/validate';
import { updateCurrentPathname } from 'modules/product';
import { getUndefinedFilteredObject } from 'lib/form';
import client from 'lib/api/client';
import history from 'lib/history';
import { useCertificateMode } from 'hook/certificate';
import useGA, { EGAActionType } from 'hook/useGA';

export interface ProductPhysicalPropertyAddParams {
  productId: number;
  form: string;
  color: string;
  odour: string;
  phLimitMin: number;
  phLimitMax: number;
  ph: number;
  specificGravityLimitMin: number;
  specificGravityLimitMax: number;
  specificGravity: number;
  netWeight: number;
  netWeightUnit: string;
  lotNo: string;
  manufacturingDate: string;
  isAerosolProduct: boolean;
  isEthanolProduct: boolean;
  countryId: number;
}

export interface ProductPhysicalPropertyUpdateParams {
  productPhysicalPropertyId: number;
  form: string;
  color: string;
  odour: string;
  phLimitMin: number;
  phLimitMax: number;
  ph: number;
  specificGravityLimitMin: number;
  specificGravityLimitMax: number;
  specificGravity: number;
  netWeight: number;
  netWeightUnit: string;
  lotNo: string;
  manufacturingDate: string;
  isAerosolProduct: boolean;
  isEthanolProduct: boolean;
  countryId: number;
}

const queryKey = '/product-physical-property/';

export const useGetPhysicalProperties = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const {
    data: productPhysicalProperty,
    isLoading: getProductPhysicalPropertyLoading,
  } = useQuery(
    [queryKey, productId, countryId],
    () =>
      client.get(
        `/product-physical-property/${productId}${
          countryId ? `/${countryId}` : ''
        }`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    productPhysicalProperty,
    getProductPhysicalPropertyLoading,
  };
};

export const usePhysicalProperties = (
  productId?: number,
  countryId?: number,
) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({});
  const { sendEventToGA } = useGA();
  const certificateMode = useCertificateMode();

  const { currentProduct, readOnlyMode } = useSelector(
    ({ product, certificate }: any) => ({
      currentProduct: product.currentProduct,
      readOnlyMode: certificate.readOnlyMode,
    }),
  );
  const queryClient = useQueryClient();

  const {
    data: productPhysicalProperty,
    isLoading: getProductPhysicalPropertyLoading,
  } = useQuery(
    [queryKey, productId, countryId],
    () =>
      client.get(
        `/product-physical-property/${productId}${
          countryId ? `/${countryId}` : ''
        }`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const updateMode = productPhysicalProperty !== null;

  const {
    mutate: addProductPhysicalProperty,
    isLoading: addProductPhysicalPropertyLoading,
  } = useMutation((productPhysicalProperty: ProductPhysicalPropertyAddParams) =>
    client.post('/product-physical-property', productPhysicalProperty),
  );

  const {
    mutate: updateProductPhysicalProperty,
    isLoading: updateProductPhysicalPropertyLoading,
  } = useMutation(
    (productPhysicalProperty: ProductPhysicalPropertyUpdateParams) =>
      client.patch('/product-physical-property', productPhysicalProperty),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, productId, countryId]);
      },
    },
  );

  const handleChangePhRadioGroup = (notApplicablePh: boolean) => {
    if (notApplicablePh) {
      form.setFieldsValue({ phLimitMin: 5.0 });
      form.setFieldsValue({ phLimitMax: 10.0 });
      form.setFieldsValue({ ph: '' });
    }
  };

  const handleChangeSpecificGravityRadioGroup = (
    notApplicableSpecificGravity: boolean,
  ) => {
    if (notApplicableSpecificGravity) {
      if (notApplicableSpecificGravity) {
        form.setFieldsValue({ specificGravityLimitMin: 0.9 });
        form.setFieldsValue({ specificGravityLimitMax: 1.1 });
        form.setFieldsValue({ specificGravity: '' });
      }
    }
  };

  const handleChangeViscosityRadioGroup = (notApplicableViscosity: boolean) => {
    if (notApplicableViscosity) {
      form.setFieldsValue({ viscosityLimitMin: '' });
      form.setFieldsValue({ viscosityLimitMax: '' });
      form.setFieldsValue({ viscosity: '' });
      form.setFieldsValue({ viscosityUnit: null });
    }
  };

  const handleChangeHardnessRadioGroup = (notApplicableHardness: boolean) => {
    if (notApplicableHardness) {
      form.setFieldsValue({ hardnessLimitMax: '' });
      form.setFieldsValue({ hardnessLimitMin: '' });
      form.setFieldsValue({ hardness: '' });
      form.setFieldsValue({ hardnessUnit: null });
    }
  };

  const onSubmit = (formValues: any) => {
    const {
      viscosityUnitDirect,
      aerosolOrEthanol,
      hardnessUnitDirect,
      ...requireValues
    } = formValues;
    if (!updateMode) {
      addProductPhysicalProperty(
        {
          notApplicablePh: formValues.notApplicablePh,
          notApplicableViscosity: formValues.notApplicableViscosity,
          notApplicableHardness: formValues.notApplicableHardness,
          notApplicableSpecificGravity: formValues.notApplicableSpecificGravity,

          productId: currentProduct.productId,
          ...requireValues,
          isAerosolProduct: formValues.aerosolOrEthanol === 'aerosol',
          isEthanolProduct: formValues.aerosolOrEthanol === 'ethanol',
          form:
            formValues.form === 'others'
              ? formValues.formDirect
              : formValues.form,
          color:
            formValues.color === 'others'
              ? formValues.colorDirect
              : formValues.color,
          odour:
            formValues.odour === 'others'
              ? formValues.odourDirect
              : formValues.odour,
          ...(formValues.notApplicableViscosity && {
            viscosityLimitMin: formValues.viscosityLimitMin,
            viscosityLimitMax: formValues.viscosityLimitMax,
            viscosity: formValues.viscosity,
            viscosityUnit:
              formValues.viscosityUnit === 'others'
                ? formValues.viscosityUnitDirect
                : formValues.viscosityUnit,
          }),
          ...(formValues.notApplicableHardness && {
            hardnessLimitMin: formValues.hardnessLimitMin,
            hardnessLimitMax: formValues.hardnessLimitMax,
            hardness: formValues.hardness,
            hardnessUnit:
              formValues.hardnessUnit === 'others'
                ? formValues.hardnessUnitDirect
                : formValues.hardnessUnit,
          }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Physical Properties',
              actionType: EGAActionType.REGISTER,
            });
            dispatch(updateCurrentPathname());
          },
        },
      );
    } else {
      if (isEqual(initialFormValues, { ...initialFormValues, ...formValues })) {
        message.warn(messages.NO_NEED_TO_UPDATE);
        return;
      }

      let {
        productType,
        form,
        formDirect,
        color,
        colorDirect,
        odour,
        odourDirect,
        notApplicablePh,
        phLimitMin,
        phLimitMax,
        ph,
        notApplicableSpecificGravity,
        specificGravityLimitMin,
        specificGravityLimitMax,
        specificGravity,
        netWeightUnit,
        netWeightUnitDirect,
        lotNo,
        manufacturingDate,
        aerosolOrEthanol,
        expiryDate,
        notApplicableViscosity,
        viscosity,
        viscosityLimitMax,
        viscosityLimitMin,
        viscosityUnit,
        viscosityUnitDirect,
        notApplicableHardness,
        hardness,
        hardnessLimitMax,
        hardnessLimitMin,
        hardnessUnit,
        hardnessUnitDirect,
      } = formValues;
      if (form === 'others') form = formDirect;
      if (color === 'others') color = colorDirect;
      if (odour === 'others') odour = odourDirect;
      if (viscosityUnit === 'others') viscosityUnit = viscosityUnitDirect;
      if (netWeightUnit === 'others') netWeightUnit = netWeightUnitDirect;
      if (hardnessUnit === 'others') hardnessUnit = hardnessUnitDirect;
      const updateFormValues = {
        ...(productPhysicalProperty.productType !== productType && {
          productType,
        }),
        ...(productPhysicalProperty.isAerosolProduct !==
          (aerosolOrEthanol === 'aerosol') && {
          isAerosolProduct: aerosolOrEthanol === 'aerosol',
        }),
        ...(productPhysicalProperty.isEthanolProduct !==
          (aerosolOrEthanol === 'ethanol') && {
          isEthanolProduct: aerosolOrEthanol === 'ethanol',
        }),
        ...(productPhysicalProperty.form.toLowerCase() !==
          form.toLowerCase() && { form }),
        ...(productPhysicalProperty.color.toLowerCase() !==
          color.toLowerCase() && { color }),
        ...(productPhysicalProperty.odour.toLowerCase() !==
          odour.toLowerCase() && { odour }),
        ...(productPhysicalProperty.lotNo !== lotNo && { lotNo }),
        ...(productPhysicalProperty.manufacturingDate !== manufacturingDate && {
          manufacturingDate,
        }),
        ...(productPhysicalProperty.expiryDate !== expiryDate && {
          expiryDate,
        }),
        // pH
        ...(productPhysicalProperty.notApplicablePh !== notApplicablePh && {
          notApplicablePh,
        }),
        ...(productPhysicalProperty.phLimitMin !== phLimitMin && {
          phLimitMin,
        }),

        ...(productPhysicalProperty.phLimitMin !== phLimitMin &&
          (phLimitMin === null || phLimitMin === '') && {
            phLimitMin: '',
          }),
        ...(productPhysicalProperty.phLimitMax !== phLimitMax && {
          phLimitMax,
        }),
        ...(productPhysicalProperty.phLimitMax !== phLimitMax &&
          (phLimitMax === null || phLimitMax === '') && {
            phLimitMax: '',
          }),
        ...(productPhysicalProperty.ph !== ph &&
          (ph === null || ph === '') && {
            ph: '',
          }),
        ...((ph || ph === 0) && productPhysicalProperty.ph !== ph && { ph }),
        // Specific gravity
        ...(productPhysicalProperty.notApplicableSpecificGravity !==
          notApplicableSpecificGravity && {
          notApplicableSpecificGravity,
        }),
        ...(productPhysicalProperty.specificGravityLimitMin !==
          specificGravityLimitMin && { specificGravityLimitMin }),
        ...(productPhysicalProperty.specificGravityLimitMin !==
          specificGravityLimitMin &&
          (specificGravityLimitMin === null ||
            specificGravityLimitMin === '') && {
            specificGravityLimitMin: '',
          }),
        ...(productPhysicalProperty.specificGravityLimitMax !==
          specificGravityLimitMax && { specificGravityLimitMax }),
        ...(productPhysicalProperty.specificGravityLimitMax !==
          specificGravityLimitMax &&
          (specificGravityLimitMax === null ||
            specificGravityLimitMax === '') && {
            specificGravityLimitMax: '',
          }),
        ...((specificGravity || specificGravity === 0) &&
          productPhysicalProperty.specificGravity !== specificGravity && {
            specificGravity,
          }),
        ...(productPhysicalProperty.specificGravity !== specificGravity &&
          (specificGravity === null || specificGravity === '') && {
            specificGravity: '',
          }),
        // Viscosity
        ...(productPhysicalProperty.notApplicableViscosity !==
          notApplicableViscosity && {
          notApplicableViscosity,
        }),
        ...((viscosityLimitMin !== '' ||
          viscosityLimitMin !== undefined ||
          viscosityLimitMin !== null) &&
          productPhysicalProperty.viscosityLimitMin !== viscosityLimitMin && {
            viscosityLimitMin,
          }),
        ...(productPhysicalProperty.viscosityLimitMin !== viscosityLimitMin &&
          (viscosityLimitMin === null || viscosityLimitMin === '') && {
            viscosityLimitMin: '',
          }),
        ...((viscosityLimitMax !== '' ||
          viscosityLimitMax !== undefined ||
          viscosityLimitMax !== null) &&
          productPhysicalProperty.viscosityLimitMax !== viscosityLimitMax && {
            viscosityLimitMax,
          }),
        ...(productPhysicalProperty.viscosityLimitMax !== viscosityLimitMax &&
          (viscosityLimitMax === null || viscosityLimitMax === '') && {
            viscosityLimitMax: '',
          }),
        ...(viscosityUnit &&
          productPhysicalProperty.viscosityUnit !== viscosityUnit && {
            viscosityUnit,
          }),
        ...((viscosity || viscosity === 0) &&
          productPhysicalProperty.viscosity !== viscosity && { viscosity }),
        ...(productPhysicalProperty.viscosity !== viscosity &&
          (viscosity === null || viscosity === '') && { viscosity: '' }),
        // Hardness
        ...(productPhysicalProperty.notApplicableHardness !==
          notApplicableHardness && {
          notApplicableHardness,
        }),
        ...((viscosityLimitMin !== '' ||
          viscosityLimitMin !== undefined ||
          viscosityLimitMin !== null) &&
          productPhysicalProperty.hardnessLimitMin !== hardnessLimitMin && {
            hardnessLimitMin,
          }),
        ...(productPhysicalProperty.hardnessLimitMin !== hardnessLimitMin &&
          (hardnessLimitMin === null || hardnessLimitMin === '') && {
            hardnessLimitMin: '',
          }),
        ...(hardnessLimitMax &&
          productPhysicalProperty.hardnessLimitMax !== hardnessLimitMax && {
            hardnessLimitMax,
          }),
        ...(productPhysicalProperty.hardnessLimitMax !== hardnessLimitMax &&
          (hardnessLimitMax === null || hardnessLimitMax === '') && {
            hardnessLimitMax: '',
          }),
        ...(hardnessUnit &&
          productPhysicalProperty.hardnessUnit !== hardnessUnit && {
            hardnessUnit,
          }),
        ...((hardness || hardness === 0) &&
          productPhysicalProperty.hardness !== hardness && { hardness }),

        ...(productPhysicalProperty.hardness !== hardness &&
          (hardness === null || hardness === '') && { hardness: '' }),
      };

      if (
        Object.keys(getUndefinedFilteredObject(updateFormValues)).length === 0
      ) {
        return message.warn(messages.NO_NEED_TO_UPDATE);
      }

      updateProductPhysicalProperty(
        {
          productPhysicalPropertyId:
            productPhysicalProperty.productPhysicalPropertyId,
          ...updateFormValues,
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Physical Properties',
              actionType: certificateMode
                ? EGAActionType.MODIFY
                : EGAActionType.UPDATE,
            });

            if (certificateMode) {
              message.success('보완 완료되었습니다.');
            } else {
              message.success('수정되었습니다.');
            }
            history.goBack();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (updateMode && productPhysicalProperty) {
      const newFormValues = {
        ...productPhysicalProperty,
        notApplicablePh: productPhysicalProperty.notApplicablePh,
        notApplicableViscosity: productPhysicalProperty.notApplicableViscosity,
        notApplicableHardness: productPhysicalProperty.notApplicableHardness,
        notApplicableSpecificGravity:
          productPhysicalProperty.notApplicableSpecificGravity,

        form: formOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.form))
          ? capitalize(productPhysicalProperty.form)
          : 'others',
        formDirect: formOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.form))
          ? ''
          : capitalize(productPhysicalProperty.form),
        color: colorOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.color))
          ? capitalize(productPhysicalProperty.color)
          : 'others',
        colorDirect: colorOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.color))
          ? ''
          : capitalize(productPhysicalProperty.color),
        viscosityUnitDirect: viscosityUnitOptions
          .map(({ value }) => value)
          .includes(productPhysicalProperty.viscosityUnit)
          ? productPhysicalProperty.viscosityUnit
          : 'others',
        hardnessUnitDirect: hardnessUnitOptions
          .map(({ value }) => value)
          .includes(productPhysicalProperty.hardnessUnit)
          ? productPhysicalProperty.hardnessUnit
          : 'others',
        odour: odourOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.odour))
          ? capitalize(productPhysicalProperty.odour)
          : 'others',
        odourDirect: odourOptions
          .map(({ value }) => value)
          .includes(capitalize(productPhysicalProperty.odour))
          ? ''
          : capitalize(productPhysicalProperty.odour),
        aerosolOrEthanol: productPhysicalProperty.isAerosolProduct
          ? 'aerosol'
          : productPhysicalProperty.isEthanolProduct
          ? 'ethanol'
          : 'nothing',
      };

      setInitialFormValues(newFormValues);
      form.setFieldsValue(newFormValues);
    }
  }, [productPhysicalProperty]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        aerosolOrEthanol: 'aerosol',
        productType: 'LEAVE_ON',
        form: 'solid',
        color: 'red',
        odour: 'odourless',
        lotNo: 'apple10',
        ph: 7.0,
        specificGravity: 1.0,
        manufacturingDate: '2021-01-01',
        expiryDate: '2023-01-01',
        viscosity: 13,
        viscosityLimitMax: 15,
        viscosityLimitMin: 13,
        viscosityUnit: 'others',
        viscosityUnitDirect: 'sd',
        hardness: 24.1,
        hardnessLimitMax: 26,
        hardnessLimitMin: 23,
        hardnessUnit: 'kg',
      });
    }
  }, [updateMode]);

  return useMemo(
    () => ({
      form,
      certificateMode,
      currentProduct,
      productPhysicalProperty,
      fetchLoading:
        addProductPhysicalPropertyLoading ||
        updateProductPhysicalPropertyLoading,
      updateMode,
      readOnlyMode,
      handleChangePhRadioGroup,
      handleChangeSpecificGravityRadioGroup,
      handleChangeViscosityRadioGroup,
      handleChangeHardnessRadioGroup,
      onSubmit,
    }),
    [
      form,
      certificateMode,
      addProductPhysicalPropertyLoading,
      updateProductPhysicalPropertyLoading,
      getProductPhysicalPropertyLoading,
      updateMode,
      readOnlyMode,
    ],
  );
};
