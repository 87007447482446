import { Button, FormInstance, message } from 'antd';
import styled from 'styled-components';
import {
  ChangeEventHandler,
  DragEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import FileViewer from 'components/file/FileViewer';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import palette from 'lib/styles/palette';
import { ScreeningFormData } from 'pages/brand/formulaScreening/FormulaScreeningPage';
import {
  useFormulaArtworkScreening,
  useFormulaArtworkScreeningRequest,
} from 'service/brand/artworkScreening/artworkScreening';
import useScreeningSocket from 'service/brand/artworkScreening/useScreeningSocket';
import { ArtworkItem } from 'types/brand/product/artworkScreening';
import { useModal } from 'hook/useModal';
import IngredientAreaSelectModal from './IngredientAreaSelectModal';
import StickyButtonWrapper from './StickyButtonWrapper';

const ArtworkStep3 = ({
  form,
  file,
  artworkFile,
  screeningId,
  existedArtworkFile,
  setArtworkFile,
  setStep,
  setExistedArtworkFile,
}: {
  form: FormInstance<ScreeningFormData>;
  file: File | null;
  artworkFile: File | null;
  method: number;
  screeningId?: number;
  existedArtworkFile?: ArtworkItem | null;
  setHTML?: React.Dispatch<React.SetStateAction<string>>;
  setArtworkFile: React.Dispatch<React.SetStateAction<File | null>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setExistedArtworkFile: React.Dispatch<
    React.SetStateAction<ArtworkItem | null>
  >;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>(
    'none',
  );
  const [fileLoading, setFileLoading] = useState<{
    type: 'upload' | 'put';
    loading: boolean;
  } | null>(null);
  const [artworkFileId, setArtworkFileId] = useState<string>();
  const [hoverCount, setHoverCount] = useState(0);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const isScreeningStatus = useScreeningSocket({ fileId: artworkFileId });
  const { openAlertModal, openConfirmModal, closeConfirmModal } = useModal();
  const {
    requestFormulaScreening,
    requestFormulaScreeningLoading,
    cancelFormulaScreening,
    requestFormulaScreeningAgain,
    requestFormulaScreeningAgainLoading,
  } = useFormulaArtworkScreeningRequest();

  const { formulaScreeningArtworkFile } = useFormulaArtworkScreening({
    formulaScreeningId: screeningId,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!artworkFileId || isScreeningStatus === undefined) return;

    if (isScreeningStatus === 'COMPLETE' && screeningId) {
      formulaScreeningArtworkFile(
        { formulaScreeningId: screeningId },
        {
          onSuccess: (res) => {
            const { aiAnalyzeResult } = res.data.result;

            if (aiAnalyzeResult.splitImages.length === 0) {
              setLoadingVisible(false);
              openAlertModal({
                content: `INGREDIENTS가 포함되어 있지 않습니다.\n포함된 이미지로 업로드해 주세요.`,
                okText: '확인',
                noCloseIcon: true,
                noCancelButton: true,
              });
            } else if (aiAnalyzeResult.splitImages.length === 1) {
              requestFormulaScreeningAgain(
                {
                  formulaScreeningArtworkImageId:
                    aiAnalyzeResult.splitImages[0]
                      .formulaScreeningArtworkImageId,
                  formulaScreeningId: screeningId,
                },
                {
                  onSuccess: () => {
                    setLoadingVisible(false);
                    openAlertModal({
                      content: `스크리닝이 완료되었습니다.\n분석결과 화면으로 이동합니다.`,
                      onOk: () => {
                        setStep(4);
                      },
                      okText: '결과 화면으로 가기',
                    });
                  },
                  onError: () => {
                    setLoadingVisible(false);
                  },
                },
              );
            } else {
              setLoadingVisible(false);
              openConfirmModal({
                title: '전성분 영역 선택',
                width: 834,
                content: (
                  <IngredientAreaSelectModal
                    splitImages={aiAnalyzeResult.splitImages}
                    okLoading={requestFormulaScreeningAgainLoading}
                    onOk={(formulaScreeningArtworkImageId: number) => {
                      setLoadingVisible(true);
                      closeConfirmModal();
                      requestFormulaScreeningAgain(
                        {
                          formulaScreeningArtworkImageId,
                          formulaScreeningId: screeningId,
                        },
                        {
                          onSuccess: () => {
                            setLoadingVisible(false);
                            openAlertModal({
                              content: `스크리닝이 완료되었습니다.\n분석결과 화면으로 이동합니다.`,
                              onOk: () => {
                                setStep(4);
                              },
                              okText: '결과 화면으로 가기',
                            });
                          },
                          onError: () => {
                            setLoadingVisible(false);
                          },
                        },
                      );
                    }}
                  />
                ),
                footer: null,
              });
            }
          },
        },
      );
    } else if (isScreeningStatus === 'NOT_FOUND_INGREDIENT') {
      setLoadingVisible(false);
      openAlertModal({
        content: `INGREDIENTS가 포함되어 있지 않습니다.\n포함된 이미지로 업로드해 주세요.`,
        onOk: () => {
          setLoadingVisible(false);
        },
        okText: '확인',
        noCloseIcon: true,
        noCancelButton: true,
      });
    } else if (isScreeningStatus === 'SERVER_ERROR') {
      setLoadingVisible(false);
      return message.error('스크리닝 중 오류가 발생했습니다.');
    } else if (isScreeningStatus === 'MAX_FILE_SIZE') {
      setLoadingVisible(false);
      openAlertModal({
        width: 400,
        content: (
          <Typography.Text type="BODY_2" color="GRAY90">
            아트웍 파일의 전체 면적이 큰 경우
            <br />
            분석에 시간이 많이 소요될 수 있습니다.
            <br />
            AI 분석 최적화를 위하여 아트웍에서
            <br />
            전성분이 위치한 영역을 잘라서 업로드해주세요.
          </Typography.Text>
        ),
        okText: '확인',
        onOk: () => {
          setLoadingVisible(false);
        },
      });
    }
  }, [isScreeningStatus, artworkFileId]);

  const exampleImages = [
    {
      id: 1,
      src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Screening_1.jpeg`,
      width: 180,
    },
    {
      id: 2,
      src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Screening_2.jpeg`,
      width: 180,
    },
    {
      id: 3,
      src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Screening_3.jpeg`,
      width: 130,
    },
  ];

  const commonFileUpload = async (filesList: FileList | File[]) => {
    const files: File[] = [];

    if (filesList.length > 1) {
      return message.warn('1개의 파일만 업로드 가능합니다.');
    }

    for (const file of filesList) {
      const filename = file.name.toLowerCase();
      if (
        !filename.endsWith('.png') &&
        !filename.endsWith('.jpeg') &&
        !filename.endsWith('.jpg') &&
        !filename.endsWith('.pdf')
      ) {
        return message.warn('pdf, 이미지 파일만 업로드 가능합니다.');
      }
      files.push(file);
    }

    setFileLoading({
      type: 'upload',
      loading: true,
    });

    if ((files[0]?.name).toLowerCase().endsWith('.pdf')) {
      let reader = new FileReader();
      reader.readAsBinaryString(files[0]);
      reader.onloadend = function () {
        let count = (reader?.result as any)?.match(/\b\/Page\b/g)?.length;

        if (count > 1) {
          message.warn('한 페이지의 Artwork 파일만 업로드 가능합니다.');
          setFileLoading({
            type: 'upload',
            loading: false,
          });
          return;
        } else {
          setArtworkFile(files[0]);
          message.success(`정상적으로 업로드되었습니다.`);
          setFileLoading({
            type: 'upload',
            loading: false,
          });
          return;
        }
      };
    } else {
      setArtworkFile(files[0]);
      message.success(`정상적으로 업로드되었습니다.`);
      setFileLoading({
        type: 'upload',
        loading: false,
      });
    }
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if (
      [...e.dataTransfer.files].filter((file) => file.type === '').length > 0
    ) {
      message.warn('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    commonFileUpload(e.dataTransfer.files);
  };

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;

    commonFileUpload(e.target.files);

    e.target.value = '';
  };

  const preventDefault: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const referToFile = () => fileRef.current?.click();

  const handleRequest = () => {
    if (!screeningId) return;
    setLoadingVisible(true);
    setArtworkFileId(undefined);

    requestFormulaScreening(
      {
        formulaScreeningId: screeningId,
        ...(artworkFile && {
          artwork: artworkFile,
        }),
      },
      {
        onSuccess: (res) => {
          if (res) {
            //HINT: 스크리닝 분석 요청 전송 후 Artwork fileId를 저장
            formulaScreeningArtworkFile(
              { formulaScreeningId: screeningId },
              {
                onSuccess: (res) => {
                  const { fileId } = res.data.result;
                  setExistedArtworkFile(res.data.result);
                  setArtworkFile(null);
                  setArtworkFileId(fileId);
                },
              },
            );
          }
        },
        onError: () => {
          setLoadingVisible(false);
        },
      },
    );
  };

  const handleCancel = () => {
    if (!screeningId) return;
    cancelFormulaScreening(
      { formulaScreeningId: screeningId },
      {
        onSuccess: (res) => {
          setLoadingVisible(false);
        },
        onError: () => {
          setLoadingVisible(false);
        },
      },
    );
  };

  return (
    <Container>
      <InnerContainer>
        <LoadingModal
          visible={loadingVisible}
          textType="BODY_2"
          content={`업로드하신 파일을 스크리닝중입니다.\n시간이 소요될 수 있으니 기다려 주세요 :)`}
          onCancel={handleCancel}
          canCacel={!requestFormulaScreeningLoading}
        />
        <Flex
          gap={8}
          style={{
            marginTop: 56,
            paddingBottom: 8,
            borderBottom: `2px solid ${palette.PRIMARY50}`,
            width: '100%',
          }}
          align="center"
        >
          <Flex gap={4} align="center">
            <Icon name="robot" size={24} />
            <Typography.Text type="TITLE_1">Artwork 업로드</Typography.Text>
          </Flex>
        </Flex>
        <DescriptionContainer gap={4} dir="column" style={{ height: 92 }}>
          <Typography.Text style={{ fontSize: 14 }}>
            · 고화질 이미지로 업로드해 주세요. 화질이 떨어지면 스크리닝에 오류가
            있을 수 있습니다.
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
            ·
            <span style={{ textDecoration: 'underline', marginLeft: 4 }}>
              아래 업로드 예시 이미지를 참고하여 INGREDIENTS가 포함되어 있는
              이미지로 업로드해 주세요.
            </span>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            · 한가지 제품만 스크리닝이 가능하며 Artwork 파일에 여러개의 전성분이
            있는 경우 영역을 선택하여 검토할 수 있습니다.
          </Typography.Text>
        </DescriptionContainer>
        <Flex dir="column" gap={16} gutter={{ top: 24 }}>
          <Typography.Text style={{ fontSize: 18 }}>
            업로드 예시
          </Typography.Text>
          <Flex gap={24} justify="center" style={{ width: '100%' }}>
            {exampleImages.map(({ id, src, width }) => {
              return (
                <ImgItem justify="center" align="center" key={id}>
                  <img style={{ width }} src={src} alt="써티코스" />
                </ImgItem>
              );
            })}
          </Flex>
        </Flex>
        <ArtworkContainer>
          {!artworkFile && !existedArtworkFile ? (
            <UploadContainer
              ref={containerRef}
              dragging={fileDragState === 'dragging'}
              onDragEnter={handleFileDragEnter}
              onDragLeave={handleFileDragLeave}
              onDragOver={preventDefault}
              onDrop={handleFileDragUpload}
              align="center"
              justify="center"
            >
              <input
                type="file"
                ref={fileRef}
                multiple={true}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                accept=".pdf, .jpg, .jpeg, .png,.PDF,.JPG,.JPEG,.PNG"
              />
              {fileLoading?.type === 'upload' && fileLoading.loading && (
                <Flex dir="column" align="center" gap={8}>
                  <LoadingIndicator size="md" />
                  <Typography.Text
                    style={{
                      fontSize: 16,
                      color: palette.SLATE_GRAY70,
                      fontWeight: 500,
                    }}
                  >
                    파일을 업로드 하는 중...
                  </Typography.Text>
                </Flex>
              )}
              {!fileLoading?.loading && (
                <Flex
                  dir="column"
                  align="center"
                  justify="center"
                  onClick={referToFile}
                >
                  <Icon
                    name="uploadDocIcon"
                    size={48}
                    color="SLATE_GRAY50"
                    style={{ marginBottom: 16 }}
                  />
                  <Typography.Text
                    style={{
                      color: palette.SLATE_GRAY60,
                      fontSize: 16,
                      fontWeight: 500,
                      marginBottom: 8,
                    }}
                  >
                    버튼 클릭 혹은 파일 끌어서 넣기
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      color: palette.SLATE_GRAY60,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    업로드 가능한 파일 : pdf, jpg, jpeg, png
                  </Typography.Text>
                  <Button
                    loading={fileLoading?.loading}
                    icon={
                      <Icon
                        name="upload"
                        size={18}
                        color="GRAY50"
                        style={{ marginRight: 4 }}
                      />
                    }
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderColor: palette.GRAY40,
                      color: palette.GRAY70,
                      height: 32,
                      marginTop: 16,
                      padding: '6px 8px',
                    }}
                  >
                    파일 업로드
                  </Button>
                </Flex>
              )}
            </UploadContainer>
          ) : (
            <PreviewContainer dir="column" gap={16} style={{ width: '100%' }}>
              <FileViewer
                title=""
                file={artworkFile || existedArtworkFile?.url}
                bodyStyle={{ height: 560, borderColor: palette.GRAY30 }}
              />
              <FileItem align="center" justify="space-between">
                <Flex align="center" gap={8}>
                  <Icon name="clip" size={18} color="SLATE_GRAY70" />
                  <Typography.Text
                    style={{ fontSize: 12, color: palette.SLATE_GRAY70 }}
                  >
                    {artworkFile?.name || existedArtworkFile?.filename}
                  </Typography.Text>
                </Flex>
                <Icon
                  name="close"
                  size={18}
                  color="SLATE_GRAY70"
                  onClick={() => {
                    setArtworkFile(null);
                    setExistedArtworkFile(null);
                  }}
                />
              </FileItem>
            </PreviewContainer>
          )}
        </ArtworkContainer>
      </InnerContainer>
      <StickyButtonWrapper>
        <Button
          htmlType="button"
          style={{
            width: 200,
            height: 56,
            fontSize: 18,
          }}
          type="default"
          onClick={() => setStep(2)}
        >
          이전
        </Button>
        <Button
          style={{
            width: 200,
            height: 56,
            fontSize: 18,
          }}
          htmlType="button"
          type="primary"
          onClick={handleRequest}
          disabled={!artworkFile && !existedArtworkFile}
        >
          스크리닝 하기
        </Button>
      </StickyButtonWrapper>
    </Container>
  );
};

const Container = styled.div``;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;

const ArtworkContainer = styled.div``;
const PreviewContainer = styled(Flex)`
  margin-top: 24px;
  img {
    width: auto;
    max-width: 100%;
    max-height: 496px;
  }
  .react-pdf__Page__canvas {
    max-height: 496px;
    max-width: 100%;
    object-fit: contain;
  }
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 72px;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const ImgItem = styled(Flex)`
  background-color: ${palette.GRAY10};
  border-radius: 4px;
  width: 304px;
  height: 180px;
  border: 1px solid ${palette.GRAY30};
`;

const UploadContainer = styled(Flex)<{ dragging: boolean }>`
  width: 100%;
  background-color: white;
  height: 560px;
  border-radius: 8px;
  padding: 52px 32px;
  margin-top: 24px;
  cursor: pointer;
  transition: border 100ms linear;
  border: 1px solid ${palette.GRAY30};
  ${({ dragging }) =>
    dragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
  `}
`;

const FileItem = styled(Flex)`
  width: 100%;
  border-radius: 4px;
  background-color: ${palette.SLATE_GRAY10};
  padding: 11px 16px;
  color: ${palette.SLATE_GRAY70};
`;

export default ArtworkStep3;
