import { Button, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import {
  useNMPABasicInfoComplete,
  useNMPAOngoingDocs,
} from 'service/material/nmpa/nmpa';
import { DocumentStatus } from 'types/common';
import {
  BasicInfoStatus,
  DocumentCode,
  NMPAMaterialProgressStatus,
  NMPAOngoingDocs,
} from 'types/material/nmpa/nmpa';

const Container = styled.div`
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 18px 16px;
  }

  .ant-btn[disabled] {
    background: ${palette.SLATE_GRAY30};
    border-color: #fff;
    color: ${palette.SLATE_GRAY60};
    opacity: 1;
  }
`;

const NMPADocumentList = ({ materialId }: { materialId: number }) => {
  const { nmpaOngoingDocs, basicInfoStatus, isLoading } = useNMPAOngoingDocs({
    materialId,
  });
  const {
    completeBasicInfo,
    isLoading: isCompleteLoading,
  } = useNMPABasicInfoComplete();
  const history = useHistory();
  const { openAlertModal } = useModal();

  // 등록 페이지에서만 true를 가질 수 있으며, 기본 정보 | 기본 특성 | 상세 정보가 입력 완료 상태여야함
  const registerAvailable = useMemo(() => {
    if (!nmpaOngoingDocs?.length || basicInfoStatus !== BasicInfoStatus.INPUT)
      return false;

    const needCode = [
      DocumentCode.BASIC,
      DocumentCode.BAT,
      DocumentCode.DETAIL,
    ];

    return (
      nmpaOngoingDocs.filter(
        (doc) =>
          (doc.status === DocumentStatus.ONG ||
            doc.status === DocumentStatus.FIN) &&
          needCode.find(
            (code) =>
              doc.documentCode.replace('01', '').toLocaleLowerCase() === code,
          ),
      ).length === 3
    );
  }, [nmpaOngoingDocs]);

  const handleFinishInput = () => {
    const handleOk = () => {
      completeBasicInfo(materialId, {
        onSuccess: () => {
          message.success('입력 완료 처리되었습니다.');
          history.push(path.material.nmpa.material.list, {
            progressStatus: NMPAMaterialProgressStatus.INPUT_FINISH,
          });
        },
      });
    };

    openAlertModal({
      content: (
        <Typography.Text type="BODY_2" align="center">
          해당 원료는{' '}
          <Typography.Text type="BODY_2" medium inline>
            입력 완료 원료 탭
          </Typography.Text>
          으로 이동하며,
          <br />
          취득 신청이 가능하게 됩니다.
        </Typography.Text>
      ),
      okText: '입력 완료 처리',
      width: 400,
      onOk: handleOk,
    });
  };

  const navigateDocument = (documentCode: string) => () => {
    const documentCodeWithout01 = documentCode.replace('01', '').toLowerCase();

    let documentUrl = path.material.nmpa.material.root;

    if (documentCodeWithout01 === DocumentCode.BASIC) {
      documentUrl += `/${DocumentCode.BASIC}?materialId=${materialId}`;
    } else {
      documentUrl += `/${materialId}/${documentCodeWithout01}`;
    }

    history.push(documentUrl);
  };

  const columns: ColumnsType<NMPAOngoingDocs> = [
    {
      title: '구분',
      dataIndex: 'no',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: '입력정보',
      render: (_, { documentName, documentCode }) => (
        <Typography.Text
          type="BODY_2"
          onClick={navigateDocument(documentCode)}
          style={{
            fontWeight: 500,
            cursor: 'pointer',
            color: palette.GRAY90,
          }}
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리현황',
      align: 'center',
      render: (_, { status, isOptional, documentCode }) => {
        if (basicInfoStatus === BasicInfoStatus.FINISH) {
          return (
            <Typography.Text type="BODY_2" color="GRAY70">
              입력 완료
            </Typography.Text>
          );
        } else if (basicInfoStatus === BasicInfoStatus.ONGOING) {
          if (status === DocumentStatus.MOD) {
            return (
              <Tags.Mark
                type="arrow"
                color="blue"
                onClick={navigateDocument(documentCode)}
              >
                보완 필요
              </Tags.Mark>
            );
          }
          return <Tags.Mark color="lightGray">검토중</Tags.Mark>;
        }

        switch (status) {
          case DocumentStatus.ONG:
          case DocumentStatus.FIN: {
            return (
              <Typography.Text type="BODY_2" color="GRAY70">
                입력 완료
              </Typography.Text>
            );
          }
          case DocumentStatus.MOD:
          default: {
            let buttonName = '필수 입력';
            if (isOptional) {
              buttonName = '선택 입력';
            }

            return (
              <Tags.Mark color="blue" onClick={navigateDocument(documentCode)}>
                {buttonName}
              </Tags.Mark>
            );
          }
        }
      },
    },
    {
      title: '최근 입력자 명',
      align: 'center',
      render: (_, { modifierName, modifierCompanyName }) =>
        modifierName ? `${modifierName} (${modifierCompanyName})` : '-',
    },
    {
      title: '입력 일시',
      align: 'center',
      render: (_, { registerDt }) => registerDt || '-',
    },
  ];

  return (
    <Container>
      <Table
        size="small"
        columns={columns}
        rowKey="documentCode"
        dataSource={nmpaOngoingDocs}
        pagination={false}
        loading={isLoading}
        style={{ marginTop: 24 }}
      />
      {registerAvailable && (
        <FooterBox>
          <Button
            type="primary"
            onClick={handleFinishInput}
            loading={isCompleteLoading}
          >
            입력 완료 처리
          </Button>
        </FooterBox>
      )}
    </Container>
  );
};

export default NMPADocumentList;
