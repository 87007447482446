import { Button, Form } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useQCQACreateProduct } from 'service/brand/qcqa/qcqa';
import { FileInfo } from 'types/brand/qcqa';
import { UploadFile } from 'types/common';
import QCQAProductBasic from './QCQAProductBasic';
import QCQAProductDocument from './QCQAProductRegisterDocs';

const Container = styled.div``;

const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  width: 528px;
  background-color: ${palette.ETC_BG_BLUE};
`;

const Tab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 44px;
  background-color: ${({ active }) => (active ? '#fff' : palette.ETC_BG_BLUE)};
  color: ${({ active }) => (active ? palette.GRAY90 : palette.GRAY50)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
`;

export interface QCQAProductForm {
  brandName: string;
  productNameKo: string;
  productNameEn: string;
}

const QCQAProduct = () => {
  const isBlockingGoBack = useRef<boolean>(true);
  const [form] = Form.useForm<QCQAProductForm>();
  const [tab, setTab] = useState<'basic' | 'document'>('basic');
  const [isRegistable, setIsRegistable] = useState(false);
  const [screeningFileData, setScreeningFileData] = useState<{
    fileInfo: UploadFile;
    file: File;
  }>();
  const [fileMapBeforeUploading, setFileMapBeforeUploading] = useState<
    Record<number, FileInfo[]>
  >({});

  const { openAlertModal } = useModal();
  const {
    createQCQAProduct,
    isCreateQCQAProductLoading,
  } = useQCQACreateProduct();

  const handleSubmit = (_: QCQAProductForm) => {
    const formValues: QCQAProductForm = form.getFieldsValue(true);
    const tmpFileMapBeforeUploading: Record<
      number,
      FileInfo[]
    > = structuredClone(fileMapBeforeUploading);

    const records = [];
    for (const qcqaDocumentId in tmpFileMapBeforeUploading) {
      if (!tmpFileMapBeforeUploading[qcqaDocumentId]?.length) continue;

      records.push({
        qcqaUserDocumentId: Number(qcqaDocumentId),
        attachIds: tmpFileMapBeforeUploading[qcqaDocumentId].map(
          (file) => file.attachId,
        ),
        ...(screeningFileData?.fileInfo.attachId &&
          tmpFileMapBeforeUploading[qcqaDocumentId]
            .map((item) => item.attachId)
            .includes(screeningFileData?.fileInfo.attachId) && {
            formulaFile: screeningFileData?.file,
          }),
      });
    }

    createQCQAProduct(
      {
        ...formValues,
        records,
      },
      {
        onSuccess: (res) => {
          openAlertModal({
            content:
              '품질 제품이 등록되었습니다.\n제조 번호를 이어서 등록하시겠습니까?',
            onCustomClose: () => {
              isBlockingGoBack.current = false;
              history.push(path.qcqa.management.root);
            },
            onOk: () => {
              isBlockingGoBack.current = false;
              const qcqaProductId = res.data.result.qcqaProductId;
              history.push(path.qcqa.registration.lot, {
                qcqaProductId,
              });
            },
          });
        },
      },
    );
  };

  const handleFormFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    const { brandName, productNameKo, productNameEn } = form.getFieldsValue();
    if (
      brandName &&
      (productNameKo || productNameEn) &&
      allFields.every((field) => field.errors && field.errors.length === 0)
    ) {
      setIsRegistable(true);
    } else {
      setIsRegistable(false);
    }
  };

  useEffect(() => {
    const getIsFieldEmpty = () => {
      const { brandName, productNameKo, productNameEn } = form.getFieldsValue(
        true,
      );

      return (
        !brandName &&
        !productNameKo &&
        !productNameEn &&
        Object.values(fileMapBeforeUploading).every((item) =>
          Array.isArray(item) ? item.length === 0 : !item,
        )
      );
    };

    const unblock = history.block(({ pathname }, action) => {
      if (
        !getIsFieldEmpty() &&
        isBlockingGoBack.current &&
        (action !== 'REPLACE' || pathname === path.logout)
      ) {
        openAlertModal({
          content:
            '품질 제품이 등록되지 않았습니다.\n이 페이지에서 나가시겠습니까?',
          okText: '나가기',
          closeText: '계속 등록',
          onOk: () => {
            isBlockingGoBack.current = false;
            if (action === 'POP') {
              history.goBack();
            } else {
              history.push(pathname);
            }
          },
        });

        return false;
      }
    });

    const preventLeave = (e: BeforeUnloadEvent) => {
      e.preventDefault();

      if (getIsFieldEmpty()) {
        return;
      }

      const a = document.activeElement as HTMLAnchorElement | null;

      if ((a?.href || '').endsWith('.xlsx')) {
        a?.blur();
        return;
      }

      e.returnValue = '';
      return false;
    };

    window.addEventListener('beforeunload', preventLeave);

    return () => {
      window.removeEventListener('beforeunload', preventLeave);
      unblock();
    };
  }, [tab, form, fileMapBeforeUploading]);

  return (
    <Container>
      <Form
        form={form}
        colon={false}
        preserve
        layout="vertical"
        onFinish={handleSubmit}
        onFieldsChange={handleFormFieldsChange}
        initialValues={{
          productNameKo: '',
          productNameEn: '',
        }}
      >
        <Flex justify="space-between" align="center">
          <TabContainer>
            <Tab active={tab === 'basic'} onClick={() => setTab('basic')}>
              기본 정보
            </Tab>
            <Tab
              active={tab === 'document'}
              onClick={() => {
                if (isRegistable) {
                  setTab('document');
                } else {
                  form.validateFields();
                }
              }}
            >
              서류 등록
            </Tab>
          </TabContainer>
          <Button
            disabled={!isRegistable}
            loading={isCreateQCQAProductLoading}
            type="primary"
            onClick={form.submit}
            style={{ width: 160, height: 44 }}
          >
            등록
          </Button>
        </Flex>
        {tab === 'basic' ? (
          <QCQAProductBasic form={form} />
        ) : (
          <QCQAProductDocument
            fileMapBeforeUploading={fileMapBeforeUploading}
            onChangeFileMapBeforeUploading={setFileMapBeforeUploading}
            screeningFileData={screeningFileData}
            onChangescreeningFileData={setScreeningFileData}
            addLoading={isCreateQCQAProductLoading}
          />
        )}
      </Form>
    </Container>
  );
};

export default QCQAProduct;
