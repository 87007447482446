import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';

import {
  exceptKoreanRule,
  generateFormRules,
  numberOneToNinetynineRule,
  requireRule,
} from 'lib/validate';
import palette from 'lib/styles/palette';
import { productSpecificationsOption } from 'lib/consts';
import { PreventEnterInput, Tip, Typography } from 'components/system';
import FooterBox from 'components/FooterBox';
import RouteLeaveGuard from 'components/RouteLeaveGuard';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import { useProductSpec } from 'service/brand/product/specifications';
import { DocumentCode } from 'types/product';
import SelectOptionInput from 'components/ui/Select/SelectOptionInput';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const productInfoValidCodes = [
  'PS01-CO',
  'PS01-TA',
  'PS01-ST',
  'PS01-AA',
  'PS01-FOU',
  'PS01-POPIM',
  'PS01-APU',
  'PS01-PAO',
];

const detailDescriptionValidCodes = [
  'PS01-HTU',
  'PS01-W',
  'PS01-DC',
  'PS01-MC',
  'PS01-M',
];

const ProductSpecBlock = styled.div`
  .target_age:has(.ant-form-item-explain-error) {
    .ant-form-item-explain-error {
      margin-left: -300px !important;
    }
  }
`;

const TitleBullet = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${palette.PRIMARY50};
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  vertical-align: top;
  line-height: 24px;
`;

const StyledTextarea = styled(Input.TextArea)`
  resize: none;
`;

const ProductSpec = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const {
    form,
    updateMode,
    getLoading,
    saveLoading,
    fetchLoading,
    submitSuccess,
    onSaveDraft,
    onSubmit,
  } = useProductSpec(productId, countryId);
  const readOnlyMode = useSelector(
    ({ certificate }: any) => certificate.readOnlyMode,
  );

  return (
    <ProductSpecBlock>
      <RouteLeaveGuard
        when={!updateMode && !submitSuccess}
        description={
          '현재 페이지를 벗어나면 입력하신 데이터가 사라집니다.\n데이터가 유지되길 원하시는 경우, ‘계속 입력’ 버튼을 누른 후\n임시 저장 혹은 등록 하시길 바랍니다.'
        }
      />
      <Row justify="end" gutter={8} style={{ marginBottom: 16 }}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.PS}
          />
        </Col>
      </Row>
      <Spin spinning={getLoading || fetchLoading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Row justify="space-between">
            <Col>
              <Typography.Text
                type="TITLE_1"
                gutter={{ bottom: 24 }}
                style={{ lineHeight: 1, height: 24 }}
              >
                <TitleBullet>1</TitleBullet> 제품 정보 입력
              </Typography.Text>
            </Col>
            {updateMode && (
              <Col>
                <UpdateLog
                  productId={productId}
                  countryId={countryId}
                  documentCode={DocumentCode.PS}
                  validCodes={productInfoValidCodes}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16} justify="space-between">
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                name="countryOrigin"
                label="Country of Origin (원산지(영문))"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input placeholder="예시: Korea" disabled={readOnlyMode} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                required
                shouldUpdate={(prev, curr) =>
                  prev.productPeriodUnit !== curr.productPeriodUnit
                }
                label={
                  <Row gutter={4} align="middle">
                    <Col>Shelf Life (제품 총 유통기한)</Col>
                    <Col>
                      <Tip>
                        <Typography.Text type="BODY_2">
                          예시 : 30 month(s), 3year(s)
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                style={{ marginBottom: 0 }}
                rules={generateFormRules({
                  required: true,
                })}
              >
                {() => (
                  <Row gutter={8} justify="space-between">
                    <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                      <Form.Item
                        name="productPeriod"
                        rules={[requireRule, numberOneToNinetynineRule]}
                      >
                        <PreventEnterInput disabled={readOnlyMode} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                      <Form.Item
                        name="productPeriodUnit"
                        rules={generateFormRules({
                          required: true,
                        })}
                      >
                        <Select
                          options={
                            productSpecificationsOption.productPeriodOptions
                          }
                          disabled={readOnlyMode}
                          placeholder="기간 선택"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                required
                shouldUpdate={(prev, curr) => prev.paoUnit !== curr.paoUnit}
                label={
                  <Row gutter={4} align="middle">
                    <Col>PAO (개봉 후 사용기간)</Col>
                  </Row>
                }
                style={{ marginBottom: 0 }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const isPaoUnitImmediately =
                    getFieldValue('paoUnit') === 'use immediately';
                  if (isPaoUnitImmediately) {
                    setFieldsValue({
                      pao: '',
                    });
                  }
                  return (
                    <Row gutter={8} justify="space-between">
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Form.Item
                          name="pao"
                          rules={
                            isPaoUnitImmediately
                              ? []
                              : [requireRule, numberOneToNinetynineRule]
                          }
                        >
                          <PreventEnterInput
                            disabled={readOnlyMode || isPaoUnitImmediately}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Form.Item
                          name="paoUnit"
                          rules={generateFormRules({
                            required: true,
                          })}
                        >
                          <Select
                            options={
                              productSpecificationsOption.productPaoPeriodOptions
                            }
                            disabled={readOnlyMode}
                            placeholder="기간 선택"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                label="Target Age (사용 연령)"
                rules={generateFormRules({
                  required: true,
                  exceptKorean: true,
                })}
                shouldUpdate={(prev, next) =>
                  prev.targetAgeUnit !== next.targetAgeUnit
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <SelectOptionInput
                      selectClassName="target_age"
                      style={{
                        width: 300,
                      }}
                      selectInputStyle={{
                        width: 260,
                        marginRight: '36px',
                      }}
                      parentFormName="targetAgeUnit"
                      rules={generateFormRules({
                        required: true,
                      })}
                      selectPlaceholder=""
                      selectInputPlaceholder=""
                      onSelect={() =>
                        setFieldsValue({
                          targetAgeUnitDirect: null,
                        })
                      }
                      selectDisabled={readOnlyMode}
                      isOthers={getFieldValue('targetAgeUnit') === 'others'}
                      inputFormName={
                        getFieldValue('targetAgeUnit') === 'others'
                          ? 'targetAgeUnitDirect'
                          : 'targetAgeUnit'
                      }
                      inputDisabled={readOnlyMode}
                      onInputChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>,
                      ) =>
                        setFieldsValue({
                          targetAgeUnitDirect: e.target.value,
                        })
                      }
                      unitDirectValue={getFieldValue('targetAgeUnitDirect')}
                    >
                      {productSpecificationsOption.productTargetAgeOptions.map(
                        (option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ),
                      )}
                      <Select.Option key="others" value="others">
                        직접입력
                      </Select.Option>
                    </SelectOptionInput>
                  );
                }}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                name="skinType"
                label="Skin Type (피부 타입)"
                rules={[requireRule]}
              >
                <Select
                  placeholder="피부 타입 선택"
                  options={productSpecificationsOption.skinTypeOptions}
                  disabled={readOnlyMode}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                name="applicationArea"
                label="Application Area (사용 부위)"
                rules={generateFormRules({
                  required: true,
                  exceptKorean: true,
                })}
              >
                <Input disabled={readOnlyMode} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <Row gutter={4} align="middle">
                    <Col>
                      Applied per Use (평균 1회 사용량 - 성인 60kg 기준)
                    </Col>
                    <Col>
                      <Tip>
                        <Typography.Text type="BODY_2">
                          마스크팩과 같이 개봉 후 즉시 사용하는 제품의 경우,
                          <br /> 제품의 표시용량을 무게 단위 (g/mg)로 입력해
                          주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                required
              >
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="appliedPerUse"
                      rules={[
                        requireRule,
                        {
                          pattern: /^([1-9](\d?)(\d?)|0)(\.\d{1,6})?$|(^100$)/,
                          message: '0~999, 소수점 6째자리 숫자만 입력 가능',
                        },
                      ]}
                    >
                      <PreventEnterInput
                        disabled={readOnlyMode}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="appliedPerUseUnit"
                      rules={generateFormRules({
                        required: true,
                      })}
                    >
                      <Select
                        placeholder="단위 선택"
                        disabled={readOnlyMode}
                        options={
                          productSpecificationsOption.appliedPerUseOptions
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <Row gutter={4} align="middle">
                    <Col>Frequency of Use (사용 빈도)</Col>
                    <Col>
                      <Tip>
                        <Typography.Text type="BODY_2">
                          소수점 셋째 자리 이상일 시, <br />
                          소수점 둘째 자리로 반올림해 주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                required
              >
                <Row gutter={8}>
                  <Col>
                    <Form.Item
                      name="frequencyOfUse"
                      rules={[
                        requireRule,
                        {
                          pattern: /^([1-9](\d?)(\d?))(\.\d{1,2})?$|(^100$)/,
                          message: '0~999, 소수점 둘째 자리 숫자만 입력 가능',
                        },
                      ]}
                    >
                      <PreventEnterInput
                        disabled={readOnlyMode}
                        style={{ width: 216 }}
                        suffix="time(s)"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Typography.Text type="TITLE_2" style={{ lineHeight: 2.4 }}>
                      /
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Form.Item
                      name="frequencyOfUseUnit"
                      rules={generateFormRules({
                        required: true,
                      })}
                      required
                    >
                      <Select
                        style={{ width: 216 }}
                        placeholder="기간 선택"
                        disabled={readOnlyMode}
                        options={
                          productSpecificationsOption.frequencyOfUseOptions
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 24 }}>
            <Col>
              <Typography.Text
                type="TITLE_1"
                gutter={{ bottom: 24 }}
                style={{ lineHeight: 1, height: 24 }}
              >
                <TitleBullet>2</TitleBullet> 상세 설명 입력
              </Typography.Text>
            </Col>
            {updateMode && (
              <Col>
                <UpdateLog
                  productId={productId}
                  countryId={countryId}
                  documentCode={DocumentCode.PS}
                  validCodes={detailDescriptionValidCodes}
                />
              </Col>
            )}
          </Row>
          <Form.Item
            label="How to Use (사용 방법)"
            name="useManual"
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
            })}
          >
            <StyledTextarea
              rows={3}
              placeholder="예시:  Apply an appropriate amount to face. Wash off after 15 minutes. Recommend to use 2~3 times a week"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Warnings (주의사항)"
            name="warnings"
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
            })}
          >
            <StyledTextarea
              rows={3}
              placeholder="예시:  For external use only. Do not use it on damaged skin. Avoid contact with eyes. Discontinue use if signs of irritation appear.  Keep out of reach of children."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Description &amp; Characteristics (제품 특징)"
            name="productFeature"
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
            })}
          >
            <StyledTextarea
              rows={3}
              placeholder="예시: Anti-wrinkle, Brightening, Moisturizing, Soothing"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Marketing Claims (제품 문구)"
            name="marketingClaims"
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
            })}
          >
            <StyledTextarea
              rows={3}
              placeholder="제품 단상자에 적혀 있는 모든 마케팅 문구를 입력해 주세요."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="Miscellaneous (기타 사항)"
            name="miscellaneous"
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
            })}
          >
            <StyledTextarea
              rows={3}
              placeholder="브랜드 스토리, 메인 컨셉성분, 특별 기능을 입력해 주세요."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <FooterBox style={{ paddingTop: 40 }}>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {!updateMode ? (
                <Button loading={saveLoading} onClick={onSaveDraft}>
                  임시 저장
                </Button>
              ) : (
                <CorrectRequestWithoutChangeButton
                  documentCode={DocumentCode.PS}
                />
              )}
              <Button loading={fetchLoading} type="primary" htmlType="submit">
                {!updateMode ? '등록' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </ProductSpecBlock>
  );
};

export default ProductSpec;
