import { Button, Modal } from 'antd';
import { Typography } from 'components/system';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

const StyledModal = styled(Modal)`
  border-radius: 4px;

  .ant-modal-content {
    border-radius: 4px;
  }
`;

export interface AlertModalProps {
  content: string | ReactNode;
  width?: number;
  bodyStyle?: React.CSSProperties;
  onClose?: () => void;
  onCustomClose?: () => void;
  onOk?: () => void;
  closeText?: string;
  okText?: string;
  okLoading?: boolean;
  noCancelButton?: boolean;
  noCloseIcon?: boolean;
}

const AlertModal = ({
  content,
  width = 400,
  bodyStyle = {
    padding: '24px 24px 32px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  onClose,
  onCustomClose,
  onOk,
  closeText = '취소',
  okText = '확인',
  okLoading = false,
  noCancelButton = false,
  noCloseIcon = false,
}: AlertModalProps) => {
  return (
    <StyledModal
      visible={true}
      bodyStyle={bodyStyle}
      closable={false}
      centered
      width={width}
      footer={false}
    >
      <Flex justify="flex-end" gutter={{ bottom: noCloseIcon ? 24 : 8 }}>
        {!noCloseIcon && (
          <Icon
            name="close"
            size={24}
            onClick={() => {
              onCustomClose?.();
              onClose?.();
            }}
          />
        )}
      </Flex>
      {typeof content === 'string' ? (
        <Typography.Text type="BODY_2">{content}</Typography.Text>
      ) : (
        content
      )}
      <Flex justify="center" align="center" gap={8} gutter={{ top: 24 }}>
        {!noCancelButton && (
          <Button
            key="back"
            onClick={() => {
              onCustomClose?.();
              onClose?.();
            }}
          >
            {closeText}
          </Button>
        )}
        <Button
          key="submit"
          type="primary"
          loading={okLoading}
          onClick={() => {
            onOk?.();
            onClose?.();
          }}
          style={noCancelButton ? { width: 73 } : {}}
        >
          {okText}
        </Button>
      </Flex>
    </StyledModal>
  );
};

export default AlertModal;
