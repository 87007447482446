import { memo } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';

const MSDSAccidentalReleaseBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSAccidentalRelease = () => {
  return (
    <MSDSAccidentalReleaseBlock>
      <Form.Item
        label={
          <AlphabetLabel alphabet="A" style={{ width: 538 }}>
            Personal precautions, protective equipment and emergency procedures
          </AlphabetLabel>
        }
        name={['accidentalRelease', 'personalPrecautions']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not required."
      >
        <Input.TextArea
          placeholder="인체를 보호하기 위해 필요한 조치 사항 및 보호구"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">Environmental precautions</AlphabetLabel>
        }
        name={['accidentalRelease', 'environmentalPrecautions']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Not required."
      >
        <Input.TextArea
          placeholder="환경을 보호하기 위해 필요한 조치사항"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="C">
            Methods and materials for containment and cleaning up
          </AlphabetLabel>
        }
        name={['accidentalRelease', 'containmentAndCleaningUp']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Collect the spill. Finish the rest with plenty of water."
      >
        <Input.TextArea
          placeholder="정화 또는 제거 방법"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSAccidentalReleaseBlock>
  );
};

export default memo(MSDSAccidentalRelease);
