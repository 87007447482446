import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import styled from 'styled-components';

import { Flex } from 'components/ui';
import { generateFormRules } from 'lib/validate';

const StyledModal = styled(Modal)`
  border-radius: 4px;

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    height: min-content !important;
    border: none;
    padding-top: 24px !important;
    padding-bottom: 0 !important;
    .ant-modal-title {
      font-size: 18px !important;
      font-weight: 500 !important;
    }
  }

  .ant-modal-body {
    padding: 40px 24px !important;
    white-space: pre-wrap !important;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 32px;

    button {
      padding: 12px 16px !important;
      height: 44px !important;
      border-radius: 4px;
      font-size: 14px !important;
      align-items: center !important;
      display: flex !important;
      justify-content: center !important;
    }
  }

  .ant-form-item {
    padding-left: 16px;
  }

  .ant-form-item-explain {
    /* align-items: center !important; */
  }

  .ant-form-item-label > label {
    /* margin-left: 15px !important; */
  }
`;

export interface DocsItemModalProps {
  columnId?: string;
  updateMode?: boolean;
  okLoading?: boolean;
  docItemName?: {
    [key: string]: string;
  };
  onClose?: () => void;
  onSubmit: (itemName: string) => void;
}

const DocsItemModal = ({
  columnId,
  updateMode = false,
  okLoading,
  docItemName,
  onClose,
  onSubmit,
}: DocsItemModalProps) => {
  const [form] = Form.useForm();
  const [itemName, setItemName] = useState('');

  useEffect(() => {
    if (!columnId || !docItemName) return;

    if (updateMode) {
      setItemName(docItemName[columnId]);
      form.setFieldsValue({
        name: docItemName[columnId],
      });
    }
  }, []);

  return (
    <StyledModal
      title={updateMode ? '항목명 수정' : '항목 추가'}
      centered
      width={560}
      visible={true}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={okLoading}
          onClick={form.submit}
        >
          {updateMode ? '수정' : '추가'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={() => {
          onSubmit(itemName);
          onClose?.();
        }}
        colon={false}
      >
        <Flex dir="column" gap={8}>
          <Form.Item
            name="name"
            label="항목명"
            required
            rules={generateFormRules({
              required: true,
              maxLength: 20,
            })}
          >
            <Input
              style={{ width: 480 }}
              value={itemName}
              onChange={(e) => {
                setItemName(e.target.value);
              }}
            />
          </Form.Item>
        </Flex>
      </Form>
    </StyledModal>
  );
};

export default DocsItemModal;
