import QCQAProductList from 'components/brand/qcqa/QCQAProductList';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import { useSearchParams } from 'hook/useSearchParams';

export interface QCQAProductListPageSearchParams {
  page: number;
  productName: string;
  brandName: string;
}

const QCQAProductListPage = () => {
  const { searchParams, onChangeSearchParams } = useSearchParams({
    path: path.qcqa.management.root,
  });

  const queryParams: QCQAProductListPageSearchParams = {
    page: Number(searchParams.get('page')) || 1,
    productName: searchParams.get('productName') || '',
    brandName: searchParams.get('brandName') || '',
  };

  return (
    <PageTemplate
      title="품질 제품 관리"
      back={false}
      description="화장품책임판매업자의 품질서류관리 및 보관 의무 [화장품법 시행규칙 제 12조] 이행을 위해 하단의 서류들을 보관하시기 바랍니다."
    >
      <QCQAProductList
        queryParams={queryParams}
        onChangeSearchParams={onChangeSearchParams}
      />
    </PageTemplate>
  );
};

export default QCQAProductListPage;
