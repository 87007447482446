import { useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import produce from 'immer';
import { Rule } from 'rc-field-form/lib/interface';

import palette from 'lib/styles/palette';
import { MinusIcon, Typography } from 'components/system';
import Table from 'components/Table';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import {
  ProductSampleProcessMapProcessType,
  ProductSampleProcessMapItemForm,
} from 'types/manufacturer/productSample';
import {
  useIsProductSampleConfirmed,
  useProductSampleId,
  useProductSampleProcessMap,
  useProductSampleProcessMapPhases,
  usePublishProcessMapPreview,
} from 'service/manufacturer/productSample';
import useUpdateMode from 'hook/useUpdateMode';
import FooterBox from 'components/FooterBox';
import { base64ToFile } from 'lib/file';
import FileViewer from 'components/file/FileViewer';
import Icon from 'components/ui/Icon/Icon';

const processTypeOptions = [
  {
    label: '별도 용해',
    value: ProductSampleProcessMapProcessType.DISSOLVING,
  },
  {
    label: '혼합 (단일)',
    value: ProductSampleProcessMapProcessType.MIXING,
  },
  {
    label: '혼합 (다중)',
    value: ProductSampleProcessMapProcessType.MULTIPLE_MIXING,
  },
  {
    label: '유화',
    value: ProductSampleProcessMapProcessType.EMULSIONING,
  },
  {
    label: '다중 유화',
    value: ProductSampleProcessMapProcessType.MULTIPLE_EMULSIONING,
  },
  {
    label: '가용화',
    value: ProductSampleProcessMapProcessType.SOLUBILIZING,
  },
  {
    label: '냉각',
    value: ProductSampleProcessMapProcessType.COOLING,
  },
  {
    label: '중화',
    value: ProductSampleProcessMapProcessType.NEUTRALIZING,
  },
  {
    label: '롤링',
    value: ProductSampleProcessMapProcessType.ROLLING,
  },
  {
    label: '탈포',
    value: ProductSampleProcessMapProcessType.DEGASSING,
  },
  {
    label: '필터링',
    value: ProductSampleProcessMapProcessType.FILTERING,
  },
  {
    label: '분산',
    value: ProductSampleProcessMapProcessType.DISPERING,
  },
  {
    label: '직접 입력',
    value: ProductSampleProcessMapProcessType.DIRECT_INPUT,
  },
];

const numberOrSpecialCharacterRule: Rule = {
  pattern: /^[0-9,~±]+$/,
  message: "숫자, 특수문자 (',' , '~', '±' )만 가능",
};

const ProductSampleProcessMapBlock = styled.div`
  .ant-table {
    border-radius: 0;

    table {
      .ant-table-cell {
        padding-left: 16px;
        padding-right: 16px;
      }
      th.ant-table-cell {
        border-top: 2px solid ${palette.PRIMARY50};
        height: 46px;
        text-align: center;
        background-color: ${palette.GRAY10};
      }
      td.ant-table-cell {
        vertical-align: top;
        color: ${palette.GRAY90};

        &:first-child {
          vertical-align: middle;
        }

        .ant-form-item {
          &.ant-form-item-with-help {
            margin-bottom: 12px !important;
          }

          .ant-form-item-control-input {
            position: relative;
            min-height: 32px;
            .ant-input {
              height: 32px;
            }
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input,
          input.ant-input,
          .ant-input-affix-wrapper,
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-input-number-input,
          .ant-picker {
            height: 32px;
          }
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            text-align: left;
            line-height: 30px;
          }
          .ant-form-item-explain {
            position: absolute;
            bottom: -16px;
          }
        }
        .ant-checkbox {
          transform: scale(0.75);
        }
        .ant-checkbox + span {
          padding-left: 2px;
        }
        .ant-btn {
          height: 32px;
        }
      }
    }
  }
`;

const ProductSampleProcessMap = () => {
  const productSampleId = useProductSampleId();
  const updateMode = useUpdateMode();
  const { publishProcessMapPreview, isLoading } = usePublishProcessMapPreview();
  const productSampleProcessMapPhases = useProductSampleProcessMapPhases(
    productSampleId!,
    !updateMode,
  );
  const {
    processMapItems,
    addProductSampleProcessMap,
    addLoading,
    updateProductSampleProcessMap,
    updateLoading,
  } = useProductSampleProcessMap(productSampleId!, updateMode);
  const isProductSampleConfirmed = useIsProductSampleConfirmed(productSampleId);
  const [form] = Form.useForm<{
    processMapItems: ProductSampleProcessMapItemForm[];
  }>();
  const handleChangeProcessType = (
    processMapItems: ProductSampleProcessMapItemForm[],
    index: number,
    processType: ProductSampleProcessMapProcessType,
    prevProcessType: ProductSampleProcessMapProcessType,
  ) => {
    form.setFieldsValue({
      processMapItems: produce(processMapItems, (proxy) => {
        if (
          prevProcessType === ProductSampleProcessMapProcessType.DIRECT_INPUT
        ) {
          proxy[index].directInputText = undefined;
        } else {
          proxy[index].temperature = undefined;
          proxy[index].isNotApplicableTemperature = false;
          proxy[index].rpm = undefined;
          proxy[index].isNotApplicableRpm = false;
          proxy[index].processTime = undefined;
          proxy[index].isUntilProcessCompletion = false;
          if (
            prevProcessType ===
            ProductSampleProcessMapProcessType.MULTIPLE_MIXING
          ) {
            proxy[index].mixedDatas = undefined;
          }
        }
        if (
          processType === ProductSampleProcessMapProcessType.MULTIPLE_MIXING
        ) {
          proxy[index].mixedDatas = [undefined];
        }
      }),
    });
  };
  const handleIssueEnglishProcessMap = () => {
    form.validateFields().then(({ processMapItems }) => {
      publishProcessMapPreview(
        { productSampleId: productSampleId!, processMapItems },
        {
          onSuccess: (res) => {
            const file = base64ToFile(
              `data:application/pdf;base64,${res.data.result}`,
              '영문공정도.pdf',
            );
            Modal.info({
              width: 920,
              icon: null,
              closable: true,
              content: <FileViewer file={file} style={{ paddingTop: 36 }} />,
              okButtonProps: { hidden: true },
            });
          },
        },
      );
    });
  };
  const handleSubmit = ({
    processMapItems,
  }: {
    processMapItems: ProductSampleProcessMapItemForm[];
  }) => {
    if (!updateMode) {
      addProductSampleProcessMap(processMapItems);
    } else {
      updateProductSampleProcessMap(processMapItems);
    }
  };
  useEffect(() => {
    if (processMapItems.length !== 0) {
      form.setFieldsValue({ processMapItems: processMapItems });
    }
  }, [processMapItems]);
  useEffect(() => {
    if (productSampleProcessMapPhases && !updateMode) {
      form.setFieldsValue({
        processMapItems: productSampleProcessMapPhases.map((phase) => ({
          phase,
        })),
      });
    }
  }, [productSampleProcessMapPhases]);

  return (
    <ProductSampleProcessMapBlock>
      <Form form={form} onFinish={handleSubmit}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: '10%' }}>
                <Typography.Text medium color="SLATE_GRAY70">
                  Phase
                </Typography.Text>
              </Table.Th>
              <Table.Th style={{ width: '20%' }}>
                <Typography.Text medium asterisk color="SLATE_GRAY70">
                  공정
                </Typography.Text>
              </Table.Th>
              <Table.Th style={{ width: '24%' }}>
                <Typography.Text medium asterisk color="SLATE_GRAY70">
                  온도
                </Typography.Text>
              </Table.Th>
              <Table.Th style={{ width: '24%' }}>
                <Typography.Text medium asterisk color="SLATE_GRAY70">
                  RPM
                </Typography.Text>
              </Table.Th>
              <Table.Th style={{ width: '22%' }}>
                <Typography.Text medium asterisk color="SLATE_GRAY70">
                  시간 (minutes)
                </Typography.Text>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.processMapItems !== next.processMapItems
              }
            >
              {({ getFieldValue, setFieldsValue }) => {
                const processMapItems = getFieldValue('processMapItems') as
                  | ProductSampleProcessMapItemForm[]
                  | undefined;
                if (!processMapItems)
                  return (
                    <Table.Tr>
                      <Table.Td
                        colSpan={5}
                        style={{ height: 100, textAlign: 'center' }}
                      >
                        <Spin spinning />
                      </Table.Td>
                    </Table.Tr>
                  );
                return processMapItems.map(
                  (
                    {
                      phase,
                      processType,
                      isNotApplicableTemperature,
                      isNotApplicableRpm,
                      isUntilProcessCompletion,
                      mixedDatas,
                    },
                    index,
                  ) => (
                    <Table.Tr key={phase}>
                      <Table.Td>
                        <Form.Item
                          noStyle
                          name={['processMapItems', index, 'phase']}
                        >
                          <Typography.Text type="BODY_2" align="center">
                            {phase}
                          </Typography.Text>
                        </Form.Item>
                      </Table.Td>
                      <Table.Td>
                        <Form.Item
                          name={['processMapItems', index, 'processType']}
                          rules={[requireRule]}
                          normalize={(processType, prevProcessType) => {
                            handleChangeProcessType(
                              processMapItems,
                              index,
                              processType,
                              prevProcessType,
                            );

                            return processType;
                          }}
                        >
                          <Select
                            placeholder="공정 선택"
                            options={processTypeOptions}
                            style={{ textAlign: 'left' }}
                            disabled={isProductSampleConfirmed}
                          />
                        </Form.Item>

                        {processType ===
                          ProductSampleProcessMapProcessType.MULTIPLE_MIXING && (
                          <Form.List
                            name={['processMapItems', index, 'mixedDatas']}
                          >
                            {(fields, { add, remove }) => {
                              const mixPhaseOptions = processMapItems
                                .map(({ phase }) => phase)
                                .filter(
                                  (target) =>
                                    target !== phase &&
                                    !mixedDatas?.includes(target),
                                )
                                .map((phase) => ({
                                  label: `Phase ${phase}`,
                                  value: phase,
                                }));
                              return (
                                <Row gutter={[0, 8]} style={{ marginTop: 24 }}>
                                  {fields.map(({ name }) => (
                                    <Col key={name} flex="100%">
                                      <Row>
                                        {name !== 0 &&
                                          !isProductSampleConfirmed && (
                                            <Col
                                              style={{
                                                position: 'absolute',
                                                top: 4,
                                                left: -30,
                                              }}
                                            >
                                              <MinusIcon
                                                onClick={() => remove(name)}
                                              />
                                            </Col>
                                          )}
                                        <Col flex="100%">
                                          <Form.Item
                                            name={[name]}
                                            rules={[requireRule]}
                                          >
                                            <Select
                                              options={[
                                                ...(mixedDatas?.[name]
                                                  ? [
                                                      {
                                                        label: `Phase ${mixedDatas[name]}`,
                                                        value: mixedDatas[
                                                          name
                                                        ]!,
                                                      },
                                                    ]
                                                  : []),
                                                ...mixPhaseOptions,
                                              ]}
                                              placeholder="Phase 선택"
                                              disabled={
                                                isProductSampleConfirmed
                                              }
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))}
                                  {mixedDatas?.length !==
                                    processMapItems.length - 1 &&
                                    !isProductSampleConfirmed && (
                                      <Col flex="100%">
                                        <Button
                                          block
                                          type="dashed"
                                          icon={
                                            <Icon
                                              name="plus"
                                              color="PRIMARY50"
                                              size={18}
                                              style={{
                                                marginRight: 4,
                                              }}
                                            />
                                          }
                                          onClick={() => add(undefined)}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          Phase
                                        </Button>
                                      </Col>
                                    )}
                                </Row>
                              );
                            }}
                          </Form.List>
                        )}
                      </Table.Td>
                      {typeof processType !== 'undefined' ? (
                        <>
                          {processType !==
                          ProductSampleProcessMapProcessType.DIRECT_INPUT ? (
                            <>
                              <Table.Td>
                                <Row gutter={4} wrap={false}>
                                  <Col flex="auto">
                                    <Form.Item
                                      name={
                                        !isNotApplicableTemperature
                                          ? [
                                              'processMapItems',
                                              index,
                                              'temperature',
                                            ]
                                          : undefined
                                      }
                                      rules={[
                                        requireRule,
                                        numberOrSpecialCharacterRule,
                                      ]}
                                    >
                                      <Input
                                        maxLength={20}
                                        placeholder={
                                          !isNotApplicableTemperature
                                            ? 'ex) 20~25 or 25'
                                            : undefined
                                        }
                                        disabled={
                                          isNotApplicableTemperature ||
                                          isProductSampleConfirmed
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Typography.Text
                                      type="BODY_2"
                                      style={{ lineHeight: '32px' }}
                                    >
                                      ℃
                                    </Typography.Text>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name={[
                                    'processMapItems',
                                    index,
                                    'isNotApplicableTemperature',
                                  ]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    style={{ marginTop: 4, fontSize: 12 }}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldsValue({
                                          processMapItems: produce(
                                            processMapItems,
                                            (proxy) => {
                                              proxy[
                                                index
                                              ].temperature = undefined;
                                              proxy[
                                                index
                                              ].isNotApplicableTemperature =
                                                e.target.checked;
                                            },
                                          ),
                                        });
                                      }
                                    }}
                                    disabled={isProductSampleConfirmed}
                                  >
                                    온도 해당 없음
                                  </Checkbox>
                                </Form.Item>
                              </Table.Td>
                              <Table.Td>
                                <Row gutter={4} wrap={false}>
                                  <Col flex="auto">
                                    <Form.Item
                                      name={
                                        !isNotApplicableRpm
                                          ? ['processMapItems', index, 'rpm']
                                          : undefined
                                      }
                                      rules={[
                                        requireRule,
                                        numberOrSpecialCharacterRule,
                                      ]}
                                    >
                                      <Input
                                        maxLength={20}
                                        placeholder={
                                          !isNotApplicableRpm
                                            ? 'ex) 120 ~ 130 or 200'
                                            : undefined
                                        }
                                        disabled={
                                          isNotApplicableRpm ||
                                          isProductSampleConfirmed
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Typography.Text
                                      type="BODY_2"
                                      style={{ lineHeight: '32px' }}
                                    >
                                      RPM
                                    </Typography.Text>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name={[
                                    'processMapItems',
                                    index,
                                    'isNotApplicableRpm',
                                  ]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    style={{ marginTop: 4, fontSize: 12 }}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldsValue({
                                          processMapItems: produce(
                                            processMapItems,
                                            (proxy) => {
                                              proxy[index].rpm = undefined;
                                              proxy[index].isNotApplicableRpm =
                                                e.target.checked;
                                            },
                                          ),
                                        });
                                      }
                                    }}
                                    disabled={isProductSampleConfirmed}
                                  >
                                    RPM 해당 없음
                                  </Checkbox>
                                </Form.Item>
                              </Table.Td>
                              <Table.Td>
                                <Row gutter={4} wrap={false}>
                                  <Col flex="auto">
                                    <Form.Item
                                      name={
                                        !isUntilProcessCompletion
                                          ? [
                                              'processMapItems',
                                              index,
                                              'processTime',
                                            ]
                                          : undefined
                                      }
                                      rules={[
                                        requireRule,
                                        numberOrSpecialCharacterRule,
                                      ]}
                                    >
                                      <Input
                                        maxLength={20}
                                        placeholder={
                                          !isUntilProcessCompletion
                                            ? 'ex) 30~40 or 120'
                                            : undefined
                                        }
                                        disabled={
                                          isUntilProcessCompletion ||
                                          isProductSampleConfirmed
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Typography.Text
                                      type="BODY_2"
                                      style={{ lineHeight: '32px' }}
                                    >
                                      min
                                    </Typography.Text>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name={[
                                    'processMapItems',
                                    index,
                                    'isUntilProcessCompletion',
                                  ]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    style={{ marginTop: 4, fontSize: 12 }}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldsValue({
                                          processMapItems: produce(
                                            processMapItems,
                                            (proxy) => {
                                              proxy[
                                                index
                                              ].processTime = undefined;
                                              proxy[
                                                index
                                              ].isUntilProcessCompletion =
                                                e.target.checked;
                                            },
                                          ),
                                        });
                                      }
                                    }}
                                    disabled={isProductSampleConfirmed}
                                  >
                                    공정 완료 시까지
                                  </Checkbox>
                                </Form.Item>
                              </Table.Td>
                            </>
                          ) : (
                            <Table.Td colSpan={3}>
                              <Form.Item
                                name={[
                                  'processMapItems',
                                  index,
                                  'directInputText',
                                ]}
                                rules={[requireRule, exceptKoreanRule]}
                              >
                                <Input.TextArea
                                  placeholder="온도 및 RPM 정보를 담은 공정을 완성된 영어 문장으로 작성해 주세요."
                                  autoSize={{ minRows: 2, maxRows: 2 }}
                                  disabled={isProductSampleConfirmed}
                                />
                              </Form.Item>
                            </Table.Td>
                          )}
                        </>
                      ) : (
                        <>
                          <Table.Td colSpan={3}></Table.Td>
                        </>
                      )}
                    </Table.Tr>
                  ),
                );
              }}
            </Form.Item>
          </Table.Tbody>
        </Table>
        <FooterBox>
          <Button loading={isLoading} onClick={handleIssueEnglishProcessMap}>
            영문 공정도 미리보기
          </Button>
          {!isProductSampleConfirmed && (
            <Button
              type="primary"
              htmlType="submit"
              loading={addLoading || updateLoading}
            >
              {!updateMode ? '등록' : '수정'}
            </Button>
          )}
        </FooterBox>
      </Form>
    </ProductSampleProcessMapBlock>
  );
};
export default ProductSampleProcessMap;
