import { createContext, useCallback, useContext, useState } from 'react';

import DocsHistoryModal, {
  DocsHistoryModalProps,
} from 'components/brand/mypage/DocsHistoryModal';
import DocsItemModal, {
  DocsItemModalProps,
} from 'components/brand/mypage/DocsItemModal';
import StepModal, { StepModalProps } from 'components/modal/StepModal';
import CorrectRequestMessageModal, {
  CorrectRequestMessageModalProps,
} from 'components/modal/CorrectRequestMessageModal';
import EstimateContactModal, {
  EstimateContactModalProps,
} from 'components/modal/EstimateContactModal';
import StibeePopUpModal, {
  StibeePopUpModalProps,
} from 'components/modal/StibeePopUpModal';
import PrivacyModal, {
  PrivacyModalProps,
} from 'components/modal/adInquiry/PrivacyModal';
import ChangePasswordModal, {
  ChangePasswordModalProps,
} from 'components/modal/auth/ChangePasswordModal';
import QCQADocsHistoryModal, {
  QCQADocsHistoryModalProps,
} from 'components/modal/brand/qcqa/QCQADocsHistoryModal';
import QCQAFormulaBreakdownModal, {
  QCQAFormulaBreakdownModalProps,
} from 'components/modal/brand/qcqa/QCQAFormulaBreakdownModal';
import CertificateProgressModal, {
  CertificateProgressModalProps,
} from 'components/modal/certificate/CertificateProgressModal';
import ProductCorrectChatModal, {
  ProductCorrectChatModalProps,
} from 'components/modal/certificate/ProductCorrectChatModal';
import EstimateAdditionalsModal, {
  EstimateAdditionalsModalProps,
} from 'components/modal/estimate/EstimateAdditionalsModal';
import EstimateHistoriesModal, {
  EstimateHistoriesModalProps,
} from 'components/modal/estimate/EstimateHistoriesModal';
import NMPACorrectChatModal, {
  NMPACorrectChatModalProps,
} from 'components/modal/material/NMPACorrectChatModal';
import UseGuideModal, {
  UseGuideModalProps,
} from 'components/modal/material/UseGuideModal';
import DocumentPreviewModal, {
  DocumentPreviewModalProps,
} from 'components/modal/product/DocumentPreviewModal';
import AlertModal, { AlertModalProps } from 'components/ui/Modal/AlertModal';
import ConfirmModal, {
  ConfirmModalProps,
} from 'components/ui/Modal/ConfirmModal';
import LoginModal from 'components/ui/Modal/LoginModal';
import ManufacturerInfoModal, {
  ManufacturerInfoModalProps,
} from 'components/modal/product/ManufacturerInfoModal';
import ProductRPAddModal, {
  ProductRPAddModalProps,
} from 'components/modal/brand/rp/ProductRPAddModal';
import FDAAccountAddModal, {
  FDAAccountAddModalProps,
} from 'components/modal/product/FDAAccountAddModal';

export type OpenModal<T> = (params: T) => void;

interface ModalContextProp {
  openLoginModal: VoidFunction;
  openDocumentPreviewModal: OpenModal<DocumentPreviewModalProps>;
  openEstimateHistoriesModal: OpenModal<EstimateHistoriesModalProps>;
  openEstimateAdditionalsModal: OpenModal<EstimateAdditionalsModalProps>;
  openCorrectRequestMessageModal: OpenModal<CorrectRequestMessageModalProps>;
  openNMPACorrectChatModal: OpenModal<NMPACorrectChatModalProps>;
  openProductCorrectChatModal: OpenModal<ProductCorrectChatModalProps>;
  openPrivacyModal: OpenModal<PrivacyModalProps>;
  openCertificateProgressModal: OpenModal<CertificateProgressModalProps>;
  openEstimateContactModal: VoidFunction;
  openQCQAFormulaBreakdownModal: OpenModal<QCQAFormulaBreakdownModalProps>;
  openQCQADocsHistoryModal: OpenModal<QCQADocsHistoryModalProps>;
  closeQCQAFormulaBreakdownModal: () => void;
  openConfirmModal: OpenModal<ConfirmModalProps>;
  closeConfirmModal: () => void;
  openAlertModal: OpenModal<AlertModalProps>;
  closeAlertModal: () => void;
  openUseGuideModal: OpenModal<UseGuideModalProps>;
  openDocsItemModal: OpenModal<DocsItemModalProps>;
  openDocsHistoryModal: OpenModal<DocsHistoryModalProps>;
  openStepModal: OpenModal<StepModalProps>;
  openStibeePopUpModal: OpenModal<StibeePopUpModalProps>;
  openChangePasswordModal: OpenModal<ChangePasswordModalProps>;
  openManufacturerInfoModal: OpenModal<ManufacturerInfoModalProps>;
  openProductRPAddModal: OpenModal<ProductRPAddModalProps>;
  openFDAAccountAddModal: OpenModal<FDAAccountAddModalProps>;
}

const ModalContext = createContext<ModalContextProp>({} as ModalContextProp);

// HINT: 모달을 열기위한 open 메소드, 모달 열려있는지 여부 visible와 컴포넌트 렌더링에 필요한 props를 반환
const useDefaultModalLogic = <T extends unknown>() => {
  const [visible, setVisible] = useState(false);
  const [props, setProps] = useState<T | undefined>();

  const openModal = useCallback((props?: T) => {
    setProps(props);
    setVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setProps(undefined);
    setVisible(false);
  }, []);

  return {
    visible,
    props,
    openModal,
    closeModal,
  };
};

export const ModalContextProvider: React.FC = ({ children }) => {
  const {
    openModal: openDocumentPreviewModal,
    closeModal: closeDocumentPreviewModal,
    visible: documentPreviewModalVisible,
    props: documentPreviewModalProps,
  } = useDefaultModalLogic<DocumentPreviewModalProps>();

  const {
    openModal: openEstimateHistoriesModal,
    closeModal: closeEstimateHistoriesModal,
    visible: estimateHistoriesModalVisible,
    props: estimateHistoriesModalProps,
  } = useDefaultModalLogic<EstimateHistoriesModalProps>();

  const {
    openModal: openEstimateAdditionalsModal,
    closeModal: closeEstimateAdditionalsModal,
    visible: estimateAdditionalsModalVisible,
    props: estimateAdditionalsModalProps,
  } = useDefaultModalLogic<EstimateAdditionalsModalProps>();

  const {
    openModal: openPrivacyModal,
    closeModal: closePrivacyModal,
    visible: privacyModalVisible,
    props: privacyModalProps,
  } = useDefaultModalLogic<PrivacyModalProps>();

  const {
    openModal: openLoginModal,
    closeModal: closeLoginModal,
    visible: loginModalVisible,
  } = useDefaultModalLogic();

  const {
    openModal: openCorrectRequestMessageModal,
    closeModal: closeCorrectRequestMessageModal,
    visible: correctRequestMessageModalVisible,
    props: correctRequestMessageModalProps,
  } = useDefaultModalLogic<CorrectRequestMessageModalProps>();

  const {
    openModal: openNMPACorrectChatModal,
    closeModal: closeNMPACorrectChatModal,
    visible: nmpaCorrectChatModalVisible,
    props: nmpaCorrectChatModalProps,
  } = useDefaultModalLogic<NMPACorrectChatModalProps>();

  const {
    openModal: openProductCorrectChatModal,
    closeModal: closeProductCorrectChatModal,
    visible: productCorrectChatModalVisible,
    props: productCorrectChatModalProps,
  } = useDefaultModalLogic<ProductCorrectChatModalProps>();

  const {
    openModal: openCertificateProgressModal,
    closeModal: closeCertificateProgressModal,
    visible: certificateProgressModalVisible,
    props: certificateProgressModalProps,
  } = useDefaultModalLogic<CertificateProgressModalProps>();

  const {
    openModal: openEstimateContactModal,
    closeModal: closeEstimateContactModal,
    visible: estimateContactModalVisible,
  } = useDefaultModalLogic<EstimateContactModalProps>();

  const {
    openModal: openQCQAFormulaBreakdownModal,
    closeModal: closeQCQAFormulaBreakdownModal,
    visible: qcqaFormulaBreakdownModalVisible,
    props: qcqaFormulaBreakdownModalProps,
  } = useDefaultModalLogic<QCQAFormulaBreakdownModalProps>();

  const {
    openModal: openQCQADocsHistoryModal,
    closeModal: closeQCQADocsHistoryModal,
    visible: qcqaDocsHistoryModalVisible,
    props: qcqaDocsHistoryModalProps,
  } = useDefaultModalLogic<QCQADocsHistoryModalProps>();

  const {
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
    visible: confirmModalVisible,
    props: confirmModalProps,
  } = useDefaultModalLogic<ConfirmModalProps>();

  const {
    openModal: openAlertModal,
    closeModal: closeAlertModal,
    visible: alertModalVisible,
    props: alertModalProps,
  } = useDefaultModalLogic<AlertModalProps>();

  const {
    openModal: openUseGuideModal,
    closeModal: closeUseGuideModal,
    visible: useGuideModalVisible,
    props: useGuideModalProps,
  } = useDefaultModalLogic<UseGuideModalProps>();

  const {
    openModal: openDocsItemModal,
    closeModal: closeDocsItemModal,
    visible: docsItemModalVisible,
    props: docsItemModalProps,
  } = useDefaultModalLogic<DocsItemModalProps>();

  const {
    openModal: openDocsHistoryModal,
    closeModal: closeDocsHistoryModal,
    visible: docsHistoryModalVisible,
    props: docsHistoryModalProps,
  } = useDefaultModalLogic<DocsHistoryModalProps>();

  const {
    openModal: openStepModal,
    closeModal: closeStepModal,
    visible: stepModalVisible,
    props: stepModalProps,
  } = useDefaultModalLogic<StepModalProps>();

  const {
    openModal: openStibeePopUpModal,
    closeModal: closeStibeePopUpModal,
    visible: stibeePopUpModalVisible,
    props: stibeePopUpModalProps,
  } = useDefaultModalLogic<StibeePopUpModalProps>();

  const {
    openModal: openChangePasswordModal,
    closeModal: closeChangePasswordModal,
    visible: changePasswordModalVisible,
    props: changePasswordModalProps,
  } = useDefaultModalLogic<ChangePasswordModalProps>();
  const {
    openModal: openProductRPAddModal,
    closeModal: closeProductRPAddModal,
    visible: productRPAddModalVisible,
    props: productRPAddModalProps,
  } = useDefaultModalLogic<ProductRPAddModalProps>();

  const {
    openModal: openManufacturerInfoModal,
    closeModal: closeManufacturerInfoModal,
    visible: manufacturerInfoModalVisible,
    props: manufacturerInfoModalProps,
  } = useDefaultModalLogic<ManufacturerInfoModalProps>();
  const {
    openModal: openFDAAccountAddModal,
    closeModal: closeFDAAccountAddModal,
    visible: fdaAccountAddModalVisible,
    props: fdaAccountAddModalProps,
  } = useDefaultModalLogic<ProductRPAddModalProps>();

  const modalContext: ModalContextProp = {
    openLoginModal,
    openDocumentPreviewModal,
    openEstimateHistoriesModal,
    openEstimateAdditionalsModal,
    openCorrectRequestMessageModal,
    openNMPACorrectChatModal,
    openProductCorrectChatModal,
    openPrivacyModal,
    openCertificateProgressModal,
    openEstimateContactModal,
    openQCQAFormulaBreakdownModal,
    closeQCQAFormulaBreakdownModal,
    openQCQADocsHistoryModal,
    openConfirmModal,
    closeConfirmModal,
    openAlertModal,
    closeAlertModal,
    openUseGuideModal,
    openDocsItemModal,
    openDocsHistoryModal,
    openStepModal,
    openStibeePopUpModal,
    openChangePasswordModal,
    openManufacturerInfoModal,
    openProductRPAddModal,
    openFDAAccountAddModal,
  };

  return (
    <ModalContext.Provider value={modalContext}>
      {loginModalVisible && (
        <LoginModal
          isOnlyBrand
          visible={loginModalVisible}
          onClose={closeLoginModal}
        />
      )}
      {documentPreviewModalVisible && documentPreviewModalProps && (
        <DocumentPreviewModal
          {...documentPreviewModalProps}
          onClose={closeDocumentPreviewModal}
        />
      )}
      {estimateHistoriesModalVisible && estimateHistoriesModalProps && (
        <EstimateHistoriesModal
          {...estimateHistoriesModalProps}
          onClose={closeEstimateHistoriesModal}
        />
      )}
      {estimateAdditionalsModalVisible && estimateAdditionalsModalProps && (
        <EstimateAdditionalsModal
          {...estimateAdditionalsModalProps}
          onClose={closeEstimateAdditionalsModal}
        />
      )}
      {correctRequestMessageModalVisible && correctRequestMessageModalProps && (
        <CorrectRequestMessageModal
          {...correctRequestMessageModalProps}
          onClose={closeCorrectRequestMessageModal}
        />
      )}
      {nmpaCorrectChatModalVisible && nmpaCorrectChatModalProps && (
        <NMPACorrectChatModal
          {...nmpaCorrectChatModalProps}
          onClose={closeNMPACorrectChatModal}
        />
      )}
      {productCorrectChatModalVisible && productCorrectChatModalProps && (
        <ProductCorrectChatModal
          {...productCorrectChatModalProps}
          onClose={closeProductCorrectChatModal}
        />
      )}
      {privacyModalVisible && privacyModalProps && (
        <PrivacyModal {...privacyModalProps} onClose={closePrivacyModal} />
      )}
      {certificateProgressModalVisible && certificateProgressModalProps && (
        <CertificateProgressModal
          {...certificateProgressModalProps}
          onClose={closeCertificateProgressModal}
        />
      )}
      {estimateContactModalVisible && (
        <EstimateContactModal onClose={closeEstimateContactModal} />
      )}
      {qcqaFormulaBreakdownModalVisible && qcqaFormulaBreakdownModalProps && (
        <QCQAFormulaBreakdownModal
          {...qcqaFormulaBreakdownModalProps}
          onClose={closeQCQAFormulaBreakdownModal}
        />
      )}
      {qcqaDocsHistoryModalVisible && qcqaDocsHistoryModalProps && (
        <QCQADocsHistoryModal
          {...qcqaDocsHistoryModalProps}
          onClose={closeQCQADocsHistoryModal}
        />
      )}
      {confirmModalVisible && confirmModalProps && (
        <ConfirmModal {...confirmModalProps} onClose={closeConfirmModal} />
      )}
      {alertModalVisible && alertModalProps && (
        <AlertModal {...alertModalProps} onClose={closeAlertModal} />
      )}
      {useGuideModalVisible && useGuideModalProps && (
        <UseGuideModal {...useGuideModalProps} onClose={closeUseGuideModal} />
      )}
      {docsItemModalVisible && docsItemModalProps && (
        <DocsItemModal {...docsItemModalProps} onClose={closeDocsItemModal} />
      )}
      {docsHistoryModalVisible && docsHistoryModalProps && (
        <DocsHistoryModal
          {...docsHistoryModalProps}
          onClose={closeDocsHistoryModal}
        />
      )}
      {stepModalVisible && stepModalProps && (
        <StepModal {...stepModalProps} onClose={closeStepModal} />
      )}
      {stibeePopUpModalVisible && stibeePopUpModalProps && (
        <StibeePopUpModal
          {...stibeePopUpModalProps}
          onClose={closeStibeePopUpModal}
        />
      )}
      {changePasswordModalVisible && changePasswordModalProps && (
        <ChangePasswordModal
          {...changePasswordModalProps}
          onClose={closeChangePasswordModal}
        />
      )}
      {manufacturerInfoModalVisible && manufacturerInfoModalProps && (
        <ManufacturerInfoModal
          {...manufacturerInfoModalProps}
          onClose={closeManufacturerInfoModal}
        />
      )}
      {productRPAddModalVisible && productRPAddModalProps && (
        <ProductRPAddModal
          {...productRPAddModalProps}
          onClose={closeProductRPAddModal}
        />
      )}
      {fdaAccountAddModalVisible && fdaAccountAddModalProps && (
        <FDAAccountAddModal
          {...fdaAccountAddModalProps}
          onClose={closeFDAAccountAddModal}
        />
      )}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
