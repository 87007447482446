import { Button, Checkbox, Col, Form, Input, message, Row, Select } from 'antd';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useMemo, useState } from 'react';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { requireRule } from 'lib/validate';
import { Tip, Typography } from 'components/system';
import {
  ICIDApplicationStatus,
  ICIDDetailFormValues,
} from 'types/material/icid/icid';
import {
  useAddDetail,
  useCategories,
  useDetail,
  useSaveDetail,
  useTmpDetail,
} from 'service/material/icid/application';
import history from 'lib/history';
import { useICIDApplication } from 'service/material/icid/icid';
import UpdateLog from '../UpdateLog';
import { Flex, CorrectButton, BackgroundRadio } from 'components/ui';
import { useModal } from 'hook/useModal';
import { DocumentCode } from 'types/material/common';
import { DocumentStatus } from 'types/common';
import { omit } from 'lodash';
import { isObjectChanged } from 'lib/form';
import { messages } from 'lib/consts';

const Container = styled.div`
  margin: 0 auto;
  max-width: 520px;

  .ant-form-item textarea.ant-input {
    resize: none;
    min-height: 88px;
  }

  .solvent {
    label {
      width: 100%;

      .ant-checkbox-wrapper {
        span {
          font-size: 16px;
        }
      }
    }
  }
`;

const ICIDDetail = ({ icidApplicationId }: { icidApplicationId: number }) => {
  const [form] = Form.useForm<ICIDDetailFormValues>();
  const [isSolvent, setIsSolvent] = useState<boolean>();
  const { categories, isLoadingCategories } = useCategories();
  const { icidApplication } = useICIDApplication(icidApplicationId);
  const { detail, isDetailLoading } = useDetail(icidApplicationId);
  const { tmpDetail } = useTmpDetail(icidApplicationId);
  const readOnlyMode = useMemo(
    () =>
      !!(
        (detail &&
          detail.status !== DocumentStatus.MOD &&
          icidApplication?.status === ICIDApplicationStatus.ONGOING) ||
        icidApplication?.status === ICIDApplicationStatus.APPLYING ||
        icidApplication?.status === ICIDApplicationStatus.CANCEL
      ),
    [detail],
  );

  const { addDetail, isLoading } = useAddDetail();
  const handleSubmit = (formValues: ICIDDetailFormValues) => {
    let { tradeName, ...rest } = formValues;

    const updateFormData = omit(formValues, ['tradeName']);

    const originFormData = omit(
      {
        ...detail,
        materialCategoryIds: detail?.materialIcidApplicationDetailFunctionMaterialCategories?.map(
          ({ materialCategoryId }) => materialCategoryId,
        ),
      },
      [
        'correctRequestMessage',
        'isSolvent',
        'materialIcidApplicationDetailId',
        'materialIcidApplicationId',
        'status',
        'materialIcidApplicationDetailFunctionMaterialCategories',
      ],
    );

    if (
      !isObjectChanged({ object1: originFormData, object2: updateFormData })
    ) {
      return message.warn(messages.NO_NEED_TO_UPDATE);
    }

    addDetail(
      {
        icidApplicationId,
        isSolvent,
        ...rest,
      },
      {
        onSuccess: () => {
          message.success(detail ? '수정되었습니다.' : '등록되었습니다.');
          history.goBack();
        },
      },
    );
  };

  const { saveDetail } = useSaveDetail();
  const handleSaveDetail = () => {
    let { tradeName, ...rest } = form.getFieldsValue();

    saveDetail(
      {
        icidApplicationId,
        isSolvent,
        ...rest,
      },
      {
        onSuccess: () => {
          message.success('임시저장되었습니다.');
        },
      },
    );
  };

  const handleCheckIsNotSolvent = (checked: boolean) => {
    if (checked) {
      form.setFieldsValue({
        solvent: undefined,
      });
    }
    setIsSolvent(!checked);
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        isPublic: true,
        compositionRatio: 'TEMPTEMPTEMPTEMP',
        referenceInfo: 'TEMPTEMPTEMPTEMP',
        sourceOrigin: 'TEMPTEMPTEMPTEMP',
        productType: 'TEMPTEMPTEMPTEMP',
        manufacturingFlow: 'TEMPTEMPTEMPTEMP',
        additionalInfo: 'TEMPTEMPTEMPTEMP',
        materialCategoryIds: [402, 403, 404],
      });
    }
  }, []);

  useEffect(() => {
    if (detail) {
      let {
        materialIcidApplicationDetailId,
        materialIcidApplicationId,
        materialIcidApplicationDetailFunctionMaterialCategories,
        solvent,
        ...rest
      } = detail;

      form.setFieldsValue({
        materialCategoryIds: materialIcidApplicationDetailFunctionMaterialCategories.map(
          ({ materialCategoryId }) => materialCategoryId,
        ),
        solvent,
        ...rest,
      });

      if (!solvent) {
        setIsSolvent(false);
      }
    } else if (!isDetailLoading && tmpDetail) {
      let {
        materialIcidApplicationDetailTmpId,
        materialIcidApplicationId,
        materialIcidApplicationDetailTmpFunctionMaterialCategories,
        isSolvent,
        ...rest
      } = tmpDetail;

      form.setFieldsValue({
        materialCategoryIds: materialIcidApplicationDetailTmpFunctionMaterialCategories.map(
          ({ materialCategoryId }) => materialCategoryId,
        ),
        ...rest,
      });

      setIsSolvent(isSolvent);
    }
  }, [detail, tmpDetail, isDetailLoading]);

  useEffect(() => {
    if (icidApplication) {
      form.setFieldsValue({
        tradeName: icidApplication?.tradeName,
      });
    }
  }, [icidApplication]);

  const { openCorrectRequestMessageModal } = useModal();

  return (
    <>
      {detail && (
        <Flex justify="end" gap={8}>
          {detail.status === 'MOD' && (
            <CorrectButton
              onClick={() =>
                openCorrectRequestMessageModal({
                  correctMessage: detail.correctRequestMessage || '',
                })
              }
            />
          )}
          <UpdateLog
            id={icidApplicationId}
            documentCode={DocumentCode.DETAIL}
          />
        </Flex>
      )}
      <Container>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="원료명 (Trade Name)"
            name="tradeName"
            required
            rules={[
              requireRule,
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,150}$/,
                message: '영문, 숫자, 기호 가능 최대 150자',
              },
            ]}
          >
            <Input placeholder="영문" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col>원료명 공개 여부</Col>
                <Col>
                  <Tip
                    trigger="click"
                    closeIconStyle={{
                      fontSize: 16,
                    }}
                  >
                    <Typography.Text type="SMALL">
                      ICID 사전에 원료가 등재되었을시, 원료의 Trade Name 공개
                      여부를 선택해주세요.
                      <br />
                      등재된 INCI Name은 필수적으로 공개됩니다.
                    </Typography.Text>
                  </Tip>
                </Col>
              </Row>
            }
            name="isPublic"
            required
            rules={[
              requireRule,
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <BackgroundRadio
              gap={120}
              itemWidth={70}
              disabled={readOnlyMode}
              options={[
                { title: '비공개', value: false },
                { title: '공개', value: true },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col>희망 INCI Name</Col>
                <Col>
                  <Tip
                    trigger="click"
                    closeIconStyle={{
                      fontSize: 16,
                    }}
                  >
                    <Typography.Text type="SMALL">
                      희망하시는 INCI Name 은 사전 고지 없이 PCPC 위원회에
                      의하여 변경될 수 있습니다.
                    </Typography.Text>
                  </Tip>
                </Col>
              </Row>
            }
            name="inciName"
            required
            rules={[
              requireRule,
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <Input disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item
            label="CAS No. 또는 EC No."
            name="casNo"
            rules={[
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,50}$/,
                message: '영문, 숫자, 기호 가능 최대 50자',
              },
            ]}
          >
            <Input disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item
            label="배합비 (조성비율)"
            name="compositionRatio"
            required
            rules={[
              requireRule,
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <TextArea
              placeholder="실제 원료의 조성비율 (Composition) 을 자유 양식으로 입력"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="참고 자료"
            name="referenceInfo"
            rules={[
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <TextArea
              placeholder="해당 원료에 대해 참고할 수 있는 레퍼런스 입력"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            className="solvent"
            label={
              <Row justify="space-between" style={{ width: '100%' }}>
                <Col>희석제 (용매)</Col>
                <Col>
                  <Checkbox
                    disabled={readOnlyMode}
                    checked={isSolvent === false}
                    onChange={(e) => handleCheckIsNotSolvent(e.target.checked)}
                  >
                    용매 불포함
                  </Checkbox>
                </Col>
              </Row>
            }
            name="solvent"
            required
            rules={[
              ...(isSolvent !== false ? [requireRule] : []),
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,200}$/,
                message: '영문, 숫자, 기호 가능 최대 200자',
              },
            ]}
          >
            <Input
              disabled={isSolvent === false || readOnlyMode}
              placeholder={isSolvent === false ? '용매 불포함' : ''}
            />
          </Form.Item>
          <Form.Item
            label="기원 및 출처 (Source of Origin)"
            name="sourceOrigin"
            required
            rules={[
              requireRule,
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <TextArea
              placeholder="해당 원료의 Source of Origin (Genus, Species, Plant or Animal Part) 을 자세하게 기재"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="제품 타입 (성상)"
            name="productType"
            rules={[
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]+$/,
                message: '한글 입력 불가',
              },
            ]}
          >
            <TextArea
              placeholder="제품의 성상을 입력. 예시) powder, liquid"
              maxLength={300}
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col>제조 공정</Col>
                <Col>
                  <Tip
                    trigger="click"
                    closeIconStyle={{
                      fontSize: 16,
                    }}
                  >
                    <Typography.Text type="SMALL">
                      제조 공정을 자유 양식으로 자세히 기술해주세요.
                    </Typography.Text>
                  </Tip>
                </Col>
              </Row>
            }
            name="manufacturingFlow"
            required
            rules={[
              requireRule,
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <TextArea disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item
            label="추가 정보"
            name="additionalInfo"
            rules={[
              {
                pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,1000}$/,
                message: '영문, 숫자, 기호 가능 최대 1000자',
              },
            ]}
          >
            <TextArea
              placeholder="원료 등재 신청서 제출시 PCPC위원회에 추가로 공유하고 싶은 정보가 있는 경우 해당 내용을 입력"
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Form.Item
            label="기능 (Function)"
            name="materialCategoryIds"
            rules={[requireRule]}
            normalize={(value, prevValue) => {
              if (value.length > 5) {
                message.warn('최대 5개까지 등록 가능합니다.');
                return prevValue;
              }
              return value;
            }}
          >
            <Select
              placeholder="기능 선택 (최대 5개까지 선택)"
              mode="multiple"
              filterOption={(searchKeyword, option) =>
                (option?.label as string)
                  ?.toLowerCase()
                  .startsWith(searchKeyword.toLowerCase()) || false
              }
              loading={isLoadingCategories}
              options={categories}
              disabled={readOnlyMode}
            />
          </Form.Item>
          <FooterBox>
            {!readOnlyMode && !detail && (
              <Button onClick={handleSaveDetail}>임시 저장</Button>
            )}
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {detail ? '수정' : '등록'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Container>
    </>
  );
};

export default ICIDDetail;
