import { memo } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';

const MSDSToxicologicalBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSToxicological = () => {
  return (
    <MSDSToxicologicalBlock>
      <AlphabetLabel alphabet="A">
        Information on likely routes of exposure
      </AlphabetLabel>
      <Form.Item
        label="Inhalation"
        name={['toxicological', 'exposureInhalation']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="흡입했을 때"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Ingestion"
        name={['toxicological', 'exposureIngestion']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="먹었을 때"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Skin"
        name={['toxicological', 'exposureSkin']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="피부에 접촉했을 때"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <AlphabetLabel alphabet="B">
        Delayed and immediate effects and also chronic effects from short and
        long term exposure
      </AlphabetLabel>
      <Form.Item
        label="Acute toxicity"
        name={['toxicological', 'acuteToxicity']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="급성 독성(노출 가능한 모든 경로에 대해 기재)"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Skin corrosion/irritation"
        name={['toxicological', 'skinCorrosionIrritation']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="피부 부식성 또는 자극성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Serious eye damage/eye irritation"
        name={['toxicological', 'seriousEysDamageIrritation']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="심한 눈 손상 또는 자극성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Respiratory or skin sensitisation"
        name={['toxicological', 'respiratorySkinSensitisation']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="호흡기 또는 피부 과민성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Carcinogenicity"
        name={['toxicological', 'carcinogenicity']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="발암성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Germ cell mutagenicity"
        name={['toxicological', 'germCellMutagenicity']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="생식세포 변이원성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Reproductive toxicity"
        name={['toxicological', 'reproductiveToxicity']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="생식 독성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Specific target organ toxicity -  single exposure"
        name={['toxicological', 'singleExposure']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="특정 표적장기 독성(1회 노출)"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Specific target organ toxicity - repeated exposure"
        name={['toxicological', 'repeatedExposure']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="특정 표적장기 독성(반복 노출)"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Aspiration hazard"
        name={['toxicological', 'aspirationHazard']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="흡인 유해성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Signs and Symptoms of Exposure"
        name={['toxicological', 'signsSymptomsExposure']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="노출의 징후와 증상"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <AlphabetLabel alphabet="C">Numerical measures of toxicity</AlphabetLabel>
      <Form.Item
        label="Additional Information"
        name={['toxicological', 'numericalMeasuresToxicity']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="Not available"
      >
        <Input.TextArea
          placeholder="추가 정보"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSToxicologicalBlock>
  );
};

export default memo(MSDSToxicological);
