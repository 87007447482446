import { Button, Checkbox, Form, Input, Select, message } from 'antd';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { Tip, Typography } from 'components/system';
import { BackgroundRadio, CorrectButton, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { focusToInvalidatedField, isEmptyString } from 'lib/form';
import history from 'lib/history';
import palette from 'lib/styles/palette';
import { getNumberAndDotRule, requireRule } from 'lib/validate';
import {
  useNMPABasicInfoStatus,
  useNMPABasicOld,
  useNMPACorrectWithNoUpdate,
  useNMPADetail,
  useNMPAMaterialUsePurposes,
  useNMPAOverwriteHistory,
  useNMPATempStore,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  NMPADetailItem,
  NMPADetailSolubility,
  NMPADocumentCode,
  NMPAMaterialUsePurpose,
  NMPATempStoreType,
} from 'types/material/nmpa/nmpa';

const Container = styled.div`
  max-width: 520px;
  margin: 0 auto;

  textarea {
    resize: none;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    div:nth-child(n + 2) {
      display: none;
    }
    width: 180px;
  }

  .ant-form-item-explain-success {
    display: none;
  }

  .range-input-left {
    transition: none;
    width: 92px;
    max-width: 92px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0px !important;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      border-right-width: 1px !important;
    }
  }

  .range-input-right {
    transition: none;
    width: 92px;
    max-width: 92px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0px !important;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      border-left-width: 1px !important;
    }
  }

  .ant-form-item-has-error {
    .range-input-right {
      border-left-width: 1px !important;
    }

    .range-input-left {
      border-right-width: 1px !important;
    }
  }
`;

const Char = styled(Typography.Text)<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#f5f5f5' : '#fff')};
  display: flex;
  align-items: center;
  height: 44px;
  transform: translateY(-1px);
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
`;

const LeaveOn = styled(Flex)`
  background: ${palette.SLATE_GRAY10};
  padding: 10px 16px;
  border-radius: 4px;
  position: relative;

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    top: 100%;
  }
`;

const RinseOff = styled(Flex)`
  margin-top: 8px;
  padding: 10px 16px;
  border-radius: 4px;
  background: ${palette.SLATE_GRAY10};
  position: relative;

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    top: 100%;
  }
`;

const UseGuideBtn = styled(Button)`
  position: absolute;
  z-index: 10;
  right: 434px;
  color: ${palette.GRAY70};
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  height: 24px !important;
  margin-left: 8px;
  margin-top: 2px;
  font-size: 12px !important;

  span:nth-child(2) {
    margin-left: 5px !important;
  }
`;

const checkIsDuplicate = (str1: string, str2: string) =>
  str1.replaceAll(' ', '').toLowerCase() ===
  str2.replaceAll(' ', '').toLowerCase();

interface NMPADetailForm {
  usePurposes?: (
    | {
        materialCategory?: NMPAMaterialUsePurpose | null;
        materialCategoryId?: number;
        usePurpose?: string;
      }
    | string
  )[];
  leaveOnMin: string;
  leaveOnMax: string;
  isNotLeaveOn: boolean;
  rinseOffMin: string;
  rinseOffMax: string;
  isNotRinseOff: boolean;
  prescriptionContraindications?: string | null;
  usageRestrictions?: string | null;
  warningText?: string | null;
  materialColor: string;
  materialSmell: string;
  materialAppearance: string;
  solubility: NMPADetailSolubility;
  solubilityReason?: string | null;
  logPow?: string | null;
  etcAttribute?: string;
  manufactureProcessExplanation: string;
  methodOfAnalysis?: string | null;
  otherRequirements?: string | null;
}

const NMPADetail = ({ materialId }: { materialId: number }) => {
  // HINT: 최초 불러온 데이터를 저장하고 있고, 수정할 내역을 검사할 때 사용됩니다.
  const [initFormData, setInitFormData] = useState<NMPADetailForm>();
  const [solubilityEtcVisible, setSolubilityEtcVisible] = useState(false);
  const [form] = Form.useForm();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const { nmpaBasicInfo } = useNMPABasicOld(materialId);

  const materialNmpaBasicInfoId = nmpaBasicInfo?.materialNmpaBasicInfoId;

  const {
    nmpaCorrectRequestWithNoUpdate,
    nmpaCorrectRequestWithNoUpdateLoading,
  } = useNMPACorrectWithNoUpdate({
    materialNmpaBasicInfoId,
    documentCode: NMPADocumentCode.DETAIL,
  });

  const {
    nmpaMaterialUsePurposes,
    getNMPAMaterialUsePurposesLoading,
  } = useNMPAMaterialUsePurposes();
  const {
    nmpaDetail,
    getNMPADetailLoading,
    addNMPADetail,
    addNMPADetailLoading,
  } = useNMPADetail(materialId);
  const {
    basicInfoStatus,
    isLoading: isBasicInfoStatusLoading,
  } = useNMPABasicInfoStatus({
    materialId,
    documentCode: DocumentCode.DETAIL,
    enabled: materialId !== undefined && nmpaDetail !== null,
  });
  const {
    nmpaTempStore,
    addNMPATempStore,
    addNMPATempStoreLoading,
  } = useNMPATempStore({
    type: NMPATempStoreType.DETAIL,
    materialNmpaBasicInfoId: materialId,
  });
  const {
    nmpaOverwriteHistory,
    nmpaOverwriteHistoryLoading,
  } = useNMPAOverwriteHistory({
    nmpaBasicInfoId: materialId,
    documentCode: NMPADocumentCode.DETAIL,
  });

  const {
    openUseGuideModal,
    openNMPACorrectChatModal,
    openAlertModal,
  } = useModal();

  const readOnly =
    (nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.ONGOING &&
      !basicInfoStatus?.isNmpaDocModStatus) ||
    nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.FINISH;
  const updateMode = nmpaDetail !== null;
  const loading = isBasicInfoStatusLoading || getNMPADetailLoading;
  const ctaName = useMemo(() => {
    if (!updateMode) return '등록';
    if (basicInfoStatus?.isNmpaDocModStatus) return '보완 완료';
    return '수정';
  }, [updateMode, basicInfoStatus]);

  const handleSubmit = (
    formValues: Omit<NMPADetailItem, 'usePurposes'> & {
      usePurposes: string[];
    },
  ) => {
    if (
      initFormData !== undefined &&
      isEqual(formValues, initFormData as any)
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    if (initFormData) {
      nmpaOverwriteHistory(
        getChangedValues({ obj1: initFormData, obj2: formValues }),
      );
    }

    const {
      usePurposes,
      leaveOnMin,
      leaveOnMax,
      isNotLeaveOn,
      rinseOffMin,
      rinseOffMax,
      isNotRinseOff,
      prescriptionContraindications,
      usageRestrictions,
      warningText,
      materialColor,
      materialSmell,
      materialAppearance,
      solubility,
      solubilityReason,
      logPow,
      etcAttribute,
      manufactureProcessExplanation,
      methodOfAnalysis,
      otherRequirements,
    } = formValues;

    const registerParams = {
      ...(usePurposes !== undefined && {
        usePurposes: usePurposes.map((item) => {
          const nmpaMaterialUsePurpose = nmpaMaterialUsePurposes.find(
            ({ nameKo }) => item === nameKo,
          );

          if (nmpaMaterialUsePurpose) {
            return {
              materialCategoryId: nmpaMaterialUsePurpose.materialCategoryId,
            };
          }

          return { usePurpose: item };
        }),
      }),
      leaveOnMin,
      leaveOnMax,
      isNotLeaveOn,
      rinseOffMin,
      rinseOffMax,
      isNotRinseOff,
      ...(prescriptionContraindications !== undefined && {
        prescriptionContraindications,
      }),
      ...(usageRestrictions !== undefined && { usageRestrictions }),
      ...(warningText !== undefined && { warningText }),
      materialColor,
      materialSmell,
      materialAppearance,
      solubility,
      ...(solubilityReason !== undefined && { solubilityReason }),
      ...(logPow !== undefined && { logPow }),
      ...(etcAttribute !== undefined && { etcAttribute }),
      manufactureProcessExplanation,
      ...(methodOfAnalysis !== undefined && { methodOfAnalysis }),
      ...(otherRequirements !== undefined && { otherRequirements }),
    };

    addNMPADetail(registerParams, {
      onSuccess: () => {
        message.success(`${ctaName}되었습니다.`);
        history.goBack();
      },
    });
  };

  const handleSaveDetail = () => {
    const {
      usePurposes,
      leaveOn,
      isNotLeaveOn,
      rinseOff,
      isNotRinseOff,
      prescriptionContraindications,
      usageRestrictions,
      warningText,
      materialColor,
      materialSmell,
      materialAppearance,
      solubility,
      solubilityReason,
      logPow,
      etcAttribute,
      manufactureProcessExplanation,
      methodOfAnalysis,
      otherRequirements,
    } = form.getFieldsValue();

    const registerParams = JSON.stringify({
      ...(usePurposes && { usePurposes }),
      leaveOn,
      isNotLeaveOn,
      rinseOff,
      isNotRinseOff,
      ...(prescriptionContraindications && { prescriptionContraindications }),
      ...(usageRestrictions && { usageRestrictions }),
      ...(warningText && { warningText }),
      materialColor,
      materialSmell,
      materialAppearance,
      solubility,
      ...(solubilityReason && { solubilityReason }),
      ...(logPow && { logPow }),
      ...(etcAttribute && { etcAttribute }),
      manufactureProcessExplanation,
      ...(methodOfAnalysis && { methodOfAnalysis }),
      ...(otherRequirements && { otherRequirements }),
    });

    addNMPATempStore(registerParams, {
      onSuccess: () => {
        message.success('임시저장되었습니다.');
      },
    });
  };

  const handleNoUpdateSubmit = () => {
    if (materialNmpaBasicInfoId) {
      openAlertModal({
        content: `
'보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

보완할 내용 없이 처리 하시겠습니까?
`,
        onOk: () => {
          nmpaCorrectRequestWithNoUpdate(
            {},
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');
                history.goBack();
              },
            },
          );
        },
      });
    }
  };

  useEffect(() => {
    if (nmpaDetail) {
      form.setFieldsValue({
        ...nmpaDetail,
        usePurposes: nmpaDetail.usePurposes?.map((item) =>
          item.materialCategory
            ? item.materialCategory.nameKo
            : item.usePurpose,
        ),
      });
      if (nmpaDetail.solubility === NMPADetailSolubility.ETC) {
        setSolubilityEtcVisible(true);
      }
      setTimeout(() => setInitFormData(form.getFieldsValue()), 0);
    } else if (!getNMPADetailLoading && !nmpaDetail && nmpaTempStore) {
      const parsedNMPADetail = JSON.parse(nmpaTempStore);
      if (
        parsedNMPADetail.solubility &&
        parsedNMPADetail.solubility === NMPADetailSolubility.ETC
      ) {
        setSolubilityEtcVisible(true);
      }
      form.setFieldsValue(parsedNMPADetail);
    }
  }, [nmpaDetail, getNMPADetailLoading, nmpaTempStore]);

  const generateContentAndRangeValidation = (
    name: 'leaveOn' | 'rinseOff',
    messagePos: 'left' | 'right',
  ) => ({
    validator: () => {
      const leaveOnMin = form.getFieldValue('leaveOnMin');
      const rinseOffMin = form.getFieldValue('rinseOffMin');
      const leaveOnMax = form.getFieldValue('leaveOnMax');
      const rinseOffMax = form.getFieldValue('rinseOffMax');

      let message: string | null = null;

      const { pattern } = getNumberAndDotRule({
        start: 0,
        end: 50,
        message: '올바르지 않은 형식',
      });

      if (name === 'leaveOn') {
        // 입력하지 않은 경우
        if (
          isEmptyString(leaveOnMin) &&
          isEmptyString(leaveOnMax) &&
          !form.getFieldValue('isNotLeaveOn')
        ) {
          message = messagePos === 'right' ? '필수 입력' : '';
        }

        // 한쪽만 입력했거나 두 값이 같은 경우
        else if (
          (leaveOnMin && isEmptyString(leaveOnMax)) ||
          (isEmptyString(leaveOnMin) && leaveOnMax) ||
          (!isEmptyString(leaveOnMin) &&
            Number(leaveOnMin) === Number(leaveOnMax))
        ) {
          message = messagePos === 'right' ? '수치를 확인해 주세요' : '';
        }

        // 최소값이 최대값보다 큰 경우
        else if (Number(leaveOnMin) > Number(leaveOnMax)) {
          message =
            messagePos === 'right' ? '최소값은 최대값 보다 클 수 없음' : '';
        }

        // 공백에 대한 강제 벨리데이션
        else if (
          !pattern.test(leaveOnMin || '0') ||
          !pattern.test(leaveOnMax || '0')
        ) {
          message =
            messagePos === 'right' ? '숫자 또는 기호 입력 가능, 최대 50자' : '';
        }
      }

      if (name === 'rinseOff') {
        // 입력하지 않은 경우
        if (
          isEmptyString(rinseOffMin) &&
          isEmptyString(rinseOffMax) &&
          !form.getFieldValue('isNotRinseOff')
        ) {
          message = messagePos === 'right' ? '필수 입력' : '';
        }

        // 한쪽만 입력했거나 두 값이 같은 경우
        else if (
          (rinseOffMin && isEmptyString(rinseOffMax)) ||
          (isEmptyString(rinseOffMin) && rinseOffMax) ||
          (!isEmptyString(rinseOffMin) &&
            Number(rinseOffMin) === Number(rinseOffMax))
        ) {
          message = messagePos === 'right' ? '수치를 확인해 주세요' : '';
        }

        // 최소값이 최대값보다 큰 경우
        else if (Number(rinseOffMin) > Number(rinseOffMax)) {
          message =
            messagePos === 'right' ? '최소값은 최대값 보다 클 수 없음' : '';
        } else if (
          // 공백에 대한 강제 벨리데이션
          !pattern.test(rinseOffMin || '0') ||
          !pattern.test(rinseOffMax || '0')
        ) {
          message =
            messagePos === 'right' ? '숫자 또는 기호 입력 가능, 최대 50자' : '';
        }
      }

      if (message === null) {
        return Promise.resolve();
      } else {
        return Promise.reject(message);
      }
    },
  });

  const validateContentAndRangeValidation = () => () => {
    const fieldsToValidateLeaveOn = ['leaveOnMin', 'leaveOnMax'];
    form.validateFields(fieldsToValidateLeaveOn);
    const fieldsToValidateRinseOff = ['rinseOffMin', 'rinseOffMax'];
    form.validateFields(fieldsToValidateRinseOff);
  };

  return (
    <Container>
      {loading && <FullLoading />}
      {basicInfoStatus?.isNmpaDocModStatus && (
        <Flex justify="end" style={{ marginBottom: 16 }}>
          <CorrectButton
            onClick={() => {
              openNMPACorrectChatModal({
                title: nmpaBasicInfo?.tradeName,
                nmpaBasicInfoId: materialNmpaBasicInfoId,
                documentCode: NMPADocumentCode.DETAIL,
                isFirstOpenChatModal,
              });
              setIsFirstOpenChatModal(true);
            }}
            disableAnim={false}
          />
        </Flex>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          isNotLeaveOn: false,
          isNotRinseOff: false,
          leaveOn: '',
          rinseOff: '',
        }}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
      >
        <Form.Item
          label={
            <>
              <Typography.Text type="TITLE_2">
                원료 사용 목적 (Function)
              </Typography.Text>
              <Tip
                style={{ display: 'inline-block', marginLeft: 4 }}
                trigger="click"
                closeIconStyle={{
                  fontSize: 16,
                }}
                bodyStyle={{
                  width: 304,
                  whiteSpace: 'pre-line',
                }}
              >
                <Typography.Text inline type="SMALL">
                  선택 입력 항목이나, 문제의 소지가 발생할 수 있으니
                  <br />
                  원료가 보유한 Function을 모두 작성해 주세요.
                </Typography.Text>
              </Tip>
            </>
          }
          name="usePurposes"
          normalize={(selectedValues, prevValues = []) => {
            if (selectedValues.length === 0) {
              return selectedValues;
            }

            const lastIndex = selectedValues.length - 1;
            const last = selectedValues[lastIndex];

            const isDuplicateFormularPurpose = nmpaMaterialUsePurposes.find(
              ({ nameKo }) => checkIsDuplicate(nameKo, last),
            );

            const isAlreadyIncluded = selectedValues
              .slice(0, -1)
              .find((item: string) => checkIsDuplicate(item, last));

            selectedValues[lastIndex] = isDuplicateFormularPurpose
              ? isDuplicateFormularPurpose.nameKo
              : last;

            return isAlreadyIncluded ? prevValues : selectedValues;
          }}
        >
          <Select
            loading={getNMPAMaterialUsePurposesLoading}
            placeholder="선택 또는 직접 입력 후 Enter"
            mode="tags"
            options={nmpaMaterialUsePurposes.map(({ nameKo }) => ({
              label: nameKo,
              value: nameKo,
            }))}
            disabled={readOnly}
            onSelect={(value) => {
              const description = nmpaMaterialUsePurposes.find(
                (i) => i.nameKo === value,
              )?.description;
              if (description) {
                message.info(description);
              } else {
                return;
              }
            }}
          />
        </Form.Item>
        <UseGuideBtn
          size="small"
          icon={<Icon name="search" color="GRAY70" size={10} />}
          onClick={() =>
            openUseGuideModal({
              materialId: materialId,
            })
          }
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          권장 사용량 가이드
        </UseGuideBtn>
        <Form.Item
          label={
            <>
              <Typography.Text type="TITLE_2">
                화장품 권장 사용량
              </Typography.Text>
              <Tip
                style={{ display: 'inline-block', marginLeft: 4 }}
                trigger="click"
                closeIconStyle={{
                  fontSize: 16,
                }}
                bodyStyle={{
                  width: 304,
                  whiteSpace: 'pre-line',
                }}
              >
                <Typography.Text inline type="SMALL">
                  원료 사용시 안전성을 보장할 수 있는 범위값
                  <br />
                  (국제공인기관 또는 중국기사용화장품명칭목록
                  <br />
                  데이터 참고)
                </Typography.Text>
              </Tip>
            </>
          }
          required
          shouldUpdate={(prev: NMPADetailItem, next: NMPADetailItem) =>
            prev.isNotLeaveOn !== next.isNotLeaveOn ||
            prev.isNotRinseOff !== next.isNotRinseOff
          }
        >
          {({ getFieldValue, resetFields, validateFields }) => (
            <>
              <LeaveOn align="flex-start">
                <Typography.Text
                  style={{ flex: '1 1 45%' }}
                  gutter={{ top: 10 }}
                >
                  씻어내지 않는 제품 (Leave On)
                </Typography.Text>
                <Flex dir="column" gap={14}>
                  <Flex align="center">
                    <Form.Item
                      style={{ height: 46, maxWidth: 100 }}
                      name="leaveOnMin"
                      rules={[
                        generateContentAndRangeValidation('leaveOn', 'right'),
                      ]}
                    >
                      <Input
                        className="range-input-left"
                        disabled={getFieldValue('isNotLeaveOn') || readOnly}
                        placeholder="최소"
                        onChange={validateContentAndRangeValidation()}
                      />
                    </Form.Item>
                    <Char
                      color="GRAY90"
                      disabled={getFieldValue('isNotLeaveOn') || readOnly}
                      style={{ top: 12 }}
                    >
                      ~
                    </Char>
                    <Form.Item
                      style={{ height: 46 }}
                      name="leaveOnMax"
                      rules={[
                        generateContentAndRangeValidation('leaveOn', 'left'),
                      ]}
                    >
                      <Input
                        className="range-input-right"
                        disabled={getFieldValue('isNotLeaveOn') || readOnly}
                        placeholder="최대"
                        onChange={validateContentAndRangeValidation()}
                      />
                    </Form.Item>
                    <Typography.Text medium gutter={{ left: 4 }}>
                      %
                    </Typography.Text>
                  </Flex>
                  <Form.Item
                    noStyle
                    name="isNotLeaveOn"
                    valuePropName="checked"
                  >
                    <Checkbox
                      style={{ flex: '1 1 30%' }}
                      disabled={readOnly}
                      onChange={({ target: { checked } }) => {
                        if (checked) {
                          resetFields(['leaveOnMin', 'leaveOnMax']);
                        }

                        if (getFieldValue('isNotRinseOff') && !checked) {
                          validateFields(['isNotRinseOff']);
                        }
                      }}
                    >
                      권장 사용량 없음
                    </Checkbox>
                  </Form.Item>
                </Flex>
              </LeaveOn>
              <RinseOff align="flex-start">
                <Typography.Text
                  style={{ flex: '1 1 45%' }}
                  gutter={{ top: 10 }}
                >
                  씻어내는 제품 (Rinse-off)
                </Typography.Text>
                <Flex dir="column" gap={14}>
                  <Flex align="center">
                    <Form.Item
                      style={{ height: 46, maxWidth: 100 }}
                      name="rinseOffMin"
                      rules={[
                        generateContentAndRangeValidation('rinseOff', 'right'),
                      ]}
                    >
                      <Input
                        className="range-input-left"
                        disabled={getFieldValue('isNotRinseOff') || readOnly}
                        placeholder="최소"
                        onChange={validateContentAndRangeValidation()}
                      />
                    </Form.Item>
                    <Char
                      color="GRAY90"
                      style={{ top: 12 }}
                      disabled={getFieldValue('isNotRinseOff') || readOnly}
                    >
                      ~
                    </Char>
                    <Form.Item
                      style={{ height: 46 }}
                      name="rinseOffMax"
                      rules={[
                        generateContentAndRangeValidation('rinseOff', 'left'),
                      ]}
                    >
                      <Input
                        className="range-input-right"
                        disabled={getFieldValue('isNotRinseOff') || readOnly}
                        placeholder="최대"
                        onChange={validateContentAndRangeValidation()}
                      />
                    </Form.Item>
                    <Typography.Text medium gutter={{ left: 4 }}>
                      %
                    </Typography.Text>
                  </Flex>
                  <Form.Item
                    noStyle
                    name="isNotRinseOff"
                    valuePropName="checked"
                  >
                    <Checkbox
                      style={{ flex: '1 1 30%' }}
                      disabled={readOnly}
                      onChange={({ target: { checked } }) => {
                        if (checked) {
                          resetFields(['rinseOffMin', 'rinseOffMax']);
                        }

                        if (getFieldValue('isNotLeaveOn') && !checked) {
                          validateFields(['isNotLeaveOn']);
                        }
                      }}
                    >
                      권장 사용량 없음
                    </Checkbox>
                  </Form.Item>
                </Flex>
              </RinseOff>
            </>
          )}
        </Form.Item>
        <Form.Item
          label="처방 시 금기사항"
          name="prescriptionContraindications"
          rules={[
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="처방 시 유의해야 하는 금지 성분 또는 사항에 대해 입력"
          />
        </Form.Item>
        <Form.Item
          label="사용 제한 사항"
          name="usageRestrictions"
          rules={[
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="원료 사용에 제한되는 사항을 입력"
          />
        </Form.Item>
        <Form.Item
          label="경고 문구"
          name="warningText"
          rules={[
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="원료 사용 경고 문구를 입력"
          />
        </Form.Item>
        <Typography.Text
          type="TITLE_2"
          asterisk
          gutter={{ top: 32, bottom: 8 }}
        >
          원료 속성
        </Typography.Text>
        <Form.Item
          required={false}
          label={
            <Typography.Text>
              <Typography.Text inline color="PRIMARY50">
                1.
              </Typography.Text>{' '}
              색상
            </Typography.Text>
          }
          name="materialColor"
          rules={[
            requireRule,
            {
              max: 100,
              message: '최대 100자',
            },
          ]}
        >
          <Input disabled={readOnly} placeholder="원료의 색상 입력" />
        </Form.Item>
        <Form.Item
          required={false}
          label={
            <Typography.Text>
              <Typography.Text inline color="PRIMARY50">
                2.
              </Typography.Text>{' '}
              냄새
            </Typography.Text>
          }
          name="materialSmell"
          rules={[
            requireRule,
            {
              max: 100,
              message: '최대 100자',
            },
          ]}
        >
          <Input
            disabled={readOnly}
            placeholder="원료의 냄새를 구체적으로 입력, ‘특이취' 표현 지양"
          />
        </Form.Item>
        <Form.Item
          required={false}
          label={
            <Typography.Text>
              <Typography.Text inline color="PRIMARY50">
                3.
              </Typography.Text>{' '}
              성상
            </Typography.Text>
          }
          name="materialAppearance"
          rules={[
            requireRule,
            {
              max: 100,
              message: '최대 100자',
            },
          ]}
        >
          <Input disabled={readOnly} placeholder="원료의 성상 입력" />
        </Form.Item>
        <Form.Item
          label="물리적/화학적 특성 : 용해도"
          name="solubility"
          required
          rules={[requireRule]}
        >
          <BackgroundRadio
            gap={0}
            itemWidth={0}
            justify="space-between"
            onChange={(e) => {
              if (e.target.value === NMPADetailSolubility.ETC) {
                setSolubilityEtcVisible(true);
              } else {
                setSolubilityEtcVisible(false);
              }
            }}
            disabled={readOnly}
            options={[
              { title: '수용성', value: NMPADetailSolubility.RECEPTIVITY },
              { title: '지용성', value: NMPADetailSolubility.FAT_SOLUBLE },
              { title: '불용성', value: NMPADetailSolubility.INSOLUBLE },
              { title: '기타 (직접 입력)', value: NMPADetailSolubility.ETC },
            ]}
          />
        </Form.Item>
        {solubilityEtcVisible && (
          <Form.Item
            name="solubilityReason"
            rules={[
              requireRule,
              {
                max: 50,
                message: '최대 50자',
              },
            ]}
          >
            <Input placeholder="용해도에 대한 특성 입력" />
          </Form.Item>
        )}
        <Form.Item
          label="물리적/화학적 특성 : 옥탄올 - 물 분배계수 (log pow)"
          name="logPow"
          rules={[
            {
              max: 50,
              message: '최대 50자',
            },
          ]}
        >
          <Input disabled={readOnly} placeholder="분배계수에 대한 설명 입력" />
        </Form.Item>
        <Form.Item
          label="물리적/화학적 특성 : 기타 속성"
          name="etcAttribute"
          rules={[
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="기타 다른 물리적/화학적 특성 입력"
          />
        </Form.Item>
        <Form.Item
          label="제조 공정 설명"
          name="manufactureProcessExplanation"
          required
          rules={[
            requireRule,
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="제조 공정에 대한 설명을 영문/한글로 입력"
          />
        </Form.Item>
        <Form.Item
          label="원료 식별/특성화 지표 (Method of analysis)"
          name="methodOfAnalysis"
          rules={[
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="원료를 분석하기 위한 시험법 입력"
          />
        </Form.Item>
        <Form.Item
          label="기타 업종에서 사용 시 요구되는 조건"
          name="otherRequirements"
          rules={[
            {
              max: 1000,
              message: '최대 1000자',
            },
          ]}
        >
          <Input.TextArea
            disabled={readOnly}
            rows={4}
            placeholder="기타 업종에서 사용 시 요구되는 조건 입력"
          />
        </Form.Item>
        <FooterBox>
          {!readOnly && !nmpaDetail && (
            <Button
              loading={addNMPATempStoreLoading}
              onClick={handleSaveDetail}
            >
              임시 저장
            </Button>
          )}
          <ReadOnlyBackButton readOnly={readOnly}>
            <Flex>
              {basicInfoStatus?.isNmpaDocModStatus && (
                <Button
                  loading={nmpaCorrectRequestWithNoUpdateLoading}
                  onClick={handleNoUpdateSubmit}
                >
                  보완할 내용 없음
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                loading={addNMPADetailLoading || nmpaOverwriteHistoryLoading}
              >
                {ctaName}
              </Button>
            </Flex>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

export default NMPADetail;
