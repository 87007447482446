import { Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import CertificateOngoing from 'components/certificate/CertificateOngoing';
import path from 'lib/path';

export interface CertificateOngoingPageSearchParams {
  page: number;
  searchType: SearchType;
  searchValue: string;
}

export enum SearchType {
  ALL = 'all',
  PRODUCT_NAME_EN = 'productNameEn',
  COUNTRY_NAMEK_KO = 'countryNameKo',
}

const notices = [
  '인증 등록 신청은 24시간 가능하며 17시 이후에 신청된 건은 접수대기 상태로 처리됩니다.',
  '해당 접수대기 건은 근무일 기준 익일 9시에 접수됩니다. (휴일에 접수하신 경우 다음 근무일에 접수됩니다.)',
  '용량의 경우, 실제 인증을 진행하는 서류의 제품명에 반영되지 않습니다.',
  <Typography.Text type="danger" style={{ padding: 0 }}>
    처리 필요란에 처리할 항목이 없어야 인증이 정상적으로 진행됩니다.
  </Typography.Text>,
];

const CertificateOngoingPage = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const handleChangeQueryParams = (
    params: Partial<CertificateOngoingPageSearchParams>,
  ) => {
    for (const [key, value] of Object.entries(params)) {
      queryParams.set(key, value.toString());
    }
    history.push(`${path.certificate.ongoing}?${queryParams.toString()}`);
  };

  return (
    <PageTemplate title="인증 진행 현황" back={false} notices={notices}>
      <CertificateOngoing
        queryParams={queryParams}
        onChangeQueryParams={handleChangeQueryParams}
      />
    </PageTemplate>
  );
};

export default CertificateOngoingPage;
