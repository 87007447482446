import styled from 'styled-components';
import { Checkbox, Card, Button, Form, Collapse } from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import FooterBox from 'components/FooterBox';
import path from 'lib/path';
import { deviceSize } from 'lib/styles';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import { useAllTerms } from 'service/terms';
import { TermsType } from 'types/terms';
import TermsEditorViewer from 'components/terms/TermsEditorViewer';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';

const RegisterTermsBlock = styled.div`
  flex: 0 1 992px;

  @media ${deviceSize.sm} {
    padding: 20px 0;
  }

  .ant-form-item-control .ant-form-item-control-input {
    min-height: 24px;
  }

  .ant-collapse {
    border: unset;
    background-color: white;
  }

  .ant-collapse-header {
    background-color: ${palette.SLATE_GRAY10};
    padding: 16px 0px 0px 16px !important;
    border-radius: 8px;

    .ant-collapse-arrow {
      top: 0 !important;
      right: 0 !important;
      padding: 0 !important;
    }
  }

  .ant-collapse-content {
    &.ant-collapse-content-active {
      border-top: none;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: unset;
  }

  .ant-collapse-item {
    margin-bottom: 16px;
  }
`;

const CheckAllBlock = styled.div`
  display: flex;
  padding: 16px;
  background-color: ${palette.PRIMARY20};
  border: 1px solid #d7e7fe;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const TermsCard = styled(Card)`
  margin: 8px 0 8px;

  .ant-card-body {
    height: 200px;
    padding: 8px 48px 0;
    overflow: auto;

    @media ${deviceSize.sm} {
      padding: 12px 16px;
    }
  }
`;

const RegisterTerms = ({
  companyType,
  form,
  isTermsChecked,
  isPrivateChecked,
  isThirdPartyChecked,
  isPolicyChecked,
  isLogoChecked,
  onCheckAll,
  onCheckTerm,
  onCheckPrivate,
  onCheckThirdParty,
  onCheckPolicy,
  onCheckLogo,
  onClickNext,
  onFail,
}: any) => {
  const checkedLength = useMemo(
    () =>
      [
        isTermsChecked,
        isPrivateChecked,
        isThirdPartyChecked,
        isPolicyChecked,
        isLogoChecked,
      ].filter((el) => el).length,
    [
      isTermsChecked,
      isPrivateChecked,
      isThirdPartyChecked,
      isPolicyChecked,
      isLogoChecked,
    ],
  );

  const { data: allTerms } = useAllTerms();

  const brandServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.SERVICE_BRAND)
      ?.content || '';
  const manufacturerServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.SERVICE_MANUFACTURER)
      ?.content || '';
  const materialServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.SERVICE_MATERIAL)
      ?.content || '';
  const brandPrivacyServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.PRIVACY_SERVICE_BRAND)
      ?.content || '';
  const manufacturerPrivacyServiceTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_SERVICE_MANUFACTURER,
    )?.content || '';
  const materialPrivacyServiceTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_SERVICE_MATERIAL,
    )?.content || '';
  const brandPrivacyThirdPartyTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_THIRD_PARTY_BRAND,
    )?.content || '';
  const manufacturerPrivacyThirdPartyTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_THIRD_PARTY_MANUFACTURER,
    )?.content || '';
  const materialPrivacyThirdPartyTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_THIRD_PARTY_MATERIAL,
    )?.content || '';
  const brandPrivacyProcessTerms =
    allTerms?.find((item) => item.termType === TermsType.PRIVACY_PROCESS_BRAND)
      ?.content || '';
  const manufacturerPrivacyProcessTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_PROCESS_MANUFACTURER,
    )?.content || '';
  const materialPrivacyProcessTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.PRIVACY_PROCESS_MATERIAL,
    )?.content || '';
  const brandCIBIServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.CI_BI_SERVICE_BRAND)
      ?.content || '';
  const manufacturerCIBIServiceTerms =
    allTerms?.find(
      (item) => item.termType === TermsType.CI_BI_SERVICE_MANUFACTURER,
    )?.content || '';
  const materialCIBIServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.CI_BI_SERVICE_MATERIAL)
      ?.content || '';

  return (
    <RegisterTermsBlock>
      <Form
        form={form}
        colon={false}
        onFinish={onClickNext}
        onFinishFailed={onFail}
        style={{ marginTop: 32 }}
      >
        <CheckAllBlock onClick={() => onCheckAll(checkedLength !== 5)}>
          <Checkbox
            indeterminate={checkedLength > 0 && checkedLength < 5}
            checked={checkedLength === 5}
          >
            <Typography.Text
              style={{ fontSize: 18 }}
              inline
              color="SLATE_GRAY70"
              medium
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              모든 약관에 동의
            </Typography.Text>
          </Checkbox>
        </CheckAllBlock>
        <Collapse
          defaultActiveKey={[
            'terms',
            'private',
            'thirdParty',
            'policy',
            'logo',
          ]}
          expandIconPosition="right"
          expandIcon={({ isActive }) =>
            isActive ? (
              <Flex>
                <Icon
                  name="up"
                  size={20}
                  style={{ position: 'absolute', top: 18, right: 30 }}
                />
              </Flex>
            ) : (
              <Flex>
                <Icon
                  name="down"
                  size={20}
                  style={{ position: 'absolute', top: 18, right: 30 }}
                />
              </Flex>
            )
          }
        >
          <Collapse.Panel
            key="terms"
            header={
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    onCheckTerm(e.target.checked);
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="SLATE_GRAY70"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    이용 약관 동의
                  </Typography.Text>
                  <Typography.Text
                    color="PRIMARY50"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <TermsEditorViewer
                content={
                  companyType === 'brand'
                    ? brandServiceTerms
                    : companyType === 'manufacturer'
                    ? manufacturerServiceTerms
                    : materialServiceTerms
                }
                termsType={TermsType.SERVICE_BRAND}
              ></TermsEditorViewer>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="private"
            header={
              <Form.Item
                name="private"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => onCheckPrivate(e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="SLATE_GRAY70"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    개인정보 수집·이용 동의
                  </Typography.Text>
                  <Typography.Text
                    color="PRIMARY50"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <TermsEditorViewer
                content={
                  companyType === 'brand'
                    ? brandPrivacyServiceTerms
                    : companyType === 'manufacturer'
                    ? manufacturerPrivacyServiceTerms
                    : materialPrivacyServiceTerms
                }
              ></TermsEditorViewer>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="thirdParty"
            header={
              <Form.Item
                name="thirdParty"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => onCheckThirdParty(e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="SLATE_GRAY70"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    개인정보 제3자 제공 동의
                  </Typography.Text>
                  <Typography.Text
                    color="PRIMARY50"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <TermsEditorViewer
                content={
                  companyType === 'brand'
                    ? brandPrivacyThirdPartyTerms
                    : companyType === 'manufacturer'
                    ? manufacturerPrivacyThirdPartyTerms
                    : materialPrivacyThirdPartyTerms
                }
              ></TermsEditorViewer>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="policy"
            header={
              <Form.Item
                name="policy"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('필수 체크항목입니다.'),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    onCheckPolicy(e.target.checked);
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="SLATE_GRAY70"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    개인정보 처리방침 동의
                  </Typography.Text>
                  <Typography.Text
                    color="PRIMARY50"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (필수)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <TermsEditorViewer
                content={
                  companyType === 'brand'
                    ? brandPrivacyProcessTerms
                    : companyType === 'manufacturer'
                    ? manufacturerPrivacyProcessTerms
                    : materialPrivacyProcessTerms
                }
              ></TermsEditorViewer>
            </TermsCard>
          </Collapse.Panel>
          <Collapse.Panel
            key="logo"
            header={
              <Form.Item name="logo" valuePropName="checked">
                <Checkbox
                  onChange={(e) => onCheckLogo(e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography.Text
                    color="SLATE_GRAY70"
                    inline
                    medium
                    onClick={(e) => e.stopPropagation()}
                  >
                    로고 사용 및 게시 동의
                  </Typography.Text>
                  <Typography.Text
                    color="SLATE_GRAY70"
                    inline
                    gutter={{ left: 4 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    (선택)
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            }
          >
            <TermsCard size="small">
              <TermsEditorViewer
                content={
                  companyType === 'brand'
                    ? brandCIBIServiceTerms
                    : companyType === 'manufacturer'
                    ? manufacturerCIBIServiceTerms
                    : materialCIBIServiceTerms
                }
                termsType={TermsType.CI_BI_SERVICE_BRAND}
              ></TermsEditorViewer>
            </TermsCard>
          </Collapse.Panel>
        </Collapse>
        <FooterBox>
          <Button>
            <Link to={path.login}>취소</Link>
          </Button>
          <Button type="primary" htmlType="submit">
            다음
          </Button>
        </FooterBox>
      </Form>
    </RegisterTermsBlock>
  );
};

export default RegisterTerms;
