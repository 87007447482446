import { useState } from 'react';
import { useMutation } from 'react-query';

import client from 'lib/api/client';
import { apiUrls, useBasicMutation, useBasicQuery } from 'service/core';
import {
  ProductChatMessage,
  ProductCorrectRequestMsg,
  ProductProviderForms,
} from 'types/certificate';

// 제품 보완 메시지 조회
export const useProductChatMessages = ({
  productId,
  productDocStatusId,
}: {
  productId: number;
  productDocStatusId: number;
}) => {
  const [emptyArray] = useState([]);

  const {
    data: productChatMessages = emptyArray,
    isLoading: productChatMessagesLoading,
  } = useBasicQuery<ProductChatMessage[]>({
    apiUrl: apiUrls.productChatMessages,
    qs: {
      productId,
      productDocStatusId,
    },
    option: {
      enabled: productId !== undefined && productDocStatusId !== undefined,
    },
  });

  return { productChatMessages, productChatMessagesLoading };
};

// 제품 보완 메시지 보내기
export const useProductCorrectRequestChat = ({
  productDocThreadId,
}: {
  productDocThreadId: number;
}) => {
  const {
    mutate: productCorrectRequestChat,
    isLoading: productCorrectRequestChatLoading,
  } = useBasicMutation<ProductCorrectRequestMsg>({
    apiUrl: apiUrls.productCorrectRequestChat,
    urlParams: { productDocThreadId },
    useFormData: true,
    refetchUrl: apiUrls.productChatMessages,
  });

  return {
    productCorrectRequestChat,
    productCorrectRequestChatLoading,
  };
};

export const useProductCorrectWithoutChange = ({
  productId,
}: {
  productId: number;
}) => {
  const {
    mutate: productCorrectRequestWithoutChange,
    isLoading: productCorrectRequestWithoutChangeLoading,
  } = useBasicMutation<{ countryId: number; documentCode: string }>({
    method: 'patch',
    apiUrl: apiUrls.productCorrectRequestWithoutChange,
    urlParams: { productId },
  });

  return {
    productCorrectRequestWithoutChange,
    productCorrectRequestWithoutChangeLoading,
  };
};

export const useProviderForms = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  // CFS,위탁가공협의서 양식 조회 (pfd001)
  const {
    data: providerForms,
    isLoading: isProviderFormsLoading,
  } = useBasicQuery<ProductProviderForms>({
    apiUrl: apiUrls.productProviderForms,
    urlParams: {
      productId,
      ...(countryId && { countryId }),
    },
    option: {
      enabled: !!productId && !!countryId,
    },
  });

  // CFS 파일 조회
  const { mutate: cfsFileData, isLoading: isCFSFileDataLoading } = useMutation(
    ({ productId, countryId }: { productId: number; countryId?: number }) =>
      client.get(
        `/files/cfs/products/${productId}/pdf?countryId=${countryId}`,
        {
          responseType: 'blob',
        },
      ),
  );

  return {
    providerForms,
    isProviderFormsLoading,
    cfsFileData,
    isCFSFileDataLoading,
  };
};
