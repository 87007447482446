import { Button, Modal } from 'antd';
import { ComponentProps, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import LoadingIndicator from '../LoadingIndicator';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 40px 20px 40px;
    text-align: center;
  }

  .ant-modal-close {
    display: none;
  }
`;

const LoadingModal = ({
  visible,
  width = 400,
  textType = 'BODY_1',
  content,
  onCancel,
  canCacel = true,
}: {
  visible: boolean;
  width?: number;
  textType?: ComponentProps<typeof Typography.Text>['type'];
  content: string;
  canCacel?: boolean;
  onCancel?: () => void;
}) => {
  const [cancelDisabled, setCancelDisabled] = useState<boolean>(false);

  useEffect(() => {
    setCancelDisabled(!canCacel);
  }, [canCacel]);

  return (
    <StyledModal
      visible={visible}
      destroyOnClose
      footer={false}
      centered
      width={width}
      onCancel={onCancel}
      maskClosable={false}
    >
      <LoadingIndicator />
      <Typography.Text
        type={textType}
        style={{ marginTop: 12, whiteSpace: 'pre-line' }}
      >
        {content}
      </Typography.Text>
      {onCancel && (
        <Button
          style={{ marginTop: 24 }}
          htmlType="button"
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          취소
        </Button>
      )}
    </StyledModal>
  );
};

export default LoadingModal;
