import { useEffect } from 'react';
import { message } from 'antd';

import history from 'lib/history';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { messages } from 'lib/consts';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductAgency from 'components/product/ProductAgency';

const notices = [
  '에이전시 (현지 RP) 정보를 입력해 주세요.',
  'certicos의 에이전시를 사용하는 경우에는 자동으로 에이전시 정보가 기입됩니다.',
  <MistakeNoticeItem />,
];

const ProductAgencyPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();

  useEffect(() => {
    if (!countryId) {
      message.warning(messages.INVALID_APPROACH);
      history.goBack();
    }
  }, []);

  if (!countryId) return null;

  return (
    <ProductPageTemplate
      subtitle="에이전시 정보"
      hasSkip={false}
      notices={notices}
    >
      <ProductAgency productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductAgencyPage;
