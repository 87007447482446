import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import MaterialReady from 'components/material/registerManage/MaterialReady';
import PageTemplate from 'templates/PageTemplate';
import MaterialDone from 'components/material/registerManage/MaterialDone';
import path from 'lib/path';
import { RegisterSearchType } from 'types/material/register';

export interface MaterialRegisterMangeSearchParams {
  page: number;
  searchType: RegisterSearchType;
  searchKeyword: string;
}

const MaterialRegisterManagePage = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    params: { status },
  } = useRouteMatch<{ status: string }>();
  const isReady = status === 'ready';

  const queryParams = new URLSearchParams(location.search);

  const handleChangeQueryParams = (
    params: Partial<MaterialRegisterMangeSearchParams>,
  ) => {
    for (const [key, value] of Object.entries(params)) {
      queryParams.set(key, value.toString());
    }
    history.push(
      `${path.material.manage.root}/${status}?${queryParams.toString()}`,
    );
  };

  return (
    <PageTemplate
      title={`등록 ${isReady ? '중' : '완료'} 원료 관리`}
      description={`아래 등록 ${
        isReady ? '중인' : '완료된'
      } 원료를 관리해보세요.`}
      back={false}
    >
      {isReady ? (
        <MaterialReady
          queryParams={queryParams}
          onChangeQueryParams={handleChangeQueryParams}
        />
      ) : (
        <MaterialDone
          queryParams={queryParams}
          onChangeQueryParams={handleChangeQueryParams}
        />
      )}
    </PageTemplate>
  );
};

export default MaterialRegisterManagePage;
