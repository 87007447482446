import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Statistic } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useCurrentProduct } from 'service/brand/product/product';
import StatisticCard from 'components/StatisticCard';
import {
  CertificateInfoFormType,
  DocumentIndexCode,
  ProductCompletedDocument,
} from 'types/product';
import { downloadFile } from 'lib/file';
import { useProductArtworkList } from 'service/product';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const CertificateDocumentsBlock = styled.div`
  transition: unset;
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  width: 218px;
  transition: unset;
`;

const CertificateDocuments = ({
  documents,
  certificate,
  getLoading,
  downloadFilesLoading,
  downloadPIFLoading,
  onPreview,
  onDownload,
  onPIFDownload,
  onDocumentPreview,
}: {
  documents: ProductCompletedDocument[];
  certificate: any;
  getLoading: boolean;
  downloadFilesLoading: boolean;
  downloadPIFLoading: boolean;
  onPreview: ({
    documentUrl,
    documentUrls,
    documentFilename,
  }: {
    documentUrl?: string | undefined;
    documentUrls?: string[] | undefined;
    documentFilename?: string | undefined;
  }) => void;
  onDownload: () => void;
  onPIFDownload: () => void;
  onDocumentPreview: ({
    type,
    documentCode,
    documentName,
  }: {
    type: 'download' | 'preview';
    documentCode: DocumentIndexCode;
    documentName: string;
  }) => void;
}) => {
  const currentProduct = useCurrentProduct() || {};
  const { getProductArtworkList } = useProductArtworkList();
  const [artworks, setArtworks] = useState<
    {
      uploadFileUrl: string;
      documentUrl: string;
      filename: string;
      targetCountryId: number;
    }[]
  >();

  const {
    certRequestNo,
    manufacturerNameEn,
    certRegisterNo,
    certFinYmd,
    certDocUrl,
    certDocFilename,
    cpsrUploadFileUrl,
    estimateTarget: { productDetail, countryName, netWeight, netWeightUnit },
  } = certificate;

  useEffect(() => {
    getProductArtworkList(
      {
        productId: productDetail.productId,
        countryId: productDetail.countryId,
      },
      {
        onSuccess: (res) => {
          setArtworks(res?.data?.result?.productArtworks);
        },
      },
    );
  }, [productDetail]);

  const filteredDocuments = useMemo(() => {
    const arr: ProductCompletedDocument[] = [];

    documents.forEach((item) => {
      if (item.generatableDocumentReport.canGenerate || item.uploadFileUrl) {
        arr.push(item);
      } else if (
        item.documentCode === DocumentIndexCode.ART &&
        artworks &&
        artworks?.length > 0
      ) {
        arr.push(item);
      }
    });
    return arr;
  }, [documents, artworks]);

  const isEU =
    productDetail.country.countryNameKo === '영국' ||
    productDetail.country.countryNameKo === '유럽연합';

  const columns: ColumnsType<ProductCompletedDocument> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center' as const,
        width: '20%',
        render: (_, __, index) => index + 1,
      },

      {
        title: '문서명',
        dataIndex: 'documentName',
      },
      {
        title: '업로드 서류',
        align: 'center' as const,
        width: '20%',
        render: (
          _,
          { uploadFileUrl, originalUploadFileName, documentCode, documentName },
        ) => {
          if (uploadFileUrl) {
            return (
              <Icon
                name="download"
                size={20}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFile(uploadFileUrl, originalUploadFileName);
                }}
                style={{ margin: '0 auto' }}
              />
            );
          } else if (documentCode === DocumentIndexCode.ART) {
            return (
              <Icon
                name="page"
                size={22}
                style={{ margin: '0 auto', cursor: 'pointer' }}
                onClick={() => {
                  onDocumentPreview?.({
                    type: 'download',
                    documentCode,
                    documentName,
                  });
                }}
              />
            );
          }
          return <></>;
        },
      },
      {
        title: '인증 서류',
        align: 'center',
        render: (
          _,
          {
            formType,
            documentCode,
            generatableDocumentReport,
            originalUploadFileName,
          },
        ) => {
          if (
            generatableDocumentReport?.canGenerate ||
            formType === CertificateInfoFormType.E
          ) {
            const isDownloadDocument =
              documentCode === DocumentIndexCode.RMCOA ||
              documentCode === DocumentIndexCode.RMMSDS;

            return (
              <Icon
                name="page"
                size={22}
                style={{ cursor: 'pointer', margin: '0 auto' }}
                onClick={() => {
                  onDocumentPreview?.({
                    type: isDownloadDocument ? 'download' : 'preview',
                    documentCode,
                    documentName: originalUploadFileName || '',
                  });
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
    ],
    [currentProduct],
  );

  return (
    <CertificateDocumentsBlock>
      <StatisticCard>
        <Row gutter={[16, 24]}>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col
            sm={{ span: 10 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="제품명 (용량)"
              value={`${productDetail.productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="제조사" value={manufacturerNameEn} />
          </Col>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="국가" value={countryName} />
          </Col>
          <Col
            sm={{ span: 10 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic
              title="인증 등록 번호"
              valueRender={() => certRegisterNo || '-'}
            />
          </Col>
          <Col
            sm={{ span: 7 }}
            xs={{ span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Statistic title="인증 등록 완료일" value={certFinYmd} />
          </Col>
        </Row>
      </StatisticCard>
      <Row
        justify="center"
        style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'center',
          columnGap: 16,
        }}
      >
        {cpsrUploadFileUrl && (
          <Col>
            <StyledButton
              icon={
                <Icon
                  name="clipboard"
                  size={18}
                  color="PRIMARY50"
                  style={{ marginRight: 4 }}
                />
              }
              onClick={() => onPreview({ documentUrl: cpsrUploadFileUrl })}
              style={{
                width: 218,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {countryName === '미국' ? 'US Safety Report 확인' : 'CPSR 확인'}
            </StyledButton>
          </Col>
        )}
        {certDocUrl && (
          <Col>
            <StyledButton
              icon={
                <Icon
                  name="clipboard"
                  size={18}
                  color="PRIMARY50"
                  style={{ marginRight: 4 }}
                />
              }
              onClick={() =>
                onPreview({
                  documentUrl: certDocUrl,
                  documentFilename: certDocFilename,
                })
              }
              style={{
                width: isEU ? 218 : 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {countryName === '중국' ? '전자위생허가증 확인' : '등록증 확인'}
            </StyledButton>
          </Col>
        )}
        <Col>
          <StyledButton
            loading={downloadFilesLoading}
            icon={
              <Icon
                name="download"
                size={18}
                color="PRIMARY50"
                style={{ marginRight: 4 }}
              />
            }
            onClick={onDownload}
            style={{
              width: isEU ? 218 : 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            모든 서류 다운로드
          </StyledButton>
        </Col>
        {(countryName === '유럽연합' || countryName === '영국') && (
          <Col>
            <StyledButton
              loading={downloadPIFLoading}
              icon={
                <Icon
                  name="download"
                  size={18}
                  color="PRIMARY50"
                  style={{ marginRight: 4 }}
                />
              }
              onClick={onPIFDownload}
              style={{
                width: 218,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              PIF 다운로드
            </StyledButton>
          </Col>
        )}
      </Row>
      <Table
        columns={columns}
        rowKey={({ documentName }) => documentName}
        dataSource={filteredDocuments}
        pagination={false}
        loading={getLoading}
        style={{ marginTop: 40 }}
      />
    </CertificateDocumentsBlock>
  );
};

export default CertificateDocuments;
