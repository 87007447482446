import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Form, message } from 'antd';

import {
  findPassword,
  initializeLogin,
  login,
  togglePasswordModal,
} from 'modules/auth';
import LoginForm from 'components/auth/LoginForm';
import FindPasswordModal from 'components/modal/auth/FindPasswordModal';
import { Typography } from 'components/system';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [loginForm] = Form.useForm();
  const {
    loginError,
    loginFailCount,
    loginLoading,
    isShowFindPasswordModal,
    findPasswordLoading,
  } = useSelector(
    ({ auth, loading }) => ({
      loginError: auth.login.error,
      loginFailCount: auth.login.failCount,
      isShowFindPasswordModal: auth.login.isShowFindPasswordModal,
      loginLoading: loading['auth/LOGIN'],
      findPasswordLoading: loading['auth/FIND_PASSWORD'],
    }),
    shallowEqual,
  );
  const sendAuthenticationEmail = ({ username, email, userType }) => {
    dispatch(
      findPassword({
        username,
        email,
        userType,
      }),
    );
  };
  const closeFindPasswordModal = () => {
    dispatch(togglePasswordModal());
  };
  const showFindPasswordModal = () => {
    dispatch(initializeLogin());
    dispatch(togglePasswordModal());
  };
  const handleLogin = ({ username, password, userType }) => {
    dispatch(
      login({
        username,
        password,
        userType,
      }),
    );
  };
  useEffect(() => {
    if (loginError) {
      if (loginFailCount >= 5) {
        message.warning(
          <Typography.Text type="BODY_2" style={{ textAlign: 'center' }}>
            비밀번호를 모르실 경우, 비밀번호 찾기를 눌러주세요,
          </Typography.Text>,
        );
      } else {
        message.warning(loginError.message);
      }
    }
  }, [loginError, loginFailCount]);
  useEffect(() => {
    return () => {
      dispatch(initializeLogin());
    };
  }, [dispatch]);
  useEffect(() => {
    if (query.reason === 'expire') {
      message.warn('로그인 세션이 만료되었습니다.');
      window.history.pushState(null, null, '/login');
    }
  }, [query.reason]);

  return (
    <>
      <FindPasswordModal
        visible={isShowFindPasswordModal}
        findPasswordLoading={findPasswordLoading}
        onOk={sendAuthenticationEmail}
        onCancel={closeFindPasswordModal}
      />
      <LoginForm
        form={loginForm}
        loginLoading={loginLoading}
        onLogin={handleLogin}
        onFindPassword={showFindPasswordModal}
      />
    </>
  );
};

export default LoginContainer;
