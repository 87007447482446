import { Switch, Route, useParams } from 'react-router-dom';
import { Suspense } from 'react';

import path from 'lib/path';
import FullLoading from 'components/FullLoading';
import ProductReadyPage from 'pages/product/ProductReadyPage';
import ProductBasicPage from 'pages/product/ProductBasicPage';
import ProductCountryPage from 'pages/product/ProductCountryPage';
import ProductCategoryPage from 'pages/product/ProductCategoryPage';
import ProductPhysicalPropertyPage from 'pages/product/ProductPhysicalPropertyPage';
import ProductArtworkPage from 'pages/product/ProductArtworkPage';
import ProductCGMPPage from 'pages/product/ProductCGMPPage';
import ProductStabilityTestPage from 'pages/product/ProductStabilityTestPage';
import ProductChallengeTestPage from 'pages/product/ProductChallengeTestPage';
import ProductPackingAttestationPage from 'pages/product/ProductPackingAttestationPage';
import ProductManufacturingProcessChinaPage from 'pages/product/ProductManufacturingProcessChinaPage';
import ProductFormulaBreakdownPage from 'pages/product/ProductFormulaBreakdownPage';
import ProductFormulaBreakdownChinaPage from 'pages/product/ProductFormulaBreakdownChinaPage';
import ProductIFRAPage from 'pages/product/ProductIFRAPage';
import ProductAllergensListPage from 'pages/product/ProductAllergensListPage';
import ProductThirdPartyTestReportPage from 'pages/product/ProductThirdPartyTestReportPage';
import ProductRawMaterialCoAPage from 'pages/product/ProductRawMaterialCoAPage';
import ProductRawMaterialMSDSPage from 'pages/product/ProductRawMaterialMSDSPage';
import ProductRegisteredPage from 'pages/product/ProductRegisteredPage';
import ProductDetailPage from 'pages/product/ProductDetailPage';
import Error404Page from 'pages/error/Error404Page';
import ProductCFSPage from 'pages/product/ProductCFSPage';
import ProductChinaCertificateTrademarkPage from 'pages/product/ProductChinaCertificateTrademarkPage';
import ProductInternalResponsibleCompanyPage from 'pages/product/ProductInternalResponsibleCompanyPage';
import ProductLogoPage from 'pages/product/ProductLogoPage';
import ProductCOBRMPage from 'pages/product/ProductCOBRMPage';
import ProductROPQMPage from 'pages/product/ProductROPQMPage';
import ProductImportPage from 'pages/product/ProductImportPage';
import { useManuMode } from 'hook/company';
import PrivateRoute from 'components/PrivateRoute';
import { messages } from 'lib/consts';
import ProductBuyersPage from 'pages/product/ProductBuyersPage';
import ProductSpecPage from 'pages/product/ProductSpecPage';
import ProductCountryVendorPage from 'pages/product/ProductCountryVendorPage';
import ProductKeepWarningPage from 'pages/product/ProductKeepWarningPage';
import ProductSeparateWastePage from 'pages/product/ProductSeparateWastePage';
import {
  useCountryId,
  useSetCurrentProduct,
  useSetCurrentProductDocStatus,
} from 'service/brand/product/product';
import { DocumentCode } from 'types/product';
import ProductSaleInfoPage from 'pages/product/ProductSaleInfoPage';
import ProductFDACertificatedColorPage from 'pages/product/ProductFDACertificatedColorPage';
import ProductVCRPAccountPage from 'pages/product/ProductVCRPAccountPage';
import ProductVCRPAccountRequestPage from 'pages/product/ProductVCRPAccountRequestPage';
import ProductManufacturerDeclarationPage from 'pages/product/ProductManufacturerDeclarationPage';
import ProductPeriodOnMarketsPage from 'pages/product/ProductPeriodOnMarketsPage';
import ProductStatementMicrobiologicalPurityPage from 'pages/product/ProductStatementMicrobiologicalPurityPage';
import ProductImagesPage from 'pages/product/ProductImagesPage';
import ProductInformationChinaPage from 'pages/product/ProductInformationChinaPage';
import ProductAgencyPage from 'pages/product/ProductAgencyPage';
import ProductHeavyMetalsAndMicrobiologicalStandardPage from 'pages/product/ProductHeavyMetalsAndMicrobiologicalStandardPage';
import ProductEfficacyPage from 'pages/product/ProductEfficacyPage';
import ProductPMAPage from 'pages/product/ProductPMAPage';
import ProductPQSPage from 'pages/product/ProductPQSPage';
import ProductPCPAPage from 'pages/product/ProductPCPAPage';
import ProductEfficacyEvaluationPage from 'pages/product/ProductEfficacyEvaluationPage';
import ProductArtworkAIScreeningPage from 'pages/product/ProductArtworkAIScreeningPage';
import ProductEtcDataPage from 'pages/product/ProductEtcDataPage';
import ProductAnnex14Page from 'pages/product/ProductAnnex14Page';
import ProductEtcFilePage from 'pages/product/ProductETCFilePage';
import ProductManufacturerInfoPage from 'pages/product/ProductManufacturerInfoPage';
import ProductRPPage from 'pages/product/ProductRPPage';
import ProductFDAPage from 'pages/product/ProductFDAPage';

const ProductRouter = () => {
  const manuMode = useManuMode();
  const params = useParams<{
    productId?: string;
    documentCode?: DocumentCode;
  }>();
  const productId = Number(params.productId) || undefined;
  const { documentCode } = params;
  const countryId = useCountryId();

  useSetCurrentProduct({ productId });
  useSetCurrentProductDocStatus({ productId, countryId, documentCode });

  return (
    <Switch>
      <PrivateRoute
        // HINT : 기존에 사용하던 key={nanoid()} 를 삭제. 혹시 nanoid가 필요해 롤백이 필요할 수 있어 표기를 위해 임시 key 기입
        key={'ProductReadyPage'}
        path={path.product.ready}
        component={ProductReadyPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <PrivateRoute
        // HINT : 기존에 사용하던 key={nanoid()} 를 삭제. 혹시 nanoid가 필요해 롤백이 필요할 수 있어 표기를 위해 임시 key 기입
        key={'ProductRegisteredPage'}
        path={path.product.registered}
        component={ProductRegisteredPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <PrivateRoute
        path={path.product.import}
        component={ProductImportPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <Route
        path={[
          `/product/:productId/${DocumentCode.BASIC}`,
          `/product/${DocumentCode.BASIC}`,
        ]}
        component={ProductBasicPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.COUNTRY}`}
        component={ProductCountryPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.CAT}`}
        component={ProductCategoryPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PHY}`}
        component={ProductPhysicalPropertyPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.HMMS}`}
        component={ProductHeavyMetalsAndMicrobiologicalStandardPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.FORMBR}`}
        component={ProductFormulaBreakdownPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.FORMBRC}`}
        component={ProductFormulaBreakdownChinaPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.ANE}`}
        component={ProductAnnex14Page}
      />
      <Route
        path={`/product/:productId/${DocumentCode.ART}`}
        component={ProductArtworkPage}
      />
      <Route
        path={`/product-art-screening/:productId`}
        component={ProductArtworkAIScreeningPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.CGMP}`}
        component={ProductCGMPPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.STAB}`}
        component={ProductStabilityTestPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.CHAL}`}
        component={ProductChallengeTestPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PACK}`}
        component={ProductPackingAttestationPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.ALLE}`}
        component={ProductAllergensListPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.IFRA}`}
        component={ProductIFRAPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.RMCOA}`}
        component={ProductRawMaterialCoAPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.RMMSDS}`}
        component={ProductRawMaterialMSDSPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.CLOD}`}
        component={ProductEtcDataPage}
      />
      {/* 인증 단계에서 받는 추가 서류 */}
      <Route
        path={`/product/:productId/${DocumentCode.MANUC}`}
        component={ProductManufacturingProcessChinaPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.AI}`}
        component={ProductAgencyPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.BI}`}
        component={ProductBuyersPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.RP}`}
        component={ProductRPPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.FDAAR}`}
        component={ProductFDAPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.THIRD}`}
        component={ProductThirdPartyTestReportPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.CFS}`}
        component={ProductCFSPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PCC}`}
        component={ProductChinaCertificateTrademarkPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.IRC}`}
        component={ProductInternalResponsibleCompanyPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.LOGOAI}`}
        component={ProductLogoPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.COBRM}`}
        component={ProductCOBRMPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.ROPQM}`}
        component={ProductROPQMPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PS}`}
        component={ProductSpecPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.CV}`}
        component={ProductCountryVendorPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.KW}`}
        component={ProductKeepWarningPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.SWI}`}
        component={ProductSeparateWastePage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.UPSI}`}
        component={ProductSaleInfoPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.FCCAD}`}
        component={ProductFDACertificatedColorPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.VCRPAR}`}
        component={ProductVCRPAccountPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.VCRPAC}`}
        component={ProductVCRPAccountRequestPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.MD}`}
        component={ProductManufacturerDeclarationPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.SMP}`}
        component={ProductStatementMicrobiologicalPurityPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.POM}`}
        component={ProductPeriodOnMarketsPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PI}`}
        component={ProductImagesPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PEI}`}
        component={ProductEfficacyPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PMA}`}
        component={ProductPMAPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PIF}`}
        component={ProductInformationChinaPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PQS}`}
        component={ProductPQSPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PCPA}`}
        component={ProductPCPAPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.PEEC}`}
        component={ProductEfficacyEvaluationPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.EDC}`}
        component={ProductEtcFilePage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.MF}`}
        component={ProductManufacturerInfoPage}
      />
      <Route
        path={`/product/:productId/${DocumentCode.RP}`}
        component={ProductRPPage}
      />
      <PrivateRoute
        path="/product/:productId"
        component={ProductDetailPage}
        check={!manuMode}
        redirect={path.main}
        message={messages.NO_PERMISSION}
      />
      <Route path={path.product.root} component={Error404Page} />
    </Switch>
  );
};

const SuspendedProductRouter = (props: any) => {
  return (
    <Suspense fallback={<FullLoading />}>
      <ProductRouter {...props} />
    </Suspense>
  );
};

export default SuspendedProductRouter;
