import { createAction, handleActions } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { message, Modal, Typography } from 'antd';

import { createRequestSaga, createRequestActionTypes } from 'lib/saga';
import * as buyerApi from 'lib/api/buyer';
import history from 'lib/history';
import path from 'lib/path';
import { finishLoading, startLoading } from 'modules/loading';

const [GET_BUYERS, GET_BUYERS_SUCCESS] = createRequestActionTypes(
  'buyer/GET_BUYERS',
);
const [
  GET_NOTARIZATION_BUYERS,
  GET_NOTARIZATION_BUYERS_SUCCESS,
] = createRequestActionTypes('buyer/GET_NOTARIZATION_BUYERS');
const INITIALIZE_NOTARIZATION = 'buyer/INITIALIZE_NOTARIZATION';
const [ADD_BUYER, ADD_BUYER_SUCCESS] = createRequestActionTypes(
  'buyer/ADD_BUYER',
);
const [UPDATE_BUYER, UPDATE_BUYER_SUCCESS] = createRequestActionTypes(
  'buyer/UPDATE_BUYER',
);
const [DELETE_BUYERS, DELETE_BUYERS_SUCCESS] = createRequestActionTypes(
  'buyer/DELETE_BUYERS',
);
const SET_BUYER = 'buyer/SET_BUYER';
const TOGGLE_SHOW_BUYER_ADD_MODAL = 'buyer/TOGGLE_SHOW_BUYER_ADD_MODAL';
const [
  GET_PRODUCT_BUYERS,
  GET_PRODUCT_BUYERS_SUCCESS,
] = createRequestActionTypes('buyer/GET_PRODUCT_BUYERS');
const INITIALIZE_PRODUCT_BUYERS = 'buyer/INITIALIZE_PRODUCT_BUYERS';

export const getBuyers = createAction(GET_BUYERS);
const getBuyersSaga = createRequestSaga(GET_BUYERS, buyerApi.getBuyers);
export const getNotarizationBuyers = createAction(GET_NOTARIZATION_BUYERS);
const getNotarizationBuyersSaga = createRequestSaga(
  GET_NOTARIZATION_BUYERS,
  buyerApi.getBuyersByCountryCode,
);
export const initializeNotarizationBuyers = createAction(
  INITIALIZE_NOTARIZATION,
);

export const addBuyer = createAction(ADD_BUYER);
export const addBuyerSaga = createRequestSaga(ADD_BUYER, buyerApi.addBuyer);
export const addBuyerSuccessSaga = function* () {
  message.success('바이어가 등록되었습니다.');
  yield put(toggleShowAddBuyerModal());
  const companyId = yield select(({ auth }) => auth.user.companyId);
  yield put(getBuyers(companyId));
};

export const updateBuyer = createAction(UPDATE_BUYER);
export const updateBuyerSaga = createRequestSaga(
  UPDATE_BUYER,
  buyerApi.updateBuyer,
);
export const updateBuyerSuccessSaga = function* () {
  message.success('바이어가 수정되었습니다.');
  yield put(setBuyer(null));
  const companyId = yield select(({ auth }) => auth.user.companyId);
  yield put(getBuyers(companyId));
};
export const deleteBuyers = createAction(DELETE_BUYERS);
export const deleteBuyersSaga = function* ({ payload: buyerIds }) {
  const type = DELETE_BUYERS;
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  yield put(startLoading(type));
  try {
    const response = yield call(buyerApi.deleteBuyers, buyerIds);
    yield put({
      type: SUCCESS,
      payload: response.data.result,
      meta: buyerIds,
    });
  } catch (error) {
    if (error.code) {
      yield put({
        type: FAILURE,
        payload: error,
      });
    }
  }
  yield put(finishLoading(type));
};
const deleteBuyersSuccessSaga = function* ({
  payload: notDeletedIds,
  meta: originalDeleteIds,
}) {
  if (originalDeleteIds.length > notDeletedIds.length) {
    message.success('바이어가 삭제되었습니다.');
  }
  if (notDeletedIds.length) {
    const buyers = yield select(({ buyer }) => buyer.buyers);
    const buyerNames = buyers
      .filter(({ buyerId }) => notDeletedIds.includes(buyerId))
      .map(({ companyName }) => companyName);
    Modal.info({
      icon: null,
      content: (
        <>
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            [{buyerNames.join(', ')}]
          </Typography.Paragraph>
          {notDeletedIds.length === 1 ? (
            <Typography.Paragraph>
              위 바이어는 이미 인증 중이거나 인증 완료된 제품에 등록되었기
              때문에 삭제할 수 없습니다.
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph>
              위 바이어들은 이미 인증 중이거나 인증 완료된 제품에 등록되었기
              때문에 삭제할 수 없습니다.
            </Typography.Paragraph>
          )}
        </>
      ),
    });
  }
  const companyId = yield select(({ auth }) => auth.user.companyId);
  yield put(getBuyers(companyId));
};
export const setBuyer = createAction(SET_BUYER);
export const toggleShowAddBuyerModal = createAction(
  TOGGLE_SHOW_BUYER_ADD_MODAL,
);
export const getProductBuyers = createAction(GET_PRODUCT_BUYERS);
const getProductBuyersSaga = createRequestSaga(
  GET_PRODUCT_BUYERS,
  buyerApi.getProductBuyers,
  {
    onSuccess: function* ({ payload: { companyId }, result }) {
      if (companyId && result.length === 0) {
        const modal = Modal.warn({
          title: '해당 바이어로 인증 진행 중인 제품이 없습니다.',
          content: (
            <Typography.Text>
              공증서류 양식을 다운받으시려면,{' '}
              <Typography.Link
                underline
                onClick={() => {
                  modal.destroy();
                  history.push(path.certificate.ongoing);
                }}
              >
                인증진행현황
              </Typography.Link>
              에서 인증 진행 중인 제품을 클릭하여 해당 바이어 정보를 입력해
              주세요.
            </Typography.Text>
          ),
        });
      }
    },
  },
);
export const initializeProductBuyers = createAction(INITIALIZE_PRODUCT_BUYERS);

export const buyerSaga = function* () {
  yield takeLatest(GET_BUYERS, getBuyersSaga);
  yield takeLatest(GET_NOTARIZATION_BUYERS, getNotarizationBuyersSaga);
  yield takeLatest(ADD_BUYER, addBuyerSaga);
  yield takeLatest(ADD_BUYER_SUCCESS, addBuyerSuccessSaga);
  yield takeLatest(UPDATE_BUYER, updateBuyerSaga);
  yield takeLatest(UPDATE_BUYER_SUCCESS, updateBuyerSuccessSaga);
  yield takeLatest(DELETE_BUYERS, deleteBuyersSaga);
  yield takeLatest(DELETE_BUYERS_SUCCESS, deleteBuyersSuccessSaga);
  yield takeLatest(GET_PRODUCT_BUYERS, getProductBuyersSaga);
};

const initialState = {
  buyers: [],
  buyer: null,
  notarizationBuyers: [],
  showAddModal: false,
  productBuyers: [],
};

export default handleActions(
  {
    [GET_BUYERS_SUCCESS]: (state, { payload: buyers }) =>
      produce(state, (draft) => {
        draft.buyers = buyers.sort((a, b) => b.buyerId - a.buyerId);
      }),
    [GET_NOTARIZATION_BUYERS_SUCCESS]: (state, { payload: buyers }) =>
      produce(state, (draft) => {
        if (!buyers.length) {
          message.warn(
            '해당 국가에 바이어가 등록되지 않았습니다.\n바이어를 등록해주셔야 서류 자동발행이 가능합니다.',
          );
        }
        draft.notarizationBuyers = buyers;
      }),
    [initializeProductBuyers]: (state) =>
      produce(state, (draft) => {
        draft.productBuyers = [];
      }),
    [INITIALIZE_NOTARIZATION]: (state) =>
      produce(state, (draft) => {
        draft.notarizationBuyers = [];
      }),
    [SET_BUYER]: (state, { payload: buyer }) =>
      produce(state, (draft) => {
        draft.buyer = buyer;
      }),
    [TOGGLE_SHOW_BUYER_ADD_MODAL]: (state) =>
      produce(state, (draft) => {
        draft.showAddModal = !state.showAddModal;
      }),
    [GET_PRODUCT_BUYERS_SUCCESS]: (state, { payload: productBuyers }) =>
      produce(state, (draft) => {
        draft.productBuyers = productBuyers;
      }),
    [INITIALIZE_PRODUCT_BUYERS]: (state) =>
      produce(state, (draft) => {
        draft.productBuyers = [];
      }),
  },
  initialState,
);
