import { Button, Checkbox, Form, FormInstance, message } from 'antd';
import styled, { css } from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useHeavyMetalsAndMicrobiologicalStandard } from 'service/brand/product/heavyMetalsAndMicrobiologicalStandard';
import { updateCurrentPathname } from 'modules/product';
import history from 'lib/history';
import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import useGA, { EGAActionType } from 'hook/useGA';
import Table from 'components/ui/Table/Table';

const Container = styled.div``;

const UIContainer = styled.div`
  max-width: 920px;
  margin: 0 auto;

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-explain {
    display: none;
  }

  .ant-form-item-has-error {
    .ant-checkbox-inner {
      border: 1px solid red;
    }
  }

  .ant-form-item-control-input {
    align-items: flex-start;
  }
`;

const TableWrapper = styled.div<{ safety?: boolean; heavyMetal?: boolean }>`
  flex: 1;
  margin-top: 8px;
  white-space: break-spaces;

  .ant-table-thead > tr > th {
    padding: 10px 8px;
    line-height: 20px;
    border-bottom: none;
  }

  .ant-table-tbody > tr > td {
    padding: ${({ heavyMetal }) => (heavyMetal ? '10px 24px' : '10px 8px')};
    border-bottom: 1px solid #d8d8d8;

    & > span {
      line-height: 19px;
    }
  }

  ${(props) =>
    props.safety &&
    css`
      .ant-table-tbody > tr:first-child > td:first-child {
        border-right: 1px solid #d8d8d8;
      }
    `}

  ${(props) =>
    props.heavyMetal &&
    css`
      .ant-table-tbody > tr > td:last-child {
        border-left: 1px solid #d8d8d8;
        padding: 10px 24px 10px 16px;
      }
    `}
`;

const StandardConfirmWrapper = styled.div`
  margin-top: 40px;
  border-radius: 8px;
  padding: 24px;
  background-color: ${palette.SLATE_GRAY10};

  .ant-checkbox-wrapper {
    line-height: 24px;
  }
`;

const heavayMetalList = [
  {
    title: '납',
    limit: '점토 포함된 분말 제품 : 50㎍/g이하\n그 밖의 제품 : 20㎍/g이하',
  },
  {
    title: '니켈',
    limit:
      '눈 화장용 제품 : 35㎍/g 이하\n색조 화장용 제품 : 30㎍/g이하\n그 밖의 제품 : 10㎍/g 이하',
  },
  {
    title: '비소',
    limit: '10㎍/g이하',
  },
  {
    title: '수은',
    limit: '1㎍/g이하',
  },
  {
    title: '안티몬',
    limit: '10㎍/g이하',
  },
  {
    title: '카드뮴',
    limit: '5㎍/g이하',
  },
  {
    title: '디옥산',
    limit: '100㎍/g이하',
  },
  {
    title: '메탄올',
    limit: '0.2(v/v)%이하 (단, 물휴지는 0.002%(v/v)이하)',
  },
  {
    title: '포름알데하이드',
    limit: '2000㎍/g이하 (단, 물휴지는 20㎍/g이하)',
  },
  {
    title:
      '프탈레이트류\n(디부틸프탈레이트, 부틸벤질프탈레이트 및 디에칠헥실프탈레이트)',
    limit: '총 합으로서 100㎍/g이하',
  },
];

const safetyList = [
  {
    title: '총호기성생균\n(세균 + 진균)',
    category: '영유아용 · 눈화장용',
    limit: '500개 / g(mL) 이하',
  },
  {
    category: '기타 화장품',
    limit: '1,000개 / g(mL) 이하',
  },
  {
    category: '물휴지',
    limit: '100개 /g(mL) 이하',
  },
];

const microList = [
  {
    title: '황색포도상구균(Staphylococcus aureus)',
    result: '불검출',
  },
  {
    title: '대장균(Escherichia coli)',
    result: '불검출',
  },
  {
    title: '녹농균(Pseudomonas aeruginosa)',
    result: '불검출',
  },
];

const heavyMetalColumns: ColumnsType<{
  title: string;
  limit: string;
}> = [
  {
    title: '검출 항목',
    align: 'center',
    width: '50%',
    render: (_, { title }) => (
      <Typography.Text type="BODY_2" color="GRAY90" medium align="left">
        {title}
      </Typography.Text>
    ),
  },
  {
    title: '검출 허용 한도',
    align: 'center',
    width: '50%',
    render: (_, { limit }) => (
      <Typography.Text type="BODY_2" color="GRAY70" align="left">
        {limit}
      </Typography.Text>
    ),
  },
];

const satefyColumns: ColumnsType<{
  title?: string;
  category: string;
  limit: string;
}> = [
  {
    title: '검출 항목',
    align: 'center',
    width: 125,
    render: (_, { title }, index) => {
      let rowSpan = 0;
      if (index === 0) {
        rowSpan = 3;
      }
      const obj = {
        children: (
          <Typography.Text
            type="BODY_2"
            color="GRAY90"
            medium
            style={{ whiteSpace: 'break-spaces' }}
          >
            {title}
          </Typography.Text>
        ),
        props: { rowSpan },
      };

      return obj;
    },
  },
  {
    title: '카테고리',
    align: 'center',
    width: 159,
    render: (_, { category }) => (
      <Typography.Text type="BODY_2" color="GRAY70">
        {category}
      </Typography.Text>
    ),
  },
  {
    title: '검출 허용 한도',
    align: 'center',
    width: 160,
    render: (_, { limit }) => (
      <Typography.Text type="BODY_2" color="GRAY70">
        {limit}
      </Typography.Text>
    ),
  },
];

const microColumns: ColumnsType<{ title: string; result: string }> = [
  {
    title: '검출 항목',
    align: 'center',
    width: 210,
    render: (_, { title }) => (
      <Typography.Text type="BODY_2" color="GRAY90" medium>
        {title}
      </Typography.Text>
    ),
  },
  {
    title: '결과',
    align: 'center',
    width: 100,
    render: (_, { result }) => (
      <Typography.Text type="BODY_2" color="GRAY70">
        {result}
      </Typography.Text>
    ),
  },
];

export interface HeavyMetalsAndMicrobiologicalStandardForm {
  isChemicalMicrobiologicalStandardConfirm: boolean;
}

const ProductHeavyMetalsAndMicrobiologicalStandard = ({
  productId,
}: {
  productId: number;
}) => {
  const [form] = Form.useForm<HeavyMetalsAndMicrobiologicalStandardForm>();
  const dispatch = useDispatch();
  const { sendEventToGA } = useGA();

  const { skipMode } = useSelector(
    ({ product }: any) => ({
      skipMode: product.skipMode,
    }),
    shallowEqual,
  );

  const {
    addHeavyMetalsAndMicrobiologicalStandard,
    isLoading,
  } = useHeavyMetalsAndMicrobiologicalStandard(productId);

  const handleSubmit = ({
    isChemicalMicrobiologicalStandardConfirm,
  }: {
    isChemicalMicrobiologicalStandardConfirm: boolean;
  }) => {
    if (isChemicalMicrobiologicalStandardConfirm) {
      return addHeavyMetalsAndMicrobiologicalStandard(
        isChemicalMicrobiologicalStandardConfirm,
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Heavy Metals & Microbiological Standard',
              actionType: EGAActionType.REGISTER,
            });
            if (skipMode) {
              dispatch(updateCurrentPathname());
              return;
            }
            message.success('등록되었습니다.');
            history.goBack();
          },
        },
      );
    }
  };

  const handleSubmitFailed = () => {
    message.warn(
      '기준에 적합하지 않은 제품인 경우, 인증을 진행하실 수 없습니다.',
    );
  };

  return (
    <Container>
      <ProductHeavyMetalsAndMicrobiologicalStandardUI
        isLoading={isLoading}
        form={form}
        onSubmit={handleSubmit}
        onSubmitFailed={handleSubmitFailed}
      />
    </Container>
  );
};

export const ProductHeavyMetalsAndMicrobiologicalStandardUI = ({
  form,
  isLoading = false,
  onSubmit,
  onSubmitFailed,
}: {
  form?: FormInstance<HeavyMetalsAndMicrobiologicalStandardForm>;
  isLoading?: boolean;
  onSubmit?: (values: HeavyMetalsAndMicrobiologicalStandardForm) => void;
  onSubmitFailed?: VoidFunction;
}) => {
  return (
    <UIContainer>
      <Typography.Text type="TITLE_2" color="GRAY90" asterisk>
        국내 화장품법상 유통화장품안전관리 기준
      </Typography.Text>
      <Typography.Text type="BODY_2" color="GRAY70" style={{ marginLeft: 8 }}>
        해당 기준은 화장품 안전기준 등에 관한 규정 제1장 제2조에 따라, 국내에서
        제조, 수입 또는 유통되는 모든 화장품에 대해 적용되는 부분임을
        안내드립니다.
      </Typography.Text>
      <TableWrapper style={{ marginTop: 24 }} heavyMetal>
        <Table
          columns={heavyMetalColumns}
          pagination={false}
          dataSource={heavayMetalList}
          rowKey={({ title }) => title}
        />
      </TableWrapper>
      <Flex gap={32} style={{ marginTop: 32 }}>
        <TableWrapper safety>
          <Table
            columns={satefyColumns}
            pagination={false}
            dataSource={safetyList}
            rowKey={({ category }) => category}
          />
        </TableWrapper>
        <TableWrapper>
          <Table
            columns={microColumns}
            pagination={false}
            dataSource={microList}
            rowKey={({ title }) => title}
          />
        </TableWrapper>
      </Flex>
      <Form form={form} onFinish={onSubmit} onFinishFailed={onSubmitFailed}>
        <StandardConfirmWrapper>
          <Form.Item
            name="isChemicalMicrobiologicalStandardConfirm"
            valuePropName="checked"
            required
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.reject();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Checkbox>
              <Typography.Text inline>
                해당 기준에 적합한 제품임을 확인하였습니다.
              </Typography.Text>
              <br />
              <span
                style={{
                  color: palette.SLATE_GRAY70,
                  fontSize: 14,
                  marginLeft: 24,
                }}
              >
                *각 수출국마다 적용되는 국가별 규정이 상이할 수 있어, 인증 진행
                과정에서 담당자가 추가 증빙자료를 요구할 수 있습니다.
              </span>
            </Checkbox>
          </Form.Item>
        </StandardConfirmWrapper>
        <FooterBox>
          <Button type="primary" loading={isLoading} htmlType="submit">
            등록
          </Button>
        </FooterBox>
      </Form>
    </UIContainer>
  );
};

export default ProductHeavyMetalsAndMicrobiologicalStandard;
