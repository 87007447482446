import { useMemo } from 'react';
import { Modal, message as toast } from 'antd';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import {
  useNMPAChatMessages,
  useNMPACorrectRequestChat,
} from 'service/material/nmpa/nmpa';
import {
  NMPADocumentCode,
  NMPAChatMessageThreadAttaches,
} from 'types/material/nmpa/nmpa';
import CorrectChatModalContent, {
  MsgImage,
  NMPAMsgItem,
  NMPAMsgList,
} from '../CorrectChatModalContent';

export interface NMPACorrectChatModalProps {
  onClose?: VoidFunction;
  title?: string;
  nmpaBasicInfoId?: number;
  documentCode: NMPADocumentCode;
  isFirstOpenChatModal: boolean;
}

const StyledModal = styled(Modal)`
  .ant-modal-title {
    max-width: 472px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-modal-content {
    border: none;
    border-radius: 8px;
    max-height: 800px;
    min-height: 800px;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
  }

  .ant-modal-close-x {
    position: absolute;
    top: 7px;
    right: 4px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    padding: 24px;
    display: flex;
    justify-content: center;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      color: ${palette.SLATE_GRAY70};
    }
  }
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border: none;
    border-radius: 0 0 8px 8px;
  }

  .last-file-name {
    font-size: 10px;
    font-weight: 400;
  }

  [ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
`;

const formatDate = (date: string) => {
  return `${date?.slice(0, 4)}년 ${date?.slice(5, 7)}월 ${date?.slice(
    8,
    10,
  )}일`;
};

const getTodayDate = () => {
  const date = new Date();
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일`;
};

const NMPACorrectChatModal = ({
  onClose,
  title,
  nmpaBasicInfoId,
  documentCode,
  isFirstOpenChatModal,
}: NMPACorrectChatModalProps) => {
  const { nmpaChatMessages } = useNMPAChatMessages({
    nmpaBasicInfoId,
    documentCode,
  });

  const materialNmpaDocThreadId =
    nmpaChatMessages.length > 0
      ? nmpaChatMessages[0].materialNmpaDocThreadId
      : 0;

  const {
    nmpaCorrectRequestChat,
    nmpaCorrectRequestChatLoading,
  } = useNMPACorrectRequestChat({
    materialNmpaDocThreadId,
  });

  const messageList = useMemo(() => {
    const newArr: NMPAMsgList[] = [];

    if (!nmpaChatMessages) return [];

    const isCurrentThreadMessages = nmpaChatMessages.filter((i) => !i.isFinish);

    const changeMessagesFormat = (
      attaches: NMPAChatMessageThreadAttaches[],
    ) => {
      if (attaches.length === 0) return [];
      const messages: MsgImage[] = attaches?.map((attach) => ({
        src: attach.fileUrl,
        name: attach.filename,
        type:
          attach.filename
            .slice(attach.filename?.lastIndexOf('.') + 1)
            .startsWith('xlsx') ||
          attach.filename
            .slice(attach.filename?.lastIndexOf('.') + 1)
            .startsWith('pdf')
            ? 'file'
            : 'img',
        size: attach.fileSize,
      }));

      return messages;
    };

    const isContainTodayData =
      isCurrentThreadMessages.filter(
        (item) => formatDate(item.threadMessageRegisterDt) === getTodayDate(),
      ).length > 0;

    isCurrentThreadMessages.forEach(
      ({
        isDelete,
        materialNmpaDocThreadId,
        materialNmpaDocThreadMessageId,
        message,
        threadAttaches,
        threadMessageRegisterDt,
        userId,
        userType,
      }) => {
        const date = formatDate(threadMessageRegisterDt);

        const sameIndex = newArr.findIndex((item) => item.date === date);
        const messages: NMPAMsgItem[] = [
          {
            materialNmpaDocThreadId: materialNmpaDocThreadId,
            msgId: materialNmpaDocThreadMessageId,
            msg: message,
            dir: userType === 'ADMIN' ? 'L' : 'R',
            time: threadMessageRegisterDt,
            deleted: isDelete,
            images: threadAttaches ? changeMessagesFormat(threadAttaches) : [],
          },
        ];

        if (sameIndex !== -1) {
          newArr[sameIndex].messages = newArr[sameIndex].messages.concat(
            messages,
          );
        } else {
          newArr.push({
            materialNmpaDocThreadId,
            materialNmpaDocThreadMessageId,
            date,
            userId: userId,
            userType: userType,
            messages,
          });
        }
      },
    );

    return isContainTodayData
      ? newArr
      : [
          ...newArr,
          {
            materialNmpaDocThreadId:
              isCurrentThreadMessages[0]?.materialNmpaDocThreadId,
            date: getTodayDate(),
            messages: [],
          },
        ];
  }, [nmpaChatMessages]);

  const handleCancel = () => {
    if (!isFirstOpenChatModal) {
      toast.info(
        "보완 내용이 없으신 경우, '보완할 내용 없음' 버튼을 눌러 보완을 완료해 주세요.",
      );
    }
    onClose?.();
  };

  const sendCorrectRequestChat = ({
    message,
    docThreadMessageAttaches,
    onSuccess,
  }: {
    message?: string;
    docThreadMessageAttaches?: {
      attachFile: File | string | null;
    }[];
    onSuccess: VoidFunction;
  }) => {
    const nmpaDocThreadId = nmpaChatMessages?.filter(
      (data) => !data.isFinish,
    )[0]?.materialNmpaDocThreadId;

    if (nmpaBasicInfoId && materialNmpaDocThreadId) {
      nmpaCorrectRequestChat(
        {
          nmpaBasicInfoId,
          documentCode,
          ...(nmpaDocThreadId && {
            materialNmpaDocThreadId: nmpaDocThreadId,
          }),
          nmpaDocThreadMessage: {
            ...(message && {
              message: message?.replaceAll('\n', '<br />'),
            }),
            ...(docThreadMessageAttaches && {
              nmpaDocThreadMessageAttaches: docThreadMessageAttaches,
            }),
          },
        },
        {
          onSuccess,
        },
      );
    }
  };

  return (
    <StyledModal
      className="modal"
      width={560}
      visible={true}
      title={title || '-'}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <CorrectChatModalContent
        chatMessages={nmpaChatMessages}
        correctRequestChatLoading={nmpaCorrectRequestChatLoading}
        messageList={messageList}
        sendCorrectRequestChat={sendCorrectRequestChat}
        onClose={onClose}
      />
    </StyledModal>
  );
};

export default NMPACorrectChatModal;
