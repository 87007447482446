import { Button, Form, message, Modal } from 'antd';
import { useSelector } from 'react-redux';

import { PasswordInput } from 'components/system';
import { Flex } from 'components/ui';
import { requireRule } from 'lib/validate';
import { useChangePassword, useUserType } from 'service/auth';

export interface ChangePasswordModalProps {
  onClose?: () => void;
}

const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  const userType = useUserType();
  const { userId } = useSelector(({ auth, loading }: any) => ({
    userId: auth?.user?.userId,
  }));
  const { changePassword, changePasswordLoading } = useChangePassword();

  const handleSubmit = (passwordForm: any) => {
    if (passwordForm.password !== passwordForm.passwordCheck) {
      return message.warn('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
    }
    if (passwordForm.prevPassword === passwordForm.password) {
      return message.warn('현재 비밀번호와 다르게 입력해 주세요.');
    }
    changePassword(
      {
        userId,
        userType,
        prevPassword: passwordForm.prevPassword,
        password: passwordForm.password,
      },
      {
        onSuccess: () => {
          message.success('변경되었습니다.');
          onClose?.();
        },
        onError: (err: any) => {
          if (err?.code === '-13') {
            message.warn('현재 비밀번호를 확인해 주세요.');
            return;
          }

          onClose?.();
        },
      },
    );
  };
  return (
    <Modal
      visible={true}
      title="비밀번호 변경"
      footer={null}
      destroyOnClose
      onCancel={onClose}
    >
      <Form
        labelCol={{ span: 8 }}
        colon={false}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          label="현재 비밀번호"
          name="prevPassword"
          rules={[requireRule]}
        >
          <PasswordInput
            placeholder="현재 비밀번호 입력"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item label="새 비밀번호" name="password" rules={[requireRule]}>
          <PasswordInput
            placeholder="새 비밀번호 입력"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          label="새 비밀번호 확인"
          name="passwordCheck"
          rules={[requireRule]}
        >
          <PasswordInput
            placeholder="새 비밀번호 다시 입력"
            autoComplete="new-password"
          />
        </Form.Item>
        <Flex justify="center">
          <Button
            type="primary"
            loading={changePasswordLoading}
            htmlType="submit"
            style={{
              marginTop: 8,
              minWidth: 97,
              width: 97,
              height: 44,
              fontSize: 14,
            }}
          >
            변경하기
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
