import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import Error404Page from 'pages/error/Error404Page';
import IngredientDetailPage from 'pages/material/ingredient/IngredientDetailPage';
import IngredientDictionaryPage from 'pages/material/ingredient/IngredientDictionaryPage';

const IngredientRouter = () => {
  return (
    <Switch>
      <Route
        path={path.material.ingredient.dictionary}
        component={IngredientDictionaryPage}
      />
      <Route
        path={`${path.material.ingredient.detail}/:ingredientId`}
        component={IngredientDetailPage}
      />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default IngredientRouter;
