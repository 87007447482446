import { Table as AntTable, PaginationProps, TableProps } from 'antd';

import Icon from '../Icon/Icon';

const Table = <RecordType extends object = any>({
  ...props
}: TableProps<RecordType>) => {
  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <button className="ant-pagination-item-link">
          <Icon name="left" size={20} style={{ margin: '0 auto' }} />
        </button>
      );
    }
    if (type === 'next') {
      return (
        <button className="ant-pagination-item-link">
          <Icon name="right" size={20} style={{ margin: '0 auto' }} />
        </button>
      );
    }
    return originalElement;
  };

  return (
    <AntTable
      {...props}
      pagination={
        props.pagination ? { ...props.pagination, itemRender } : false
      }
    />
  );
};

export default Table;
