import { Button, Col, Row, Upload } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import FooterBox from 'components/FooterBox';
import history from 'lib/history';
import { useCompositionInformation } from 'service/material/compositionInformation';
import CompositionInformationExcelViewerTable from 'components/material/rawMaterial/compositionInformation/CompositionInformationExcelViewerTable';
import {
  useCIDocumentForm,
  useRawMaterial,
} from 'service/material/rawMaterial';
import { CompositionInformationDraft } from 'types/material/compositionInformation';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';

const CompositionInformationBlock = styled.div``;

const StyledButton = styled(Button)<{ isDownload: boolean; disabled: boolean }>`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${({ disabled }) => !disabled && palette.PRIMARY50};
      transition: ${({ isDownload }) =>
        isDownload ? 'all 300ms ease' : 'all 150ms ease'};
    }
  }

  svg,
  path {
    transition: ${({ isDownload }) =>
      isDownload ? 'all 300ms ease' : 'all 150ms ease'};
  }
`;

const CompositionInformation = () => {
  const params = useParams<{
    materialId: string;
  }>();
  const materialId = Number(params.materialId)!;
  const {
    compositionInformations,
    getLoading,
    uploadCompositionInformation,
    uploadLoading,
  } = useCompositionInformation(materialId);
  const {
    downloadCIDocumentForm,
    isLoading: isDownloadCIDocumentFormLoading,
  } = useCIDocumentForm();
  const [
    compositionInformationDraft,
    setCompositionInformationDraft,
  ] = useState<CompositionInformationDraft | null>(null);
  const { rawMaterial } = useRawMaterial(materialId);
  const fetchLoading = getLoading || uploadLoading;

  const handleDocumentFormDownload = () => {
    downloadCIDocumentForm({ materialId });
  };

  const handleUpload = (file: File) => {
    uploadCompositionInformation(
      { materialId, file },
      {
        onSuccess: (res) => {
          setCompositionInformationDraft(res.data.result);
        },
        onError: () => {
          setCompositionInformationDraft(null);
        },
      },
    );
  };

  return (
    <CompositionInformationBlock>
      <Row gutter={8} justify="center" align="middle">
        <Col>
          <StyledButton
            disabled={rawMaterial?.isDiscontinued === true}
            type="dashed"
            isDownload={true}
            icon={<Icon name="download" size={14} style={{ marginRight: 4 }} />}
            onClick={handleDocumentFormDownload}
            loading={isDownloadCIDocumentFormLoading}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            엑셀 서식 다운로드
          </StyledButton>
        </Col>
        <Col>
          <Upload
            disabled={rawMaterial?.isDiscontinued}
            accept=".xlsx"
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            itemRender={() => null}
          >
            <StyledButton
              disabled={rawMaterial?.isDiscontinued === true}
              loading={fetchLoading}
              isDownload={true}
              icon={<Icon name="upload" size={14} style={{ marginRight: 4 }} />}
              style={{
                width: 122,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onDrop={(e) => e.stopPropagation()}
            >
              파일 업로드
            </StyledButton>
          </Upload>
        </Col>
      </Row>
      <CompositionInformationExcelViewerTable
        fetchLoading={fetchLoading}
        dataSource={
          compositionInformationDraft?.list || compositionInformations
        }
        errorCodes={compositionInformationDraft?.invalidCodes || null}
        invalidURL={compositionInformationDraft?.invalidExcelUrl}
      />
      {rawMaterial &&
        !rawMaterial?.isDiscontinued &&
        ((compositionInformationDraft &&
          compositionInformationDraft.invalidCodes.length === 0) ||
          compositionInformations.length > 0) && (
          <FooterBox>
            <Button type="primary" onClick={history.goBack}>
              확인
            </Button>
          </FooterBox>
        )}
    </CompositionInformationBlock>
  );
};

export default CompositionInformation;
