import { useRouteMatch } from 'react-router-dom';

import IngredientDetail from 'components/material/ingredient/IngredientDetail';
import PageTemplate from 'templates/PageTemplate';

const IngredientDetailPage = () => {
  const {
    params: { ingredientId },
  } = useRouteMatch<{ ingredientId: string }>();

  return (
    <PageTemplate
      title="성분 상세 정보"
      titleStyle={{ fontWeight: 400 }}
      style={{ maxWidth: 1040 }}
    >
      <IngredientDetail ingredientId={Number(ingredientId)} />
    </PageTemplate>
  );
};

export default IngredientDetailPage;
