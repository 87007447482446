import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';

const Container = styled.div`
  margin-top: 24px;
`;

const CompanyInfoGuide = styled(Flex)`
  padding: 16px 0;
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};
  text-align: center;
`;

const NMPACompanyInfoGuide = () => {
  return (
    <Container>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
        회사 정보 입력
      </Typography.Text>
      <CompanyInfoGuide justify="center">
        <Typography.Text
          type="TITLE_1"
          medium
          color="SLATE_GRAY70"
          style={{ fontSize: 16 }}
        >
          중국 NMPA 원료 신고번호 취득 절차에 따라 회사 정보의 입력이
          필요합니다.
          <br />
          아래 버튼을 눌러 마이페이지 &gt; 중국 NMPA 회사 정보 탭으로 이동하여
          입력해 주세요.
        </Typography.Text>
      </CompanyInfoGuide>
    </Container>
  );
};

export default NMPACompanyInfoGuide;
