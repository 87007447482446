import { Button, Col, Form, Input, message, Radio, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { createFormData } from 'lib/file';
import palette from 'lib/styles/palette';
import { requireRule } from 'lib/validate';
import { useSaleInfo } from 'service/brand/product/vcrp';
import UpdateLog from './UpdateLog';
import ProductSaleInfoUpload from 'components/file/ProductSaleInfoUpload';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { SaleInfoAttachUpload } from 'types/brand/vcrp';
import { DocumentCode } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const ProductSaleInfoContainer = styled.div`
  width: 520px;
  margin: 0 auto;
`;

const IsOnlineSaleRadioGroup = styled(Radio.Group)`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 80px;
  background: ${palette.SLATE_GRAY10};
  height: 56px;

  label ~ label {
    margin-left: 148px;
  }
`;

const ProductSaleInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [isOnlineSale, setIsOnlineSale] = useState(true);
  const [deleteAttachIds, setDeleteAttachIds] = useState<number[]>([]);
  const {
    form,
    saleInfo,
    addSaleInfo,
    updateSaleInfo,
    updateMode,
    readOnlyMode,
    isLoading,
  } = useSaleInfo({
    productId,
    countryId,
  });

  const handleSubmit = ({
    isOnlineSale,
    saleWebUrl,
    files,
  }: {
    isOnlineSale: boolean;
    saleWebUrl: string | undefined;
    files: (File | SaleInfoAttachUpload)[] | undefined;
  }) => {
    const newFiles = files?.filter((file) => !('url' in file));

    if (
      (isOnlineSale && saleWebUrl === saleInfo?.saleWebUrl) ||
      (!isOnlineSale && !newFiles?.length && !deleteAttachIds.length)
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    const formData = createFormData({
      isOnlineSale,
      ...(isOnlineSale
        ? { saleWebUrl: saleWebUrl }
        : { files: newFiles, deleteAttachIds: deleteAttachIds }),
    });

    if (!updateMode) {
      addSaleInfo(formData);
    } else {
      updateSaleInfo(formData);
    }
  };

  useEffect(() => {
    if (saleInfo) {
      form.setFieldsValue({
        ...saleInfo,
        files: saleInfo.attaches.map((attach) => ({
          name: attach.filename,
          url: attach.attachUrl,
          productSaleInfoAttachId: attach.productSaleInfoAttachId,
        })),
      });
      setIsOnlineSale(saleInfo.isOnlineSale);
    }
  }, [saleInfo]);

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.UPSI}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.UPSI}
            />
          </Col>
        )}
      </Row>
      <ProductSaleInfoContainer>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError={scrollToFirstErrorOption}
            onFinish={handleSubmit}
          >
            <Form.Item
              initialValue={true}
              label="온라인 판매 여부"
              name="isOnlineSale"
              rules={[requireRule]}
            >
              <IsOnlineSaleRadioGroup
                value={isOnlineSale}
                options={[
                  { label: '유', value: true },
                  { label: '무', value: false },
                ]}
                onChange={(e) => setIsOnlineSale(e.target.value)}
                disabled={readOnlyMode}
              ></IsOnlineSaleRadioGroup>
            </Form.Item>
            {isOnlineSale ? (
              <Form.Item
                label="미국내 판매 웹페이지 주소"
                name="saleWebUrl"
                rules={[requireRule]}
              >
                <Input disabled={readOnlyMode} />
              </Form.Item>
            ) : (
              <Form.Item
                label="미국내 판매 증빙 서류"
                name="files"
                rules={[requireRule]}
              >
                <ProductSaleInfoUpload
                  readOnly={readOnlyMode}
                  deleteAttachIds={deleteAttachIds}
                  setDeleteAttachIds={setDeleteAttachIds}
                />
              </Form.Item>
            )}
            <FooterBox>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                {updateMode && (
                  <CorrectRequestWithoutChangeButton
                    documentCode={DocumentCode.UPSI}
                  />
                )}
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {!updateMode ? '등록' : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </Form>
        </Spin>
      </ProductSaleInfoContainer>
    </>
  );
};

export default ProductSaleInfo;
