// HINT: 브랜드, 원료사 각 API가 반환하는 EstimateHistory 필드 이름이 달라서
// 두 API를 통합한 service/useEstimateHistores에서 동일한 형식으로 반환 하기 위해
// 정의함
export interface EstimateHistory {
  no: number;
  estimateHistoryId: number;
  estimateId: number;
  estimateCode: string;
  estimatePrice: number;
  fileUrl: string;
  registerDt: string;
}

export enum DepositStatus {
  READY = 'READY',
  PARTIAL = 'PARTIAL',
  FINISH = 'FINISH',
}

export type EstimateHistoryDomainType =
  | 'brand'
  | 'materialIcid'
  | 'materialNmpa';
