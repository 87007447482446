import { Button, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { downloadFile, getFilenameExtension } from 'lib/file';
import palette from 'lib/styles/palette';
import { NMPACompanyInfo } from 'types/material/nmpa/nmpa';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  margin: 20px auto 0;
  max-width: 688px;
`;

const DownloadContainer = styled.div`
  padding: 24px;
  background: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  word-break: keep-all;
`;

const StyledButton = styled(Button)<{ $disabled: boolean }>`
  margin-top: 16px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      border: 1px solid ${palette.GRAY40};
      color: ${palette.GRAY50};

      :hover,
      :focus {
        border: 1px solid ${palette.GRAY40};
        color: ${palette.GRAY50};
      }

      i {
        color: ${palette.GRAY40} !important;
      }
    `}
`;

const NMPAApplyingInformation = ({
  nmpaCompanyInfo,
}: {
  nmpaCompanyInfo?: NMPACompanyInfo | null;
}) => {
  const [fileUrl, setFileUrl] = useState<string>();
  const isDownloadable = typeof fileUrl !== 'undefined';

  const handleClickDownload = async () => {
    if (isDownloadable) {
      const filename = await getFilenameExtension(
        nmpaCompanyInfo?.certificateOfBusinessFileUrl,
      );

      downloadFile(nmpaCompanyInfo?.certificateOfBusinessFileUrl, filename);
      return;
    }

    message.warn('공증 완료 사업자 등록증 발급 전입니다.');
  };

  useEffect(() => {
    if (nmpaCompanyInfo?.certificateOfBusinessFileUrl) {
      setFileUrl(nmpaCompanyInfo.certificateOfBusinessFileUrl);
    }
  }, [nmpaCompanyInfo]);

  return (
    <Container>
      <Typography.Text type="TITLE_2" gutter={{ bottom: 8 }}>
        공증 완료 사업자 등록증
      </Typography.Text>
      <DownloadContainer>
        <Typography.Text color="SLATE_GRAY70" type="BODY_2">
          번역 공증이 완료된 사업자 등록증 정보를 확인해 주세요.
          <br />
          수권서&기업정보표 탭의 회사 정보 내용과 상이한 경우 사업자 등록증
          정보로 수정해 주세요.
          <br />
        </Typography.Text>
        <StyledButton
          $disabled={!isDownloadable}
          icon={
            <Icon
              name="download"
              color={!isDownloadable ? 'GRAY40' : 'PRIMARY50'}
              size={18}
              style={{ marginRight: 4 }}
            />
          }
          onClick={handleClickDownload}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          다운로드
        </StyledButton>
      </DownloadContainer>
      <Typography.Text type="TITLE_2" gutter={{ top: 16, bottom: 8 }}>
        원료사 코드 (제조업체코드)
      </Typography.Text>
      <Input
        value={nmpaCompanyInfo?.rawMaterialCode ?? ''}
        style={{ width: 256 }}
        disabled
      />
    </Container>
  );
};

export default NMPAApplyingInformation;
