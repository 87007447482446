import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from 'antd';
import styled from 'styled-components';
import { useState } from 'react';

import { exponentOptions } from 'lib/selectOption';
import { useForceUpdate } from 'lib/hook';
import { exceptKoreanRule, numberRule, requireRule } from 'lib/validate';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { useCertificateMode, useReadOnlyMode } from 'hook/certificate';
import FooterBox from 'components/FooterBox';
import Table from 'components/Table';
import Typography from 'components/system/general/Typography';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import palette from 'lib/styles/palette';
import { useChallengeTest } from 'service/brand/product/challengeTest';
import { ToggleSelect } from 'components/system/form/ToggleSelect';
import UpdateLog from 'components/product/UpdateLog';
import { DocumentCode, ProductChallengeTestForm } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';
import InputNumber from 'components/ui/InputNumber';

const ProductChallengeTestBlock = styled.div`
  .ant-radio-wrapper {
    line-height: 24px;
  }
`;

const StyledCard = styled(Card)`
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-checkbox {
      position: relative;
      top: 0;
    }

    span + span {
      flex: 1 1 100%;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-top: 8px;
    margin-left: 0;
  }
`;

const TemperatureInputBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledRadioGroup = styled(Radio.Group)`
  flex-direction: column;

  & > :first-child {
    margin-bottom: 8px;
  }
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px 1px;
  border-radius: 4px;
  border: solid 1px ${palette.GRAY40};
  cursor: pointer;
`;

const TemperatureInput = ({
  form,
  name,
  disabled,
}: {
  form: FormInstance<ProductChallengeTestForm>;
  name: string;
  disabled: boolean;
}) => {
  return (
    <TemperatureInputBlock>
      <Form.Item
        name={`is${name.charAt(0).toUpperCase() + name.slice(1)}None`}
        initialValue={false}
        style={{ flex: '0 0 100px', marginRight: '8px' }}
      >
        <ToggleSelect
          disabled={disabled}
          options={[
            { label: '온도 입력', value: 'false' },
            { label: 'None', value: 'true' },
          ]}
        />
      </Form.Item>
      {!form.getFieldValue(
        `is${name.charAt(0).toUpperCase() + name.slice(1)}None`,
      ) && (
        <Form.Item
          name={name}
          required
          rules={[
            requireRule,
            {
              type: 'number',
              message: messages.SHOULD_NUMBER,
            },
          ]}
          style={{ flex: '0 0 100px' }}
        >
          <InputNumber
            precision={1}
            step={0.1}
            min={0}
            max={80}
            disabled={disabled}
          />
        </Form.Item>
      )}
    </TemperatureInputBlock>
  );
};

const CountInput = ({
  form,
  title,
  name,
  disabled,
}: {
  form: FormInstance<ProductChallengeTestForm>;
  title: string;
  name: string;
  disabled: boolean;
}) => {
  return (
    <>
      <Table.Td>{title}</Table.Td>
      <Table.Td>
        <Row gutter={8} wrap={false} align="top">
          <Col flex="0 0 100px">
            <Form.Item
              initialValue={false}
              name={`is${name.charAt(0).toUpperCase() + name.slice(1)}Tntc`}
            >
              <ToggleSelect
                disabled={disabled}
                options={[
                  { label: '직접입력', value: 'false' },
                  { label: 'TNTC', value: 'true' },
                ]}
              />
            </Form.Item>
          </Col>
          {!form.getFieldValue(
            `is${name.charAt(0).toUpperCase() + name.slice(1)}Tntc`,
          ) && (
            <>
              <Col flex="auto">
                <Form.Item
                  name={name}
                  normalize={(value) => {
                    if (value === null) return '';
                    if (value <= 0) return 1;
                    const stringifiedValue = value.toString();
                    return Number(
                      !stringifiedValue.includes('.')
                        ? `${stringifiedValue.charAt(
                            0,
                          )}.${stringifiedValue.substring(1, 3)}`
                        : stringifiedValue,
                    );
                  }}
                  rules={[requireRule, numberRule]}
                >
                  <InputNumber style={{ width: '100%' }} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col style={{ lineHeight: '44px' }}>*</Col>
              <Col flex="0 0 80px">
                <Form.Item
                  name={`${name}Exponent`}
                  initialValue={exponentOptions[0].value}
                  rules={[requireRule]}
                >
                  <Select disabled={disabled}>
                    {exponentOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Table.Td>
    </>
  );
};

const ProductChallengeTest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const {
    updateMode,
    form,
    getLoading,
    addLoading,
    updateLoading,
    selectedBacteriaCount,
    checkMap,
    isMergeBacteria,
    isMergeYeast,
    isMergeMold,
    toggleIsMergeBacteria,
    toggleIsMergeYeast,
    toggleIsMergeMold,
    toggleCheck,
    onSubmit,
  } = useChallengeTest(productId, countryId);
  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  const readOnlyMode = useReadOnlyMode();
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);
  const [isExampleModal, setIsExampleModal] = useState(false);
  const handleModalClose = () => {
    if (isOpenReportModal) {
      setIsOpenReportModal(false);
    } else {
      setIsExampleModal(false);
    }
  };

  return (
    <ProductChallengeTestBlock>
      <Spin spinning={!!getLoading}>
        <Row justify="end" gutter={8}>
          <Col>
            <ProductCorrectButton
              isFirstOpenChatModal={isFirstOpenChatModal}
              onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
              documentCode={DocumentCode.CHAL}
            />
          </Col>
          {updateMode && (
            <Col>
              <UpdateLog
                productId={productId!}
                countryId={countryId}
                documentCode={DocumentCode.CHAL}
              />
            </Col>
          )}
        </Row>
        <Modal
          visible={isOpenReportModal || isExampleModal}
          onCancel={handleModalClose}
          footer={null}
          width={600}
          zIndex={10000}
          destroyOnClose
        >
          <img
            style={{ width: '100%' }}
            alt="sample"
            src={
              !isExampleModal
                ? 'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/Challenge_Test.jpg'
                : 'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/Challenge_Test_Example.jpg'
            }
          />
        </Modal>
        <Form
          colon={false}
          form={form}
          scrollToFirstError={scrollToFirstErrorOption}
          onValuesChange={() => {
            forceUpdate();
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            required
            name="isReportExist"
            label={
              <Row gutter={8} justify="center">
                <Col>
                  <Typography.Text type="TITLE_2">
                    방부력 테스트 결과 보유 여부
                  </Typography.Text>
                </Col>
                <Col>
                  <IconButton onClick={() => setIsOpenReportModal(true)}>
                    <Typography.Text
                      type="SMALL"
                      color="GRAY70"
                      gutter={{ right: 2 }}
                    >
                      성적서 예시
                    </Typography.Text>
                    <Icon name="search" size={12} color="GRAY70" />
                  </IconButton>
                </Col>
              </Row>
            }
            rules={[requireRule]}
            labelAlign="left"
            style={{ flexDirection: 'column', alignContent: 'center' }}
          >
            <StyledRadioGroup disabled={readOnlyMode}>
              <Radio value={false}>
                성적서 없음 (ISO 29621:2017 기준 방부력 테스트 진행하지 않아도
                되는 제품)
              </Radio>
              <Radio value={true}>
                성적서 보유 (방부력 테스트 결과가 있음)
              </Radio>
            </StyledRadioGroup>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.isReportExist !== curr.isReportExist
            }
            noStyle
          >
            {({ getFieldValue }) =>
              getFieldValue('isReportExist') && (
                <>
                  <Row
                    justify="end"
                    style={{ marginBottom: 8, paddingTop: 20 }}
                  >
                    <Button
                      className="ant-btn-gray"
                      style={{ height: 32 }}
                      onClick={() => setIsExampleModal(true)}
                    >
                      입력 예시 보기
                    </Button>
                  </Row>
                  <Row
                    gutter={8}
                    align="middle"
                    style={{ marginBottom: 8 }}
                    wrap={false}
                  >
                    <Col flex="0 0 auto">
                      <Typography.Text type="TITLE_1">
                        항목 선택
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text type="BODY_2">
                        실험한 Bacteria, Yeast, Mold를 선택 또는 입력해 주세요.
                        Yeast와 Mold의 경우, 수정도 가능합니다.
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <StyledCard
                        title="Bacteria"
                        size="small"
                        type="inner"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Checkbox
                          checked={checkMap.isSaureus}
                          disabled={readOnlyMode}
                          onChange={(e) => {
                            if (
                              !e.target.checked &&
                              selectedBacteriaCount === 3
                            ) {
                              return message.warning(
                                '3개 이상 선택해야 합니다.',
                              );
                            }
                            toggleCheck('isSaureus');
                          }}
                        >
                          Staphylococcus aureus
                        </Checkbox>
                        <Checkbox
                          disabled={readOnlyMode}
                          checked={checkMap.isEcoli}
                          onChange={(e) => {
                            if (
                              !e.target.checked &&
                              selectedBacteriaCount === 3
                            ) {
                              return message.warning(
                                '3개 이상 선택해야 합니다.',
                              );
                            }
                            toggleCheck('isEcoli');
                          }}
                        >
                          Escherichia coli
                        </Checkbox>
                        <Checkbox
                          checked={checkMap.isPaeruginosa}
                          disabled={readOnlyMode}
                          onChange={(e) => {
                            if (
                              !e.target.checked &&
                              selectedBacteriaCount === 3
                            ) {
                              return message.warning(
                                '3개 이상 선택해야 합니다.',
                              );
                            }
                            toggleCheck('isPaeruginosa');
                          }}
                        >
                          Pseudomonas aeruginosa
                        </Checkbox>
                        <Checkbox
                          checked={checkMap.isBacillus}
                          disabled={readOnlyMode}
                          onChange={(e) => {
                            if (
                              !e.target.checked &&
                              selectedBacteriaCount === 3
                            ) {
                              return message.warning(
                                '3개 이상 선택해야 합니다.',
                              );
                            }
                            toggleCheck('isBacillus');
                          }}
                        >
                          Bacillus subtilis
                        </Checkbox>
                        <Checkbox
                          checked={checkMap.isEnterobacter}
                          disabled={readOnlyMode}
                          onChange={(e) => {
                            if (
                              !e.target.checked &&
                              selectedBacteriaCount === 3
                            ) {
                              return message.warning(
                                '3개 이상 선택해야 합니다.',
                              );
                            }
                            toggleCheck('isEnterobacter');
                          }}
                        >
                          Enterobacter spp (On-site detection of bacteria)
                        </Checkbox>
                      </StyledCard>
                    </Col>
                    <Col span={8}>
                      <StyledCard title="Yeast" size="small" type="inner">
                        <Checkbox
                          checked={true}
                          disabled={readOnlyMode}
                          onClick={() => message.warning('필수 항목입니다.')}
                        >
                          <Form.Item
                            name="yeastName"
                            style={{ marginBottom: 0 }}
                            rules={[requireRule, exceptKoreanRule]}
                          >
                            <Input disabled={readOnlyMode} />
                          </Form.Item>
                        </Checkbox>
                        {!readOnlyMode && (
                          <Typography.Text
                            type="BODY_2"
                            color="GRAY70"
                            gutter={{ left: 24 }}
                          >
                            수정이 가능합니다.
                          </Typography.Text>
                        )}
                      </StyledCard>
                    </Col>
                    <Col span={8}>
                      <StyledCard title="Mold" size="small" type="inner">
                        <Checkbox
                          checked={true}
                          disabled={readOnlyMode}
                          onClick={() => message.warning('필수 항목입니다.')}
                        >
                          <Form.Item
                            name="moldName"
                            rules={[requireRule, exceptKoreanRule]}
                            style={{ marginBottom: 0 }}
                          >
                            <Input disabled={readOnlyMode} />
                          </Form.Item>
                        </Checkbox>
                        {!readOnlyMode && (
                          <Typography.Text
                            type="BODY_2"
                            color="GRAY70"
                            gutter={{ left: 24 }}
                          >
                            수정이 가능합니다.
                          </Typography.Text>
                        )}
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row
                    gutter={8}
                    align="middle"
                    style={{ marginTop: 32, marginBottom: 8 }}
                    wrap={false}
                  >
                    <Col flex="0 0 auto">
                      <Typography.Text type="TITLE_1">온도</Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text type="BODY_2">
                        배양 온도를 모르시거나 서류에 기재되어 있지 않은 경우는
                        온도입력 버튼을 클릭하여 None 으로 변경해 주세요.
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Table size="small" bordered>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th />
                        <Table.Th style={{ width: '50%' }}>
                          Incubation Temperature (℃)
                        </Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td>Bacteria</Table.Td>
                        <Table.Td>
                          <TemperatureInput
                            form={form}
                            name="bacteriaTemperature"
                            disabled={readOnlyMode}
                          />
                        </Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>Yeast</Table.Td>
                        <Table.Td>
                          <TemperatureInput
                            form={form}
                            name="yeastTemperature"
                            disabled={readOnlyMode}
                          />
                        </Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>Mold</Table.Td>
                        <Table.Td>
                          <TemperatureInput
                            form={form}
                            name="moldTemperature"
                            disabled={readOnlyMode}
                          />
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                  <Row
                    gutter={8}
                    align="middle"
                    style={{ marginTop: 32, marginBottom: 8 }}
                    wrap={false}
                  >
                    <Col flex="0 0 auto">
                      <Typography.Text type="TITLE_1">
                        {' '}
                        미생물수
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text type="BODY_2">
                        통합하여 발행 또는 Yeast/Mold로 발행 클릭시, Challenge
                        Test 서류상에 각각의 균주 이름이 아니라 Bacteria, Yeast,
                        Mold로 기재되어 발행됩니다.
                        <br />
                        통합하여 발행을 선택하실 경우는 해당 Microorganism의
                        수를 각각 적으실 필요가 없습니다.
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Table size="small" bordered style={{ marginTop: 8 }}>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th width="25%" />
                        <Table.Th>Microorganism</Table.Th>
                        <Table.Th width="40%">
                          미생물수(cfu/g(ml)) : 1 day
                        </Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td
                          rowSpan={
                            isMergeBacteria ? 1 : selectedBacteriaCount + 1
                          }
                        >
                          <Checkbox
                            checked={isMergeBacteria}
                            disabled={readOnlyMode}
                            onClick={toggleIsMergeBacteria}
                          >
                            Bacteria로 통합하여 발행
                          </Checkbox>
                        </Table.Td>
                        {isMergeBacteria && (
                          <CountInput
                            form={form}
                            title="Bacteria"
                            name="mergeBacteria"
                            disabled={readOnlyMode}
                          />
                        )}
                      </Table.Tr>
                      {!isMergeBacteria && (
                        <>
                          {checkMap.isSaureus && (
                            <Table.Tr>
                              <CountInput
                                form={form}
                                title="Staphylococcus aureus"
                                name="saureus"
                                disabled={readOnlyMode}
                              />
                            </Table.Tr>
                          )}
                          {checkMap.isEcoli && (
                            <Table.Tr>
                              <CountInput
                                form={form}
                                title="Escherichia coli"
                                name="ecoli"
                                disabled={readOnlyMode}
                              />
                            </Table.Tr>
                          )}
                          {checkMap.isPaeruginosa && (
                            <Table.Tr>
                              <CountInput
                                form={form}
                                title="Pseudomonas aeruginosa"
                                name="paeruginosa"
                                disabled={readOnlyMode}
                              />
                            </Table.Tr>
                          )}
                          {checkMap.isBacillus && (
                            <Table.Tr>
                              <CountInput
                                form={form}
                                title="Bacillus subtilis"
                                name="bacillus"
                                disabled={readOnlyMode}
                              />
                            </Table.Tr>
                          )}
                          {checkMap.isEnterobacter && (
                            <Table.Tr>
                              <CountInput
                                form={form}
                                title="Enterobacter spp (On-site detection of bacteria)"
                                name="enterobacter"
                                disabled={readOnlyMode}
                              />
                            </Table.Tr>
                          )}
                        </>
                      )}
                      <Table.Tr>
                        <Table.Td>
                          <Checkbox
                            checked={isMergeYeast}
                            onClick={toggleIsMergeYeast}
                            disabled={readOnlyMode}
                          >
                            Yeast로 발행
                          </Checkbox>
                        </Table.Td>
                        <CountInput
                          form={form}
                          title={
                            isMergeYeast
                              ? 'Yeast'
                              : form.getFieldValue('yeastName')
                          }
                          name={isMergeYeast ? 'mergeYeast' : 'yeast'}
                          disabled={readOnlyMode}
                        />
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>
                          <Checkbox
                            checked={isMergeMold}
                            onClick={toggleIsMergeMold}
                            disabled={readOnlyMode}
                          >
                            Mold로 발행
                          </Checkbox>
                        </Table.Td>
                        <CountInput
                          form={form}
                          title={
                            isMergeMold
                              ? 'Mold'
                              : form.getFieldValue('moldName')
                          }
                          name={isMergeMold ? 'mergeMold' : 'mold'}
                          disabled={readOnlyMode}
                        />
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                </>
              )
            }
          </Form.Item>
          <FooterBox>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {updateMode && certificateMode && (
                <CorrectRequestWithoutChangeButton
                  documentCode={DocumentCode.CHAL}
                />
              )}
              <Button
                type="primary"
                loading={addLoading || updateLoading}
                htmlType="submit"
              >
                {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </ProductChallengeTestBlock>
  );
};

export default ProductChallengeTest;
