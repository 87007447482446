import { createAction, handleActions } from 'redux-actions';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import produce from 'immer';

import * as estimateApi from 'lib/api/estimate';
import path from 'lib/path';
import { createRequestSaga, createRequestActionTypes } from 'lib/saga';
import { finishLoading, startLoading } from 'modules/loading';

const SET_ESTIMATE_TARGET = 'estimate/SET_ESTIMATE_TARGET';
const SET_SELECTED_ESTIMATE_TARGET_IDS =
  'estimate/SET_SELECTED_ESTIMATE_TARGET_IDS';
const SET_ESTIMATE = 'estimate/SET_ESTIMATE';

const [GET_ESTIMATE, GET_ESTIMATE_SUCCESS] = createRequestActionTypes(
  'estimate/GET_ESTIMATE',
);
const [GET_ESTIMATES, GET_ESTIMATES_SUCCESS] = createRequestActionTypes(
  'estimate/GET_ESTIMATES',
);

const INITIALIZE_ESTIMATE = 'estimate/INITIALIZE_ESTIMATE';
const [PUBLISH_ESTIMATE, PUBLISH_ESTIMATE_SUCCESS] = createRequestActionTypes(
  'estimate/PUBLISH_ESTIMATE',
);
const [CONTRACT] = createRequestActionTypes('estimate/CONTRACT');
const [
  GET_ESTIMATE_HISTORIES,
  GET_ESTIMATE_HISTORIES_SUCCESS,
] = createRequestActionTypes('estimate/GET_ESTIMATE_HISTORIES');
const INITIALIZE_ESTIMATE_HISTORIES = 'estimate/INITIALIZE_ESTIMATE_HISTORIES';
const SET_CONTRACT_STEP = 'estimate/SET_CONTRACT_STEP';

export const setEstimate = createAction(SET_ESTIMATE);
export const setEstimateTarget = createAction(SET_ESTIMATE_TARGET);
export const setSelectedEstimateTargetIds = createAction(
  SET_SELECTED_ESTIMATE_TARGET_IDS,
);
export const initializeEstimate = createAction(INITIALIZE_ESTIMATE);
export const publishEstimate = createAction(PUBLISH_ESTIMATE);
const publishEstimateSaga = createRequestSaga(
  PUBLISH_ESTIMATE,
  estimateApi.publishEstimate,
  {
    onSuccess: function* () {
      yield put(setSelectedEstimateTargetIds([]));
    },
  },
);
const publishEstimateSuccessSaga = function* () {
  const history = yield getContext('history');
  history.push(path.estimate.contract);
};
export const contract = createAction(CONTRACT);
const contractSaga = function* ({ payload }) {
  const SUCCESS = `${CONTRACT}_SUCCESS`;
  const FAILURE = `${CONTRACT}_FAILURE`;
  const { estimateId, isAgreeContract } = payload;

  yield put(startLoading(CONTRACT));
  try {
    yield call(estimateApi.setAddress, payload);
    yield call(estimateApi.contract, estimateId, isAgreeContract);
    yield put({
      type: SUCCESS,
    });
    yield put(setContractStep('payment'));
  } catch (error) {
    if (error.code) {
      yield put({
        type: FAILURE,
        payload: error,
      });
    }
  }
  yield put(finishLoading(CONTRACT));
};
export const getEstimate = createAction(GET_ESTIMATE);
const getEstimateSaga = createRequestSaga(
  GET_ESTIMATE,
  estimateApi.getEstimate,
);
export const getEstimates = createAction(GET_ESTIMATES);
const getEstimatesSaga = createRequestSaga(
  GET_ESTIMATES,
  estimateApi.getEstimates,
);

export const getEstimateHistories = createAction(GET_ESTIMATE_HISTORIES);
const getEstimateHistoriesSaga = createRequestSaga(
  GET_ESTIMATE_HISTORIES,
  estimateApi.getEstimateHistories,
);
export const initializeEstimateHistories = createAction(
  INITIALIZE_ESTIMATE_HISTORIES,
);
export const setContractStep = createAction(SET_CONTRACT_STEP);

export const estimateSaga = function* () {
  yield takeLatest(PUBLISH_ESTIMATE, publishEstimateSaga);
  yield takeLatest(PUBLISH_ESTIMATE_SUCCESS, publishEstimateSuccessSaga);
  yield takeLatest(CONTRACT, contractSaga);
  yield takeLatest(GET_ESTIMATE, getEstimateSaga);
  yield takeLatest(GET_ESTIMATES, getEstimatesSaga);
  yield takeLatest(GET_ESTIMATE_HISTORIES, getEstimateHistoriesSaga);
};

const initialState = {
  estimateTarget: null,
  estimate: null,
  estimates: { list: [], total: 0 },
  selectedEstimateTargetIds: [],
  estimateHistories: [],
  contractStep: 'viewer',
};

export default handleActions(
  {
    [SET_ESTIMATE_TARGET]: (state, { payload: estimateTarget }) =>
      produce(state, (draft) => {
        draft.estimateTarget = estimateTarget;
      }),
    [SET_SELECTED_ESTIMATE_TARGET_IDS]: (
      state,
      { payload: selectedEstimateTargetIds },
    ) =>
      produce(state, (draft) => {
        draft.selectedEstimateTargetIds = selectedEstimateTargetIds;
      }),
    [INITIALIZE_ESTIMATE]: (state) =>
      produce(state, (draft) => {
        draft.estimate = null;
      }),
    [SET_ESTIMATE]: (state, { payload: estimate }) =>
      produce(state, (draft) => {
        draft.estimate = estimate;
      }),
    [GET_ESTIMATE_SUCCESS]: (state, { payload: estimate }) =>
      produce(state, (draft) => {
        draft.estimate = estimate;
      }),
    [GET_ESTIMATES_SUCCESS]: (state, { payload: result }) =>
      produce(state, (draft) => {
        draft.estimates.list = result.content;
        draft.estimates.total = result.totalElements;
        draft.estimates.total = result.totalElements;
      }),
    [GET_ESTIMATE_HISTORIES_SUCCESS]: (state, { payload: estimateHistories }) =>
      produce(state, (draft) => {
        draft.estimateHistories = estimateHistories.map(
          (estimateHistory, index) => ({
            no: estimateHistories.length - index,
            ...estimateHistory,
          }),
        );
      }),
    [INITIALIZE_ESTIMATE_HISTORIES]: (state) =>
      produce(state, (draft) => {
        draft.estimateHistories = [];
      }),
    [SET_CONTRACT_STEP]: (state, { payload: contractStep }) =>
      produce(state, (draft) => {
        draft.contractStep = contractStep;
      }),
  },
  initialState,
);
