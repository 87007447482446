import { Badge, Button, Col, Row, Statistic, Typography as Typo } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import StatisticCard from 'components/StatisticCard';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import { documentNameMap } from 'lib/documentNames';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useUser } from 'service/auth';
import { useProductDetail } from 'service/manufacturer/productDetail';
import { useProductInputRequest } from 'service/manufacturer/productInputRequest';
import ProductInputRequestModal from './ProductInputRequestModal';

const ProductDetailBlock = styled.div``;

export const ProductDetail = ({
  productId,
  status,
  documentCode,
  setRegisterable,
}: {
  productId: number;
  status?: string;
  documentCode?: string;
  setRegisterable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [
    documentCodeForInputRequest,
    setDocumentCodeForInputRequest,
  ] = useState<string | null>(null);
  const { userId: currentUserId } = useUser();
  const {
    currentProduct: product,
    documents,
    registerable,
    registerLoading,
    fetchLoading,
    registerProduct,
    showDocument,
    getManuDocuments,
  } = useProductDetail(productId);
  const { openAlertModal } = useModal();
  useEffect(() => {
    setRegisterable(registerable);
  }, [registerable]);
  useEffect(() => {
    getManuDocuments();
  }, [documentCode, status]);
  const { showOngoingRequestModal, showRequestModal } = useProductInputRequest({
    productId,
  });
  useEffect(() => {
    if (status && documentCode) {
      if (status === 'CANCEL') {
        openAlertModal({
          content: (
            <Typography.Text type="BODY_2" align="center">
              요청자에 의해 <b>{documentNameMap[documentCode]}</b>의<br />
              입력 요청이 취소되었습니다.
            </Typography.Text>
          ),
          noCancelButton: true,
        });
      }
      history.replace(`${path.manufacturer.product.root}/${productId}`);
    }
  }, [status, productId, documentCode, documents]);

  const columns: any = useMemo(
    () =>
      !product
        ? []
        : [
            { title: '구분', dataIndex: 'no', align: 'center' },
            {
              title: '입력정보',
              render: (document: any) => (
                <Flex align="center" gap={8}>
                  <Typography.Text
                    type="BODY_2"
                    style={{
                      fontWeight: 500,
                      cursor: 'pointer',
                      color: palette.GRAY90,
                    }}
                    onClick={() => showDocument(document)}
                  >
                    {document.documentName}
                  </Typography.Text>
                </Flex>
              ),
              onCell: (document: any) => {
                return {
                  onClick: () => {
                    showDocument(document);
                  },
                  style: {
                    cursor: 'pointer',
                  },
                };
              },
            },
            {
              title: '처리현황',
              align: 'center',
              render: (document: any) => {
                if (document.status === 'INP') {
                  return (
                    <Tags.Mark
                      type="arrow"
                      onClick={() => {
                        showDocument(document);
                      }}
                      color="blue"
                    >
                      필수 입력
                    </Tags.Mark>
                  );
                } else if (document.status === 'OPT') {
                  return (
                    <Tags.Mark
                      type="arrow"
                      color="blue"
                      onClick={() => {
                        showDocument(document);
                      }}
                    >
                      선택 입력
                    </Tags.Mark>
                  );
                } else {
                  return <Typo.Text>{document.statusString}</Typo.Text>;
                }
              },
            },
            ...(product.manufacturerStatus === 'PRD-RDY'
              ? [
                  {
                    title: '입력 요청',
                    align: 'center',
                    render: (document: any) => {
                      if (document.status !== 'INP') return null;
                      if (
                        !document.inputRequest ||
                        document.inputRequest.status !== 'REQUEST'
                      ) {
                        return (
                          <Tags.Mark
                            type="arrow"
                            color="blue"
                            iconColor="GRAY70"
                            textColor="GRAY70"
                            onClick={() =>
                              setDocumentCodeForInputRequest(
                                document.documentCode,
                              )
                            }
                            style={{
                              background: palette.ETC_WHITE,
                              border: `1px solid ${palette.GRAY40}`,
                            }}
                          >
                            입력 요청
                          </Tags.Mark>
                        );
                      }
                      const { status, toUserId } = document.inputRequest;
                      if (status === 'REQUEST') {
                        if (toUserId === currentUserId) {
                          return (
                            <Badge
                              dot
                              style={{
                                top: 6,
                                right: 11,
                                backgroundColor: palette.MESSAGE_ERROR,
                                minWidth: 0,
                                width: 4,
                                height: 4,
                              }}
                            >
                              <Tags.Mark
                                color="blue"
                                onClick={() => {
                                  showRequestModal(document);
                                }}
                                style={{
                                  background: palette.ETC_WHITE,
                                  border: `1px solid ${palette.PRIMARY50}`,
                                  paddingRight: 10,
                                }}
                              >
                                받은 요청
                              </Tags.Mark>
                            </Badge>
                          );
                        } else {
                          return (
                            <Tags.Mark
                              type="arrow"
                              color="lightGray"
                              onClick={() => {
                                showOngoingRequestModal(document);
                              }}
                              style={{
                                border: `1px solid ${palette.GRAY40}`,
                              }}
                            >
                              요청 중
                            </Tags.Mark>
                          );
                        }
                      }
                    },
                  },
                ]
              : []),
            {
              title: '최근 입력자 명',
              align: 'center',
              render: (document: any) =>
                document.modifierName
                  ? `${document.modifierName} (${document.modifierCompanyName})`
                  : '',
            },
            {
              title: '입력 일시',
              align: 'center',
              render: (document: any) =>
                document.modifierName && document.updateDate
                  ? document.updateDate
                  : '',
            },
          ],
    [product, showDocument, showOngoingRequestModal, showRequestModal],
  );

  return (
    <ProductDetailBlock>
      {documentCodeForInputRequest && (
        <ProductInputRequestModal
          documentCode={documentCodeForInputRequest}
          productId={product?.productId}
          productName={`${product?.manufacturerItemNameKo || '-'} ${
            product?.netWeight
              ? `(${product?.netWeight} ${product?.netWeightUnit})`
              : '( - )'
          }`}
          onClose={() => setDocumentCodeForInputRequest(null)}
        />
      )}
      <StatisticCard>
        <Row gutter={[24, 24]}>
          <Col span={9}>
            <Statistic
              title="자체 품목명 (용량)"
              value={`${product?.manufacturerItemNameKo} (${product?.netWeight} ${product?.netWeightUnit})`}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Lot No."
              groupSeparator=""
              value={
                product?.productLotNos.length > 0
                  ? product?.productLotNos[0].lotNo || '-'
                  : '-'
              }
            />
          </Col>
          <Col span={7}>
            <Statistic
              title="제조사"
              value={product?.productDetail.manufacturerNameKo}
            />
          </Col>
          <Col span={9}>
            <Statistic
              title="샘플 랩넘버"
              groupSeparator=""
              value={product?.productLabNos?.[0].labNo}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="샘플 담당자"
              value={
                product?.isUsedProductSample
                  ? `${product?.productSampleRegisterUserName} (${product?.productSampleRegisterUserDeptName})`
                  : '-'
              }
            />
          </Col>
        </Row>
      </StatisticCard>
      <Table
        size="small"
        columns={columns}
        rowKey={(row) => row.documentCode}
        dataSource={documents}
        pagination={false}
        loading={fetchLoading}
        style={{ marginTop: 30 }}
      />
      {registerable && (
        <FooterBox>
          <Button
            type="primary"
            loading={registerLoading}
            onClick={registerProduct}
          >
            등록 완료
          </Button>
        </FooterBox>
      )}
    </ProductDetailBlock>
  );
};
