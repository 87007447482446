import { Button, Select, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import { downloadFilesAsZip } from 'lib/file';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { QCQALotListPageSearchParams } from 'pages/brand/qcqa/QCQALotListPage';
import { useUser } from 'service/auth';
import { useQCQALotList, useQCQAProduct } from 'service/brand/qcqa/qcqa';
import {
  QCQADocumentFile,
  QCQALotDocumentUseType,
  QCQALotListItem,
} from 'types/brand/qcqa';

const Container = styled.div`
  .ant-table-wrapper .ant-table {
    overflow: visible !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    overflow: visible !important;
  }

  .ant-table-cell.ant-table-selection-column {
    cursor: default;
  }

  .download-btn {
    display: flex;
    align-items: center;
    gap: 4px;

    i {
      margin-right: 0;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .download-btn:disabled {
    background-color: ${palette.ETC_WHITE};
    border-color: ${palette.GRAY40};
    color: ${palette.GRAY50};

    i {
      color: ${palette.GRAY50};
    }
  }
`;

const RemarkText = styled.span<{ lotId: string }>`
  ${({ lotId }) => css`
    &:hover {
      [class*=${lotId}] {
        opacity: 1 !important;
      }
    }
    [class*=${lotId}] {
      pointer-events: none;
      opacity: 0 !important;
      position: absolute !important;
      padding: 2px 8px !important;
      background: ${palette.GRAY30} !important;
      border-radius: 3px !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1) !important;
      transition: all 0.3s ease !important;
      width: 240px !important;
      height: max-content;
      white-space: pre-wrap !important;
      z-index: 9999;
    }
  `}
`;

const splitNameAndExt = (filename: string) => {
  const idxLastDot = filename.lastIndexOf('.');

  if (idxLastDot === -1) return [filename, ''];

  return [filename.substring(0, idxLastDot), filename.substring(idxLastDot)];
};

const getUseTypeName = (useType: QCQALotDocumentUseType) => {
  switch (useType) {
    case QCQALotDocumentUseType.COMPANY:
      return '1. 회사 서류';
    case QCQALotDocumentUseType.PRODUCT:
      return '2. 제품 서류';
    case QCQALotDocumentUseType.LOT_NO:
      return '3. 제조번호 서류';
  }
};

const QCQALotList = ({
  queryParams,
  replaceSearchParams,
  qcqaProductId,
}: {
  queryParams: QCQALotListPageSearchParams;
  replaceSearchParams: (state: Partial<QCQALotListPageSearchParams>) => void;
  qcqaProductId: number;
}) => {
  const user = useUser();
  const { page, productYear } = queryParams;
  const [selectedLotIds, setSelectedLotIds] = useState<number[]>([]);
  const [isDownloadZipLoading, setIsDownloadZipLoading] = useState<boolean>(
    false,
  );
  const [isCanDeleteUser, setIsCanDeleteUser] = useState<boolean>(false);

  const { qcProduct } = useQCQAProduct({
    qcqaProductId,
  });
  const { openAlertModal } = useModal();
  const {
    qcqaYearlyLotCount,
    isQCQAYearlyLotCountLoading,
    qcqaLotListItems,
    isQCQALotListLoading,
    totalElements,
    deleteQCQALot,
    isDeleteQCQALotLoading,
    getQCQALotDocsListAsync,
  } = useQCQALotList({
    page,
    qcqaProductId,
    productYear,
  });

  const isSuperUser = user.authority === 'SUPER_USER';

  const yearOptions = useMemo(
    () =>
      qcqaYearlyLotCount.map(({ year, count }) => ({
        label:
          Number(year) <=
          Number(qcqaYearlyLotCount[qcqaYearlyLotCount.length - 1].year)
            ? `${year} 이전 (${count})`
            : `${year} (${count})`,
        value: Number(year),
      })),
    [qcqaYearlyLotCount],
  );

  const handleYearChange = (productYear: number) => {
    replaceSearchParams({
      page: 1,
      productYear,
    });
  };

  const handleChangePage = (page: number) => {
    replaceSearchParams({
      page,
    });
  };

  const handleDocumentsDownload = async () => {
    setIsDownloadZipLoading(true);

    const docsMap: Record<number, Record<number, QCQADocumentFile[]>> = {};
    let docCategoryList: {
      qcqaUserDocumentId: number;
      name: string;
      useType: QCQALotDocumentUseType;
    }[] = [];

    const createDocsZipData = () => {
      const zipData: Parameters<typeof downloadFilesAsZip>[0] = [];
      // 중복된 파일의 경우 newLotNo 이름을 가지고 있음
      const tmpRows: (QCQALotListItem & {
        newLotNo?: string;
      })[] = structuredClone(
        qcqaLotListItems.filter((item) =>
          selectedLotIds.includes(item.qcqaProductLotnoId),
        ),
      );

      // 중복 되는 lotNo 이름 변경
      for (let i = 0; i < tmpRows.length; i++) {
        if (tmpRows[i].newLotNo) continue;

        let no = 1;
        for (let j = i + 1; j < tmpRows.length; j++) {
          // 이미 newLotNo이 지정된 것은 패스
          if (tmpRows[j].newLotNo) continue;

          if (tmpRows[i].lotNo === tmpRows[j].lotNo) {
            if (!tmpRows[i].newLotNo) {
              tmpRows[i].newLotNo = tmpRows[i].lotNo + ` (${no++})`;
            }

            tmpRows[j].newLotNo = tmpRows[j].lotNo + ` (${no++})`;
          }
        }
      }

      for (const { qcqaProductLotnoId, lotNo, newLotNo } of tmpRows) {
        // 중복된 이름을 가진 경우 newLotNo를 가지고 있어, 이를 우선 사용
        let lotDirName = newLotNo ?? lotNo;

        zipData.push({
          name: lotDirName,
          url: '',
        });

        // 폴더
        for (const name of [
          '1. 회사 서류',
          '2. 제품 서류',
          '3. 제조번호 서류',
        ]) {
          const categoryDirName = `${lotDirName}/${name}`;

          zipData.push({
            name: categoryDirName,
            url: '',
          });
        }

        for (const category of docCategoryList) {
          const categoryDirName = `${lotDirName}/${getUseTypeName(
            category.useType,
          )}`;
          const tmpDocsMap: Record<
            number,
            Record<
              number,
              (QCQADocumentFile & {
                newFilename?: string;
              })[]
            >
          > = structuredClone(docsMap);

          // 중복되는 파일명 변경
          const targetFiles: (QCQADocumentFile & {
            newFilename?: string;
          })[] = [];

          if (!tmpDocsMap[qcqaProductLotnoId][category.qcqaUserDocumentId])
            continue;

          for (const file of tmpDocsMap[qcqaProductLotnoId][
            category.qcqaUserDocumentId
          ]) {
            targetFiles.push(file);
          }

          // 중복 되는 fileName 이름 변경
          let no = 1;
          for (let i = 0; i < targetFiles.length; i++) {
            if (targetFiles[i].newFilename) continue;

            for (let j = i + 1; j < targetFiles.length; j++) {
              // 이미 newFileName이 지정된 것은 패스
              if (targetFiles[j].newFilename) continue;

              if (targetFiles[i].filename === targetFiles[j].filename) {
                if (!targetFiles[i].newFilename) {
                  const [name, ext] = splitNameAndExt(targetFiles[i].filename);
                  targetFiles[i].newFilename = `${name}(${no++})${
                    ext ? `${ext}` : ''
                  }`;
                }

                const [name, ext] = splitNameAndExt(targetFiles[i].filename);
                targetFiles[j].newFilename = `${name}(${no++})${
                  ext ? `${ext}` : ''
                }`;
              }
            }
          }

          // 파일
          const files =
            tmpDocsMap[qcqaProductLotnoId][category.qcqaUserDocumentId];
          if (!files) continue; // 파일이 없는 경우 스킵

          for (const file of files) {
            zipData.push({
              name: `${categoryDirName}/[${category.name}] ${
                file.newFilename || file.filename
              }`,
              url: file.uploadFileUrl,
            });
          }
        }

        // lotNo
      }

      return zipData;
    };

    const download = async () => {
      const zipData = createDocsZipData();
      await downloadFilesAsZip(
        zipData,
        `${productYear}_${
          qcProduct?.productNameKo || qcProduct?.productNameEn
        }.zip`,
      );
      setIsDownloadZipLoading(false);
    };

    for (const qcqaProductLotnoId of selectedLotIds) {
      docsMap[qcqaProductLotnoId] = {};
      // documentId 다 가져오기
      try {
        const res = await getQCQALotDocsListAsync({
          qcqaProductLotnoId,
        });

        const documents = res.data.result;
        if (docCategoryList.length === 0) {
          docCategoryList = documents.map((item) => ({
            qcqaUserDocumentId: item.qcqaUserDocumentId,
            name: item.name,
            useType: item.useType,
          }));
        }

        // 압축 파일 구조에 추가
        for (const document of documents) {
          if (!docsMap[qcqaProductLotnoId][document.qcqaUserDocumentId]) {
            docsMap[qcqaProductLotnoId][document.qcqaUserDocumentId] = [];
          }

          if (
            document.useType !== QCQALotDocumentUseType.LOT_NO &&
            document.selected &&
            document.records
          ) {
            const targetRecord = document.records?.find(
              ({ qcqaDocumentRecordId }) =>
                qcqaDocumentRecordId ===
                document.selected!.qcqaRelationDocumentRecordId,
            );

            targetRecord?.attaches?.forEach((item) => {
              docsMap[qcqaProductLotnoId][document.qcqaUserDocumentId].push({
                qcqaDocumentAttachId: item.qcqaDocumentRecordAttachId!,
                qcqaDocumentId: document.qcqaUserDocumentId,
                attachId: item.attachId,
                filename: item.filename,
                uploadFileUrl: item.uploadFileUrl,
                registerDt: item.registerDt,
              });
            });
          } else if (
            document.useType === QCQALotDocumentUseType.LOT_NO &&
            document.attaches
          ) {
            document.attaches.forEach((item) => {
              docsMap[qcqaProductLotnoId][document.qcqaUserDocumentId].push({
                qcqaDocumentAttachId: item.qcqaProductLotnoAttachId!,
                qcqaDocumentId: document.qcqaUserDocumentId,
                attachId: item.attachId,
                filename: item.filename,
                uploadFileUrl: item.uploadFileUrl,
                registerDt: item.registerDt,
              });
            });
          }
        }
      } catch {
        setIsDownloadZipLoading(false);
        return;
      }
    }

    // 모든 작업이 끝나면 다운로드
    download();
  };

  const deleteLots = () => {
    openAlertModal({
      content: (
        <Typography.Text type="BODY_2" style={{ whiteSpace: 'pre-wrap' }}>
          {`해당 제품의 등록된 정보가 삭제됩니다.
정말로 삭제하시겠습니까?`}
        </Typography.Text>
      ),
      onOk: () => {
        deleteQCQALot(selectedLotIds, {
          onSuccess: () => {
            message.success('삭제되었습니다.');
          },
        });
      },
      okText: '삭제',
      okLoading: isDeleteQCQALotLoading,
    });
  };

  const columns = useMemo<ColumnsType<QCQALotListItem>>(
    () => [
      {
        title: 'Lot No. (용량)',
        width: 200,
        dataIndex: 'productNameKo',
        render: (_, { lotNo, netWeight, netWeightUnit }) => (
          <Typography.Text type="BODY_2" color="GRAY90" medium>
            {`${lotNo} (${netWeight} ${netWeightUnit})`}
          </Typography.Text>
        ),
        onCell: () => ({
          style: {
            cursor: 'pointer',
          },
        }),
      },
      {
        title: '제조사',
        width: 200,
        render: (_, { manufacturerNameKo }) => manufacturerNameKo,
        onCell: () => ({
          style: {
            cursor: 'pointer',
          },
        }),
      },
      {
        title: '등록자',
        width: 160,
        render: (_, { registerUsername }) => registerUsername,
        onCell: () => ({
          style: {
            cursor: 'pointer',
          },
        }),
      },
      {
        title: '제조일',
        width: 120,
        render: (_, { manufacturingDate }) => manufacturingDate.slice(0, 10),
        onCell: () => ({
          style: {
            cursor: 'pointer',
          },
        }),
      },
      {
        title: '비고',
        ellipsis: true,
        width: 200,
        render: (_, { remark, qcqaProductLotnoId }) => {
          const txt = document.getElementById(`${qcqaProductLotnoId}`);
          const el = txt?.querySelector('span');

          const isOverflowed = (el?.scrollWidth || 0) > 192;

          return (
            <RemarkText
              id={`${qcqaProductLotnoId}`}
              lotId={`tooltip_${qcqaProductLotnoId}`}
            >
              <Typography.Text
                color="SLATE_GRAY70"
                style={{
                  maxWidth: 168,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontSize: 14,
                }}
              >
                {remark || '-'}
              </Typography.Text>
              {isOverflowed && (
                <div className={`tooltip_${qcqaProductLotnoId}`}>{remark}</div>
              )}
            </RemarkText>
          );
        },
        onCell: () => ({
          style: {
            cursor: 'pointer',
          },
        }),
      },
    ],
    [],
  );

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <Select
          loading={isQCQAYearlyLotCountLoading}
          style={{ width: 140 }}
          options={yearOptions}
          value={productYear}
          onChange={handleYearChange}
        />
        <Flex gap={8}>
          <Button
            icon={
              <Icon
                name="plus"
                size={18}
                color="PRIMARY50"
                style={{ marginRight: 4 }}
              />
            }
            onClick={() =>
              history.push(path.qcqa.registration.lot, {
                qcqaProductId,
              })
            }
            style={{ display: 'flex', alignItems: 'center' }}
          >
            제조번호 등록
          </Button>
          <Button
            className="download-btn"
            icon={
              <Icon
                name="download"
                color={selectedLotIds.length === 0 ? 'GRAY50' : 'PRIMARY50'}
              />
            }
            loading={isDownloadZipLoading}
            disabled={selectedLotIds.length === 0}
            onClick={handleDocumentsDownload}
          >
            서류 다운
          </Button>
          <Button
            className="download-btn"
            icon={
              <Icon
                name="delete"
                size={18}
                color={selectedLotIds.length === 0 ? 'GRAY60' : 'PRIMARY50'}
              />
            }
            disabled={selectedLotIds.length === 0}
            onClick={() => {
              if (!isCanDeleteUser) {
                openAlertModal({
                  content:
                    '본인이 등록한 제조번호만 삭제할 수 있습니다.\n다시 선택해 주세요.',
                  noCancelButton: true,
                });
                return;
              }
              if (selectedLotIds.length > 0) {
                deleteLots();
              }
            }}
          >
            삭제
          </Button>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        rowKey={({ qcqaProductLotnoId }) => qcqaProductLotnoId}
        dataSource={qcqaLotListItems}
        loading={isQCQALotListLoading}
        pagination={{
          current: page,
          onChange: handleChangePage,
          total: totalElements,
          pageSize: 10,
        }}
        onRow={({ qcqaProductLotnoId }) => ({
          onClick: (e) => {
            if (!(e.target as HTMLElement).querySelector('.ant-checkbox')) {
              history.push(
                `${path.qcqa.management.root}/${qcqaProductId}/lot/${qcqaProductLotnoId}`,
              );
            }
          },
        })}
        style={{ marginTop: 16 }}
        rowSelection={{
          selectedRowKeys: selectedLotIds,
          onChange: (key, selectedArr) => {
            if (isSuperUser) {
              setIsCanDeleteUser(true);
            } else {
              if (
                selectedArr.find(
                  ({ registerUserId }) => user.userId !== registerUserId,
                )
              ) {
                setIsCanDeleteUser(false);
              } else {
                setIsCanDeleteUser(true);
              }
            }
            setSelectedLotIds(key as number[]);
          },
        }}
      />
    </Container>
  );
};

export default QCQALotList;
